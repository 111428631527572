<template lang="pug" v-if="countryName === 'United States' || countryName === 'Canada'">
    v-row(v-if="countryName === 'United States' || countryName === 'Canada'")
        v-col(cols='6')
            v-autocomplete(
                label='City'
                v-model="cityId"
                @change="changeCity"
                :items="cities"
                item-text="name"
                item-value="city_id"
                clearable
                required
                :rules="[rules.required]"
                :search-input.sync="citySearch"
            )
                template(v-slot:item="data")
                    template
                        v-list-item-content
                            v-list-item-title {{ data.item.name }}
                            v-list-item-subtitle {{ data.item.region_name }}

        v-col(cols='6')
            v-autocomplete(
                :label="countryName === 'United States' ? 'State' : 'Province'"
                v-model="regionId"
                @change="changeRegion"
                :items="regions"
                item-text="name"
                item-value="region_id"
                clearable
                required
                :rules="[rules.required]"
                :search-input.sync="regionSearch"
            )

        v-col(cols='6')
            v-autocomplete(
                label='Postal Code'
                v-model="postalCodeId"
                @change="changePostalCode"
                @blur="postalCodesLoading = false"
                :items="postalCodes"
                item-text="name"
                item-value="postal_code_id"
                clearable
                required
                :rules="[rules.required]"
                :no-data-text="!((!!this.postalCodeSearch && this.postalCodeSearch.length > 1) || this.regionId > 0 || this.cityId > 0) ? 'Continue typing to lookup' : 'No results'"
                :loading="postalCodesLoading"
                :search-input.sync="postalCodeSearch"
            )
                template(v-slot:item="data")
                    template
                        v-list-item-content
                            v-list-item-title {{ data.item.name }}
                            v-list-item-subtitle {{ data.item.region_name }}

    v-row(v-else-if="countryName === 'Australia'")
        v-col(cols='6')
            v-text-field(
                label='City'
                v-model="city"
                @input="$emit('update-customer-address-city-region-postal', this.getCustomerAddressParams())"
                :rules="[rules.required]"
                clearable
                required
                validate-on-blur
            )

        v-col(cols='6')
            v-text-field(
                label='State'
                v-model="region"
                @input="$emit('update-customer-address-city-region-postal', this.getCustomerAddressParams())"
                :rules="[rules.required]"
                clearable
                required
                validate-on-blur
            )

        v-col(cols='6')
            v-text-field(
                label='Postal Code'
                v-model="postalCode"
                @input="$emit('update-customer-address-city-region-postal', this.getCustomerAddressParams())"
                :rules="[rules.required]"
                clearable
                required
                validate-on-blur
            )

    v-row(v-else-if="countryName === 'France' || countryName === 'Switzerland'")
        v-col(cols='6')
            v-text-field(
                label='Postal Code'
                v-model="postalCode"
                @input="$emit('update-customer-address-city-region-postal', this.getCustomerAddressParams())"
                :rules="[rules.required]"
                clearable
                required
                validate-on-blur
            )

        v-col(cols='6')
            v-text-field(
                label='City'
                v-model="city"
                @input="$emit('update-customer-address-city-region-postal', this.getCustomerAddressParams())"
                :rules="[rules.required]"
                clearable
                required
                validate-on-blur
            )

        v-col(cols='12' v-if="countryName === 'Switzerland'")
            v-text-field(
                label='Canton'
                v-model="region"
                @input="$emit('update-customer-address-city-region-postal', this.getCustomerAddressParams())"
                clearable
            )

</template>

<script lang="ts">
    import Vue from 'vue';

    // Data
    import { 
        GetCities,
        GetPostalCodes,
        GetRegions,
    } from "@/data";

    export default Vue.extend({
        data() {
            return {
                citiesMap: {},
                cities: [],
                citySearch: null,
                city: null,
                cityId: null,
                postalCode: null,
                postalCodeId: null,
                region: null,
                regionId: null,
                regionSearch: null,
                postalCodesMap: {},
                postalCodes: [],
                postalCodesLoading: false,
                postalCodeSearch: null,
                regions: [],
                rules: {
                    required: v => !!v || 'Required.',
                }
            }
        },
        methods: {
            changeCity(cityId) {
                if (!cityId) {
                    this.cityId = null
                }

                if (cityId > 0) {
                    const city = this.citiesMap[cityId];
                    if (city?.city_id > 0) {
                        this.regionId = city.region_id;
                    }
                }

                this.getPostalCodes();

                this.$emit('update-customer-address-city-region-postal', this.getCustomerAddressParams());
            },
            changePostalCode(postalCodeId) {
                if (!postalCodeId) {
                    this.postalCodeId = null
                    this.postalCodeSearch = null
                }

                if (postalCodeId > 0) {
                    const postalCode = this.postalCodesMap[postalCodeId];
                    if (postalCode?.postal_code_id > 0) {
                        this.regionId = postalCode.region_id;
                    }
                }
                
                this.getCities();

                this.$emit('update-customer-address-city-region-postal', this.getCustomerAddressParams());
            },
            changeRegion(regionId) {
                if (!regionId) {
                    this.regionId = null;
                }

                this.cityId = null;
                this.postalCodeId = null;
                this.postalCodeSearch = null;

                this.getPostalCodes();
                this.getCities();

                this.$emit('update-customer-address-city-region-postal', this.getCustomerAddressParams());
            },
            getCities() {
                if (!(this.countryName === 'United States' || this.countryName === 'Canada')) {
                    this.cities = [];
                    this.citiesMap = {};
                    this.cityId = null;
                    return;
                }

                const options = {
                    country_id: this.countryId,
                    postal_code_id: this.postalCodeId,
                    region_id: this.regionId,
                }

                this.isLoading = true;
                GetCities(options).then((response) => {
                    if (response?.Data?.cities?.length > 0) {
                        const ungroupedCities = response.Data.cities;
                        const groupedCities = [];
                        const citiesMap = {};
                        if (ungroupedCities?.length > 1) {
                            ungroupedCities.forEach(function (city, index) {
                                if ((index === 0) || (ungroupedCities[index - 1]?.region_id !== city?.region_id)) {
                                    groupedCities.push({header: city.region_name})
                                }
    
                                groupedCities.push(city);
    
                                citiesMap[city.city_id] = city;
                            });

                            this.cities = groupedCities;
                            this.citiesMap = citiesMap;
                        } else if (ungroupedCities?.length === 1) {
                            this.cities = ungroupedCities;
                            this.citiesMap[ungroupedCities[0].city_id] = ungroupedCities[0];
                            if (this.cityId !== ungroupedCities[0].city_id) {
                                this.cityId = ungroupedCities[0].city_id;
                                this.changeCity(this.cityId);
                            }
                        }
                    }
                    this.isLoading = false;
                });
            },
            getCustomerAddressParams() {
                const params = {
                    city:           this.city,         
                    city_id:        this.cityId,       
                    postal_code:    this.postalCode,   
                    postal_code_id: this.postalCodeId, 
                    region:         this.region,       
                    region_id:      this.regionId,
                }

                return params
            },
            getPostalCodes() {
                if (!(this.countryName === 'United States' || this.countryName === 'Canada')) {
                    this.postalCodes = [];
                    this.postalCodesMap = {};
                    this.postalCodeId = null;
                    return
                }

                this.postalCodesLoading = true;

                // need to have 2 postal code characters for acceptable sql load time
                if (!(this.postalCodeSearch?.length > 1 || this.regionId > 0 || this.cityId > 0)) {
                    this.postalCodes = [];
                    this.postalCodesMap = {};
                    this.postalCodeId = null;
                    this.postalCodesLoading = false;
                    return
                }

                const options = {
                    city_id: this.cityId,
                    country_id: this.countryId,
                    name: null,
                    region_id: this.regionId,
                    group_by_region: true,
                }

                if (this.postalCodeSearch?.length > 0) {
                    options.name = this.postalCodeSearch?.toString();
                }

                this.isLoading = true;
                GetPostalCodes(options).then((response) => {
                    if (response?.Data?.postal_codes?.length > 0) {
                        const ungroupedPostalCodes = response.Data.postal_codes;
                        const groupedPostalCodes = [];
                        const postalCodesMap = {};
                        if (ungroupedPostalCodes?.length > 1) {
                            ungroupedPostalCodes.forEach(function (postalCode, index) {
                                if ((index === 0) || (ungroupedPostalCodes[index - 1]?.region_id !== postalCode?.region_id)) {
                                    groupedPostalCodes.push({header: postalCode.region_name})
                                }
    
                                groupedPostalCodes.push(postalCode);
    
                                postalCodesMap[postalCode.postal_code_id] = postalCode;
                            });

                            this.postalCodes = groupedPostalCodes;
                            this.postalCodesMap = postalCodesMap;
                        } else if (ungroupedPostalCodes?.length === 1) {
                            this.postalCodes = ungroupedPostalCodes;
                            this.postalCodesMap[ungroupedPostalCodes[0].postal_code_id] = ungroupedPostalCodes[0];
                            // can't set postalCodeId because creates circular loop issues with cities
                        }
                    }
                    this.postalCodesLoading = false;
                    this.isLoading = false;
                });
            },
            getRegions() {
                if (!(this.countryName === 'United States' || this.countryName === 'Canada')) {
                    return [];
                }

                const options = {
                    country_id: this.countryId,
                }

                this.isLoading = true;
                GetRegions(options).then((response) => {
                    if (response?.Data?.regions?.length > 0) {
                        this.regions = response.Data.regions;
                    }
                    this.isLoading = false;
                });
            },
            initCustomerAddress() {
                if (this.customerAddressCity?.length > 0) {
                    this.city = this.customerAddressCity
                }
                if (this.customerAddressCityId > 0) {
                    this.cityId = this.customerAddressCityId
                }
                if (this.customerAddressPostalCode?.length > 0) {
                    this.postalCode = this.customerAddressPostalCode
                }
                if (this.customerAddressPostalCodeId > 0) {
                    this.postalCodeId = this.customerAddressPostalCodeId
                }
                if (this.customerAddressRegion?.length > 0) {
                    this.region = this.customerAddressRegion
                }
                if (this.customerAddressRegionId > 0) {
                    this.regionId = this.customerAddressRegionId
                }
            },
        },
        computed: {
        },
        components: {
        },
        props: {
            customerAddressCity:         String,
            customerAddressCityId:       Number,
            countryId:                   Number,
            countryName:                 String,
            customerAddressPostalCode:   String,
            customerAddressPostalCodeId: Number,
            customerAddressRegion:       String,
            customerAddressRegionId:     Number,
        },
        created() {
            this.initCustomerAddress();
            this.getRegions();
            this.getCities();
            this.getPostalCodes();
            return;
        },
        watch: {
            countryName: {
                immediate: true,
                handler (next, old) {
                    if (!!old && next !== old) {
                        this.city = null
                        this.cityId = null
                        this.postalCode = null
                        this.postalCodeId = null
                        this.region = null
                        this.regionId = null
                        this.citiesMap = {}
                        this.cities = []
                        this.postalCodesMap = {}
                        this.postalCodes = []
                        this.postalCodesLoading = false
                        this.postalCodeSearch = null
                        this.regions = []

                        if (next === 'United States' || next === 'Canada') {
                            this.getRegions();
                            this.getCities();
                            this.getPostalCodes();
                        }


                    }
                }
            },
            citySearch (val) {
                this.city = val;
                this.$emit('update-customer-address-city-region-postal', this.getCustomerAddressParams());
            },
            postalCodeSearch (val) {
                this.postalCode = val;
                this.$emit('update-customer-address-city-region-postal', this.getCustomerAddressParams());
                this.getPostalCodes();
            },
            regionSearch (val) {
                this.region = val;
                this.$emit('update-customer-address-city-region-postal', this.getCustomerAddressParams());
            },
        }
    });
</script>

<style lang="scss" scoped>
</style>