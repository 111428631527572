<template lang="pug">
    v-dialog(v-model='dialog' scrollable persistent max-width='600px')
        template(v-slot:activator='{ on }')
            v-btn(icon light v-on='on')
                v-icon mdi-trash-can-outline
        v-card
            v-card-title
                span.text-h5 Remove Address
            v-card-text
                v-container
                    span.text-h6 Are you sure you want to delete this address?

                v-container
                    AddressPostalFormat(
                        :street-one="streetOne"
                        :street-two="streetTwo"
                        :city="city"
                        :region="regionAbbr ? regionAbbr : region"
                        :postal-code="postalCode"
                        :country="country"
                    )

            v-card-actions.text-right
                v-spacer
                    v-btn.ma-2(color="#0f5b9a" outlined ripple @click='dialog = false;')
                        v-icon mdi-window-close
                        | Close
                    v-btn.ma-2(color="red" outlined ripple :disabled="!(customerAddressId > 0)" @click='handleDestroyCustomerAddress(); dialog = false;')
                        v-icon mdi-content-save
                        | Delete

</template>

<script lang="ts">
    import Vue from 'vue';

    // Components
    import AddressPostalFormat from '@/components/shared/addresses/address_postal_format.vue';

    export default Vue.extend({
        data() {
            return {
                dialog: false,
            }
        },
        methods: {
            handleDestroyCustomerAddress() {

                const options = {
                    customer_address_id: this.customerAddressId,
                }

                // Return if not valid options
                if (!(this.customerAddressId > 0)) {
                    return;
                }

                this.destroyCustomerAddress(options);
            },
        },
        computed: {
        },
        components: {
            AddressPostalFormat,
        },
        props: {
            customerAddressId: Number,
            streetOne:  String,
            streetTwo:  String,
            city:       String,
            region:     String,
            regionAbbr: String,
            postalCode: String,
            country:    String,
            destroyCustomerAddress: Function,
        },
        created() {
            return;
        },
    });
</script>

<style lang="scss" scoped>
</style>