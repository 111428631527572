<template lang="pug">
    v-dialog(v-model='dialog' scrollable persistent max-width='600px')
        template(v-slot:activator='{ on }')
            v-btn(v-if="validCustomerAddress " icon light v-on='on' @click="init();")
                v-icon mdi-circle-edit-outline
            v-btn(v-else outlined color="green" small light v-on='on' @click="init()")
                v-icon mdi-plus
                | Add Address
        v-card(:key = "addressFormKey")
            v-card-title
                span.text-h5(v-if="validCustomerAddress") Edit Address
                span.text-h5(v-else) New Address
            v-card-text
                v-container
                    v-form(ref="form")
                        v-row
                            v-col(cols='12')
                                v-autocomplete(
                                    label='Country'
                                    v-model="countryId"
                                    :items="countries"
                                    item-text="name"
                                    item-value="country_id"
                                    :rules="[rules.required, rules.country]"
                                    required
                                    validate-on-blur
                                )

                            v-col(cols='12')
                                v-text-field(
                                    label='Street Address 1'
                                    v-model="streetOne"
                                    :rules="[rules.required]"
                                    clearable
                                    required
                                    validate-on-blur
                                )

                            v-col(cols='12')
                                v-text-field(
                                    label='Street Address 2'
                                    v-model="streetTwo"
                                    clearable
                                )

                        FormAddressPartialByCountry(
                            :country-id="countryId"
                            :country-name="countryMap[countryId]"
                            :customer-address-city="city"
                            :customer-address-city-id="cityId"
                            :customer-address-postal-code="postalCode"
                            :customer-address-postal-code-id="postalCodeId"
                            :customer-address-region="region"
                            :customer-address-region-id="regionId"
                            @update-customer-address-city-region-postal="updateCustomerAddressCityRegionPostal"
                        )

            v-card-actions.text-right
                v-spacer
                    v-btn.ma-2(color="#0f5b9a" outlined ripple @click='dialog = false; resetUpdatedValues();')
                        v-icon mdi-window-close
                        | Close
                    v-btn.ma-2(v-if="validCustomerAddress" color="green" outlined ripple :disabled="!eligibleToSave" @click='handleUpdateCustomerAddress(); dialog = false; resetUpdatedValues();')
                        v-icon mdi-content-save
                        | Save
                    v-btn.ma-2(v-else color="green" outlined ripple :disabled="!eligibleToSave" @click='handleCreateCustomerAddress(); dialog = false; resetUpdatedValues();')
                        v-icon mdi-content-save
                        | Save

</template>

<script lang="ts">
    import Vue from 'vue';
    import VolieState  from "@/store";

    // Components
    import FormAddressPartialByCountry from '@/components/customer_addresses/form_address_partial_by_country.vue';

    export default Vue.extend({
        data() {
            return {
                addressFormKey: 0,
                countryMap: {},
                city: null,
                cityId: null,
                countryId: null,
                dialog: false,
                postalCode: null,
                postalCodeId: null,
                region: null,
                regionId: null,
                rules: {
                    required: v => !!v || 'Required.',
                    country: v =>  v > 0 || 'Invalid country.',
                },
                streetOne: null,
                streetTwo: null,
            }
        },
        methods: {
            handleCreateCustomerAddress() {

                // Return if not valid options
                if (!this.eligibleToSave) {
                    return;
                }

                const params = {
                    street_one:     this.streetOne,    
                    street_two:     this.streetTwo,    
                    city:           this.city,         
                    city_id:        this.cityId,       
                    postal_code:    this.postalCode,   
                    postal_code_id: this.postalCodeId, 
                    region:         this.region,       
                    region_id:      this.regionId,     
                    country_id:     this.countryId,    
                }

                this.createCustomerAddress(params);
            },
            handleUpdateCustomerAddress() {

                // Return if not valid options
                if (!this.eligibleToSave || !(this.validCustomerAddress)) {
                    return;
                }

                const params = {
                    customer_address_id: this.customerAddress.customer_address_id,
                    street_one:          this.streetOne,    
                    street_two:          this.streetTwo,    
                    city:                this.city,         
                    city_id:             this.cityId,       
                    postal_code:         this.postalCode,   
                    postal_code_id:      this.postalCodeId, 
                    region:              this.region,       
                    region_id:           this.regionId,     
                    country_id:          this.countryId,    
                }

                this.updateCustomerAddress(params);
            },
            init() {
                this.setAddressFormKey();

                if (this.validCustomerAddress) {
                    this.initCustomerAddress(); 
                }

                this.setUpDefaultCountry();
            },
            initCustomerAddress() {
                this.streetOne    = this.customerAddress.address_street_one;
                this.streetTwo    = this.customerAddress.address_street_two;
                this.city         = this.customerAddress.address_city;
                this.cityId       = this.customerAddress.city_id;
                this.postalCode   = this.customerAddress.address_postal_code;
                this.postalCodeId = this.customerAddress.postal_code_id;
                this.region       = this.customerAddress.address_region;
                this.regionId     = this.customerAddress.region_id;
                this.countryId    = this.customerAddress.country_id;
            },
            resetUpdatedValues() {
                this.$refs.form.reset();
                this.$refs.form.resetValidation();
            },
            setAddressFormKey() {
                this.addressFormKey = new Date().getTime();
            },
            setUpDefaultCountry() {
                const availableCountries = VolieState.countries();
                if (availableCountries?.length > 0) {
                    for (const country of availableCountries) {
                        this.countryMap[country?.country_id] = country?.name

                        if (!(this.countryId > 0) && country?.name === "United States") {
                            this.countryId = country?.country_id
                        }
                    }
                }
            },
            updateCustomerAddressCityRegionPostal: function(val) {
                this.city         = val?.city
                this.cityId       = val?.city_id
                this.postalCode   = val?.postal_code
                this.postalCodeId = val?.postal_code_id
                this.region       = val?.region
                this.regionId     = val?.region_id
            },
        },
        computed: {
            countries() {
                return VolieState.countries();
            },
            eligibleToSave() {
                if (!(this.countryId > 0)) {
                    return false;
                } 

                if (!(this.streetOne?.length > 0)) {
                    return false;
                }

                const countryName = this.countryMap[this.countryId]
                if (countryName === "United States" || countryName === "Canada") {
                    if (!(this.cityId > 0)) {
                        return false;
                    }

                    if (!(this.regionId > 0)) {
                        return false;
                    }

                    if (!(this.postalCodeId > 0)) {
                        return false;
                    }

                    if (!(this.city?.length > 0)) {
                        return false;
                    }

                    if (!(this.region?.length > 0)) {
                        return false;
                    }

                    if (!(this.postalCode?.length > 0)) {
                        return false;
                    }
                } else if (countryName === "Australia") {
                    if (!(this.city?.length > 0)) {
                        return false;
                    }

                    if (!(this.region?.length > 0)) {
                        return false;
                    }

                    if (!(this.postalcode?.length > 0)) {
                        return false;
                    }
                } else if (countryName === "France" || countryName === "Switzerland") {
                    if (!(this.city?.length > 0)) {
                        return false;
                    }

                    if (!(this.postalcode?.length > 0)) {
                        return false;
                    }
                } else {
                    return false;
                }

                return true;
            },
            validCustomerAddress() {
                return this.customerAddress?.customer_address_id > 0;
            },
        },
        components: {
            FormAddressPartialByCountry,
        },
        props: {
            customerAddress:       Object,
            createCustomerAddress: Function,
            updateCustomerAddress: Function,
        },
        created() {
        },
    });
</script>

<style lang="scss" scoped>
</style>