<template lang="pug">
    div
        v-container.mx-auto
            WebhookEvents(
                v-if="validWebhookEvents"
                :webhook-events="webhookEvents"
            )

            div.text-center(v-else-if="isLoading")
                v-progress-circular(
                    :size='100'
                    :width='10'
                    color='#0f5b9a'
                    indeterminate
                    style="margin-top: 75px; margin-bottom: 75px;"
                )

            v-alert(v-else outlined type='info' dense border='left' style="margin-top: 16px;")
                | There are no webhook events

</template>

<script lang="ts">
    import Vue from 'vue';

    // Components
    import WebhookEvents from '@/components/webhook_events/table.vue';

    export default Vue.extend({
        computed: {
            validWebhookEvents() {
                return this.webhookEvents?.length > 0;
            },
        },
        props: {
            webhookEvents: Array,
            isLoading: Boolean,
        },
        components: {
            WebhookEvents,
        }
    });
</script>

<style lang="scss" scoped>
</style>
