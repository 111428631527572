import { get, post, put, destroy } from '.';

export function GetCdkPluginSettings(options = {}) {
	return get<any>("/app/api/cdk_plugin_settings", options);
}

export function CreateCdkPluginSetting(options = {}) {
	return post<any>("/app/api/cdk_plugin_settings", options);
}

export function UpdateCdkPluginSetting(id, options = {}) {
	return put<any>("/app/api/cdk_plugin_settings/" + (id || 0).toString(), options);
}

export function DestroyCdkPluginSetting(id) {
	return destroy<any>("/app/api/cdk_plugin_settings/" + (id || 0).toString());
}