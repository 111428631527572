import { get, destroy, post, put } from '.';

export function GetCampaignSurveyTemplates(id: number) {
    return get<any>(`/app/api/campaigns/${id}/campaign_survey_templates`);
}

export function GetAvailableSurveyTemplates(id: number) {
    return get<any>(`/app/api/campaigns/${id}/survey_templates`);
}

export function DeleteCampaignSurveyTemplate(id: number, options = {}) {
    return destroy<any>(`/app/api/campaign_survey_templates/${id}`, options);
}

export function CreateCampaignSurveyTemplate(id: number, options = {}) {
    return post<any>(`/app/api/campaigns/${id}/campaign_survey_templates`, options);
}

export function UpdateCampaignSurveyTemplate(id: number, options = {}) {
    return put<any>(`/app/api/campaign_survey_templates/${id}`, options);
}