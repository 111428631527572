<template lang="pug">
    v-dialog(v-if="validTicket" v-model='dialog' persistent max-width='600px')
        template(v-slot:activator='{ on }')
            v-btn(v-if="validTicketTask" small color="warning" dark @click="dialog = true")
                v-icon mdi-circle-edit-outline
            v-btn(v-else icon dark @click="dialog = true")
                v-icon mdi-plus-circle-outline
        Form(
            v-if="dialog"
            :close-dialog="closeDialog"
            :is-dialog="true"
            :set-changes-on-ticket-task="handleSetChangesOnTicketTask"
            :show-snackbar="showSnackbar"
            :ticket="ticket"
            :ticket-task="ticketTask"
        )

</template>

<script lang="ts">
    import Vue from 'vue';

    // Components
    import Form from '@/components/internal_ticket_tasks/form.vue';

    export default Vue.extend({
        data() {
            return {
                dialog: false,
            }
        },
        methods: {
            closeDialog() {
                this.dialog = false;
            },
            handleSetChangesOnTicketTask(isCloseDialog) {
                this.setChangesOnTicketTask(isCloseDialog);

                if (isCloseDialog) {
                    this.closeDialog();
                }
            },
        },
        computed: {
            validTicket() {
                return this.ticket?.ticket_id > 0;
            },
            validTicketTask() {
                return this.ticketTask?.internal_ticket_task_id > 0;
            },
        },
        components: {
            Form,
        },
        props: {
            setChangesOnTicketTask: Function,
            showSnackbar: Function,
            ticket: Object,
            ticketTask: Object,
        },
    });
</script>

<style lang="scss" scoped>
</style>
