<template lang="pug">
    .ma-sm-2(
        v-if="validOrganizationID"
    )
        .text-center(v-if="isLoading")
            v-progress-circular(
                :size='100'
                :width='10'
                color='#0f5b9a'
                indeterminate
                style="margin-top: 100px;"
            )

        template(v-else)
            v-container
                v-form
                    v-row(align="center")
                        v-col(cols='6')
                            v-autocomplete(v-model='searchObj.organizationIds' multiple :items='organizationUsers' clearable label='Organization' item-text="organization_name" item-value="organization_id")
                                template(v-slot:selection='{ item, index }')
                                    v-chip(v-if='index === 0' outlined pill)
                                        v-tooltip(top)
                                            template(v-slot:activator='{ on, attrs }')
                                                span(v-bind="attrs" v-on="on" role="text")
                                                    | {{ item.organization_name | str_limit(20) }}
                                            span
                                                | {{ item.organization_name }}
                                    span.caption(v-if='index === 1')
                                        | (+{{ searchObj.organizationIds.length - 1 }} others)
                        v-col(cols='6')
                            v-text-field(label='VIN' type='text' v-model="searchObj.vehicle_vin" @keydown.enter="search()")

                    v-row(align="center")
                        v-col(cols='6')
                            v-autocomplete(
                                label='Model Year'
                                v-model="searchObj.vehicle_model_years"
                                :items="vehicleModelYears"
                                :disabled="!(vehicleModelYears && vehicleModelYears.length > 0)"
                                clearable
                                validate-on-blur
                                multiple
                            )

                        v-col(cols='6')
                            v-autocomplete(
                                label='Make'
                                v-model="searchObj.vehicle_makes"
                                :items="vehicleMakes"
                                :disabled="!(vehicleMakes && vehicleMakes.length > 0)"
                                clearable
                                validate-on-blur
                                multiple
                            )

                    v-row(align="center")
                        v-col(cols='6')
                            v-autocomplete(
                                label='Model'
                                v-model="searchObj.vehicle_models"
                                :items="vehicleModels"
                                :disabled="!(vehicleModels && vehicleModels.length > 0)"
                                clearable
                                validate-on-blur
                                multiple
                            )

                        v-col(cols='6')
                            v-text-field(label='Stock #' type='text' v-model="searchObj.stock_number" @keydown.enter="search()")

                    v-row(align="center")
                        v-col(cols='12')
                            .text-right
                                v-btn.mr-4(
                                    @click='reset();'
                                )
                                    v-icon mdi-refresh
                                    | Reset
                                v-btn.mr-4(
                                    color='info'
                                    @click='search();'
                                )
                                    v-icon mdi-magnify
                                    | Search

            v-alert(
                v-if="!validVehicleInventories"
                outlined
                type='info'
                dense
                border='left'
                style="margin-top: 16px;"
            )
                | Unable to find any results. Please adjust your search criteria.

            template(v-else)
                DetailsCard(
                    v-for="(vehicleInventory, idx) in vehicleInventories"
                    :key="vehicleInventory.vehicle_inventory_id"
                    :vehicle-inventory="vehicleInventory"
                )

                v-simple-table
                    template(v-slot:default)
                        tbody
                            ShowMoreRow(
                                :handle-show-more="handleShowMore"
                                :loading="isShowMoreLoading"
                                :show-more="showMore"
                            )

</template>

<script lang="ts">
    import Vue from 'vue';

    // Components
    import DetailsCard from '@/components/vehicle_inventories/details_card.vue';
    import ShowMoreRow from '@/components/shared/show_more_row_new.vue';

    // Data
    import {
        GetOrganizationUsers,
        GetVehicleInventories,
        GetDistinctVehicleMakes,
        GetDistinctVehicleModels,
        GetDistinctVehicleModelYears,
    } from "@/data";

    export default Vue.extend({
        data() {
            return {
                isLoading: false,
                isSearched: false,
                isShowMoreLoading: false,
                limit: 100,
                offset: 0,
                organizationUsers: [],
                searchObj: null,
                showMore: true,
                vehicleInventories: [],
                vehicleMakes: [],
                vehicleModels: [],
                vehicleModelYears: [],
            }
        },
        methods: {
            getOrganizationUsers() {
                GetOrganizationUsers().then((response) => {
                    if (!!response.Data && !!response.Data.organization_users && response.Data.organization_users.length > 0) {
                        this.organizationUsers = response.Data.organization_users;
                    }
                });

                return;
            },
            getVehicleInventories() {
                
                // Init
                const options = {
                    inventory_status: (this.inventoryStatus || ''),
                    limit: this.limit,
                    offset: this.offset,
                    stock_number: (this.searchObj?.stock_number || ''),
                    organization_ids: this.searchObj?.organizationIds,
                    vehicle_makes: this.searchObj?.vehicle_makes,
                    vehicle_models: this.searchObj?.vehicle_models,
                    vehicle_model_years: this.searchObj?.vehicle_model_years,
                    vehicle_vin: (this.searchObj?.vehicle_vin || ''),
                }

                if (this.vehicleInventories?.length > 0) {
                    this.isShowMoreLoading = true;
                } else {
                    this.isLoading = true;
                }

                GetVehicleInventories(options).then((response) => {
                    if (response?.Data?.vehicle_inventories?.length > 0) {
                        if (this.vehicleInventories?.length > 0) {
                            this.vehicleInventories = this.vehicleInventories.concat(response.Data.vehicle_inventories);
                        } else {
                            this.vehicleInventories = response.Data.vehicle_inventories;
                        }
                    }

                    if (response.Data?.vehicle_inventories?.length === this.limit) {
                        this.showMore = true;
                    } else {
                        this.showMore = false;
                    }

                    this.isLoading = false;
                });
            },
            handleShowMore() {
                this.offset += this.limit;
                this.getVehicleInventories();
            },
            getVehicleMakes() {
                GetDistinctVehicleMakes().then((response) => {
                    if (response?.Data?.vehicle_makes?.length > 0) {
                        this.vehicleMakes = response.Data.vehicle_makes;
                    }
                });
            },
            getVehicleModels() {
                GetDistinctVehicleModels().then((response) => {
                    if (response?.Data?.vehicle_models?.length > 0) {
                        this.vehicleModels = response.Data.vehicle_models;
                    }
                });
            },
            getVehicleModelYears() {
                GetDistinctVehicleModelYears().then((response) => {
                    if (response?.Data?.vehicle_model_years?.length > 0) {
                        this.vehicleModelYears = response.Data.vehicle_model_years;
                    }
                });
            },
            reinitSearch() {
                this.reset();
                this.search();
            },
            reset() {
                this.isSearched = false;
                this.searchObj = {
                    organizationIds: [this.organizationId],
                    stock_number: null,
                    vehicle_makes: [],
                    vehicle_models: [],
                    vehicle_model_years: [],
                    vehicle_vin: null,
                }
            },
            search() {
                this.isLoading = true;
                this.offset = 0;
                this.vehicleInventories = [];
                this.getVehicleInventories();
            },
        },
        computed: {
            validOrganizationID() {
                return this.organizationId > 0;
            },
            validVehicleInventories() {
                return this.vehicleInventories?.length > 0;
            },
        },
        components: {
            DetailsCard,
            ShowMoreRow,
        },
        props: {
            forceOrganizationUsers: Array,
            inventoryStatus: String,
            organizationId: Number,
        },
        created() {
            this.reinitSearch();
            this.getVehicleModelYears();
            this.getVehicleMakes();
            this.getVehicleModels();
            return this.forceOrganizationUsers?.length > 0 ? (this.organizationUsers = this.forceOrganizationUsers) : this.getOrganizationUsers();
        },
    });
</script>

<style lang="scss" scoped>
    #app {
        .table-cell-padding {
            padding-top: 10px;
            padding-bottom: 10px;
        }
    }
</style>
