import { EventBus, WebsocketEvents } from './websocket_bus';

export enum CommandType {
    Subscribe = "subscribe",
    Unsubscribe = "unsubscribe",
    Message = "message",
    Activity= "activity",
    Authenticate = "authenticate",
    Ping = "ping",
}

export enum MessageType {
    Welcome = "welcome",
    Ping = "ping",
    ConfirmSubscription = "confirm_subscription",
    ConfirmUnsubscription = "confirm_unsubscription",
    RejectSubscription = "reject_subscription",
    RejectUnsubscription = "reject_unsubscription",
    Broadcast = "broadcast",
    Error = "error",
    Notice = "notice",
    AuthenticateChallenge = "authenticate_challenge",
}

export interface ChannelIdentity {
    ID: string | null;
    Type: string;
}

export interface Message {
    ID: string;
    Type: MessageType | string;
    Channel?: ChannelIdentity;
    Data?: any;
    ErrorMessage?: string;
}

export interface Command {
    Channel?: ChannelIdentity;
    Command: string | CommandType;
    Data?: any;
}

export interface Options {
    Url: string;
    DisablePing?: boolean;
    Bus?: EventBus;
    OnOpen?: () => void;
    OnClose?: () => void;
    OnError?: () => void;
}

export class WebsocketClient {
    private socket: WebSocket | null;
    private retryHandle: any;
    private pinger: any;
    private keepAlive: boolean;
    private url: string;
    private creds: any;
    private bus: EventBus;
    private isOpen: boolean;
    private onOpen: () => void | null;
    private onClose: () => void | null;
    private onError: (err: any) => void | null;
    private commands: Command[] = [];

    constructor(options: Options) {
        this.url = options.Url;
        this.keepAlive = !options.DisablePing;
        this.socket = null;
        this.bus = options.Bus || WebsocketEvents;
        this.isOpen = false;
        this.onOpen = options.OnOpen;
        this.onClose = options.OnClose;
        this.onError = options.OnError;
    }

    public open(creds: any) {
        this.creds = creds;
        this.initSocket();
        this.retryConnect()
        this.setupPoller();
    }

    public close() {
        if (this.socket) {
            this.socket.onclose = () => {};
            this.socket.close();
            this.cancelRetry();
            clearInterval(this.pinger);
        }
    }

    public initSocket(): boolean {
        if ((window as any).WebSocket) {
            if (!this.socket || (this.socket && this.socket.readyState === WebSocket.CLOSED)) {
                try {
                    this.socket = null;

                    // Connect to server
                    const socket = new WebSocket(this.url);

                    // Handle close
                    socket.onclose = () => {
                        this.socketClose()
                    };

                    // Handle message
                    socket.onmessage = (evt) => {
                        if (evt.data) {
                            const signal: any = JSON.parse(evt.data);
                            this.handleMessage(signal);
                        }
                    };

                    socket.onerror = (evt) => {
                        this.socketError(evt);
                    };

                    socket.onopen = () => { 
                        this.isOpen = true;
                        if (this.bus) {
                            this.bus.send({ type: 'clientState' }, this.isOpen);
                        }
                        if (this.onOpen) { this.onOpen() }
                    };

                    this.socket = socket;
                    return true;
                } catch (err) {
                    this.socketError(err);
                    return false;
                }
            }
        }
        return false;
    }

    public sendCommand(command: Command): boolean {
        if (this.socket && this.socket.readyState !== WebSocket.OPEN) {
            this.initSocket();
        }
        if (this.socket && this.socket.readyState === WebSocket.OPEN) {
            this.socket.send(JSON.stringify(command));
            return true
        }
        return false;
    }

    public eventBus(): EventBus {
        return this.bus;
    }

    private socketClose() {
        if (this.isOpen) {
            this.isOpen = false;
            if (this.bus) {
                this.bus.send({ type: 'clientState' }, this.isOpen);
            }
            if (this.onClose) { this.onClose() }
            this.retryConnect();
        }
    }

    private socketError(error: any) {
        if (this.onError) {
            this.onError(error);
        }
        return;
    }

    private handleMessage(message: Message) {
        if (message.Type === MessageType.Ping) {
            return;
        } else if (message.Type === MessageType.AuthenticateChallenge) {
            this.sendCommand({
                Command: CommandType.Authenticate,
                Data: this.creds,
            });
            return;
        }
        this.bus.send({
            type: message.Type,
            channel: message.Channel,
        }, message);
    }

    public activity(channel: ChannelIdentity) {
        this.sendCommand({
            Command: CommandType.Message,
            Channel: channel,
        });
    }

    private retryConnect() {
        if (!this.retryHandle) {
            this.retryHandle = setInterval(() => this.initSocket(), 5 * 1000);
        }
        this.initSocket();
    }

    private setupPoller() {
        this.pinger = setInterval(() => {
            if (this.socket && this.socket.readyState === WebSocket.OPEN) {
                if (this.keepAlive) {
                    this.sendCommand({
                        Command: CommandType.Ping,
                    });
                }
            }           
        }, 10 * 1000);
    }

    private cancelRetry() {
        clearInterval(this.retryHandle);
        this.retryHandle = false;
    }

}
