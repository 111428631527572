import { get, post, put } from '.';

export function CreateEleadSubscription(options = {}) {
	return post<any>("/app/api/elead/subscriptions", options);
}

export function UpdateEleadSubscription(id, options = {}) {
    return put<any>("/app/api/elead/subscriptions/" + id, options);
}

export function FindEleadSubscription(key, options = {}) {
	return get<any>("/app/api/elead/subscriptions/" + key, options);
}

export function GetEleadSubscriptions(options = {}) {
	return get<any>("/app/api/elead/subscriptions", options);
}
