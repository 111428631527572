import { get, post, put } from '.';

export function CreateSubscription(options = {}) {
	return post<any>("/app/api/subscriptions", options);
}

export function FindSubscription(key) {
	return get<any>("/app/api/subscriptions/" + key);
}

export function GetSubscriptions(options = {}) {
	return get<any>("/app/api/subscriptions", options);
}

export function UpdateSubscription(id, options = {}) {
	return put<any>("/app/api/subscriptions/" + (id || 0).toString(), options);
}