<template lang="pug">
    v-dialog(v-model="dialog" persistent scrollable max-width='600px' v-if="validDialog")
        v-card.mx-auto
            v-toolbar(dark color='white')
                v-btn(icon light @click='exit();')
                    v-icon mdi-close
                v-toolbar-title.text--primary Change customer's campaign
            v-card-text(style="height: 450px;")
                v-simple-table(style="margin-top: 20px;")
                    template(v-slot:default)
                        thead
                            tr
                                th.text-left(style="width: 10%; max-width: 10%")
                                th.text-left(style="width: 80%; max-width: 80%") Campaign
                                th(style="width: 10%; max-width: 10%")
                        tbody
                            tr(
                                v-for="(campaign, idx) in campaigns"
                                :key="idx"
                            )
                                td.text-left(style="width: 10%; max-width: 10%;") {{ idx + 1 | number }}
                                td.text-left(style="width: 80%; max-width: 80%;") {{ campaign.campaign_name }}
                                td.text-center(style="width: 10%; max-width: 10%;")
                                    v-btn.mx-2.my-md-4(
                                        fab
                                        dark
                                        small
                                        color='green'
                                        :outlined="!isSelectedCampaign(campaign.campaign_id)"
                                        @click="selectedCampaignID = campaign.campaign_id;"
                                    )
                                        v-icon(dark large)
                                            template(v-if="isSelectedCampaign(campaign.campaign_id)") mdi-check
                                            template(v-else) mdi-close

            v-card-actions
                v-spacer
                v-btn(outlined @click='exit();')
                    v-icon mdi-close
                    | Cancel
                v-btn(
                    :disabled="!validSelectedCampaign"
                    :dark="validSelectedCampaign"
                    color='green'
                    @click='confirm();'
                )
                    v-icon mdi-content-save
                    | Update

</template>

<script lang="ts">
    import Vue from 'vue';

    // Data
    import { GetCampaignCustomerOtherAvailableCampaigns } from "@/data";

    export default Vue.extend({
        data() {
            return {
                campaigns: [],
                dialog: true,
                isLoading: false,
                selectedCampaignID: null,
            }
        },
        methods: {
            confirm() {
                if (!this.validSelectedCampaign) {
                    return;
                }

                this.reassignCampaignCustomerCampaign(this.selectedCampaignID);
                this.exit();
            },
            exit() {
                this.dialog = false;
                this.closeSwitchCampaign();
            },
            getCampaignCustomerOtherAvailableCampaigns() {

                // Init
                const options = {
                    account_id: this.currentCampaignCustomer?.account_id,
                }

                this.isLoading = true;
                GetCampaignCustomerOtherAvailableCampaigns(this.currentCampaignCustomer?.campaign_customer_active_id, options).then((response) => {
                    if (response?.Data?.campaigns?.length > 0) {
                        this.campaigns = response?.Data?.campaigns;
                    }

                    this.isLoading = false;
                });
            },
            isSelectedCampaign(campaignID) {
                return campaignID === this.selectedCampaignID;
            },
        },
        computed: {
            validDialog() {
                return this.currentCampaignCustomer?.campaign_customer_active_id > 0 && this.currentCampaignCustomer?.account_id > 0;
            },
            validSelectedCampaign() {
                return this.selectedCampaignID > 0;
            },
        },
        components: {
        },
        props: {
            closeSwitchCampaign: Function,
            currentCampaignCustomer: Object,
            reassignCampaignCustomerCampaign: Function,
        },
        created() {
            this.getCampaignCustomerOtherAvailableCampaigns();
        },
    });
</script>

<style lang="scss" scoped>
</style>
