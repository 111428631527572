<template lang="pug">
    div(v-if="!!currentUser && !!currentUser.account")
        AccountAgent(v-if="currentUser.activated_role_id === 3")
        AccountManager(v-if="currentUser.activated_role_id === 1 || currentUser.activated_role_id === 2 || currentUser.activated_role_id === 4")
        AccountSupervisor(v-if="currentUser.activated_role_id === 5")
        ClientEmployee(v-if="currentUser.activated_role_id === 6")
        OrganizationAgent(v-if="currentUser.activated_role_id === 9")
        OrganizationManager(v-if="currentUser.activated_role_id === 8")
        PurchasedListOnly(v-if="currentUser.activated_role_id === 10")
        WhiteGloveDealer(v-if="currentUser.activated_role_id === 7")
</template>

<script lang="ts">
    import Vue from 'vue';
    import VolieState from '@/store';

    // Components
    import AccountAgent from '@/components/shared/menu_roles/account_agent.vue';
    import AccountManager from '@/components/shared/menu_roles/account_manager.vue';
    import AccountSupervisor from '@/components/shared/menu_roles/account_supervisor.vue';
    import ClientEmployee from '@/components/shared/menu_roles/client_employee.vue';
    import OrganizationAgent from '@/components/shared/menu_roles/organization_agent.vue';
    import OrganizationManager from '@/components/shared/menu_roles/organization_manager.vue';
    import PurchasedListOnly from '@/components/shared/menu_roles/purchased_list_only.vue';
    import WhiteGloveDealer from '@/components/shared/menu_roles/white_glove_dealer.vue';

    export default Vue.extend({
        data() {
            return {
            }
        },
        methods: {
        },
        computed: {
            currentUser() {
                return VolieState.currentUser();
            },
        },
        components: {
            AccountAgent,
            AccountManager,
            AccountSupervisor,
            ClientEmployee,
            OrganizationAgent,
            OrganizationManager,
            PurchasedListOnly,
            WhiteGloveDealer,
        },
        props: {
        },
    });
</script>

<style lang="scss" scoped>
</style>
