import { get, post, put, destroy } from '.';

export function CreateOrganizationDepartment(organization_vid: string, options = {}) {
	return post<any>(`/app/api/organizations/${organization_vid}/departments`, options);
}

export function DestroyOrganizationDepartment(organization_vid: string, vid: string, options = {}) {
	return destroy<any>(`/app/api/organizations/${organization_vid}/departments/${vid}`, options);
}

export function FindOrganizationDepartment(organization_vid: string, vid: string, options = {}) {
	return get<any>(`/app/api/organizations/${organization_vid}/departments/${vid}`, options);
}

export function GetOrganizationDepartments(organization_vid: string, options = {}) {
	return get<any>(`/app/api/organizations/${organization_vid}/departments`, options);
}

export function UpdateOrganizationDepartment(organization_vid: string, vid: string, options = {}) {
	return put<any>(`/app/api/organizations/${organization_vid}/departments/${vid}`, options);
}