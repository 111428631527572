import { get, post, put, destroy } from '.';

export function CreateTelnyxBillingGroup(vid: string, options = {}) {
	return post<any>(`/app/api/accounts/${vid}/telnyx_billing_group`, options);
}

export function DestroyTelnyxBillingGroup(account_vid:string, telnyx_billing_group_id: string, options = {}) {
	return destroy<any>(`/app/api/accounts/${account_vid}/telnyx_billing_group/${telnyx_billing_group_id}`, options);
}

export function GetTelnyxBillingGroupsForAccount(account_vid:string, options = {}) {
	return get<any>(`/app/api/accounts/${account_vid}/telnyx_billing_groups`, options);
}

