<template lang="pug">
    div
        v-icon(color="green" class="sentiment-icon" v-if="sentiment === 'POSITIVE'") mdi-emoticon-happy-outline
        v-icon(color="red" class="sentiment-icon" v-else-if="sentiment === 'NEGATIVE'") mdi-emoticon-sad-outline
        v-icon(color="grey" class="sentiment-icon" v-else) mdi-emoticon-neutral-outline
</template>

<script lang="ts">
import Vue from 'vue';

export default Vue.extend({
    data() {
        return {
        }
    },
    methods: {
    },
    components: {
    },
    props: {
        sentiment: String,
    },
})
</script>