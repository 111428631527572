<template lang="pug">
    div
        v-container.mx-auto
            v-card.mx-lg-auto(width="100%")
                //- v-toolbar.volie-v-toolbar-color(dark)
                //-     v-toolbar-title Transactions

                v-simple-table.table-x-scroll
                    template(v-slot:default)
                        thead.volie-background-color-blue(style="padding-top: 15px;")
                            tr
                                th.white--text Transaction Date
                                th.white--text Vid
                                th.white--text Transaction Type
                                th.white--text Transaction Vid
                        tbody(v-if="validCampaignCommunicationTransactions")
                            tr(v-for='campaignCommunicationTransaction in campaignCommunicationTransactions' :key='campaignCommunicationTransaction.vid')
                                td.no-wrap.sm-width {{ campaignCommunicationTransaction.transaction_date }}
                                td.no-wrap.sm-width {{ campaignCommunicationTransaction.vid }}
                                td.no-wrap.sm-width.text-capitalize {{ campaignCommunicationTransaction.transaction_type }}
                                td.no-wrap.sm-width {{ campaignCommunicationTransaction.transaction_key }}

                        tbody(v-else-if="isLoading")
                            tr
                                td(colspan="4")
                                    .text-center
                                        v-progress-circular(
                                            :size='100'
                                            :width='10'
                                            color='#0f5b9a'
                                            indeterminate
                                            style="margin-top: 75px; margin-bottom: 75px;"
                                        )

            v-alert(v-if="!validCampaignCommunicationTransactions && !isLoading" outlined type='info' dense border='left' style="margin-top: 16px;")
                | There are no campaign communication transactions for this conference

                        

</template>

<script lang="ts">
    import Vue from 'vue';

    export default Vue.extend({
        computed: {
            validCampaignCommunicationTransactions() {
                return this.campaignCommunicationTransactions?.length > 0;
            },
        },
        props: {
            campaignCommunicationTransactions: Array,
            isLoading: Boolean,
        },
        components: {},
    });
</script>

<style lang="scss" scoped>
</style>
