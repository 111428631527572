<template lang="pug">
    .ma-md-4
        .text-center(v-if="isLoadingMain")
            v-progress-circular(
                :size='100'
                :width='10'
                color='#0f5b9a'
                indeterminate
                style="margin-top: 100px;"
            )

        v-alert(
            v-else-if="renderError"
            dark
            dense
            type="error"
        )
            | {{ errorMessage }}

        v-container.overflow-y-auto(v-else)
            v-row(align="center")
                v-col(cols='12')
                    v-form

                        h6.font-weight-bold.list-item-title-overflow.mb-6
                            .text-capitalize.text-overline.text--secondary.text--darken-3 Lead Key
                            CopyableText(:text="prospectLeadKey")

                        v-autocomplete(
                            :items='appointmentSetters'
                            item-text="AppointmentSetterName"
                            item-value="AppointmentSetterId"
                            label='Appointment Setter *'
                            outlined
                            prepend-inner-icon="mdi-account"
                            v-model="autobuyCommunicationDisposition.appointment_setter_id"
                            v-on:change="setSelectedAppointmentSetter(); getBuyerAvailabilities();"
                            :disabled="!validAppointmentSetters"
                            dense
                        )

                        v-radio-group(v-model="autobuyCommunicationDisposition.appointment_type" style="margin-top: 0 !important;")
                            template(v-slot:label)
                                label(style="font-size: 12px;")
                                    | Type
                            v-radio(
                                style="margin-left: 10px;"
                                v-for="(option, idx) in appointmentTypes"
                                :label='option.name'
                                :value="option.value"
                                :key="idx"
                            )

                        v-autocomplete(
                            v-show="isAppointmentTypeOffice"
                            :items='preferredLocations'
                            :item-text="displayLocation"
                            item-value="LocationId"
                            label='Preferred Location *'
                            outlined
                            prepend-inner-icon="mdi-map-marker"
                            v-model="autobuyCommunicationDisposition.preferred_location_id"
                            v-on:change="setSelectedPreferredLocation();"
                            :disabled="!validPreferredLocations"
                            dense
                        )

                        v-autocomplete(
                            :items='renderBuyers'
                            item-text="BuyerName"
                            item-value="BuyerId"
                            label='Buyer *'
                            outlined
                            prepend-inner-icon="mdi-account-convert"
                            v-model="autobuyCommunicationDisposition.buyer_id"
                            v-on:change="setSelectedBuyer(); getBuyerAvailabilities();"
                            :disabled="!validRenderBuyers"
                            dense
                            :error-messages="renderBuyerErrorMessage"
                        )

                        v-autocomplete(
                            :items='sources'
                            item-text="Source"
                            item-value="SourceId"
                            label='Source *'
                            outlined
                            prepend-inner-icon="mdi-account-convert"
                            v-model="autobuyCommunicationDisposition.source_id"
                            :disabled="!validSources"
                            dense
                            v-on:change="setSelectedSource();"
                        )

                        v-menu(
                            v-model='datePickerMenu'
                            :close-on-content-click='false'
                            transition='scale-transition'
                            offset-y
                            max-width='290px'
                            min-width='290px'
                        )
                            template(v-slot:activator='{ on, attrs }')
                                v-text-field(
                                    v-model='formatAppointmentDate'
                                    label='Appointment Date *'
                                    prepend-icon='mdi-calendar'
                                    readonly
                                    v-bind='attrs'
                                    v-on='on'
                                )
                            v-date-picker(
                                v-model='autobuyCommunicationDisposition.appointment_datetime_date'
                                no-title
                                @input='datePickerMenu = false'
                                :min="minDate"
                                v-on:change="getBuyerAvailabilities();"
                            )

                        Timepicker(
                            label="Appointment Time *"
                            :on-change="onChangeTimepicker"
                            :value="autobuyCommunicationDisposition.appointment_datetime_time"
                        )

                        v-divider

                        .text-center(v-if="isLoadingBuyerAvailabilities")
                            v-progress-circular(
                                :size='100'
                                :width='10'
                                color='#0f5b9a'
                                indeterminate
                                style="margin-top: 50px;"
                            )
                        
                        v-alert(
                            v-else-if="!isEligibleToGetBuyerAvailability"
                            dark
                            dense
                            type="info"
                        )
                            | Please select an appointment setter, preferred location, buyer, and appointment datetime to view available appointment slots

                        v-alert(
                            v-else-if="!validBuyerAvailability"
                            dark
                            dense
                            type="error"
                        )
                            | The buyer is not available on the selected date

                        template(v-else-if="validBuyerAvailability")
                            template(v-if="buyerAvailability.Forenoon && buyerAvailability.Forenoon.length > 0")
                                v-radio-group(v-model="autobuyCommunicationDisposition.buyer_availability" style="margin-top: 0 !important;")
                                    template(v-slot:label)
                                        label(style="font-size: 12px;")
                                            | Forenoon
                                    v-radio(
                                        style="margin-left: 10px;"
                                        v-for="(option, idx) in buyerAvailability.Forenoon"
                                        :label='option'
                                        :value="option"
                                        :key="idx"
                                    )

                            template(v-if="buyerAvailability.Afternoon && buyerAvailability.Afternoon.length > 0")
                                v-radio-group(v-model="autobuyCommunicationDisposition.buyer_availability" style="margin-top: 0 !important;")
                                    template(v-slot:label)
                                        label(style="font-size: 12px;")
                                            | Afternoon
                                    v-radio(
                                        style="margin-left: 10px;"
                                        v-for="(option, idx) in buyerAvailability.Afternoon"
                                        :label='option'
                                        :value="option"
                                        :key="idx"
                                    )

</template>

<script lang="ts">
    import Vue from 'vue';
    import VolieState  from "@/store";

    // Components
    import CopyableText from '@/components/shared/copyable_text.vue';
    import Timepicker from "@/components/shared/timepicker.vue";

    // Data
    import {
        Autobuy_GenerateToken,
        Autobuy_GetAppointmentSetters,
        Autobuy_GetBuyerAvailabilities,
        Autobuy_GetBuyers,
        Autobuy_GetPreferredLocations,
        Autobuy_GetSourceByLocation,
        FindProspect,
    } from "@/data";

    // Helpers
    import { time12ToMilitary, timeMilitaryTo12 } from "@/helpers/general";

    export default Vue.extend({
        data() {
            return {
                appointmentSetters: [],
                appointmentTypes: [
                    { name: "Office", value: "none" },
                    { name: "Virtual", value: "virtual" },
                ],
                autobuyCommunicationDisposition: null,
                buyers: [],
                buyerAvailability: {
                    Afternoon: [],
                    Forenoon: [],
                },
                datePickerMenu: false,
                errorMessage: null,
                isLoadingBuyerAvailabilities: false,
                isLoadingMain: false,
                minDate: null,
                preferredLocations: [],
                prospect: null,
                renderBuyers: [],
                selectedAppointmentSetter: null,
                selectedBuyer: null,
                selectedSource: null,
                selectedPreferredLocation: null,
                sources: [],
                token: null,
            }
        },
        methods: {
            buildRenderBuyers() {

                // Reset
                this.renderBuyers = [];

                // Ensure valid buyers
                if (!this.validBuyers) {
                    return;
                }

                // If type office, need valid location selected
                if (this.isAppointmentTypeOffice && !this.validSelectedPreferredLocationID) {
                    return;
                }

                // Loop over buyers and cross-reference location with type
                for (let i = 0; i < this.buyers.length; i++) {

                    // Init
                    const buyer = this.buyers[i];
                    if (!buyer) {
                        continue;
                    }

                    // if (i === 1) {
                    //     debugger;
                    // }
                    
                    // Handle logic depending on type
                    switch(this.autobuyCommunicationDisposition?.appointment_type) {
                    case "none": // office

                        // Ensure city matches
                        if (this.selectedPreferredLocationCity !== buyer["Preffered Location"]) {
                            continue;
                        }

                        // Ensure appointment is office or both
                        if (buyer["Appointment"] !== "Office Appointment" && buyer["Appointment"] !== "Office and Virtual Appointment") {
                            continue;
                        }

                        // Append to rendered
                        this.renderBuyers.push(buyer);
                        break;

                    case "virtual": // virtual

                        // Ensure appointment is virtual or both
                        if (buyer["Appointment"] !== "Virtual Appointment" && buyer["Appointment"] !== "Office and Virtual Appointment") {
                            continue;
                        }

                        // Append to rendered
                        this.renderBuyers.push(buyer);
                        break;
                    }
                }
            },
            cleanupAppointmentSetters(appointmentSetters) {

                // Verify
                if (!(appointmentSetters?.length > 0)) {
                    return [];
                }

                // Sort
                return appointmentSetters.sort(function(x, y) {
                    return (x?.AppointmentSetterName || "").localeCompare(y?.AppointmentSetterName || "");
                });
            },
            cleanupBuyers(buyers) {

                // Verify
                if (!(buyers?.length > 0)) {
                    return [];
                }

                // Sort
                return buyers.sort(function(x, y) {
                    return (x?.BuyerName || "").localeCompare(y?.BuyerName || "");
                });
            },
            cleanupPreferredLocations(preferredLocations) {

                // Verify
                if (!(preferredLocations?.length > 0)) {
                    return [];
                }

                // Sort
                return preferredLocations.sort(function(x, y) {
                    return (x?.Location || "").localeCompare(y?.Location || "");
                });
            },
            cleanupSources(sources) {

                // Verify
                if (!(sources?.length > 0)) {
                    return [];
                }

                // Sort
                return sources.sort(function(x, y) {
                    return (x?.Source || "").localeCompare(y?.Source || "");
                });
            },
            displayLocation(item) {

                // Init
                const names = [];
                if (item?.Location?.length > 0) {
                    names.push(item.Location);
                }

                if (item?.State?.length > 0) {
                    names.push(item.State);
                }

                return names.join(", ");
            },
            findProspect() {

                // Return if we have already loaded prospect
                if (this.validProspect) {
                    return;
                }

                // Return if we don't have a valid prospect ID
                if (!this.validCampaignCustomerProspectID) {
                    return;
                }

                // Load prospect
                FindProspect((this.currentCampaignCustomer?.prospect_id || 0)).then((response) => {
                    if (response?.Data?.prospect?.prospect_id > 0) {
                        this.prospect = response.Data.prospect;
                    }
                });
            },
            formatDate (date) {
                if (!date) {
                    return null;
                }

                const [year, month, day] = date.split('-');
                return `${month}/${day}/${year}`;
            },
            generateToken() {

                // Verify
                if (this.validToken) {
                    return;
                }

                this.isLoadingMain = true;
                Autobuy_GenerateToken().then((response) => {
                    if (response?.Data?.token?.length > 0) {
                        this.token = response.Data.token;
                    }

                    this.isLoadingMain = false;
                });
            },
            getAppointmentSetters() {

                // Verify
                if (!this.validToken || this.validAppointmentSetters) {
                    return;
                }

                // Init
                const options = {
                    token: this.token,
                }

                this.isLoadingMain = true;
                Autobuy_GetAppointmentSetters(options).then((response) => {
                    if (response?.Data?.appointment_setters?.length > 0) {
                        this.appointmentSetters = this.cleanupAppointmentSetters(response.Data.appointment_setters);
                    }

                    this.isLoadingMain = false;
                });
            },
            getBuyerAvailabilities() {

                // First reset existing availabilities in case something changed
                this.buyerAvailability = {
                    Afternoon: [],
                    Forenoon: [],
                };
                this.autobuyCommunicationDisposition.buyer_availability = null;

                // Verify we have the valid fields (need to do it this way in case they remove something after selecting it)
                if (!this.isEligibleToGetBuyerAvailability) {
                    return;
                }

                // Init
                const options = {
                    appointment_date: this.autobuyCommunicationDisposition.appointment_datetime_date,
                    appointment_time: this.autobuyCommunicationDisposition.appointment_datetime_time,
                    appointment_type: this.autobuyCommunicationDisposition.appointment_type,
                    buyer_id: this.autobuyCommunicationDisposition.buyer_id,
                    preferred_location_city: this.autobuyCommunicationDisposition.preferred_location_city,
                    token: this.token,
                }

                // Fetch
                this.isLoadingBuyerAvailabilities = true;
                Autobuy_GetBuyerAvailabilities(options).then((response) => {
                    if (!!response?.Data?.buyer_availability) {
                        this.buyerAvailability = response.Data.buyer_availability;
                    }

                    this.isLoadingBuyerAvailabilities = false;
                });
            },
            getBuyers() {

                // Verify
                if (!this.validToken || this.validBuyers) {
                    return;
                }

                // Init
                const options = {
                    token: this.token,
                }

                this.isLoadingMain = true;
                Autobuy_GetBuyers(options).then((response) => {
                    if (response?.Data?.buyers?.length > 0) {
                        this.buyers = this.cleanupBuyers(response.Data.buyers);
                    }

                    this.isLoadingMain = false;
                });
            },
            getPreferredLocations() {

                // Verify
                if (!this.validToken || this.validPreferredLocations) {
                    return;
                }

                // Init
                const options = {
                    token: this.token,
                }

                this.isLoadingMain = true;
                Autobuy_GetPreferredLocations(options).then((response) => {
                    if (response?.Data?.preferred_locations?.length > 0) {
                        this.preferredLocations = this.cleanupPreferredLocations(response.Data.preferred_locations);
                    }

                    this.isLoadingMain = false;
                });
            },
            getSourceByLocation() {

                // Reset
                this.sources = [];

                // Verify
                if (!this.validToken) {
                    return;
                }

                // Init
                const options = {
                    preferred_location_city: null,
                    token: this.token,
                }

                // Validate appt type + location
                if (this.isAppointmentTypeOffice) {

                    // If type office, need valid location selected
                    if (!this.validSelectedPreferredLocationID) {
                        return;
                    }

                    // Set on options
                    options.preferred_location_city = this.autobuyCommunicationDisposition.preferred_location_city;
                } else { // Virtual appointment
                    options.preferred_location_city = "Virtual";
                }

                Autobuy_GetSourceByLocation(options).then((response) => {
                    if (response?.Data?.sources?.length > 0) {
                        this.sources = this.cleanupSources(response.Data.sources);

                        // Default
                        if (this.validProspectLeadSource) {
                            this.setDefaultLeadSource();
                        }
                    }
                });
            },
            initNew() {
                this.autobuyCommunicationDisposition = {
                    appointment_datetime_date: null,
                    appointment_datetime_time: null,
                    appointment_setter_id: null,
                    appointment_setter_name: null,
                    appointment_type: "none",
                    buyer_availability: null,
                    buyer_id: null,
                    buyer_name: null,
                    preferred_location_city: null,
                    preferred_location_id: null,
                    preferred_location_state: null,
                    source_id: null,
                    source_name: null,
                    time_zone_id: null,
                }
            },
            onAppointmentTypeChange() {

                // Reset
                this.autobuyCommunicationDisposition.buyer_availability = null;
                this.autobuyCommunicationDisposition.buyer_id = null;
                this.autobuyCommunicationDisposition.buyer_name = null;
                this.autobuyCommunicationDisposition.preferred_location_city = null;
                this.autobuyCommunicationDisposition.preferred_location_id = null;
                this.autobuyCommunicationDisposition.preferred_location_state = null;
                this.autobuyCommunicationDisposition.source_id = null;
                this.autobuyCommunicationDisposition.source_name = null;

                // Reset selected form
                this.selectedBuyer = null;
                this.selectedPreferredLocation = null;
                this.selectedSource = null;

                // Reload
                this.buildRenderBuyers();
                this.getBuyerAvailabilities();
                this.getSourceByLocation();
            },
            onChangeTimepicker(time) {
                this.autobuyCommunicationDisposition.appointment_datetime_time = time;

                // Make 12 hour time and autobuy doesn't have a 0 for 1-9 hours
                let twelveHr = timeMilitaryTo12(time);
                if (twelveHr?.length > 0 && twelveHr.charAt(0) === '0') {
                    twelveHr = twelveHr.substring(1);
                }

                // If time matches, then this would have been set from watcher
                if (this.autobuyCommunicationDisposition.buyer_availability?.length > 0 && twelveHr === this.autobuyCommunicationDisposition.buyer_availability) {
                    return;
                }

                // Reload
                this.getBuyerAvailabilities();
            },
            setDefaultLeadSource() {

                // Verify
                if (!this.validSources || !this.validProspectLeadSource) {
                    return;
                }

                // Find index
                const idx = this.sources?.findIndex(i => i.Source === this.prospectLeadSource);
                if (idx >= 0) {
                    this.selectedSource = this.sources[idx];
                }
            },
            setDefaultTimeZone() {

                // Set default time zone ID
                if (this.timeZoneId > 0 && this.currentUser?.time_zones?.length > 0) {
                    const idx = this.currentUser.time_zones.findIndex(i => i.id === this.timeZoneId);
                    if (idx >= 0) {
                        this.autobuyCommunicationDisposition.time_zone_id = this.timeZoneId;
                    }
                }

                // If still invalid, default to eastern
                if (!(this.autobuyCommunicationDisposition.time_zone_id > 0)) {
                    this.autobuyCommunicationDisposition.time_zone_id = 43;
                }
            },
            setMinDate() {
                const tzoffset = (new Date()).getTimezoneOffset() * 60000; //offset in milliseconds
                this.minDate = (new Date(Date.now() - tzoffset)).toISOString().slice(0, -1);
            },
            setSelectedAppointmentSetter() {

                // Verify
                if (!this.validAppointmentSetters || !this.validSelectedAppointmentSetterID) {
                    this.selectedAppointmentSetter = null;
                    return;
                }

                // Find index
                const idx = this.appointmentSetters?.findIndex(i => i.AppointmentSetterId === this.autobuyCommunicationDisposition?.appointment_setter_id);
                if (idx >= 0) {
                    this.selectedAppointmentSetter = this.appointmentSetters[idx];
                } else {
                    this.selectedAppointmentSetter = null;
                }
            },
            setSelectedBuyer() {

                // Verify
                if (!this.validBuyers || !this.validSelectedBuyerID) {
                    this.selectedBuyer = null;
                    return;
                }

                // Find index
                const idx = this.buyers?.findIndex(i => i.BuyerId === this.autobuyCommunicationDisposition?.buyer_id);
                if (idx >= 0) {
                    this.selectedBuyer = this.buyers[idx];
                } else {
                    this.selectedBuyer = null;
                }
            },
            setSelectedSource() {
                
                // Verify
                if (!this.validSources || !this.validSelectedSourceID) {
                    this.selectedSource = null;
                    return;
                }

                // Find index
                const idx = this.sources?.findIndex(i => i.SourceId === this.autobuyCommunicationDisposition?.source_id);
                if (idx >= 0) {
                    this.selectedSource = this.sources[idx];
                } else {
                    this.selectedSource = null;
                }
            },
            setSelectedPreferredLocation() {

                // Verify
                if (!this.validPreferredLocations || !this.validSelectedPreferredLocationID) {
                    this.selectedPreferredLocation = null;
                    return;
                }

                // Find index
                const idx = this.preferredLocations?.findIndex(i => i.LocationId === this.autobuyCommunicationDisposition?.preferred_location_id);
                if (idx >= 0) {
                    this.selectedPreferredLocation = this.preferredLocations[idx];
                } else {
                    this.selectedPreferredLocation = null;
                }
            },
        },
        computed: {
            currentUser() {
                return VolieState.currentUser();
            },
            formatAppointmentDate() {
                return this.formatDate(this.autobuyCommunicationDisposition?.appointment_datetime_date);
            },
            isAppointmentTypeOffice() {
                return this.autobuyCommunicationDisposition?.appointment_type === "none";
            },
            isEligibleToGetBuyerAvailability() {
                return this.validSelectedAppointmentSetterID && this.validSelectedAppointmentType && ((this.isAppointmentTypeOffice && this.validSelectedPreferredLocationID) || !this.isAppointmentTypeOffice) && this.validSelectedBuyerID && this.validNewAppointment;
            },
            prospectLeadKey() {
                return this.validProspect && this.prospect?.lead_key?.length > 0 ? this.prospect.lead_key : null;
            },
            prospectLeadSource() {
                return this.validProspect && this.prospect?.lead_source?.length > 0 ? this.prospect.lead_source : null;
            },
            renderBuyerErrorMessage() {

                switch (this.autobuyCommunicationDisposition?.appointment_type) {
                case "none": // office

                    // If location selected but no buyers
                    if (this.validSelectedPreferredLocationID && !this.validRenderBuyers) {
                        return "Zero buyers available at the selected location";
                    }

                    break;
                
                case "virtual": // virtual

                    // If no buyers
                    if (!this.validRenderBuyers) {
                        return "Zero buyers available for a virtual appointment";
                    }

                    break;
                }

                return "";
            },
            renderError() {
                return this.errorMessage?.length > 0;
            },
            selectedPreferredLocationCity() {
                return this.selectedPreferredLocation?.Location;
            },
            validAppointmentSetters() {
                return this.appointmentSetters?.length > 0;
            },
            validBuyerAvailability() {
                return this.buyerAvailability?.Forenoon?.length > 0 || this.buyerAvailability?.Afternoon?.length > 0;
            },
            validBuyers() {
                return this.buyers?.length > 0;
            },
            validSelectedSourceID() {
                return (this.autobuyCommunicationDisposition?.source_id !== null ? this.autobuyCommunicationDisposition.source_id : -1) >= 0; // Number (for some reason, 0 is an ID we get back)
            },
            validNewAppointment() {
                return !!this.autobuyCommunicationDisposition?.appointment_datetime_date && !!this.autobuyCommunicationDisposition?.appointment_datetime_time && this.autobuyCommunicationDisposition?.time_zone_id > 0;
            },
            validPreferredLocations() {
                return this.preferredLocations?.length > 0;
            },
            validProspect() {
                return this.prospect?.prospect_id > 0;
            },
            validProspectLeadSource() {
                return this.prospectLeadSource?.length > 0;
            },
            validCampaignCustomerProspectID() {
                return this.currentCampaignCustomer?.prospect_id > 0;
            },
            validRenderBuyers() {
                return this.renderBuyers?.length > 0;
            },
            validSelectedAppointmentSetterID() {
                return this.autobuyCommunicationDisposition?.appointment_setter_id?.length > 0; // String
            },
            validSelectedBuyerID() {
                return this.autobuyCommunicationDisposition?.buyer_id?.length > 0; // String
            },
            validSelectedPreferredLocationID() {
                return (this.autobuyCommunicationDisposition?.preferred_location_id !== null ? this.autobuyCommunicationDisposition.preferred_location_id : -1) >= 0; // Number (for some reason, 0 is an ID we get back)
            },
            validSelectedAppointmentType() {
                return this.autobuyCommunicationDisposition?.appointment_type?.length > 0;
            },
            validSources() {
                return this.sources?.length > 0;
            },
            validToken() {
                return this.token?.length > 0;
            },
        },
        components: {
            CopyableText,
            Timepicker,
        },
        props: {
            currentCampaignCustomer: Object,
            onAutobuyCommunicationDispositionChange: Function,
            showSnackbar: Function,
        },
        watch: {
            "autobuyCommunicationDisposition.appointment_type"() {
                this.onAppointmentTypeChange();
            },
            "autobuyCommunicationDisposition.buyer_availability"() {
                if (this.autobuyCommunicationDisposition.buyer_availability?.length > 0) {
                    this.autobuyCommunicationDisposition.appointment_datetime_time = time12ToMilitary(this.autobuyCommunicationDisposition.buyer_availability);
                }
            },
            autobuyCommunicationDisposition: {
                deep: true,
                handler() {
                    this.onAutobuyCommunicationDispositionChange(this.autobuyCommunicationDisposition);
                },
            },
            "currentCampaignCustomer.prospect_id"() {
                this.findProspect();
            },
            selectedAppointmentSetter() {
                this.autobuyCommunicationDisposition.appointment_setter_name = this.selectedAppointmentSetter?.AppointmentSetterName?.length > 0 ? this.selectedAppointmentSetter?.AppointmentSetterName : null;
            },
            selectedBuyer() {
                this.autobuyCommunicationDisposition.buyer_name = this.selectedBuyer?.BuyerName?.length > 0 ? this.selectedBuyer?.BuyerName : null;
            },
            selectedPreferredLocation() {
                this.autobuyCommunicationDisposition.preferred_location_city = this.selectedPreferredLocation?.Location?.length > 0 ? this.selectedPreferredLocation?.Location : null;
                this.autobuyCommunicationDisposition.preferred_location_state = this.selectedPreferredLocation?.State?.length > 0 ? this.selectedPreferredLocation?.State : null;

                // Reset other fields that are dependent
                this.autobuyCommunicationDisposition.buyer_availability = null;
                this.autobuyCommunicationDisposition.buyer_id = null;
                this.autobuyCommunicationDisposition.buyer_name = null;
                this.autobuyCommunicationDisposition.source_id = null;
                this.autobuyCommunicationDisposition.source_name = null;

                // Reload
                this.buildRenderBuyers();
                this.getSourceByLocation();
            },
            selectedSource() {
                this.autobuyCommunicationDisposition.source_id = this.selectedSource?.SourceId > 0 ? this.selectedSource.SourceId : null;
                this.autobuyCommunicationDisposition.source_name = this.selectedSource?.Source?.length > 0 ? this.selectedSource.Source : null;
            },
            token() {

                // Load preferred locations once a valid token is here
                if (this.validToken) {
                    this.getPreferredLocations();
                    this.getBuyers();
                    this.getAppointmentSetters();
                }
            },
        },
        created() {

            // Setup form
            this.initNew();
            this.setDefaultTimeZone();
            this.setMinDate();

            // Generate token
            this.generateToken();
            this.findProspect();
        },
    });
</script>

<style lang="scss" scoped>
</style>
