import { get, post } from '.';

export function Autobuy_GenerateToken(options = {}) {
	return post<any>("/app/api/autobuy/token", options);
}

export function Autobuy_GetAppointmentSetters(options = {}) {
	return get<any>("/app/api/autobuy/appointment_setters", options);
}

export function Autobuy_GetBuyerAvailabilities(options = {}) {
	return get<any>("/app/api/autobuy/buyer_availabilities", options);
}

export function Autobuy_GetBuyers(options = {}) {
	return get<any>("/app/api/autobuy/buyers", options);
}

export function Autobuy_GetPreferredLocations(options = {}) {
	return get<any>("/app/api/autobuy/preferred_locations", options);
}

export function Autobuy_GetSourceByLocation(options = {}) {
	return get<any>("/app/api/autobuy/source_by_location", options);
}