import { post } from '.';

export function RunEleadInstallWorkflow(options = {}) {
	return post<any>("/app/api/elead/workflow/install", options);
}

export function RunEleadLeadSync(options = {}) {
	return post<any>("/app/api/elead/workflow/lead_sync", options);
}

export function RunEleadScheduleWorkflow(options = {}) {
	return post<any>("/app/api/elead/workflow/schedule", options);
}
