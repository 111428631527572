import { get, post } from '.';

export function CompleteEleadScheduledActivity(vid, options = {}) {
    return post<any>("/app/api/elead/activities/" + vid, options);
}

export function CreateEleadActivityV2(eleadSubscriptionVid: string, eleadOpportunityVid: string, options = {}) {
    return post<any>("/app/api/elead/subscriptions/" + eleadSubscriptionVid + "/opportunities/" + eleadOpportunityVid + "/activities/", options);
}

export function CreateEleadActivity(options = {}) {
    return post<any>("/app/api/elead/activities", options);
}

export function FindEleadActivity(eleadSubscriptionVid: string, eleadOpportunityVid: string, vid: string, options = {}) {
    return get<any>("/app/api/elead/subscriptions/" + eleadSubscriptionVid + "/opportunities/" + eleadOpportunityVid + "/activities/" + vid, options )
}

export function GetEleadActivities(eleadSubscriptionVid: string, options = {}) {
    return get<any>("/app/api/elead/subscriptions/" + eleadSubscriptionVid + "/activities/", options )
}

export function ImportEleadActivities(options = {}) {
    return post<any>("/app/api/elead/activities/import", options);
}

export function GetEleadActivitiesForCustomer(id) {
	return get<any>("/app/api/elead/customers/" + (id || 0).toString() + "/activities");
}

export function SyncEleadActivitiesForCustomer(id, options = {}) {
    return post<any>("/app/api/elead/customers/" + (id || 0).toString() + "/activities/sync", options);
}