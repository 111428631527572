import { destroy, get, post, put } from '.';

export function GetCustomFields(options = {}) {
	return get<any>("/app/api/custom_fields", options);
}

export function CreateCustomField(options = {}) {
	return post<any>("/app/api/custom_fields", options);
}

export function UpdateCustomField(id, options = {}) {
    return put<any>("/app/api/custom_fields/" + (id || 0).toString(), options);
}

export function DestroyCustomField(id, options = {}) {
	return destroy<any>("/app/api/custom_fields/" + (id || 0).toString(), options);
}

export function FindOrganizationCustomField(organizationVid: string, vid: string, options = {}) {
	return get<any>(`/app/api/organizations/${organizationVid}/custom_fields/${vid}`, options);
}

