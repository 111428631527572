<template lang="pug">
    .message-bubble-font.rounded-md-lg.max-width-100-percent.text-left.pa-2
        .font-weight-regular.background-transparent.no-padding.text-body-2(:class="dark ? 'text-white' : 'text-black-default'")
            slot

</template>

<script lang="ts">
    import Vue from 'vue';

    export default Vue.extend({
        props: {
            dark: Boolean,
        },
    });
</script>

<style lang="scss" scoped>
</style>
