import { get, put, post, destroy } from '.';

export function GetOrganizationPlugins(options = {}) {
	return get<any>("/app/api/organization_plugins", options);
}

export function FindOrganizationPlugin(vid) {
	return get<any>("/app/api/organization_plugins/" + vid);
}

export function CreateOrganizationPlugins(options = {}) {
	return post<any>("/app/api/organization_plugins", options);
}

export function UpdateOrganizationPlugins(id, options = {}) {
	return put<any>("/app/api/organization_plugins/" + (id || 0).toString(), options);
}

export function DestroyOrganizationPlugin(id, options = {}) {
	return destroy<any>("/app/api/organization_plugins/" + (id || 0).toString(), options);
}
