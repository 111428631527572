import { destroy, get, post, put } from '.';

export function GetCampaignDispositions(options = {}) {
	return get<any>("/app/api/campaign_dispositions", options);
}

export function GetCampaignDispositionsForDisposition(id: string, options = {}) {
	return get<any>(`/app/api/dispositions/${id}/campaign_dispositions`, options)
}

export function GetCommunicationDispositionsForMapping(options = {}) {
	return get<any>("/app/api/campaign_dispositions_for_mapping", options);
}

export function GetAvailableCampaignDispositions(id: string, options = {}) {
	return get<any>(`/app/api/campaign_dispositions/${id}/available_dispositions`, options);
}
export function FindCampaignDisposition(id: string) {
	return get<any>(`/app/api/campaign_dispositions/${id}`);
}

export function CreateCampaignDisposition(options = {}) {
	return post<any>("/app/api/campaign_dispositions", options);
}

export function UpdateCampaignDisposition(id: string, options = {}) {
	return put<any>(`/app/api/campaign_dispositions/${id}`, options);
}

export function UpdateCampaignDispositionOrders(options = {}) {
	return put<any>("/app/api/campaign_disposition_orders", options);
}

export function DestroyCampaignDisposition(id: string) {
	return destroy<any>(`/app/api/campaign_dispositions/${id}`);
}
