<template lang="pug">
    v-card.mx-lg-auto(v-if='validWebhookEvents' width="100%")

        v-data-table.table-x-scroll(
            :headers="headers"
            :items="items"
            item-key="webhook_event_id"
            hide-default-footer
            :items-per-page="-1" 
            no-data-text=""
            :expanded.sync="expanded"
            show-expand
        )

            template(v-slot:item.friendly_created_at="{ item }")
                td.no-wrap.xs-width
                    template(v-if="item.friendly_created_at") {{ item.friendly_created_at }}
                    template(v-else) ---

            template(v-slot:item.friendly_sent_at="{ item }")
                td.no-wrap.xs-width
                    template(v-if="item.friendly_sent_at") {{ item.friendly_sent_at }}
                    template(v-else) ---

            template(v-slot:item.webhook_type="{ item }")
                td.no-wrap.xs-width {{ item.webhook_type | titleize }}

            template(v-slot:item.webhook_url="{ item }")
                td.no-wrap.xs-width
                    template(v-if="item.webhook_url") {{ item.webhook_url | tel }}
                    template(v-else) ---

            template(v-slot:item.success="{ item }")
                td.no-wrap
                    v-icon.green--text(v-if="item.success") mdi-check
                    v-icon.error--text(v-else) mdi-close

            template(v-slot:expanded-item="{ headers, item }")
                td(:colspan="headers.length" style="padding: 0px;")
                    v-card(style="margin: 15px;")
                        div(style="padding: 5px;")

                            v-simple-table
                                template(v-slot:default)
                                    tbody
                                        tr
                                            td(style="max-width: 20%; width: 20%;")
                                                .text-capitalize.text-overline.text--secondary.text--darken-3 Vid
                                                template {{ item.vid }}
                                            td(style="max-width: 16%; width: 16%;")
                                                .text-capitalize.text-overline.text--secondary.text--darken-3 Request Method
                                                .text-uppercase {{ item.request_method ? item.request_method : '---' }}
                                            td(style="max-width: 16%; width: 16%;")
                                                .text-capitalize.text-overline.text--secondary.text--darken-3 Sent
                                                v-icon.green--text(v-if="item.is_sent") mdi-check
                                                v-icon.error--text(v-else) mdi-close
                                        tr
                                            td(style="max-width: 16%; width: 16%;")
                                                .text-capitalize.text-overline.text--secondary.text--darken-3 Response Code
                                                | {{ item.response_code ? item.response_code : '---' }}
                                            td(style="max-width: 16%; width: 16%;")
                                                .text-capitalize.text-overline.text--secondary.text--darken-3 Response Time
                                                template(v-if='item.response_total_time') {{ item.response_total_time }} secs
                                                template(v-else) ---
                                            td(style="max-width: 16%; width: 16%;")
                                                .text-capitalize.text-overline.text--secondary.text--darken-3 Timed Out
                                                v-icon.green--text(v-if="item.timed_out") mdi-check
                                                v-icon.error--text(v-else) mdi-close

                                        tr
                                            td(colspan='6' style="max-width: 100%; width: 100%;")
                                                .text-capitalize.text-overline.text--secondary.text--darken-3 Request Headers
                                                template(v-if='item.request_headers') 
                                                    pre {{ item.request_headers | prettify_json }}
                                                template(v-else) ---
                                        tr
                                            td(colspan='6' style="max-width: 100%; width: 100%;")
                                                .text-capitalize.text-overline.text--secondary.text--darken-3 Request Params
                                                template(v-if='item.request_params') 
                                                    pre {{ item.request_params | prettify_json }}
                                                template(v-else) ---
                                        tr
                                            td(colspan='6' style="max-width: 100%; width: 100%;")
                                                .text-capitalize.text-overline.text--secondary.text--darken-3 Response Headers
                                                template(v-if='item.response_headers') 
                                                    pre {{ item.response_headers | prettify_json }}
                                                template(v-else) ---
                                        tr
                                            td(colspan='6' style="max-width: 100%; width: 100%;")
                                                .text-capitalize.text-overline.text--secondary.text--darken-3 Response Body
                                                template(v-if='item.response_body') 
                                                    pre {{ item.response_body | prettify_json }}
                                                template(v-else) ---


            template(v-slot:loading)
                .text-center
                    v-progress-circular(
                        :size='100'
                        :width='10'
                        color='#0f5b9a'
                        indeterminate
                        style="margin-top: 75px; margin-bottom: 75px;"
                    )

</template>

<script lang="ts">
    import Vue from 'vue';

    export default Vue.extend({
        data() {
            return {
                expanded: [],
                headers: [
                    {
                        text: 'Datetime',
                        align: 'start',
                        sortable: false,
                        value: 'friendly_created_at',
                        class: "volie-background-color-blue white--text",
                    },
                    {
                        text: 'Sent At',
                        align: 'start',
                        sortable: false,
                        value: 'friendly_sent_at',
                        class: "volie-background-color-blue white--text",
                    },
                    {
                        text: 'Webhook Type',
                        align: 'start',
                        sortable: false,
                        value: 'webhook_type',
                        class: "volie-background-color-blue white--text",
                    },
                    {
                        text: 'Webhook Url',
                        align: 'start',
                        sortable: false,
                        value: 'webhook_url',
                        class: "volie-background-color-blue white--text",
                    },
                    {
                        text: 'Success',
                        align: 'start',
                        sortable: false,
                        value: 'success',
                        class: "volie-background-color-blue white--text",
                        width: '75px',
                    },
                    { 
                        text: '',
                        align: 'start',
                        sortable: false,
                        value: 'data-table-expand' ,
                        class: "volie-background-color-blue white--text",
                    },
                ],
            }
        },
        methods: {
        },
        computed: {
            items() {
                if (this.webhookEvents?.length > 0) {
                    return this.webhookEvents;
                }

                return [];
            },
            validWebhookEvents() {
                return this.webhookEvents?.length > 0;
            },
        },
        props: {
            webhookEvents: Array,
        },
    });
</script>

<style lang="scss" scoped>
</style>
