<template lang="pug">
    v-card.mx-lg-auto.mt-md-4.mb-md-4(outlined width="100%" v-if="validCommunicationDisposition")
        v-toolbar(flat)
            v-btn(
                color="primary"
                icon
                ripple
                @click="showDetails = !showDetails;"
            )
                v-icon(v-if="showDetails === true")
                    | mdi-chevron-down
                v-icon(v-else)
                    | mdi-chevron-right

            v-toolbar-title
                | {{ showCampaign ? communicationDisposition.campaign_name : communicationDisposition.disposition_description }}
                br
                span.text-body-2
                    | {{ showCampaign ? communicationDisposition.disposition_description: communicationDisposition.notes }}
            v-spacer
            .text-caption.text-right.m-l-5
                strong.text-uppercase {{ communicationDisposition.communication_channel }}
                br
                | {{ communicationDisposition.friendly_created_at_date }}

        template(v-if="showDetails === true")
            v-divider
            v-card-text
                v-simple-table
                    template(v-slot:default)
                        tbody
                            tr
                                td
                                    .text-capitalize.text-overline.text--secondary.text--darken-3 Organization
                                    .cell-value-spacing {{ communicationDisposition.organization_name }}
                            tr
                                td
                                    .text-capitalize.text-overline.text--secondary.text--darken-3 Campaign
                                    .cell-value-spacing(v-if="communicationDisposition.campaign_name && communicationDisposition.campaign_name.length > 0") {{ communicationDisposition.campaign_name }}
                                    .cell-value-spacing(v-else) N/a
                            tr
                                td
                                    .text-capitalize.text-overline.text--secondary.text--darken-3 Datetime
                                    .cell-value-spacing {{ communicationDisposition.friendly_created_at_time }}
                            tr
                                td
                                    .text-capitalize.text-overline.text--secondary.text--darken-3 Disposition
                                    .cell-value-spacing(v-if="communicationDisposition.disposition_description && communicationDisposition.disposition_description.length > 0") {{ communicationDisposition.disposition_description }}
                                    .cell-value-spacing(v-else) N/a
                            tr
                                td
                                    .text-capitalize.text-overline.text--secondary.text--darken-3 Communication Channel
                                    .cell-value-spacing.text-capitalize(v-if="communicationDisposition.communication_channel && communicationDisposition.communication_channel.length > 0") {{ communicationDisposition.communication_channel }}
                                    .cell-value-spacing(v-else) N/a
                            tr
                                td
                                    .text-capitalize.text-overline.text--secondary.text--darken-3 Notes
                                    .cell-value-spacing(v-if="communicationDisposition.notes && communicationDisposition.notes.length > 0") {{ communicationDisposition.notes }}
                                    .cell-value-spacing(v-else) N/a

</template>

<script lang="ts">
    import Vue from 'vue';
    export default Vue.extend({
        data() {
            return {
                showDetails: false,
            }
        },
        methods: {
        },
        computed: {
            validCommunicationDisposition() {
                return this.communicationDisposition?.communication_disposition_id > 0;
            },
        },
        components: {
        },
        props: {
            communicationDisposition: Object,
            showCampaign: Boolean,
        },
    });
</script>

<style lang="scss" scoped>
    #app {
        .cell-value-spacing {
            padding: 0 0 5px 0;
        }
        .m-l-5 {
            margin-left: 5px;
        }
    }
</style>