import { post, put } from '.';

export function AcceptCallTransfer(id, options = {}) {
	return put<any>("/app/api/call_transfers/" + (id || 0).toString() + "/accept", options);
}

export function AssignNextInboundCallTransfer(options = {}) {
	return put<any>("/app/api/call_transfers/next", options);
}

export function BridgeCallTransfer(id) {
	return put<any>("/app/api/call_transfers/" + (id || 0).toString() + "/bridge", {});
}

export function CancelCallTransfer(id) {
	return put<any>("/app/api/call_transfers/" + (id || 0).toString() + "/cancel", {});
}

export function CreateCallTransfer(options = {}) {
	return post<any>("/app/api/call_transfers", options);
}

export function DeclineCallTransfer(id, options = {}) {
	return put<any>("/app/api/call_transfers/" + (id || 0).toString() + "/decline", options);
}

export function SwapCallTransferHoldStatus(id: string, options = {}) {
	return put<any>(`/app/api/call_transfers/${id}/swap_hold`, options);
}