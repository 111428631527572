import Vue from 'vue';
import Vuex, { Store, StoreOptions } from 'vuex';
import { GetMe, Me, GetSharedLink_Me, SharedLink, cacheSet, NotAuthorized } from '../data';
import { WebsocketClient } from '../rac-client';

export const RealtimeUri = process.env.VUE_APP_REALTIME_URL;

Vue.use(Vuex);

export let token = JSON.parse(window.localStorage.getItem('volie:token'));

interface Message {
    Message: string;
    Open?: boolean;
    Type: string;
    Timer: number;
}

interface State {
    BaseRailsUrl: string;
    CurrentUser: Me;
    Snacks: Message[];
    Realtime: WebsocketClient;
    CurrentTime: Date;
    SharedLink: SharedLink;
}

function newStore(): StoreOptions<any> {
    return {
        state: {
            BaseRailsUrl: null,
            BaseDashboardSummaries: [],
            Countries: [],
            CurrentUser: null,
            CurrentUserClock: null,
            Snacks: [],
            Realtime: new WebsocketClient({
                Url: RealtimeUri,
            }),
            CurrentAgent: null,
            CurrentAgentTimer: null,
            CurrentTime: new Date(),
            SharedLink: null,
            UserDomInteraction: false,
            WhiteLabel: null,
        },
        mutations: {
            pushSnack(state, snack: Message) {
                state.Snacks.push(snack);
            },
            popSnack(state, snack: string) {
                state.Snacks.pop();
            },
            setCountries(state, countries) {
                state.Countries = countries?.length > 0 ? countries : [];
            },
            setCurrentUser(state, me) {
                state.CurrentUser = me;
                if (me) {
                    state.Realtime.open(me.realtime_token);
                } else {
                    state.Realtime.close();
                }
            },
            setCurrentUserClock(state, clock) {
                state.CurrentUserClock = clock;
            },
            setCurrentTime(state, time: Date) {
                state.CurrentTime = time;
            },
            setCurrentAgent(state, currentAgent) {
                state.CurrentAgent = currentAgent;
            },
            setCurrentAgentTimer(state, timer) {
                state.CurrentAgentTimer = timer;
            },
            setBaseDashboardSummaries(state, bases) {
                state.BaseDashboardSummaries = bases;
            },
            setSharedLink(state, sharedLink) {
                state.SharedLink = sharedLink;
            },
            setUserDomInteraction(state) {
                state.UserDomInteraction = true;
            },
            setWhiteLabel(state, me) {

                if (process.env.NODE_ENV !== 'production') {
                    document.title = 'Volie';
                }

                // set to system default
                let baseRailsUrl = process.env.VUE_APP_RAILS_VOLIE_BASE_URL;

                // if valid me find window domain name
                if (me?.user_id > 0 && window?.location?.host?.length > 0) {

                    // set domin to window domain name
                    const domain = window.location.host;

                    // check that white_label was returned and domain isn't system default domain
                    if (me?.account?.white_label?.white_label_id > 0 && domain != process.env.GO_URL_DOMAIN) {

                        // set white label
                        const whiteLabel = me.account.white_label;
                        state.WhiteLabel = me.account.white_label;

                        if (process.env.NODE_ENV !== 'production') {

                            // update page title / on tab
                            if (whiteLabel.page_title?.length > 0) {
                                document.title = whiteLabel.page_title;

                            }

                            // update favicon
                            if (whiteLabel.main_favicon_url?.length > 0) {
                                const favicon: any = document.getElementById("favicon");
                                favicon.href = whiteLabel.main_favicon_url;
                            }
                        }


                        // if match, then use white label urls
                        if (whiteLabel.external_base_go_app_url === domain) { // check external
                            baseRailsUrl = "//" + whiteLabel.external_base_rails_app_url;
                        } else if (whiteLabel.internal_base_go_app_url === domain) { // check internal
                            baseRailsUrl = "//" + whiteLabel.internal_base_rails_app_url;
                        }
                    }
                }

                state.BaseRailsUrl = baseRailsUrl;
            },
        },
    }
}

class VolieStore {
    store: Store<any>;

    constructor(initialData: StoreOptions<any>) {
        this.store = new Vuex.Store(initialData);

        this.websocketClient().eventBus().onSend = (args: any[]) => {
            try {
                window.sessionStorage.setItem("last_rac", JSON.stringify(args));
            } catch (e) {
                console.error(e)
            }
        }

        this.websocketClient().eventBus().subscribe({
            type: 'broadcast',
            channel: {
                Type: "agent",
                ID: null,
            },
        }, this.handleCurrentAgent.bind(this));

        this.websocketClient().eventBus().subscribe({
            type: 'broadcast',
            channel: {
                Type: "accountUserUpdate",
                ID: null,
            },
        }, this.handleAccountUserUpdate.bind(this));

        this.websocketClient().eventBus().subscribe({
            type: 'broadcast',
            channel: {
                Type: "todayAccountUserStats",
                ID: null,
            },
        }, this.onTodayAccountUserStats.bind(this));

        this.websocketClient().eventBus().subscribe({
            type: 'broadcast',
            channel: {
                Type: "baseDashboardSummaryUpdates",
                ID: null,
            },
        }, this.handleBaseDashboardSummaryUpdates.bind(this));

        setInterval(() => {
            this.store.commit('setCurrentTime', new Date());
            this.store.commit('setCurrentUserClock', this.buildCurrentUserClock());
            this.store.commit('setCurrentAgentTimer', this.buildCurrentAgentTimer());
        }, 1000);
    }

    baseRailsUrl() {
        return this.store.state.BaseRailsUrl;
    }

    load(sharedLinkMode) {

        // If shared link mode, render different promise
        if (sharedLinkMode === true) {
            return Promise.all([
                this.initSharedLink(),
                this.initUserDomInteractionTracker(),
            ]);
        }

        return Promise.all([
            this.initUser(),
            this.initUserDomInteractionTracker(),
        ]);
    }

    buildCurrentAgentTimer() {
        const currentAgent = this.currentAgent();
        if (!!currentAgent && currentAgent.agent_id > 0 && !!currentAgent.created_at) {
            const createdAt = new Date(currentAgent.created_at);
            const currentTime = new Date();
            return (Math.floor(currentTime.getTime() - createdAt.getTime()) / 1000);
        }

        return -1;
    }

    buildCurrentUserClock() {
        const currentUser = this.currentUser();
        if (!!currentUser && currentUser.user_id > 0 && !!currentUser.time_zone && currentUser.time_zone.id > 0) {

            const clock = {} as any;
            clock.timeZoneId = currentUser.time_zone.id;
            clock.timeZoneAbbreviation = currentUser.time_zone.abbreviation;
            clock.localTime = new Date();
            clock.utcTime = clock.localTime.getTime() + (clock.localTime.getTimezoneOffset() * 60000);
            clock.currentTime = (new Date(clock.utcTime + (3600000 * (currentUser.time_zone.zone_offset))));
            return clock;
        }

        return null;
    }

    pushSnack(message: string, type: string) {
        this.store.commit('pushSnack', { Message: message, Type: type, Open: true, Timer: 3000 });
    }

    popSnack() {
        this.store.commit('popSnack');
    }

    baseDashboardSummaries() {
        return this.store.state.BaseDashboardSummaries || [];
    }

    countries() {
        return this.store.state.Countries;
    }

    currentAgent() {
        return this.store.state.CurrentAgent;
    }

    currentAgentTimer() {
        return this.store.state.CurrentAgentTimer;
    }

    currentUser() {
        return this.store.state.CurrentUser;
    }

    currentUserClock() {
        return this.store.state.CurrentUserClock;
    }

    currentTime() {
        return this.store.state.CurrentTime;
    }

    sharedLink() {
        return this.store.state.SharedLink;
    }

    websocketClient(): WebsocketClient {
        return this.store.state.Realtime;
    }

    setToken(t: string): Promise<any> {
        window.localStorage.setItem('volie:token', JSON.stringify(t));
        token = t;
        return this.initUser();
    }

    handleBaseDashboardSummaryUpdates(message) {

        // Load and verify current user
        const currentUser = this.currentUser();
        if (!currentUser || !(currentUser.user_id > 0) || !(currentUser.activated_account_id > 0)) {
            return;
        }

        // Load agent push data
        const data = message?.Data;
        if (!data || !(data.base_dashboard_summary?.id > 0)) {
            return;
        }

        // Verify account and user match
        if (data?.base_dashboard_summary?.account_id !== currentUser.activated_account_id || data?.base_dashboard_summary?.user_id !== currentUser.user_id) {
            return;
        }

        // Load base dashboard summaries
        const baseDashboardSummaries = this.baseDashboardSummaries();

        // If we already have summaries, need to loop over and replace with updates
        if (baseDashboardSummaries?.length > 0) {

            // Init
            let foundMatch = false;

            // Loop over and look for a match
            for (let i = 0; i < baseDashboardSummaries.length; i++) {

                // Init
                const base = baseDashboardSummaries[i];
                if (!base || !(base?.base_dashboard_summary?.id > 0)) {
                    continue;
                }

                // Check to see if base matches data from message
                if (base.base_dashboard_summary?.id !== data.base_dashboard_summary?.id) {
                    continue;
                }

                // Set properties
                Object.assign(base.base_dashboard_summary, data.base_dashboard_summary);

                // Loop over and fill in (if possible)
                if (base.dashboard_summaries?.length > 0) {

                    // Loop over targets
                    for (let j = 0; j < base.dashboard_summaries.length; j++) {

                        // Init
                        const target = base.dashboard_summaries[j];
                        if (!target || !(target?.id > 0)) {
                            continue;
                        }

                        // Loop over new
                        for (let k = 0; k < data.dashboard_summaries.length; k++) {

                            // Init
                            const source = data.dashboard_summaries[k];
                            if (!source || !(source?.id > 0)) {
                                continue;
                            }

                            // Check if the source ID matches the target
                            if (source.id !== target.id) {
                                continue;
                            }

                            // Set and break out
                            Object.assign(target, source);
                            break;
                        }
                    }
                } else {
                    base.dashboard_summaries = data.dashboard_summaries;
                }

                foundMatch = true;
            }

            // Push new object onto summaries if we didn't find match
            if (!foundMatch) {
                baseDashboardSummaries.push(data);
            }

        } else { // Otherwise, just init a new array with the data as the first object
            baseDashboardSummaries.push(data);
        }

        this.store.commit('setBaseDashboardSummaries', baseDashboardSummaries);
    }

    handleCurrentAgent(message) {

        // Load and verify current user
        const currentUser = this.currentUser();
        if (!currentUser || !(currentUser.user_id > 0) || !(currentUser.activated_account_id > 0)) {
            return;
        }

        // Load current agent
        const currentAgent = this.currentAgent();

        // Load agent push data
        const data = message?.Data;
        if (!data || !(data.agent_id > 0)) {
            return;
        }

        // Verify account and user match
        if (data?.account_id !== currentUser.activated_account_id || data?.user_id !== currentUser.user_id) {
            return;
        }

        // If current agent is present, verify latest push data is newer than the existing data
        if ((currentAgent?.agent_id || 0) > data.agent_id) {
            return;
        }

        // Store since we know this is the latest agent record and therefore is the "current"
        this.store.commit('setCurrentAgent', data);
    }

    handleAccountUserUpdate(message) {

        // Load and verify current user
        const currentUser = this.currentUser();
        if (!currentUser || !(currentUser.user_id > 0) || !(currentUser.activated_account_id > 0)) {
            return;
        }

        const data = message?.Data;
        if (data?.account_id === currentUser.activated_account_id && data?.user_id === currentUser.user_id && data?.account_user_id === currentUser?.account_user?.account_user_id) {

            // Set on current user
            currentUser.account_user = data;

            // Set in store
            this.store.commit('setCurrentUser', currentUser);
        }

        return;
    }

    onTodayAccountUserStats(message) {

        // Verify legit account user
        const accountUser = message?.Data;
        if (!(accountUser?.account_user_id > 0) || !(accountUser?.user_id > 0) || !(accountUser?.account_id > 0)) {
            return;
        }

        // Load and verify current user
        const currentUser = this.currentUser();
        if (!currentUser || !(currentUser.user_id > 0) || !(currentUser.activated_account_id > 0)) {
            return;
        }

        // Verify account user matches
        if (accountUser.account_user_id !== currentUser.account_user?.account_user_id) {
            return;
        }

        // Set fields
        currentUser.account_user.appointments_created_today = accountUser.appointments_created_today;
        currentUser.account_user.inbound_calls_today = accountUser.inbound_calls_today;
        currentUser.account_user.outbound_calls_today = accountUser.outbound_calls_today;
        currentUser.account_user.total_assigned_return_message_campaign_customers = accountUser.total_assigned_return_message_campaign_customers;
        currentUser.account_user.total_calls = accountUser.total_calls;
        currentUser.account_user.unresponded_to_incoming_message_at = accountUser.unresponded_to_incoming_message_at;
        currentUser.account_user.unresponded_to_incoming_message_id = accountUser.unresponded_to_incoming_message_id;

        // Set in store
        this.store.commit('setCurrentUser', currentUser);
    }

    reloadUser() {
        return this.initUser();
    }

    initUser(): Promise<any> {
        return new Promise<any>(
            (resolve, reject) => {
                // if (!token) {
                //     this.store.commit('setCurrentUser', null);
                //     resolve(null);
                //     return;
                // }

                // set domain from window host
                const domain = window?.location?.host || '';

                GetMe({ domain: domain }).
                    then((x) => {
                        this.store.commit('setWhiteLabel', x.Data.me)
                        this.store.commit('setCurrentUser', x.Data.me);
                        this.store.commit('setCurrentAgent', x.Data.current_agent);
                        this.store.commit('setBaseDashboardSummaries', x.Data.base_dashboard_summaries);
                        this.store.commit('setCountries', x.Data.countries);
                        resolve(null);
                    }).catch((x) => {
                        this.store.commit('setWhiteLabel', null)
                        this.store.commit('setCurrentUser', null);
                        this.store.commit('setCurrentAgent', null);
                        this.store.commit('setBaseDashboardSummaries', []);
                        this.store.commit('setCountries', []);
                        if (x && x.Error) {
                            this.pushSnack(x.Error, 'error');
                        }
                        cacheSet('token', null);
                        if (x == NotAuthorized) {
                            reject(x);
                            return
                        }
                        reject(null);
                    });
            },
        );
    }

    initSharedLink() {
        return new Promise<any>(
            (resolve, reject) => {
                GetSharedLink_Me().
                    then((x) => {
                        this.store.commit('setSharedLink', x.Data.shared_link);
                        this.store.commit('setCountries', x.Data.countries);
                        this.store.commit('setWhiteLabel', null);
                        resolve(null);
                    }).catch((x) => {
                        this.store.commit('setSharedLink', null);
                        this.store.commit('setCountries', []);
                        this.store.commit('setWhiteLabel', null);
                        if (x && x.Error) {
                            this.pushSnack(x.Error, 'error');
                        }
                        resolve(null);
                    });
            },
        );
    }

    userDomInteraction() {
        return this.store.state.UserDomInteraction;
    }

    whiteLabel() {
        return this.store.state.WhiteLabel;
    }

    handleWindowClick() {

        // Set to true
        this.store.commit('setUserDomInteraction');

        // Remove event listener so we do not keep checking over and over again
        window.removeEventListener('click', this.handleWindowClick.bind(this));
    }

    initUserDomInteractionTracker() {
        window.addEventListener('click', this.handleWindowClick.bind(this));
    }
}

export const instanced = new VolieStore(newStore());

export default instanced;

