import { get, post } from '.';

export function GetTekionDeals(options = {}) {
	return get<any>("/app/api/tekion/deals", options);
}

export function ImportTekionDeals(options = {}) {
	return post<any>("/app/api/tekion/deals/import", options);
}

export function GetTekionDeal(key) {
	return get<any>("/app/api/tekion/deals/" + key);
}
