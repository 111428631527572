import { post, put, get, destroy } from '.';

export function GetOrganizationPostalCodes(options = {}) {
	return get<any>("/app/api/organization_postal_codes", options);
}

export function DestroyOrganizationPostalCode(id) {
	return destroy<any>("/app/api/organization_postal_codes/" + (id || 0).toString());
}

export function CreateOrganizationPostalCode(options = {}) {
	return post<any>("/app/api/organization_postal_codes", options);
}

export function FindOrganizationPostalCode(vid) {
	return get<any>("/app/api/organization_postal_codes/" + (vid || 0).toString());
}

export function UpdateOrganizationPostalCode(id, options = {}) {
	return put<any>("/app/api/organization_postal_codes/" + (id || 0).toString(), options);
}
