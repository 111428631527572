<template lang="pug">
    .ma-sm-2(v-if="validCustomer")
        v-snackbar(
            v-model='snackbar.show'
            :color='snackbar.color'
            :timeout="snackbar.timeout"
            top
        )
            | {{ snackbar.text }}
            v-btn(dark text @click='snackbar.show = false')
                | Close

        v-container.mx-auto(v-if="!isLoading")
            .text-subtitle-2.subtitle-2-padding.text-center
                | Elead Activities
            
            .text-right(style='padding-top: 15px;')
                v-btn(:loading='isSyncing' outlined color="green" small light @click='syncEleadActivitiesForCustomer();')
                    v-icon mdi-sync
                    | Sync Activities

            EleadActivityDetails(
                v-if="validEleadActivities"
                v-for='eleadActivity in eleadActivities'
                :key='eleadActivity.elead_activity_id'
                :elead-activity="eleadActivity"
            )

            v-alert(outlined type='warning' dense border='left' style="margin-top: 16px;" v-if="!validEleadActivities")
                | Customer does not have any elead activities

        .text-center(v-if="isLoading")
            v-progress-circular(
                :size='100'
                :width='10'
                color='#0f5b9a'
                indeterminate
                style="margin-top: 100px;"
            )

</template>

<script lang="ts">
	import Vue from 'vue';
    import VolieState from '@/store';
	
    // Components
    import EleadActivityDetails from '@/components/elead_activities/elead_activity_details.vue';
	
    // Data
	import { GetEleadActivitiesForCustomer, SyncEleadActivitiesForCustomer } from "@/data";

    export default Vue.extend({
        data() {
            return {
                bus: VolieState.websocketClient().eventBus(),
                eleadActivities: [],
                isLoading: false,
                isSyncing: false,
                snackbar: {
                    show: false,
                    timeout: 2500,
                },
            }
        },
        methods: {
            getEleadActivitiesForCustomer() {
                if (!this.validCustomer) {
                    return;
                }

                const customerId = this.currentCampaignCustomer.customer_id
                this.isLoading = true;

                GetEleadActivitiesForCustomer(customerId).then((response) => {
                    if (response?.Data?.elead_activities?.length > 0) {
                        this.eleadActivities = response.Data.elead_activities;
                    }
                    this.isLoading = false;
                });
            },
            showSnackbar(color, text) {
                if (color?.length > 0 && text?.length > 0) {
                    this.snackbar.color = color;
                    this.snackbar.text = text;
                    this.snackbar.show = true;
                }
            },
            onEleadActivityByCustomerRefresh(response) {

                // Verify
                const data = response?.Data;
                if (!(data?.customer_id > 0 && data?.elead_activities?.length > 0)) {
                    return;
                }

                // Verify customer is current customer
                if (!(data.customer_id === this.currentCampaignCustomer.customer_id)) {
                    return;
                }

                // Set elead activities
                this.eleadActivities = response.Data.elead_activities;

                // Set button to be pressable again / no longer loading
                this.isSyncing = false;

                // Print success
                this.showSnackbar("green", "Successfully synced elead activities");
            },
            syncEleadActivitiesForCustomer() {
                const id = this.currentCampaignCustomer.customer_id

                this.isSyncing = true;
                SyncEleadActivitiesForCustomer(id).then((response) => {
                    if (response?.Error === null) {
                        this.showSnackbar("green", "Elead activities for customer are syncing")
                    } else if (response?.Error?.length > 0) {
                        this.showSnackbar("error", response.Error);
                        this.isSyncing = false;
                    }

                    // wait for websocket to tell us finished syncing
                    // this.isSyncing = false;
                })
            },
        },
        computed: {
            validEleadActivities() {
                return this.eleadActivities?.length > 0;
            },

            validCustomer() {
                return this.currentCampaignCustomer?.account_id > 0 && this.currentCampaignCustomer?.customer_id > 0 && this.currentCampaignCustomer?.organization_id > 0;
            },
        },
        components: {
            EleadActivityDetails,
        },
        props: {
            currentCampaignCustomer: Object,
        },
        created() {
            this.bus.subscribe({ type: "broadcast" , channel: { ID: null, Type: "eleadActivityByCustomerRefresh" }}, this.onEleadActivityByCustomerRefresh);
            this.getEleadActivitiesForCustomer();
        },
        destroyed() {
            this.bus.unsubscribe({ type: "broadcast" , channel: { ID: null, Type: "eleadActivityByCustomerRefresh" }}, this.onEleadActivityByCustomerRefresh);
        },
    });
</script>

<style lang="scss" scoped>
</style>
