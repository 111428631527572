<template lang="pug">
    div
        v-container.mx-auto
            v-card.mx-lg-auto(width="100%")
                //- v-toolbar.volie-v-toolbar-color(dark)
                //-     v-toolbar-title Follow Ups

                v-simple-table.table-x-scroll
                    template(v-slot:default)
                        thead.volie-background-color-blue(style="padding-top: 15px;")
                            tr
                                th.white--text Key
                                th.white--text Description
                                th.white--text Vid
                                th.white--text Type
                        tbody(v-if="validFollowUps")
                            tr(v-for='followUp in followUps' :key='followUp.follow_up_id')
                                td.no-wrap.sm-width.text-capitalize {{ followUp.key }}
                                td.no-wrap.sm-width
                                    StrLimitTooltipText(:text="followUp.description" :char-limit="35")
                                td.no-wrap.sm-width {{ followUp.follow_up_key }}
                                td.no-wrap.sm-width {{ followUp.follow_uppable_type ? followUp.follow_uppable_type : '---' }}
                                    template(v-if="followUp.communication_type_name") {{ ' (' + followUp.communication_type_name + ')' }}

                        tbody(v-else-if="isLoading")
                            tr
                                td(colspan="4")
                                    .text-center
                                        v-progress-circular(
                                            :size='100'
                                            :width='10'
                                            color='#0f5b9a'
                                            indeterminate
                                            style="margin-top: 75px; margin-bottom: 75px;"
                                        )

            v-alert(v-if="!validFollowUps && !isLoading" outlined type='info' dense border='left' style="margin-top: 16px;")
                | There are no follow ups for this conference

                        

</template>

<script lang="ts">
    import Vue from 'vue';

    // Components
    import StrLimitTooltipText from "@/components/shared/str_limit_tooltip_text.vue";

    export default Vue.extend({
        computed: {
            validFollowUps() {
                return this.followUps?.length > 0;
            },
        },
        props: {
            followUps: Array,
            isLoading: Boolean,
        },
        components: {
            StrLimitTooltipText,
        },
    });
</script>

<style lang="scss" scoped>
</style>