<template lang="pug">
    .ma-sm-2(v-if="validCustomer")
        .text-center(v-if="isLoading")
            v-progress-circular(
                :size='100'
                :width='10'
                color='#0f5b9a'
                indeterminate
                style="margin-top: 100px;"
            )

        v-container.mx-auto(v-if="!isLoading")
            .text-subtitle-2.subtitle-2-padding.text-center
                | Campaign Enrollment History

            CampaignCustomerDetails(
                v-if="validCampaignEnrollmentHistory"
                v-for='campaignCustomer in campaignCustomers'
                :key='campaignCustomer.campaign_customer_id'
                :campaign-customer="campaignCustomer"
            )

            v-alert(outlined type='warning' dense border='left' style="margin-top: 16px;" v-if="!validCampaignEnrollmentHistory")
                | Customer does not have any campaign enrollment history

</template>

<script lang="ts">
    import Vue from 'vue';

    // Components
    import CampaignCustomerDetails from '@/components/campaign_customers/campaign_customer_details.vue';

    // Data
    import { GetCampaignCustomers } from "@/data";

    export default Vue.extend({
        data() {
            return {
                campaignCustomers: [],
                isLoading: false,
            }
        },
        methods: {
            getCampaignCustomers() {
                if (!this.validCustomer) {
                    return;
                }

                const options = {
                    customer_id: this.currentCampaignCustomer?.customer_id,
                    organization_id: this.currentCampaignCustomer?.organization_id,
                    campaign_history: true,
                }

                this.isLoading = true;
                GetCampaignCustomers(options).then((response) => {
                    if (response?.Data?.campaign_customers?.length > 0) {
                        this.campaignCustomers = response.Data.campaign_customers;
                    }
                    this.isLoading = false;
                });
            },
        },
        computed: {
            validCampaignEnrollmentHistory() {
                return this.campaignCustomers?.length > 0;
            },
            validCustomer() {
                return this.currentCampaignCustomer?.account_id > 0 && this.currentCampaignCustomer?.customer_id > 0 && this.currentCampaignCustomer?.organization_id > 0;
            },
        },
        components: {
            CampaignCustomerDetails,
        },
        props: {
            currentCampaignCustomer: Object,
        },
        created() {
            this.getCampaignCustomers();
        },
    });
</script>

<style lang="scss" scoped>
</style>
