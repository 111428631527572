import { get, post, put, destroy } from '.';

export function GetCommunicationQueueTeams(options = {}) {
    return get<any>('/app/api/communication_queue_teams', options);
}

export function CreateCommunicationQueueTeam(options = {}) {
    return post<any>('/app/api/communication_queue_teams', options)
}

export function DestroyCommunicationQueueTeam(id: string) {
    return destroy<any>(`/app/api/communication_queue_teams/${id}`)
}

export function UpdateCommunicationQueueTeam(id: string, options) {
    return put<any>(`/app/api/communication_queue_teams/${id}`, options)
}