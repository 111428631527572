<template lang="pug">
    ExpandableCardListItem.mt-0.mb-5.mx-1(
        v-if="validCampaignCustomer"
        :color="itemColor"
        :content-right-top="campaignCustomer.friendly_created_at_date"
        :content-right-bottom="isCampaignCustomerPurposeDefault ? formattedPurposeAndCommunicationType : ''"
        expandable
        :is-expanded="showDetails === true"
        :on-expand="() => { showDetails = !showDetails; fetchDetails(); }"
        :subtitle="campaignCustomer.status"
        :title="campaignCustomer.campaign_name"
    )
        template(v-slot:rightBottom)
            v-tooltip(v-if="isCampaignCustomerPurposeDefault" top)
                template(v-slot:activator='{ on, attrs }')
                    span(v-bind="attrs" v-on="on" role="text")
                        | {{ campaignCustomer.status }}
                span
                    | {{ campaignCustomer.status }}

        template(v-slot:body)
            v-card-text.white.text--primary(v-if="showDetails === true")

                //- Campaign
                ListItem(
                    default-title="N/a"
                    icon="mdi-bullhorn"
                    indent
                    subtitle="Campaign Name"
                    :title="campaignCustomer.campaign_name"
                )
                ListItem(
                    default-title="N/a"
                    indent
                    subtitle="Campaign Customer Key"
                    :title="campaignCustomer.campaign_customer_key"
                )
                ListItem(
                    default-title="N/a"
                    indent
                    subtitle="Enrolled"
                    :title="campaignCustomer.friendly_created_at_date"
                )
                ListItem(
                    default-title="N/a"
                    indent
                    subtitle="Status"
                    :title="campaignCustomer.status"
                )

                //- Communication
                v-divider(inset)
                ListItem(
                    default-title="N/a"
                    indent
                    subtitle="Communication Type"
                    :icon="communicationTypeIcon"
                    :title="campaignCustomer.communication_type_name"
                )
                ListItem(
                    default-title="N/a"
                    indent
                    subtitle="Attempt Count"
                )
                    template(v-slot:title)
                        template(v-if="campaignCustomer.communication_type_name === 'Phone'") {{ campaignCustomer.attempt_count | number }}
                        template(v-else) N/a

                ListItem(
                    default-title="N/a"
                    indent
                    subtitle="Last Attempt"
                )
                    template(v-slot:title)
                        template(v-if="campaignCustomer.friendly_last_attempt_time_date && campaignCustomer.friendly_last_attempt_time_date.length > 0 && campaignCustomer.last_attempt_disposition > 0") {{ campaignCustomer.friendly_last_attempt_time_date, campaignCustomer.last_attempt_disposition_description }}
                        template(v-else-if="campaignCustomer.friendly_last_attempt_time_date && campaignCustomer.friendly_last_attempt_time_date.length > 0 && !(campaignCustomer.last_attempt_disposition > 0)") {{ campaignCustomer.friendly_last_attempt_time_date }}
                        template(v-else) N/a

                //- ListItem(
                //-     default-title="N/a"
                //-     subtitle="Campaign Key"
                //-     :title="campaignCustomer.campaign_key"
                //- )
                //- ListItem(
                //-     default-title="N/a"
                //-     subtitle="Customer Key"
                //-     :title="campaignCustomer.customer_key"
                //- )
                //- ListItem(
                //-     default-title="N/a"
                //-     subtitle="Customer Vehicle Key"
                //-     :title="campaignCustomer.customer_vehicle_key"
                //- )
                //- ListItem(
                //-     default-title="N/a"
                //-     subtitle="Vehicle Key"
                //-     :title="campaignCustomer.vehicle_key"
                //- )

                //- Vehicle
                v-divider(inset)
                ListItem(
                    default-title="N/a"
                    icon="mdi-car"
                    indent
                    subtitle="Vehicle"
                    :title="campaignCustomer.customer_vehicle_year_make_model"
                )

                //- Appointment
                v-divider(inset)
                ListItem(
                    default-title="N/a"
                    icon="mdi-calendar"
                    indent
                    subtitle="Appointment Datetime"
                    :title="campaignCustomer.friendly_appointment_datetime"
                )
                ListItem(
                    default-title="N/a"
                    indent
                    subtitle="Appointment Number"
                    :title="campaignCustomer.appointment_number"
                )

                //- Prospect
                v-divider(inset)
                ListItem(
                    default-title="N/a"
                    icon="mdi-account-plus"
                    indent
                    subtitle="Prospect Request Date"
                    :title="campaignCustomer.friendly_prospect_request_date"
                )
                ListItem(
                    default-title="N/a"
                    indent
                    subtitle="Prospect Lead Key"
                    :title="campaignCustomer.prospect_lead_key"
                )
                ListItem(
                    default-title="N/a"
                    indent
                    subtitle="Prospect Lead Source"
                    :title="campaignCustomer.prospect_lead_source_name"
                )

                //- Sale
                v-divider(inset)
                ListItem(
                    default-title="N/a"
                    icon="mdi-currency-usd"
                    indent
                    subtitle="Sale Deal Book Date"
                    :title="campaignCustomer.friendly_sale_deal_book_date"
                )
                ListItem(
                    default-title="N/a"
                    indent
                    subtitle="Sale Deal Number"
                    :title="campaignCustomer.sale_deal_number"
                )

                //- Service
                v-divider(inset)
                ListItem(
                    default-title="N/a"
                    icon="mdi-tools"
                    indent
                    subtitle="Service Close Date"
                    :title="campaignCustomer.friendly_service_close_date"
                )
                ListItem(
                    default-title="N/a"
                    indent
                    subtitle="Service RO Number"
                    :title="campaignCustomer.service_ro_number"
                )

                ListItem(
                    default-title="None"
                    :indent="!validCampaignCustomerActivities"
                    subtitle="Activity"
                )
                    template(v-slot:title)
                        template(v-if="validCampaignCustomerActivities")
                            CampaignCustomerActivityDetail(
                                v-for="campaignCustomerActivity in campaignCustomerActivities"
                                :key="campaignCustomerActivity.campaign_customer_activity_id"
                                :campaign-customer-activity="campaignCustomerActivity"
                            )
                        template(v-else) None

                ListItem(
                    default-title="None"
                    :indent="!validCampaignCustomerAgents"
                    subtitle="Agent Statuses"
                )
                    template(v-slot:title)
                        template(v-if="validCampaignCustomerAgents")
                            CampaignCustomerAgentDetail(
                                v-for="campaignCustomerAgent in campaignCustomerAgents"
                                :key="campaignCustomerAgent.agent_id"
                                :campaign-customer-agent="campaignCustomerAgent"
                            )
                        template(v-else) None
</template>

<script lang="ts">
    import Vue from 'vue';
    import VolieState from '@/store';

    // Components
    import CampaignCustomerActivityDetail from "@/components/communication_center/shared/tabs/campaign_enrollment_history/campaign_customer_activity_detail.vue";
    import CampaignCustomerAgentDetail from "@/components/communication_center/shared/tabs/campaign_enrollment_history/campaign_customer_agent_detail.vue";
    import ExpandableCardListItem from '@/components/shared/expandable_card_list_item.vue';
    import ListItem from '@/components/shared/list_item.vue';

    // Data
    import { GetCampaignCustomerActivities, GetCampaignCustomerAgents } from "@/data";

    // Helpers
    import { resourceColorClass, } from "@/helpers/css_classes";
    import { titleize } from "@/helpers/general";

    export default Vue.extend({
        data() {
            return {
                campaignCustomerAgents: [],
                campaignCustomerActivities: [],
                showDetails: false,
            }
        },
        methods: {
            fetchDetails() {
                this.getCampaignCustomerAgents();
                this.getCampaignCustomerActivities();
            },
            getCampaignCustomerAgents(){
                GetCampaignCustomerAgents(this.campaignCustomer.campaign_customer_id).then((response) => {
                    if (response?.Data?.campaign_customer_agents?.length > 0) {
                        this.campaignCustomerAgents = response.Data.campaign_customer_agents
                    }
                })
            },
            getCampaignCustomerActivities(){
                GetCampaignCustomerActivities(this.campaignCustomer.campaign_customer_id).then((response) => {
                    if (response?.Data?.campaign_customer_activities?.length > 0) {
                        this.campaignCustomerActivities = response.Data.campaign_customer_activities
                    }
                })
            },
        },
        computed: {
            communicationTypeIcon() {
                switch (this.campaignCustomer?.communication_type_name) {
                    case "Email":
                        return "mdi-email";
                    case "Export List":
                        return "mdi-view-list-outline";
                    case "Phone":
                        return "mdi-phone";
                    case "SMS Message":
                        return "mdi-cellphone";
                }
                return null;
            },
            formattedPurposeAndCommunicationType() {
                return titleize(this.campaignCustomer?.purpose) + " - " + this.campaignCustomer?.communication_type_name;
            },
            itemColor() {
                return resourceColorClass("CampaignCustomer");
            },
            isCampaignCustomerPurposeDefault() {
                return this.campaignCustomer?.purpose === "default";
            },
            validCampaignCustomer() {
                return this.campaignCustomer?.campaign_customer_id > 0;
            },
            validCampaignCustomerActivities() {
                return this.campaignCustomerActivities?.length > 0;
            },
            validCampaignCustomerAgents() {
                return this.campaignCustomerAgents?.length > 0;
            }
        },
        components: {
            CampaignCustomerActivityDetail,
            CampaignCustomerAgentDetail,
            ExpandableCardListItem,
            ListItem,
        },
        props: {
            campaignCustomer: Object,
        },
    });
</script>

<style lang="scss" scoped>
    #app {
        .cell-value-spacing {
            padding: 0 0 5px 0;
        }
    }
</style>
