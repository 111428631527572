<template lang="pug">
    div
        v-divider(inset)
        template(v-if="validSpecialInstructions")
            ListItem(
                v-for='(obj, idx) in specialInstructions'
                :key="idx"
                default-title="N/a"
                :icon="idx === 0 ? 'mdi-clipboard-list-outline' : null"
                indent
                :subtitle="obj.instruction_text"
                :title="obj.type_name"
            )
                template(v-slot:subtitle)
                    v-container.pa-0(style="overflow: scroll;" v-html="obj.instruction_text")

</template>

<script lang="ts">
    import Vue from 'vue';

    // Components
    import ListItem from '@/components/shared/list_item.vue';

    export default Vue.extend({
        data() {
            return {
            }
        },
        methods: {
        },
        computed: {
            validSpecialInstructions() {
                return this.specialInstructions?.length > 0;
            },
        },
        components: {
            ListItem,
        },
        props: {
            specialInstructions: Array,
        },
        created() {
        },
    });
</script>

<style lang="scss" scoped>
    #app {
        .table-cell-padding {
            padding-top: 10px;
            padding-bottom: 10px;
        }
    }
</style>
