<template lang="pug">
    ExpandableCardListItem.mt-0.mb-4(
        v-if="validConversationGroup"
        :color="itemColor"
        :content-right-top="conversationGroup.friendly_last_message_time"
        expandable
        :is-expanded="showDetails === true"
        :on-expand="() => { showDetails = !showDetails; getConversationGroupConversations(); }"
        :title="conversationGroup.campaign_name"
        :subtitle="conversationGroup.last_message_body"
    )

        template(v-slot:body)
            v-card-text.white.text--primary(v-if="showDetails")
                template(v-if="conversations && conversations.length > 0")
                    v-list-item(
                        v-for='(conversation, i) in conversations'
                        :key='i'
                    )
                        ConversationMessages(
                            :conversation="conversation"
                        )
                template(v-else-if="isLoadingConversations === true")
                    .text-center
                        v-progress-circular(
                            :size='100'
                            :width='10'
                            color='#0f5b9a'
                            indeterminate
                            style="margin-top: 50px; margin-bottom: 50px;"
                        )
                template(v-else) N/a


</template>

<script lang="ts">
    import Vue from 'vue';

    // Components
    import ConversationMessages from '@/components/communication_center/sms_message/conversation_messages.vue';
    import ExpandableCardListItem from '@/components/shared/expandable_card_list_item.vue';

    // Data
    import { GetConversationGroupConversations } from "@/data";

    // Helpers
    import { resourceColorClass, } from "@/helpers/css_classes";

    export default Vue.extend({
        data() {
            return {
                conversations: [],
                isLoadingConversations: false,
                showDetails: false,
            }
        },
        methods: {
            getConversationGroupConversations() {

                // only load the first time
                if (this.conversations?.length > 0 ) {
                    return
                }

                if (!(this.conversationGroup?.conversation_group_id > 0 && this.conversationGroup?.customer_id)) {
                    return
                }

                const options = {
                    account_id:            this.conversationGroup?.account_id,
                    customer_id:           this.conversationGroup?.customer_id,
                    conversation_group_id: this.conversationGroup?.conversation_group_id,
                    organization_id:       this.conversationGroup?.organization_id,
                }

                this.isLoadingConversations = true;
                GetConversationGroupConversations(options).then((response) => {
                    if (response?.Data?.conversations?.length > 0) {
                        this.conversations = response.Data.conversations;
                    }
                    this.isLoadingConversations = false;
                });
            },
        },
        computed: {
            itemColor() {
                return resourceColorClass("Message");
            },
            validConversationGroup() {
                return this.conversationGroup?.conversation_group_id > 0;
            },
        },
        components: {
            ConversationMessages,
            ExpandableCardListItem,
        },
        props: {
            conversationGroup: Object,
        },
    });
</script>

<style lang="scss" scoped>
    #app {
        .no-top-bottom-padding {
            padding-top: 0;
            padding-bottom: 0;
        }

        .cell-value-spacing {
            padding: 0 0 5px 0;
        }

        .m-l-5 {
            margin-left: 5px;
        }
    }
</style>
