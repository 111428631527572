<template lang="pug">
    tr(v-if="showMore")
        td.text-center(colspan='100%')
            v-tooltip(top)
                template(v-slot:activator="{ on, attrs }")
                    v-btn.volie-color-blue.width-100-percent(link outlined text ripple small :loading="isShowMoreLoading" @click="handleShowMore()" v-bind="attrs" v-on="on" role="text")
                        | Show more
                span SHOW MORE

</template>

<script lang="ts">
    import Vue from 'vue';

    export default Vue.extend({
        data() {
            return {}
        },
        computed: {
            showMore() {
                // if showMoreable is false, then show more was clicked and no more records were returned from get route so we know not to show button any more
                // this handles the case where number of records returned is divisble by limit where show more button would never go away
                if (!this.showMoreable) {
                    return false;
                }                

                // if there are records returned and data length plus/minus splices is divisible by limit, indicates there are probably more rows unless total number of records is divisible by limit
                if (this.dataLength > 0 && ((this.dataLength - this.splices) % this.limit) === 0) {
                    return true;
                }

                return false;
            },
        },
        props: {
            colspan:        Number,
            dataLength:     Number,
            handleShowMore: Function,
            isShowMoreLoading: Boolean,
            limit:          Number,
            showMoreable:   Boolean,
            splices:        Number, // positive or negative number to indicate how many elements have been added to data or removed from data array due to splices/appends (not from get route). To be used in conjunction with data length when determining if should display show more row.
        },
    });
</script>

<style lang="scss" scoped>
</style>