<template lang="pug">
    ExpandableCardListItem.mt-0.mb-5.mx-1(
        v-if="validCustomerVehicle"
        :color="itemColor"
        expandable
        :is-expanded="showDetails === true"
        :on-expand="() => showDetails = !showDetails"
        :title="vehicleYearMakeModel"
        :subtitle="'VIN: ' + (customerVehicle.vin || '---')"
    )
        template(v-slot:title)
            CopyableText(:text="vehicleYearMakeModel")

        template(v-slot:subtitle)
            template(v-if="customerVehicle.vin")
                | VIN:
                CopyableText(:text="customerVehicle.vin")
            template(v-else)
                | Vin: ---

        template(v-slot:rightTop)
            .text-right
                template(v-if="validToyoguardVehicle")
                    v-btn(
                        icon
                        dark
                        ripple
                        @click="changeTab()"
                    )
                        v-tooltip(top)
                            template(v-slot:activator='{ on, attrs }')
                                span(v-bind="attrs" v-on="on" role="text")
                                    v-icon | mdi-car-cog
                            span
                                | Toyoguard Information Available

                CustomerVehicleFormDialog(
                    v-if='agentsCanEditCustomerVehicles === true'
                    dark
                    :customer-vehicle="customerVehicle"
                    :update-vehicle="updateVehicle"
                )
                CustomerVehicleDestroyDialog(
                    v-if='agentsCanDeleteCustomerVehicles === true'
                    dark
                    :customer-vehicle="customerVehicle"
                    :destroy-customer-vehicle="destroyCustomerVehicle"
                )

        template(v-slot:body)
            v-card-text.white.text--primary
                v-btn(
                    color="primary"
                    icon
                    ripple
                    @click="toggleNoLongerOwns();"
                    :disabled="hideCreateUpdateDestroyButtons"
                )
                    v-icon(v-if="customerVehicle.no_longer_owns === false")
                        | mdi-checkbox-marked
                    v-icon(v-else)
                        | mdi-checkbox-blank-outline
                span Currently Owns

                template(v-if="campaignCustomerId > 0")
                    br
                    v-btn(
                        color="primary"
                        icon
                        ripple
                        @click="updateCampaignCustomerActiveCustomerVehicle(customerVehicle.customer_vehicle_id);"
                        :disabled="primaryCustomerVehicleId === customerVehicle.customer_vehicle_id"
                    )
                        v-icon(v-if="customerVehicle.customer_vehicle_id === primaryCustomerVehicleId")
                            | mdi-checkbox-marked
                        v-icon(v-else)
                            | mdi-checkbox-blank-outline
                    span Reason for Communication

                template(v-if="showDetails === true")
                    v-divider.divider-spacing
                    v-list.py-0(
                        two-line
                    )
                        //- ListItem(
                        //-     subtitle="Customer Vehicle Vid"
                        //-     :title="customerVehicle.customer_vehicle_key"
                        //- )
                        ListItem(
                            default-title="N/a"
                            icon="mdi-car"
                            indent
                            subtitle="Vehicle"
                            :title="vehicleYearMakeModel"
                        )
                        ListItem(
                            default-title="N/a"
                            indent
                            subtitle="Trim"
                            :title="customerVehicle.vehicle_trim"
                        )
                        ListItem(
                            default-title="N/a"
                            indent
                            subtitle="VIN"
                            :title="customerVehicle.vin"
                        )
                        v-divider(inset)
                        ListItem(
                            default-title="N/a"
                            icon="mdi-calendar"
                            indent
                            subtitle="Sale Date"
                            :title="customerVehicle.friendly_sale_date"
                        )
                        ListItem(
                            default-title="N/a"
                            indent
                            subtitle="Last Service Date"
                            :title="customerVehicle.friendly_last_service_date"
                        )
                        ListItem(
                            default-title="N/a"
                            indent
                            subtitle="Created At"
                            :title="customerVehicle.friendly_created_at_date"
                        )
                        v-divider(inset)
                        ListItem(
                            default-title="N/a"
                            icon="mdi-counter"
                            indent
                            subtitle="Latest Odometer"
                            :title="customerVehicle.vehicle_trim"
                        )
                            template(v-slot:title)
                                template(v-if="customerVehicle.latest_odometer_value && customerVehicle.latest_odometer_value > 0")
                                    template(v-if="customerVehicle.friendly_latest_odometer_date && customerVehicle.friendly_latest_odometer_date.length > 0") {{ customerVehicle.latest_odometer_value | number }} ({{ customerVehicle.friendly_latest_odometer_date }})
                                    template(v-else) {{ customerVehicle.latest_odometer_value | number }}
                                template(v-else) N/a

</template>

<script lang="ts">
    import Vue from 'vue';
    import VolieState  from "@/store";

    // Components
    import CopyableText from '@/components/shared/copyable_text.vue';
    import CustomerVehicleDestroyDialog from '@/components/customer_vehicles/destroy_dialog.vue';
    import CustomerVehicleFormDialog from '@/components/customer_vehicles/form_dialog.vue';
    import ExpandableCardListItem from '@/components/shared/expandable_card_list_item.vue';
    import ListItem from '@/components/shared/list_item.vue';

    // Helpers
    import { resourceColorClass, } from "@/helpers/css_classes";
    import { concatVehicleYearMakeModel } from "@/helpers/general";

    // Types
    import { VehicleYearMakeModelObj } from "@/helpers/general";

    export default Vue.extend({
        data() {
            return {
                showDetails: false,
            }
        },
        methods: {
            toggleNoLongerOwns() {

                const options = {
                    customer_vehicle_id: this.customerVehicle.customer_vehicle_id,
                    no_longer_owns:      !this.customerVehicle.no_longer_owns,
                    vehicle_id:          this.customerVehicle.vehicle_id,
                    vehicle_make:        this.customerVehicle.vehicle_make,
                    vehicle_model:       this.customerVehicle.vehicle_model,
                    vehicle_model_year:  this.customerVehicle.vehicle_model_year,
                    vehicle_trim:        this.customerVehicle.vehicle_trim,
                    vin:                 this.customerVehicle.vin,
                }

                this.updateVehicle(options)
            },
        },
        computed: {
            currentUser() {
                return VolieState.currentUser();
            },
            currentUserIsSuperAdmin() {
                return this.currentUser?.activated_role_id === 1;
            },
            itemColor() {
                return resourceColorClass("Vehicle");
            },
            validCustomerVehicle() {
                return this.customerVehicle?.customer_vehicle_id > 0 && this.customerVehicle?.vehicle_id > 0;
            },
            validVin() {
                return this.validCustomerVehicle && this.customerVehicle.vin?.length > 0;
            },
            vehicleYearMakeModel() {
                if (!this.validCustomerVehicle) {
                    return null;
                }

                const vehObj = {
                    vehicleModelYear: this.customerVehicle.vehicle_model_year,
                    vehicleMake:      this.customerVehicle.vehicle_make,
                    vehicleModel:     this.customerVehicle.vehicle_model,
                }

                return concatVehicleYearMakeModel(vehObj as VehicleYearMakeModelObj);
            },
            validToyoguardVehicle() {
                return this.validCustomerVehicle && this.customerVehicle.toyoguard_program_vid?.length > 0
            }
        },
        components: {
            CopyableText,
            CustomerVehicleDestroyDialog,
            CustomerVehicleFormDialog,
            ExpandableCardListItem,
            ListItem,
        },
        props: {
            agentsCanDeleteCustomerVehicles:             Boolean,
            agentsCanEditCustomerVehicles:               Boolean,
            campaignCustomerId:                          Number,
            customerVehicle:                             Object,
            primaryCustomerVehicleId:                    Number,
            destroyCustomerVehicle:                      Function,
            hideCreateUpdateDestroyButtons:              Boolean,
            updateCampaignCustomerActiveCustomerVehicle: Function,
            updateVehicle:                               Function,
            changeTab:                                   Function,
        },
        watch: {
        },
        created() {
        },
    });
</script>

<style lang="scss" scoped>
    #app {

        .cell-value-spacing {
            padding: 0 0 5px 0;
        }
    }
</style>
