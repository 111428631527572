import { get, put, destroy, } from '.';

export function GetScorecards(options = {}) {
	return get<any>("/app/api/scorecards", options);
}

export function FindScorecard(id) {
	return get<any>("/app/api/scorecards/" + (id || 0).toString());
}

export function UpdateScorecard(id, options = {}) {
	return put<any>("/app/api/scorecards/" + (id || 0).toString(), options);
}

export function CompleteScorecard(id) {
	return put<any>("/app/api/scorecards/" + (id || 0).toString() + "/complete", {});
}

export function DestroyScorecard(id, options = {}) {
	return destroy<any>("/app/api/scorecards/" + (id || 0).toString(), options);
}

export function GetScorecardCallConference(scorecardId, callId, options = {}) {
	return get<any>("/app/api/scorecards/" + (scorecardId || 0).toString() + "/calls/" + (callId || 0).toString() + "/conference", options);
}

export function GetUserChartScorecards(options = {}) {
	return get<any>("/app/api/scorecards/user_chart", options);
}

export function GetScorecardUsersStats(options = {}) {
	return get<any>("/app/api/scorecards/users_stats", options);
}

export function GetScorecardTemplatesStats(options = {}) {
	return get<any>("/app/api/scorecards/templates_stats", options);
}

export function GetScorecardItemsStats(options = {}) {
	return get<any>("/app/api/scorecards/items_stats", options);
}
