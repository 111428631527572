<template lang="pug">
    ExpandableCardListItem.mt-0.mb-4(
        v-if="validSale"
        id="sale-details-card"
        :color="itemColor"
        :content-right-top="sale.friendly_deal_book_date"
        expandable
        :is-expanded="showDetails === true"
        :on-expand="() => showDetails = !showDetails"
        :title="vehicleYearMakeModel"
        :subtitle="'# ' + sale.deal_number"
    )

        template(v-slot:body)
            template(v-if="showDetails === true")
                v-card-text.white.text--primary
                    v-list.py-0(
                            two-line
                        )
                        ListItem(
                            default-title="N/a"
                            icon="mdi-car"
                            indent
                            subtitle="Vehicle"
                            :title="vehicleYearMakeModel"
                        )

                        ListItem(
                            default-title="N/a"
                            indent
                            subtitle="VIN"
                            :title="sale.vehicle_vin"
                        )

                        ListItem(
                            default-title="N/a"
                            indent
                            subtitle="Vehicle Type"
                            :title="sale.vehicle_type"
                        )

                        ListItem(
                            default-title="N/a"
                            indent
                            subtitle="Vehicle Status"
                            :title="sale.customer_vehicle_no_longer_owns === true ? 'No Longer Owns' : 'Currently Owns'"
                        )

                        v-divider

                        ListItem(
                            default-title="N/a"
                            icon="mdi-calendar"
                            subtitle="Deal Book Date"
                            :title="sale.friendly_deal_book_date"
                        )

                        ListItem(
                            default-title="N/a"
                            indent
                            subtitle="Deal Number"
                            :title="sale.deal_number"
                        )

                        ListItem(
                            default-title="N/a"
                            indent
                            subtitle="Deal Type"
                            :title="sale.friendly_deal_type"
                        )

                        v-list-item
                            v-list-item-icon
                            v-list-item-content
                                v-list-item-title
                                    template(v-if="sale.friendly_deal_type !== 'Cash' && sale.term > 0") {{ sale.term | number }}
                                    template(v-else)
                                        | N/a
                                v-list-item-subtitle
                                    | Term


                        v-list-item
                            v-list-item-icon
                            v-list-item-content(v-if="sale.co_buyer_customer_key && sale.co_buyer_customer_key.length > 0")
                                v-card(flat)
                                    v-toolbar(
                                        flat
                                        rounded
                                        elevation="4"
                                        color="light-green lighten-4"
                                    )
                                        v-btn(
                                            color="primary"
                                            icon
                                            ripple
                                            @click="showCoBuyerDetails = !showCoBuyerDetails;"
                                        )
                                            v-icon(v-if="showCoBuyerDetails === true")
                                                | mdi-chevron-down
                                            v-icon(v-else)
                                                | mdi-chevron-right
                                        v-toolbar-title
                                            | Co-Buyer
                                            br
                                            span(style="font-size: 14px") {{ saleCardTitle }}
                                        v-spacer
                                        .text-caption.text-right.m-l-5
                                            v-btn(
                                                color="primary"
                                                icon
                                                ripple
                                                :to="{ name: 'customers_show', params: { customer_key: sale.co_buyer_customer_key } }"
                                                target="_blank"
                                            )
                                                v-icon(small color="primary") mdi-open-in-new
                                    template(v-if="showCoBuyerDetails == true")
                                        v-card-text

                                            ListItem(
                                                default-title="N/a"
                                                icon="mdi-account"
                                                indent
                                                subtitle="Customer Key"
                                                :title="sale.co_buyer_customer_key"
                                            )


                                            ListItem(
                                                default-title="N/a"
                                                indent
                                                subtitle="Full Name"
                                                :title="sale.co_buyer_full_name"
                                            )

                                            ListItem(
                                                default-title="N/a"
                                                indent
                                                subtitle="Email"
                                                :title="sale.co_buyer_email_address"
                                            )

                                            ListItem(
                                                default-title="N/a"
                                                indent
                                                subtitle="Cell Phone"
                                                :title="sale.co_buyer_cell_phone"
                                            )

                                            ListItem(
                                                default-title="N/a"
                                                indent
                                                subtitle="Home Phone"
                                                :title="sale.co_buyer_home_phone"
                                            )

                                            ListItem(
                                                default-title="N/a"
                                                indent
                                                subtitle="Work Phone"
                                                :title="sale.co_buyer_work_phone"
                                            )

                                            v-divider

                                            ListItem(
                                                default-title="N/a"
                                                icon="mdi-map-marker"
                                                subtitle="Address 1"
                                                :title="sale.co_buyer_address_street_one"
                                            )

                                            ListItem(
                                                default-title="N/a"
                                                indent
                                                subtitle="Address 2"
                                                :title="sale.co_buyer_address_street_two"
                                            )

                                            ListItem(
                                                default-title="N/a"
                                                indent
                                                subtitle="City / Region"
                                                :title="sale.co_buyer_address_city_region"
                                            )

                                            ListItem(
                                                default-title="N/a"
                                                indent
                                                subtitle="State / Province"
                                                :title="sale.co_buyer_address_state_province"
                                            )

                                            ListItem(
                                                default-title="N/a"
                                                indent
                                                subtitle="Postal Code"
                                                :title="sale.co_buyer_address_postal_code"
                                            )

                            v-list-item-content(v-else)
                                v-list-item-title
                                    | None
                                v-list-item-subtitle
                                    | Co-Buyer

                        v-divider

                        ListItem(
                            default-title="N/a"
                            icon="mdi-calendar"
                            subtitle="Created"
                            :title="sale.friendly_created_at_date"
                        )

                        ListItem(
                            default-title="N/a"
                            indent
                            subtitle="Buyer Number"
                            :title="sale.customer_number && sale.customer_number.length > 0 ? sale.customer_number : ''"
                        )

                        ListItem(
                            default-title="N/a"
                            icon="mdi-account"
                            subtitle="Salesman 1 Email"
                            :title="sale.salesman_one_email && sale.salesman_one_email.length > 0 ? sale.salesman_one_email : ''"
                        )

                        ListItem(
                            default-title="N/a"
                            indent
                            subtitle="Salesman 1 Number"
                            :title="sale.salesman_one_number && sale.salesman_one_number.length > 0 ? sale.salesman_one_number : ''"
                        )

                        ListItem(
                            default-title="N/a"
                            indent
                            subtitle="Salesman 1 Name"
                            :title="sale.salesman_one_name && sale.salesman_one_name.length > 0 ? sale.salesman_one_name : ''"
                        )

                        ListItem(
                            default-title="N/a"
                            icon="mdi-account"
                            subtitle="Salesman 2 Email"
                            :title="sale.salesman_two_email && sale.salesman_two_email.length > 0 ? sale.salesman_two_email : ''"
                        )

                        ListItem(
                            default-title="N/a"
                            indent
                            subtitle="Salesman 2 Number"
                            :title="sale.salesman_two_number && sale.salesman_two_number.length > 0 ? sale.salesman_two_number : ''"
                        )

                        ListItem(
                            default-title="N/a"
                            indent
                            subtitle="Salesman 2 Name"
                            :title="sale.salesman_two_name && sale.salesman_two_name.length > 0 ? sale.salesman_two_name : ''"
                        )

                        ListItem(
                            default-title="N/a"
                            icon="mdi-account"
                            subtitle="Sales Manager Email"
                            :title="sale.sales_manager_email && sale.sales_manager_email.length > 0 ? sale.sales_manager_email : ''"
                        )

                        ListItem(
                            default-title="N/a"
                            indent
                            subtitle="Sales Manager Number"
                            :title="sale.sales_manager_number && sale.sales_manager_number.length > 0 ? sale.sales_manager_number : ''"
                        )

                        ListItem(
                            default-title="N/a"
                            indent
                            subtitle="Sales Manager Name"
                            :title="sale.sales_manager_name && sale.sales_manager_name.length > 0 ? sale.sales_manager_name : ''"
                        )

                        ListItem(
                            default-title="N/a"
                            icon="mdi-account"
                            subtitle="F&I Manager Email"
                            :title="sale.fand_i_manager_email_email && sale.fand_i_manager_email_email.length > 0 ? sale.fand_i_manager_email_email : ''"
                        )

                        ListItem(
                            default-title="N/a"
                            indent
                            subtitle="F&I Manager Number"
                            :title="sale.fand_i_manager_email_number && sale.fand_i_manager_email_number.length > 0 ? sale.fand_i_manager_email_number : ''"
                        )

                        ListItem(
                            default-title="N/a"
                            indent
                            subtitle="F&I Manager Name"
                            :title="sale.fand_i_manager_email_name && sale.fand_i_manager_email_name.length > 0 ? sale.fand_i_manager_email_name : ''"
                        )

                        v-divider

                        ListItem(
                            default-title="N/a"
                            icon="mdi-counter"
                            number-filter
                            subtitle="Delivery Odometer"
                            :title="sale.delivery_odometer"
                        )



                        ListItem(
                            default-title="N/a"
                            icon="mdi-dollar"
                            number-filter
                            subtitle="Sale Price"
                            :title="sale.vehicle_sale_price"
                        )

                        ListItem(
                            indent
                            default-title="N/a"
                            number-filter
                            subtitle="Total Gross Profit"
                            :title="sale.total_gross_profit"
                        )


                        v-list-item
                            v-list-item-icon
                            v-list-item-content
                                v-list-item-title(v-if="sale.extended_warranty_flag === true")
                                    v-icon(color="success") mdi-check
                                v-list-item-title(v-else)
                                    v-icon(color="error") mdi-close
                                v-list-item-title Has Extended Warranty?

                        v-divider

                        ListItem(
                            default-title="N/a"
                            icon="mdi-bank"
                            subtitle="Bank"
                            :title="sale.bank_name && sale.bank_name.length > 0 ? sale.bank_name : ''"
                        )

                        ListItem(
                            default-title="N/a"
                            indent
                            number-filter
                            subtitle="Total Odometer Allowed"
                            :title="sale.total_odometer_allowed > 0 ? sale.total_odometer_allowed : 'N/a'"
                        )

                        ListItem(
                            default-title="N/a"
                            indent
                            number-filter
                            subtitle="Annual Odometer Allowed"
                            :title="sale.annual_odometer_allowed > 0 ? sale.annual_odometer_allowed : 'N/a'"
                        )

                        v-divider

                        ListItem(
                            default-title="N/a"
                            icon="mdi-currency-usd"
                            number-filter
                            subtitle="Monthly Payment"
                            :title="sale.deal_type === 'L' ? sale.lease_payment : sale.retail_payment"
                        )

                        v-list-item
                            v-list-item-icon
                            v-list-item-content
                                v-list-item-title(v-if="sale.deal_type === 'L'") {{ sale.lease_apr | number }}
                                v-list-item-title(v-if="sale.deal_type === 'P'") {{ sale.retail_apr | number }}
                                v-list-item-title(v-else) N/a
                                v-list-item-subtitle APR


                        ListItem(
                            default-title="N/a"
                            indent
                            number-filter
                            subtitle="Down Payment"
                            :title="sale.deal_type === 'P' ? sale.retail_down_payment : 'N/a'"
                        )


                        ListItem(
                            default-title="N/a"
                            indent
                            number-filter
                            subtitle="Cap Cost Reduction"
                            :title="sale.deal_type === 'L' ? sale.lease_cap_cost_reduction : 'N/a'"
                        )

                        ListItem(
                            default-title="N/a"
                            indent
                            number-filter
                            subtitle="Total Cost Reduction"
                            :title="sale.deal_type === 'L' ? sale.lease_total_cap_cost : 'N/a'"
                        )

                        ListItem(
                            default-title="N/a"
                            indent
                            number-filter
                            subtitle="Money Factor"
                            :title="sale.deal_type === 'L' ? sale.lease_total_cap_cost : 'N/a'"
                        )

                        ListItem(
                            default-title="N/a"
                            indent
                            number-filter
                            subtitle="Residual Amount"
                            :title="sale.deal_type === 'L' ? sale.lease_residual_amount : 'N/a'"
                        )

</template>

<script lang="ts">
    import Vue from 'vue';
    import VolieState from '@/store';

    // Components
    import ExpandableCardListItem from '@/components/shared/expandable_card_list_item.vue';
    import ListItem from '@/components/shared/list_item.vue';

    // Helpers
    import { concatVehicleYearMakeModel } from "@/helpers/general";

    // Types
    import { VehicleYearMakeModelObj } from "@/helpers/general";
    import { resourceColorClass, } from "@/helpers/css_classes";

    export default Vue.extend({
        data() {
            return {
                saleLineCardWidth: 300,
                showDetails: false,
                showCoBuyerDetails: false,
            }
        },
        methods: {
            handleSaleDetailsResize() {
                const saleDetailsWidth = document?.getElementById('sale-details-card')?.getBoundingClientRect()?.width
                if (saleDetailsWidth >= 375) {
                    this.saleLineCardWidth = saleDetailsWidth - 75
                } else {
                    this.saleLineCardWidth = 300
                }
            },
        },
        computed: {
            itemColor() {
                return resourceColorClass("Sale");
            },
            railsVolieBaseUrl() {
                return VolieState.baseRailsUrl();
            },
            saleCardTitle() {
                return this.sale?.co_buyer_full_name?.length > 0
                    ? this.sale.co_buyer_full_name
                    : this.sale.co_buyer_customer_key
            },
            validSale() {
                return this.sale?.sale_id > 0;
            },
            validVehicleType() {
                return this.sale.vehicle_type && this.sale.vehicle_type.length > 0
            },
            validVin() {
                return this.sale.vehicle_vin && this.sale.vehicle_vin.length > 0
            },
            validDealType(){
                return this.sale.friendly_deal_type && this.sale.friendly_deal_type.length > 0
            },
            vehicleYearMakeModel() {
                if (!this.validSale) {
                    return null;
                }

                const vehObj = {
                    vehicleModelYear: this.sale.vehicle_model_year,
                    vehicleMake:      this.sale.vehicle_make,
                    vehicleModel:     this.sale.vehicle_model,
                }

                return concatVehicleYearMakeModel(vehObj as VehicleYearMakeModelObj);
            },
        },
        components: {
            ExpandableCardListItem,
            ListItem,
        },
        props: {
            sale: Object,
        },
        watch: {
            sale: {
                handler() {
                    Vue.nextTick(() => { this.handleSaleDetailsResize(); });
                },
                deep: true,
            },
        },
        created() {
            window.addEventListener('resize', this.handleSaleDetailsResize);
        },
        destroyed() {
            window.removeEventListener('resize', this.handleSaleDetailsResize);
        },
        mounted() {
            this.handleSaleDetailsResize();
        },
    });
</script>

<style lang="scss" scoped>
    #app {

        .cell-value-spacing {
            padding: 0 0 5px 0;
        }

        .m-l-5 {
            margin-left: 5px;
        }
    }
</style>
