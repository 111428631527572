import { get, post, put, destroy } from '.';

export function GetCommunicationQueueUsers(options = {}) {
	return get<any>("/app/api/communication_queue_users", options);
}

export function LoginAllCommunicationQueueUsers(options = {}) {
	return post<any>("/app/api/login_all_communication_queue_users", options);
}

export function LogoutAllCommunicationQueueUsers(options = {}) {
	return post<any>("/app/api/logout_all_communication_queue_users", options);
}

export function UpdateCommunicationQueueUser(options = {}) {
	return put<any>("/app/api/update_communication_queue_user", options);
}

//removing single queue user from campaign comm queue
export function DestroyCommunicationQueueUser(id: string) {
	return destroy<any>(`/app/api/communication_queue_users/${id}`)
}

//removing all queue users from campaign comm queue
export function DestroyAllCommunicationQueueUsers(options = {}) {
	return destroy<any>(`/app/api/communication_queue_users`, options)
}

export function GetAllAvailableCommunicationQueueUsers(options = {}) {
	return get<any>('/app/api/communication_queue_users/all_available', options)
}

//Batch Create communication Queue Users - or just one
export function CreateCommunicationQueueUsers(options = {}) {
	return post<any>('/app/api/communication_queue_users', options)
}
