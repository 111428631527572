import { get, post, put, } from '.';

export function BuildAssignedCampaignCustomerForCustomer(id, options = {}) {
	return get<any>("/app/api/customers/" + (id || 0).toString() + "/assigned_campaign_customer", options);
}

export function FindCustomer(vid, options = {}) {
	return get<any>("/app/api/customers/" + (vid || "").toString(), options);
}

export function GetCustomerCommunicationHistory(options = {}) {
	return get<any>("/app/api/customer_communication_history", options);
}

export function GetCustomerCommunicationManager(options = {}) {
	return get<any>("/app/api/customer_communication_manager", options);
}

export function MatchCustomers(options = {}) {
	return get<any>("/app/api/customers/match", options);
}

export function MatchOrCreateCustomer(options = {}) {
	return post<any>("/app/api/customers/match_or_create", options);
}

export function UpdateCustomerCommunicationManager(id, options = {}) {
	return put<any>("/app/api/customer_communication_managers/" + (id || 0).toString(), options);
}
