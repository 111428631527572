<template lang="pug">
    .video-wrapper(v-if="fileSupported")
        video#video-playback(
            controls
            controlsList=""
            :src="fileToPlay"
            :type="videoType"
        )  
            | Your browser does not support the video tag.

    
    v-alert(v-else outlined type='info' dense border='left' style="margin-top: 0px;")
        | This video format is unsupported.
                        
</template>

<script lang="ts">
    import Vue from 'vue';

    export default Vue.extend({
        computed: {
            fileToPlay() {
                if (this.file) {
                    return this.file;
                }
                if (this.videoUrl)  {
                    return this.videoUrl;
                }
                return "";
            },
            fileSupported() {
                return this.videoType === 'video/mp4' || this.videoType === 'video/webm' || this.videoType === 'video/ogg';
            },
        },
        props: {
            file: String,
            videoType: String,
            videoUrl: String,
        },
    })
</script>

<style lang="scss" scoped>

</style>