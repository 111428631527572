<template lang="pug">
    v-container.mx-auto
        Form(
            v-if="validTekionAppointment && !isLoading"
            :convert-appointment-date-time-from-milliseconds="convertAppointmentDateTimeFromMilliseconds"
            :update-tekion-appointment="updateTekionAppointment"
            :current-campaign-customer="currentCampaignCustomer"
            :scoped-slot="scopedSlot"
            :slot-form-object="slotFormObject"
            :customer-vehicles="customerVehicles"
            :scoped-appointment="scopedAppointment"
            :set-tekion-appointment-details="setTekionAppointmentDetails"
            :display-details-view="displayDetailsView"
            :display-slots-view="displaySlotsView"
        )

        .text-center(v-if="isLoading")
            v-progress-circular(
                :size='100'
                :width='10'
                color='#0f5b9a'
                indeterminate
                style="margin-top: 100px; margin-bottom: 100px;"
            )


</template>

<script lang="ts">
    import Vue from 'vue';
    import VolieState from '@/store';

    //Components

    import Form from '@/components/communication_center/shared/tabs/tekion/form.vue'

    // Data
    import {  UpdateTekionAppointment } from "@/data";

    export default Vue.extend({
        data() {
            return {
                isLoading: false,
            }
        },
        methods: {
            // buildJobsArray(jobTypes) {
            //     const types = typeof jobTypes === 'string' ? [jobTypes] : jobTypes;
            //     const jobs = [];

            //     for (let i = 0; i < types.length; i++) {
            //         const job = {
            //             type: types[i],
            //             operations: [
            //                 {opcode:"test", opcodeDescription: "test"}
            //             ]
            //         };
            //         jobs.push(job);
            //     }

            //     return jobs;
            // },
            updateTekionAppointment(formAppointment){
                if(!this.validTekionAppointment || formAppointment === null) {
                    return;
                }

                // const jobs = this.buildJobsArray(formAppointment.job_types);

                const options = {
                    //TODO:May have to switch back sids and appt_date_time to using scopedSlot and scoped slot object
                    campaign_customer_id: this.currentCampaignCustomer?.campaign_customer_id || 0,
                    appointment_date_time: formAppointment?.appointment_date_time || 0,
                    customer_comments: formAppointment?.customer_comments || "",
                    tekion_shop_sid: formAppointment?.shop_sid || "",
                    tekion_service_advisor_sid: formAppointment?.service_advisor_sid || "",
                    tekion_transportation_type_sid: formAppointment?.transportation_type_sid || "",
                    notify_customer: formAppointment?.notify_customer || null,
                    vehicle_id: formAppointment?.vehicle_id || 0,
                    vehicle_year: formAppointment?.vehicle_year || "",
                    vehicle_make: formAppointment?.vehicle_make || "",
                    vehicle_model: formAppointment?.vehicle_model || "",
                    mock_response: false,
                    //Currently Unused feilds that can be added
                    // jobs: jobs,
                    // delivery_contact_same_as_customer: formAppointment?.delivery_contact_same_as_customer || null,

                };

                if (window.location.href.includes('mockResponse=1')) {
                    options.mock_response = true;
                } else {
                    options.mock_response = false;
                }


                this.isLoading = true;
                UpdateTekionAppointment(this.scopedAppointment.tekion_appointment_id, options).then((response) => {
                    if (response?.Data?.tekion_appointment?.tekion_appointment_id > 0) {
                        //find objects and update properties
                        this.handleUpdateTekionAppointments(response.Data.tekion_appointment);
                        this.showSnackbar("success", "Successfully update this customer's tekion appointment");
                        this.getCustomerVehicles();
                        this.displayDetailsView();
                        this.setTekionError(false);
                        this.setAppointmentIdForTekion(response.Data.tekion_appointment.appointment_id);
                    } else {
                        if (response?.Data?.api_status > 0){
                            this.setTekionError(response.Data.api_status);
                        } 
                        let err = "Unable to update this Tekion Appointment";
                        if (response?.Error?.length > 0) {
                            err = response.Error;
                        }
                        this.showSnackbar("error", err);
                    }
                    this.isLoading = false;
                });

            },
        },
        computed: {
            currentUser() {
                return VolieState.currentUser();
            },
            validTekionAppointment(){
                return this.scopedAppointment?.tekion_appointment_id > 0;
            }

        },
        components: {
            Form,

        },
        props: {
            convertAppointmentDateTimeFromMilliseconds: Function,
            currentCampaignCustomer: Object,
            customerVehicles: Array,
            displayDetailsView: Function,
            displaySlotsView: Function,
            getCustomerVehicles: Function,
            getTekionAppointments: Function,
            scopedSlot: Object,
            scopedAppointment: Object,
            setTekionAppointmentDetails: Function,
            setTekionError: Function,
            showSnackbar: Function,
            slotFormObject: Object,
            handleUpdateTekionAppointments: Function,
            setAppointmentIdForTekion: Function, 
        },
        created() {
        },
        destroyed() {
        }
    });
</script>

<style lang="scss" scoped>
</style>
