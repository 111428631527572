import { get, post, destroy } from '.';

export function GetCommunicationQueueConnectKeys(options = {}) {
	return get<any>("/app/api/communication_queue_connect_keys", options);
}

export function CreateCommunicationQueueConnectKey(options = {}) {
	return post<any>("/app/api/communication_queue_connect_keys", options);
}

export function DestroyCommunicationQueueConnectKey(id) {
	return destroy<any>("/app/api/communication_queue_connect_keys/" + (id || 0).toString());
}

export function GetAvailableCommunicationQueueConnectKeys(options = {}) {
	return get<any>("/app/api/communication_queue_connect_keys_available", options);
}

export function CreateAllCommunicationQueueConnectKeys(options = {}) {
	return post<any>("/app/api/communication_queue_connect_keys_all", options);
}

export function DestroyAllCommunicationQueueConnectKeys(id, options = {}) {
	return destroy<any>("/app/api/communication_queue_connect_keys_all/" + (id || 0).toString(), options);
}

