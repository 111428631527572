<template lang="pug">
    v-dialog(v-model='dialog' persistent max-width='600px')
        template(v-slot:activator='{ on, attrs }')
            v-btn.white--text.mr-4(
                color="error"
                v-bind='attrs'
                v-on='on'
                dark
                small
                @click="reinit();"
            )
                v-icon mdi-skip-next
                | Skip Record

        v-card(v-if="validCampaignCustomer")
            v-card-title
                span.headline Skip Record
            template(v-if="isLoading === false")
                v-card-text
                    v-container
                        v-form
                            v-checkbox(
                                v-model='removeFromQueue'
                                label='Remove this customer from the queue?'
                            )

                            v-textarea(
                                v-model="notes"
                                label='Reason for skipping'
                                rows="2"
                                auto-grow
                                clearable
                                counter
                                filled
                                outlined
                                dense
                            )

                v-card-actions
                    v-spacer
                    v-btn.ma-2(outlined ripple text @click='dialog = false')
                        v-icon mdi-window-close
                        | Close

                    v-btn.ma-2.white--text(
                        outlined
                        ripple
                        color="error"
                        text
                        @click='createSkip();'
                    )
                        v-icon mdi-skip-next
                        | Confirm

            template(v-else)
                v-card-text
                    .text-center
                        v-progress-linear(
                            indeterminate
                            color="success"
                            height="15"
                            rounded
                        )
        
        v-card(v-else)
            v-card-title
                span.headline Skip Record
            v-card-text
                | Something went wrong
            v-card-actions
                v-spacer
                v-btn.ma-2(outlined ripple text @click='dialog = false')
                    v-icon mdi-window-close
                    | Close

</template>

<script lang="ts">
    import Vue from 'vue';
    import VolieState  from "@/store";

    // Data
    import { CreateSkip } from "@/data";

    export default Vue.extend({
        data() {
            return {
                dialog: false,
                isLoading: false,
                notes: null,
                removeFromQueue: false,
            }
        },
        methods: {
            createSkip() {

                // Init
                const options = {
                    account_id: this.currentCampaignCustomer?.account_id,
                    attempt_key: this.currentAgent?.attempt_key,
                    call_id: (this.callId || 0),
                    campaign_customer_id: this.currentCampaignCustomer?.campaign_customer_id, // we want this instead of active ID in case where they unsubscribe and cc active is already deleted
                    notes: this.notes,
                    remove_from_queue: this.removeFromQueue,
                }

                this.isLoading = true;

                // Create
                CreateSkip(options).then((response) => {
                    if (response.Data?.skip?.id > 0) {

                        // Handle response
                        this.handleSkippedCampaignCustomer(this.removeFromQueue);

                        // Close dialog
                        this.dialog = false;
                    }

                    this.isLoading = false;
                });
            },
            reinit() {
                this.notes = null;
                this.removeFromQueue = false;
            },
        },
        computed: {
            currentAgent() {
                return VolieState.currentAgent();
            },
            validCampaignCustomer() {
                return (this.currentCampaignCustomer?.campaign_customer_active_id > 0 && this.currentCampaignCustomer?.account_id > 0);
            },
        },
        components: {
        },
        props: {
            callId: Number,
            currentCampaignCustomer: Object,
            handleSkippedCampaignCustomer: Function,
        },
        destroyed() {
        },
        created() {
        },
        mounted() {
        },
        watch: {
        },
    });
</script>

<style lang="scss" scoped>
</style>
