<template lang="pug">
    v-list-item
        ExpandableCardListItem.mt-2.mb-4(
            v-if="validServiceLine"
            :color="itemColor"
            expandable
            :is-expanded="showDetails === true"
            :on-expand="() => showDetails = !showDetails"
            :title="serviceLineCardTitle"
            subtitle="Service Line Item"
            :style="'width: ' + serviceLineCardWidth + 'px;'"
        )
            template(v-slot:body)
                template(v-if="showDetails === true")
                    v-card-text.white.text--primary
                        v-list.py-0(
                            two-line
                        )
                            //- ListItem(
                            //- indent    
                            //- subtitle="Service Line Vid"
                            //-     :title="serviceLine.service_line_key"
                            //- )
                            //- ListItem(
                            //-     default-title="N/a"
                            //- indent    
                            //- subtitle="Advisor Employee Vid"
                            //-     :title="serviceLine.advisor_employee_key"
                            //- )
                            ListItem(
                                default-title="N/a"
                                icon="mdi-account"
                                indent
                                subtitle="Advisor Name"
                                :title="serviceLine.advisor_name"
                            )
                            //- ListItem(
                            //-     default-title="N/a"
                            //- indent    
                            //- subtitle="Technician Employee Vid"
                            //-     :title="serviceLine.technician_employee_key"
                            //- )
                            //- ListItem(
                            //-     default-title="N/a"
                            //- indent    
                            //- subtitle="Technician Vid"
                            //-     :title="serviceLine.technician_key"
                            //- )
                            v-divider(inset)
                            ListItem(
                                default-title="N/a"
                                icon="mdi-format-list-numbered"
                                indent
                                subtitle="Line Number"
                                :title="serviceLine.line_number"
                                numberFilter
                            )
                            ListItem(
                                default-title="N/a"
                                indent
                                subtitle="Operation Code"
                                :title="serviceLine.operation_code"
                            )
                            ListItem(
                                default-title="N/a"
                                indent
                                subtitle="Operation Description"
                                :title="serviceLine.operation_description"
                            )
                            ListItem(
                                default-title="N/a"
                                indent
                                subtitle="Status"
                                :title="serviceLine.status"
                            )
                            v-divider(inset)
                            ListItem(
                                default-title="N/a"
                                icon="mdi-calendar"
                                indent
                                subtitle="Created At"
                                :title="serviceLine.friendly_created_at"
                            )
                            v-divider(inset)
                            ListItem(
                                default-title="N/a"
                                icon="mdi-invoice-clock"
                                number-filter
                                indent
                                subtitle="Actual Hours"
                                :title="serviceLine.actual_hours"
                            )
                            ListItem(
                                default-title="N/a"
                                indent
                                multi-line-title
                                subtitle="Complaint Statement"
                                :title="serviceLine.complaint_statement"
                            )
                            ListItem(
                                default-title="N/a"
                                indent
                                multi-line-title
                                subtitle="Correction Comments"
                                :title="serviceLine.correction_comments"
                            )
                            ListItem(
                                default-title="N/a"
                                number-filter
                                indent
                                subtitle="Estimated Labor Hours"
                                :title="serviceLine.estimated_labor_hours"
                            )
                            ListItem(
                                default-title="N/a"
                                number-filter
                                indent
                                subtitle="Flat Rate Labor Hours"
                                :title="serviceLine.flat_rate_labor_hours"
                            )
                            v-divider(inset)
                            ListItem(
                                default-title="N/a"
                                dollar-filter
                                icon="mdi-gas-cylinder"
                                indent
                                subtitle="Gas Oil & Grease Amount"
                                :title="serviceLine.gog_amount"
                            )
                            ListItem(
                                default-title="N/a"
                                dollar-filter
                                indent
                                subtitle="Gas Oil & Grease Cost"
                                :title="serviceLine.gog_cost"
                            )
                            ListItem(
                                default-title="N/a"
                                dollar-filter
                                indent
                                subtitle="Gas Oil & Grease Profit"
                                :title="serviceLine.gog_profit"
                            )
                            v-divider(inset)
                            ListItem(
                                default-title="N/a"
                                dollar-filter
                                icon="mdi-car-wrench"
                                indent
                                subtitle="Labor Amount"
                                :title="serviceLine.labor_amount"
                            )
                            ListItem(
                                default-title="N/a"
                                dollar-filter
                                indent
                                subtitle="Labor Cost"
                                :title="serviceLine.labor_cost"
                            )
                            ListItem(
                                default-title="N/a"
                                dollar-filter
                                indent
                                subtitle="Labor Profit"
                                :title="serviceLine.labor_profit"
                            )
                            ListItem(
                                default-title="N/a"
                                indent
                                subtitle="Labor Type"
                                :title="serviceLine.labor_type"
                            )
                            v-divider(inset)
                            ListItem(
                                default-title="N/a"
                                dollar-filter
                                icon="mdi-tools"
                                indent
                                subtitle="Parts Amount"
                                :title="serviceLine.parts_amount"
                            )
                            ListItem(
                                default-title="N/a"
                                dollar-filter
                                indent
                                subtitle="Parts Cost"
                                :title="serviceLine.parts_cost"
                            )
                            ListItem(
                                default-title="N/a"
                                dollar-filter
                                indent
                                subtitle="Parts Profit"
                                :title="serviceLine.parts_profit"
                            )
                            v-divider(inset)
                            ListItem(
                                default-title="N/a"
                                dollar-filter
                                icon="mdi-invoice-plus"
                                indent
                                subtitle="Misc Amount"
                                :title="serviceLine.misc_amount"
                            )
                            ListItem(
                                default-title="N/a"
                                dollar-filter
                                indent
                                subtitle="Misc Cost"
                                :title="serviceLine.misc_cost"
                            )
                            ListItem(
                                default-title="N/a"
                                dollar-filter
                                indent
                                subtitle="Misc Profit"
                                :title="serviceLine.misc_profit"
                            )
                            v-divider(inset)
                            ListItem(
                                default-title="N/a"
                                icon="mdi-invoice-list"
                                indent
                                subtitle="Repair Order Number"
                                :title="serviceLine.ro_number"
                            )
</template>

<script lang="ts">
    import Vue from 'vue';

    // Components
    import ExpandableCardListItem from '@/components/shared/expandable_card_list_item.vue';
    import ListItem from '@/components/shared/list_item.vue';

    // Helpers
    import { resourceColorClass, } from "@/helpers/css_classes";

    export default Vue.extend({
        data() {
            return {
                serviceLineCardWidth: 300,
				showDetails: false,
            }
        },
        methods: {
            handleServiceDetailsResize() {
                const serviceDetailsWidth = document?.getElementById('service-details-card')?.getBoundingClientRect()?.width
                if (serviceDetailsWidth >= 364) {
                    this.serviceLineCardWidth = serviceDetailsWidth - 64
                } else {
                    this.serviceLineCardWidth = 300
                }
            },
        },
        computed: {
            itemColor() {
                return resourceColorClass("Service");
            },
            serviceLineCardTitle() {
                return this.serviceLine?.operation_description?.length > 0
                    ? 'Op Description: ' + this.serviceLine.operation_description
                    : this.serviceLine.operation_code?.length > 0
                        ? 'Op Code: ' + this.serviceLine.operation_code
                        : this.serviceLine.labor_type?.length > 0
                            ? 'Labor Type: ' + this.serviceLine.labor_type
                            : this.serviceLine.line_number > 0
                                ? 'Line # ' + (this.serviceLine.line_number)
                                : this.serviceLine.status?.length > 0
                                    ? 'Status: ' + this.serviceLine.status
                                    : this.serviceLine.friendly_created_at
            },
            validServiceLine() {
                return this.serviceLine?.service_line_id > 0;
            },
        },
        components: {
            ExpandableCardListItem,
            ListItem,
        },
        props: {
            serviceLine: Object,
        },
        watch: {
            serviceLine: {
                handler() {
                    Vue.nextTick(() => { this.handleServiceDetailsResize(); });
                },
                deep: true,
            },
        },
        created() {
			window.addEventListener('resize', this.handleServiceDetailsResize);
        },
        destroyed() {
            window.removeEventListener('resize', this.handleServiceDetailsResize);
        },
        mounted() {
            this.handleServiceDetailsResize();
        },
    });
</script>

<style lang="scss" scoped>
    #app {
        .cell-value-spacing {
            padding: 0 0 5px 0;
        }
    }
</style>
