import { get, post } from '.';

export function GetEleadVehicleClasses(eleadSubscriptionVid: string, options = {}) {
	return get<any>("/app/api/elead/subscriptions/" + eleadSubscriptionVid + "/vehicle/classes", options);
}

export function ImportEleadVehicleClasses(options = {}) {
	return post<any>("/app/api/elead/vehicle/classes/import", options);
}

export function GetEleadVehicleMakes(eleadSubscriptionVid: string, options = {}) {
	return get<any>("/app/api/elead/subscriptions/" + eleadSubscriptionVid + "/vehicle/makes", options);
}

export function ImportEleadVehicleMakes(options = {}) {
	return post<any>("/app/api/elead/vehicle/makes/import", options);
}

export function GetEleadVehicleModels(eleadSubscriptionVid: string, options = {}) {
	return get<any>("/app/api/elead/subscriptions/" + eleadSubscriptionVid + "/vehicle/models", options);
}

export function ImportEleadVehicleModels(options = {}) {
	return post<any>("/app/api/elead/vehicle/models/import", options);
}

export function GetEleadVehicleYears(eleadSubscriptionVid: string, options = {}) {
	return get<any>("/app/api/elead/subscriptions/" + eleadSubscriptionVid + "/vehicle/years", options);
}

export function ImportEleadVehicleYears(options = {}) {
	return post<any>("/app/api/elead/vehicle/years/import", options);
}
