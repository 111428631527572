<template lang="pug">
    v-card.no-padding(outlined tile v-if="renderCustomerProfile && currentUserActivatedAccountMatchesCustomerAccount")
        v-toolbar(color="grey lighten-4" light dense)
            v-btn.mr-sm-2(color="#FFFFFF" light small v-if="validExit" @click="exit();")
                | Exit
                v-icon
                    | mdi-close
            v-icon(style="margin-right: 5px;") mdi-account
            v-toolbar-title {{ currentCampaignCustomer.customer.full_name }}
            v-spacer
            SharedLinksDialog(
                v-if="renderSharedLinks"
                :account-id="currentCampaignCustomer.account_id"
                :auto-create="autoCreateSharedLink"
                component="customer_profile"
                :shareable-id="currentCampaignCustomer.customer_id"
                shareable-type="customer"
                :share-button-classes="shareButtonClassesForSharedLinkDialog"
            )
            SkipRecord(
                v-if="renderSkipRecord"
                :call-id="callId"
                :current-campaign-customer="currentCampaignCustomer"
                :handle-skipped-campaign-customer="handleSkippedCampaignCustomer"
            )
            SaveForLater(
                v-if="renderSaveForLater"
                :communication-type="communicationType"
                :current-campaign-customer="currentCampaignCustomer"
                :unassign-campaign-customer-active="unassignCampaignCustomerActive"
            )
            ScheduledCommunicationDetails(
                v-if="renderScheduledCommunicationDetails"
                :scheduled-communication-id="currentScheduledCommunicationID"
            )
            VoiceMailRecordingDialog(
                v-if="renderVoiceMailRecordingDetails"
                :call-id="callId"
                :end-voice-mail-recording="endVoiceMailRecording"
                :show-snackbar="showSnackbar"
                :start-force-start-next-dial="startForceStartNextDial"
                :voice-mail-recording="voiceMailRecording"
            )
            v-btn.white--text(
                v-if="renderCompleteCampaignCustomerActive"
                @click="completeCampaignCustomerActive"
                color="warning"
                :disabled="completeButtonDisabled"
                small
            )
                v-icon mdi-check
                | Complete
        v-row(
            no-gutters
            v-bind:style="{ height: cardBodyHeight + 'px' }"
        )
            v-col.col-right-divider(cols='6')
                v-tabs(grow show-arrows light v-model="leftCurrentTab")
                    template(v-if="leftItems && leftItems.length > 2")
                        v-tab(v-for='item in leftItems' :key='item.key' :href='"#" + item.href' class="left-contact-tab")
                            v-tooltip(top)
                                template(v-slot:activator='{ on, attrs }')
                                    span(v-bind="attrs" v-on="on" role="text")
                                        v-icon {{item.icon}}
                                span {{ item.name }}
                    template(v-else)
                        v-tab(v-for='item in leftItems' :key='item.key' :href='"#" + item.href' class="left-contact-tab")
                            | {{ item.name }}

                v-tabs-items(v-model="leftCurrentTab")
                    CustomerTab.overflow-y-auto(
                        v-show="leftCurrentTab === 'customer-tab'"
                        :audio-email-incoming="audioEmailIncoming"
                        :audio-email-outcoming="audioEmailOutcoming"
                        :current-campaign-customer="currentCampaignCustomer"
                        v-bind:style="{ height: tabContentHeight + 'px' }"
                        :call="call"
                        :force-subscription-changes-response="forceSubscriptionChangesResponse"
                        :handle-update-current-campaign-customer="handleUpdateCurrentCampaignCustomer"
                        :hide-create-update-destroy-buttons="hideCreateUpdateDestroyButtons"
                        :in-communication-center="inCommunicationCenter"
                        :is-rac-online="isRacOnline"
                        :parent-height="tabContentHeight"
                        :reassign-campaign-customer-campaign="reassignCampaignCustomerCampaign"
                        :set-communication-manager="setCommunicationManager"
                    )
                    template(v-if="campaignCustomerActiveID > 0")
                        DispositionTab.overflow-y-auto(
                            v-show="leftCurrentTab === 'disposition-tab'"
                            :autobuy-communication-disposition="autobuyCommunicationDisposition"
                            :call-failed="callFailed"
                            :call-id="callId"
                            :communication-manager="communicationManager"
                            :communication-type="communicationType"
                            :create-agent="createAgent"
                            :create-survey-attempt="createSurveyAttempt"
                            :current-campaign-customer="currentCampaignCustomer"
                            :initiate-create-communication-disposition="initiateCreateCommunicationDisposition"
                            :is-manual-mode="isManualMode"
                            :is-paused-voice-mail-recording="isPausedVoiceMailRecording"
                            :parent-disposition-notes="parentDispositionNotes"
                            :set-change-on-current-campaign-customer="setChangeOnCurrentCampaignCustomer"
                            :set-is-okay-to-create-communication-disposition="setIsOkayToCreateCommunicationDisposition"
                            :set-selected-campaign-disposition="setSelectedCampaignDisposition"
                            :set-subscription-changes-on-communication-manager="setSubscriptionChangesOnCommunicationManager"
                            :start-save-and-next="startSaveAndNext"
                            :sync-disposition-notes="syncDispositionNotes"
                            :tekion-appointment-details="tekionAppointmentDetails"
                            v-bind:style="{ height: tabContentHeight + 'px' }"
                            :voice-mail-recording="voiceMailRecording"
                        )
                    ProspectsTab.overflow-y-auto(
                        v-if="leftCurrentTab === 'prospects-tab'"
                        :current-campaign-customer="currentCampaignCustomer"
                        v-bind:style="{ height: tabContentHeight + 'px' }"
                    )
                    TimelineTab.overflow-y-auto(
                        v-if="leftCurrentTab === 'timeline-tab'"
                        :customer-id="currentCampaignCustomer.customer_id"
                        :organization-id="currentCampaignCustomer.organization_id"
                        :source="source"
                        v-bind:style="{ height: tabContentHeight + 'px' }"
                    )

            v-col.col-left-divider(cols='6')
                v-tabs(grow show-arrows light v-model='rightCurrentTab')
                    v-tab(v-for='item in rightItems' :key='item.key' :href='"#" + item.href')
                        | {{ item.name }}
                    v-menu(v-if='rightMore.length > 0' bottom left)
                        template(v-slot:activator='{ on }')
                            v-btn.align-self-center.mr-4(text v-on='on')
                                | more
                                v-icon(right) mdi-menu-down
                        v-list.grey.lighten-3
                            v-list-item(v-for='item in rightMore' :key='item.key' @click='addRightItem(item)')
                                | {{ item.name }}

                v-tabs-items
                    AppointmentHistoryTab.overflow-y-auto(
                        v-if="rightCurrentTab === 'appointment-history-tab'"
                        :current-campaign-customer="currentCampaignCustomer"
                        v-bind:style="{ height: tabContentHeight + 'px' }"
                    )
                    CampaignEnrollmentHistoryTab.overflow-y-auto(
                        v-if="rightCurrentTab === 'campaign-enrollment-history-tab'"
                        :current-campaign-customer="currentCampaignCustomer"
                        v-bind:style="{ height: tabContentHeight + 'px' }"
                    )
                    CommunicationDispositionsTab.overflow-y-auto(
                        v-if="rightCurrentTab === 'communication-dispositions-tab'"
                        :current-campaign-customer="currentCampaignCustomer"
                        :set-change-on-current-campaign-customer="setChangeOnCurrentCampaignCustomer"
                        v-bind:style="{ height: tabContentHeight + 'px' }"
                    )
                    CommunicationHistoryTab.overflow-y-auto(
                        v-if="rightCurrentTab === 'communication-history-tab'"
                        :current-campaign-customer="currentCampaignCustomer"
                        :source="source"
                        v-bind:style="{ height: tabContentHeight + 'px' }"
                    )

                    keep-alive
                        AutobuyCrmTab.overflow-y-auto(
                            v-if="rightCurrentTab === 'autobuy-tab'"
                            :current-campaign-customer="currentCampaignCustomer"
                            :on-autobuy-communication-disposition-change="onAutobuyCommunicationDispositionChange"
                            :show-snackbar="showSnackbar"
                            v-bind:style="{ height: tabContentHeight + 'px' }"
                        )
                        ContactsTab.overflow-y-auto(
                            v-if="rightCurrentTab === 'contacts-tab'"
                            :call-id="callId"
                            :end-call="endCall"
                            :on-call="onCall"
                            :open-transfer-dialog="openTransferDialog"
                            :organization-id="currentCampaignCustomer.organization_id"
                            v-bind:style="{ height: tabContentHeight + 'px' }"
                        )
                        CustomFieldsTab.overflow-y-auto(
                            v-if="rightCurrentTab === 'custom-fields-tab'"
                            :account-id="currentCampaignCustomer.account_id"
                            :campaign-customer-id="currentCampaignCustomer.campaign_customer_id"
                            :customer-id="currentCampaignCustomer.customer_id"
                            :hide-create-update-destroy-buttons="hideCreateUpdateDestroyButtons"
                            :organization-id="currentCampaignCustomer.organization_id"
                            v-bind:style="{ height: tabContentHeight + 'px' }"
                        )
                        CustomerVehiclesTab.overflow-y-auto(
                            v-if="rightCurrentTab === 'customer-vehicles-tab'"
                            :current-campaign-customer="currentCampaignCustomer"
                            :hide-create-update-destroy-buttons="hideCreateUpdateDestroyButtons"
                            v-bind:style="{ height: tabContentHeight + 'px' }"
                            :change-tab="goToToyoguardTab"
                            :in-communication-center="inCommunicationCenter"
                        )
                        EleadTab.overflow-y-auto(
                            v-if="isEleadEnabled && rightCurrentTab === 'elead-tab'"
                            :current-campaign-customer="currentCampaignCustomer"
                            v-bind:style="{ height: tabContentHeight + 'px' }"
                        )
                        ExternalLinksTab.overflow-y-auto(
                            v-if="rightCurrentTab === 'external-links-tab'"
                            :campaign-id="currentCampaignCustomer.campaign_id"
                            :customer-id="currentCampaignCustomer.customer_id"
                            :organization-id="currentCampaignCustomer.organization_id"
                            v-bind:style="{ height: tabContentHeight + 'px' }"
                        )
                        ForteTab.overflow-y-auto(
                            v-if="rightCurrentTab === 'forte-tab'"
                            :current-campaign-customer="currentCampaignCustomer"
                            v-bind:style="{ height: tabContentHeight + 'px' }"
                        )
                        GMROHistory.overflow-y-auto(
                            v-if="isGmBacEnabled && rightCurrentTab === 'gm-bac-tab'"
                            :current-campaign-customer="currentCampaignCustomer"
                            v-bind:style="{ height: tabContentHeight + 'px' }"
                        )
                        InlineTab.overflow-y-auto(
                            v-if="rightCurrentTab === 'inline-tab'"
                            :current-campaign-customer="currentCampaignCustomer"
                            v-bind:style="{ height: tabContentHeight + 'px' }"
                        )
                        OrganizationTab.overflow-y-auto(
                            v-if="rightCurrentTab === 'organization-tab'"
                            :campaign-id="currentCampaignCustomer.campaign_id"
                            :organization-id="currentCampaignCustomer.organization_id"
                            v-bind:style="{ height: tabContentHeight + 'px' }"
                        )
                        PromotionsTab.overflow-y-auto(
                            v-if="rightCurrentTab === 'promotions-tab'"
                            :campaign-id="currentCampaignCustomer.campaign_id"
                            :customer-id="currentCampaignCustomer.customer_id"
                            :organization-id="currentCampaignCustomer.organization_id"
                            v-bind:style="{ height: tabContentHeight + 'px' }"
                        )
                        RasaniPingPostTab.overflow-y-auto(
                            v-if="isRasaniEnabled && rightCurrentTab === 'rasani-ping-post-tab'"
                            :call-id="callId"
                            :current-campaign-customer="currentCampaignCustomer"
                            :end-call="endCall"
                            :on-call="onCall"
                            v-bind:style="{ height: tabContentHeight + 'px' }"
                        )
                        SalesTab.overflow-y-auto(
                            v-if="rightCurrentTab === 'sales-tab'"
                            :current-campaign-customer="currentCampaignCustomer"
                            v-bind:style="{ height: tabContentHeight + 'px' }"
                        )
                        ScheduledCommunicationsTab.overflow-y-auto(
                            v-if="rightCurrentTab === 'scheduled-communications-tab'"
                            :current-campaign-customer="currentCampaignCustomer"
                            :hide-create-update-destroy-buttons="hideCreateUpdateDestroyButtons"
                            v-bind:style="{ height: tabContentHeight + 'px' }"
                        )
                        ServicesTab.overflow-y-auto(
                            v-if="rightCurrentTab === 'services-tab'"
                            :current-campaign-customer="currentCampaignCustomer"
                            v-bind:style="{ height: tabContentHeight + 'px' }"
                        )
                        TekionTab.overflow-y-auto(
                            v-if="rightCurrentTab === 'tekion-tab'"
                            :current-campaign-customer="currentCampaignCustomer"
                            :in-communication-center="inCommunicationCenter"
                            :set-tekion-appointment-details="setTekionAppointmentDetails"
                            :set-appointment-id-for-tekion="setAppointmentIdForTekion"
                            v-bind:style="{ height: tabContentHeight + 'px' }"
                        )
                        TelematicAlertsTab.overflow-y-auto(
                            v-if="rightCurrentTab === 'telematic-alerts-tab'"
                            :customer-id="customerID"
                            :current-campaign-customer="currentCampaignCustomer"
                            v-bind:style="{ height: tabContentHeight + 'px' }"
                        )
                        ToyoguardTab.overflow-y-auto(
                            v-if="rightCurrentTab === 'toyoguard-tab'"
                            :current-campaign-customer="currentCampaignCustomer"
                            v-bind:style="{ height: tabContentHeight + 'px' }"
                        )
                        VehicleInventoryTab.overflow-y-auto(
                            v-if="rightCurrentTab === 'vehicle-inventories-tab'"
                            :organization-id="currentCampaignCustomer.organization_id"
                            :force-organization-users="organizationUsers"
                            inventory-status="in_stock"
                            v-bind:style="{ height: tabContentHeight + 'px' }"
                        )
                        AutoAssistantTab.overflow-y-auto(
                            v-if="rightCurrentTab === 'auto-assistant-tab'"
                            :call-id="callId"
                            :customer-id="customerID"
                            :in-communication-center="inCommunicationCenter"
                            :auto-assist-data="autoAssistData"
                            :transferred-from-auto-assist="hasAutoAssistData"
                            v-bind:style="{ height: tabContentHeight + 'px' }"
                        )

    v-alert( v-else-if="renderCustomerProfile && !currentUserActivatedAccountMatchesCustomerAccount" outlined type='warning' dense border='left' style="margin-top: 0px; max-height: 41px !important; background-color: white !important;")
        | User account does not match customer account

</template>

<script lang="ts">
    import Vue from 'vue';
    import VolieState from '@/store';

    // Components
    import AppointmentHistoryTab from '@/components/communication_center/shared/tabs/appointment_history.vue';
    import AutoAssistantTab from '@/components/communication_center/shared/tabs/auto_assistant.vue';
    import AutobuyCrmTab from '@/components/communication_center/shared/tabs/autobuy_crm.vue';
    import CampaignEnrollmentHistoryTab from '@/components/communication_center/shared/tabs/campaign_enrollment_history.vue';
    import CommunicationDispositionsTab from '@/components/communication_center/shared/tabs/communication_dispositions.vue';
    import CommunicationHistoryTab from '@/components/communication_center/shared/tabs/communication_history.vue';
    import ContactsTab from '@/components/communication_center/shared/tabs/contacts.vue';
    import CustomerTab from '@/components/communication_center/shared/tabs/customer.vue';
    import CustomerVehiclesTab from '@/components/communication_center/shared/tabs/customer_vehicles.vue';
    import CustomFieldsTab from '@/components/communication_center/shared/tabs/custom_fields.vue';
    import DispositionTab from '@/components/communication_center/shared/tabs/disposition.vue';
    import EleadTab from '@/components/communication_center/shared/tabs/elead.vue';
    import ExternalLinksTab from '@/components/communication_center/shared/tabs/external_links.vue';
    import ForteTab from '@/components/communication_center/shared/tabs/forte_crm.vue';
    import GMROHistory from '@/components/communication_center/shared/tabs/gm_ro_history.vue';
    import InlineTab from '@/components/communication_center/shared/tabs/inline_crm.vue';
    import OrganizationTab from '@/components/communication_center/shared/tabs/organization.vue';
    import PromotionsTab from '@/components/communication_center/shared/tabs/promotions.vue';
    import ProspectsTab from '@/components/communication_center/shared/tabs/prospects.vue';
    import RasaniPingPostTab from '@/components/communication_center/shared/tabs/rasani_ping_post.vue';
    import SalesTab from '@/components/communication_center/shared/tabs/sales.vue';
    import SaveForLater from '@/components/communication_center/shared/save_for_later.vue';
    import ScheduledCommunicationDetails from '@/components/scheduled_communications/details_dialog.vue';
    import ScheduledCommunicationsTab from '@/components/communication_center/shared/tabs/scheduled_communications.vue';
    import ServicesTab from '@/components/communication_center/shared/tabs/services.vue';
    import SharedLinksDialog from '@/components/shared_links/dialog.vue';
    import SkipRecord from '@/components/communication_center/shared/skip_record.vue';
    import TekionTab from '@/components/communication_center/shared/tabs/tekion.vue';
    import TelematicAlertsTab from '@/components/communication_center/shared/tabs/telematic_alerts.vue';
    import TimelineTab from '@/components/customers/timeline.vue';
    import ToyoguardTab from '@/components/communication_center/shared/tabs/toyoguard.vue';
    import VehicleInventoryTab from '@/components/communication_center/shared/tabs/vehicle_inventories.vue';
    import VoiceMailRecordingDialog from '@/components/voice_mail_recordings/dialog.vue';


    // Helpers
    import {
        getInCommunicationCenterCustomerProfileLeftTabs,
        getNonCommunicationCenterCustomerProfileLeftTabs,
        getCustomerProfileRightTabs,
    } from '@/helpers/customer_profile';

    export default Vue.extend({
        data() {
            return {
                autobuyCommunicationDisposition: null,
                cardBodyHeight: null,
                forceSubscriptionChangesResponse: null,
                leftCurrentTab: null,
                leftItems: [],
                rightCurrentTab: null,
                rightItems: [],
                rightMore: [],
                tabContentHeight: null,
                toyoguardEnabled: false,
                tekionAppointmentDetails: null,
            }
        },
        methods: {
            addRightItem (item) {
                const removed = this.rightItems.splice(0, 1);
                this.rightItems.push(
                    ...this.rightMore.splice(this.rightMore.indexOf(item), 1)
                );
                this.rightMore.push(...removed);
                this.rightMore.sort((a, b) => {
                    if (a.name < b.name) {
                        return -1
                    }
                    if (a.name > b.name) {
                        return 1
                    }
                    return 0
                });

                this.rightCurrentTab = item.href;
            },
            goToToyoguardTab() {
                this.rightMore.forEach((item) => {
                    if (item.key === 'toyoguard') {
                        this.addRightItem(item);
                    }
                });
            },
            handleWindowResize() {

                // Declare constants
                const paddingPx = 2;
                const toolbarHeight = 48;
                const tabBarHeight = 48;
                const colPaddingAndMargin = 47.109;

                // Calcuate
                this.cardBodyHeight = this.customerProfileHeight - paddingPx - toolbarHeight;
                this.tabContentHeight = this.cardBodyHeight - tabBarHeight - colPaddingAndMargin;
            },
            onAutobuyCommunicationDispositionChange(autobuyCommunicationDisposition) {

                // Verify
                if (!autobuyCommunicationDisposition) {
                    return;
                }

                // Set
                this.autobuyCommunicationDisposition = autobuyCommunicationDisposition;
            },
            setCustomerProfileLeftMoreTabs() {

                // If we aren't in communication center, just give them the customer info and prospect tab (we don't care about default tab here since we are actually on the show page)
                if (!this.inCommunicationCenter) {
                    this.leftItems = getNonCommunicationCenterCustomerProfileLeftTabs();
                    this.leftCurrentTab = this.leftItems[0].href;
                    return;
                }

                const leftTabs = getInCommunicationCenterCustomerProfileLeftTabs();

                // set default tab
                const systemDefaultTab = "customer";
                let defaultTab = systemDefaultTab;

                // Check account user default
                if (this.currentUser?.account_user?.default_left_tab_on_communication_center?.length > 0) {
                    defaultTab = this.currentUser.account_user.default_left_tab_on_communication_center;
                }

                // if default tab setting is a hidden tab determine which tab should be open
                if (this.hiddenTabs?.includes(defaultTab)) {

                    // reset to system default tab if system default not hidden
                    if (!this.hiddenTabs?.includes(systemDefaultTab)) {
                        defaultTab = systemDefaultTab;
                    } else { // if system default tab is hidden then open to first available tab alphabetically
                        for (let i = 0; i < leftTabs.length; i++) {
                            if (!this.hiddenTabs?.includes(leftTabs[i].key)) {
                                defaultTab = leftTabs[i].key;
                                break;
                            }
                        }
                    }
                }

                // filter out hidden tabs
                const permittedTabs = [];
                for (let i = 0; i < leftTabs.length; i++) {
                    if (!this.hiddenTabs?.includes(leftTabs[i].key)) {
                        permittedTabs.push(leftTabs[i]);
                    }
                }

                // if 2 or less tabs

                this.leftItems = permittedTabs;

                for (let i = 0; i < permittedTabs.length; i++) {
                    if (permittedTabs[i].key === defaultTab) {
                        this.leftCurrentTab = permittedTabs[i].href;
                        break;
                    }
                }

                return;

            },
            setCustomerProfileRightMoreTabs() {
                this.rightMore = [];
                this.rightItems = [];
                this.rightCurrentTab = null;

                const options = {
                    autobuyCrmEnabled: this.isAutobuyCrmEnabled,
                    autoAssistantName: this.hasAutoAssistData ? this.autoAssistData?.auto_assistant_type || "" : "stella",
                    forteCrmEnabled: this.isForteCrmEnabled,
                    gmBacEnabled: this.isGmBacEnabled,
                    inCommunicationCenter: this.inCommunicationCenter,
                    inlineCrmEnabled: this.isInlineCrmEnabled,
                    eleadEnabled: this.isEleadEnabled,
                    rasaniEnabled: this.isRasaniEnabled,
                    tekionEnabled: this.isTekionEnabled,
                    toyoguardEnabled: this.isToyoguardEnabled,
                    hasAutoAssist: this.hasAutoAssistData,
                    stellaEnabled: this.isStellaEnabled,
                }

                const rightTabs = getCustomerProfileRightTabs(options);

                // set default tab
                const systemDefaultTab = "communication_history";
                let defaultTab = systemDefaultTab;

                // Check integration tabs
                if (this.isAutobuyCrmEnabled) {
                    defaultTab = "autobuy";
                } else if (this.isForteCrmEnabled) {
                    defaultTab = "forte";
                } else if (this.isInlineCrmEnabled) {
                    defaultTab = "inline";
                } else if (this.isGmBacEnabled) {
                    defaultTab = 'gm-bac'
                } else if (this.isTekionEnabled) {
                    defaultTab = 'tekion'
                } else {

                    // Check account user default
                    if (this.currentUser?.account_user?.default_right_tab_on_communication_center?.length > 0) {
                        defaultTab = this.currentUser.account_user.default_right_tab_on_communication_center;
                    }

                    // if default tab setting is a hidden tab determine which tab should be open
                    if (this.hiddenTabs?.includes(defaultTab)) {

                        // reset to system default tab if system default not hidden
                        if (!this.hiddenTabs?.includes(systemDefaultTab)) {
                            defaultTab = systemDefaultTab;
                        } else { // if system default tab is hidden then open to first available tab alphabetically
                            for (let i = 0; i < rightTabs.length; i++) {
                                if (!this.hiddenTabs?.includes(rightTabs[i].key)) {
                                    defaultTab = rightTabs[i].key;
                                    break;
                                }
                            }
                        }
                    }
                }

                if (this.hasAutoAssistData) {
                    defaultTab = 'auto-assistant'
                }

                // filter out hidden tabs
                const permittedTabs = [];
                for (let i = 0; i < rightTabs.length; i++) {
                    if (!this.hiddenTabs?.includes(rightTabs[i].key)) {
                        permittedTabs.push(rightTabs[i]);
                    }
                }

                // if 2 or less tabs
                if (permittedTabs.length <= 2) {
                    this.rightItems = permittedTabs;

                    for (let i = 0; i < permittedTabs.length; i++) {
                        if (permittedTabs[i].key === defaultTab) {
                            this.rightCurrentTab = permittedTabs[i].href;
                            break;
                        }
                    }

                    return;
                }

                // if 3 or more tabs
                for (let i = 0; i < permittedTabs.length; i++) {
                    if (permittedTabs[i].key === defaultTab) {
                        this.rightItems = [permittedTabs[i]];
                    } else {
                        this.rightMore.push(permittedTabs[i]);
                    }
                }
            },
            setCustomerProfileTabs() {
                this.setCustomerProfileLeftMoreTabs();
                this.setCustomerProfileRightMoreTabs();
            },
            setSubscriptionChangesOnCommunicationManager(response) {
                this.forceSubscriptionChangesResponse = { ...response };
            },
            setTekionAppointmentDetails(tekionAppointmentDetails) {
                // Verify
                if (!tekionAppointmentDetails) {
                    return;
                }
                this.tekionAppointmentDetails = tekionAppointmentDetails;

            },
            setAppointmentIdForTekion(appointmentId) {
                if (!appointmentId || appointmentId === 0) {
                    return;
                }
                
                this.tekionAppointmentDetails.appointment_id = appointmentId;
                return;
            }
        },
        computed: {
            autoCreateSharedLink() {
                return this.inCommunicationCenter;
            },
            campaignCustomerActiveID() {
                return this.currentCampaignCustomer?.campaign_customer_active_id;
            },
            completeButtonDisabled() {
                return (!(this.currentCampaignCustomer?.total_communication_dispositions > 0));
            },
            currentScheduledCommunicationID() {
                return this.currentCampaignCustomer?.current_scheduled_communication_id;
            },
            currentUser() {
                return VolieState.currentUser();
            },
            currentUserActivatedAccountMatchesCustomerAccount() {
                if (this.currentUser === null) {
                    return true;
                }
                return this.currentUser?.activated_account_id > 0 && this.currentUser?.activated_account_id === this.currentCampaignCustomer?.customer?.account_id;
            },
            currentUserIsSuperAdmin() {
                return this.currentUser?.activated_role_id === 1;
            },
            customerID() {
                return this.currentCampaignCustomer?.customer_id;
            },
            hiddenTabs() {
                if (this.currentUser?.account_user?.hidden_tabs?.length > 0) {
                    return this.currentUser.account_user.hidden_tabs;
                }

                return [];
            },
            isAutobuyCrmEnabled() {
                return this.currentUser?.account?.autobuy_crm_enabled === true && this.isValidCampaignCustomerActive;
            },
            isEleadEnabled() {
                return this.isValidCampaignCustomerActive && this.currentCampaignCustomer?.elead_enabled === true;
            },
            isForteCrmEnabled() {
                return this.currentUser?.account?.forte_crm_enabled === true && this.isValidCampaignCustomerActive && this.currentCampaignCustomer?.forte_crm_key_id > 0;
            },
            isInlineCrmEnabled() {
                return this.currentUser?.account?.inline_crm_enabled === true && this.isValidCampaignCustomerActive && this.currentCampaignCustomer?.inline_crm_key?.length > 0;
            },
            isRasaniEnabled() {
                return this.currentUser?.account?.rasani_enabled === true;
            },
            isGmBacEnabled() {
                return this.currentUser?.account?.gm_ro_history_enabled === true && this.isValidCampaignCustomerActive && this.currentCampaignCustomer?.gm_ro_bac_is_valid;
            },
            isTekionEnabled() {
                return this.currentUserIsSuperAdmin && this.currentCampaignCustomer?.tekion_enabled;
            },
            isToyoguardEnabled() {
                return this.currentCampaignCustomer?.toyoguard_enabled;
            },
            isStellaEnabled() {
                return this.currentCampaignCustomer?.stella_enabled;
            },
            isValidCampaignCustomerActive() {
                return this.inCommunicationCenter ? this.campaignCustomerActiveID > 0 : this.currentCampaignCustomer?.customer_id > 0;
            },
            renderCompleteCampaignCustomerActive() {
                return (this.communicationType === 'Email' || this.communicationType === 'SMS Message');
            },
            renderCustomerProfile() {
                return this.isValidCampaignCustomerActive;
            },
            renderSaveForLater() {
                return (this.communicationType === 'Email' || this.communicationType === 'SMS Message') && ((this.currentCampaignCustomer?.expiration_code || 0) !== 11);
            },
            renderScheduledCommunicationDetails() {
                return this.currentScheduledCommunicationID > 0;
            },
            renderSharedLinks() {
                return this.currentUser?.account?.shared_links_enabled === true;
            },
            renderSkipRecord() {
                return this.isLockedAgentStatus !== true && this.communicationType === 'Phone' && !this.isManualMode && !this.callId && !this.voiceMailRecording;
            },
            renderVoiceMailRecordingDetails() {
                return this.voiceMailRecording?.voice_mail_recording_id > 0;
            },
            shareButtonClassesForSharedLinkDialog() {
                return this.inCommunicationCenter ? "mr-4" : null;
            },
            validExit() {
                return this.exit !== undefined && this.exit !== null;
            },
        },
        components: {
            AppointmentHistoryTab,
            AutoAssistantTab,
            AutobuyCrmTab,
            CampaignEnrollmentHistoryTab,
            CommunicationDispositionsTab,
            CommunicationHistoryTab,
            ContactsTab,
            CustomerTab,
            CustomerVehiclesTab,
            CustomFieldsTab,
            DispositionTab,
            EleadTab,
            ExternalLinksTab,
            ForteTab,
            GMROHistory,
            InlineTab,
            OrganizationTab,
            PromotionsTab,
            ProspectsTab,
            RasaniPingPostTab,
            SalesTab,
            SaveForLater,
            ScheduledCommunicationDetails,
            ScheduledCommunicationsTab,
            ServicesTab,
            SharedLinksDialog,
            SkipRecord,
            TekionTab,
            TelematicAlertsTab,
            TimelineTab,
            ToyoguardTab,
            VehicleInventoryTab,
            VoiceMailRecordingDialog,
        },
        props: {
            autoAssistData: Object,
            audioEmailIncoming: HTMLAudioElement,
            audioEmailOutcoming: HTMLAudioElement,
            call: Object,
            callFailed: Boolean,
            callId: Number,
            communicationManager: Object,
            communicationType: String,
            completeCampaignCustomerActive: Function,
            createAgent: Function,
            createSurveyAttempt: Function,
            currentCampaignCustomer: Object,
            customerProfileHeight: Number,
            endCall: Function,
            endVoiceMailRecording: Function,
            exit: Function,
            hasAutoAssistData: Boolean,
            hideCreateUpdateDestroyButtons: Boolean,
            inCommunicationCenter: Boolean,
            initiateCreateCommunicationDisposition: Boolean,
            isLockedAgentStatus: Boolean,
            isManualMode: Boolean,
            isPausedVoiceMailRecording: Boolean,
            isRacOnline: Boolean,
            isSupervisor: Boolean,
            handleSkippedCampaignCustomer: Function,
            handleUpdateCurrentCampaignCustomer: Function,
            onCall: Boolean,
            openTransferDialog: Function,
            organizationUsers: Array,
            parentDispositionNotes: String,
            reassignCampaignCustomerCampaign: Function,
            setChangeOnCurrentCampaignCustomer: Function,
            setCommunicationManager: Function,
            setIsOkayToCreateCommunicationDisposition: Function,
            setSelectedCampaignDisposition: Function,
            showSnackbar: Function,
            source: String,
            startForceStartNextDial: Function,
            startSaveAndNext: Function,
            syncDispositionNotes: Function,
            unassignCampaignCustomerActive: Function,
            voiceMailRecording: Object,
        },
        destroyed() {
            window.removeEventListener('resize', this.handleWindowResize);
        },
        created() {
            window.addEventListener('resize', this.handleWindowResize);
            this.setCustomerProfileTabs();
        },
        mounted() {
            this.handleWindowResize();
        },
        watch: {
            customerProfileHeight() {
                Vue.nextTick(() => { this.handleWindowResize(); });
            },
        },
    });
</script>

<style lang="scss" scoped>
    #app {
        .col-right-divider {
            border-right: 1px solid #F5F5F5;
            margin-top: 1%;
            margin-bottom: 2%;
            padding: 10px 20px 10px 20px;
        }

        .col-left-divider {
            border-left: 1px solid #F5F5F5;
            margin-top: 1%;
            margin-bottom: 2%;
            padding: 10px 20px 10px 20px;
        }

    }
    @media only screen and (min-width: 1264px) and (max-width: 1525px) {
        .left-contact-tab {
            font-size: 0.8vw;
        }
    }
</style>
