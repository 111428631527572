
import { get, post } from '.';

export function GetEleadEmails(eleadSubscriptionVid: string, options = {}) {
	return get<any>("/app/api/elead/subscriptions/" + eleadSubscriptionVid + "/emails", options);
}

export function ImportEleadEmails(options = {}) {
	return post<any>("/app/api/elead/emails/import", options);
}
