import { get, post, put, destroy } from '.';

export function GetDispositions(options = {}) {
	return get<any>("/app/api/dispositions", options);
}

export function CreateDisposition(options = {}) {
	return post<any>("/app/api/dispositions", options);
}

export function DestroyDisposition(id, options = {}) {
	return destroy<any>("/app/api/dispositions/" + (id || 0).toString(), options);
}

export function FindDisposition(dispositionKey:string, options = {}) {
	return get<any>(`/app/api/dispositions/${dispositionKey}`, options);
}

export function UpdateDisposition(dispositionKey:string, options = {}) {
	return put<any>(`/app/api/dispositions/${dispositionKey}`, options);
}
