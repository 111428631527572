import { get, destroy, post, put } from '.';

export function GetCriterions(options = {}) {
	return get<any>("/app/api/criterions", options);
}

export function DestroyCriterion(id: string) {
	return destroy<any>(`/app/api/criterions/${id}`);
}

export function CreateCriterion(options = {}) {
	return post<any>("/app/api/criterions", options);
}

export function UpdateCriterion(id: string, options = {}) {
	return put<any>(`/app/api/criterions/${id}`, options);
}