<template lang="pug">
    v-card.mx-lg-auto.mt-md-4.mb-md-4(outlined width="100%" v-if="validProspect" id="prospect-details-card")
        v-toolbar(flat)
            v-btn(
                color="primary"
                icon
                ripple
                @click="showDetails = !showDetails;"
            )
                v-icon(v-if="showDetails === true")
                    | mdi-chevron-down
                v-icon(v-else)
                    | mdi-chevron-right

            v-toolbar-title(style="font-size: 16px;")
                | {{ prospectLead }}
                br
                span.text-body-2
                    | {{ vehicleYearMakeModel }}
                template(v-if="prospect.lead_status")
                    br
                    span.text-body-2
                        | {{ prospect.lead_status }}
            v-spacer
            .text-caption.text-right.m-l-5
                strong.text-uppercase Request Date: {{ prospect.friendly_request_date }}
                br
                strong.text-uppercase Created at: {{ prospect.friendly_created_at_datetime }}

        template(v-if="showDetails === true")
            v-divider
            v-card-text
                v-simple-table
                    template(v-slot:default)
                        tbody
                            tr
                                td
                                    .text-capitalize.text-overline.text--secondary.text--darken-3 Date
                                    .cell-value-spacing {{ prospect.friendly_request_date }}
                            tr
                                td
                                    .text-capitalize.text-overline.text--secondary.text--darken-3 Created At
                                    .cell-value-spacing {{ prospect.friendly_created_at_datetime }}
                            tr
                                td
                                    .text-capitalize.text-overline.text--secondary.text--darken-3 Lead Source
                                    .cell-value-spacing(v-if="prospect.lead_source && prospect.lead_source.length > 0") {{ prospect.lead_source }}
                                    .cell-value-spacing(v-else) N/a
                            tr
                                td
                                    .text-capitalize.text-overline.text--secondary.text--darken-3 Lead Id
                                    .cell-value-spacing(v-if="prospect.lead_key && prospect.lead_key.length > 0") {{ prospect.lead_key }}
                                    .cell-value-spacing(v-else) N/a
                            tr
                                td
                                    .text-capitalize.text-overline.text--secondary.text--darken-3 Last User Activity
                                    .cell-value-spacing(v-if="prospect.user_first_name || prospect.user_last_name || prospect.user_email") {{ prospect.user_first_name + ' ' + prospect.user_last_name }}
                                    .cell-value-spacing(v-else) N/a
                            tr
                                td
                                    .text-capitalize.text-overline.text--secondary.text--darken-3 Comments
                                    .cell-value-spacing(v-if="prospect.comments && prospect.comments.length > 0") {{ prospect.comments }}
                                    .cell-value-spacing(v-else) N/a
                            //tr
                            //    td
                            //        .text-capitalize.text-overline.text--secondary.text--darken-3 First Name
                            //        .cell-value-spacing(v-if="prospect.first_name && prospect.first_name.length > 0") {{ prospect.first_name }}
                            //        .cell-value-spacing(v-else) N/a
                            //tr
                            //    td
                            //        .text-capitalize.text-overline.text--secondary.text--darken-3 Middle Name
                            //        .cell-value-spacing(v-if="prospect.middle_name && prospect.middle_name.length > 0") {{ prospect.middle_name }}
                            //        .cell-value-spacing(v-else) N/a
                            //tr
                            //    td
                            //        .text-capitalize.text-overline.text--secondary.text--darken-3 Last Name
                            //        .cell-value-spacing(v-if="prospect.last_name && prospect.last_name.length > 0") {{ prospect.last_name }}
                            //        .cell-value-spacing(v-else) N/a
                            //tr
                            //    td
                            //        .text-capitalize.text-overline.text--secondary.text--darken-3 Cell Phone
                            //        .cell-value-spacing(v-if="prospect.cell_phone && prospect.cell_phone.length > 0") {{ prospect.cell_phone | tel }}
                            //        .cell-value-spacing(v-else) N/a
                            //tr
                            //    td
                            //        .text-capitalize.text-overline.text--secondary.text--darken-3 Home Phone
                            //        .cell-value-spacing(v-if="prospect.home_phone && prospect.home_phone.length > 0") {{ prospect.home_phone | tel }}
                            //        .cell-value-spacing(v-else) N/a
                            //tr
                            //    td
                            //        .text-capitalize.text-overline.text--secondary.text--darken-3 Work Phone
                            //        .cell-value-spacing(v-if="prospect.work_phone && prospect.work_phone.length > 0") {{ prospect.work_phone | tel }}
                            //        .cell-value-spacing(v-else) N/a
                            //tr
                            //    td
                            //        .text-capitalize.text-overline.text--secondary.text--darken-3 Primary Email Address
                            //        .cell-value-spacing(v-if="prospect.primary_email_address && prospect.primary_email_address.length > 0") {{ prospect.primary_email_address }}
                            //        .cell-value-spacing(v-else) N/a
                            //tr
                            //    td
                            //        .text-capitalize.text-overline.text--secondary.text--darken-3 Vehicle Make
                            //        .cell-value-spacing(v-if="prospect.vehicle_make && prospect.vehicle_make.length > 0") {{ prospect.vehicle_make }}
                            //        .cell-value-spacing(v-else) N/a
                            //tr
                            //    td
                            //        .text-capitalize.text-overline.text--secondary.text--darken-3 Vehicle Model
                            //        .cell-value-spacing(v-if="prospect.vehicle_model && prospect.vehicle_model.length > 0") {{ prospect.vehicle_model }}
                            //        .cell-value-spacing(v-else) N/a
                            //tr
                            //    td
                            //        .text-capitalize.text-overline.text--secondary.text--darken-3 Vehicle Model Year
                            //        .cell-value-spacing(v-if="prospect.vehicle_model_year && prospect.vehicle_model_year.length > 0") {{ prospect.vehicle_model_year }}
                            //        .cell-value-spacing(v-else) N/a
                            tr
                                td
                                    .text-capitalize.text-overline.text--secondary.text--darken-3 Vehicle Vin
                                    .cell-value-spacing(v-if="prospect.vehicle_vin && prospect.vehicle_vin.length > 0") {{ prospect.vehicle_vin }}
                                    .cell-value-spacing(v-else) N/a
                            //tr
                            //    td
                            //        .text-capitalize.text-overline.text--secondary.text--darken-3 Address Street One
                            //        .cell-value-spacing(v-if="prospect.address_street_one && prospect.address_street_one.length > 0") {{ prospect.address_street_one }}
                            //        .cell-value-spacing(v-else) N/a
                            //tr
                            //    td
                            //        .text-capitalize.text-overline.text--secondary.text--darken-3 Address Street Two
                            //        .cell-value-spacing(v-if="prospect.address_street_two && prospect.address_street_two.length > 0") {{ prospect.address_street_two }}
                            //        .cell-value-spacing(v-else) N/a
                            //tr
                            //    td
                            //        .text-capitalize.text-overline.text--secondary.text--darken-3 Address City
                            //        .cell-value-spacing(v-if="prospect.address_city && prospect.address_city.length > 0") {{ prospect.address_city }}
                            //        .cell-value-spacing(v-else) N/a
                            //tr
                            //    td
                            //        .text-capitalize.text-overline.text--secondary.text--darken-3 Address Region
                            //        .cell-value-spacing(v-if="prospect.address_region && prospect.address_region.length > 0") {{ prospect.address_region }}
                            //        .cell-value-spacing(v-else) N/a
                            //tr
                            //    td
                            //        .text-capitalize.text-overline.text--secondary.text--darken-3 Address Postal Code
                            //        .cell-value-spacing(v-if="prospect.address_postal_code && prospect.address_postal_code.length > 0") {{ prospect.address_postal_code }}
                            //        .cell-value-spacing(v-else) N/a
                            tr
                                td
                                    .text-capitalize.text-overline.text--secondary.text--darken-3 Prospect Addresses
                                    .cell-value-spacing(v-if="validProspectAddresses")
                                        ProspectAddressDetails(
                                            v-for='prospectAddress in prospect.prospect_addresses'
                                            :key='prospectAddress.prospect_address_id'
                                            :prospect-address="prospectAddress"
                                        )
                                    .cell-value-spacing(v-else)
                                        | None
                            tr
                                td
                                    .text-capitalize.text-overline.text--secondary.text--darken-3 Prospect Contacts
                                    .cell-value-spacing(v-if="validProspectContacts")
                                        ProspectContactDetails(
                                            v-for='prospectContact in prospect.prospect_contacts'
                                            :key='prospectContact.prospect_contact_id'
                                            :prospect-contact="prospectContact"
                                        )
                                    .cell-value-spacing(v-else)
                                        | None
                            tr
                                td
                                    .text-capitalize.text-overline.text--secondary.text--darken-3 Prospect Email Addresses
                                    .cell-value-spacing(v-if="validProspectEmailAddresses")
                                        ProspectEmailAddressDetails(
                                            v-for='prospectEmailAddress in prospect.prospect_email_addresses'
                                            :key='prospectEmailAddress.prospect_email_address_id'
                                            :prospect-email-address="prospectEmailAddress"
                                        )
                                    .cell-value-spacing(v-else)
                                        | None
                            tr
                                td
                                    .text-capitalize.text-overline.text--secondary.text--darken-3 Prospect Names
                                    .cell-value-spacing(v-if="validProspectNames")
                                        ProspectNameDetails(
                                            v-for='prospectName in prospect.prospect_names'
                                            :key='prospectName.prospect_name_id'
                                            :prospect-name="prospectName"
                                        )
                                    .cell-value-spacing(v-else)
                                        | None
                            tr
                                td
                                    .text-capitalize.text-overline.text--secondary.text--darken-3 Prospect Phone Numbers
                                    .cell-value-spacing(v-if="validProspectPhoneNumbers")
                                        ProspectPhoneNumberDetails(
                                            v-for='prospectPhoneNumber in prospect.prospect_phone_numbers'
                                            :key='prospectPhoneNumber.prospect_phone_number_id'
                                            :prospect-phone-number="prospectPhoneNumber"
                                        )
                                    .cell-value-spacing(v-else)
                                        | None
                            tr
                                td
                                    .text-capitalize.text-overline.text--secondary.text--darken-3 Prospect Providers
                                    .cell-value-spacing(v-if="validProspectProviders")
                                        ProspectProviderDetails(
                                            v-for='prospectProvider in prospect.prospect_providers'
                                            :key='prospectProvider.prospect_provider_id'
                                            :prospect-provider="prospectProvider"
                                        )
                                    .cell-value-spacing(v-else)
                                        | None
                            tr
                                td
                                    .text-capitalize.text-overline.text--secondary.text--darken-3 Prospect Sales
                                    .cell-value-spacing(v-if="validProspectSales")
                                        ProspectSaleDetails(
                                            v-for='prospectSale in prospect.prospect_sales'
                                            :key='prospectSale.prospect_sale_id'
                                            :prospect-sale="prospectSale"
                                        )
                                    .cell-value-spacing(v-else)
                                        | None
                            tr
                                td
                                    .text-capitalize.text-overline.text--secondary.text--darken-3 Prospect Services
                                    .cell-value-spacing(v-if="validProspectServices")
                                        ProspectServiceDetails(
                                            v-for='prospectService in prospect.prospect_services'
                                            :key='prospectService.prospect_service_id'
                                            :prospect-service="prospectService"
                                        )
                                    .cell-value-spacing(v-else)
                                        | None
                            tr
                                td
                                    .text-capitalize.text-overline.text--secondary.text--darken-3 Prospect Vehicles
                                    .cell-value-spacing(v-if="validProspectVehicles")
                                        ProspectVehicleDetails(
                                            v-for='prospectVehicle in prospect.prospect_vehicles'
                                            :key='prospectVehicle.prospect_vehicle_id'
                                            :prospect-vehicle="prospectVehicle"
                                        )
                                    .cell-value-spacing(v-else)
                                        | None
                            tr
                                td
                                    .text-capitalize.text-overline.text--secondary.text--darken-3 Prospect Vendors
                                    .cell-value-spacing(v-if="validProspectVendors")
                                        ProspectVendorDetails(
                                            v-for='prospectVendor in prospect.prospect_vendors'
                                            :key='prospectVendor.prospect_vendor_id'
                                            :prospect-vendor="prospectVendor"
                                        )
                                    .cell-value-spacing(v-else)
                                        | None

</template>

<script lang="ts">
    import Vue from 'vue';

    // Components
    import ProspectAddressDetails from "@/components/prospects/prospect_address_details.vue";
    import ProspectContactDetails from "@/components/prospects/prospect_contact_details.vue";
    import ProspectEmailAddressDetails from "@/components/prospects/prospect_email_address_details.vue";
    import ProspectNameDetails from "@/components/prospects/prospect_name_details.vue";
    import ProspectPhoneNumberDetails from "@/components/prospects/prospect_phone_number_details.vue";
    import ProspectProviderDetails from "@/components/prospects/prospect_provider_details.vue";
    import ProspectSaleDetails from "@/components/prospects/prospect_sale_details.vue";
    import ProspectServiceDetails from "@/components/prospects/prospect_service_details.vue";
    import ProspectVehicleDetails from "@/components/prospects/prospect_vehicle_details.vue";
    import ProspectVendorDetails from "@/components/prospects/prospect_vendor_details.vue";

    // Helpers
    import { concatVehicleYearMakeModel } from "@/helpers/general";

    // Types
    import { VehicleYearMakeModelObj } from "@/helpers/general";

    export default Vue.extend({
        data() {
            return {
                showDetails: false,
            }
        },
        computed: {
            prospectLead() {
                if (!this.validProspect) {
                    return null;
                }
                
                const leadNames = [];
                if (this.prospect?.lead_source?.length > 0) {
                    leadNames.push(this.prospect.lead_source);
                }

                if (this.prospect?.lead_key?.length > 0) {
                    leadNames.push(this.prospect.lead_key);
                }

                if (leadNames.length > 0) {
                    return leadNames.join((' : '));
                }

                return '--';
            },
            validProspect() {
                return this.prospect?.prospect_id > 0;
            },
            validProspectAddresses() {
                return this.prospect?.prospect_addresses?.length > 0;
            },
            validProspectContacts() {
                return this.prospect?.prospect_contacts?.length > 0;
            },
            validProspectEmailAddresses() {
                return this.prospect?.prospect_email_addresses?.length > 0;
            },
            validProspectNames() {
                return this.prospect?.prospect_names?.length > 0;
            },
            validProspectPhoneNumbers() {
                return this.prospect?.prospect_phone_numbers?.length > 0;
            },
            validProspectProviders() {
                return this.prospect?.prospect_providers?.length > 0;
            },
            validProspectSales() {
                return this.prospect?.prospect_sales?.length > 0;
            },
            validProspectServices() {
                return this.prospect?.prospect_services?.length > 0;
            },
            validProspectVehicles() {
                return this.prospect?.prospect_vehicles?.length > 0;
            },
            validProspectVendors() {
                return this.prospect?.prospect_vendors?.length > 0;
            },
            vehicleYearMakeModel() {
                if (!this.validProspect) {
                    return null;
                }

                const vehObj = {
                    vehicleModelYear: this.prospect.vehicle_model_year,
                    vehicleMake:      this.prospect.vehicle_make,
                    vehicleModel:     this.prospect.vehicle_model,
                }

                return concatVehicleYearMakeModel(vehObj as VehicleYearMakeModelObj);
            },
        },
        components: {
            ProspectAddressDetails,
            ProspectContactDetails,
            ProspectEmailAddressDetails,
            ProspectNameDetails,
            ProspectPhoneNumberDetails,
            ProspectProviderDetails,
            ProspectSaleDetails,
            ProspectServiceDetails,
            ProspectVehicleDetails,
            ProspectVendorDetails,
        },
        props: {
            prospect: Object,
        },
    });
</script>

<style lang="scss" scoped>
    #app {
        .no-top-bottom-padding {
            padding-top: 0;
            padding-bottom: 0;
        }

        .cell-value-spacing {
            padding: 0 0 5px 0;
        }

        .m-l-5 {
            margin-left: 5px;
        }
    }
</style>
