<template lang="pug">
    .ma-sm-2(v-if="validCustomer")
        .text-center(v-if="isLoading")
            v-progress-circular(
                :size='100'
                :width='10'
                color='#0f5b9a'
                indeterminate
                style="margin-top: 100px;"
            )

        v-container.mx-auto(v-if="!isLoading")
            .text-subtitle-2.subtitle-2-padding.text-center
                | Disposition History

            CommunicationDispositionDetails(
                v-if="validCommunicationDispositions"
                v-for='communicationDisposition in communicationDispositions'
                :key='communicationDisposition.communication_disposition_id'
                :communication-disposition="communicationDisposition"
                :show-campaign="true"
            )

            v-alert(outlined type='warning' dense border='left' style="margin-top: 16px;" v-if="!validCommunicationDispositions")
                | Customer does not have any communication dispositions

</template>

<script lang="ts">
    import Vue from 'vue';
    import VolieState  from "@/store";

    // Components
    import CommunicationDispositionDetails from '@/components/communication_dispositions/communication_disposition_details.vue';

    // Data
    import { GetCommunicationDispositions } from "@/data";

    export default Vue.extend({
        data() {
            return {
                bus: VolieState.websocketClient().eventBus(),
                communicationDispositions: [],
                isLoading: false,
            }
        },
        methods: {
            getCommunicationDispositions() {
                if (!(this.currentCampaignCustomer?.customer_id > 0)) {
                    return;
                }

                const options = {
                    customer_id: this.currentCampaignCustomer.customer_id,
                }

                this.isLoading = true;
                GetCommunicationDispositions(options).then((response) => {
                    if (response?.Data?.communication_dispositions?.length > 0) {
                        this.communicationDispositions = response.Data.communication_dispositions;
                    }

                    this.isLoading = false;
                });
            },
            onNewCommunicationDisposition(response) {

                // Verify legit communication disposition
                const data = response?.Data;
                const communicationDisposition = data?.communication_disposition;
                if (!(communicationDisposition?.communication_disposition_id > 0) || !(communicationDisposition?.campaign_customer_active_id > 0) || !(communicationDisposition?.account_id > 0)) {
                    return;
                }
                
                // Verify the campaign customer is the same and if so, set total
                if (this.currentCampaignCustomer?.campaign_customer_active_id === communicationDisposition.campaign_customer_active_id) {
                    this.setChangeOnCurrentCampaignCustomer("total_communication_dispositions", (data?.total_communication_dispositions || 0));

                    if (communicationDisposition?.communication_disposition_id > 0) {

                        // Verify new data to current screen
                        if (this.communicationDispositions?.length > 0) {
                            const existingNewCommunicationDisposition = this.communicationDispositions.find(x => x.communication_disposition_id === communicationDisposition.communication_disposition_id);
                            
                            // Return if new communication disposition already in array
                            if (existingNewCommunicationDisposition?.communication_disposition_id > 0) {
                                return;
                            }
                        }

                        // Insert new communication disposition to front of communicationDispositions array
                        this.communicationDispositions.unshift(communicationDisposition);
                    }
                }
            },
        },
        computed: {
            validCommunicationDispositions() {
                return this.communicationDispositions?.length > 0;
            },
            validCustomer() {
                return this.currentCampaignCustomer?.account_id > 0 && this.currentCampaignCustomer?.customer_id > 0 && this.currentCampaignCustomer?.organization_id > 0;
            },
        },
        components: {
            CommunicationDispositionDetails,
        },
        props: {
            currentCampaignCustomer: Object,
            setChangeOnCurrentCampaignCustomer: Function,
        },
        created() {
            this.getCommunicationDispositions();
            this.bus.subscribe({ type: "broadcast" , channel: { ID: null, Type: "newCommunicationDisposition" }}, this.onNewCommunicationDisposition);
        },
        destroyed() {
            this.bus.unsubscribe({ type: "broadcast" , channel: { ID: null, Type: "newCommunicationDisposition" }}, this.onNewCommunicationDisposition);
        },
    });
</script>

<style lang="scss" scoped>
</style>
