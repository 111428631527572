import { get, post, put } from '.';

export function GetCampaignDeployments(options = {}) {
	return get<any>("/app/api/campaign_deployments", options);
}

export function CreateCampaignDeployment(options = {}) {
	return post<any>("/app/api/campaign_deployments", options);
}

export function UpdateCampaignDeployment(id, options = {}) {
	return put<any>("/app/api/campaign_deployments/" + (id || 0).toString(), options);
}