import { destroy, get, post, put } from '.';

export function GetScorecardTemplates(options = {}) {
    return get<any>("/app/api/scorecard_templates", options);
}

export function FindScorecardTemplate(vid, options = {}) {
    return get<any>("/app/api/scorecard_templates/" + vid, options);
}

export function CreateScorecardTemplate() {
    return post<any>("/app/api/scorecard_templates");
}

export function UpdateScorecardTemplate(id, options = {}) {
    return put<any>("/app/api/scorecard_templates/" + (id || 0).toString(), options);
}

export function UpdateScorecardTemplateStatus(id, options = {}) {
    return put<any>("/app/api/scorecard_templates/" + (id || 0).toString() + "/status", options);
}

export function DestroyScorecardTemplate(id, options = {}) {
    return destroy<any>("/app/api/scorecard_templates/" + (id || 0).toString(), options);
}

export function GetAvailableScorecardTemplates(scorecardId, options = {}) {
    return get<any>("/app/api/scorecards/" + (scorecardId || 0).toString() + "/scorecard_templates/available", options);
}

export function UpdateScorecardScorecardTemplate(scorecardId, scorecardTemplateId, options = {}) {
    return put<any>("/app/api/scorecards/" + (scorecardId || 0).toString() + "/scorecard_templates/" + (scorecardTemplateId || 0).toString(), options);
}
