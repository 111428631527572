<template lang="pug">
    v-card.mx-lg-auto(v-if='validCalls' width="100%")

        v-data-table.table-x-scroll(
            :headers="headers"
            :items="items"
            item-key="call_id"
            hide-default-footer
            :items-per-page="-1" 
            no-data-text=""
            :expanded.sync="expanded"
            show-expand
        )

            template(v-slot:item.leg="{ item }")
                td.no-wrap
                    //- Leg
                    strong.green--text(v-if="item.agent_call === true && item.transfer_call === false") Agent
                    strong.info--text(v-else-if="item.customer_call === true") Customer
                    strong.primary--text(v-else-if="item.agent_call === false && item.transfer_call === true") Transfer
                    strong.primary--text(v-else-if="item.agent_call === true && item.transfer_call === true")
                        strong.green--text Agent
                        | {{ ' ' }}
                        strong.primary--text Transfer

            template(v-slot:item.friendly_start_time="{ item }")
                td.no-wrap
                    template(v-if="item.friendly_start_time")
                        TooltipText(:text="item.friendly_start_time_abbr" :alt-tooltip="item.friendly_start_time")
                    template(v-else) ---

            template(v-slot:item.duration="{ item }")
                td.no-wrap 
                    //- Has Recording
                    v-icon.green--text(v-if="item.recording_url") mdi-microphone
                    v-icon.error--text(v-else) mdi-microphone-off

                    | {{ item.duration | friendlyDurationFormat }}

            template(v-slot:item.from_number="{ item }")
                td.no-wrap
                    template(v-if="item.from_number") {{ item.from_number | tel }}
                    template(v-else) ---

            template(v-slot:item.to_number="{ item }")
                td.no-wrap
                    template(v-if="item.to_number") {{ item.to_number | tel }}
                    template(v-else) ---

            template(v-slot:expanded-item="{ headers, item }")
                td(:colspan="headers.length" style="padding: 0px;")
                    v-card(style="margin: 15px;")
                        div(style="padding: 5px;")

                            v-simple-table
                                template(v-slot:default)
                                    tbody                                                
                                        tr
                                            td(colspan="2")
                                                .text-capitalize.text-overline.text--secondary.text--darken-3.mt-2 Recording
                                                VolieAudio(
                                                    v-if="item.recording_url"
                                                    :recordingUrl="item.recording_url"
                                                    color="success"
                                                    :downloadable="!hideDownloadButtons"
                                                    style="margin-bottom: 15px;"
                                                )
                                                template(v-else) ---
                                            
                                        tr
                                            td(style="max-width: 50%; width: 50%;")
                                                .text-capitalize.text-overline.text--secondary.text--darken-3 End Time
                                                template(v-if="item.friendly_end_time") {{ item.friendly_end_time }}
                                                template(v-else) ---
                                            td(style="max-width: 50%; width: 50%;")
                                                .text-capitalize.text-overline.text--secondary.text--darken-3 Agent
                                                template(v-if='item.agent_call === true') {{ displayUser(item) }}
                                                template(v-else) N/a

                                        tr
                                            td(style="max-width: 50%; width: 50%;")
                                                .text-capitalize.text-overline.text--secondary.text--darken-3 Disconnect Cause
                                                template(v-if="item.disconnect_cause") {{ item.disconnect_cause | titleize }}
                                                template(v-else) ---
                                            td(style="max-width: 50%; width: 50%;")
                                                .text-capitalize.text-overline.text--secondary.text--darken-3 Record Enabled
                                                v-icon.green--text(v-if="item.record_enabled") mdi-check
                                                v-icon.error--text(v-else) mdi-close

                                        tr
                                            td(style="max-width: 50%; width: 50%;")
                                                .text-capitalize.text-overline.text--secondary.text--darken-3 Outgoing Caller Id
                                                template(v-if="item.outgoing_caller_id_phone_number") {{ item.outgoing_caller_id_phone_number | tel }}
                                                template(v-else) ---
                                            td(style="max-width: 50%; width: 50%;")
                                                .text-capitalize.text-overline.text--secondary.text--darken-3 Send Digits
                                                template {{ item.send_digits ? item.send_digits : '---' }}

                                        tr
                                            td(style="max-width: 50%; width: 50%;")
                                                .text-capitalize.text-overline.text--secondary.text--darken-3 SID
                                                | {{ item.call_sid ? item.call_sid : '---' }}
                                        
                                            td(style="max-width: 50%; width: 50%;")
                                                .text-capitalize.text-overline.text--secondary.text--darken-3 Vid
                                                template {{ item.vid }}

            template(v-slot:loading)
                .text-center
                    v-progress-circular(
                        :size='100'
                        :width='10'
                        color='#0f5b9a'
                        indeterminate
                        style="margin-top: 75px; margin-bottom: 75px;"
                    )

</template>

<script lang="ts">
    import Vue from 'vue';
    

    // Components
    import VolieAudio from '@/components/shared/audio_player.vue';
    import TooltipText from "@/components/shared/tooltip_text.vue";

    export default Vue.extend({
        data() {
            return {
                expanded: [],
                headers: [
                    {
                        text: 'Leg',
                        align: 'start',
                        sortable: false,
                        value: 'leg',
                        class: "volie-background-color-blue white--text",
                    },
                    {
                        text: 'Start Time',
                        align: 'start',
                        sortable: false,
                        value: 'friendly_start_time',
                        class: "volie-background-color-blue white--text",
                    },
                    {
                        text: 'Duration',
                        align: 'start',
                        sortable: false,
                        value: 'duration',
                        class: "volie-background-color-blue white--text",
                    },
                    {
                        text: 'From Number',
                        align: 'start',
                        sortable: false,
                        value: 'from_number',
                        class: "volie-background-color-blue white--text",
                    },
                    {
                        text: 'To Number',
                        align: 'start',
                        sortable: false,
                        value: 'to_number',
                        class: "volie-background-color-blue white--text",
                    },
                    { 
                        text: '',
                        align: 'start',
                        sortable: false,
                        value: 'data-table-expand' ,
                        class: "volie-background-color-blue white--text",
                    },
                ],
            }
        },
        methods: {
            displayUser(call) {
                if (!(call?.vid?.length > 0)) {
                    return null;
                }
    
                if (call?.user_email?.length > 0) {
                    return (call.user_full_name + ' <' + call.user_email + '>').trim();
                }
    
                return call.user_full_name;
            },
        },
        computed: {
            items() {
                if (this.conference?.calls?.length > 0) {
                    return this.conference.calls;
                }

                return [];
            },
            validCalls() {
                return this.conference?.calls?.length > 0;
            },
        },
        props: {
            conference: Object,
            hideDownloadButtons: Boolean,
        },
        components: {
            TooltipText,
            VolieAudio,
        },
    });
</script>

<style lang="scss" scoped>
</style>
