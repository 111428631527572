import { get, put } from '.';

export function PauseRecording(id) {
	return put<any>("/app/api/recordings/" + (id || 0).toString() + "/pause");
}

export function ResumeRecording(id) {
	return put<any>("/app/api/recordings/" + (id || 0).toString() + "/resume");
}

export function GetRecordingCallTranscription(vid) {
	return get<any>(`/app/api/recordings/${vid}/call_transcription`)
}