import { post, put, destroy, } from '.';

export function CreateCustomerEmailAddress(options = {}) {
	return post<any>("/app/api/customer_email_addresses", options);
}

export function UpdateCustomerEmailAddress(id, options = {}) {
	return put<any>("/app/api/customer_email_addresses/" + (id || 0).toString(), options);
}

export function DestroyCustomerEmailAddress(id, options = {}) {
	return destroy<any>("/app/api/customer_email_addresses/" + (id || 0).toString(), options);
}

export function UpdateCustomerEmailAddressPrimary(id, options = {}) {
	return put<any>("/app/api/update_customer_email_address_primary/" + (id || 0).toString(), options);
}
