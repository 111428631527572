<template lang="pug">
    v-card.mx-lg-auto.mt-md-4.mb-md-4(outlined :width="emailAttachmentCardWidth" style="margin: 10px 0px !important;")
        v-card-text
            v-simple-table
                template(v-slot:default)
                    tbody
                        tr
                            td.cell-value-spacing.break-word
                                v-btn(
                                    color="primary"
                                    icon
                                    ripple
                                    exact
                                    :href="formatLinkUrl(emailAttachment.attachment_url)"
                                    target="_blank"
                                )
                                    v-icon(small color="primary") mdi-open-in-new
                            td.text-left {{ emailAttachment.attachment_url }}

</template>

<script lang="ts">
    import Vue from 'vue';


    export default Vue.extend({
        data() {
            return {
                emailAttachmentCardWidth: 300,
            }
        },
        methods: {
            formatLinkUrl(linkUrl) { 
                // if url doesn't include a scheme '//' such as www.google.com, vue href prop will prepend the current domain to the url
                if (linkUrl?.includes("//")) {
                    return linkUrl
                }

                return "//" + linkUrl
            },
            handleEmailDetailsResize() {
                const emailDetailsWidth = document?.getElementById('email-details-card')?.getBoundingClientRect()?.width
                if (emailDetailsWidth >= 375) {
                    this.emailAttachmentCardWidth = emailDetailsWidth - 75
                } else {
                    this.emailAttachmentCardWidth = 300
                }
            },
        },
        computed: {
            validEmailAttachment() {
                return this.emailAttachment?.email_attachment_key > 0;
            },
        },
        components: {
        },
        props: {
            emailAttachment: Object,
        },
        watch: {
            emailAttachment: {
                handler() {
                    Vue.nextTick(() => { this.handleEmailDetailsResize(); });
                },
                deep: true,
            },
        },
        created() {
			window.addEventListener('resize', this.handleEmailDetailsResize);
        },
        destroyed() {
            window.removeEventListener('resize', this.handleEmailDetailsResize);
        },
        mounted() {
            this.handleEmailDetailsResize();
        },
    });
</script>

<style lang="scss" scoped>
    #app {
        .cell-value-spacing {
            padding: 0 0 5px 0;
        }
    }
</style>
