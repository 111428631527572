<template lang="pug">
    div(:class="right ? 'text-right' : 'text-left'" style="max-width: 90%;")
        small.px-4
            | {{ sender }}

        .px-4.mt-1(style="display: flex; flex-direction: column;" :class="right ? 'justify-content-flex-end' : 'justify-content-flex-start'")
            .my-1.max-width-100-percent(v-if="messageHasMedia" :class="right ? 'mr-0 justify-content-flex-end align-items-flex-end' : 'ml-0 justify-content-flex-start align-items-flex-start'" style="display: flex; flex-direction: column;")
                div(v-if="media.media_url" v-for="media in message.message_medias" style="display: flex; flex-direction: row; align-items: center;")
                    v-container.no-padding-bottom.px-0(fluid)
                        Attachment(
                            :is-link="true"
                            :attachment="media"
                        )
 
                    v-icon.error--text.ml-2(v-if="message.is_message_undelivered" medium) mdi-alert-circle

            .my-1.max-width-100-percent(v-if="messageHasBody" :class="right ? 'mr-0 justify-content-flex-end' : 'ml-0 justify-content-flex-start'" style="display: flex; flex-direction: row; align-items: center;")
                MessageBubble(
                    v-if="bodyContainsText"
                    :dark="right" 
                    :class=`right ? 
                                isInAppMessage ? 'blue darken-2' : 'bg-sms-message-green' 
                                : 'bg-sms-message-gray'`
                )
                    | {{ message.body }}

                .text-h3(v-else) {{ message.body }}

                v-icon.error--text.ml-2(v-if="message.is_message_undelivered" medium) mdi-alert-circle

            .caption.error--text(v-if="message.is_message_undelivered")
                | Not Delivered

</template>

<script lang="ts">
    import Vue from 'vue';

    // Components
    import Attachment from "@/components/shared/attachments/attachment_card.vue"
    import MessageBubble from "@/components/communication_center/sms_message/message_bubble.vue"

    export default Vue.extend({
        props: {
            isInAppMessage: Boolean,
            message: Object,
            right: Boolean,
            sender: String,
        },
        computed: {
            messageBody() {
                return (this.message?.body || '');
            },
            bodyContainsText() {
                return this.emojiTrimmedBody?.length > 0;
            },
            emojiTrimmedBody() {
                return (this.messageBody?.replaceAll(/\p{Emoji}/ug, '') || '')?.trim();
            },
            messageHasMedia() {
                return this.message?.message_medias?.length > 0;
            },
            messageHasBody() {
                return this.messageBody?.length > 0;
            },
        },
        components: {
            Attachment,
            MessageBubble,
        },
    });
</script>

<style lang="scss" scoped>
</style>
