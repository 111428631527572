<template lang="pug">
    div 
        v-snackbar(
            v-model='snackbar.show'
            :color='snackbar.color'
            :timeout="snackbar.timeout"
            top
        )
            | {{ snackbar.text }}
            v-btn(dark text @click='snackbar.show = false')
                | Close

        v-dialog(
            v-model="dialogDestroy",
            persistent="",
            max-width="600px"
        )
            v-card
                v-card-title
                    span.text-h5 Remove ticket follower
                v-card-text
                    v-container
                        span.text-h6 Are you sure you want to remove this ticket follower?

                    v-container
                        v-simple-table
                            template(v-slot:default)
                                thead
                                    tr
                                        th Name
                                        th Email
                                tbody
                                    tr(v-if="scopedFollower" :key='scopedFollower.ticket_follower_id')
                                        td.no-wrap.xs-width
                                            template(v-if="scopedFollower.user_full_name && scopedFollower.user_full_name.length > 0")
                                                StrLimitTooltipText(:text="scopedFollower.user_full_name" :char-limit="50")
                                        td.no-wrap.xs-width
                                            template(v-if="scopedFollower.user_email && scopedFollower.user_email.length > 0")
                                                StrLimitTooltipText(:text="scopedFollower.user_email" :char-limit="50")

                v-card-actions
                    v-spacer
                        v-btn.ma-2(color="#0f5b9a" outlined ripple @click="closeDialogDestroy")
                            v-icon mdi-window-close
                            | Close
                        v-btn.ma-2(color="red" outlined ripple @click="destroyTicketFollower()" :loading="isSaving")
                            v-icon mdi-content-save
                            | Delete

        v-dialog(v-model="dialogCreate", persistent="", max-width="600px")
            v-card
                v-card-title
                    span.text-h5 Add Ticket Follower
                v-card-text
                    v-container
                        v-row
                            v-col(cols="12")
                                v-autocomplete(
                                    v-model="scopedFollowerId" 
                                    label="Follower" 
                                    type="text" 
                                    :items="availableTicketFollowers" 
                                    item-text="display_name" 
                                    item-value="follower_id" 
                                    clearable
                                )

                v-card-actions
                    v-spacer
                        v-btn.ma-2(color="#0f5b9a" outlined ripple @click="closeDialogCreate")
                            v-icon mdi-window-close
                            | Close
                        v-btn.ma-2(color="success" outlined ripple @click="createTicketFollower()" :loading="isSaving")
                            v-icon mdi-content-save
                            | Create

        v-card.mx-lg-auto.mt-md-4.mb-md-4(outlined width="100%")
            v-toolbar.volie-v-toolbar-color(dark)
                v-toolbar-title Ticket Followers
                v-spacer
                v-btn(icon dark @click="dialogCreate = true")
                    v-icon mdi-plus-circle-outline

            .text-center(v-if="isLoading")
                v-progress-circular(
                    :size='100'
                    :width='10'
                    color='#0f5b9a'
                    indeterminate
                    style="margin-top: 100px; margin-bottom: 100px;"
                )

            v-simple-table(v-else-if="validTicketFollowers")
                template(v-slot:default)
                    thead
                        tr
                            th Name
                            th Email
                            th
                    tbody
                        tr(v-for='ticketFollower in ticketFollowers' :key='ticketFollower.ticket_follower_id')
                            td.no-wrap.sm-width
                                StrLimitTooltipText(:text="ticketFollower.user_full_name" :char-limit="50")
                            td.no-wrap.sm-width
                                StrLimitTooltipText(:text="ticketFollower.user_email" :char-limit="50")
                            td.no-wrap.xs-xs-width.xs-xs-max-width.text-right
                                v-btn(small color="red" dark @click="openDialogDestroy(ticketFollower)")
                                    v-icon mdi-trash-can-outline

            v-alert(v-else outlined type='info' dense border='left' style="margin: 16px;")
                | Unable to find any followers for this ticket

</template>

<script lang="ts">
    import Vue from 'vue';

    // Data
    import { 
        CreateTicketFollower,
        DestroyTicketFollower,
        GetTicketFollowers,
    } from "@/data";

    // Components
    import StrLimitTooltipText from "@/components/shared/str_limit_tooltip_text.vue";

    export default Vue.extend({
        data() {
            return {
                availableTicketFollowers: [],
                dialogCreate: false,
                dialogDestroy: false,
                isLoading: false,
                isSaving: false,
                scopedFollower: null,
                scopedFollowerId: null,
                snackbar: {
                    show: false,
                    timeout: 2500,
                },
                ticketFollowers: [],
                
            }
        },
        methods: {
            closeDialogCreate() {
                this.dialogCreate = false;
                this.scopedFollowerId = null;
            },
            closeDialogDestroy() {
                this.dialogDestroy = false;
                this.scopedFollower = null;
            },
            createTicketFollower() {
                const options = {
                    ticket_id: this.ticket?.id || 0,
                    follower_id: this.scopedFollowerId || 0,
                };

                this.isSaving = true;
                CreateTicketFollower(options).then((response) => {

                    if (response?.Error === null) {
                        this.showSnackbar("success", "Successfully created ticket follower")
                    } else {
                        this.showSnackbar("red", response.Error)
                    }

                    this.isLoading = true;
                    if (response?.Data?.ticket_followers?.length > 0) {
                        this.ticketFollowers = response.Data.ticket_followers;
                    }
                    if (response?.Data?.potential_ticket_followers?.length > 0) {
                        this.setPotentialTicketFollowers(response?.Data?.potential_ticket_followers);
                    }

                    this.closeDialogCreate();
                    this.isLoading = false;
                    this.isSaving = false;
                });
            },
            destroyTicketFollower() {
                const options = {
                    ticket_id: this.ticket?.id || 0,
                    ticket_follower_id: this.scopedFollower?.ticket_follower_id || 0,
                };

                this.isSaving = true;
                DestroyTicketFollower(options).then((response) => {

                    if (response?.Error === null) {
                        this.showSnackbar("success", "Successfully removed ticket follower")
                    } else {
                        this.showSnackbar("red", response.Error)
                    }
                    
                    this.isLoading = true;
                    if (response?.Data?.ticket_followers?.length > 0) {
                        this.ticketFollowers = response.Data.ticket_followers;
                    }
                    if (response?.Data?.potential_ticket_followers?.length > 0) {
                        this.setPotentialTicketFollowers(response?.Data?.potential_ticket_followers);
                    }

                    this.closeDialogDestroy();
                    this.isLoading = false;
                    this.isSaving = false;
                });
            },
            getTicketFollowers() {
                if (!this.validTicket) {
                    return;
                }

                this.isLoading = true;
                GetTicketFollowers(this.ticket.ticket_id, {}).then((response) => {

                    if (response?.Data?.ticket_followers?.length > 0) {
                        this.ticketFollowers = response.Data.ticket_followers;
                    }
                    if (response?.Data?.potential_ticket_followers?.length > 0) {
                        this.setPotentialTicketFollowers(response?.Data?.potential_ticket_followers);
                    }

                    this.isLoading = false;
                });
            },
            openDialogDestroy(follower) {
                this.dialogDestroy = true;
                this.scopedFollower = follower;
            },
            setPotentialTicketFollowers(followers) {
                const followerMap = new Map();
                followers.forEach(function (assignable) {
                    followerMap.set(assignable.role_name, [
                        {
                            header: assignable.role_name,
                        },
                    ]);
                });

                followers.forEach(function (follower) {
                    let item = followerMap.get(follower.role_name);
                    if (item) {
                        item.push(follower);
                    } else {
                        item = [];
                    }

                    followerMap.set(follower.role_name, item);
                });

                followers = [];
                followerMap.forEach(function (value) {
                    followers = followers.concat(value);
                });

                this.availableTicketFollowers = followers;
            },
            showSnackbar(color, text) {
                if (color?.length > 0 && text?.length > 0) {
                    this.snackbar.color = color;
                    this.snackbar.text = text;
                    this.snackbar.show = true;
                }
            },
        },
        computed: {
            validTicketFollowers() {
                return this.ticketFollowers?.length > 0;
            },
            validTicket() {
                return this.ticket?.ticket_id > 0;
            },
        },
        props: {
            ticket: Object,
        },
        components: {
            StrLimitTooltipText,
        },
        created() {
            this.getTicketFollowers();
            return;
        },
    });
</script>

<style lang="scss" scoped>
</style>
