        <!-- v-bind:style="{ border-left: requiredWarningSize + 'px solid red' }" -->
<template lang="pug">
    v-card.my-md-4.mr-md-4(
        v-if="validSurveyTemplateQuestion"
        outlined
        v-bind:style="{ borderColor: requiredWarningColor }"
    )
        v-card-text
            v-alert(
                v-if="validAgentPrompt"
                color='info'
                dense
                outlined
                text
            )
                .break-word(v-html="surveyTemplateQuestion.agent_prompt")

            div(v-html="questionText")

            v-container.px-0.pb-0(fluid v-if="renderAnswerContainer")

                //- radio buttons
                div(v-if="surveyTemplateQuestion.display_type === 1 && validSurveyTemplateQuestionOptions")
                    v-radio-group.mt-0(v-model='answer')
                        v-radio(
                            v-for='option in surveyTemplateQuestion.survey_template_question_options'
                            :key='option.survey_template_question_option_id'
                            :label='option.question_option'
                            :value='option.question_option'
                            :disabled="!isApplicable"
                            :readonly="readonly"
                        )

                //- textarea
                div(v-else-if="surveyTemplateQuestion.display_type === 2")
                    v-textarea(
                        v-model="answer"
                        label='Type answer'
                        rows="1"
                        auto-grow
                        clearable
                        counter
                        outlined
                        dense
                        :disabled="!isApplicable"
                        :readonly="readonly"
                    )

                //- plain input
                div(v-else-if="surveyTemplateQuestion.display_type === 5 && surveyTemplateQuestion.validation_type === 0")
                    v-text-field(
                        label="Type Answer"
                        v-model="answer"
                        clearable
                        append-icon="mdi-pencil"
                        :disabled="!isApplicable"
                        :readonly="readonly"
                    )

                //- email address input
                div(v-else-if="surveyTemplateQuestion.display_type === 5 && surveyTemplateQuestion.validation_type === 1")
                    v-text-field(
                        label='Email Address'
                        v-model="emailAddress"
                        clearable
                        append-icon="mdi-email"
                        :error-messages="errorMessage"
                        :disabled="!isApplicable"
                        :readonly="readonly"
                    )

                //- phone number input
                div(v-else-if="surveyTemplateQuestion.display_type === 5 && surveyTemplateQuestion.validation_type === 2")
                    v-row
                        v-col.py-2(cols='12')
                            v-select(
                                :items='countries'
                                :item-text="countryDisplay"
                                item-value="iso_two"
                                label='Country'
                                v-model="countryIsoTwo"
                                :disabled="!isApplicable"
                                :readonly="readonly"
                            )
                    v-row
                        v-col.py-2(cols='12')
                            v-text-field(
                                label='Phone Number'
                                type='text'
                                append-icon="mdi-phone"
                                v-model="phoneNumber"
                                :error-messages="errorMessage"
                                v-on:keypress="phoneNumberChange"
                                :disabled="!isApplicable"
                                :readonly="readonly"
                            )

                v-checkbox.mt-sm-1(
                    v-model='markNa'
                    label='Mark N/a'
                    v-if="renderNaCheckbox"
                    :readonly="readonly"
                )

</template>

<script lang="ts">
    import Vue from 'vue';
    import VolieState  from "@/store";

    // Helpers
    import { cleanupEmailAddress } from "@/helpers/email";
    import { cleanupPhoneNumber } from "@/helpers/phone_number";

    export default Vue.extend({
        data() {
            return {
                answer: null,
                countryIsoTwo: "US",
                emailAddress: null,
                emailAddressRules: {
                    email: v => {
                        const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                        return pattern.test(v) || 'Invalid e-mail.'
                    },
                },
                errorMessage: null,
                isApplicable: true,
                markNa: false,
                phoneNumber: null,
            }
        },
        methods: {
            countryDisplay(item) {
                return item?.name + ' (' + item?.dialing_code + ")";
            },
            phoneNumberChange(e) {
                
                // Ensure only numbers are entered
                if (isNaN(e.key)) {
                    e.preventDefault();
                }
            },
            validateEmailAddress() {

                // Reset if necessary
                if (!(this.emailAddress?.length > 0)) {
                    this.answer = null;
                    this.errorMessage = null;
                }

                const cleaned = cleanupEmailAddress(this.emailAddress);
                if (cleaned?.length > 0) {
                    this.answer = cleaned;
                    this.errorMessage = null;
                } else {
                    this.errorMessage = "Please enter a valid email address";
                }
            },
            validatePhoneNumber() {

                // Reset if necessary
                if (!(this.phoneNumber?.length > 0)) {
                    this.answer = null;
                    this.errorMessage = null;
                }

                const cleanPN = cleanupPhoneNumber(this.phoneNumber, this.countryIsoTwo);
                if (cleanPN.valid) {
                    this.answer = cleanPN.phoneNumber;
                    this.errorMessage = null;
                } else {
                    this.errorMessage = "Please enter a valid phone number";
                }
            },
        },
        computed: {
            countries() {
                return VolieState.countries();
            },
            outputSurveyAnswer() {
                return {
                    answer: this.answer,
                    is_applicable: this.isApplicable,
                    question_order: this.surveyTemplateQuestion?.question_order,
                    required: this.surveyTemplateQuestion?.required === true,
                    survey_answer: this.answer, // used in conference show page to create or update survey answers
                    survey_template_id: this.surveyTemplateQuestion?.survey_template_id,
                    survey_template_question_id: this.surveyTemplateQuestion?.survey_template_question_id,
                    survey_template_question_option_id: this.selectSurveyTemplateQuestionOptionID,
                }
            },
            questionText() {
                return this.surveyTemplateQuestion?.question_order + ". " + this.surveyTemplateQuestion?.question_text;
            },
            renderAnswerContainer() {
                return [1,2,5].indexOf(this.surveyTemplateQuestion?.display_type) >= 0;
            },
            renderNaCheckbox() {
                return this.surveyTemplateQuestion?.required === true;
            },
            requiredWarningColor() {
                return this.renderNaCheckbox && this.isApplicable === true && !(this.answer?.length > 0) ? '#FF5252' : '';
            },
            selectSurveyTemplateQuestionOptionID() {

                // Verify the user has even answered the question
                if (!this.answer) {
                    return null;
                }

                // Verify valid survey template question options
                if (this.surveyTemplateQuestion?.display_type !== 1 || !this.validSurveyTemplateQuestionOptions) {
                    return null;
                }

                // Loop through and find option ID by answer
                for (let i = 0; i < this.surveyTemplateQuestion?.survey_template_question_options?.length; i++) {

                    // Init
                    const option = this.surveyTemplateQuestion?.survey_template_question_options[i];
                    if (option?.survey_template_question_option_id > 0 && option.question_option === this.answer) {
                        return option?.survey_template_question_option_id;
                    }

                }

                return null;
            },
            validAgentPrompt() {
                return this.surveyTemplateQuestion?.agent_prompt?.length > 0;
            },
            validSurveyTemplateQuestion() {
                return this.surveyTemplateQuestion?.survey_template_question_id > 0;
            },
            validSurveyTemplateQuestionOptions() {
                return this.surveyTemplateQuestion?.survey_template_question_options?.length > 0;
            },
        },
        components: {
        },
        props: {
            readonly: Boolean,
            setSurveyAnswer: Function,
            surveyTemplateQuestion: Object,
        },
        watch: {
            answer() {
                this.setSurveyAnswer(this.outputSurveyAnswer);
            },
            "countryIsoTwo"() {
                this.validatePhoneNumber();
            },
            "emailAddress"() {
                this.validateEmailAddress();
            },
            "markNa"() {

                // Is applicable should be the opposite
                this.isApplicable = !this.markNa;

                // Reset inputs
                this.answer = null;
                this.errorMessage = null;
                this.emailAddress = null;
                this.phoneNumber = null;

                // Manually call watch function
                this.setSurveyAnswer(this.outputSurveyAnswer);
            },
            "phoneNumber"() {
                this.validatePhoneNumber();
            },
        },
        created() {

            // used in conference show page to create or update survey answers
            if (this.surveyTemplateQuestion?.survey_answer) {
                this.answer = this.surveyTemplateQuestion.survey_answer;
                this.isApplicable = this.surveyTemplateQuestion.is_applicable === true;

                // email address input
                if (this.surveyTemplateQuestion.display_type === 5 && this.surveyTemplateQuestion.validation_type === 1) {
                    this.emailAddress = this.answer;
                }

                // phone number input
                if (this.surveyTemplateQuestion.display_type === 5 && this.surveyTemplateQuestion.validation_type === 2) {
                    const cleanPN = cleanupPhoneNumber(this.answer, null);
                    if (cleanPN.valid) {
                        this.countryIsoTwo = cleanPN.countryIso;
                        this.phoneNumber = cleanPN.phoneNumber;
                    }
                }

                // mark N/A
                if (!this.isApplicable) {
                    this.markNA = true;
                }
            }

            this.setSurveyAnswer(this.outputSurveyAnswer);
        },
    });
</script>

<style lang="scss" scoped>
</style>
