import { get, post, put, destroy } from '.';

export function CreateAppointmentType(options = {}) {
	return post<any>("/app/api/appointment_types", options);
}

export function DestroyAppointmentType(options = {}) {
	return destroy<any>("/app/api/appointment_types", options);
}

export function FindAppointmentType(tagKey, options = {}) {
	return get<any>("/app/api/appointment_types/" + tagKey, options);
}

export function GetAppointmentTypes(options = {}) {
	return get<any>("/app/api/appointment_types", options);
}

export function UpdateAppointmentType(id, options = {}) {
	return put<any>("/app/api/appointment_types/" + (id || 0).toString(), options);
}