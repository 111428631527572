import { get, post, put, destroy } from '.';

export function GetCdkPlugins(options = {}) {
	return get<any>("/app/api/cdk_plugins", options);
}

export function FindCdkPlugin(key) {
	return get<any>("/app/api/cdk_plugins/" + key);
}

export function CreateCdkPlugin(options = {}) {
	return post<any>("/app/api/cdk_plugins", options);
}

export function UpdateCdkPlugin(id, options = {}) {
	return put<any>("/app/api/cdk_plugins/" + (id || 0).toString(), options);
}

export function DestroyCdkPlugin(id) {
	return destroy<any>("/app/api/cdk_plugins/" + (id || 0).toString());
}