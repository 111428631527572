<template lang="pug">
    div.width-100-percent.height-100-percent
        v-layout.width-100-percent
            v-row(no-gutters)
                v-col.col-right-divider(cols='4' :style="'max-width: ' + colLeftWidth + 'px;'")
                    v-tabs(grow)
                        v-tab(disabled style="opacity: 1;") Queue
                    v-simple-table
                        template(v-slot:default)
                            tbody(v-if="validCallCallQueue")
                                tr
                                    td
                                        .text-capitalize.text-overline.text--secondary.text--darken-3 Call Queue Vid
                                        CopyableText(:text="callCallQueue.call_queue_vid")
                                tr
                                    td
                                        .text-capitalize.text-overline.text--secondary.text--darken-3 Call Vid
                                        template(v-if="callCallQueue.call_vid")
                                            CopyableText(:text="callCallQueue.call_vid")
                                        template(v-else) ---
                                tr
                                    td
                                        .text-capitalize.text-overline.text--secondary.text--darken-3 Campaign Vid
                                        template(v-if="callCallQueue.campaign_vid")
                                            CopyableText(:text="callCallQueue.campaign_vid")
                                        template(v-else) ---
                                tr
                                    td
                                        .text-capitalize.text-overline.text--secondary.text--darken-3 Status
                                        | {{ callCallQueue.status | titleize }}
                                tr
                                    td
                                        .text-capitalize.text-overline.text--secondary.text--darken-3 Agent
                                        template(v-if="callCallQueue.user_display_name")
                                            StrLimitTooltipText(:text="callCallQueue.user_display_name" :char-limit="25")
                                        template(v-else) ---

                    .text-center(v-if="isLoading")
                        v-progress-circular(
                            :size='100'
                            :width='10'
                            color='#0f5b9a'
                            indeterminate
                            style="margin-top: 75px; margin-bottom: 75px;"
                        )

                    v-alert(v-if="!validCallCallQueue && !isLoading" outlined type='info' dense border='left' style="margin-top: 16px;")
                        | There is no queue for this conference

                v-col.col-left-divider(cols='8' :style="'max-width: ' + colRightWidth + 'px;'")
                    v-tabs(grow)
                        v-tab(disabled style="opacity: 1;") Queue Activities
                    
                    v-container.mx-auto(v-if="validCallCallQueueActivities && !isLoading")
                        v-card.mx-lg-auto(width="100%")
                            v-simple-table.table-x-scroll
                                template(v-slot:default)
                                    thead.volie-background-color-blue
                                        tr
                                            th.white--text Time
                                            th.white--text Queue Status
                                            th.white--text Status
                                            th.white--text Agent
                                    tbody
                                        tr(v-for='callCallQueueActivity in callCallQueueActivities' :key='callCallQueueActivity.call_call_queue_activity_id')
                                            td.no-wrap.xs-width 
                                                TooltipText(:text="callCallQueueActivity.friendly_created_at_time" :alt-tooltip="callCallQueueActivity.friendly_created_at")
                                            td.no-wrap.xs-sm-width
                                                template(v-if="callCallQueueActivity.call_call_queue_status")
                                                    | {{ callCallQueueActivity.call_call_queue_status | titleize }}
                                                template(v-else) ---
                                            td.no-wrap.xs-width
                                                | {{ callCallQueueActivity.status | titleize }}
                                            td.no-wrap.xs-width
                                                template(v-if="callCallQueueActivity.user_id > 0")
                                                    StrLimitTooltipText(:text="callCallQueueActivity.user_full_name" :char-limit="20")
                                                template(v-else) ---
                                
                    .text-center(v-if="isLoading")
                        v-progress-circular(
                            :size='100'
                            :width='10'
                            color='#0f5b9a'
                            indeterminate
                            style="margin-top: 75px; margin-bottom: 75px;"
                        )

                    v-alert(v-if="!validCallCallQueueActivities && !isLoading" outlined type='info' dense border='left' style="margin-top: 16px;")
                        | There are no queue activities for this conference

                    v-divider(style="margin: 25px 50px;")

                    v-tabs(grow)
                        v-tab(disabled style="opacity: 1;") Ignore Calls
                    
                    v-container.mx-auto(v-if="validIgnoreCalls && !isLoading")
                        v-card.mx-lg-auto(width="100%")
                            v-simple-table.table-x-scroll
                                template(v-slot:default)
                                    thead.volie-background-color-blue
                                        tr
                                            th.white--text Datetime
                                            th.white--text Agent
                                            th.white--text Clicked Decline
                                    tbody
                                        tr(v-for='ignoreCall in ignoreCalls' :key='ignoreCall.ignore_call_id')
                                            td.no-wrap.xs-width
                                                StrLimitTooltipText(:text="ignoreCall.friendly_created_at" :char-limit="13")
                                            td.no-wrap.xs-width
                                                template(v-if="ignoreCall.user_display_name")
                                                    StrLimitTooltipText(:text="ignoreCall.user_display_name" :char-limit="20")
                                                template(v-else) ---
                                            td.no-wrap.sm-width
                                                v-icon.green--text(v-if="ignoreCall.agent_clicked_decline") mdi-check
                                                v-icon.error--text(v-else) mdi-close
                                
                    .text-center(v-if="isLoading")
                        v-progress-circular(
                            :size='100'
                            :width='10'
                            color='#0f5b9a'
                            indeterminate
                            style="margin-top: 75px; margin-bottom: 75px;"
                        )

                    v-alert(v-if="!validIgnoreCalls && !isLoading" outlined type='info' dense border='left' style="margin-top: 16px;")
                        | There are no ignore calls for this conference

</template>

<script lang="ts">
    import Vue from 'vue';

    // Components
    import CopyableText from '@/components/shared/copyable_text.vue';
    import StrLimitTooltipText from "@/components/shared/str_limit_tooltip_text.vue";
    import TooltipText from "@/components/shared/tooltip_text.vue";


    export default Vue.extend({
        data() {
            return {
                colLeftWidth: 250,
                colRightWidth: 500,
            }
        },
        methods: {
            handleWindowResize() {
                const container = document?.getElementById('inbound-insights-container')?.getBoundingClientRect()?.width;

                this.colLeftWidth = .33 * container;
                this.colRightWidth = .66 * container;
            },
        },
        computed: {
            validCallCallQueue() {
                return this.callCallQueue?.call_call_queue_id > 0;
            },
            validCallCallQueueActivities() {
                return this.callCallQueue?.call_call_queue_activities?.length > 0;
            },
            validIgnoreCalls() {
                return this.callCallQueue?.ignore_calls?.length > 0;
            },
            callCallQueueActivities() {
                if (!this.validCallCallQueueActivities) {
                    return [];
                }

                return this.callCallQueue.call_call_queue_activities;
            },
            ignoreCalls() {
                if (!this.validIgnoreCalls) {
                    return [];
                }

                return this.callCallQueue.ignore_calls;
            },
        },
        props: {
            callCallQueue: Object,
            isLoading: Boolean,
        },
        components: {
            CopyableText,
            StrLimitTooltipText,
            TooltipText,
        },
        watch: {
            callCallQueue() {
                this.handleWindowResize();
            },
        },
        created() {
            window.addEventListener('resize', this.handleWindowResize);
        },
        mounted() {
            this.handleWindowResize();
        },
        destroyed() {
            window.removeEventListener('resize', this.handleWindowResize);
        },
    });
</script>

<style lang="scss" scoped>

    #app {
        .col-right-divider {
            border-right: 1px solid #F5F5F5;
            margin-top: 1%;
            margin-bottom: 2%;
            padding: 10px;
        }

        .col-left-divider {
            border-left: 1px solid #F5F5F5;
            margin-top: 1%;
            margin-bottom: 2%;
            padding: 10px;
        }
    }

</style>
