export function formatName(inputs: string[]): string {

	// Verify
	if (!(inputs?.length > 0)) {
		return "";
	}

	// Init
	const names = [];

	// Loop through inputs and append valid strings to names
	for (let i = 0; i < inputs.length; i++) {

		// Init
		const input = inputs[i];
		if (!(input?.length > 0)) {
			continue;
		}

		// Trim and push
		names.push(input.trim())
	}

	return names.join(" ").trim();
}

export function formatVehicleName(year: string, make: string, model: string): string {

	// Verify
	if (!(year?.length > 0) && !(make?.length > 0) && !(model?.length > 0)) {
		return "---";
	}

	// Init
	const vehicleName = [];

	if ((year?.length > 0)) {
		vehicleName.push(year)
	}

	if ((make?.length > 0)) {
		vehicleName.push(make)
	}

	if ((model?.length > 0)) {
		vehicleName.push(model)
	}

	return vehicleName.join(" ");

}