import { get } from '.';

export function GetCommunicationCenter() {
	return get<any>("/app/api/get_communication_center", {});
}

export function GetCommunicationCenterEmailClient(options = {}) {
	return get<any>("/app/api/communication_center/email_client", options);
}

export function GetCommunicationCenterEmailsInThread(options = {}) {
	return get<any>("/app/api/communication_center/emails_in_thread", options);
}

export function GetCommunicationCenterOrganizationContacts(options = {}) {
	return get<any>("/app/api/communication_center_organization_contacts", options);
}

export function GetCommunicationCenterSurveyTemplates(options = {}) {
	return get<any>("/app/api/communication_center/survey_templates", options);
}

export function GetCommunicationCenterSmsMessageSoftphone(options = {}) {
	return get<any>("/app/api/get_communication_center_sms_message_softphone", options);
}

export function GetCommunicationQueueSelection(options = {}) {
	return get<any>("/app/api/get_communication_queue_selection", options);
}