<template lang="pug">
    .ma-sm-2(v-if="validCustomer")
        .text-center(v-if="isLoading")
            v-progress-circular(
                :size='100'
                :width='10'
                color='#0f5b9a'
                indeterminate
                style="margin-top: 100px;"
            )

        v-container.mx-auto(v-if="!isLoading")
            .text-subtitle-2.subtitle-2-padding.text-center
                | Sales

            SaleDetails(
                v-if="validSales"
                v-for='sale in sales'
                :key='sale.sale_id'
                :sale="sale"
            )

            v-alert(outlined type='warning' dense border='left' style="margin-top: 16px;" v-if="!validSales")
                | Customer does not have any vehicle sales

</template>

<script lang="ts">
    import Vue from 'vue';

    // Components
    import SaleDetails from '@/components/sales/sale_details.vue';

    // Data
    import { GetSales } from "@/data";

    export default Vue.extend({
        data() {
            return {
                sales: [],
                isLoading: false,
                lastSaleID: null,
                limit: 100,
            }
        },
        methods: {
            getSales() {
                if (!this.validCustomer) {
                    return;
                }

                const options = {
                    customer_id: this.currentCampaignCustomer?.customer_id,
                    last_sale_id: this.lastSaleID || 0,
                    limit: this.limit,
                    organization_id: this.currentCampaignCustomer?.organization_id,
                }

                // todo - add show more to render above
                this.isLoading = true;
                GetSales(options).then((response) => {
                    if (response?.Data?.sales?.length > 0) {

                        // Get last record and set Id
                        this.lastSaleID = response.Data.sales[response.Data.sales.length - 1]?.sale_id;

                        if (this.sales?.length > 0) {
                            this.sales = this.sales.concat(response.Data.sales);
                        } else {
                            this.sales = response.Data.sales;
                        }
                    }
                    this.isLoading = false;
                });
            },
        },
        computed: {
            validSales() {
                return this.sales?.length > 0;
            },
            validCustomer() {
                return this.currentCampaignCustomer?.account_id > 0 && this.currentCampaignCustomer?.customer_id > 0 && this.currentCampaignCustomer?.organization_id > 0;
            },
        },
        components: {
            SaleDetails,
        },
        props: {
            currentCampaignCustomer: Object,
        },
        created() {
            this.getSales();
        },
    });
</script>

<style lang="scss" scoped>
</style>
