import { get, post, put, destroy } from '.';

export function GetAgentStatusCampaignTypes(options = {}) {
    return get<any>("/app/api/agent_status_campaign_types", options);
}

export function GetAvailableAgentStatusCampaignTypes(options = {}) {
    return get<any>("/app/api/agent_status_campaign_types_available", options);
}

export function CreateAgentStatusCampaignTypes(id, options = {}) {
    return post<any>("/app/api/campaign_types/" + (id || 0).toString() + "/agent_statuses", options);
}

export function DestroyAgentStatusCampaignType(id) {
    return destroy<any>("/app/api/agent_status_campaign_types/" + (id || 0).toString());
}

export function DestroyAllAgentStatusCampaignTypes(id, options = {}) {
    return destroy<any>("/app/api/agent_status_campaign_types_all/" + (id || 0).toString(), options);
}

export function UpdateAgentStatusCampaignType(id, options = {}) {
    return put<any>("/app/api/agent_status_campaign_types/" + (id || 0).toString(), options);
}