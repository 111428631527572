import { get, post, destroy } from '.';

export function GetVinsolutionsDealers(options = {}) {
	return get<any>("/app/api/vin_solution_dealers", options);
}

export function DestroyVinsolutionsDealer(id) {
	return destroy<any>("/app/api/vin_solution_dealers/" + (id || 0).toString());
}

export function GetVinsolutionsDealersWithAccount(options = {}) {
	return get<any>("/app/api/vin_solution_dealers_with_account", options);
}

export function ProcessDealersImportable(options = {}) {
	return post<any>("/app/api/vin_solution_process_dealers_importable", options);
}

export function EnableAccountsForDealer(options = {}) {
	return post<any>("/app/api/vin_solution_enable_accounts", options);
}
