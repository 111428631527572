import { post, put, get, destroy } from '.';

export function GetCampaignMappings(options = {}) {
	return get<any>("/app/api/campaign_mappings", options);
}

export function GetCampaignMappingLeadSources(options = {}) {
	return get<any>("/app/api/campaign_mapping_lead_sources", options);
}

export function FindCampaignMapping(id) {
	return get<any>("/app/api/campaign_mappings/" + (id || 0).toString());
}

export function CreateCampaignMapping(options = {}) {
	return post<any>("/app/api/campaign_mappings", options);
}

export function UpdateCampaignMapping(id, options = {}) {
	return put<any>("/app/api/campaign_mappings/" + (id || 0).toString(), options);
}

export function DestroyCampaignMapping(id) {
	return destroy<any>("/app/api/campaign_mappings/" + (id || 0).toString());
}
