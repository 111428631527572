<template lang="pug">
    div.mx-lg-auto(width="100%")
        template(v-if="validDeepgramTranscription")
            v-card.ma-2(v-if="!hideSpeakerTimeChart && validSpeakerTime" :outlined="isOutlined")
                v-card-title Speaker Time
                v-card-subtitle (in percentage)
                v-card-text
                    pie-chart(
                        :data="speakerTime"
                        :colors="['#ffce27', '#0f5b9a', '#e5e5e5', '#c4716c']"
                        plugins=[chartjsPluginDatalabels]
                    )

            v-card(:outlined="isOutlined")
                v-card-title(style="font-size: 1rem;")
                    | Transcript
                    div.audio-wrapper.ml-auto.width-100-percent
                        audio#audio-playback(
                            controls
                            :src="conference.recording_url"
                        )
                v-card-text
                    v-row.transcription-segment.ma-0.grey.lighten-2(style="padding-top:20px;")
                        v-col.sentiment(cols='3')
                            h6(style="font-size: .9rem;") Confidence
                        v-col.transcript-content(cols='9')
                            h6(style="font-size: .9rem;") Transcription Text

                    //- v-row.transcription-segment.ma-0.grey-side-border(v-for="utterance in deepgramTranscriptionObj.results.utterances" :key="utterance.start" :class="isActiveRecordingSegment(utterance)")
                    v-row.transcription-segment.ma-0(v-for="utterance in deepgramTranscriptionObj.results.utterances" :key="utterance.start" :class="isActiveRecordingSegment(utterance)")
                        v-col.sentiment(cols='3')
                            strong {{ utterance.confidence.toFixed(2) * 100 }}%
                        v-col.transcript-content(cols='9')
                            .participant Speaker {{ utterance.speaker + 1 }} -
                                span(class="time-seek" @click="setTimeOfPlayback(utterance.start)") {{ utterance.start.toFixed(2) }}
                            .content {{utterance.transcript}}


                    //- v-row.transcription-segment.ma-0.grey.lighten-2(style="padding: 1px 0px;")


        div(v-if="!validDeepgramTranscription")
            v-alert(outlined type='info' dense border='left' style="margin-top: 16px;")
                | There is no call transcription for this conference

</template>

<script lang="ts">
import Vue from 'vue';

import Chartkick from 'vue-chartkick';
import Chart from 'chart.js';

Vue.use(Chartkick.use(Chart));

// Components
import SentimentIcon from '@/components/call_transcriptions/sentiment_icon.vue';

export default Vue.extend({
    data() {
        return {
            audio: null,
            agentDecibels: [],
            compatibleTranscription: true,
            customerDecibels: [],
            currentSeconds: 0,
            displayStyle: 'normal',
            issues: [],
            playing: false,
            sentiments: [],
            speakerTime: [],
            transcription: [],
        }
    },
    methods: {
        isActiveRecordingSegment(utterance) {
            const beginningSeconds = utterance.start.toFixed(0)
            const endingSeconds = utterance.end.toFixed(0)
            if (this.currentSeconds >= beginningSeconds && this.currentSeconds <= endingSeconds) {
                return "active-segment"
            } else {
                return "inactive-segment"
            }
        },
        hasIssues(segment) {
            return segment?.IssuesDetected?.length > 0
        },
        setParticipant(name) {

            let cleanedName = name

            if(this.displayStyle === 'flipped') {
                if (name === 'CUSTOMER') {
                    cleanedName = 'AGENT'
                }
                if (name === 'AGENT') {
                    cleanedName = 'CUSTOMER'
                }
            }

            return cleanedName.toLowerCase()
        },
        setSpeakerTime(){
            if (!this.validDeepgramTranscription) {
                return;
            }
            // this.deepgramTranscriptionObj.results.utterances.forEach((utterance) => {
            //     const participant = this.setParticipant(utterance.speaker)
            //     const time = utterance.end - utterance.start
            //     if (this.speakerTime.length === 0) {
            //         this.speakerTime.push([participant, time])
            //     } else {
            //         const index = this.speakerTime.findIndex((element) => element[0] === participant)
            //         if (index === -1) {
            //             this.speakerTime.push([participant, time])
            //         } else {
            //             this.speakerTime[index][1] += time
            //         }
            //     }
            // })
            const speakerTimes = new Map();

            for (let i = 0; i < this.deepgramTranscriptionObj.results.utterances.length; i++) {
                const speaker = this.deepgramTranscriptionObj.results.utterances[i].speaker;
                const startTime = this.deepgramTranscriptionObj.results.utterances[i].start;
                const endTime = this.deepgramTranscriptionObj.results.utterances[i].end;

                const talkTime = endTime - startTime;

                if (speakerTimes.has(speaker)) {
                    speakerTimes.set(speaker, speakerTimes.get(speaker) + talkTime);
                } else {
                    speakerTimes.set(speaker, talkTime);
                }
            }

            this.speakerTime = Array.from(speakerTimes, ([name, value]) => [`Speaker ${name + 1}`, (value / this.deepgramTranscriptionObj.metadata.duration * 100).toFixed(2)]);
            // this is used to figure out the silence time (remaining percentage)
            this.speakerTime.push(['Silence', (100 - this.speakerTime.reduce((accumulator, currentElement) => accumulator + parseFloat(currentElement[1]), 0)).toFixed(2)]);
        },
        setTimeInSeconds(millis) {
            const minutes = Math.floor(millis / 60000);
            const seconds = ((millis % 60000) / 1000).toFixed(0);
            return minutes + ":" + (parseInt(seconds) < 10 ? '0' : '') + seconds;
        },
        setTimeOfPlayback(seconds) {
            this.audio.currentTime = seconds.toFixed(0);
            this.audio.play();
        },

        toggleDisplayType() {
          if(this.displayStyle === 'normal') {
              this.displayStyle = 'flipped';
              this.updateCharts();
              return
          }
          if(this.displayStyle === 'flipped') {
              this.displayStyle = 'normal';
              this.updateCharts();
              return
          }
        },
        updateCharts() {
            this.sentiments = [];
            this.speakerTime = [];
            this.setSpeakerTime();
        },
        update() {
            this.currentSeconds = parseInt(this.audio.currentTime);
        },
    },
    computed: {
        deepgramTranscriptionObj() {
            return this.callTranscription?.deepgram_transcription_obj || null;
        },
        validDeepgramTranscription() {
            return this.deepgramTranscriptionObj?.metadata?.duration > 0.0 && this.deepgramTranscriptionObj?.results?.utterances?.length > 0;
        },
        validSpeakerTime() {
            return true
        }
    },
    components: {
        SentimentIcon
    },
    props: {
        callTranscription: Object,
        conference: Object,
        hideSpeakerTimeChart: Boolean,
        isLoading: Boolean,
        isOutlined: Boolean,
    },
    watch: {
        playing(value) {
            if (value) { return this.audio.play(); }
            this.audio.pause();
        },
    },
    created() {
        this.setSpeakerTime();
    },
    destroyed() {
    },
    mounted() {
        this.audio = document.getElementById('audio-playback');
        if(this.audio) {
            this.audio.addEventListener('timeupdate', this.update);
            this.audio.addEventListener('loadeddata', this.load);
            this.audio.addEventListener('pause', () => { this.playing = false; });
            this.audio.addEventListener('play', () => { this.playing = true; });
        }
    },
})
</script>

<style lang="scss" scoped>
    #app {
        .grey-side-border {
            border-right: 1px #e0e0e0 solid;
            border-left: 1px #e0e0e0 solid;
        }

    }
</style>