import { get, put } from '.';

export function GetAppointmentHistory(options = {}) {
	return get<any>("/app/api/appointment_history", options);
}

export function GetAppointments(options = {}) {
	return get<any>("/app/api/appointments", options)
}

export function FindAppointment(vid: string, options = {}) {
	return get<any>(`/app/api/appointments/${vid}`, options)
}

export function UpdateAppointment(vid: string, options = {}) {
	return put<any>(`/app/api/appointments/${vid}`, options)
}