<template lang="pug">

    div
        v-card.mx-lg-auto(width="100%" v-if="formTicketTask")
            v-toolbar.volie-v-toolbar-color(dark)
                v-toolbar-title(v-if="formTicketTask.internal_ticket_task_id > 0") Edit Ticket Task
                v-toolbar-title(v-else) New Ticket Task

            v-card-text
                v-container
                    v-row
                        v-col(cols='12')
                            v-text-field(label='Title *' type='text' v-model="formTicketTask.title")

                        v-col(cols='12')
                            v-autocomplete(
                                v-model='formTicketTask.assigned_user_id'
                                :items='assignables'
                                label='Assignee'
                                item-text="assignable_display_name"
                                item-value="assignable_id"
                            )
                                template(v-slot:selection='{ item, index }')
                                    v-chip(v-if='index === 0')
                                        span {{ item.assignable_display_name }}

                        v-col(cols='6' v-if='validTicketTask' )
                            v-autocomplete(
                                v-model='formTicketTask.status'
                                :items='ticketStatuses'
                                label='Status *'
                                item-text="key"
                                item-value="value"
                                clearable
                            )

                        v-col(cols='6')
                            v-text-field(
                                v-model='formTicketTask.estimate_hours'
                                label='Estimate Hours'
                                type='number'
                            )

                        v-col(cols='6' v-if='validTicketTask' )
                            v-text-field(
                                v-model='formTicketTask.actual_hours'
                                label='Actual Hours'
                                type='number'
                            )

                    v-row
                        v-col(cols='12')
                            .text-right
                                v-btn.ma-2(outlined ripple v-if="isDialog" @click="closeDialog();")
                                    v-icon mdi-window-close
                                    | Close

                                v-btn.ma-2.white--text(:disabled="!eligibleToSave" :loading="isSaving" v-if="isUpdate" ripple color="green" @click="updateInternalTicketTask()")
                                    v-icon mdi-check
                                    | Update Ticket Task

                                v-btn.ma-2.white--text(:disabled="!eligibleToSave" :loading="isSaving" v-else ripple color="green" @click="createInternalTicketTask()")
                                    v-icon mdi-check
                                    | Create Ticket Task

</template>

<script lang="ts">
    import Vue from 'vue';
    import VolieState from '@/store';

    // Data
    import { CreateInternalTicketTask, GetAssignables, UpdateInternalTicketTask, } from "@/data";

    export default Vue.extend({
        data() {
            return {
                assignables: [],
                formTicketTask: {
                    assigned_user_id: null,
                    estimate_hours: null,
                    status: null,
                    title: null,
                },
                isSaving: false,
                ticketStatuses: [
                    {
                        key: 'Done',
                        value: 'done',
                    },
                    {
                        key: 'In Progress',
                        value: 'in_progress',
                    },
                    {
                        key: 'In Review',
                        value: 'in_review',
                    },
                    {
                        key: 'Open',
                        value: 'open',
                    },
                ],
            }
        },
        methods: {
            createInternalTicketTask() {
                if (!this.validTicket) {
                    return;
                }

                const options = {
                    ticket_id: this.ticket.ticket_id || 0,
                    assigned_user_id: parseInt(this.formTicketTask.assigned_user_id || 0),
                    estimate_hours: parseInt(this.formTicketTask.estimate_hours || 0),
                    title: this.formTicketTask.title || '',
                };

                this.isSaving = true;
                CreateInternalTicketTask(options).then((response) => {
                    if (response?.Error === null) {

                        // Bubble up updates
                        this.setChangesOnTicketTask(true);

                        this.showSnackbar("green", "Successfully created ticket task");

                    } else {
                        this.showSnackbar("error", "Unable to create ticket task");
                    }

                    this.isSaving = false;
                })
            },
            getAssignables() {
                const options = {
                    current_user_id: this.currentUser?.user_id || 0,
                }

                GetAssignables(options).then((response) => {
                    if (response?.Data?.assignables?.length > 0) {

                        const assignables = []
                        response.Data.assignables.forEach(function (assignable) {
                            if (assignable.assignable_type.toLowerCase() === 'user') {
                                assignables.push(assignable);
                            }
                        });

                        this.assignables = assignables;
                    }
                });

                return;
            },
            initFormTicketTask() {
                if (this.isUpdate) {
                    this.formTicketTask = { ...this.ticketTask };
                }

                return;
            },
            updateInternalTicketTask() {
                if (!this.validTicket && this.ticketTask) {
                    return;
                }

                const options = {
                    actual_hours: parseInt(this.formTicketTask.actual_hours || 0),
                    assigned_user_id: parseInt(this.formTicketTask.assigned_user_id || 0),
                    estimate_hours: parseInt(this.formTicketTask.estimate_hours || 0),
                    status: this.formTicketTask.status || '',
                    title: this.formTicketTask.title || '',
                };

                this.isSaving = true;
                UpdateInternalTicketTask(this.ticketTask?.internal_ticket_task_id, options).then((response) => {
                    
                    if (response?.Error === null) {

                        // Bubble up updates
                        this.setChangesOnTicketTask(true);

                        this.showSnackbar("green", "Successfully updated ticket task");
                    } else {
                        this.showSnackbar("error", "Unable to update ticket task");
                    }

                    this.isSaving = false;
                })
            },
        },
        computed: {
            currentUser() {
                return VolieState.currentUser();
            },
            eligibleToSave() {
                if (!(parseInt(this.formTicketTask?.assigned_user_id) > 0)) {
                    return false;
                }
                if (!(parseInt(this.formTicketTask?.estimate_hours) > 0)) {
                    return false;
                }
                if (!(this.formTicketTask?.title?.length > 0)) {
                    return false;
                }
                if (this.validTicketTask ) {
                    if (!(this.formTicketTask?.status?.length > 0)) {
                        return false;
                    }
                }

                return true;
            },
            isUpdate() {
                return this.ticketTask?.internal_ticket_task_id > 0;
            },
            validTicket() {
                return this.ticket?.ticket_id > 0;
            },
            validTicketTask() {
                return this.ticketTask?.internal_ticket_task_id > 0;
            },
        },
        props: {
            ticket: Object,
            ticketTask: Object,
            closeDialog: Function,
            isDialog: Boolean,
            setChangesOnTicketTask: Function,
            showSnackbar: Function,
        },
        created() {
            this.initFormTicketTask();
            this.getAssignables();
            return;
        },
    });
</script>

<style lang="scss" scoped>
</style>