<template lang="pug">
    div
        v-card.mx-lg-auto.mb-10(width="100%")
            v-toolbar.volie-v-toolbar-color(dark)
                v-toolbar-title Voice Mail Recording

            div.pa-5(v-if="recordingUrl" style="max-width: 100%; width: 100%;")
                VolieAudio(
                    :file="recordingUrl"
                    color="success"
                    :downloadable="!hideDownloadButtons"
                )
            
            div(v-else-if="isLoading === true" style="max-width: 100%; width: 100%; display: flex; justify-content: center;")
                v-progress-circular(
                    :size='100'
                    :width='10'
                    color='#0f5b9a'
                    indeterminate
                    style="margin-top: 75px; margin-bottom: 75px;"
                )
            
            div(v-else style="padding: 10px;")
                v-alert(outlined type='info' dense border='left' style="margin-top: 16px;")
                    | There are no voice mail recordings for this missed call


        v-card.mx-lg-auto.mb-10(width="100%")
            v-toolbar.volie-v-toolbar-color(dark)
                v-toolbar-title Missed Call

            v-card-text
                v-simple-table
                    template(v-slot:default)
                        tbody(v-if="validVoiceMailRecording")
                            tr
                                td(style="max-width: 50%; width: 50%;")
                                    .text-capitalize.text-overline.text--secondary.text--darken-3 Vid
                                    | {{ voiceMailRecording.voice_mail_recording_key }}
                                td(style="max-width: 50%; width: 50%;")
                                    .text-capitalize.text-overline.text--secondary.text--darken-3 Date / Time
                                    | {{ voiceMailRecording.friendly_created_at }}

                            tr
                                td(style="max-width: 50%; width: 50%;")
                                    .text-capitalize.text-overline.text--secondary.text--darken-3 Purpose
                                    div(v-if="voiceMailRecording.purpose && voiceMailRecording.purpose.length > 0")
                                        | {{ voiceMailRecording.purpose | titleize }}
                                    div(v-else)
                                        | N/a
                                td(style="max-width: 50%; width: 50%;")
                                    .text-capitalize.text-overline.text--secondary.text--darken-3 Match Status
                                    template(v-if="voiceMailRecording.customer_match_code === 1") Perfect Match
                                    template(v-else-if="voiceMailRecording.customer_match_code === 2") Customer match, mult. queues
                                    template(v-else-if="voiceMailRecording.customer_match_code === 3") Multiple possible customers
                                    template(v-else-if="voiceMailRecording.customer_match_code === 4") No Match
                                    template(v-else) N/a

                            tr
                                td(style="max-width: 50%; width: 50%;")
                                    .text-capitalize.text-overline.text--secondary.text--darken-3 Organization
                                    | {{ voiceMailRecording.organization_name }}
                                td(style="max-width: 50%; width: 50%;")
                                    .text-capitalize.text-overline.text--secondary.text--darken-3 Campaign
                                    | {{ voiceMailRecording.campaign_name }}

                            tr
                                td(style="max-width: 50%; width: 50%;")
                                    .text-capitalize.text-overline.text--secondary.text--darken-3 Customer
                                    div(v-if="voiceMailRecording.customer_full_name && voiceMailRecording.customer_full_name.length > 0")
                                        | {{ voiceMailRecording.customer_full_name }}
                                    div(v-else)
                                        | N/a
                                td(style="max-width: 50%; width: 50%;")
                                    .text-capitalize.text-overline.text--secondary.text--darken-3 Caller ID
                                    | {{ voiceMailRecording.from_number | tel }}

                            tr
                                td(style="max-width: 50%; width: 50%;")
                                    .text-capitalize.text-overline.text--secondary.text--darken-3 Status
                                    template(v-if="voiceMailRecording.status === 'queued'")
                                        v-icon.primary--text mdi-phone-log
                                        strong.primary--text Queued
                                    template(v-else-if="voiceMailRecording.status === 'assigned'")
                                        v-icon.warning--text mdi-account
                                        strong.warning--text Assigned
                                    template(v-else-if="voiceMailRecording.status === 'in_progress'")
                                        v-icon.error--text mdi-microphone
                                        strong.error--text In Progress
                                    template(v-else-if="voiceMailRecording.status === 'completed'")
                                        v-icon.green--text mdi-check
                                        strong.green--text Completed
                                td(style="max-width: 50%; width: 50%;")
                                    .text-capitalize.text-overline.text--secondary.text--darken-3 Communication Queue
                                    div(v-if="voiceMailRecording.communication_queue_name && voiceMailRecording.communication_queue_name.length > 0")
                                        | {{ voiceMailRecording.communication_queue_name }}
                                    div(v-else)
                                        | N/a

                            tr
                                td(style="max-width: 50%; width: 50%;")
                                    .text-capitalize.text-overline.text--secondary.text--darken-3 Language
                                    div(v-if="voiceMailRecording.language_name && voiceMailRecording.language_name.length > 0")
                                        | {{ voiceMailRecording.language_name }}
                                    div(v-else)
                                        | N/a

                        tbody(v-else-if='isLoading === true')
                            tr
                                td.text-center(colspan='8')
                                    v-progress-circular(
                                        :size='100'
                                        :width='10'
                                        color='#0f5b9a'
                                        indeterminate
                                        style="margin-top: 75px; margin-bottom: 75px;"
                                    )

                v-alert(v-if="!validVoiceMailRecording && isLoading === false" outlined type='info' dense border='left' style="margin-top: 16px;")
                    | There are no details for this missed call


        v-card.mx-lg-auto.mb-10(width="100%")
            v-toolbar.volie-v-toolbar-color(dark)
                v-toolbar-title Return Call

            //- Conference Details - conference_overview
            ConferenceDetails(
                :conference="returnCall"
                :hide-download-buttons="hideDownloadButtons"
                :is-loading="isLoading"
                :link-to-conference="true"
            )

</template>

<script lang="ts">
    import Vue from 'vue';
    

    // Components
    import VolieAudio from '@/components/shared/audio_player.vue';
    import ConferenceDetails from '@/components/conferences/conference_details.vue';

    export default Vue.extend({
        data() {
            return {}
        },
        computed: {
            recordingUrl() {
                return this.voiceMailRecording?.recording_url;
            },
            returnCall() {
                if (this.validReturnCall) {
                    return this.voiceMailRecording.return_call;
                }

                return null;
            },
            validVoiceMailRecording() {
                return this.voiceMailRecording?.voice_mail_recording_key?.length > 0;
            },
            validReturnCall() {
                return this.voiceMailRecording?.return_call?.conference_id > 0;
            },
        },
        components: {
            ConferenceDetails,
            VolieAudio,
        },
        props: {
            isLoading: Boolean,
            hideDownloadButtons: Boolean,
            voiceMailRecording: Object,
        },
    });
</script>

<style lang="scss" scoped>
</style> 
