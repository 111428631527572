<template lang="pug">

	span(:style="{ color: dynamicColor}")
		v-icon(medium :color="dynamicColor" ) {{ dynamicIcon }}
		strong {{ name ? name : '---' }}

</template>

<script lang="ts">
    import Vue from 'vue';

    export default Vue.extend({
		computed: {
            dynamicColor() {
				switch(this.name) {
					case "Processed":
						return "#D6A45E"
					case "Dropped":
						return "#B2129F"
					case "Delivered":
						return "#BCD514"
					case "Deferred":
						return "#459E1A"
					case "Bounce":
						return "#C042BE"
					case "Open":
						return "#028690"
					case "Click":
						return "#59C1CA"
					case "Complained":
						return "#E04427"
					case "Unsubscribe":
						return "#3E44C0"
					case "Group Unsubscribe":
						return "#744AEA"
					case "Group Resubscribe":
						return "#A64AE8"
					default:
						return ""
				}
			},
			dynamicIcon() {
				switch(this.name) {
					case "Processed":
						return "mdi-email-sync"
					case "Dropped":
						return "mdi-email-off"
					case "Delivered":
						return "mdi-email-send"
					case "Deferred":
						return "mdi-email-sync"
					case "Bounce":
						return "mdi-email-off"
					case "Open":
						return "mdi-email-open"
					case "Click":
						return "mdi-email-check"
					case "Complained":
						return "mdi-email-alert"
					case "Unsubscribe":
						return "mdi-email-minus"
					case "Group Unsubscribe":
						return "mdi-email-minus"
					case "Group Resubscribe":
						return "mdi-email-plus"
					default:
						return ""
				}
			},
        },
        props: {
            name: String,
        },
    });
</script>

<style lang="scss" scoped>
</style>
