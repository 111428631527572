<template lang="pug">
    strong.text-uppercase(:class="statusClassMatcher") {{ text | titleize }}
</template>

<script lang="ts">
    import Vue from 'vue';

    // Helpers
    import { statusClassMatcher } from "@/helpers/css_classes"

    export default Vue.extend({
        data() {
            return {}
        },
        methods: {
        },
        computed: {
            statusClassMatcher() {
                return statusClassMatcher(this.text);
            }
        },
        props: {
            text: String,
        },
    });
</script>

<style lang="scss" scoped>
</style>
