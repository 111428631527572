import { get, post, put, destroy } from '.';

export function GetCampaignEmailTemplates(options = {}) {
	return get<any>("/app/api/campaign_email_templates", options);
}

export function UpdateCampaignEmailTemplate(id: string, options = {}) {
	return put<any>(`/app/api/campaign_email_templates/${id}`, options);
}

export function GetAvailableEmailTemplates(options = {}) {
	return get<any>("/app/api/campaign_email_templates/available_email_templates", options);
}

export function CreateCampaignEmailTemplate(options = {}) {
	return post<any>("/app/api/campaign_email_templates/create", options);
}

export function DeleteCampaignEmailTemplate(id: string, options = {}) {
	return destroy<any>(`/app/api/campaign_email_templates/${id}`, options);
}

export function SwapCampaignEmailTemplate(options = {}) {
	return post<any>(`/app/api/campaign_email_templates/swap`, options);
}

export function CreateCampaignEmailTemplates(options = {}) {
	return post<any>("/app/api/campaign_email_templates", options);
}

export function DestroyCampaignEmailTemplate(id: string, options = {}) {
	return destroy<any>(`/app/api/campaign_email_templates/${id}`, options);
}




