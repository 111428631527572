<template lang="pug">
    v-card.my-md-4(
        v-if="validSurveyTemplateQuestion"
        outlined
    )
        v-card-text
            div
                //- question
                p(style="word-break: break-word;") 
                    | {{ questionText }}

            v-container.px-0.pb-0(fluid)

                //- answer
                template(v-if="validSurveyAnswer")
                    strong(style="word-break: break-word;")
                        | {{ this.surveyAnswer.survey_answer }}
                template(v-else)
                    p ---

</template>

<script lang="ts">
    import Vue from 'vue';

    export default Vue.extend({
        data() {
            return {}
        },
        methods: {
        },
        computed: {
            questionText() {
                return this.surveyAnswer?.question_order + ". " + this.surveyAnswer?.question_text;
            },
            validSurveyAnswer() {
                return this.surveyAnswer?.survey_answer !== null && this.surveyAnswer?.survey_answer?.length > 0;
            },
            validSurveyTemplateQuestion() {
                return this.surveyAnswer?.survey_template_question_id > 0;
            },
        },
        components: {
        },
        props: {
            surveyAnswer: Object,
        },
    });
</script>

<style lang="scss" scoped>
</style>
