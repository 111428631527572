<template lang="pug">
    v-container.mx-auto(v-if="!isLoading")
        .text-subtitle-2.mb-5.text-center
            | Upcoming Appointments

        DetailsCard(
            v-if="validAppointments"
            v-for='appointment in tekionAppointments'
            :appointment="appointment"
            :convert-appointment-date-time-from-milliseconds="convertAppointmentDateTimeFromMilliseconds"
            :current-campaign-customer="currentCampaignCustomer"
            :display-reschedule-view="displayRescheduleView"
            :display-details-view="displayDetailsView"
            :handle-cancel-tekion-appointment="handleCancelTekionAppointment"
            icon="mdi-calendar-clock-outline"
            :set-scoped-appointment="setScopedAppointment"
            :set-tekion-appointment-details="setTekionAppointmentDetails"
            :set-tekion-error="setTekionError"
            :show-snackbar="showSnackbar"


        )

        v-alert(outlined type='warning' dense border='left' v-if="!validAppointments")
            | Customer does not have any upcoming appointments




</template>

<script lang="ts">
    import Vue from 'vue';

    // Components
    import DetailsCard from '@/components/communication_center/shared/tabs/tekion/details_card.vue';

    export default Vue.extend({
        data() {
            return {}
        },
        methods: {

        },
        computed: {
            validAppointments() {
                return this.tekionAppointments?.length > 0;
            },
        },
        components: {
            DetailsCard,
        },
        props: {
            convertAppointmentDateTimeFromMilliseconds: Function,
            currentCampaignCustomer: Object,
            displayRescheduleView: Function,
            displayDetailsView: Function,
            getTekionAppointments: Function,
            handleCancelTekionAppointment: Function,
            isLoading: Boolean,
            setScopedAppointment: Function,
            setTekionError: Function,
            showSnackbar: Function,
            setTekionAppointmentDetails: Function,
            tekionAppointments: Array,

        },
        created() {

        },
        destroyed() {
        }
    });
</script>

<style lang="scss" scoped>
</style>
