import { get, post, put, } from '.';

export function AssignReadyCampaignCustomers(options = {}) {
	return post<any>("/app/api/assign_ready_campaign_customers", options);
}

export function BuildAssignedCampaignCustomerForCampaignCustomer(id, options = {}) {
	return get<any>("/app/api/campaign_customers/" + (id || 0).toString() + "/assigned_campaign_customer", options);
}

export function EnrollCampaignCustomer(options = {}) {
	return post<any>("/app/api/campaign_customers", options);
}

export function ExitCurrentCampaignCustomer(id, options = {}) {
	return put<any>("/app/api/campaign_customers/" + (id || 0).toString() + "/exit_current", options);
}

export function ExpireCampaignCustomer(options = {}) {
	return put<any>("/app/api/campaign_customers/expire", options);
}

export function GetCampaignCustomers(options = {}) {
	return get<any>("/app/api/campaign_customers", options);
}

export function GetCampaignCustomerActivities(campaignCustomerId) {
	return get<any>("/app/api/campaign_customers/" + campaignCustomerId + "/activities")
}

export function GetCampaignCustomerAgents(campaignCustomerId) {
	return get<any>("/app/api/campaign_customers/" + campaignCustomerId + "/agents")
}

export function GetExpirationCodeBreakDown(options = {}) {
	return get<any>("/app/api/campaign_customers/expiration_code_breakdown", options)
}

export function BuildCampaignCustomerMailable(options = {}) {
	return get<any>("/app/api/campaign_customers/build_campaign_customer_mailable", options)
}

export function SendCampaignCustomerMailableNow(id, options = {}){
	return put<any>(`/app/api/campaign_customers/${id}/send_campaign_customer_mailable_now`, options)
}

export function ReenqueueCampaignCustomers(options = {}) {
	return put<any>('/app/api/campaign_customers/reenqueue_campaign_customers', options)
}