export function arraysHaveAtLeastOneCommonElement(array1, array2: any[]): boolean {

	// Verify both arrays have records
	if (!(array1?.length > 0) || !(array2?.length > 0)) {
		return false;
	}

	// Init
	let hasCommonElement = false;

	// Loop through array 1
	for (let i = 0; i < array1.length; i++) {

		// Loop through array 2
		for (let j = 0; j < array2.length; j++) {

			// Check if they are the same
			if (array1[i] === array2[j]) {
				hasCommonElement = true;
				break;
			}
		}

		// Break if we found common element
		if (hasCommonElement) {
			break;
		}
	}

	return hasCommonElement;
}

export function joinArrayOfStringsWithComma(array: string[]): string {
	return array.join(", ")
}