import { destroy, get, post, put } from '.';

export function GetEleadEmployeeMappings(eleadSubscriptionVid: string, eleadOrganizationVid: string, options = {}) {
    return get<any>(`/app/api/elead/subscriptions/${eleadSubscriptionVid}/organizations/${eleadOrganizationVid}/employee_user_mappings`, options);
}

export function FindEleadEmployeeUserMapping(eleadSubscriptionVid: string, eleadOrganizationVid: string, vid: string, options = {}) {
    return get<any>(`/app/api/elead/subscriptions/${eleadSubscriptionVid}/organizations/${eleadOrganizationVid}/employee_user_mappings/${vid}`, options);
}

export function CreateEleadEmployeeUserMapping(eleadSubscriptionVid: string, eleadOrganizationVid: string, options = {}) {
    return post<any>(`/app/api/elead/subscriptions/${eleadSubscriptionVid}/organizations/${eleadOrganizationVid}/employee_user_mappings`, options)
}

export function UpdateEleadEmployeeUserMapping(eleadSubscriptionVid: string, eleadOrganizationVid: string, vid: string, options = {}) {
    return put<any>(`/app/api/elead/subscriptions/${eleadSubscriptionVid}/organizations/${eleadOrganizationVid}/employee_user_mappings/${vid}`, options)
}

export function DestroyEleadEmployeeUserMapping(eleadSubscriptionVid: string, eleadOrganizationVid: string, id: string) {
    return destroy<any>(`/app/api/elead/subscriptions/${eleadSubscriptionVid}/organizations/${eleadOrganizationVid}/employee_user_mappings/${id}`);
}   