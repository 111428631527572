import { get, post } from '.';

export function GetConversationGroups(options = {}) {
	return get<any>("/app/api/conversation_groups", options);
}

export function GetConversationGroupConversations(options = {}) {
	return get<any>("/app/api/conversation_group_conversations", options);
}

export function ReadAllConversationGroupUnreadMessages(options = {}) {
	return post<any>("/app/api/read_all_conversation_group_unread_messages", options);
}
