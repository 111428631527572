import { get, post, put } from '.';

export function GetVinsolutionsLeads(options = {}) {
	return get<any>("/app/api/vin_solution_leads", options);
}

export function ProcessLeadsImportable(options = {}) {
	return post<any>("/app/api/vin_solution_process_leads_importable", options);
}

export function GetVinsolutionsLead(leadKey, options = {}) {
	return get<any>("/app/api/vin_solution_leads/" + leadKey, options);
}

export function CreateVinsolutionsLead(options = {}) {
	return post<any>("/app/api/vin_solution_leads", options);
}

export function UpdateVinsolutionsLead(options = {}) {
	return put<any>("/app/api/vin_solution_leads", options);
}
