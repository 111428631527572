import { destroy, get, post } from '.';

export function CreateCustomerVehicle(options = {}) {
	return post<any>("/app/api/customer_vehicles", options);
}

export function DestroyCustomerVehicle(id, options = {}) {
	return destroy<any>("/app/api/customer_vehicles/" + (id || 0).toString(), options);
}

export function GetCustomerVehicles(options = {}) {
	return get<any>("/app/api/customer_vehicles", options);
}

export function GetCustomerVehiclesToyoguard(options = {}) {
	return get<any>("/app/api/customer_vehicles/toyoguard", options);
}
