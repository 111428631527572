<template lang="pug">
    .mt-2(v-if="validCustomerID")

        .text-center(v-if="isLoading")
            v-progress-circular(
                :size='100'
                :width='10'
                color='#0f5b9a'
                indeterminate
                style="margin-top: 100px;"
            )

        v-container.mx-auto(v-else)
            .text-subtitle-2.subtitle-2-padding.text-center
                | Telematic Alerts

            template(v-if="validTelematicAlerts")
                TelematicAlertDetails(
                    v-for='telematicAlert in telematicAlerts'
                    :key='telematicAlert.telematic_alert_id'
                    :telematic-alert="telematicAlert"
                    :current-campaign-customer="currentCampaignCustomer"
                )

                v-simple-table
                    template(v-slot:default)
                        tbody
                            ShowMoreRow(
                                :colspan='1'
                                :data-length='totalTelematicAlerts'
                                :handle-show-more="getTelematicAlerts"
                                :is-show-more-loading="isLoading"
                                :limit='limit'
                                :show-moreable="showMoreable"
                                :splices="0"
                            )
            
            v-alert(outlined type='warning' dense border='left' style="margin-top: 16px;" v-else)
                | Unable to find any Telematic Alerts


</template>

<script lang="ts">
    import Vue from 'vue';

    // Components
    import ShowMoreRow from '@/components/shared/show_more_row.vue';
    import TelematicAlertDetails from '@/components/telematic_alerts/details.vue';

    // Data
    import { GetTelematicAlerts } from "@/data";

    export default Vue.extend({
        data() {
            return {
                isLoading: false,
                lastTelematicAlertID: null,
                limit: 25,
                showMoreable: false,
                telematicAlerts: [],
            }
        },
        methods: {
            getTelematicAlerts(showLoader) {
                const options = {
                    campaign_enrollment: "all",
                    customer_id: (this.customerId || 0),
                    last_telematic_alert_id: (this.lastTelematicAlertID || 0),
                    limit: this.limit,
                }
                if (showLoader) {
                    this.isLoading = true;
                }
                GetTelematicAlerts(options).then((response) => {
                    if (response?.Data?.telematic_alerts?.length > 0) {
                        if ((!!this.telematicAlerts && this.telematicAlerts.length > 0)){
                            this.telematicAlerts =  this.telematicAlerts.concat(response.Data.telematic_alerts);
                        } else {
                            this.telematicAlerts = response.Data.telematic_alerts;
                        }

                        // Get last record and set ID
                        this.lastTelematicAlertID = this.telematicAlerts[this.telematicAlerts.length - 1]?.telematic_alert_id;
                        this.showMoreable = true;
                    } else {
                        this.showMoreable = false;
                    }

                    this.isLoading = false;
                })
            },
        },
        computed: {
            totalTelematicAlerts() {
                return this.telematicAlerts?.length || 0;
            },
            validCustomerID() {
                return this.customerId > 0;
            },
            validTelematicAlerts() {
                return this.totalTelematicAlerts > 0;
            },
        },
        components: {
            ShowMoreRow,
            TelematicAlertDetails,
        },
        props: {
            currentCampaignCustomer: Object,
            customerId: Number,
        },
        created() {
            this.getTelematicAlerts(true);
        },
    });
</script>

<style lang="scss" scoped>
</style>
