import { get, post, destroy } from '.';

export function GetCampaignTypeSurveyTemplates(options = {}) {
    return get<any>("/app/api/campaign_type_survey_templates", options);
}

export function GetAvailableCampaignTypeSurveyTemplates(options = {}) {
    return get<any>("/app/api/campaign_type_survey_templates_available", options);
}

export function CreateCampaignTypeSurveyTemplates(id, options = {}) {
    return post<any>("/app/api/campaign_types/" + (id || 0).toString() + "/survey_templates", options);
}

export function DestroyCampaignTypeSurveyTemplate(id) {
    return destroy<any>("/app/api/campaign_type_survey_templates/" + (id || 0).toString());
}

export function DestroyAllCampaignTypeSurveyTemplates(id) {
    return destroy<any>("/app/api/campaign_type_survey_templates_all/" + (id || 0).toString());
}