import { get, post } from '.';

export function GetEleadSources(elead_subscription_vid: string, options = {}) {
	return get<any>("/app/api/elead/subscriptions/" + elead_subscription_vid + "/sources", options);
}

export function ImportEleadSources(options = {}) {
	return post<any>("/app/api/elead/sources/import", options);
}

export function GetEleadSubSources(elead_subscription_vid: string, options = {}) {
	return get<any>("/app/api/elead/subscriptions/" + elead_subscription_vid + "/sub_sources", options);
}

export function ImportEleadSubSources(options = {}) {
	return post<any>("/app/api/elead/sub_sources/import", options);
}
