<template lang="pug">
    v-tooltip(top)
        template(v-slot:activator='{ on, attrs }')
            div(
                v-if="renderAddress"
                v-bind="attrs" 
                v-on="on" 
                v-clipboard:copy="fullAddress"
                v-clipboard:success="onCopySuccess"
                v-clipboard:error="onCopyError"
                style="cursor: pointer;"
                @mouseleave="onMouseLeave()"
            )
                | {{ addressLineOne() }}
                br(v-if="renderAddressLineOneBr")
                | {{ addressLineTwo() }}
                br(v-if="renderAddressLineTwoBr")
                | {{ addressLineThree() }}
        span
            | {{ tooltipMessage }}

</template>

<script lang="ts">
    import Vue from 'vue';
    import VueClipboard from 'vue-clipboard2';
    Vue.use(VueClipboard);

    export default Vue.extend({
        data() {
            return {
                tooltipMessage: 'Click to Copy',
            }
        },
        methods: {
            addressLineOne() {
                return ((this.streetOne || "") + " " + (this.streetTwo || "")).trim();
            },
            addressLineTwo() {

                const parts = [];
                if (this.city?.length > 0) {
                    parts.push(this.city);
                }

                if (this.region?.length > 0) {
                    parts.push(this.region);
                }

                return (parts.join(", ") + " " + (this.postalCode || "")).trim();
            },
            addressLineThree() {
                return this.country;
            },
            onCopyError() {
               this.tooltipMessage = 'Error copying!';
            },
            onCopySuccess() {
                this.tooltipMessage = 'Text Copied!';
            },
            onMouseLeave() {
                setTimeout(() => {
                    this.tooltipMessage = 'Click to Copy';
                }, 250);
            }
        },
        computed: {
            fullAddress() {
                if (!(this.renderAddress)) {
                    return ""
                }

                const address1 = this.addressLineOne();

                let address2 = "";
                let address3 = "";

                if (this.renderAddressLineOneBr) {
                    address2 = this.addressLineTwo();
                }

                if (this.renderAddressLineTwoBr) {
                    address3 = this.addressLineThree();
                }

                return (address1 + ' ' + (address2 + ' ' + address3).trim()).trim();
            },
            renderAddress() {
                return this.renderAddressLineOneBr || this.renderAddressLineTwoBr
            },
            renderAddressLineOneBr() {
                return this.addressLineOne()?.length > 0 && this.addressLineTwo()?.length > 0;
            },
            renderAddressLineTwoBr() {
                return this.addressLineTwo()?.length > 0 && this.addressLineThree()?.length > 0;
            },
        },
        components: {
        },
        props: {
            streetOne:  String,
            streetTwo:  String,
            city:       String,
            region:     String,
            postalCode: String,
            country:    String,
        },
    });
</script>

<style lang="scss" scoped>
</style>
