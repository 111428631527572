<template lang="pug">
    div        
        v-divider(inset)
        template(v-if="validCustomerAddresses")
            v-list-item(
                v-for='(customerAddress, idx) in customerAddresses'
                :key='customerAddress.customer_address_id'
            )
                v-list-item-icon
                    v-icon(color='primary' v-if="idx === 0")
                        | mdi-map-marker
                v-list-item-content
                    v-list-item-title.list-item-title-overflow
                        AddressPostalFormat(
                            :street-one="customerAddress.address_street_one"
                            :street-two="customerAddress.address_street_two"
                            :city="customerAddress.address_city"
                            :region="customerAddress.address_region_abbr ? customerAddress.address_region_abbr : customerAddress.address_region"
                            :postal-code="customerAddress.address_postal_code"
                            :country="customerAddress.address_country_name"
                        )
                    //- v-list-item-subtitle {{ customerAddress.address_type | titleize }}
                    v-list-item-subtitle
                        v-btn(v-if="customerAddress.address_id !== primaryCustomerAddressId" text small color="green" @click="updateCustomerAddressPrimary(customerAddress.customer_address_id)" class="primary-btn") Set Primary
                        v-btn(v-if="customerAddress.address_id === primaryCustomerAddressId" text small disabled class="primary-btn") Primary
                v-list-item-action
                    CustomerAddressFormDialog(
                        v-if="!hideCreateUpdateDestroyButtons"
                        :customer-address="customerAddress"
                        :update-customer-address="updateCustomerAddress"
                    )
                v-list-item-action(style="margin-left: 0px;")
                    CustomerAddressDestroyDialog(
                        v-if="!hideCreateUpdateDestroyButtons"
                        :customer-address-id="customerAddress.customer_address_id"
                        :street-one="customerAddress.address_street_one"
                        :street-two="customerAddress.address_street_two"
                        :city="customerAddress.address_city"
                        :region="customerAddress.address_region"
                        :region-abbr="customerAddress.address_region_abbr"
                        :postal-code="customerAddress.address_postal_code"
                        :country="customerAddress.address_country_name"
                        :destroy-customer-address="destroyCustomerAddress"
                    )
            
            v-list-item(v-if="!hideCreateUpdateDestroyButtons")
                v-list-item-content
                    v-list-item-title.text-center
                        CustomerAddressFormDialog(
                            :create-customer-address="createCustomerAddress"
                        )

        template(v-else)
            v-list-item
                v-list-item-icon
                    v-icon(color='primary')
                        | mdi-map-marker
                v-list-item-content
                    v-list-item-title No addresses on file
            
            v-list-item(v-if="!hideCreateUpdateDestroyButtons")
                v-list-item-content
                    v-list-item-title.text-center
                        CustomerAddressFormDialog(
                            :create-customer-address="createCustomerAddress"
                        )

</template>

<script lang="ts">
import Vue from "vue";

// Components
import AddressPostalFormat from "@/components/shared/addresses/address_postal_format.vue";
import CustomerAddressDestroyDialog from "@/components/customer_addresses/destroy_dialog.vue";
import CustomerAddressFormDialog from "@/components/customer_addresses/form_dialog.vue";

export default Vue.extend({
    data() {
        return {};
    },
    methods: {},
    computed: {
        validCustomerAddresses() {
            return this.customerAddresses?.length > 0;
        }
    },
    components: {
        AddressPostalFormat,
        CustomerAddressDestroyDialog,
        CustomerAddressFormDialog
    },
    props: {
        customerAddresses: Array,
        primaryCustomerAddressId: Number,
        createCustomerAddress: Function,
        destroyCustomerAddress: Function,
        hideCreateUpdateDestroyButtons: Boolean,
        updateCustomerAddress: Function,
        updateCustomerAddressPrimary: Function
    }
});
</script>

<style lang="scss" scoped>
    #app {
        .theme--light.v-btn.v-btn--disabled {
            color: #1867c0 !important;
        }
        .v-data-table > .v-data-table__wrapper > table > tbody > tr > td , .v-data-table > .v-data-table__wrapper > table > tbody > tr > th, .v-data-table > .v-data-table__wrapper > table > thead > tr > td, .v-data-table > .v-data-table__wrapper > table > thead > tr > th, .v-data-table > .v-data-table__wrapper > table > tfoot > tr > td, .v-data-table > .v-data-table__wrapper > table > tfoot > tr > th {
            padding: 0px 4px;
        }
        .primary-btn.v-btn:not(.v-btn--round).v-size--small {
            padding: 0px 2px;
        }
        .list-item-title-overflow {
            overflow: auto !important;
            white-space: normal !important;
        }
    }
</style>