import { get, post } from '.';

export function FindProspect(id, options = {}) {
	return get<any>("/app/api/prospects/" + (id || 0).toString(), options);
}

export function GetProspects(options = {}) {
	return get<any>("/app/api/prospects", options);
}

export function GetProspectSources(options = {}) {
	return get<any>("/app/api/prospects/resource_data_sources", options);
}

export function GetProspectTypes(options = {}) {
	return get<any>("/app/api/prospects/prospect_types", options);
}

export function SyncProspectsForAccount() {
	return post<any>("/app/api/prospects/sync");
}
