<template lang="pug">
    .base-min-height
        v-container
            v-simple-table(v-if="validEmailRecipients")
                template(v-slot:default)
                    thead
                        tr
                            th Email Address
                            th Name
                            th Type
                            th Last Event
                            th Last Event Timestamp
                    tbody
                        tr(v-for='emailRecipient in emailRecipients' :key='emailRecipient.email_recipient_id')
                            td.no-wrap.sm-width {{ emailRecipient.to_address }}
                            td.no-wrap.sm-width {{ emailRecipient.to_name && emailRecipient.to_name.length > 0 ? emailRecipient.to_name : '---' }}
                            td.no-wrap.sm-width.text-uppercase {{ emailRecipient.recipient_type }}
                            td.no-wrap.sm-width 
                                EmailEvent(:name="emailRecipient.last_email_event_friendly_event")
                            td.no-wrap.sm-width {{ emailRecipient.last_email_event_friendly_timestamp && emailRecipient.last_email_event_friendly_timestamp.length > 0 ? emailRecipient.last_email_event_friendly_timestamp : '---' }}

            v-alert(outlined type='warning' dense border='left' style="margin-top: 16px;" v-else)
                | There are not any recipients for this email

</template>

<script lang="ts">
    import Vue from 'vue';

    // Components
    import EmailEvent from "@/components/emails/email_event.vue";

    export default Vue.extend({
        data() {
            return {
            }
        },
        methods: {
        },
        computed: {
            validEmailRecipients() {
                return this.emailRecipients?.length > 0;
            },
        },
        components: {
            EmailEvent,
        },
        props: {
            emailRecipients: Array,
        },
        created() {
        },
    });
</script>

<style lang="scss" scoped>
    #app {
        .base-min-height {
            min-height: 300px;
        }
    }
</style>