import { get, post, put, destroy } from '.';

export function CreateTag(options = {}) {
	return post<any>("/app/api/tags", options);
}

export function DestroyTag(options = {}) {
	return destroy<any>("/app/api/tags", options);
}

export function FindTag(tagKey, options = {}) {
	return get<any>("/app/api/tags/" + tagKey, options);
}

export function GetTags(options = {}) {
	return get<any>("/app/api/tags", options);
}

export function UpdateTag(id, options = {}) {
	return put<any>("/app/api/tags/" + (id || 0).toString(), options);
}