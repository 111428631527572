<template lang="pug">
    
    v-slide-group(
        v-if="validAssets || validTmpAssets"
        show-arrow="always"
        center-active
    )
        v-slide-item(
            v-for="asset in assets"
            :key="asset.id"
            v-slot="{ active, toggle }"
        )
            .ma-3
                Attachment(
                    :align-center="true"
                    :handle-remove="() => handleRemove(asset)"
                    :removable="true" 
                    :attachment="asset"
                    @click.native="toggle"
                )

        v-slide-item(
            v-for="(tmpAsset, idx) in tmpAssets"
            :key="'file-'+idx"
        )
            v-card.ma-3(style="width: 100px; height: 100px;")
                v-skeleton-loader(
                    class="mx-auto"
                    type="image"
                    width="100px"
                    height="100px"
                    :loading="true"
                )

</template>

<script lang="ts">
    import Vue from 'vue';

    // Components
    import Attachment from '@/components/shared/attachments/attachment_card.vue';

    export default Vue.extend({
        methods: {},
        computed: {
            validAssets() {
                return this.assets?.length > 0;
            },
            validTmpAssets() {
                return this.tmpAssets?.length > 0;
            },
        },
        props: {
            assets: Array,
            handleRemove: Function,
            tmpAssets: Array,
        },
        components: {
            Attachment,
        },
    });
</script>

<style lang="scss" scoped>
</style>
