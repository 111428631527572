<template lang="pug">
    .ma-sm-2(v-if="validComponent")
        .text-center(v-if="isLoading")
            v-progress-circular(
                :size='100'
                :width='10'
                color='#0f5b9a'
                indeterminate
                style="margin-top: 100px;"
            )

        v-container.mx-auto(v-else)
            v-simple-table
                template(v-slot:default)
                    tbody
                        tr
                            th.text-center
                                v-btn-toggle(v-model="toggleFilter")
                                    v-btn
                                        v-tooltip(top)
                                            template(v-slot:activator='{ on, attrs }')
                                                span(v-bind="attrs" v-on="on" role="text")
                                                    v-icon mdi-calendar-multiple
                                            span All Events
                                    v-btn
                                        v-tooltip(top)
                                            template(v-slot:activator='{ on, attrs }')
                                                span(v-bind="attrs" v-on="on" role="text")
                                                    v-icon mdi-headphones
                                            span Communication Events
                                    v-btn
                                        v-tooltip(top)
                                            template(v-slot:activator='{ on, attrs }')
                                                span(v-bind="attrs" v-on="on" role="text")
                                                    v-icon mdi-currency-usd
                                            span Transaction Events

                            th.text-center
                                v-btn-toggle(v-model="toggleExpand")
                                    v-btn(@click="updateAllShows(false);")
                                        v-tooltip(top)
                                            template(v-slot:activator='{ on, attrs }')
                                                span(v-bind="attrs" v-on="on" role="text")
                                                    v-icon mdi-close-box-multiple
                                            span Close All
                                    v-btn(@click="updateAllShows(true);")
                                        v-tooltip(top)
                                            template(v-slot:activator='{ on, attrs }')
                                                span(v-bind="attrs" v-on="on" role="text")
                                                    v-icon mdi-expand-all
                                            span Expand All

            v-timeline(
                align-top
                dense
            )
                EventTimelineItem(
                    v-for='event in renderEvents'
                    :key='event.event_id'
                    :event="event"
                    :show-click="showClick"
                    :source="source"
                )

</template>

<script lang="ts">
    import Vue from 'vue';

    // Components
    import EventTimelineItem from '@/components/events/timeline_item.vue';

    // Data
    import {
        GetEvents,
    } from "@/data";

    export default Vue.extend({
        data() {
            return {
                events: [],
                isLoading: false,
                renderEvents: [],
                toggleExpand: false,
                toggleFilter: 0,
            }
        },
        methods: {
            buildRenderEvents() {

                // Reset
                this.renderEvents = [];

                // If we don't have valid events, abort
                if (!this.validEvents) {
                    return;
                }

                // Init
                const filteredEvents = [];
                const tmpEvents = this.events.slice();
                if (!(tmpEvents?.length > 0)) {
                    return;
                }

                // Loop through tmp events and filter based on status
                for (let i = 0; i < tmpEvents.length; i++) {

                    // Init and verify
                    const event = { ...tmpEvents[i] };
                    if (!event || !(event.event_id > 0)) {
                        continue;
                    }

                    // Verify passes filter
                    if (!this.eventPassedFilters(event)) {
                        continue;
                    }

                    // Push onto array
                    filteredEvents.push(event);
                }

                if (filteredEvents.length === 0) {
                    return;
                }

                // Sort since some events were seeded historically and the IDs aren't in order
                this.renderEvents = this.sortEvents(filteredEvents);
            },
            eventPassedFilters(event) {

                // Verify
                if (!event || !(event.event_id > 0)) {
                    return false;
                }

                // Switch over event type
                switch(this.toggleFilter) {
                    case 1: // communication

                        // Ensure communication
                        if (event.event_type !== "communication") {
                            return false;
                        }
                        break;

                    case 2: // transaction
                        
                        // Ensure transaction
                        if (event.event_type !== "transaction") {
                            return false;
                        }
                        break;
                }

                return true;
            },
            getEvents() {
                const options = {
                    customer_id: this.customerId,
                }

                GetEvents(this.organizationId, options).then((response) => {
                    if (response?.Data?.events?.length > 0) {
                        if (!!this.events && this.events.length > 0) {
                            this.events = this.events.concat(response.Data.events);
                        } else {
                            this.events = response.Data.events;
                        }
                    }

                    this.isLoading = false;
                });
            },
            showClick(event) {

                // Verify
                if (!event) {
                    return;
                }

                // Reverse
                event.show_card = !event.show_card;
            },
            sortEvents(events) {
                if (!(!!events && events.length > 0)) {
                    return [];
                }

                // return events;
                return events.sort(function(x, y) {
                    return (new Date(y.event_timestamp)).getTime() > (new Date(x.event_timestamp)).getTime() ? 1 : -1;
                });
            },
            updateAllShows(showCard) {

                // Verify
                if (!this.validEvents) {
                    return;
                }

                // Loop over and set
                for (let i = 0; i < this.events?.length; i++) {
                    this.events[i].show_card = showCard;
                }
            },
        },
        components: {
            EventTimelineItem,
        },
        computed: {
            validComponent() {
                return this.validCustomerID && this.validOrganizationID;
            },
            validCustomerID() {
                return this.customerId > 0;
            },
            validEvents() {
                return this.events?.length > 0;
            },
            validOrganizationID() {
                return this.organizationId > 0;
            },
        },
        props: {
            customerId: Number,
            organizationId: Number,
            source: String,
        },
        watch: {
            events: {
                deep: true, // This will let Vue know to look inside the array
                handler() {

                    // Then on next tick, apply changes to rendered agents
                    Vue.nextTick(() => {

                        // If we have not built records initially, do that. Otherwise just apply changes
                        return this.buildRenderEvents();
                    });
                },
            },
            "toggleFilter"() {
                Vue.nextTick(() => { this.buildRenderEvents(); });
            },
        },
        created() {
            this.getEvents();
        },
    });
</script>

<style lang="scss" scoped>
</style>
