<template lang="pug">
    .ma-sm-2(v-if="validForte")

        v-snackbar(
                v-model='snackbar.show'
                :color='snackbar.color'
                :timeout="snackbar.timeout"
                top
            )
                | {{ snackbar.text }}
                v-btn(dark text @click='snackbar.show = false')
                    | Close

        .text-center(v-if="isLoading")
            v-progress-circular(
                :size='100'
                :width='10'
                color='#0f5b9a'
                indeterminate
                style="margin-top: 100px;"
            )

        v-container.mx-auto(v-if="!isLoading")
            .text-subtitle-2.subtitle-2-padding.text-center
                | Forte

            v-alert(outlined type='warning' dense border='left' style="margin-top: 16px;" v-if="!validFortePostLead")
                | Unable to find forte post lead

            v-row(align="center")
                v-col(cols='12')
                    v-form(v-if="validFortePostLead")

                        v-text-field(
                            v-model="fortePostLead.first_name"
                            label='First Name'
                            rows="1"
                            clearable
                            outlined
                            dense
                            prepend-inner-icon="mdi-account"
                        )

                        v-text-field(
                            v-model="fortePostLead.last_name"
                            label='Last Name'
                            rows="1"
                            clearable
                            outlined
                            dense
                            prepend-inner-icon="mdi-account"
                        )

                        v-text-field(
                            v-model="fortePostLead.phone"
                            label='Primary Phone Number'
                            rows="1"
                            clearable
                            outlined
                            dense
                            prepend-inner-icon="mdi-phone"
                        )

                        v-text-field(
                            v-model="fortePostLead.secondary_phone"
                            label='Secondary Phone Number'
                            rows="1"
                            clearable
                            outlined
                            dense
                            prepend-inner-icon="mdi-phone"
                        )

                        v-text-field(
                            v-model="fortePostLead.email"
                            label='Email Address'
                            rows="1"
                            clearable
                            outlined
                            dense
                            prepend-inner-icon="mdi-email"
                        )

                        v-text-field(
                            v-model="fortePostLead.address"
                            label='Street'
                            rows="1"
                            clearable
                            outlined
                            dense
                            prepend-inner-icon="mdi-map-marker"
                        )

                        v-text-field(
                            v-model="fortePostLead.city"
                            label='City'
                            rows="1"
                            clearable
                            outlined
                            dense
                            prepend-inner-icon="mdi-map-marker"
                        )

                        v-text-field(
                            v-model="fortePostLead.state"
                            label='Region'
                            rows="1"
                            clearable
                            outlined
                            dense
                            prepend-inner-icon="mdi-map-marker"
                        )

                        v-text-field(
                            v-model="fortePostLead.zip"
                            label='Postal Code'
                            rows="1"
                            clearable
                            outlined
                            dense
                            prepend-inner-icon="mdi-map-marker"
                        )

                        v-text-field(
                            v-model="fortePostLead.year"
                            label='Vehicle Model Year'
                            rows="1"
                            clearable
                            outlined
                            dense
                            prepend-inner-icon="mdi-car"
                        )

                        v-text-field(
                            v-model="fortePostLead.make"
                            label='Vehicle Make'
                            rows="1"
                            clearable
                            outlined
                            dense
                            prepend-inner-icon="mdi-car"
                        )

                        v-text-field(
                            v-model="fortePostLead.model"
                            label='Vehicle Model'
                            rows="1"
                            clearable
                            outlined
                            dense
                            prepend-inner-icon="mdi-car"
                        )

                        v-text-field(
                            v-model="fortePostLead.vin"
                            label='Vehicle VIN'
                            rows="1"
                            clearable
                            outlined
                            dense
                            prepend-inner-icon="mdi-car"
                        )

                        v-text-field(
                            v-model="fortePostLead.odometer"
                            label='Vehicle Mileage'
                            rows="1"
                            clearable
                            outlined
                            dense
                            prepend-inner-icon="mdi-car"
                        )

                        .text-right
                            v-btn.ma-2(
                                color='success'
                                outlined
                                ripple
                                @click='postToForteCrm()'
                            )
                                v-icon(style="margin-right: 5px;") mdi-arrow-right-circle-outline
                                | Post to Forte

</template>

<script lang="ts">
    import Vue from 'vue';
    import VolieState from '@/store';

    // Data
    import { FindFortePostLead, PostToForteCrm } from "@/data";

    export default Vue.extend({
        data() {
            return {
                fortePostLead: {},
                isLoading: false,
                snackbar: {
                    show: false,
                    timeout: 2500,
                },
            }
        },
        methods: {
            findFortePostLead() {
                if (!this.validForte) {
                    return;
                }
                const options = {
                    account_id:           this.currentCampaignCustomer?.account_id,
                    campaign_customer_id: this.currentCampaignCustomer?.campaign_customer_id,
                    organization_id:      this.currentCampaignCustomer?.organization_id,
                }

                this.isLoading = true;
                FindFortePostLead(options.campaign_customer_id, options).then((response) => {
                    if (response?.Data?.forte_post_lead?.campaign_customer_id > 0) {
                        this.fortePostLead = response.Data.forte_post_lead;
                    } else {
                        this.showSnackbar("red", "Failed to find forte post lead")
                    }
                    this.isLoading = false;
                });
            },
            showSnackbar(color, text) {
                if (color?.length > 0 && text?.length > 0) {
                    this.snackbar.color = color;
                    this.snackbar.text = text;
                    this.snackbar.show = true;
                }
            },
            postToForteCrm() {
                if (!this.validFortePostLead) {
                    return;
                }

                const options = this.fortePostLead;
                options.account_id           = this.currentCampaignCustomer.account_id;
                options.organization_id      = this.currentCampaignCustomer?.organization_id;
                options.campaign_customer_id = this.currentCampaignCustomer?.campaign_customer_id;
                options.campaign_id          = this.currentCampaignCustomer?.campaign_id;
                options.customer_id          = this.currentCampaignCustomer?.customer_id;

                this.isLoading = true;
                PostToForteCrm(this.fortePostLead).then((response) => {
                    if (!(response?.Error?.length > 0)) {
                        this.showSnackbar("green", "Successfully posted to Forte")
                    } else {
                        this.showSnackbar("red", "Failed to post to Forte")
                    }
                    this.isLoading = false;
                });
            },
        },
        computed: {
            currentUser() {
                return VolieState.currentUser();
            },
            isForteCrmEnabled() {
                return this.currentUser?.account?.forte_crm_enabled === true;
            },
            validCampaignCustomer() {
                return this.currentCampaignCustomer?.campaign_customer_active_id > 0 && this.currentCampaignCustomer?.account_id > 0 && this.currentCampaignCustomer?.customer_id > 0 && this.currentCampaignCustomer?.organization_id > 0;
            },
            validFortePostLead() {
                return this.fortePostLead?.campaign_customer_id > 0
            },
            validForte() {
                return this.validCampaignCustomer && this.isForteCrmEnabled;
            },
        },
        components: {
        },
        props: {
            currentCampaignCustomer: Object,
        },
        created() {
            this.findFortePostLead();
        },
    });
</script>

<style lang="scss" scoped>
</style>
