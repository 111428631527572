// Helpers
import { GetCampaignUserSummarySummaryRecordsFollowUps } from '@/data';
import { formatPhoneNumber } from '@/helpers/phone_number';

export function secondsToFriendlyElapsedTimeFormat(seconds: number): string {
	if (seconds === undefined || seconds === null || seconds <= 0) {
		return "---";
	}

	const days = Math.floor(seconds / 86400);
	if (days === 0) {
		const hours = Math.floor(seconds / 3600)
		const minutes = Math.floor(seconds / 60)

		// only return hour value without mins & secs if hours > 0
		let hourPiece = ""
		if (hours === 1) {
			hourPiece = hours.toString() + " hour";
			return hourPiece;
		} else if (hours > 1) {
			hourPiece = hours.toString() + " hours";
			return hourPiece;
		}

		// only return minute value without secs if minutes > 0
		let minutePiece = ""
		if (minutes === 1) {
			minutePiece = minutes.toString() + " minute";
			return minutePiece;
		} else if (minutes > 1) {
			minutePiece = minutes.toString() + " minutes";
			return minutePiece;
		}

		// only return second value if > 0 - else "---"
		let secondPiece = ""
		if (seconds === 1) {
			secondPiece = seconds + " second";
			return secondPiece;
		} else if (seconds > 1) {
			secondPiece = seconds + " seconds";
			return secondPiece;
		} else {
			return "---"; // in case of decimal for whatever reason
		}
	}

	const years = Math.floor(days / 365);
	let yearPiece = "";
	if (years === 1) {
		yearPiece = years.toString() + " year";
	} else if (years > 1) {
		yearPiece = years.toString() + " years";
	}

	const dayCount = Math.floor(days % 365)
	let dayPiece = ""
	if (dayCount === 1) {
		dayPiece = dayCount.toString() + " day";
	} else if (dayCount > 1) {
		dayPiece = dayCount.toString() + " days";
	}
	// ex: 1 year 14 days / 2 years 1 day, etc.
	return (yearPiece + " " + dayPiece).trim();
}

export function friendlyDurationFormat(seconds: number): string {
	if (seconds === undefined || seconds === null || seconds <= 0) {
		return "---";
	}

	const hours = (seconds / 3600);
	let hourPiece = "";
	if (hours >= 1) {
		if (hours.toFixed() === "1") {
			hourPiece = Math.floor(hours).toString() + " hr";
		} else {
			hourPiece = Math.floor(hours).toString() + " hrs";
		}
	}

	const minutes = ((seconds - (Math.floor(hours) * 3600)) / 60);
	let minutePiece = "";
	if (minutes >= 1) {
		if (minutes.toFixed() === "1") {
			minutePiece = Math.floor(minutes).toString() + " min";
		} else {
			minutePiece = Math.floor(minutes).toString() + " mins";
		}
	}

	const secs = (seconds % 60);
	let secPiece = "";
	if (secs >= 1) {
		if (secs.toFixed() === "1") {
			secPiece = Math.floor(secs).toString() + " sec";
		} else {
			secPiece = Math.floor(secs).toString() + " secs";
		}
	}

	return (hourPiece + " " + minutePiece + " " + secPiece).trim();
}

export function secondsToHHMM(seconds: number): string {
		if (seconds === undefined || seconds === null || seconds <= 0) {
			return '00:00'
		}

		const time_min = Math.round(seconds / 60)
		const hours = Math.floor(time_min / 60)
		const minutes = time_min % 60
		const display_hours = hours < 10 ? '0' + hours : hours;
		const display_minutes = minutes < 10 ? '0' + minutes : minutes;

		return display_hours + ':' + display_minutes;
}

export function secondsToFullWords(seconds: number): string {
	if (seconds && seconds > 0) {
		const hours = Math.floor((seconds / 3600));
		let hrStr = "";
		const leftOverTime = (seconds - (hours * 3600)) / 3600;
		const minutes = Math.floor(leftOverTime * 60);
		let minStr = "";
		const secondsPart = ((seconds - (hours * 3600) - (minutes * 60)) % 60).toString().substring(0, 2);
		let secStr = "";

		if (hours <= 9) {
			hrStr = "0" + hours.toString();
		} else {
			hrStr = hours.toString();
		}

		if (minutes <= 9) {
			minStr = "0" + minutes.toString();
		} else {
			minStr = minutes.toString();
		}

		if (parseInt(secondsPart) <= 9) {
			secStr = "0" + parseInt(secondsPart).toString();
		} else {
			secStr = secondsPart.toString();
		}

		return hrStr + ":" + minStr + ":" + secStr;
	}

	return "00:00:00";
}

export function formatTime(time: string): string {
    if (time?.length > 0) {

        const pieces = time.split(":");
        if (pieces?.length === 2) {
            const hours = parseInt(pieces[0]);
            if (Number.isInteger(hours)) {
                if (hours > 11) {
                    return (hours === 12 ? "12:" + pieces[1] + " PM" : (hours % 12).toString() + ":" + pieces[1] + " PM");
                } else {
                    return (hours === 0 ? "12:" + pieces[1] + " AM" : hours.toString() + ":" + pieces[1] + " AM");
                }
            }
        }
    }

    return "";
}

export function formatDateFromDateTime (dateTime: string): string {
	if (!dateTime) {
		return "";
	}
	const date = dateTime.split("T")[0]

	const [year, month, day] = date.split('-');
	return `${month}/${day}/${year}`;
}

export function formatMMDDYYYYToYYYYMMDD (date: string): string {
	if (!date) {
		return "";
	}
	const [month, day, year] = date.split('/');
	const paddedMonth = String(month).padStart(2, '0');
	const paddedDay = String(day).padStart(2, '0');

	return `${year}-${paddedMonth}-${paddedDay}`;
}

export function formatMMDDYYYYArrayToYYYYMMDDArray (dates: string[]): string[] {
	if (!dates) {
		return [];
	}

	const formattedDates = [];

	for (let i = 0; i < dates.length; i++) {
		const date = dates[i];
		const [month, day, year] = date.split('/');
		const paddedMonth = String(month).padStart(2, '0');
		const paddedDay = String(day).padStart(2, '0');
		formattedDates.push(`${year}-${paddedMonth}-${paddedDay}`);
	}

	return formattedDates;
}

export function titleize(title: string): string {
	if (!title || title?.length === 0) { return ''; }

	return title.split("_").map(x => x.charAt(0).toUpperCase() + x.slice(1)).join(" ");
}

export interface VehicleYearMakeModelObj {
	vehicleModelYear: string;
	vehicleMake:      string;
	vehicleModel:     string;
	vehicleTrim?:      string;
}

export function concatVehicleYearMakeModel(vehObj: VehicleYearMakeModelObj): string {
	if (vehObj?.vehicleModelYear?.length > 0 || vehObj?.vehicleModel?.length > 0 || vehObj?.vehicleMake?.length > 0) {

		const names = [];

		// Year
		if (vehObj.vehicleModelYear?.length > 0) {
			names.push(vehObj.vehicleModelYear);
		}

		// Make
		if (vehObj.vehicleMake?.length > 0) {
			names.push(vehObj.vehicleMake);
		}

		// Model
		if (vehObj.vehicleModel?.length > 0) {
			names.push(vehObj.vehicleModel);
		}

		// Trim
		if (vehObj.vehicleTrim?.length > 0) {
			names.push(vehObj.vehicleTrim);
		}

		return names.join(" ").trim();
	}

	return ""
}

export function capitalizeString(x: string): string{
	return x?.length > 0 ? x.charAt(0).toUpperCase() + x.slice(1) : "";
}

export function numberToOrdinal(num: number): string {
	if (!num) {
		return "";
	}

	const suffixes = ["th","st","nd","rd"];
	const numMod = num % 100;
	return num + (suffixes[(numMod - 20) % 10] || suffixes[numMod] || suffixes[0]);
}

// Duplicate func
export function formatTel(tel: string): string {
	return formatPhoneNumber(tel);
}

export function stringFormat(input: string): string {
	if ( input === undefined || input === null || input.length <= 0 ) {
		return "---";
	}

	return input;
}

export function time12ToMilitary(input: string): string {

	// Verify
	if (!input) {
		return "";
	}

	// Split at :
	const hourRemaining = input.split(":");
	if (!(hourRemaining?.length === 2)) {
		return "";
	}

	// Parse hour
	let hour = parseInt(hourRemaining[0]);
	if (!(hour > 0)) {
		return "";
	}

	// Split at space
	const minutesAmPm = hourRemaining[1].split(" ");
	if (!(minutesAmPm?.length === 2)) {
		return "";
	}

	// Parse minutes
	const minutes = parseInt(minutesAmPm[0]);
	if (!(minutes >= 0)) {
		return "";
	}

	// Ensure valid
	if (['am', 'pm'].indexOf(minutesAmPm[1].toLowerCase()) < 0) {
		return "";
	}

	// If PM, need to add 12 to hours unless 'noon'
	if (minutesAmPm[1].toLowerCase() === "pm" && hour !== 12) {
		hour += 12;
	}

	// Build hour string
	let hourString = hour.toString();
	if (hour < 10) {
		hourString = "0" + hourString;
	}

	// Build minute string
	let minuteString = minutes.toString();
	if (minutes < 10) {
		minuteString = "0" + minuteString;
	}

	return hourString + ":" + minuteString;
}

export function timeMilitaryTo12(input: string): string {

	// Verify
	if (!input) {
		return "";
	}

	// Split at :
	const hourRemaining = input.split(":");
	if (!(hourRemaining?.length === 2)) {
		return "";
	}

	// Parse hour
	let hour = parseInt(hourRemaining[0]);
	if (!(hour > 0)) {
		return "";
	}

	// Parse minutes
	const minutes = parseInt(hourRemaining[1]);
	if (!(minutes >= 0)) {
		return "";
	}

	// Init
	let amPm = "AM";
	if (hour > 12) {
		hour = hour % 12;
		amPm = "PM";
	} else if (hour === 12) {
		amPm = "PM";
	}

	// Build hour string
	let hourString = hour.toString();
	if (hour < 10) {
		hourString = "0" + hourString;
	}

	// Build minute string
	let minuteString = minutes.toString();
	if (minutes < 10) {
		minuteString = "0" + minuteString;
	}

	return hourString + ":" + minuteString + " " + amPm;
}
interface TimeFormatParts {
    hour: number;
    minute: string;
    ampm: string;
}

export function timeMilitaryTo12HPieces(time: string): TimeFormatParts {
	// Turn into date object to use built-in date functions
    const date = new Date("1970-01-01 " + time);

    let hour = date.getHours();
    const minuteCheck = date.getMinutes();

    // Convert the hour to 12-hour format
    const ampm = hour >= 12 ? 'PM' : 'AM';
    hour = hour % 12;
    hour = hour ? hour : 12;

    // Ensure the minute is two digits long
	// Set temp variable to check if minute is less than 10
	let minute = '00'
    minute = minuteCheck < 10 ? '0' + minuteCheck : minuteCheck.toString();

    return { hour: hour, minute: minute, ampm: ampm };
}

export function timeMilitaryToFloat(input: string): number {

	// Verify
	if (!input) {
		return -1.0;
	}

	// Split at :
	const hourRemaining = input.split(":");
	if (!(hourRemaining?.length === 2)) {
		return -1.0;
	}

	// Parse hour
	const hour = parseInt(hourRemaining[0]);
	if (!(hour > 0)) {
		return -1.0;
	}

	// Parse minutes
	const minutes = parseInt(hourRemaining[1]);
	if (!(minutes >= 0)) {
		return -1.0;
	}

	return hour + ((minutes * 1.0) / 60.0);
}

export function hourMinutesAmPmToFloat(inputHour: string, inputMinutes: string, inputAmPm: string): number {

	// Verify
	if (!inputHour || !inputMinutes || !inputAmPm) {
		return -1.0;
	}

	// Parse hour
	let hour = parseInt(inputHour);
	if (!(hour > 0)) {
		return -1.0;
	}

	// Parse minutes
	const minutes = parseInt(inputMinutes);
	if (!(minutes >= 0)) {
		return -1.0;
	}

	// Add 12 to hour if pm (unless midnight or noon)
	if (hour === 12) {
		if (inputAmPm.toUpperCase() === "PM") {
			// noon should be left as 12
		} else {
			hour -= 12; // midnight should be 0
		}
	} else if (inputAmPm.toUpperCase() === "PM") {
		hour += 12;
	}

	return hour + ((minutes * 1.0) / 60.0);
}

export function jsDayToFriendly(day: number): string {
	let friendly = null;
	switch(day) {
	case 0:
		friendly = "sunday";
		break;
	case 1:
		friendly = "monday";
		break;
	case 2:
		friendly = "tuesday";
		break;
	case 3:
		friendly = "wednesday";
		break;
	case 4:
		friendly = "thursday";
		break;
	case 5:
		friendly = "friday";
		break;
	case 6:
		friendly = "saturday";
		break;
	}
	return friendly;
}

export function formatNumber(val: number): string {
	return (val || 0).toLocaleString();
}
