<template lang="pug">
    div
        .text-center(v-if="isLoading")
            v-progress-circular(
                :size='100'
                :width='10'
                color='#0f5b9a'
                indeterminate
                v-if="isLoading"
                style="margin-top: 100px; margin-bottom: 100px;"
            )

        .mt-8(v-else-if="successCreateSharedLink")
            v-alert.overflow-wrap-anywhere(outlined type='success' text border='left')
                CopyableText.pr-4(:text="createdSharedLink.url")

            template(v-if="!autoCreate")
                v-divider
                .text-center
                    v-btn(
                        color='info'
                        ripple
                        @click='init();'
                    )
                        v-icon(style="margin-right: 5px;")
                        | Create Another Link

        template(v-else)
            v-form.mt-8(
                v-if="validProps"
            )
                v-alert(dense outlined type='warning') Configure link settings

                v-row
                    v-col(cols='12')
                        v-select(
                            v-model='sharedLink.access'
                            label='Access *'
                            :items='accesses'
                            item-text="key"
                            item-value="value"
                        )

                v-row(v-if="requiresPassword")
                    v-col(cols='12')
                        v-text-field(
                            v-model="sharedLink.password"
                            label='Password *'
                            type='password'
                            counter
                        )

                v-radio-group(
                    v-model='sharedLink.expiration_select'
                    label="Expiration"
                    hint="Disable link at specific date and time"
                    :mandatory='true'
                )
                    v-radio(label='Never' value='never')
                    v-radio(label='24 hours later' value='hour24')
                    v-radio(label='Custom' value='custom')

                v-row(v-if="sharedLink.expiration_select === 'custom'")
                    v-col(cols="6")
                        v-menu(
                            v-model='datePickerMenu'
                            :close-on-content-click='false'
                            transition='scale-transition'
                            offset-y
                        )
                            template(v-slot:activator='{ on, attrs }')
                                v-text-field(
                                    v-model='formatExpirationTimeDate'
                                    label='Date'
                                    prepend-icon='mdi-calendar'
                                    readonly
                                    v-bind='attrs'
                                    v-on='on'
                                )
                            v-date-picker(
                                v-model='sharedLink.expiration_time_date'
                                no-title
                                @input='datePickerMenu = false'
                                :min="minDate"
                            )

                    v-col(cols="6")
                        Timepicker(
                            :on-change="onChangeTimepicker"
                        )
                        
                v-row
                    v-col(cols='12')
                        v-select(
                            v-model='sharedLink.downloads'
                            label='Downloads *'
                            :items='downloads'
                            item-text="key"
                            item-value="value"
                        )

                v-row
                    v-col(cols='12')
                        v-text-field(
                            v-model="sharedLink.name"
                            label='Name'
                            type='text'
                            hint="Assign name to track your link visits by source"
                            persistent-hint
                        )

                v-row
                    v-col(cols='12')
                        .text-right
                            v-btn.ma-2(
                                color='success'
                                ripple
                                @click='createSharedLink();'
                                :disabled="!eligibleToCreate"
                            )
                                v-icon(style="margin-right: 5px;") mdi-share
                                | Share

</template>

<script lang="ts">
    import Vue from 'vue';

    // Components
    import CopyableText from '@/components/shared/copyable_text.vue';
    import Timepicker from "@/components/shared/timepicker.vue";

    // Data
    import { CreateSharedLink } from "@/data";

    export default Vue.extend({
        data() {
            return {
                accesses: [
                    { key: "Anyone with link", value: "anyone" },
                    { key: "People with password", value: "password" },
                ],
                createdSharedLink: null,
                datePickerMenu: false,
                downloads: [
                    { key: "Disabled", value: "disabled" },
                    { key: "Enabled", value: "enabled" },
                ],
                isLoading: false,
                minDate: new Date().toISOString(),
                sharedLink: null,
            }
        },
        methods: {
            createSharedLink() {

                // Init
                const options = {
                    access: this.sharedLink.access,
                    account_id: this.sharedLink.account_id,
                    component: this.sharedLink.component,
                    downloads: this.sharedLink.downloads,
                    expiration_select: this.sharedLink.expiration_select,
                    expiration_time_date: this.sharedLink.expiration_time_date,
                    expiration_time_time: this.sharedLink.expiration_time_time,
                    expiration_time_time_zone: this.sharedLink.expiration_time_time_zone,
                    name: this.sharedLink.name,
                    password: this.sharedLink.password,
                    shareable_id: this.sharedLink.shareable_id,
                    shareable_type: this.sharedLink.shareable_type,
                }

                this.isLoading = true;
                CreateSharedLink(options).then((response) => {
                    if (response?.Data?.shared_link?.id > 0) {
                        this.createdSharedLink = response?.Data?.shared_link;
                    }

                    this.isLoading = false;
                });
            },
            formatDate (date) {
                if (!date) {
                    return null;
                }

                const [year, month, day] = date.split('-');
                return `${month}/${day}/${year}`;
            },
            init() {

                // Init form
                this.sharedLink = {
                    access: "anyone",
                    account_id: this.accountId,
                    component: this.component,
                    downloads: "enabled",
                    expiration_select: "never",
                    expiration_time_date: null,
                    expiration_time_time: null,
                    expiration_time_time_zone: (Intl?.DateTimeFormat()?.resolvedOptions()?.timeZone || "America/New_York"),
                    name: null,
                    password: null,
                    shareable_id: this.shareableId,
                    shareable_type: this.shareableType,
                }

                // Reset success
                this.createdSharedLink = null;
            },
            onChangeTimepicker(time) {
                this.sharedLink.expiration_time_time = time;
            },
        },
        computed: {
            eligibleToCreate() {

                // Password
                if (this.requiresPassword && !this.validPassword) {
                    return false;
                }

                // Expiration
                if (this.sharedLink?.expiration_select === "custom" && !this.validCustomExpiration) {
                    return false;
                }

                return true;
            },
            formatExpirationTimeDate() {
                return this.formatDate(this.sharedLink?.expiration_time_date);
            },
            requiresPassword() {
                return this.sharedLink?.access === "password";
            },
            successCreateSharedLink() {
                return this.createdSharedLink?.id > 0;
            },
            validComponent() {
                return ["conference_show_full", "customer_profile", "email_show", "message_show"].indexOf(this.component) >= 0;
            },
            validCustomExpiration() {
                return this.sharedLink?.expiration_time_date?.length > 0 && this.sharedLink?.expiration_time_time?.length > 0;
            },
            validPassword() {
                return this.sharedLink?.password?.length > 0;
            },
            validProps() {
                return this.validComponent && this.validShareable;
            },
            validShareable() {
                return this.accountId > 0 && this.shareableId > 0 && ["conference", "customer", "email", "message"].indexOf(this.shareableType) >= 0;
            },
        },
        components: {
            CopyableText,
            Timepicker,
        },
        props: {
            accountId: Number,
            autoCreate: Boolean,
            component: String,
            shareableId: Number,
            shareableType: String,
        },
        created() {
            this.init();

            // Auto
            if (this.autoCreate) {
                this.createSharedLink();
            }
        },
        destroyed() {
        },
    });
</script>

<style lang="scss" scoped>
</style>
