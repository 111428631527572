<template lang="pug">
    v-card.mx-lg-auto.mt-md-4.mb-md-4(v-if="validProspectSale" outlined :width="cardWidth" style="margin: 10px 0px !important;")
        v-toolbar(flat)
            v-btn(
                color="primary"
                icon
                ripple
                @click="showDetails = !showDetails;"
            )
                v-icon(v-if="showDetails === true")
                    | mdi-chevron-down
                v-icon(v-else)
                    | mdi-chevron-right

            v-toolbar-title(style ="font-size: 14px;")
                | {{ vehicleYearMakeModel }}
            v-spacer
            .text-caption.text-right.m-l-5
                strong.text-uppercase {{ prospectSale.sale_date }}
        v-divider
        v-card-text(v-if="showDetails === true")
            v-simple-table
                template(v-slot:default)
                    tbody
                        tr
                            td
                                .text-capitalize.text-overline.text--secondary.text--darken-3 Sale Date 
                                .cell-value-spacing(v-if="prospectSale.sale_date && prospectSale.sale_date.length > 0") {{ prospectSale.sale_date }}
                                .cell-value-spacing(v-else) N/a
                        tr
                            td
                                .text-capitalize.text-overline.text--secondary.text--darken-3 Sale Deal Number
                                .cell-value-spacing(v-if="prospectSale.sale_deal_number && prospectSale.sale_deal_number.length > 0") {{ prospectSale.sale_deal_number }}
                                .cell-value-spacing(v-else) N/a
                        tr
                            td
                                .text-capitalize.text-overline.text--secondary.text--darken-3 Deal Type
                                .cell-value-spacing(v-if="prospectSale.sale_deal_type && prospectSale.sale_deal_type.length > 0") {{ prospectSale.sale_deal_type }}
                                .cell-value-spacing(v-else) N/a
                        tr
                            td
                                .text-capitalize.text-overline.text--secondary.text--darken-3 Sale Term
                                .cell-value-spacing(v-if="prospectSale.sale_term && prospectSale.sale_term.length > 0") {{ prospectSale.sale_term }}
                                .cell-value-spacing(v-else) N/a
                        tr
                            td
                                .text-capitalize.text-overline.text--secondary.text--darken-3 Sale Vehicle Type
                                .cell-value-spacing(v-if="prospectSale.sale_vehicle_type && prospectSale.sale_vehicle_type.length > 0") {{ prospectSale.sale_vehicle_type }}
                                .cell-value-spacing(v-else) N/a
                        tr
                            td
                                .text-capitalize.text-overline.text--secondary.text--darken-3 Vehicle Model Year
                                .cell-value-spacing(v-if="prospectSale.sale_vehicle_model_year && prospectSale.sale_vehicle_model_year.length > 0") {{ prospectSale.sale_vehicle_model_year }}
                                .cell-value-spacing(v-else) N/a
                        tr
                            td
                                .text-capitalize.text-overline.text--secondary.text--darken-3 Vehicle Make
                                .cell-value-spacing(v-if="prospectSale.sale_vehicle_make && prospectSale.sale_vehicle_make.length > 0") {{ prospectSale.sale_vehicle_make }}
                                .cell-value-spacing(v-else) N/a
                        tr
                            td
                                .text-capitalize.text-overline.text--secondary.text--darken-3 Vehicle Model
                                .cell-value-spacing(v-if="prospectSale.sale_vehicle_model && prospectSale.sale_vehicle_model.length > 0") {{ prospectSale.sale_vehicle_model }}
                                .cell-value-spacing(v-else) N/a
                        tr
                            td
                                .text-capitalize.text-overline.text--secondary.text--darken-3 Vehicle Odometer
                                .cell-value-spacing(v-if="prospectSale.sale_vehicle_odometer && prospectSale.sale_vehicle_odometer.length > 0") {{ prospectSale.sale_vehicle_odometer }}
                                .cell-value-spacing(v-else) N/a
                        tr
                            td
                                .text-capitalize.text-overline.text--secondary.text--darken-3 Vehicle Trim
                                .cell-value-spacing(v-if="prospectSale.sale_vehicle_trim && prospectSale.sale_vehicle_trim.length > 0") {{ prospectSale.sale_vehicle_trim }}
                                .cell-value-spacing(v-else) N/a
                        tr
                            td
                                .text-capitalize.text-overline.text--secondary.text--darken-3 Vehicle Vin
                                .cell-value-spacing(v-if="prospectSale.sale_vehicle_vin && prospectSale.sale_vehicle_vin.length > 0") {{ prospectSale.sale_vehicle_vin }}
                                .cell-value-spacing(v-else) N/a
                        tr
                            td
                                .text-capitalize.text-overline.text--secondary.text--darken-3 Vehicle Exterior Color
                                .cell-value-spacing(v-if="prospectSale.sale_vehicle_exterior_color && prospectSale.sale_vehicle_exterior_color.length > 0") {{ prospectSale.sale_vehicle_exterior_color }}
                                .cell-value-spacing(v-else) N/a
                        tr
                            td
                                .text-capitalize.text-overline.text--secondary.text--darken-3 Vehicle Stock Number
                                .cell-value-spacing(v-if="prospectSale.sale_vehicle_stock_number && prospectSale.sale_vehicle_stock_number.length > 0") {{ prospectSale.sale_vehicle_stock_number }}
                                .cell-value-spacing(v-else) N/a
                        tr
                            td
                                .text-capitalize.text-overline.text--secondary.text--darken-3 Sale Vehicle Comments
                                .cell-value-spacing(v-if="prospectSale.sale_vehicle_comments && prospectSale.sale_vehicle_comments.length > 0") {{ prospectSale.sale_vehicle_comments }}
                                .cell-value-spacing(v-else) N/a

</template>

<script lang="ts">
    import Vue from 'vue';

    // Helpers
    import { concatVehicleYearMakeModel } from "@/helpers/general";

    // Types
    import { VehicleYearMakeModelObj } from "@/helpers/general";

    export default Vue.extend({
        data() {
            return {
                cardWidth: 300,
				showDetails: false,
            }
        },
        methods: {
            handleDetailsResize() {
                const detailsWidth = document?.getElementById('prospect-details-card')?.getBoundingClientRect()?.width
                if (detailsWidth >= 375) {
                    this.cardWidth = detailsWidth - 75
                } else {
                    this.cardWidth = 300
                }
            },
        },
        computed: {
            validProspectSale() {
                return this.prospectSale?.prospect_sale_id > 0;
            },
            vehicleYearMakeModel() {
                if (!this.validProspectSale) {
                    return null;
                }

                const vehObj = {
                    vehicleModelYear: this.prospectSale.sale_vehicle_model_year,
                    vehicleMake:      this.prospectSale.sale_vehicle_make,
                    vehicleModel:     this.prospectSale.sale_vehicle_model,
                }

                return concatVehicleYearMakeModel(vehObj as VehicleYearMakeModelObj);
            },
        },
        props: {
            prospectSale: Object,
        },
        watch: {
            prospectSale: {
                handler() {
                    Vue.nextTick(() => { this.handleDetailsResize(); });
                },
                deep: true,
            },
        },
        created() {
			window.addEventListener('resize', this.handleDetailsResize);
        },
        destroyed() {
            window.removeEventListener('resize', this.handleDetailsResize);
        },
        mounted() {
            this.handleDetailsResize();
        },
    });
</script>

<style lang="scss" scoped>
    #app {
        .cell-value-spacing {
            padding: 0 0 5px 0;
        }
    }
</style>
