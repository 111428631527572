import { render, staticRenderFns } from "./custom_field_details.vue?vue&type=template&id=3ccee05e&scoped=true&lang=pug"
import script from "./custom_field_details.vue?vue&type=script&lang=ts"
export * from "./custom_field_details.vue?vue&type=script&lang=ts"
import style0 from "./custom_field_details.vue?vue&type=style&index=0&id=3ccee05e&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3ccee05e",
  null
  
)

export default component.exports