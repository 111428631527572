import { post, put, destroy, } from '.';

export function CreateCustomerAddress(options = {}) {
	return post<any>("/app/api/customer_addresses", options);
}

export function UpdateCustomerAddress(id, options = {}) {
	return put<any>("/app/api/customer_addresses/" + (id || 0).toString(), options);
}

export function DestroyCustomerAddress(id, options = {}) {
	return destroy<any>("/app/api/customer_addresses/" + (id || 0).toString(), options);
}

export function UpdateCustomerAddressPrimary(id, options = {}) {
	return put<any>("/app/api/update_customer_address_primary/" + (id || 0).toString(), options);
}
