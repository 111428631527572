<template lang="pug">
    div
        v-container.mx-auto

            //- Call Events
            v-card.mx-lg-auto(width="100%")

                v-toolbar.volie-v-toolbar-color(dark)
                    v-toolbar-title Call Events

                v-card-text(v-if="validCallEvents || isLoadingCallEvents")
                    v-simple-table.table-x-scroll(v-if="validCallEvents")
                        template(v-slot:default)
                            thead
                                tr
                                    th Datetime
                                    th Status
                            tbody
                                tr(v-for='callEvent in callEvents' :key='callEvent.call_event_id')
                                    td.no-wrap.sm-width {{ callEvent.friendly_timestamp ? callEvent.friendly_timestamp : '---' }}
                                    td.no-wrap.sm-width {{ callEvent.call_status | titleize }}

                    .text-center(v-else-if="isLoadingCallEvents")
                        v-progress-circular(
                            :size='100'
                            :width='10'
                            color='#0f5b9a'
                            indeterminate
                            style="margin-top: 75px; margin-bottom: 75px;"
                        )

                v-container.mx-auto(v-if="!validCallEvents && !isLoadingCallEvents")
                    v-alert(outlined type='info' dense border='left' style="margin: 16px;")
                        | There are no call events for this conference


            //- Conference Events
            v-card.mx-lg-auto(width="100%" style="margin-top: 25px;")

                v-toolbar.volie-v-toolbar-color(dark)
                    v-toolbar-title Conference Events

                v-card-text(v-if="validConferenceEvents || isLoadingConferenceEvents")
                    v-simple-table.table-x-scroll(v-if="validConferenceEvents")
                        template(v-slot:default)
                            thead
                                tr
                                    th Datetime
                                    th Call Leg
                                    th Status
                            tbody
                                tr(v-for='conferenceEvent in conferenceEvents' :key='conferenceEvent.conference_event_id')
                                    td.no-wrap.sm-width {{ conferenceEvent.friendly_created_at_time }}
                                    td.no-wrap.sm-width
                                        //- Leg
                                        strong.green--text(v-if="conferenceEvent.call_leg === 'agent'") Agent
                                        strong.info--text(v-else-if="conferenceEvent.call_leg === 'customer'") Customer
                                        strong.primary--text(v-else-if="conferenceEvent.call_leg === 'transfer'") Transfer
                                        strong.primary--text(v-else-if="conferenceEvent.call_leg === 'agent transfer'")
                                            strong.green--text Agent
                                            | {{ ' ' }}
                                            strong.primary--text Transfer
                                        strong(v-else) N/a
                                    td.no-wrap.sm-width {{ conferenceEvent.status_callback_event | titleize }}

                    .text-center(v-else-if="isLoadingConferenceEvents")
                        v-progress-circular(
                            :size='100'
                            :width='10'
                            color='#0f5b9a'
                            indeterminate
                            style="margin-top: 75px; margin-bottom: 75px;"
                        )

                v-container.mx-auto(v-if="!validConferenceEvents && !isLoadingConferenceEvents")
                    v-alert(outlined type='info' dense border='left' style="margin: 16px;")
                        | There are no conference events for this conference

                        

</template>

<script lang="ts">
    import Vue from 'vue';

    export default Vue.extend({
        computed: {
            validCallEvents() {
                return this.callEvents?.length > 0;
            },
            validConferenceEvents() {
                return this.conferenceEvents?.length > 0;
            },
        },
        props: {
            callEvents: Array,
            conferenceEvents: Array,
            isLoadingCallEvents: Boolean,
            isLoadingConferenceEvents: Boolean,
        },
    });
</script>

<style lang="scss" scoped>
</style>