import { get, put, post, destroy } from '.';

export function GetAccountPlugins(options = {}) {
	return get<any>("/app/api/account_plugins", options);
}

export function FindAccountPlugin(id) {
	return get<any>("/app/api/account_plugins/" + (id || 0).toString());
}

export function CreateAccountPlugins(options = {}) {
	return post<any>("/app/api/account_plugins", options);
}

export function UpdateAccountPlugins(id, options = {}) {
	return put<any>("/app/api/account_plugins/" + (id || 0).toString(), options);
}

export function DestroyAccountPlugins(id, options = {}) {
	return destroy<any>("/app/api/account_plugins/" + (id || 0).toString(), options);
}
