export function cleanupEmailAddress(emailAddress: string): string {

	// Trim string
	const cleaned = (emailAddress || "").trim();

	// If we have an empty string, abort
	if (cleaned === "") {
		return "";
	}

	// Validating email address
	const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
	if (pattern.test(cleaned)) {
		return cleaned.toLowerCase();
	}

	return "";
}