<template lang="pug">
    .ma-sm-2(v-if="validOrganizationId")

        .text-center(v-if="isLoading")
            v-progress-circular(
                :size='100'
                :width='10'
                color='#0f5b9a'
                indeterminate
                style="margin-top: 100px;"
            )

        div(v-else)
            v-container
                v-form
                    v-row(align="center")
                        v-col(cols='6')
                            v-text-field(
                                label='First Name'
                                type='text'
                                v-model="firstName"
                                @keydown.enter="search()"
                            )
                        //- v-col(cols='4')
                        //-     v-text-field(label='Middle Name' type='text' v-model="middleName")
                        v-col(cols='6')
                            v-text-field(label='Last Name' type='text' v-model="lastName" @keydown.enter="search()")

                    v-row(align="center")
                        v-col(cols='6')
                            v-select(
                                :items='countries'
                                :item-text="countryDisplay"
                                item-value="iso_two"
                                label='Country'
                                v-model="pnCountryIsoTwo"
                            )
                        v-col(cols='6')
                            v-text-field(
                                label='Phone Number'
                                type='text'
                                v-model="phoneNumber"
                                v-on:keypress="phoneNumberChange"
                                @keydown.enter="search()"
                            )

                    v-row(align="center")
                        v-col(cols='6')
                            v-text-field(label='Email Address' type='text' v-model="emailAddress" @keydown.enter="search()")
                        v-col(cols='6')
                            v-text-field(label='Vehicle VIN' type='text' v-model="vehicleVin" @keydown.enter="search()")

                    v-row(align="center")
                        v-col(cols='12')
                            .text-right
                                v-btn.mr-4(
                                    @click='resetSearch();'
                                )
                                    v-icon mdi-refresh
                                    | Reset
                                v-btn.mr-4(
                                    color='info'
                                    @click='search();'
                                    :disabled="disableSearchButton"
                                )
                                    v-icon mdi-magnify
                                    | Search


            v-alert(
                v-if="renderSearchAlert"
                outlined
                type='info'
                dense
                border='left'
                style="margin-top: 16px;"
            )
                | Search {{ isVehicleSearch ? 'vehicles' : 'customers' }}

            div(
                v-else-if="renderCustomers"
                v-for="(result, idx) in results"
                :key="idx"
            )
                v-card.mx-auto.my-sm-4(
                    outlined
                )
                    template(v-if="isVehicleSearch ? validCustomerVehicleResult(result) : validCustomerResult(result)")
                        v-list(two-line)
                            template(v-if="isVehicleSearch")
                                v-list-item
                                    v-list-item-content
                                        v-list-item-title(v-if='isVehicleSearch' v-text='customerVehicleYearMakeModel(result)' style='font-weight: 500;')
                                        v-list-item-subtitle.text--primary {{ result.customer_matcher.organization_name }}
                                        v-list-item-subtitle.text--primary(v-text='customerName(result)')
                                    v-list-item-action
                                        v-tooltip(bottom)
                                            template(v-slot:activator='{ on, attrs }')
                                                v-btn(
                                                    outlined
                                                    fab
                                                    x-small
                                                    color="success"
                                                    @click="createCustomerVehicle(result.vehicle);"
                                                    v-bind='attrs'
                                                    v-on='on'
                                                )
                                                    v-icon
                                                        | mdi-plus
                                            span Add vehicle to customer's profile

                            template(v-else)
                                v-list-item
                                    v-list-item-content
                                        v-list-item-title {{ result.customer_matcher.organization_name }}
                                        v-list-item-subtitle.text--primary(v-text='customerName(result)')
                                    v-list-item-action(v-if="validEnrollCampaignCustomer")
                                        v-tooltip(bottom)
                                            template(v-slot:activator='{ on, attrs }')
                                                v-btn(
                                                    outlined
                                                    fab
                                                    x-small
                                                    color="success"
                                                    @click="handleEnrollCampaignCustomer(result);"
                                                    v-bind='attrs'
                                                    v-on='on'
                                                    :disabled="isSupervisor"
                                                )
                                                    v-icon
                                                        | mdi-check
                                            span Enroll customer

                                    v-list-item-action(v-if="validViewProfile")
                                        v-tooltip(bottom)
                                            template(v-slot:activator='{ on, attrs }')
                                                v-btn(
                                                    outlined
                                                    fab
                                                    x-small
                                                    color="success"
                                                    @click="viewProfile(result.customer_matcher.customer_id);"
                                                    v-bind='attrs'
                                                    v-on='on'
                                                )
                                                    v-icon
                                                        | mdi-eye
                                            span View profile

                            template(v-if="validCustomerPhoneNumber(result)")
                                v-divider(inset)
                                ListItem(
                                    v-if="result.customer_matcher.cell_phone && result.customer_matcher.cell_phone.length > 0"
                                    default-title="N/a"
                                    icon="mdi-phone"
                                    indent
                                    subtitle="Cell"
                                    :title="result.customer_matcher.cell_phone | tel"
                                )
                                    template(v-slot:title)
                                        CopyablePhoneNumber(:phone-number="{ phone_number_phone_number: result.customer_matcher.cell_phone }")

                                ListItem(
                                    v-if="result.customer_matcher.home_phone && result.customer_matcher.home_phone.length > 0"
                                    default-title="N/a"
                                    :icon="!(result.customer_matcher.cell_phone && result.customer_matcher.cell_phone.length > 0) ? 'mdi-phone' : null"
                                    indent
                                    subtitle="Home"
                                    :title="result.customer_matcher.home_phone | tel"
                                )
                                    template(v-slot:title)
                                        CopyablePhoneNumber(:phone-number="{ phone_number_phone_number: result.customer_matcher.home_phone }")

                                ListItem(
                                    v-if="result.customer_matcher.work_phone && result.customer_matcher.work_phone.length > 0"
                                    default-title="N/a"
                                    :icon="!(result.customer_matcher.cell_phone && result.customer_matcher.cell_phone.length > 0) && !(result.customer_matcher.home_phone && result.customer_matcher.home_phone.length > 0) ? 'mdi-phone' : null"
                                    indent
                                    subtitle="Work"
                                    :title="result.customer_matcher.work_phone | tel"
                                )
                                    template(v-slot:title)
                                        CopyablePhoneNumber(:phone-number="{ phone_number_phone_number: result.customer_matcher.work_phone }")

                            template(v-if="result.customer_matcher.email_address_one && result.customer_matcher.email_address_one.length > 0")
                                v-divider(inset)
                                ListItem(
                                    default-title="N/a"
                                    icon="mdi-email"
                                    indent
                                    subtitle="Email"
                                    :title="result.customer_matcher.email_address_one"
                                )

                            template(v-if="validCustomerAddress(result)")
                                v-divider(inset)
                                ListItem(
                                    default-title="N/a"
                                    icon="mdi-map-marker"
                                    indent
                                    subtitle="Address"
                                    :title="result.customer_matcher.street_one"
                                )
                                    template(v-slot:title)
                                        AddressPostalFormat(
                                            :street-one="result.customer_matcher.street_one"
                                            :street-two="result.customer_matcher.street_two"
                                            :city="result.customer_matcher.city"
                                            :region="result.customer_matcher.region"
                                            :postal-code="result.customer_matcher.postal_code"
                                            :country="result.customer_matcher.country_name"
                                        )

                            template(v-if="validCustomerVehicles(result)")
                                v-divider(inset)
                                ListItem(
                                    v-for="(veh, jdx) in result.vehicles"
                                    :key="jdx"
                                    default-title="N/a"
                                    :icon="jdx === 0 ? 'mdi-car' : null"
                                    indent
                                    subtitle="Vehicle"
                                    :title="vehicleYearMakeModel(veh)"
                                )

                v-divider(
                    v-if='idx < results.length - 1'
                    :key='idx'
                )

            v-alert(
                v-else-if="renderNoResultsAlert"
                outlined
                type='info'
                dense
                border='left'
                style="margin-top: 16px;"
            )
                | Unable to find any results. Please adjust your search criteria.

</template>

<script lang="ts">
    import Vue from 'vue';
    import VolieState  from "@/store";

    import * as Sentry from "@sentry/vue";

    // Components
    import AddressPostalFormat from "@/components/shared/addresses/address_postal_format.vue";
    import CopyablePhoneNumber from '@/components/shared/copyable_phone_number.vue';
    import ListItem from '@/components/shared/list_item.vue';

    // Data
    import { MatchCustomers } from "@/data";

    // Helpers
    import { cleanupPhoneNumber } from "@/helpers/phone_number";
    import { concatVehicleYearMakeModel } from "@/helpers/general";
    import { formatName } from "@/helpers/name";

    // Types
    import { VehicleYearMakeModelObj } from "@/helpers/general";

    export default Vue.extend({
        data() {
            return {
                results: [],
                emailAddress: null,
                firstName: null,
                isLoading: false,
                isSearched: false,
                lastName: null,
                middleName: null,
                phoneNumber: null,
                pnCountryIsoTwo: "US",
                vehicleVin: null,
            }
        },
        methods: {
            buildCustomerVehicleResults(customers) {
                if (!(customers?.length > 0)) {
                    return;
                }

                const results = [];
                for (let i = 0; i < customers.length; i++) {

                    const customerVehicles = customers[i].vehicles;
                    if (customerVehicles?.length > 0) {
                        for (let j = 0; j < customerVehicles.length; j++) {

                            const customerVehicle = customerVehicles[j]
                            if (customerVehicle?.vehicle_make?.length > 0 && customerVehicle?.vehicle_model?.length > 0 && customerVehicle?.vehicle_model_year?.length > 0) {

                                const result = customers[i];
                                result.vehicle = customerVehicle;
                                results.push(result);
                            }
                        }
                    }
                }

                return results;
            },
            countryDisplay(item) {
                return item?.name + ' (' + item?.dialing_code + ")";
            },
            customerName(result) {
                return formatName([result?.customer_matcher?.first_name, result?.customer_matcher?.middle_name, result?.customer_matcher?.last_name]);
            },
            customerVehicleYearMakeModel(result) {

                const vehObj = {
                    vehicleModelYear: result?.vehicle?.vehicle_model_year,
                    vehicleMake:      result?.vehicle?.vehicle_make,
                    vehicleModel:     result?.vehicle?.vehicle_model,
                }

                return concatVehicleYearMakeModel(vehObj as VehicleYearMakeModelObj);
            },
            handleEnrollCampaignCustomer(result) {

                const params = {
                    campaign_customer_id: result.campaign_customer_id,
                    next_attemptable_id: null,
                    next_attemptable_type: null,
                    next_attemptable_value: null,
                    noticeable_id: null,
                    noticeable_type: null,
                    vehicle_id: null,
                }

                this.enrollCampaignCustomer(result.customer_matcher.customer_id, params);
            },
            init() {

                // Init
                let autoSearch = false;

                // Initial: phone number
                if (this.initialPhoneNumber?.length > 0) {

                    // Validate
                    const cleaned = cleanupPhoneNumber(this.initialPhoneNumber, "");
                    if (cleaned?.valid === true) {

                        // Set
                        autoSearch = true;
                        this.phoneNumber = cleaned.phoneNumber.replace(cleaned.dialingCode, "");
                        this.pnCountryIsoTwo = cleaned.countryIso;
                    }
                }

                return autoSearch ? this.search() : null;
            },
            phoneNumberChange(e) {

                // Ensure only numbers are entered
                if (isNaN(e.key)) {
                    e.preventDefault();
                }
            },
            resetSearch() {
                this.results = [];
                this.emailAddress = null;
                this.firstName = null;
                this.lastName = null;
                this.middleName = null;
                this.phoneNumber = null;
                this.pnCountryIsoTwo = "US";
                this.vehicleVin = null;
                this.isSearched = false;
            },
            search() {

                // Init
                const options = {
                    organization_id: this.organizationId,
                    cell_phone: this.phoneNumber,
                    email_address: this.emailAddress,
                    first_name: this.firstName,
                    last_name: this.lastName,
                    limit: 10,
                    middle_name: this.middleName,
                    vehicle_vin: this.vehicleVin,
                }

                this.isLoading = true;
                MatchCustomers(options).then((response) => {
                    if (response?.Data?.customers?.length > 0) {
                        if (this.isVehicleSearch) {
                            this.results = this.buildCustomerVehicleResults(response.Data.customers);
                        } else {
                            this.results = response.Data.customers;
                        }
                    } else {
                        this.results = [];
                    }
                    this.isLoading = false;
                    this.isSearched = true;
                }).catch((err) => {
                    Sentry.captureException(err)
                });
            },
            validCustomerAddress(result) {
                return result?.customer_matcher?.street_one?.length > 0 || result?.customer_matcher?.city?.length > 0 || result?.customer_matcher?.region?.length > 0 || result?.customer_matcher?.postal_code?.length > 0;
            },
            validCustomerResult(result) {
                return result?.customer_matcher?.customer_id > 0;
            },
            validCustomerPhoneNumber(result) {
                return result?.customer_matcher?.cell_phone?.length > 0 || result?.customer_matcher?.home_phone?.length > 0 || result?.customer_matcher?.work_phone?.length > 0;
            },
            validCustomerVehicleResult(result) {
                return this.validCustomerResult(result) && result?.vehicle?.vehicle_id > 0 && result?.vehicle?.vehicle_make?.length > 0 && result?.vehicle?.vehicle_model?.length > 0 && result?.vehicle?.vehicle_model_year?.length > 0;
            },
            validCustomerVehicles(result) {
                return result?.vehicles?.length > 0;
            },
            vehicleYearMakeModel(vehicle) {
                if (!(vehicle?.customer_vehicle_id > 0)) {
                    return null;
                }

                const vehObj = {
                    vehicleModelYear: vehicle.vehicle_model_year,
                    vehicleMake:      vehicle.vehicle_make,
                    vehicleModel:     vehicle.vehicle_model,
                }

                return concatVehicleYearMakeModel(vehObj as VehicleYearMakeModelObj);
            },
        },
        computed: {
            countries() {
                return VolieState.countries();
            },
            disableSearchButton() {

                // First name
                if (this.firstName?.length > 0) {
                    return false;
                }

                // Middle name
                if (this.middleName?.length > 0) {
                    return false;
                }

                // Last name
                if (this.lastName?.length > 0) {
                    return false;
                }

                // Vehicle vin
                if (this.vehicleVin?.length === 17) {
                    return false;
                }

                // Email address
                if (this.emailAddress?.length > 0) {
                    return false;
                }

                // Phone number
                if (this.phoneNumber?.length > 0) {
                    return false;
                }

                return true;
            },
            renderCustomers() {
                return !this.isLoading && this.isSearched && this.validResults;
            },
            renderNoResultsAlert() {
                return !this.isLoading && this.isSearched && !this.validResults;
            },
            renderSearchAlert() {
                return !this.isLoading && !this.isSearched;
            },
            validEnrollCampaignCustomer() {
                return this.enrollCampaignCustomer !== undefined && this.enrollCampaignCustomer !== null;
            },
            validResults() {
                return this.results?.length > 0;
            },
            validOrganizationId() {
                return (this.organizationId > 0);
            },
            validViewProfile() {
                return this.viewProfile !== undefined && this.viewProfile !== null;
            },
        },
        components: {
            AddressPostalFormat,
            CopyablePhoneNumber,
            ListItem,
        },
        props: {
            createCustomerVehicle: Function,
            enrollCampaignCustomer: Function,
            initialPhoneNumber: String,
            isSupervisor: Boolean,
            isVehicleSearch: Boolean,
            organizationId: Number,
            viewProfile: Function,
        },
        destroyed() {
        },
        created() {
            this.init();
        },
    });
</script>

<style lang="scss" scoped>
</style>
