<template lang="pug">
    .ma-sm-2(v-if="validCustomer")
        .text-center(v-if="isLoading")
            v-progress-circular(
                :size='100'
                :width='10'
                color='#0f5b9a'
                indeterminate
                style="margin-top: 100px;"
            )

        v-container.mx-auto(v-if="!isLoading")
            .text-subtitle-2.subtitle-2-padding.text-center
                | Upcoming Appointments

            AppointmentHistoryDetails(
                v-if="validUpcomingAppointmentHistory"
                v-for='appointment in upcomingAppointments'
                :key='appointment.appointment_id'
                :appointment="appointment"
                icon="mdi-calendar-clock-outline"
            )

            v-alert(outlined type='warning' dense border='left' style="margin-top: 16px;" v-if="!validUpcomingAppointmentHistory")
                | Customer does not have any upcoming appointment

            v-divider.divider-spacing

            .text-subtitle-2.subtitle-2-padding.text-center
                | Past Appointments

            AppointmentHistoryDetails(
                v-if="validPastAppointmentHistory"
                v-for='appointment in pastAppointments'
                :key='appointment.appointment_id'
                :appointment="appointment"
                icon="mdi-calendar-check-outline"
            )

            v-alert(outlined type='warning' dense border='left' style="margin-top: 16px;" v-if="!validPastAppointmentHistory")
                | Customer does not have any past appointment

            v-divider.divider-spacing

            .text-subtitle-2.subtitle-2-padding.text-center
                | Canceled Appointments

            AppointmentHistoryDetails(
                v-if="validCanceledAppointmentHistory"
                v-for='appointment in canceledAppointments'
                :key='appointment.appointment_id'
                :appointment="appointment"
                icon="mdi-calendar-remove-outline"
            )

            v-alert(outlined type='warning' dense border='left' style="margin-top: 16px;" v-if="!validCanceledAppointmentHistory")
                | Customer does not have any canceled appointment

</template>

<script lang="ts">
    import Vue from 'vue';

    // Components
    import AppointmentHistoryDetails from '@/components/appointments/appointment_history_details.vue';

    // Data
    import { GetAppointmentHistory } from "@/data";

    export default Vue.extend({
        data() {
            return {
                canceledAppointments: [],
                isLoading: false,
                pastAppointments: [],
                upcomingAppointments: [],
            }
        },
        methods: {
            getAppointmentHistory() {
                if (!(this.currentCampaignCustomer?.customer_id > 0)) {
                    return;
                }

                const options = {
                    customer_id: this.currentCampaignCustomer.customer_id,
                }

                this.isLoading = true;
                GetAppointmentHistory(options).then((response) => {
                    if (response?.Data?.canceled_appointments?.length > 0 || response?.Data?.past_appointments?.length > 0 || response?.Data?.upcoming_appointments?.length > 0) {
                        this.canceledAppointments = response.Data.canceled_appointments;
                        this.pastAppointments = response.Data.past_appointments;
                        this.upcomingAppointments = response.Data.upcoming_appointments;
                    }
                    this.isLoading = false;
                });
            },
        },
        computed: {
            validCustomer() {
                return this.currentCampaignCustomer?.account_id > 0 && this.currentCampaignCustomer?.customer_id > 0 && this.currentCampaignCustomer?.organization_id > 0;
            },
            validCanceledAppointmentHistory() {
                return this.canceledAppointments?.length > 0;
            },
            validPastAppointmentHistory() {
                return this.pastAppointments?.length > 0;
            },
            validUpcomingAppointmentHistory() {
                return this.upcomingAppointments?.length > 0;
            },
        },
        components: {
            AppointmentHistoryDetails,
        },
        props: {
            currentCampaignCustomer: Object,
        },
        created() {
            this.getAppointmentHistory();
        },
    });
</script>

<style lang="scss" scoped>
</style>
