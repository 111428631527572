<template lang="pug">

    div.width-100-percent(style="height: 150px;")

        v-divider.mx-4.mb-2.mt-1

        v-text-field.mx-4(v-model='search' dense prepend-inner-icon='mdi-magnify' label='Search Emoji' hide-details outlined filled clearable background-color="white")
    
        div.my-2.text-left(v-if="validFilteredEmojis" style="height: 100px; overflow-y: scroll;")
            v-row.mx-2.mb-2.mt-0.max-width-100-percent(v-for="(emojiGroup, category) in filteredEmojis" :key="category")
                v-col.py-0.px-2(cols='12')
                    v-list-item-title(style="font-size: 14px;") {{ category }}
                v-col.pa-0(cols='12')
                    v-btn.pa-0(
                        v-for="(emoji, emojiName) in emojiGroup"
                        :key="emojiName"
                        :title="emojiName"
                        text
                        rounded
                        style="font-size: 24px; min-width: 36px; width: 36px; height: 36px; cursor: pointer;"
                        @click="selectEmoji(emoji);"
                    )
                        | {{ emoji }}
    
        v-row.mx-5.my-2.max-width-100-percent(v-else)
            v-col.pa-0.text-center(cols='12')
                v-alert(outlined type='info' dense border='left' style="margin-top: 16px;")
                    | Unable to find any emojis. Please adjust your search.
    
    
    //- ------------  MENU VERSION  ----------------
    //- --------------------------------------------
    //- v-menu(
    //-     v-model='menu'
    //-     top
    //-     left
    //-     :close-on-content-click="false"
    //-     max-width="372px"
    //-     max-height="400px"
    //-     min-width="372px"
    //-     min-height="400px"
    //- )

    //-     template(v-slot:activator="{ on }")
    //-         v-btn(icon v-on="on" style="margin: 0px; margin-top: -5px; padding: 0px;")
    //-             v-icon mdi-emoticon-outline
    //-     v-card.mx-lg-auto(width="100%")
            
    //-         //- use footer to match softphone footer input style
    //-         v-footer.font-weight-medium.pa-4
    //-             v-text-field(v-model='search' dense prepend-inner-icon='mdi-magnify' label='Search' hide-details outlined filled clearable)
            
    //-             template(v-if="validFilteredEmojis")
    //-                 v-row.mx-2.my-2.max-width-100-percent(v-for="(emojiGroup, category) in filteredEmojis" :key="category")
    //-                     v-col.pa-0(cols='12')
    //-                         v-list-item-title {{ category }}
    //-                     v-col.pa-0(cols='12')
    //-                         v-btn(
    //-                             text
    //-                             rounded
    //-                             v-for="(emoji, emojiName) in emojiGroup"
    //-                             :key="emojiName"
    //-                             @click="insert(emoji)"
    //-                             :title="emojiName"
    //-                             style="font-size: 24px; padding: 0px; min-width: 36px; height: 36px; cursor: pointer;"
    //-                         ) {{ emoji }}
            
    //-             v-row.mx-2.my-2.max-width-100-percent(v-else)
    //-                 v-col.pa-0.text-center(cols='12')
    //-                     v-alert(outlined type='info' dense border='left' style="margin-top: 16px;")
    //-                         | Unable to find any emojis. Please adjust your search criteria.
    //- --------------------------------------------
    //- ------------  MENU VERSION  ----------------

</template>

<script lang="ts">
    import Vue from 'vue';

    // Helpers
    import { GetEmojis } from "@/helpers/emoji";

    export default Vue.extend({
        data() {
            return {
                // menu: false,
                search: '',
                emojis: {},
            }
        },
        computed: {
            filteredEmojis() {
                if (!(this.search?.length > 0)) {
                    return this.emojis || {};
                }

                const results = {};
                Object.keys(this.emojis).forEach((category:string) => {
                    const emojiGroup:object = this.emojis[category];

                    const groupResults = {};
                    Object.keys(emojiGroup).forEach((emojiName:string) => {
                        const emoji:string = emojiGroup[emojiName];

                        // If emoji name has substring
                        if (emojiName.includes(this.search)) {
                            groupResults[emojiName] = emoji;
                        }
                    });

                    // Add group category if 1 or more emojis match search
                    if (Object.keys(groupResults).length > 0) {
                        results[category] = groupResults;
                    }
                });

                return results;
            },
            validFilteredEmojis() {
                return Object.keys(this.filteredEmojis)?.length > 0;
            },
        },
        components: {
        },
        props: {
            selectEmoji: Function,
        },
        created() {
            this.emojis = GetEmojis();
        },
    });
</script>

<style lang="scss" scoped>
</style>
