import { get, destroy, post, } from '.';

export function GetDataImportTemplates(options = {}) {
	return get<any>("/app/api/data_import_templates", options);
}

export function CopyDataImportTemplate(id, options = {}) {
    return post<any>("/app/api/data_import_templates/" + (id || 0).toString() + "/copy", options);
}

export function DestroyDataImportTemplate(id, options = {}) {
    return destroy<any>("/app/api/data_import_templates/" + (id || 0).toString(), options);
}