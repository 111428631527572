<template lang="pug">
    .ma-sm-2()

        v-snackbar(
            v-model='snackbar.show'
            :color='snackbar.color'
            :timeout="snackbar.timeout"
            top
        )
            | {{ snackbar.text }}
            v-btn(dark text @click='snackbar.show = false')
                | Close

        .text-center(v-if="isLoading")
            v-progress-circular(
                :size='100'
                :width='10'
                color='#0f5b9a'
                indeterminate
                style="margin-top: 100px;"
            )

        v-container(v-if="!isLoading")
            v-row.text-center
                v-col(cols='12' v-if="isTekionError")
                    v-alert(
                        outlined
                        color="error"
                    )
                        | An error has occured please disposition the appointment creation in Volie and create the appointment in Tekion.

                v-col(cols='12')
                    v-btn.ma-2(v-if="isDetailsState"
                        color="primary"
                        outlined
                        @click="displaySlotsView()"

                    )
                        | Create New Appointment
                    Details(
                        v-if="isDetailsState"
                        :convert-appointment-date-time-from-milliseconds="convertAppointmentDateTimeFromMilliseconds"
                        :current-campaign-customer="currentCampaignCustomer"
                        :display-details-view="displayDetailsView"
                        :display-reschedule-view="displayRescheduleView"
                        :get-tekion-appointments="getTekionAppointments"
                        :handle-cancel-tekion-appointment="handleCancelTekionAppointment"
                        :set-scoped-appointment="setScopedAppointment"
                        :set-tekion-appointment-details="setTekionAppointmentDetails"
                        :show-snackbar="showSnackbar"
                        :tekion-appointments="tekionAppointments"
                        :set-tekion-error="setTekionError"
                        :is-loading="isLoading"
                    )

                v-col(cols='12' v-if="isSlotsState")
                    Slots(
                        :convert-appointment-date-time-from-milliseconds="convertAppointmentDateTimeFromMilliseconds"
                        :current-campaign-customer="currentCampaignCustomer"
                        :display-create-view="displayCreateView"
                        :display-details-view="displayDetailsView"
                        :display-reschedule-view="displayRescheduleView"
                        :handle-slot-selected="handleSlotSelected"
                        :is-tekion-enabled="isTekionEnabled"
                        :scoped-tekion-appointment="scopedAppointment"
                        :show-snackbar="showSnackbar"
                        :set-tekion-error="setTekionError"
                    )

                v-col(cols='12' v-if="isCreateState")
                    Create(
                        :add-new-appointment="addNewAppointment"
                        :convert-appointment-date-time-from-milliseconds="convertAppointmentDateTimeFromMilliseconds"
                        :current-campaign-customer="currentCampaignCustomer"
                        :customer-vehicles="customerVehicles"
                        :get-customer-vehicles="getCustomerVehicles"
                        :display-details-view="displayDetailsView"
                        :display-slots-view="displaySlotsView"
                        :scoped-slot="scopedSlot"
                        :set-appointment-id-for-tekion="setAppointmentIdForTekion"
                        :set-tekion-appointment-details="setTekionAppointmentDetails"
                        :show-snackbar="showSnackbar"
                        :slot-form-object="slotFormObject"
                        :set-tekion-error="setTekionError"
                    )

                v-col(cols='12' v-if="isRescheduleState")
                    Reschedule(
                        :convert-appointment-date-time-from-milliseconds="convertAppointmentDateTimeFromMilliseconds"
                        :current-campaign-customer="currentCampaignCustomer"
                        :customer-vehicles="customerVehicles"
                        :get-customer-vehicles="getCustomerVehicles"
                        :get-tekion-appointments="getTekionAppointments"
                        :scoped-slot="scopedSlot"
                        :show-snackbar="showSnackbar"
                        :scoped-appointment="scopedAppointment"
                        :set-tekion-appointment-details="setTekionAppointmentDetails"
                        :set-appointment-id-for-tekion="setAppointmentIdForTekion"
                        :slot-form-object="slotFormObject"
                        :display-slots-view="displaySlotsView"
                        :display-details-view="displayDetailsView"
                        :handle-update-tekion-appointments="handleUpdateTekionAppointments"
                        :set-tekion-error="setTekionError"
                    )




</template>

<script lang="ts">
    import Vue from 'vue';
    import VolieState from '@/store';

    // Components
    import Cancel from '@/components/communication_center/shared/tabs/tekion/cancel.vue';
    import Create from '@/components/communication_center/shared/tabs/tekion/create.vue';
    import Details from '@/components/communication_center/shared/tabs/tekion/details.vue';
    import Slots from '@/components/communication_center/shared/tabs/tekion/slots.vue';
    import Reschedule from '@/components/communication_center/shared/tabs/tekion/reschedule.vue';


    import ExpandableCardListItem from '@/components/shared/expandable_card_list_item.vue';
    import ListItem from '@/components/shared/list_item.vue';

    // Data
    import { resourceColorClass, } from "@/helpers/css_classes";

    import { GetCustomerVehicles, GetTekionAppointments } from "@/data";

    export default Vue.extend({
        data() {
            return {
                appointmentSlots: [],
                currentView: 'details',
                bus: VolieState.websocketClient().eventBus(),
                customerVehicles: [],
                findSlots: false,
                organizationId: this.currentCampaignCustomer.organization_id,
                isLoading: false,
                isTekionError: false,
                customerVehiclesLimit: 25,
                scopedAppointment: null,
                scopedSlot: null,
                slotFormObject: null,
                snackbar: {
                    show: false,
                    timeout: 2500,
                },
                tekionAppointments: [],
            }
        },
        methods: {
            addNewAppointment(tekionAppointment) {

                if (!tekionAppointment) {
                    return;
                }
                this.tekionAppointments.push(tekionAppointment);
                this.scopedSlot = null;
                this.sortTekionAppointments();
            },
            convertAppointmentDateTimeFromMilliseconds(milliseconds: number): string {
                let timeZone = "America/New_York" // default to east coast
                if (typeof(milliseconds) !== 'number') {
                    milliseconds = parseInt(milliseconds);
                }

                if (this.currentCampaignCustomer?.organization_time_zone_location?.length > 0) {
                    try {
                        // Test if timezone is valid
                        Intl.DateTimeFormat('en-US', {
                            timeZone: this.currentCampaignCustomer.organization_time_zone_location
                        });
                        timeZone = this.currentCampaignCustomer.organization_time_zone_location;
                    } catch (e) {
                        console.warn('Invalid timezone, falling back to America/New_York');
                    }
                }

                const date = new Date(milliseconds);
                const options: Intl.DateTimeFormatOptions = {
                    timeZone,
                    year: 'numeric',
                    month: '2-digit',
                    day: '2-digit',
                    hour: '2-digit',
                    minute: '2-digit',
                    second: '2-digit',
                    hour12: true
                };

                const dateTimeFormat = new Intl.DateTimeFormat('en-US', options);
                return dateTimeFormat.format(date);
            },
            displayDetailsView(){
                this.currentView = 'details';
                this.scopedSlot = null;
                this.scopedAppointment = null;
            },
            displaySlotsView(){
                this.currentView = 'slots-selector'
                this.scopedSlot = null;
            },
            displayCreateView(){
                this.currentView = 'create'
            },
            displayRescheduleView(slot){
                this.currentView = 'reschedule'
                this.setScopedSlot(slot);
            },
            handleSlotSelected(slot, formObject, isEdit) {
                this.setScopedSlot(slot);
                this.setSlotFormObject(formObject);

                if (isEdit) {
                    this.displayRescheduleView(slot);
                } else {
                    this.displayCreateView();
                }
            },
            getCustomerVehicles() {

                if (!this.validCurrentCampaignCustomer) {
                    return;
                }

                const now = new Date();

                const options = {
                    account_id:                  this.currentCampaignCustomer.account_id,
                    customer_id:                 this.currentCampaignCustomer.customer_id,
                    limit:                       this.customerVehiclesLimit,
                    organization_id:             this.currentCampaignCustomer.organization_id,
                    primary_customer_vehicle_id: this.currentCampaignCustomer.customer_vehicle_id || 0,
                }


                GetCustomerVehicles(options).then((response) => {
                    if (response?.Data?.customer_vehicles?.length > 0) {
                        // Set on array
                        if (!!this.customerVehicles && this.customerVehicles.length > 0) {
                            this.customerVehicles = this.customerVehicles.concat(response.Data.customer_vehicles);
                        } else {
                            this.customerVehicles = response.Data.customer_vehicles;
                        }
                    }
                });
            },
            getTekionAppointments() {
                if (!this.validCurrentCampaignCustomer || !this.validCampaignCustomerCustomerId) {
                    return;
                }

                const now = new Date();
                const options = {
                    customer_id: this.currentCampaignCustomer.customer_id,
                    start_date: now.toISOString(),
                    mock_response: null,
                };


                // check to see if the url has a query param
                // mockResponse=1
                if (window.location.href.includes('mockResponse=1')) {
                    options.mock_response = true;
                } else {
                    options.mock_response = false;
                }
                this.isLoading = true;
                GetTekionAppointments(options).then((response) => {
                    if (response?.Data?.tekion_appointments?.length > 0) {
                        this.tekionAppointments = response.Data.tekion_appointments;
                        this.sortTekionAppointments();
                    }
                    this.isLoading = false;
                });
            },
            handleUpdateTekionAppointments(updatedAppointment) {
                const index = this.tekionAppointments.findIndex(
                    appointment => appointment.tekion_appointment_id === updatedAppointment.tekion_appointment_id
                );

                if (index !== -1) {
                    this.tekionAppointments[index] = {
                        ...this.tekionAppointments[index], // Keep existing properties
                        ...updatedAppointment // Override with updated properties
                    };
                }
                this.sortTekionAppointments();
            },
            sortTekionAppointments(){
                this.tekionAppointments.sort((a, b) => a.appointment_date_time - b.appointment_date_time);
            },
            handleCancelTekionAppointment(tekionAppointment) {
                const index = this.tekionAppointments.findIndex(
                    appointment => appointment.tekion_appointment_id === tekionAppointment.tekion_appointment_id
                );

                if (index !== -1) {
                    this.tekionAppointments.splice(index, 1);
                }
                this.sortTekionAppointments();
            },
            resetSlotSelection() {
                this.scopedSlot = null;
                this.slotFormObject = null;
                this.findSlots = true;
            },
            setAppointmentSlots(slots) {
                if (slots === null) {
                    return;
                }

                this.appointmentSlots = slots;
            },
            setScopedAppointment(appointment) {
                if (appointment === null) {
                    return;
                }

                this.scopedAppointment = appointment;
            },
            setScopedSlot(slot) {
                if (slot === null) {
                    this.resetSlotSelection();
                    return;
                }

                this.scopedSlot = slot;
                this.findSlots = false;
            },
            setSlotFormObject(formObject) {
                if (formObject === null) {
                    return;
                }

                this.slotFormObject = formObject;
            },
            setTekionError(status) {
                if (status === null) {
                    return;
                }
                if (status >= 400) {
                    this.isTekionError = true;
                } else {
                    this.isTekionError = false;
                }
            },
            showSnackbar(color, text) {
                if (color?.length > 0 && text?.length > 0) {
                    this.snackbar.color = color;
                    this.snackbar.text = text;
                    this.snackbar.show = true;
                }
            },
        },
        computed: {
            isSlotsState(){
                return this.currentView === 'slots-selector' && this.scopedSlot === null;
            },
            isCreateState(){
                return this.currentView === 'create' && this.scopedSlot !== null;
            },
            isDetailsState(){
                return this.currentView === 'details' && this.scopedSlot === null;
            },
            isRescheduleState(){
                return this.currentView === 'reschedule'&& this.scopedAppointment !== null;
            },
            currentUser() {
                return VolieState.currentUser();
            },
            hasValidAppointment() {
                return this.tekionAppointments?.length > 0 && !!this.tekionAppointments[0]?.tekion_appointment_id;
            },
            itemColor() {
                return resourceColorClass("Service");
            },
            isTekionEnabled() {
                // need to check campaign customer
                return true
            },
            validCurrentCampaignCustomer() {
                return this.currentCampaignCustomer?.customer_id > 0
            },
            validCampaignCustomerCustomerId() {
                return this.currentCampaignCustomer?.customer_id > 0;
            }
        },
        components: {
            Cancel,
            Create,
            Details,
            Slots,
            Reschedule,
            ExpandableCardListItem,
            ListItem,
        },
        watch: {
        },
        props: {
            currentCampaignCustomer: Object,
            inCommunicationCenter: Boolean,
            setTekionAppointmentDetails: Function,
            setAppointmentIdForTekion: Function,
        },
        created() {
            this.getCustomerVehicles();
            this.getTekionAppointments();
        },
        destroyed() {
        }
    });
</script>

<style lang="scss" scoped>
</style>
