import { post, put, get, destroy } from '.';

export function GetOrganizationMappings(options = {}) {
	return get<any>("/app/api/organization_mappings", options);
}

export function GetOrganizationMappingCodes(options = {}) {
	return get<any>("/app/api/organization_mapping_codes", options);
}

export function GetOrganizationMappingPostalCodes(options = {}) {
	return get<any>("/app/api/organization_mapping_postal_codes", options);
}

export function FindOrganizationMapping(id) {
	return get<any>("/app/api/organization_mappings/" + (id || 0).toString());
}

export function CreateOrganizationMapping(options = {}) {
	return post<any>("/app/api/organization_mappings", options);
}

export function UpdateOrganizationMapping(id, options = {}) {
	return put<any>("/app/api/organization_mappings/" + (id || 0).toString(), options);
}

export function DestroyOrganizationMapping(id) {
	return destroy<any>("/app/api/organization_mappings/" + (id || 0).toString());
}
