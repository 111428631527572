<template lang="pug">
    div(v-if="currentUser")

        v-alert(dense outlined type='error' v-if="!validCustomer") In order to cancel an appointment, a valid customer must be present.
        
        v-alert(
            v-else-if="!validUpcomingAppointments"
            border="left"
            dense
            type="warning"
        )
            | No upcoming appointments

        v-card.mx-auto.mb-sm-4(
            v-else
            v-for="(appt, idx) in upcomingAppointments"
            :key="idx"
            outlined
        )
            template
                v-list(two-line)
                    template
                        v-list-item
                            v-list-item-content
                                v-list-item-title {{ appt.organization_name }}
                                v-list-item-subtitle.text--primary(v-text='appt.friendly_appointment_datetime')
                                v-list-item-subtitle.text--primary(
                                    v-if="appt.appointment_type_id > 0"
                                    v-text='appt.appointment_type_name'
                                )
                                v-list-item-subtitle.text--primary(
                                    v-if="validVehicle(appt)"
                                    v-text='appt.vehicle_year_make_model'
                                )
                            v-list-item-action
                                v-btn(
                                    :outlined="!isSelected(appt)"
                                    fab
                                    x-small
                                    color="green"
                                    @click="selectAppointment(appt);"
                                )
                                    v-icon(:color="iconColor(appt)")
                                        | mdi-check


</template>

<script lang="ts">
    import Vue from 'vue';
    import VolieState  from "@/store";

    export default Vue.extend({
        data() {
            return {
                selectedUpcomingAppointment: null,
            }
        },
        methods: {
            iconColor(appt) {
                return this.isSelected(appt) ? "white" : "success";
            },
            isSelected(appt) {
                return this.selectedUpcomingAppointment?.appointment_id > 0 && this.selectedUpcomingAppointment?.appointment_id === appt?.appointment_id;
            },
            selectAppointment(appt) {
                if (!(appt?.appointment_id > 0)) {
                    return;
                }

                // Set selected
                this.selectedUpcomingAppointment = appt;

                // Bubble up to disposition component
                this.onAppointmentChange({ appointment_id: this.selectedUpcomingAppointment.appointment_id });
            },
            validVehicle(appt) {
                return appt?.customer_vehicle_key?.length > 0 && appt?.vehicle_year_make_model?.length > 0;
            },
        },
        computed: {
            currentUser() {
                return VolieState.currentUser();
            },
            validUpcomingAppointments() {
                return this.upcomingAppointments?.length > 0;
            },
          
        },
        watch: {
            tekionAppointmentDetails: {
                handler(newValue) {
                    if (this.tekionAppointmentDetails?.appointment_id > 0) {
                        const appointment = this.upcomingAppointments.find(
                            appt => appt.appointment_id === newValue.appointment_id
                        );

                        this.selectAppointment(appointment)
                    } else if (this.tekionAppointmentDetails?.appointment_id === 0) {
                        this.selectedUpcomingAppointment = null;
                    }
                }
            }
        },
        components: {
        },
        props: {
            onAppointmentChange: Function,
            upcomingAppointments: Array,
            validCustomer: Boolean,
            tekionAppointmentDetails: Object,
        },
        created() {
        },
    });
</script>

<style lang="scss" scoped>
</style> 
