import { get, post, put, destroy } from '.';

export function GetScorecardCriterions(scorecardTemplateId, options = {}) {
	return get<any>("/app/api/scorecard_templates/" + (scorecardTemplateId || 0).toString() + "/scorecard_criterions", options);
}

export function CreateScorecardCriterion(scorecardTemplateId, options = {}) {
	return post<any>("/app/api/scorecard_templates/" + (scorecardTemplateId || 0).toString() + "/scorecard_criterions", options);
}

export function UpdateScorecardCriterion(scorecardTemplateId, id, options = {}) {
	return put<any>("/app/api/scorecard_templates/" + (scorecardTemplateId || 0).toString() + "/scorecard_criterions/" + (id || 0).toString(), options);
}

export function DestroyScorecardCriterion(scorecardTemplateId, id, options = {}) {
	return destroy<any>("/app/api/scorecard_templates/" + (scorecardTemplateId || 0).toString() + "/scorecard_criterions/" + (id || 0).toString(), options);
}
