import { get, post, Response } from '.';

export function GetSharedLinks(options = {}): Promise<Response<any>> {
  return get<any>('/app/api/shared_links', options);
}

export function GetSharedLink_Me(): Promise<Response<any>> {
  return get<any>('/app/api/shared_links/me', {});
}

export function CreateSharedLink(options = {}): Promise<Response<any>> {
  return post<any>('/app/api/shared_links', options);
}

export function ValidateSharedLinkPassword(id, options = {}): Promise<Response<any>> {
  return get<any>('/app/api/shared_links/' + (id || 0).toString() + "/validate_password", options);
}
