<template lang="pug">      
    div 
        v-divider(inset)
        template(v-if="validCustomerEmailAddresses")
            v-list-item(
                v-for='(customerEmailAddress, idx) in customerEmailAddresses'
                :key='customerEmailAddress.customer_email_address_id'
            )
                v-list-item-icon
                    v-icon(color='primary' v-if="idx === 0")
                        | mdi-email
                v-list-item-content
                    v-list-item-title
                        CopyableText(:text="customerEmailAddress.email_address_email")
                    v-list-item-subtitle
                        v-btn(v-if="customerEmailAddress.email_address_id !== primaryCustomerEmailAddressId" text small color="green" @click="updateCustomerEmailAddressPrimary(customerEmailAddress.customer_email_address_id)" class="primary-btn") Set Primary
                        v-btn(v-if="customerEmailAddress.email_address_id === primaryCustomerEmailAddressId" text small disabled class="primary-btn") Primary
                template(v-if="!hideCreateUpdateDestroyButtons")
                    v-list-item-action
                        template
                            v-btn(
                                v-if="emailAllowed(customerEmailAddress)"
                                icon
                                light
                                @click="setEmailCustomerEmailAddress(customerEmailAddress);"
                                :disabled="allowedEmailDisabled(customerEmailAddress)"
                            )
                                v-icon mdi-email
                            v-btn(v-else icon light disabled)
                                v-icon mdi-email-off
                    v-list-item-action
                        CustomerEmailAddressFormDialog(
                            :customer-email-address="customerEmailAddress"
                            :update-customer-email-address="updateCustomerEmailAddress"
                        )
                    v-list-item-action(style="margin-left: 0px;")
                        CustomerEmailAddressDestroyDialog(
                            :customer-email-address-id="customerEmailAddress.customer_email_address_id"
                            :email-address="customerEmailAddress.email_address_email"
                            :destroy-customer-email-address="destroyCustomerEmailAddress"
                        )
            
            v-list-item(v-if="!hideCreateUpdateDestroyButtons")
                v-list-item-content
                    v-list-item-title.text-center
                        CustomerEmailAddressFormDialog(
                            :create-customer-email-address="createCustomerEmailAddress"
                        )

        template(v-else)
            v-list-item
                v-list-item-icon
                    v-icon(color='primary')
                        | mdi-email
                v-list-item-content
                    v-list-item-title No email addresses on profile
            
            v-list-item(v-if="!hideCreateUpdateDestroyButtons")
                v-list-item-content
                    v-list-item-title.text-center
                        CustomerEmailAddressFormDialog(
                            :create-customer-email-address="createCustomerEmailAddress"
                        )

</template>

<script lang="ts">
import Vue from "vue";

// Components
import CopyableText from '@/components/shared/copyable_text.vue';
import CustomerEmailAddressDestroyDialog from "@/components/customer_email_addresses/destroy_dialog.vue";
import CustomerEmailAddressFormDialog from "@/components/customer_email_addresses/form_dialog.vue";

export default Vue.extend({
    data() {
        return {};
    },
    methods: {
        allowedEmailDisabled(cea) {

            // Verify
            if (!cea || !(cea.customer_email_address_id > 0)) {
                return true;
            }

            // If no valid cc, then just viewing plain customer profile and so this disable is not necessary
            if (!this.validCurrentCampaignCustomer) {
                return false;
            }

            // We only care about disabling if purpose is return email and we are viewing the same email client
            if (this.currentCampaignCustomer?.purpose !== "return_email" || !(this.currentCampaignCustomer?.next_attemptable_id > 0 && this.currentCampaignCustomer?.next_attemptable_type === "customer_email_address")) {
                return false;
            }

            return this.currentCampaignCustomer.next_attemptable_id === cea.customer_email_address_id;
        },
        emailAllowed(cea) {

            // Verify
            if (!cea || !(cea.customer_email_address_id > 0)) {
                return false;
            }

            return (cea.do_not_email === false && cea.email_address_do_not_email === false && cea.invalid === false && cea.email_address_invalid === false);
        },
    },
    computed: {
        validCustomerEmailAddresses() {
            return this.customerEmailAddresses?.length > 0;
        }
    },
    components: {
        CopyableText,
        CustomerEmailAddressDestroyDialog,
        CustomerEmailAddressFormDialog
    },
    props: {
        customerEmailAddresses: Array,
        primaryCustomerEmailAddressId: Number,
        createCustomerEmailAddress:  Function,
        destroyCustomerEmailAddress: Function,
        hideCreateUpdateDestroyButtons: Boolean,
        setEmailCustomerEmailAddress: Function,
        updateCustomerEmailAddress:  Function,
        updateCustomerEmailAddressPrimary: Function
    }
});
</script>

<style lang="scss" scoped>
    .theme--light.v-btn.v-btn--disabled {
        color: #1867c0 !important;
    }
    .v-data-table > .v-data-table__wrapper > table > tbody > tr > td , .v-data-table > .v-data-table__wrapper > table > tbody > tr > th, .v-data-table > .v-data-table__wrapper > table > thead > tr > td, .v-data-table > .v-data-table__wrapper > table > thead > tr > th, .v-data-table > .v-data-table__wrapper > table > tfoot > tr > td, .v-data-table > .v-data-table__wrapper > table > tfoot > tr > th {
        padding: 0px 4px;
    }
    .primary-btn.v-btn:not(.v-btn--round).v-size--small {
        padding: 0px 2px;
    }
</style>
