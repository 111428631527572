<template lang="pug">
    .ma-sm-2
        .text-center(v-if="isLoading")
            v-progress-circular(
                :size='100'
                :width='10'
                color='#0f5b9a'
                indeterminate
                style="margin-top: 100px;"
            )

        v-container.mx-auto(v-if="organization && organization.organization_id > 0")
            
            template
                ListItem(
                    default-title="N/a"
                    icon="mdi-office-building"
                    indent
                    subtitle="Organization"
                    :title="organization.name"
                )
                ListItem(
                    default-title="N/a"
                    indent
                    subtitle="Address"
                    :title="organization.address_street_one"
                )
                    template(v-slot:title)
                        AddressPostalFormat(
                            :street-one="organization.address_street_one"
                            :street-two="organization.address_street_two"
                            :city="organization.address_city"
                            :region="organization.address_region"
                            :postal-code="organization.address_postal_code"
                            :country="organization.address_country_name"
                        )

                ListItem(
                    default-title="N/a"
                    indent
                    subtitle="Main Phone"
                    :title="organization.main_phone_number | tel"
                )
                    template(v-slot:title)
                        template(v-if="organization.main_phone_number && organization.main_phone_number.length > 0")
                            CopyablePhoneNumber(:phone-number="{phone_number_phone_number: organization.main_phone_number}")
                        template(v-else) N/a

            SpecialInstructions(
                v-if="validSpecialInstructions"
                :special-instructions="specialInstructions"
            )

</template>

<script lang="ts">
    import Vue from 'vue';

    // Components
    import AddressPostalFormat from '@/components/shared/addresses/address_postal_format.vue';
    import CopyablePhoneNumber from '@/components/shared/copyable_phone_number.vue';
    import ListItem from '@/components/shared/list_item.vue';
    import SpecialInstructions from '@/components/communication_center/shared/tabs/special_instructions.vue';

    // Data
    import { GetCommunicationCenterOrganization } from "@/data";

    export default Vue.extend({
        data() {
            return {
                isLoading: false,
                organization: null,
                specialInstructions: [],
            }
        },
        methods: {
            getCommunicationCenterOrganization() {

                // Verify
                if (!this.organizationId) {
                    return;
                }

                const options = {
                    campaign_id: null,
                    organization_id: this.organizationId,
                }

                // If present, add to options
                if (this.campaignId > 0) {
                    options.campaign_id = this.campaignId;
                }

                this.isLoading = true;
                GetCommunicationCenterOrganization(options).then((response) => {
                    if (response?.Data?.organization?.organization_id > 0)  {
                        this.organization = response.Data.organization;
                        this.specialInstructions = response.Data.special_instructions;
                    }
                    this.isLoading = false;
                });
            },
        },
        computed: {
            validSpecialInstructions() {
                return this.specialInstructions?.length > 0;
            },
        },
        components: {
            AddressPostalFormat,
            CopyablePhoneNumber,
            ListItem,
            SpecialInstructions,
        },
        props: {
            campaignId: Number,
            organizationId: Number,
        },
        created() {
            this.getCommunicationCenterOrganization();
        },
    });
</script>

<style lang="scss" scoped>
    #app {
        .table-cell-padding {
            padding-top: 10px;
            padding-bottom: 10px;
        }
    }
</style>
