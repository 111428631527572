import { get, put, post, destroy } from '.';

export function GetAccessGroups(options = {}) {
	return get<any>("/app/api/access_groups", options);
}

export function FindAccessGroup(vid) {
	return get<any>("/app/api/access_groups/" + vid);
}

export function CreateAccessGroup(options = {}) {
	return post<any>("/app/api/access_groups", options);
}

export function UpdateAccessGroup(id, options = {}) {
	return put<any>("/app/api/access_groups/" + (id || 0).toString(), options);
}

export function DestroyAccessGroup(id, options = {}) {
	return destroy<any>("/app/api/access_groups/" + (id || 0).toString(), options);
}
