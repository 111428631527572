import { destroy, get, post, put } from '.';

export function CreateAgentStatus(options = {}) {
    return post<any>("/app/api/agent_statuses", options);
}

export function DestroyAgentStatus(id) {
    return destroy<any>("/app/api/agent_statuses/" + (id || 0).toString());
}

export function FindAgentStatus(agentStatusKey, options = {}) {
    return get<any>("/app/api/agent_statuses/" + agentStatusKey, options )
}

export function GetAgentStatuses(options = {}) {
    return get<any>("/app/api/agent_statuses", options);
}

export function UpdateAgentStatus(id, options = {}) {
    return put<any>("/app/api/agent_statuses/" + (id || 0).toString(), options);
}