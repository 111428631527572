import { get } from '.';

export function GetPlugins(options = {}) {
	return get<any>("/app/api/plugins", options);
}

export function GetPluginsForAccount(options = {}) {
	return get<any>("/app/api/plugins_for_account", options);
}

export function GetPluginsForOrganization(options = {}) {
	return get<any>("/app/api/plugins_for_organization", options);
}

export function GetOrganizationsWithoutPlugin(options = {}) {
	return get<any>("/app/api/organizations_without_plugin", options);
}
