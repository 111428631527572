<template lang="pug">
    div 
        v-snackbar(
            v-model='snackbar.show'
            :color='snackbar.color'
            :timeout="snackbar.timeout"
            top
        )
            | {{ snackbar.text }}
            v-btn(dark text @click='snackbar.show = false')
                | Close

        v-card.mx-lg-auto.mt-md-4.mb-md-4(outlined width="100%")
            v-toolbar.volie-v-toolbar-color(dark)
                v-toolbar-title Ticket Tasks
                v-spacer
                TaskDialogForm(
                    :set-changes-on-ticket-task="setChangesOnTicketTask"
                    :show-snackbar="showSnackbar"
                    :ticket="ticket"
                )

            .text-center(v-if="isLoading")
                v-progress-circular(
                    :size='100'
                    :width='10'
                    color='#0f5b9a'
                    indeterminate
                    style="margin-top: 100px; margin-bottom: 100px;"
                )

            TaskCard(
                v-else-if="validTasks"
                v-for="(task, idx) in tasks"
                :key="idx"
                :set-changes-on-ticket-task="setChangesOnTicketTask"
                :show-snackbar="showSnackbar"
                :task="task"
                :ticket="ticket"
            )

            v-alert(v-else outlined type='info' dense border='left' style="margin: 16px;")
                | Unable to find any tasks for this ticket

</template>

<script lang="ts">
    import Vue from 'vue';
    import VolieState  from "@/store";

    // Data
    import { 
        GetInternalTicketTasks,
    } from "@/data";

    // Components
    import TaskCard from '@/components/tickets/partials/task.vue';
    import TaskDialogForm from '@/components/internal_ticket_tasks/dialog_form.vue';

    export default Vue.extend({
        data() {
            return {
                dialog: false,
                isLoading: false,
                snackbar: {
                    show: false,
                    timeout: 2500,
                },
                tasks: [],
                
            }
        },
        methods: {
            getInternalTicketTasks() {
                if (!this.validTicket) {
                    return;
                }

                const options = {
                    ticket_id: this.ticket.ticket_id || 0,
                }

                this.isLoading = true;
                GetInternalTicketTasks(options).then((response) => {

                    if (response?.Data?.internal_ticket_tasks?.length > 0) {
                        this.tasks = response.Data.internal_ticket_tasks;
                    }

                    this.isLoading = false;
                });
            },
            setChangesOnTicketTask() {
                this.getInternalTicketTasks();
            },
            showSnackbar(color, text) {
                if (color?.length > 0 && text?.length > 0) {
                    this.snackbar.color = color;
                    this.snackbar.text = text;
                    this.snackbar.show = true;
                }
            },
        },
        computed: {
            currentUser() {
                return VolieState.currentUser();
            },
            currentUserIsSuperAdmin() {
                return this.currentUser?.activated_role_id === 1;
            },
            validTasks() {
                return this.tasks?.length > 0;
            },
            validTicket() {
                return this.ticket?.ticket_id > 0;
            },
        },
        props: {
            ticket: Object,
        },
        components: {
            TaskCard,
            TaskDialogForm,
        },
        created() {
            this.getInternalTicketTasks();
            return;
        },
    });
</script>

<style lang="scss" scoped>
</style>
