<template lang="pug">

    v-card(
        :color="itemColor"
    )
        v-list-item.px-1(:color="itemColor")
            v-list-item-avatar.mr-0
                v-btn(
                    v-if="expandable"
                    icon
                    ripple
                    @click="onExpand();"
                )
                    v-icon.text-white(v-if="isExpanded")
                        | mdi-chevron-down
                    v-icon.text-white(v-else)
                        | mdi-chevron-right

                v-simple-checkbox(
                    v-else-if="selectable"
                    v-model="isChecked"
                    dark
                    :ripple="false"
                    @click="onClickIsChecked(isChecked)"
                )

            v-list-item-content.pl-0(:style="'flex-grow: ' + leftFlexGrow +';'" :class="[dense ? 'py-2' : 'py-4', `text-${titleAlignment}`]")
                v-list-item-title.text-white(style="font-weight: 500;")
                    slot(name="title")
                        | {{ title }}

                v-list-item-subtitle.text-white(v-if="subtitle")
                    slot(name="subtitle")
                        | {{ subtitle }}

                slot(name="moreSubtitles")

            v-list-item-content.my-0.mr-1(:style="(contentRightTop || contentRightBottom) ? 'flex-grow: '+rightFlexGrow+'; min-width: 85px;' : 'flex-grow: '+rightFlexGrow+';'" :class="dense ? 'py-2' : 'py-4'")
                v-list-item-subtitle.text-white.text-right
                    slot(name="rightTop")
                        | {{ contentRightTop || '' }}

                v-list-item-subtitle.text-white.text-right
                    slot(name="rightBottom")
                        | {{ contentRightBottom || '' }}

            v-list-item-action.mx-1(v-if="rightActionIcon")
                slot(name="rightActionIcon")
                    template(v-if="rightActionClick")
                        v-btn(icon dark @click="rightActionClick()")
                            v-icon(dark) {{ rightActionIcon }}
                    template(v-else)
                        v-icon {{ rightActionIcon }}

        slot(name="body")

</template>

<script lang="ts">
    import Vue from 'vue';
    export default Vue.extend({
        data() {
            return {
                isChecked: false,
            }
        },
        computed: {
            leftFlexGrow() {
                if (this.flexGrowLeft > 1) {
                    return this.flexGrowLeft;
                }

                return 3;
            },
            rightFlexGrow() {
                if (this.flexGrowRight > 1) {
                    return this.flexGrowRight;
                }

                return 1;
            },
            itemColor() {
                return this.color || '';
            },
        },
        props: {
            color: String,
            contentRightTop: String,
            contentRightBottom: String,
            dense: Boolean,
            expandable: Boolean,
            flexGrowLeft: Number,
            flexGrowRight: Number,
            isExpanded: Boolean,
            onClickIsChecked: Function,
            onExpand: Function,
            rightActionClick: Function,
            rightActionIcon: String,
            selectable: Boolean,
            subtitle: String,
            title: String,
            titleAlignment: {
                type: String,
                default: 'left',
                validator: (value: string) => ['left', 'center', 'right'].includes(value)
            }
        },
    });
</script>

<style lang="scss" scoped>
    #app {
        .v-list-item__title {
            font-size: 1.15rem;
        }

        .v-list-item__subtitle {
            font-size: 1rem;
        }
    }
</style>
