import { destroy, get, post, put } from '.';

export function CreateIvrTreeOption(options = {}) {
	return post<any>("/app/api/ivr_tree_options", options);
}

export function UpdateIvrTreeOptions(ivrTreeId: string, options = {}) {
	return put<any>(`/app/api/ivr_tree/${ivrTreeId}/ivr_tree_options`, options);
}

export function DeleteIvrTreeOption(id: string, options = {}) {
	return destroy<any>(`/app/api/ivr_tree_options/${id}`, options);
}

export function BuildIvrTreeWithOptions(id: number) {
	return get<any>("/app/api/ivr_trees/" + (id || 0).toString() + "/builder");
}
