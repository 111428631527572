import { get, post, put, destroy } from '.';

export function CreateDealType(options = {}) {
	return post<any>("/app/api/deal_types", options);
}

export function DestroyDealType(dealTypeVid:string, options = {}) {
	return destroy<any>(`/app/api/deal_types/${dealTypeVid}`, options);
}

export function FindDealType(dealTypeVid:string, options = {}) {
	return get<any>(`/app/api/deal_types/${dealTypeVid}`, options);
}

export function GetDealTypes(options = {}) {
	return get<any>("/app/api/deal_types", options);
}

export function UpdateDealType(dealTypeVid:string, options = {}) {
	return put<any>(`/app/api/deal_types/${dealTypeVid}`, options);
}