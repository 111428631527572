<template lang="pug">
    div.pa-md-5.width-100-percent
        v-card.mx-lg-auto(width="100%" v-if="email && email.email_id > 0")
            v-card-text
                v-row(dense)
                    v-col(cols='4')
                        v-simple-table
                            template(v-slot:default)
                                tbody
                                    tr
                                        td
                                            strong.text-subtitle-1 Details

                                            .pull-right(v-if="renderSharedLinks")
                                                SharedLinksDialog(
                                                    v-if="renderSharedLinks"
                                                    :account-id="email.account_id"
                                                    component="email_show"
                                                    :shareable-id="email.email_id"
                                                    shareable-type="email"
                                                )

                                    tr
                                        td
                                            .text-capitalize.text-overline.text--secondary.text--darken-3 Key
                                            .cell-value-spacing {{ email.email_key }}
                                    tr
                                        td
                                            .text-capitalize.text-overline.text--secondary.text--darken-3 SID
                                            | {{ email.sid }}
                                    tr
                                        td
                                            .text-capitalize.text-overline.text--secondary.text--darken-3 Created At
                                            | {{ email.friendly_created_at }}
                                    tr
                                        td
                                            .text-capitalize.text-overline.text--secondary.text--darken-3 Noticeable
                                            | {{ email.noticeable_type }}
                                    tr
                                        td
                                            .text-capitalize.text-overline.text--secondary.text--darken-3 Organization
                                            | {{ email.organization_name ? email.organization_name : '---' }}
                                    tr
                                        td
                                            .text-capitalize.text-overline.text--secondary.text--darken-3 Campaign
                                            | {{ email.campaign_name ? email.campaign_name : '---' }}
                                    tr
                                        td
                                            .text-capitalize.text-overline.text--secondary.text--darken-3 Customer
                                            | {{ email.customer_full_name ? email.customer_full_name : '---' }}
                                    tr
                                        td
                                            .text-capitalize.text-overline.text--secondary.text--darken-3 Subject
                                            | {{ email.subject && email.subject.length > 0 ? email.subject : '---' }}
                                    tr
                                        td
                                            .text-capitalize.text-overline.text--secondary.text--darken-3 Direction
                                            | {{ email.friendly_direction && email.friendly_direction.length > 0 ? email.friendly_direction : '---' }}
                                    tr
                                        td
                                            .text-capitalize.text-overline.text--secondary.text--darken-3 Email Type
                                            | {{ email.email_type && email.email_type.length > 0 ? email.email_type : '---' }}
                                    tr
                                        td
                                            .text-capitalize.text-overline.text--secondary.text--darken-3 MIME Type
                                            | {{ email.mime_type && email.mime_type.length > 0 ? email.mime_type : '---' }}
                                    tr
                                        td
                                            .text-capitalize.text-overline.text--secondary.text--darken-3 From
                                            | {{ displayRecipient(email.from_name, email.from_address) }}
                                    tr
                                        td
                                            .text-capitalize.text-overline.text--secondary.text--darken-3 Reply To
                                            | {{ displayRecipient(email.reply_to_name, email.reply_to_address) }}
                                    tr
                                        td
                                            .text-capitalize.text-overline.text--secondary.text--darken-3 Error
                                            span(v-if="email.error === true") {{ 'Yes: ' + email.error_message ? email.error_message : '---' }}
                                            span(v-if="email.error === false") {{ 'No' }}
                                    tr
                                        td
                                            .text-capitalize.text-overline.text--secondary.text--darken-3 Last Event
                                            EmailEvent(:name="email.last_email_event_friendly_event")


                    v-col(cols='8')
                        v-tabs(grow show-arrows light v-model="currentTab")
                            v-tab(v-for='(item, i) in tabItems' :key='item.key' :href="item.href")
                                | {{ item.name }}

                        v-tabs-items
                            keep-alive
                                EmailAttachments(
                                    v-if="currentTab === 'attachments-tab'"
                                    :email-attachments="email.email_attachments"
                                )

                                EmailBody(
                                    v-if="currentTab === 'bodies-tab'"
                                    :email-body="email.email_body"
                                )

                                EmailEvents(
                                    v-if="currentTab === 'events-tab'"
                                    :email-events="email.email_events"
                                )

                                EmailRecipients(
                                    v-if="currentTab === 'recipients-tab'"
                                    :email-recipients="email.email_recipients"
                                )

                                ViewingEmailThread(
                                    v-if="currentTab === 'threads-tab'"
                                    :email-thread="email.email_thread"
                                    :highlight-email-id="highlightEmailID"
                                    :is-view-only="true"
                                )

</template>

<script lang="ts">
    import Vue from 'vue';
    import VolieState  from "@/store";

    // Components
    import EmailAttachments from "@/components/email_attachments/index.vue";
    import EmailBody from "@/components/email_bodies/show.vue";
    import EmailEvent from "@/components/emails/email_event.vue";
    import EmailEvents from "@/components/email_events/index.vue";
    import EmailRecipients from "@/components/email_recipients/index.vue";
    import SharedLinksDialog from '@/components/shared_links/dialog.vue';
    import ViewingEmailThread from '@/components/communication_center/email/viewing_email_thread.vue';

    // Data
    import { FindEmail } from "@/data";

    export default Vue.extend({
        data() {
            return {
                currentTab: "bodies-tab",
                email: null,
                snackbar: {
                    show: false,
                    timeout: 2500,
                },
                tabItems: [
                    { name: "Attachments", key: "attachments", href: "#attachments-tab" },
                    { name: "Body", key: "bodies", href: "#bodies-tab" },
                    { name: "Events", key: "events", href: "#events-tab" },
                    { name: "Recipients", key: "recipients", href: "#recipients-tab" },
                ],
            }
        },
        methods: {
            displayRecipient(name, emailAddress) {
                return name?.length > 0 && emailAddress?.length > 0 ? this.name + " <" + emailAddress + ">" : (emailAddress?.length > 0 ? emailAddress : "---");
            },
            findEmail() {
                if (!(this.emailKey?.length > 0)) {
                    return;
                }

                FindEmail(this.emailKey).then((response) => {
                    if (response?.Data?.email?.email_key?.length > 0) {
                        this.email = response.Data.email;

                        // Thead check
                        if (this.email?.email_thread?.email_thread_id > 0) {
                            this.tabItems.push({ name: "Thread", key: "threads", href: "#threads-tab" });
                        }
                    }
                });
            },
            showSnackbar(color, text) {
                if (color?.length > 0 && text?.length > 0) {
                    this.snackbar.color = color;
                    this.snackbar.text = text;
                    this.snackbar.show = true;
                }
            },
        },
        computed: {
            currentUser() {
                return VolieState.currentUser();
            },
            highlightEmailID() {
                return this.email?.email_id;
            },
            renderSharedLinks() {
                return this.currentUser?.account?.shared_links_enabled === true;
            },
        },
        components: {
            EmailAttachments,
            EmailBody,
            EmailEvent,
            EmailEvents,
            EmailRecipients,
            SharedLinksDialog,
            ViewingEmailThread,
        },
        props: {
            emailKey: String,
        },
        created() {
            this.findEmail();
        },
    });
</script>

<style lang="scss" scoped>
</style>