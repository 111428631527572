import { get, destroy, post, put } from '.';

export function GetCampaignIncomingPhoneNumbers(options = {}) {
	return get<any>("/app/api/campaign_incoming_phone_numbers", options);
}

export function DestroyCampaignIncomingPhoneNumber(id:string, options = {}) {
	return destroy<any>(`/app/api/campaign_incoming_phone_numbers/${id}`, options);
}

export function CreateCampaignIncomingPhoneNumber(options = {}) {
	return post<any>("/app/api/campaign_incoming_phone_numbers", options);
}

export function UpdateCampaignIncomingPhoneNumber(id:string, options = {}) {
	return put<any>(`/app/api/campaign_incoming_phone_numbers/${id}`, options);
}