import { get, post } from '.';

export function FindProspectLead(id) {
	return get<any>("/app/api/prospect_leads/" + (id || 0).toString());
}

export function GetProspectLeads(options = {}) {
	return get<any>("/app/api/prospect_leads", options);
}

export function GetProspectCampaignCustomers(options = {}) {
	return get<any>("/app/api/prospect_leads/campaign_customers", options);
}

export function EnrollProspect(options = {}) {
	return post<any>("/app/api/prospect_enroll", options);
}
