<template lang="pug">
    div(:key="key" style="display: flex; flex-direction: row; justify-content: flex-start; align-items: center; align-content: center;")
        v-menu(
            v-model="timePicker"
            offset-y
            bottom
            right
            :close-on-content-click="false"
            transition='scale-transition'
            max-width="200px"
        )
            template(v-slot:activator='{ on, attrs }')
                v-text-field(
                    :id="refKey"
                    v-model="timeInput"
                    type="text"
                    :label="inputLabel"
                    :placeholder="placeholder"
                    clearable
                    :rules="[v => isEmptyInput || validTime || 'Invalid time. Please use format: HH:MM AM/PM']"
                    validate-on-blur
                    prepend-icon='mdi-clock-outline'
                    @click:prepend='() => timePicker = !timePicker'
                    @blur="onBlur"
                    @focus="onFocus"
                    @paste="onPaste"
                    @keydown="onKeyDown"
                    @click="onClick"
                    :disabled="disableInput"
                    :error-messages="errorMessageNotInWindow"
                )

            v-card
                v-list
                    v-list-item
                        v-list-item-content
                            b-time(
                                v-model="timeValue"
                                :minutes-step="5"
                                hide-header
                            )
                v-card-actions(right)
                    v-spacer
                    v-btn(
                        text
                        right
                        color='primary'
                        @click='timePicker = false'
                    ) Close

</template>

<script lang="ts">
    import Vue from 'vue';

    import { BootstrapVue } from 'bootstrap-vue';
    Vue.use(BootstrapVue);

    // Import Bootstrap an BootstrapVue CSS files (order is important)
    import 'bootstrap/dist/css/bootstrap.css';
    import 'bootstrap-vue/dist/bootstrap-vue.css';

    import { v4 as uuidv4 } from 'uuid';

    // Helpers
    import { timeMilitaryToFloat } from "@/helpers/general";

    export default Vue.extend({
        data() {
            return {
                key: null,
                inputLabel: null,
                placeholder: null,
                refKey: null,
                timeInput: '',
                timePicker: false,
                timeValue: null,
            }
        },
        methods: {
            handleChange() {
                if (this.validTime && this.isTimeWithinWindow) {
                    this.onChange(this.timeValue.substring(0, 5));
                    return;
                }

                this.onChange(null);
                return;
            },
            isTimeInputSelected() {

                // get text input area
                const textArea = document.getElementById(this.refKey);
                if (!textArea) {
                    return false;
                }

                // get selection range
                const selectionStart = (textArea as HTMLInputElement).selectionStart;
                const selectionEnd   = (textArea as HTMLInputElement).selectionEnd;

                if (selectionEnd > selectionStart) {
                    return true;
                }

                return false;
            },
            onClick($event) {

                // get text input area
                const textArea = document.getElementById(this.refKey);
                if (!textArea) {
                    $event.preventDefault();
                    return;
                }

                // focus text area
                textArea.focus();

                // highlight entire input on click
                (textArea as HTMLInputElement).setSelectionRange(0, this.timeInput?.length || 0);
                $event.preventDefault();
                return;
            },
            onBlur() {
                this.placeholder = null;
            },
            onFocus() {
                this.placeholder = "HH:MM AM/PM";
            },
            onKeyDown($event) {

                // verify valid event
                if (!($event?.key?.length > 0)) {
                    $event.preventDefault();
                    return;
                }

                // on tab - let press to exit input
                if ($event.key === 'Tab' || $event.code === 'Tab') {
                    return true;
                }

                // on arrow right, set text at end of input
                if ($event.key === 'ArrowRight' || $event.code === 'ArrowRight') {
                    // get text input area
                    const textArea = document.getElementById(this.refKey);
                    if (!textArea) {
                        $event.preventDefault();
                        return;
                    }

                    // focus text area
                    textArea.focus();

                    // set cursor to end of input value
                    (textArea as HTMLInputElement).setSelectionRange(this.timeInput?.length || 0, this.timeInput?.length || 0);

                    $event.preventDefault();
                    return;
                }

                // need to handle copy/paste
                if ($event.metaKey === true || $event.ctrlKey === true) { // meta equals cmd, ctrl is control
                    // allow cut/copy/paste
                    if ($event.key === 'x' || $event.key === 'c' || $event.key === 'v') {
                        return true;
                    }

                    $event.preventDefault();
                    return;
                }

                // if input selected, everything below alters input and should be deleted
                if (this.isTimeInputSelected()) {
                    this.timeInput = '';
                }

                // backspace control
                if ($event.key === 'Backspace' || $event.code === 'Backspace') {
                    if (this.timeInput?.length === 3) {
                        this.timeInput = this.timeInput[0];
                        $event.preventDefault();
                        return;
                    } else if (this.timeInput?.length === 8) {
                        this.timeInput = this.timeInput.substring(0, 6);
                        $event.preventDefault();
                        return;
                    } else if (this.timeInput?.length === 6) {
                        this.timeInput = this.timeInput.substring(0, 4);
                        $event.preventDefault();
                        return;
                    } else {
                        return true;
                    }
                }

                // space control
                if ($event.key === 'Space' || $event.code === 'Space') {
                    if (this.timeInput?.length === 5) {
                        return true;
                    } else if (this.timeInput?.length === 4) { // case where trying to enter '1:30 pm', and it's formatting to '13:0'
                        if (['1', '2'].includes(this.timeInput[0]) && this.timeInput[2] === ':') {
                            if (this.timeInput[0] === '1') {
                                if (['0', '1', '2', '3', '4', '5'].includes(this.timeInput[1])) {
                                    this.timeInput = '0' + this.timeInput[0] + ':' + this.timeInput[1] + this.timeInput[3] + ' ';
                                }
                            } else if (this.timeInput[0] === '2') {
                                if (['0', '1', '2', '3', '4'].includes(this.timeInput[1])) {
                                    this.timeInput = '0' + this.timeInput[0] + ':' + this.timeInput[1] + this.timeInput[3] + ' ';
                                }
                            }
                        }
                    }
                        
                    $event.preventDefault();
                    return;
                }

                // parse/format time input
                if (!(this.timeInput?.length > 0)) {
                    if (!(['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'].includes($event.key))) {
                        $event.preventDefault();
                        return;
                    }

                    // if single digit hour, prepend 0
                    if (['3', '4', '5', '6', '7', '8', '9'].includes($event.key)) {
                        this.timeInput = '0' + $event.key + ':';
                    } else {
                        this.timeInput = $event.key;
                    }

                    $event.preventDefault();
                    return;

                } else if (this.timeInput.length === 1) { // 1

                    // if not valid
                    if (!(['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', ':'].includes($event.key))) {
                        $event.preventDefault();
                        return;
                    }

                    if (!(['0', '1', '2', '3', '4'].includes($event.key))) {
                        if ($event.key === ':') {
                            this.timeInput = '0' + this.timeInput[0] + $event.key;
                        } else {
                            this.timeInput = this.timeInput + $event.key + ':';
                        }

                        $event.preventDefault();
                        return;
                    }

                    this.timeInput = this.timeInput + $event.key + ':';
                    $event.preventDefault();
                    return;
    
                } else if (this.timeInput.length === 2) { // 12 // many cases we programmatically add colon after last hour digit and this isn't reached
                    if ($event.key === ':') {
                        this.timeInput = this.timeInput + ':';
                    }

                    $event.preventDefault();
                    return;

                } else if (this.timeInput.length === 3) { // 12:
                    if (!(['0', '1', '2', '3', '4', '5'].includes($event.key))) {
                        if (['6', '7', '8', '9'].includes($event.key) && this.timeInput[0] !== '0') {
                            this.timeInput = [0] + this.timeInput + $event.key;
                        }

                        $event.preventDefault();
                        return;
                    }

                    this.timeInput = this.timeInput + $event.key;
                    $event.preventDefault();
                    return;

                } else if (this.timeInput.length === 4) { // 12:3
                    if (!(['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'].includes($event.key))) {
                        $event.preventDefault();
                        return;
                    }

                    this.timeInput = this.timeInput + $event.key + ' ';
                    $event.preventDefault();
                    return;

                } else if (this.timeInput.length === 5) { // '12:30' 
                    if (!(['a', 'A', 'p', 'P', ' '].includes($event.key))) {
                        $event.preventDefault();
                        return;
                    }

                    if (['a', 'A'].includes($event.key)) {
                        this.timeInput = this.timeInput + ' AM'
                    } else if (['p', 'P'].includes($event.key)) {
                        this.timeInput = this.timeInput + ' PM'
                    }

                    $event.preventDefault();
                    return;

                } else if (this.timeInput.length === 6) { // '12:30 '
                    if (!(['a', 'A', 'p', 'P'].includes($event.key))) {
                        $event.preventDefault();
                        return;
                    }

                    if (['a', 'A'].includes($event.key)) {
                        this.timeInput = this.timeInput + 'AM'
                    } else if (['p', 'P'].includes($event.key)) {
                        this.timeInput = this.timeInput + 'PM'
                    }

                    $event.preventDefault();
                    return;
                }

                $event.preventDefault();
                return;
            },
            onPaste($event) {

                const clipboardData = $event.clipboardData || null;
                if (!clipboardData) {
                    $event.preventDefault();
                    return;

                }

                // copy/paste should override input
                this.timeInput = (clipboardData.getData('Text') || '');

                $event.preventDefault();
                return;
            },
            resetKey() {
                this.key = uuidv4();
            },
            setLabel() {
                if (this.label?.length > 0) {
                    this.inputLabel = this.label;
                    return;
                }

                this.inputLabel = "Time";
                return;
            },
        },
        props: {
            appointmentSchedulingHour: Object,
            disableInput: Boolean,
            label: String,
            onChange: Function,
            value: String,
        },
        computed: {
            appointmentSchedulingWindow() {

                // Ensure valid
                if (!this.validAppointmentSchedulingHour) {
                    return null;
                }

                // Check if closed
                if (this.isWindowDayClosed) {
                    return 'CLOSED on ' + (this.appointmentSchedulingHour.day.charAt(0).toUpperCase() + this.appointmentSchedulingHour.day.slice(1)) + 's';
                }

                return 'Valid scheduling hours for ' + (this.appointmentSchedulingHour.day.charAt(0).toUpperCase() + this.appointmentSchedulingHour.day.slice(1)) + ': ' + this.appointmentSchedulingHour.start_time_hour_value + ':' + this.appointmentSchedulingHour.start_time_minute_value + ' ' + this.appointmentSchedulingHour.start_time_am_pm_value + ' - ' + this.appointmentSchedulingHour.end_time_hour_value + ':' + this.appointmentSchedulingHour.end_time_minute_value + ' ' + this.appointmentSchedulingHour.end_time_am_pm_value;
            },
            errorMessageNotInWindow() {
                return (this.validTime || this.isWindowDayClosed) && !this.isTimeWithinWindow ? this.appointmentSchedulingWindow : null;
            },
            isEmptyInput() {
                return !(this.timeInput?.length > 0);
            },
            isTimeWithinWindow() {

                // Verify we have a valid window
                if (!this.validAppointmentSchedulingHour) {
                    return true;
                }

                // Check if we are closed on current day
                if (this.isWindowDayClosed) {
                    return false;
                }

                // Ensure valid time
                if (!this.validTime) {
                    return false;
                }

                // Convert to military time as a number
                const timeValue = timeMilitaryToFloat(this.timeValue.substring(0, 5));

                // Ensure it is within the scheduling window
                if (timeValue < this.appointmentSchedulingHour.start_time_hour || timeValue >= this.appointmentSchedulingHour.end_time_hour) {
                    return false;
                }

                return true;
            },
            isWindowDayClosed() {

                // Verify we have a valid window
                if (!this.validAppointmentSchedulingHour) {
                    return false;
                }

                return this.appointmentSchedulingHour.is_closed === true;
            },
            validAppointmentSchedulingHour() {
                return this.appointmentSchedulingHour?.appointment_scheduling_hour_id > 0;
            },
            validTime() {
                return this.timeValue?.length === 8;
            },
        },
        components: {},
        created() {
            this.refKey = 'timepicker-' + uuidv4();
            this.resetKey();
            this.setLabel();

            // In case value is already preset, check
            if (this.value?.length > 0) {
                this.timeInput = this.value;
            }
        },
        watch: {
            label: {
                handler (next, old) {
                    if (next !== old) {
                        this.setLabel();
                    }
                }
            },
            timeInput: {
                handler (next, old) {

                    // if invalid input set timeValue to null
                    if (this.timeInput?.length !== 8) {
                        this.timeValue = null;
                    }

                    if (this.timeInput?.length > 0) {
                        this.timeInput = this.timeInput.trimStart();

                        // check valid characters in each place
                        // let timeInput = '';
                        for (let i = 0; i < this.timeInput.length; i++) {
                            const char = this.timeInput.charAt(i);
                            if (i === 0) { // i.e. '1'

                                // if not valid
                                if (!(['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'].includes(char))) {
                                    this.timeInput = this.timeInput.substring(1);
                                    return;
                                }

                                // if single digit hour, prepend 0
                                if (['3', '4', '5', '6', '7', '8', '9'].includes(char)) {
                                    this.timeInput = '0' + this.timeInput;
                                    return;
                                }

                            } else if (i === 1) { // i.e. '12'

                                // if not valid
                                if (!(['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', ':'].includes(char))) {
                                    this.timeInput = this.timeInput[0] + this.timeInput.substring(2);
                                    return;
                                }

                                // if valid
                                if (char === ':') {
                                    this.timeInput = '0' + this.timeInput;
                                    return;
                                } else if (this.timeInput[0] === '0') { 
                                    // need to handle 00: case later when set to am and set 00 to 12
                                } else if (this.timeInput[0] === '1') {
                                    // 1-9 all valid
                                } else if (this.timeInput[0] === '2') {
                                    if (!(['0', '1', '2', '3', '4'].includes(char))) {
                                        this.timeInput = this.timeInput[0] + this.timeInput.substring(2);
                                        return;
                                    }
                                }
                            } else if (i === 2) { // i.e. '12:'

                                // if not valid
                                if (char !== ':') {
                                    this.timeInput = this.timeInput.substring(0, 2) + ':' + this.timeInput.substring(2); // if 3rd char is not a colon, insert colon
                                    return;
                                }
                            } else if (i === 3) { // i.e. '12:3'

                                // if not valid
                                if (!(['0', '1', '2', '3', '4', '5'].includes(char))) {
                                    this.timeInput = this.timeInput.substring(0, 3) + this.timeInput.substring(4);
                                    return;
                                }
                            } else if (i === 4) { // i.e. '12:30'

                                // if not valid
                                if (!(['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'].includes(char))) {
                                    this.timeInput = this.timeInput.substring(0, 4) + this.timeInput.substring(5);
                                    return;
                                }

                                // HH:MM check is complete - change military times to 12hr format
                                if (this.timeInput.substring(0, 2) == '00' || this.timeInput.substring(0, 2) == '24') {

                                    this.timeInput = '12' + this.timeInput.substring(2, 5) + ' AM';
                                    return;
                                } else if (parseInt(this.timeInput.substring(0, 2), 10) > 12 && parseInt(this.timeInput.substring(0, 2), 10) < 24) {

                                    const hr12Value = parseInt(this.timeInput.substring(0, 2), 10) - 12;
                                    let hr12Format = hr12Value.toString();
                                    if (hr12Value < 10) {
                                        hr12Format = '0' + hr12Format;
                                    }
                                    this.timeInput = (hr12Format).toString() + this.timeInput.substring(2, 5) + ' PM';
                                    return;
                                }
                            } else if (i === 5) { // i.e. '12:30 '

                                // if not valid
                                if (char !== ' ') {
                                    this.timeInput = this.timeInput.substring(0, 5) + ' ' + this.timeInput.substring(5); // if 5th char is not a space, insert space
                                    return;
                                }
                            } else if (i === 6) { // i.e. '12:30 P'

                                // if PM
                                if (['p', 'P'].includes(char)) {
                                    this.timeInput = this.timeInput.substring(0, 6) + 'PM'
                                // if AM
                                } else if (['a', 'A'].includes(char)) {
                                    this.timeInput = this.timeInput.substring(0, 6) + 'AM'
                                } else {
                                    this.timeInput = this.timeInput.substring(0, 6)
                                    return;
                                }

                            } else if (i === 7) { // i.e. '12:30 PM'

                                // update timeValue for time picker 
                                // handle 12am, 12pm, pm times
                                if (this.timeInput.substring(0, 2) === '12') {
                                    if (this.timeInput.substring(6, 8) === 'AM') {
                                        this.timeValue = '00' + this.timeInput.substring(2, 5) + ':00'
                                    } else if (this.timeInput.substring(6, 8) === 'PM') {
                                        this.timeValue = '12' + this.timeInput.substring(2, 5) + ':00'
                                    }
                                } else if (this.timeInput.substring(6, 8) === 'PM') {
                                    this.timeValue = (parseInt(this.timeInput.substring(0, 2), 10) + 12).toString() + this.timeInput.substring(2, 5) + ':00';
                                } else {
                                    this.timeValue = this.timeInput.substring(0, 5) + ':00';
                                }

                                return;
                            }
                        }
                    }
                },
            },
            timeValue() {

                // check that valid output i.e. 00:00:00
                if (this.validTime) {
                    if (this.timeValue.substring(0, 2) === '00') {
                        this.timeInput = '12:' + this.timeValue.substring(3, 5) + ' AM';
                    } else if (this.timeValue.substring(0, 2) === '12') {
                        this.timeInput = '12:' + this.timeValue.substring(3, 5) + ' PM';
                    } else if (parseInt(this.timeValue.substring(0, 2), 10) > 12 && parseInt(this.timeValue.substring(0, 2), 10) < 24) {
                        const hr12Value = parseInt(this.timeValue.substring(0, 2), 10) - 12;
                        let hr12Format = hr12Value.toString();
                        if (hr12Value < 10) {
                            hr12Format = '0' + hr12Format;
                        }
                        this.timeInput = (hr12Format).toString() + this.timeValue.substring(2, 5) + ' PM';
                    } else {
                        this.timeInput = this.timeValue.substring(0, 5) + ' AM';
                    }

                }

                // pass value to props
                this.handleChange();
                return;
            },
            value: { // handles format HH:MM
                handler (next, old) {
                    if ((next || '') !== (old || '')) {
                        if (next?.length === 5) { // 00:00
                            this.timeValue = next + ":00";
                            return;
                        }

                        if (next === null || next === undefined) {
                            this.timeValue = null;
                            this.timeInput = '';
                            return;
                        }
                    }
                }
            }
        },
    });
</script>

<style lang="scss" >
    // docs say to use this, but the current scss files have breaking errors
    // #app {
    //     @import 'bootstrap/scss/bootstrap.scss';
    //     @import 'bootstrap-vue/src/index.scss';
    // }
</style>