import { get, put, post, destroy } from '.';

export function GetWebhooks(options = {}) {
	return get<any>("/app/api/webhooks", options);
}

export function FindWebhook(vid) {
	return get<any>("/app/api/webhooks/" + vid);
}

export function CreateWebhook(options = {}) {
	return post<any>("/app/api/webhooks", options);
}

export function UpdateWebhook(id, options = {}) {
	return put<any>("/app/api/webhooks/" + (id || 0).toString(), options);
}

export function DestroyWebhook(id, options = {}) {
	return destroy<any>("/app/api/webhooks/" + (id || 0).toString(), options);
}

export function GetWebhookTypes() {
	return get<any>("/app/api/webhook_types");
}
