<template lang="pug">
    .ma-sm-2(v-if="validCustomer")
        .text-center(v-if="isLoading")
            v-progress-circular(
                :size='100'
                :width='10'
                color='#0f5b9a'
                indeterminate
                style="margin-top: 100px;"
            )

        v-snackbar(
            v-model='snackbarOpen'
            :color='snackbar.color'
            :timeout="snackbar.timeout"
            top
        )
            | {{ snackbar.text }}

            template(v-slot:action="{ attrs }")
                v-btn(dark text @click='snackbarOpen = false' v-bind="attrs")
                    | Close

        v-container.mx-auto(v-if="!isLoading")
            v-row(no-gutters)
                //- v-tabs(grow show-arrows light v-model="currentTab")
                //-     v-tab(v-for='(item, i) in tabItems' :key='item.key' :href="item.href")
                //-         | {{ item.name }}

                v-tabs-items(style='width: 100%;')
                    keep-alive
                        div(v-if="currentTab === 'toyoguard-tab'")

                            CustomerVehicleToyoguardDetails(
                                v-if="validToyoguardProgramDetails"
                                v-for='toyoguardProgram in toyoguardProgramDetails'
                                :key='toyoguardProgram.vid'
                                :toyoguard-program="toyoguardProgram"

                            )


                            v-alert(outlined type='warning' dense border='left' style="margin-top: 16px;" v-if="!validToyoguardProgramDetails")
                                | Customer does not have any vehicles enrolled in the Toyoguard program.

                        //- CustomerVehicleToyoguardSearch.overflow-y-auto(
                        //-     v-if="currentTab === 'search-tab'"
                        //- )

</template>

<script lang="ts">
    import Vue from 'vue';

    // Components
    import CustomerVehicleToyoguardDetails from '@/components/customer_vehicles/toyoguard_details.vue';
    // import CustomerVehicleToyoguardSearch from '@/components/customer_vehicles/toyoguard_search.vue';
    // Data
    import { GetCustomerVehiclesToyoguard } from "@/data";

    export default Vue.extend({
        data() {
            return {
                currentTab: 'toyoguard-tab',
                toyoguardProgramDetails: [],
                isLoading: false,
                limit: 25,
                primaryCustomerVehicleId: null,
                snackbarOpen: false,
                snackbar: {
                    timeout: 2000,
                },
                tabItems: [],
            }
        },
        methods: {

            getCustomerVehicles(skipLoader) {
                if (!this.validCustomer) {
                    return;
                }

                const options = {
                    account_id:                  this.currentCampaignCustomer.account_id,
                    customer_id:                 this.currentCampaignCustomer.customer_id,
                    organization_id:             this.currentCampaignCustomer.organization_id,
                }

                // Load if necessary
                if (!skipLoader) {
                    this.isLoading = true;
                }

                GetCustomerVehiclesToyoguard(options).then((response) => {
                    if (response?.Data?.toyoguard_vehicles?.length > 0) {
                        this.toyoguardProgramDetails = response.Data.toyoguard_vehicles;
                    }
                    this.isLoading = false;
                });
            },
            resetAndSearch() {
                this.toyoguardProgramDetails = [];
                this.getCustomerVehicles(false);
            },
            setTabs() {

                // Init
                this.tabItems = [
                    { name: "toyoguard", key: "toyoguard", href: "#toyoguard-tab" },
                    // { name: "Search",   key: "search",   href: "#search-tab" },
                ]

            },
            showSnackbar(color, text) {
                if (color?.length > 0 && text?.length > 0) {
                    this.snackbar.color = color;
                    this.snackbar.text = text;
                    this.snackbarOpen = true;
                }
            },

        },
        computed: {
            validCampaignCustomer() {
                return this.validCustomer && this.currentCampaignCustomer?.campaign_customer_active_id > 0 && this.currentCampaignCustomer?.campaign_customer_id > 0;
            },
            validToyoguardProgramDetails() {
                return this.toyoguardProgramDetails?.length > 0;
            },
            validCustomer() {
                return this.currentCampaignCustomer?.account_id > 0 && this.currentCampaignCustomer?.customer_id > 0 && this.currentCampaignCustomer?.organization_id > 0;
            },
        },
        components: {
            CustomerVehicleToyoguardDetails,
            // CustomerVehicleToyoguardSearch,
        },
        props: {
            currentCampaignCustomer: Object,
        },
        created() {
            this.setTabs();
            this.getCustomerVehicles(false);
        },
    });
</script>

<style lang="scss" scoped>
</style>
