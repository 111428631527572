<template lang="pug">
    v-simple-table(v-if="showMore" :id="'show-more-row'+key")
        tr
            td.text-center(colspan='100%')
                v-btn.volie-color-blue.width-100-percent(block link outlined text ripple large tile :loading="loading" @click="handleShowMore()" role="text")
                    | Show More 

</template>

<script lang="ts">
    import Vue from 'vue';

    export default Vue.extend({
        computed: {
            key() {
                return this.idKey || '';
            },
        },
        props: {
            handleShowMore: Function,
            idKey: String,
            loading: Boolean,
            showMore:   Boolean,
        },
    });
</script>

<style lang="scss" scoped>
</style>
