<template lang="pug">
    div
        .text-center(v-if="isLoading")
            v-progress-circular(
                :size='100'
                :width='10'
                color='#0f5b9a'
                indeterminate
                style="margin-top: 75px; margin-bottom: 75px;"
            )
        AmazonTranscription(
            v-else-if="callTranscription && callTranscription.provider === 'amazon'" 
            :callTranscription="callTranscription" 
            :conference="conference" 
            :isLoading="isLoading"
        )
        DeepgramTranscription(
            v-else-if="callTranscription && callTranscription.provider === 'deepgram'" 
            :callTranscription="callTranscription" 
            :conference="conference" 
            :isLoading="isLoading"
        )

        v-alert(v-else outlined type='info' dense border='left' style="margin: 10px 40px 10px 20px;")
            | Unable to find call transcription.


</template>

<script lang="ts">
import Vue from 'vue';

// Components
import AmazonTranscription from '@/components/conferences/call_transcriptions/amazon_transcription.vue';
import DeepgramTranscription from '@/components/conferences/call_transcriptions/deepgram_transcription.vue';

export default Vue.extend({
    data() {
        return {

        }
    },
    methods: {

    },
    computed: {

    },
    components: {
        AmazonTranscription,
        DeepgramTranscription,

    },
    props: {
        callTranscription: Object,
        conference: Object,
        isLoading: Boolean,
    },
    watch: {

    },
    created() {
    },
    destroyed() {
    },
    mounted() {
    },
})
</script>

<style lang="scss" scoped>

</style>