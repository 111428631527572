import { get } from '.';

export function GetAffinityDashboardAgents(options = {}) {
	return get<any>("/app/api/affinity_dashboard/agents", options);
}

export function GetAffinityDashboardAvailableAssignments(options = {}) {
	return get<any>("/app/api/affinity_dashboard/available_assignments", options);
}

export function GetAffinityDashboardEnrollees(options = {}) {
	return get<any>("/app/api/affinity_dashboard/enrollees", options);
}

export function GetAffinityDashboardCampaignCustomers(options = {}) {
	return get<any>("/app/api/affinity_dashboard/campaign_customers", options);
}

export function GetAffinityDashboardCustomers(options = {}) {
	return get<any>("/app/api/affinity_dashboard/customers", options);
}

export function GetAffinityDashboardAssignedCustomers(options = {}) {
	return get<any>("/app/api/affinity_dashboard/assigned_customers", options);
}