import { get, put, post, destroy } from '.';

export function GetOrganizationAccesses(accessGroupVid, accountAccessVid, options = {}) {
	return get<any>("/app/api/access_groups/" + accessGroupVid + "/account_accesses/" + accountAccessVid + "/organization_accesses", options);
}

export function FindOrganizationAccess(accessGroupVid, accountAccessVid, vid) {
	return get<any>("/app/api/access_groups/" + accessGroupVid + "/account_accesses/" + accountAccessVid + "/organization_accesses/" + vid);
}

export function CreateOrganizationAccess(accessGroupVid, accountAccessVid, options = {}) {
	return post<any>("/app/api/access_groups/" + accessGroupVid + "/account_accesses/" + accountAccessVid + "/organization_accesses", options);
}

export function UpdateOrganizationAccess(accessGroupVid, accountAccessVid, id, options = {}) {
	return put<any>("/app/api/access_groups/" + accessGroupVid + "/account_accesses/" + accountAccessVid + "/organization_accesses/" + (id || 0).toString(), options);
}

export function DestroyOrganizationAccess(accessGroupVid, accountAccessVid, id, options = {}) {
	return destroy<any>("/app/api/access_groups/" + accessGroupVid + "/account_accesses/" + accountAccessVid + "/organization_accesses/" + (id || 0).toString(), options);
}

export function GetAvailableOrganizationAccesses(accessGroupVid, accountAccessVid, options = {}) {
	return get<any>("/app/api/access_groups/" + accessGroupVid + "/account_accesses/" + accountAccessVid + "/organization_accesses/available", options);
}
