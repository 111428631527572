import { render, staticRenderFns } from "./email_recipient_details.vue?vue&type=template&id=4235b1bf&scoped=true&lang=pug"
import script from "./email_recipient_details.vue?vue&type=script&lang=ts"
export * from "./email_recipient_details.vue?vue&type=script&lang=ts"
import style0 from "./email_recipient_details.vue?vue&type=style&index=0&id=4235b1bf&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4235b1bf",
  null
  
)

export default component.exports