<template lang="pug">
    v-card.mx-lg-auto.mt-md-4.mb-md-4(outlined width="100%" v-if="validEleadActivity" id="elead-activity-details-card")
        v-toolbar(flat :color="itemColor")
            v-btn(
                icon
                ripple
                @click="showDetails = !showDetails;"
            )
                v-icon.text-white(v-if="showDetails === true")
                    | mdi-chevron-down
                v-icon.text-white(v-else)
                    | mdi-chevron-right

            v-toolbar-title.text-white
                | {{ eleadActivity.elead_activity_name }}

                v-spacer
                    span.text-body-2(v-if="eleadActivity.friendly_closed_at_time !== ''")
                     | CLOSED ON {{ eleadActivity.friendly_closed_at_time }}  

                    span.text-body-2(v-else-if="eleadActivity.friendly_completed_at_time !== ''")
                     | COMPLETED ON {{ eleadActivity.friendly_completed_at_time }}  

                    span.text-body-2(v-else-if="eleadActivity.friendly_due_at_time !== ''")
                     | DUE ON {{ eleadActivity.friendly_due_at_time }}
             
                    span.text-body-2(v-else-if="eleadActivity.friendly_created_at_time !== ''")
                     | CREATED ON {{ eleadActivity.friendly_created_at_time }}

            v-spacer
            .text-caption.text-right.m-l-5
                strong.text-uppercase.text-white {{ eleadActivity.outcome }}

        v-card-text.white.text--primary(
            v-if="showDetails"
        )
            v-list.py-0(
                two-line
            )

                v-list-item
                    v-list-item-content
                        v-list-item-title(:class="eleadActivity.type") {{ eleadActivity.type }}
                        v-list-item-subtitle Type

                v-list-item
                    v-list-item-content
                        v-list-item-title(:class="eleadActivity.up_type") {{ eleadActivity.up_type }}
                        v-list-item-subtitle Up Type

                v-list-item
                    v-list-item-content
                        v-list-item-title(v-if='eleadActivity.source.length > 0' :class="eleadActivity.source") {{ eleadActivity.source }}
                        v-list-item-title(v-else) N/A
                        v-list-item-subtitle Source

                v-list-item
                    v-list-item-content
                        v-list-item-title(v-if='eleadActivity.organization_name.length > 0' :class="eleadActivity.organization_name") {{ eleadActivity.organization_name }}
                        v-list-item-title(v-else) N/A
                        v-list-item-subtitle Organization

                v-list-item
                    v-list-item-content
                        v-list-item-title(v-if='eleadActivity.assigned_to.length > 0' :class="eleadActivity.assigned_to") {{ eleadActivity.assigned_to }}
                        v-list-item-title(v-else) N/A
                        v-list-item-subtitle Assigned To

                v-list-item
                    v-list-item-content
                        v-list-item-title(:class="eleadActivity.friendly_created_at_time") {{ eleadActivity.friendly_created_at_time }}
                        v-list-item-subtitle Created On

                v-list-item
                    v-list-item-content
                        v-list-item-title(v-if='eleadActivity.friendly_due_at_time.length > 0' :class="eleadActivity.friendly_due_at_time") {{ eleadActivity.friendly_due_at_time }}
                        v-list-item-title(v-else) N/A
                        v-list-item-subtitle Due On

                v-list-item
                    v-list-item-content
                        v-list-item-title(v-if='eleadActivity.friendly_completed_at_time.length > 0' :class="eleadActivity.friendly_completed_at_time") {{ eleadActivity.friendly_completed_at_time }}
                        v-list-item-title(v-else) N/A
                        v-list-item-subtitle Completed On

                v-list-item
                    v-list-item-content
                        v-list-item-title(v-if='eleadActivity.friendly_closed_at_time.length > 0' :class="eleadActivity.friendly_closed_at_time") {{ eleadActivity.friendly_closed_at_time }}
                        v-list-item-title(v-else) N/A
                        v-list-item-subtitle Closed On

                v-list-item
                    v-list-item-content
                        v-list-item-title
                            CopyableText(
                                v-if="eleadActivity.elead_activity_vid.length > 0"
                                :text="eleadActivity.elead_activity_vid"
                            )
                            template(v-else) N/a
                        v-list-item-subtitle Activity VID

                v-list-item
                    v-list-item-content
                        v-list-item-title
                            CopyableText(
                                v-if="eleadActivity.elead_opportunity_vid.length > 0"
                                :text="eleadActivity.elead_opportunity_vid"
                            )
                            template(v-else) N/a
                        v-list-item-subtitle Opportunity VID

                v-list-item
                    v-list-item-content
                        v-list-item-title(v-if='eleadActivity.comments.length > 0' :class="eleadActivity.comments") {{ eleadActivity.comments }}
                        v-list-item-title(v-else) N/A
                        v-list-item-subtitle Comments

</template>

<script lang="ts">
    import Vue from 'vue';

    // Components
    import CopyableText from '@/components/shared/copyable_text.vue'; 

    // Helpers
    import { resourceColorClass, } from "@/helpers/css_classes";

    export default Vue.extend({
        data() {
            return {
                eleadActivityLineLineCardWidth: 300,
                showDetails: false,
                showCoBuyerDetails: false,
            }
        },
        methods: {
            handleEleadActivityDetailsResize() {
                const eleadActivityDetailsWidth = document?.getElementById('elead-activity-details-card')?.getBoundingClientRect()?.width
                if (eleadActivityDetailsWidth >= 375) {
                    this.eleadActivityLineCardWidth = eleadActivityDetailsWidth - 75
                } else {
                    this.eleadActivityLineCardWidth = 300
                }
            },
        },
        computed: {
            itemColor() {
                return resourceColorClass("Prospect");
            },
            validEleadActivity() {
                return this.eleadActivity?.elead_activity_id > 0;
            },
        },
        components: {
            CopyableText,
        },
        props: {
            eleadActivity: Object,
        },
        watch: {
            eleadActivity: {
                handler() {
                    Vue.nextTick(() => { this.handleEleadActivityDetailsResize(); });
                },
                deep: true,
            },
        },
        created() {
            window.addEventListener('resize', this.handleEleadActivityDetailsResize);
        },
        destroyed() {
            window.removeEventListener('resize', this.handleEleadActivityDetailsResize);
        },
        mounted() {
            this.handleEleadActivityDetailsResize();
        },
    });
</script>

<style lang="scss" scoped>
    #app {

        .cell-value-spacing {
            padding: 0 0 5px 0;
        }

        .m-l-5 {
            margin-left: 5px;
        }
    }
</style>
