import { get, put } from '.';

export function AssignVoiceMailRecording(id, options = {}) {
	return put<any>("/app/api/voice_mail_recordings/" + (id || 0).toString() + "/assign", options);
}

export function GetVoiceMailRecordings(options = {}) {
	return get<any>("/app/api/voice_mail_recordings", options);
}

export function GetVoicemailRecordingsCountForDeletion(options = {}) {
	return get<any>("/app/api/voice_mail_recordings/count", options);
}

export function FindVoiceMailRecording(voiceMailRecordingKey, options = {}) {
	return get<any>("/app/api/voice_mail_recordings/" + voiceMailRecordingKey, options);
}

export function StartVoiceMailRecording(id, options = {}) {
	return put<any>("/app/api/voice_mail_recordings/" + (id || 0).toString() + "/start", options);
}

export function UpdateVoiceMailRecording(id, options = {}) {
	return put<any>("/app/api/voice_mail_recordings/" + (id || 0).toString(), options);
}

export function ArchiveMassVoiceMailRecordings(options = {}) {
	return put<any>("/app/api/archive_voice_mail_recordings", options)
}

export function DeleteVoiceMailRecordings(options ={}) {
	return put<any>("/app/api/archive_voice_mail_recordings/mass_delete", options)
}