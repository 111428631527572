<template lang="pug">
    div(v-if="validProps")
        .text-center(v-if="isLoading")
            v-progress-circular(
                :size='100'
                :width='10'
                color='#0f5b9a'
                indeterminate
                v-if="isLoading"
                style="margin-top: 100px; margin-bottom: 100px;"
            )
        template(v-else-if="validSharedLinks")
            v-card.ma-4(
                v-if="mobileView"
                v-for='(sharedLink, idx) in sharedLinks'
                :key='sharedLink.shared_link_id'
                shaped
            )
                v-toolbar(
                    dense
                )
                    v-toolbar-title
                        template(v-if="sharedLink.name && sharedLink.name.length > 0") {{ sharedLink.name }}
                        template(v-else) No Name
                    v-spacer
                    strong.text-uppercase(v-bind:class="[statusClass(sharedLink.status)]") {{ formatStatus(sharedLink.status) }}

                v-card-text
                    v-container
                        CopyableText.break-all(:text="sharedLink.url")
                    
                    template
                        v-list(two-line)
                            template
                                v-list-item
                                    v-list-item-icon
                                        v-icon(color='primary')
                                            | mdi-cog
                                    v-list-item-content
                                        v-list-item-title {{ sharedLink.friendly_created_at_date }}
                                        v-list-item-subtitle Created

                                v-list-item
                                    v-list-item-icon
                                    v-list-item-content
                                        v-list-item-title {{ sharedLink.access | titleize }}
                                        v-list-item-subtitle Access

                                v-list-item
                                    v-list-item-icon
                                    v-list-item-content
                                        v-list-item-title {{ sharedLink.downloads | titleize }}
                                        v-list-item-subtitle Downloads

                                v-list-item
                                    v-list-item-icon
                                    v-list-item-content
                                        v-list-item-title
                                            template(v-if="sharedLink.expires_at") {{ sharedLink.friendly_expires_at }}
                                            template(v-else) N/a
                                        v-list-item-subtitle Expiration

                            template
                                v-divider(inset)
                                v-list-item
                                    v-list-item-icon
                                        v-icon(color='primary')
                                            | mdi-magnify-scan
                                    v-list-item-content
                                        v-list-item-title {{ sharedLink.total_shared_link_visits | number }}
                                        v-list-item-subtitle # Visits

                                v-list-item
                                    v-list-item-icon
                                    v-list-item-content
                                        v-list-item-title
                                            template(v-if="sharedLink.last_shared_link_visit_friendly_created_at") {{ sharedLink.last_shared_link_visit_friendly_created_at }}
                                            template(v-else) N/a
                                        v-list-item-subtitle Last Visit

        v-alert.mt-4(outlined type='warning' dense border='left' style="margin-top: 16px;" v-else)
            | Unable to find any shared links

</template>

<script lang="ts">
    import Vue from 'vue';

    // Components
    import CopyableText from '@/components/shared/copyable_text.vue';

    // Data
    import { GetSharedLinks } from "@/data";

    export default Vue.extend({
        data() {
            return {
                isLoading: true,
                sharedLinks: [],
            }
        },
        methods: {
            formatStatus(status) {
                return status?.replace(/_/g, " ");
            },
            getSharedLinks() {
                
                // Init
                const options = {
                    component: (this.component || ""),
                    shareable_id: (this.shareableId || 0),
                    shareable_type: (this.shareableType || ""),
                }

                GetSharedLinks(options).then((response) => {
                    if (response?.Data?.shared_links?.length > 0) {
                        this.sharedLinks = response?.Data?.shared_links;
                    }

                    this.isLoading = false;
                });
            },
            statusClass(status) {
                let className = "";
                switch(status) {
                case "active":
                    className = "green--text";
                    break;
                case "inactive":
                    className = "error--text";
                    break;
                }

                return className;
            },
        },
        computed: {
            validComponent() {
                return ["conference_show_full", "customer_profile", "email_show", "message_show"].indexOf(this.component) >= 0;
            },
            validProps() {
                return this.validComponent && this.validShareable;
            },
            validShareable() {
                return this.shareableId > 0 && ["conference", "customer", "email", "message"].indexOf(this.shareableType) >= 0;
            },
            validSharedLinks() {
                return this.sharedLinks?.length > 0;
            },
        },
        components: {
            CopyableText,
        },
        props: {
            component: String,
            mobileView: Boolean,
            shareableId: Number,
            shareableType: String,
        },
        created() {
            this.getSharedLinks();
        },
    });
</script>

<style lang="scss" scoped>
</style>
