<template lang="pug">
    div
        v-divider(inset)
        template(v-if="validCustomerPhoneNumbers")
            v-list-item(
                v-for='(customerPhoneNumber, idx) in customerPhoneNumbers'
                :key='idx'
            )
                v-list-item-icon
                    v-icon(color='primary' v-if="idx === 0")
                        | mdi-phone
                v-list-item-content
                    v-list-item-title
                        CopyablePhoneNumber(:phone-number="customerPhoneNumber")
                    v-list-item-subtitle
                        template(v-if="customerPhoneNumber.phone_number_type === 'cell'")
                            v-btn(v-if="customerPhoneNumber.phone_number_id !== customerCellPhoneId" text small color="green" @click="updateCustomerPhonePrimary(customerPhoneNumber.customer_phone_number_id)" class="primary-btn") Set Primary Cell
                            v-btn(v-if="customerPhoneNumber.phone_number_id === customerCellPhoneId" text small disabled class="primary-btn") Primary Cell
                        template(v-else-if="customerPhoneNumber.phone_number_type === 'home'")
                            v-btn(v-if="customerPhoneNumber.phone_number_id !== customerHomePhoneId" text small color="green" @click="updateCustomerPhonePrimary(customerPhoneNumber.customer_phone_number_id)" class="primary-btn") Set Primary Home
                            v-btn(v-if="customerPhoneNumber.phone_number_id === customerHomePhoneId" text small disabled class="primary-btn") Primary Home
                        template(v-else-if="customerPhoneNumber.phone_number_type === 'work'")
                            v-btn(v-if="customerPhoneNumber.phone_number_id !== customerWorkPhoneId" text small color="green" @click="updateCustomerPhonePrimary(customerPhoneNumber.customer_phone_number_id)" class="primary-btn") Set Primary Work
                            v-btn(v-if="customerPhoneNumber.phone_number_id === customerWorkPhoneId" text small disabled class="primary-btn") Primary Work

                template(v-if="!hideCreateUpdateDestroyButtons")
                    v-list-item-action
                        template
                            v-btn(
                                v-if="smsMessageAllowed(customerPhoneNumber)"
                                icon
                                light
                                @click="setMessageCustomerPhoneNumber(customerPhoneNumber, null);"
                                :disabled="allowedSmsMessageDisabled(customerPhoneNumber)"
                            )
                                v-icon mdi-cellphone-message
                            v-btn(v-else icon light disabled)
                                v-icon mdi-cellphone-message-off

                    v-list-item-action
                        CustomerPhoneNumberFormDialog(
                            :customer-phone-number="customerPhoneNumber"
                            :update-customer-phone-number="updateCustomerPhoneNumber"
                        )

                    v-list-item-action(style="margin-left: 0px;")
                        CustomerPhoneNumberDestroyDialog(
                            :customer-phone-number-id="customerPhoneNumber.customer_phone_number_id"
                            :phone-number="customerPhoneNumber.phone_number_phone_number"
                            :destroy-customer-phone-number="destroyCustomerPhoneNumber"
                        )
            
            v-list-item(v-if="!hideCreateUpdateDestroyButtons")
                v-list-item-content
                    v-list-item-title.text-center
                        CustomerPhoneNumberFormDialog(
                            :create-customer-phone-number="createCustomerPhoneNumber"
                        )

        template(v-else)
            v-list-item
                v-list-item-icon
                    v-icon(color='primary')
                        | mdi-phone
                v-list-item-content
                    v-list-item-title No phone numbers on profile
            
            v-list-item(v-if="!hideCreateUpdateDestroyButtons")
                v-list-item-content
                    v-list-item-title.text-center
                        CustomerPhoneNumberFormDialog(
                            :create-customer-phone-number="createCustomerPhoneNumber"
                        )

</template>

<script lang="ts">
    import Vue from 'vue';

    // Components
    import CopyablePhoneNumber from '@/components/shared/copyable_phone_number.vue';
    import CustomerPhoneNumberDestroyDialog from '@/components/customer_phone_numbers/destroy_dialog.vue';
    import CustomerPhoneNumberFormDialog from '@/components/customer_phone_numbers/form_dialog.vue';

    export default Vue.extend({
        data() {
            return {
                newCustomerPhoneNumberDialog: false,
            }
        },
        methods: {
            allowedSmsMessageDisabled(cpn) {

                // Verify
                if (!cpn || !(cpn.customer_phone_number_id > 0)) {
                    return true;
                }

                // If no valid cc, then just viewing plain customer profile and so this disable is not necessary
                if (!this.validCurrentCampaignCustomer) {
                    return false;
                }

                // We only care about disabling if purpose is return message and we are viewing the same number
                if (this.currentCampaignCustomer?.purpose !== "return_message" || !(this.currentCampaignCustomer?.conversation_group?.conversation_group_id > 0 && this.currentCampaignCustomer?.conversation_group?.personable_type === "customer_phone_number")) {
                    return false;
                }

                return this.currentCampaignCustomer.conversation_group.personable_id === cpn.customer_phone_number_id;
            },
            smsMessageAllowed(cpn) {

                // Verify
                if (!cpn || !(cpn.customer_phone_number_id > 0)) {
                    return false;
                }

                return (cpn.do_not_message === false && cpn.phone_number_do_not_message === false && cpn.has_account_or_organization_opt_out_message === false && cpn.phone_number_is_litigator === false);
            },
        },
        computed: {
            validCurrentCampaignCustomer() {
                return this.currentCampaignCustomer?.campaign_customer_active_id > 0;
            },
            validCustomerPhoneNumbers() {
                return this.customerPhoneNumbers?.length > 0;
            },
        },
        components: {
            CopyablePhoneNumber,
            CustomerPhoneNumberDestroyDialog,
            CustomerPhoneNumberFormDialog,
        },
        props: {
            currentCampaignCustomer: Object,
            customerPhoneNumbers: Array,
            customerCellPhoneId:  Number,
            customerHomePhoneId:  Number,
            customerWorkPhoneId:  Number,
            createCustomerPhoneNumber:  Function,
            destroyCustomerPhoneNumber: Function,
            hideCreateUpdateDestroyButtons: Boolean,
            setMessageCustomerPhoneNumber: Function,
            updateCustomerPhoneNumber:  Function,
            updateCustomerPhonePrimary: Function,
        },
        created() {
        },
    });
</script>

<style lang="scss" scoped>
    .theme--light.v-btn.v-btn--disabled {
        color: #1867c0 !important;
    }
    .v-data-table > .v-data-table__wrapper > table > tbody > tr > td , .v-data-table > .v-data-table__wrapper > table > tbody > tr > th, .v-data-table > .v-data-table__wrapper > table > thead > tr > td, .v-data-table > .v-data-table__wrapper > table > thead > tr > th, .v-data-table > .v-data-table__wrapper > table > tfoot > tr > td, .v-data-table > .v-data-table__wrapper > table > tfoot > tr > th {
        padding: 0px 4px;
    }
    .primary-btn.v-btn:not(.v-btn--round).v-size--small {
        padding: 0px 2px;
    }
</style>
