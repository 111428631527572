<template lang="pug">
	v-dialog(v-model='dialog' scrollable persistent max-width='600px')
		template(v-slot:activator='{ on }')
			v-btn(icon light v-on='on' @click='initCustomerContactInfoGeneral();')
				v-icon mdi-circle-edit-outline
		v-card
			v-card-title
				span.text-h5 Edit General Contact Info
			v-card-text
				v-container
					v-row
						v-col(cols='12')
							v-text-field(
								label='First Name' 
								type='text' 
								v-model="updatedFirstName" 
								clearable 
								validate-on-blur 
								:rules="nameRules"
							)
						
						v-col(cols='12')
							v-text-field(
								label='Middle Name' 
								type='text' 
								v-model="updatedMiddleName" 
								clearable
							)
						
						v-col(cols='12')
							v-text-field(
								label='Last Name' 
								type='text' 
								v-model="updatedLastName" 
								clearable 
								validate-on-blur 
								:rules="nameRules"
							)
						
						v-col(cols='12')
							v-text-field(
								label='Company Name' 
								type='text' 
								v-model="updatedCompanyName" 
								clearable
							)
						
						v-col(cols='12')
							v-menu(ref="birthDateMenu" v-model="birthDateMenu" :close-on-content-click="false" transition="scale-transition" offset-y max-width="290px" min-width="290px")
								template(v-slot:activator="{ on, attrs }")
									v-text-field(v-model="birthDateFormatted" label="Birth Date" hint="MM/DD/YYYY format" @click:prepend-inner='() => birthDateMenu = true' persistent-hint clearable prepend-inner-icon="mdi-calendar" v-bind="attrs" @blur="updatedBirthDate = parseDate(birthDateFormatted)" v-on="on")
								v-date-picker(v-model="updatedBirthDate" @input="birthDateMenu = false" :max="new Date().toISOString()")

			v-card-actions.text-right
				v-spacer
					v-btn.ma-2(color="#0f5b9a" outlined ripple @click='dialog = false; resetUpdatedValues();') 
						v-icon mdi-window-close
						| Cancel
					v-btn.ma-2(color="green" outlined ripple :disabled="!validCustomerCommunicationManager" @click='handleUpdateCustomerCommunicationManager(); dialog = false; resetUpdatedValues();')
						v-icon mdi-content-save
						| Save

</template>

<script lang="ts">
    import Vue from 'vue';

    export default Vue.extend({
        data() {
            return {
                // not using object for general info because date and dateFormatted staying in sync breaks when in an object even with a watcher with deep: true
                birthDateMenu: false,
				birthDateFormatted: null,
				dialog: false,
                nameRules: [
                    v => !!v || 'First or Last Name is required',
                ],
                updatedFirstName:   null,
                updatedMiddleName:  null,
                updatedLastName:    null,
                updatedCompanyName: null,
				updatedBirthDate: null,
            }
        },
        methods: {
            handleUpdateCustomerCommunicationManager() {

                const options = {
                    first_name: this.updatedFirstName,
                    middle_name: this.updatedMiddleName,
                    last_name: this.updatedLastName,
                    company_name: this.updatedCompanyName,
                    birth_date: this.birthDateFormatted,
                }

                // Return if not valid options
                if (!this.validCustomerCommunicationManager) {
                    return;
                }

                this.updateCustomerCommunicationManager(options);
            },
			initCustomerContactInfoGeneral() {
				this.updatedFirstName   = this.firstName
				this.updatedMiddleName   = this.middleName
				this.updatedLastName    = this.lastName
				this.updatedCompanyName = this.companyName
				this.updatedBirthDate = !!this.birthDate && this.birthDate.length > 0 ? new Date(this.birthDate).toISOString().substr(0, 10) : null
				this.birthDateFormatted = !!this.updatedBirthDate && this.updatedBirthDate.length > 0 ? this.formatDate(new Date(this.updatedBirthDate).toISOString().substr(0, 10)) : null
            },
            formatDate(date) {
                if (!date) return null

                const [year, month, day] = date.split('-')
                return `${month}/${day}/${year}`
            },
            parseDate(date) {
                if (!date) return null

                const [month, day, year] = date.split('/')
                return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
			},
			resetUpdatedValues() {
				this.updatedFirstName   = null
				this.updatedMiddleName  = null
				this.updatedLastName    = null
				this.updatedCompanyName = null
				this.updatedBirthDate   = null
				this.birthDateFormatted = null
            },
        },
        computed: {
            validCustomerCommunicationManager() {

				// Customer MUST have at least a first OR last name
                if (!(this.updatedFirstName?.length > 0) && !(this.updatedLastName?.length > 0)) {
                    return false;
                }

                return true;
            },
        },
        components: {
        },
        props: {
            firstName:   String,
            middleName:  String,
            lastName:    String,
            companyName: String,
            birthDate:   String,
            updateCustomerCommunicationManager: Function,
        },
        created() {
		},
		watch: {
            updatedBirthDate() {
                this.birthDateFormatted = this.formatDate(this.updatedBirthDate)
			},
		},
    });
</script>

<style lang="scss" scoped>
</style>