<template lang="pug">
    div(v-if="validCommunicationManager")

        v-divider(inset)
        template
            v-list-item
                v-list-item-icon
                    v-icon(color='primary')
                        | mdi-account-cog
                v-list-item-content
                    v-list-item-title Communication Preferences
                    v-list-item-subtitle Subscribed To
            
            v-list-item
                v-list-item-icon
                v-list-item-content
                    v-list-item-title.list-item-title-overflow Receive all communications
                    v-list-item-subtitle
                v-list-item-action
                    v-checkbox(
                        v-model='subscribedCommunicationTypeAll'
                        dense
                        ripple
                        @change="handleCreateOptInOut('subscribedCommunicationTypeAll')"
                        :disabled="hideCreateUpdateDestroyButtons"
                    )

            //- v-list-item
            //-     v-list-item-icon
            //-     v-list-item-content
            //-         v-list-item-title.list-item-title-overflow Receive mail communications
            //-         v-list-item-subtitle
            //-     v-list-item-action
            //-         v-checkbox(
            //-             v-model='subscribedCommunicationTypeMail'
            //-             dense
            //-             ripple
            //-             @change="handleCreateOptInOut('subscribedCommunicationTypeMail')"
            //-             :disabled="hideCreateUpdateDestroyButtons"
            //-         )

            //- v-list-item
            //-     v-list-item-icon
            //-     v-list-item-content
            //-         v-list-item-title.list-item-title-overflow Receive email communications
            //-         v-list-item-subtitle
            //-     v-list-item-action
            //-         v-checkbox(
            //-             v-model='subscribedCommunicationTypeEmail'
            //-             dense
            //-             ripple
            //-             @change="handleCreateOptInOut('subscribedCommunicationTypeEmail')"
            //-             :disabled="hideCreateUpdateDestroyButtons"
            //-         )

            //- v-list-item
            //-     v-list-item-icon
            //-     v-list-item-content
            //-         v-list-item-title.list-item-title-overflow Receive phone call communications
            //-         v-list-item-subtitle
            //-     v-list-item-action
            //-         v-checkbox(
            //-             v-model='subscribedCommunicationTypePhone'
            //-             dense
            //-             ripple
            //-             @change="handleCreateOptInOut('subscribedCommunicationTypePhone')"
            //-             :disabled="hideCreateUpdateDestroyButtons"
            //-         )

            //- v-list-item
            //-     v-list-item-icon
            //-     v-list-item-content
            //-         v-list-item-title.list-item-title-overflow Receive SMS message communications
            //-         v-list-item-subtitle
            //-     v-list-item-action
            //-         v-checkbox(
            //-             v-model='subscribedCommunicationTypeMessage'
            //-             dense
            //-             ripple
            //-             @change="handleCreateOptInOut('subscribedCommunicationTypeMessage')"
            //-             :disabled="hideCreateUpdateDestroyButtons"
            //-         )

</template>

<script lang="ts">
    import Vue from 'vue';

    export default Vue.extend({
        data() {
            return {
                subscribedCommunicationTypeAll: false,
                subscribedCommunicationTypeMail: false,
                subscribedCommunicationTypeEmail: false,
                subscribedCommunicationTypePhone: false,
                subscribedCommunicationTypeMessage: false,
            }
        },
        methods: {
            handleCreateOptInOut(key) {

                // Verify valid key
                if (!(key?.length > 0)) {
                    return;
                }

                // Determine action
                let action = "unsubscribe";
                if (this[key] === true) {
                    action = "subscribe";
                }

                const options = {
                    action: action,
                    communication_type: null,
                    optable_id: this.communicationManager.customer_id,
                    optable_type: "customer",
                    source: "customer_profile",
                }

                if (this.inCommunicationCenter) {
                    options.source = "communication_center";
                }

                switch(key) {
                case "subscribedCommunicationTypeAll":
                    options.communication_type = "all";
                    break;

                case "subscribedCommunicationTypeEmail":
                    options.communication_type = "email";
                    break;

                case "subscribedCommunicationTypeMail":
                    options.communication_type = "mail";
                    break;

                case "subscribedCommunicationTypeMessage":
                    options.communication_type = "message";
                    break;

                case "subscribedCommunicationTypePhone":
                    options.communication_type = "phone";
                    break;
                }

                // Return if invalid communication type
                if (!(options?.communication_type?.length > 0)) {
                    return;
                }

                this.createOptInOut(options);
            },
            setSubscribedData() {
                this.subscribedCommunicationTypeAll = this.communicationManager.subscribed_communication_type_all;
                this.subscribedCommunicationTypeMail = this.communicationManager.subscribed_communication_type_mail;
                this.subscribedCommunicationTypeEmail = this.communicationManager.subscribed_communication_type_email;
                this.subscribedCommunicationTypePhone = this.communicationManager.subscribed_communication_type_phone;
                this.subscribedCommunicationTypeMessage = this.communicationManager.subscribed_communication_type_message;
            },
        },
        computed: {
            validCommunicationManager() {
                return this.communicationManager?.customer_id > 0;
            },
        },
        components: {
        },
        props: {
            communicationManager: Object,
            createOptInOut: Function,
            hideCreateUpdateDestroyButtons: Boolean,
            inCommunicationCenter: Boolean,
        },
        watch: {
            "communicationManager.subscribed_communication_type_all"() {
                this.setSubscribedData();
            },
            "communicationManager.subscribed_communication_type_email"() {
                this.setSubscribedData();
            },
            "communicationManager.subscribed_communication_type_mail"() {
                this.setSubscribedData();
            },
            "communicationManager.subscribed_communication_type_message"() {
                this.setSubscribedData();
            },
            "communicationManager.subscribed_communication_type_phone"() {
                this.setSubscribedData();
            },
        },
        created() {
            this.setSubscribedData();
        },
    });
</script>

<style lang="scss" scoped>
    #app {
        .list-item-title-overflow {
            overflow: auto !important;
            white-space: normal !important;
        }
    }
</style>
