<template lang="pug">
    div
        v-container.mx-auto
            div(v-if="validAlertTypeSurveys")

                AlertTypeSurvey(
                    v-for='(alertTypeSurvey, idx) in alertTypeSurveys'
                    :key="alertTypeSurvey.alert_type_survey_id"
                    :alert-type-survey="alertTypeSurvey"
                    :idx="idx"
                )

            div.text-center(v-else-if="isLoading")
                v-progress-circular(
                    :size='100'
                    :width='10'
                    color='#0f5b9a'
                    indeterminate
                    style="margin-top: 75px; margin-bottom: 75px;"
                )

            v-alert(v-else outlined type='info' dense border='left' style="margin-top: 16px;")
                | There are no alert type surveys for this conference

</template>

<script lang="ts">
    import Vue from 'vue';

    // Components
    import AlertTypeSurvey from '@/components/conferences/hot_alerts/show.vue';

    export default Vue.extend({
        computed: {
            validAlertTypeSurveys() {
                return this.alertTypeSurveys?.length > 0;
            },
        },
        props: {
            alertTypeSurveys: Array,
            isLoading: Boolean,
        },
        components: {
            AlertTypeSurvey,
        }
    });
</script>

<style lang="scss" scoped>
</style>
