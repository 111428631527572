import { destroy, get, post, put } from '.';

export function GetCrmDispositionMappings(organizationPluginVid: string, options = {}) {
	return get<any>(`/app/api/organization_plugins/${organizationPluginVid}/crm_disposition_mappings`, options);
}

export function FindCrmDispositionMapping(organizationPluginVid: string, vid: string, options = {}) {
	return get<any>(`/app/api/organization_plugins/${organizationPluginVid}/crm_disposition_mappings/${vid}`, options);
}

export function CreateCrmDispositionMapping(organizationPluginVid: string, options = {}) {
	return post<any>(`/app/api/organization_plugins/${organizationPluginVid}/crm_disposition_mappings`, options)
}

export function UpdateCrmDispositionMapping(organizationPluginVid: string, id: string, options = {}) {
	return put<any>(`/app/api/organization_plugins/${organizationPluginVid}/crm_disposition_mappings/${id}`, options)
}

export function DestroyCrmDispositionMapping(organizationPluginVid: string, id: string) {
	return destroy<any>(`/app/api/organization_plugins/${organizationPluginVid}/crm_disposition_mappings/${id}`);
}