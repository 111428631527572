<template lang="pug">
    .base-min-height
        v-container
            template(v-if="validEmailBody")
                v-tabs(light v-model="currentTab")
                    v-tab(v-for='(item, i) in tabItems' :key='item.key' :href="item.href")
                        | {{ item.name }}

                v-tabs-items(v-model="currentTab")
                    template(v-if="currentTab === 'view-tab'")
                        v-container(v-html="emailBody.text_html_body")

                    template(v-if="currentTab === 'plain-tab'")
                        v-container
                            | {{ emailBody.text_plain_body }}

                    template(v-if="currentTab === 'mime-tab'")
                        v-container
                            | {{ emailBody.text_mime_body }}

            v-alert(outlined type='warning' dense border='left' style="margin-top: 16px;" v-else)
                | Unable to load email body

</template>

<script lang="ts">
    import Vue from 'vue';

    export default Vue.extend({
        data() {
            return {
                currentTab: "view-tab",
                tabItems: [
                    { name: "View", key: "view", href: "#view-tab" },
                ],
            }
        },
        methods: {
            initTabs() {

                // Plain
                if (this.validPlain) {
                    this.tabItems.push({ name: "Plain", key: "plain", href: "#plain-tab" });
                }

                // Mime
                if (this.validMime) {
                    this.tabItems.push({ name: "Mime", key: "mime", href: "#mime-tab" });
                }
            },
        },
        computed: {
            validEmailBody() {
                return this.emailBody?.email_body_id > 0;
            },
            validMime() {
                return this.emailBody?.text_mime_body?.length > 0;
            },
            validPlain() {
                return this.emailBody?.text_plain_body?.length > 0;
            },
        },
        components: {
        },
        props: {
            emailBody: Object,
        },
        created() {
            this.initTabs();
        },
    });
</script>

<style lang="scss" scoped>
    #app {
        .base-min-height {
            min-height: 300px;
        }
    }
</style>