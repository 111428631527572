<template lang="pug">
    v-container(v-if="validEmailThread")
        .text-center(v-if="isLoading")
            v-progress-circular(
                :size='100'
                :width='10'
                color='#0f5b9a'
                indeterminate
                style="margin-top: 100px;"
            )

        ViewingEmailInThread(
            v-else-if="validEmails"
            v-for="(item) in emails"
            :key="item.email_id"
            :email="item"
            :highlight-email-id="highlightEmailId"
            :is-view-only="isViewOnly"
            :reply-from="emailThread.reply_from"
            :start-reply-to-email="startReplyToEmail"
        )

        v-alert(
            v-else
            border="left"
            dense
            type="warning"
        )
            | Unable to find any emails in this thread

</template>

<script lang="ts">
    import Vue from 'vue';
    import VolieState  from "@/store";

    // Components
    import ViewingEmailInThread from '@/components/communication_center/email/viewing_email_in_thread.vue';

    // Data
    import {
        GetCommunicationCenterEmailsInThread,
    } from "@/data";

    export default Vue.extend({
        data() {
            return {
                bus: VolieState.websocketClient().eventBus(),
                emails: [],
                isLoading: false,
            }
        },
        methods: {
            createAgentForJoinedThread() {
                if (!(this.currentCampaignCustomer?.campaign_customer_active_id > 0)) {
                    return;
                }

                const options = {
                    campaign_customer_id: this.currentCampaignCustomer.campaign_customer_id,
                    campaign_id: this.currentCampaignCustomer.campaign_id,
                    noticeable_id: this.currentCampaignCustomer.campaign_customer_id,
                    noticeable_type: "campaign_customer",
                    organization_id: this.currentCampaignCustomer.organization_id,
                    status_name: "Email - Joined Thread",
                }

                return this.validCreateAgent ? this.createAgent(options) : null;
            },
            getCommunicationCenterEmailsInThread(isResync) {
                const options = {
                    account_id: (this.emailThread?.account_id || 0),
                    email_thread_id: (this.emailThread?.email_thread_id || 0),
                    last_email_id: (this.emailThread?.last_email_id || 0),
                }

                this.isLoading = true;
                GetCommunicationCenterEmailsInThread(options).then((response) => {
                    if (!!response.Data?.emails) {

                        // Set
                        this.emails = response.Data.emails;

                        // // If the re-sync got us some missed messages, play incoming audio sound so the user knows new messages are here
                        // if (isResync === true && !!this.conversationGroup?.last_message_time && lastMessageTime !== this.conversationGroup?.last_message_time) {
                        //     this.playIncomingMessageAudio();
                        // }
                    }
                    this.isLoading = false;
                });
            },
            onNewCommunicationCenterEmail(response) {

                // Set thread and verify presence
                const email = response?.Data;
                if (!email || !(email?.email_thread_id > 0) || !(email?.account_id > 0)) {
                    return;
                }

                // Veirfy matches thread
                if (email.email_thread_id !== this.emailThreadID) {
                    return;
                }

                // Attempt to find existing email
                const emailIdx = this.emails.findIndex(i => i.email_id === email.email_id);
                if (emailIdx >= 0) {
                    return;
                }

                // Append to array
                this.emails.push(email);
                return this.sortEmails();
            },
            resyncThread() {
                this.getCommunicationCenterEmailsInThread(true);
            },
            sortEmails() {
                if (this.validEmails) {
                    this.emails = this.emails.sort(function(x, y) {
                        return x.email_id - y.email_id;
                    });
                }
            },
        },
        computed: {
            emailThreadID() {
                return (this.emailThread?.email_thread_id || 0);
            },
            validCreateAgent() {
                return this.createAgent !== undefined && this.createAgent !== null;
            },
            validEmails() {
                return this.emails?.length > 0;
            },
            validEmailThread() {
                return this.emailThread?.last_email_id > 0;
            },
        },
        components: {
            ViewingEmailInThread,
        },
        props: {
            createAgent: Function,
            currentCampaignCustomer: Object,
            emailThread: Object,
            isViewOnly: Boolean,
            highlightEmailId: Number,
            startReplyToEmail: Function,
        },
        destroyed() {
            this.bus.unsubscribe({ type: "broadcast" , channel: { ID: null, Type: "newCommunicationCenterEmail" }}, this.onNewCommunicationCenterEmail);
        },
        created() {
            this.bus.subscribe({ type: "broadcast" , channel: { ID: null, Type: "newCommunicationCenterEmail" }}, this.onNewCommunicationCenterEmail);
            this.getCommunicationCenterEmailsInThread();
            this.createAgentForJoinedThread();
        },
        mounted() {
        },
        watch: {
        },
    });
</script>

<style lang="scss" scoped>
</style>
