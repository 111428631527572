<template lang="pug">
    v-dialog(v-model='dialog' scrollable persistent max-width='600px')
        template(v-slot:activator='{ on }')
            v-btn(icon light v-on='on')
                v-icon mdi-trash-can-outline
        v-card
            v-card-title
                span.text-h5 Remove Email Address
            v-card-text
                v-container
                    span.text-h6 Are you sure you want to delete this email address?

                v-container
                    span {{ emailAddress }}

            v-card-actions.text-right
                v-spacer
                    v-btn.ma-2(color="#0f5b9a" outlined ripple @click='dialog = false;')
                        v-icon mdi-window-close
                        | Close
                    v-btn.ma-2(color="red" outlined ripple :disabled="!(customerEmailAddressId > 0)" @click='handleDestroyCustomerEmailAddress(); dialog = false;')
                        v-icon mdi-content-save
                        | Delete

</template>

<script lang="ts">
    import Vue from 'vue';

    export default Vue.extend({
        data() {
            return {
                dialog: false,
            }
        },
        methods: {
            handleDestroyCustomerEmailAddress() {

                const options = {
                    customer_email_address_id: this.customerEmailAddressId,
                }

                // Return if not valid options
                if (!(this.customerEmailAddressId > 0)) {
                    return;
                }

                this.destroyCustomerEmailAddress(options);
            },
        },
        computed: {
        },
        components: {
        },
        props: {
            customerEmailAddressId: Number,
            emailAddress: String,
            destroyCustomerEmailAddress: Function,
        },
        created() {
            return;
        },
    });
</script>

<style lang="scss" scoped>
</style>