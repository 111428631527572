<template lang="pug">
    .ma-sm-2(v-if="validFilterIds")

        v-snackbar(
            v-model='snackbar.show'
            :color='snackbar.color'
            :timeout="snackbar.timeout"
            top
        )
            | {{ snackbar.text }}
            v-btn(dark text @click='snackbar.show = false')
                | Close

        v-container.mx-auto(v-if="!isLoading")
            .text-subtitle-2.subtitle-2-padding.text-center
                | Custom Fields

            .text-right(style='margin-top: 15px;' v-if="!hideCreateUpdateDestroyButtons")
                CustomFieldFormDialog(
                    :campaign-customer-id="campaignCustomerId"
                    :customer-id="customerId"
                    :create-custom-field="createCustomField"
                )

            CustomFieldDetails(
                v-if="validCustomFields"
                v-for='customField in customFields'
                :key='customField.custom_field_id'
                :campaign-customer-id="campaignCustomerId"
                :customer-id="customerId"
                :custom-field="customField"
                :destroy-custom-field="destroyCustomField"
                :hide-create-update-destroy-buttons="hideCreateUpdateDestroyButtons"
                :update-custom-field="updateCustomField"
            )

            v-alert(outlined type='warning' dense border='left' v-if="!validCustomFields" style='margin-top: 15px;')
                | No custom fields on profile



        .text-center(v-if="isLoading")
            v-progress-circular(
                :size='100'
                :width='10'
                color='#0f5b9a'
                indeterminate
                style="margin-top: 75px; margin-bottom: 75px;"
            )

</template>

<script lang="ts">
import Vue from "vue";

// Data
import { CreateCustomField, DestroyCustomField, GetCustomFields, UpdateCustomField } from "@/data";

// Components
import CustomFieldDetails from '@/components/custom_fields/custom_field_details.vue';
import CustomFieldFormDialog from "@/components/custom_fields/form_dialog.vue";

export default Vue.extend({
    data() {
        return {
            customFields: [],
            isLoading: false,
            snackbar: {
                show: false,
                timeout: 2500,
            },
        };
    },
    methods: {
        createCustomField(options) {
            if (!this.validFilterIds) {
                return;
            }

            if (!(options?.key?.length > 0 && options?.value?.length > 0 && options?.data_type?.length > 0 && options?.modelable_type?.length > 0 && options?.modelable_id > 0)) {
                return;
            }

            // Set option values
            options.account_id      = this.accountId;
            options.organization_id = this.organizationId;
            options.source = "3";

            this.isLoading = true;

            CreateCustomField(options).then((response) => {
                if (response?.Data?.custom_field?.custom_field_id > 0) {
                    this.customFields.push(response.Data.custom_field);

                    // sort by key name
                    this.sortCustomFieldsByKey();

                    this.showSnackbar("green", "Successfully created custom field");
                } else {
                    if (response?.Error == "Custom field key already exists") {
                        this.showSnackbar("red", response.Error)
                    } else {
                        this.showSnackbar("red", "Failed to create custom field: " + response.Error);
                    }
                }

                this.isLoading = false;
            });
        },
        destroyCustomField(options) {
            if (!this.validFilterIds || !(options?.custom_field_id > 0)) {
                return;
            }

            this.isLoading = true;

            DestroyCustomField(options.custom_field_id).then((response) => {
                if (!(response?.Error?.length > 0)) {
                    const idxToRemove = this.customFields.findIndex(customField => customField.custom_field_id === options.custom_field_id);
                    if (idxToRemove >= 0) {
                        this.customFields.splice(idxToRemove, 1);
                    }

                    this.showSnackbar("green", "Successfully deleted custom field");
                } else {
                    this.showSnackbar("red", "Failed to delete custom field");
                }

                this.isLoading = false;
            });
        },
        getCustomFields() {
            if (!this.validFilterIds) {
                return;
            }

            const options = {
                account_id:           this.accountId,
                organization_id:      this.organizationId,
                campaign_customer_id: null,
                modelable_id:         this.customerId,
                modelable_type:       "Customer",
            }

            if (this.campaignCustomerId > 0) {
                options.campaign_customer_id = this.campaignCustomerId;
            }

            this.isLoading = true;

            GetCustomFields(options).then((response) => {
                if (response?.Data?.custom_fields?.length > 0) {
                    this.customFields = response.Data.custom_fields;
                }
                this.isLoading = false;
            });
        },
        showSnackbar(color, text) {
            if (color?.length > 0 && text?.length > 0) {
                this.snackbar.color = color;
                this.snackbar.text = text;
                this.snackbar.show = true;
            }
        },
        sortCustomFieldsByKey() {
            if (this.customFields?.length > 0) {
                this.customFields.sort((a, b) => {
                    if (a?.key?.toLowerCase() < b?.key?.toLowerCase()) {
                        return -1
                    }
                    if (a?.key?.toLowerCase() > b?.key?.toLowerCase()) {
                        return 1
                    }
                    return 0
                });
            }
        },
        updateCustomField(options) {
            if (!this.validFilterIds) {
                return;
            }

            if (!(options?.custom_field_id > 0 && options?.key?.length > 0 && options?.value?.length > 0 && options?.data_type?.length > 0 && options?.modelable_type?.length > 0 && options?.modelable_id > 0)) {
                return;
            }

            // Set option values
            options.account_id      = this.accountId;
            options.organization_id = this.organizationId;

            this.isLoading = true;

            UpdateCustomField(options.custom_field_id, options).then((response) => {
                if (response?.Data?.custom_field?.custom_field_id > 0) {
                    const idxToReplace = this.customFields.findIndex(customField => customField.custom_field_id === response.Data.custom_field.custom_field_id);
                    if (idxToReplace >= 0) {
                        this.customFields.splice(idxToReplace, 1, response.Data.custom_field);
                    }

                    // sort by key name
                    this.sortCustomFieldsByKey();

                    this.showSnackbar("green", "Successfully updated custom field");
                } else {
                    if (response?.Error == "Custom field key already exists") {
                        this.showSnackbar("red", response.Error)
                    } else {
                        this.showSnackbar("red", "Failed to update custom field: " + response.Error);
                    }
                }

                this.isLoading = false;
            });
        },
    },
    computed: {
        validFilterIds() {
            return this.accountId > 0 && this.organizationId > 0 && this.customerId > 0;
        },
        validCustomFields() {
            return this.customFields?.length > 0;
        },
    },
    components: {
        CustomFieldDetails,
        CustomFieldFormDialog,
    },
    props: {
        accountId:                      Number,
        campaignCustomerId:             Number,
        customerId:                     Number,
        hideCreateUpdateDestroyButtons: Boolean,
        organizationId:                 Number,
    },
    created() {
        this.getCustomFields();
    }
});
</script>

<style lang="scss" scoped>
    .theme--light.v-btn.v-btn--disabled {
        color: #1867c0 !important;
    }
    .v-data-table > .v-data-table__wrapper > table > tbody > tr > td , .v-data-table > .v-data-table__wrapper > table > tbody > tr > th, .v-data-table > .v-data-table__wrapper > table > thead > tr > td, .v-data-table > .v-data-table__wrapper > table > thead > tr > th, .v-data-table > .v-data-table__wrapper > table > tfoot > tr > td, .v-data-table > .v-data-table__wrapper > table > tfoot > tr > th {
        padding: 0px 4px;
    }
    .primary-btn.v-btn:not(.v-btn--round).v-size--small {
        padding: 0px 2px;
    }
</style>
