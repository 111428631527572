import { get, post, put, destroy } from '.';

export function CreateWhiteLabel(options = {}) {
	return post<any>("/app/api/white_labels", options);
}

export function DestroyWhiteLabel(id) {
	return destroy<any>("/app/api/white_labels/" + (id || 0).toString());
}

export function FindWhiteLabel(key) {
	return get<any>("/app/api/white_labels/" + key);
}

export function GetWhiteLabels(options = {}) {
	return get<any>("/app/api/white_labels", options);
}

export function UpdateWhiteLabel(id, options = {}) {
	return put<any>("/app/api/white_labels/" + (id || 0).toString(), options);
}
