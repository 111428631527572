<template lang="pug">
    v-dialog(
        v-model='dialog'
        max-width="600"
        transition='dialog-bottom-transition'
    )
        template(v-slot:activator='{ on, attrs }')
            v-btn.white--text.mr-4(
                color="info"
                v-bind='attrs'
                v-on='on'
                dark
                small
                @click='findScheduledCommunication();'
            )
                v-icon mdi-phone-return
                | Scheduled Communication Details

        v-card
            v-toolbar
                v-btn(icon @click='closeDialog();')
                    v-icon mdi-close
                v-toolbar-title Scheduled Communication Details

            template
                ScheduledCommunicationDetailsCardText(
                    :scheduled-communication="scheduledCommunication"
                )

                v-card-actions.text-right
                    v-spacer
                        v-btn.ma-2(color="#0f5b9a" outlined ripple @click='closeDialog();')
                            v-icon mdi-window-close
                            | Close

</template>

<script lang="ts">
    import Vue from 'vue';

    // Components
    import ScheduledCommunicationDetailsCardText from '@/components/scheduled_communications/details_card_text.vue';

    // Data
    import { FindScheduledCommunication } from "@/data";

    export default Vue.extend({
        data() {
            return {
                dialog: false,
                isLoading: false,
                scheduledCommunication: null,
            }
        },
        methods: {
            closeDialog() {
                this.dialog = false;
            },
            findScheduledCommunication() {

                // Verify
                if (!this.validScheduledCommunicationID) {
                    return;
                }

                // Only need to load this record once
                if (this.validScheduledCommunication) {
                    return;
                }

                this.isLoading = true;
                FindScheduledCommunication(this.scheduledCommunicationId).then((response) => {
                    if (response?.Data?.scheduled_communication?.scheduled_communication_id > 0) {
                        this.scheduledCommunication = response?.Data?.scheduled_communication;
                    }

                    this.isLoading = false;
                });
            },
        },
        computed: {
            validScheduledCommunication() {
                return this.scheduledCommunication?.scheduled_communication_id > 0;
            },
            validScheduledCommunicationID() {
                return this.scheduledCommunicationId > 0;
            },
        },
        components: {
            ScheduledCommunicationDetailsCardText,
        },
        props: {
            scheduledCommunicationId: Number,
        },
        watch: {
        },
        created() {
        },
        destroyed() {
        },
    });
</script>

<style lang="scss" scoped>
</style>
