import { get, put } from '.';

export function BuildCampaignAttemptManagement(options = {}) {
	return get<any>("/app/api/campaign_attempts/build_campaign_attempt_management", options);
}

export function UpdateCampaignAttempts(options = {}) {
    return put<any>("/app/api/campaign_attempts", options);
}

export function GetCampaignAttempts(options = {}) {
    return get<any>("/app/api/campaign_attempts", options);
}

export function GetCampaignAttempt(id, options = {}) {
    return get<any>(`/app/api/campaign_attempts/${id}`, options);
}

export function UpdateCampaignAttempt(id, options = {}) {
    return put<any>(`/app/api/campaign_attempts/${id}`, options);
}