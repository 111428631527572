<template lang="pug">
    v-dialog(v-model='dialog' scrollable persistent max-width='600px')
        template(v-slot:activator='{ on }')
            v-btn(v-if="validCustomerEmailAddress" icon light v-on='on' @click="init();")
                v-icon mdi-circle-edit-outline
            v-btn(v-else outlined color="green" small light v-on='on')
                v-icon mdi-plus
                | Add Email Address
        v-card
            v-card-title
                span.text-h5(v-if="validCustomerEmailAddress") Edit Email Address
                span.text-h5(v-else) New Email Address
            v-card-text
                v-container
                    v-row
                        v-col(cols='12')
                            v-text-field(
                                label='Email Address'
                                v-model="emailAddress"
                                :rules="[rules.required, rules.email]"
                                clearable
                                required
                                validate-on-blur
                                ref="email"
                            )

            v-card-actions.text-right
                v-spacer
                    v-btn.ma-2(color="#0f5b9a" outlined ripple @click='dialog = false; resetUpdatedValues();')
                        v-icon mdi-window-close
                        | Close
                    v-btn.ma-2(v-if="validCustomerEmailAddress" color="green" outlined ripple :disabled="!eligibleToSave" @click='handleUpdateCustomerEmailAddress(); dialog = false; resetUpdatedValues();')
                        v-icon mdi-content-save
                        | Save
                    v-btn.ma-2(v-else color="green" outlined ripple :disabled="!eligibleToSave" @click='handleCreateCustomerEmailAddress(); dialog = false; resetUpdatedValues();')
                        v-icon mdi-content-save
                        | Save

</template>

<script lang="ts">
    import Vue from 'vue';
    // import VolieState  from "@/store";

    export default Vue.extend({
        data() {
            return {
                dialog: false,
                emailAddress: null,
                errorMessage: null,
                rules: {
                    required: v => !!v || 'Required.',
                    email: v => {
                        const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                        return pattern.test(v) || 'Invalid e-mail.'
                    }
                },
            }
        },
        methods: {
            handleCreateCustomerEmailAddress() {

                // Return if not valid options
                if (!this.eligibleToSave) {
                    return;
                }

                const options = {
                    email_address: this.emailAddress,
                }

                this.createCustomerEmailAddress(options);
            },
            handleUpdateCustomerEmailAddress() {

                // Return if not valid options
                if (!this.eligibleToSave || !this.validCustomerEmailAddress) {
                    return;
                }

                const options = {
                    customer_email_address_id: this.customerEmailAddress.customer_email_address_id,
                    email_address:             this.emailAddress,
                }

                this.updateCustomerEmailAddress(options);
            },
            init() {
                if (this.validCustomerEmailAddress) {
                    this.emailAddress = this.customerEmailAddress.email_address_email;
                }
            },
            resetUpdatedValues() {
                this.emailAddress = null
                this.$refs.email.resetValidation();
            },
        },
        computed: {
            eligibleToSave() {
                if (!(this.emailAddress?.length > 0)) {
                    return false;
                }

                const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                if (!pattern.test(this.emailAddress)) {
                    return false;
                }

                return true;
            },
            validCustomerEmailAddress() {
                return this.customerEmailAddress?.customer_email_address_id > 0;
            },
        },
        components: {
        },
        props: {
            createCustomerEmailAddress: Function,
            customerEmailAddress:       Object,
            updateCustomerEmailAddress: Function,
        },
        created() {
            return;
        },
    });
</script>

<style lang="scss" scoped>
</style>