<template lang="pug">
    div
        v-container.mx-auto
            v-simple-table.mb-2(v-if="recordingUrl")
                template(v-slot:default)
                    tbody
                        tr
                            td(colspan="2")
                                .text-capitalize.text-overline.text--secondary.text--darken-3 Recording
                                VolieAudio(
                                    id="audio-player"
                                    v-if="recordingUrl"
                                    :file="recordingUrl"
                                    color="success"
                                    :downloadable="!hideDownloadButtons"
                                    style="margin-bottom: 15px;"
                                )
                                template(v-else) ---
                        

            CallLegs(
                v-if="validCallLegs"
                :conference="conference"
                :hide-download-buttons="hideDownloadButtons"
            )

            div.text-center(v-else-if="isLoading")
                v-progress-circular(
                    :size='100'
                    :width='10'
                    color='#0f5b9a'
                    indeterminate
                    style="margin-top: 75px; margin-bottom: 75px;"
                )

            v-alert(v-else outlined type='info' dense border='left' style="margin-top: 16px;")
                | There are no call legs for this conference

</template>

<script lang="ts">
    import Vue from 'vue';
    import VolieAudio from '@/components/shared/audio_player.vue';

    // Components
    import CallLegs from '@/components/conferences/call_legs/table.vue';
    export default Vue.extend({
        computed: {
            recordingUrl() {
                return this.conference?.recording_url;
            },
            validCallLegs() {
                return this.conference?.calls?.length > 0;
            },
        },
        props: {
            conference: Object,
            hideDownloadButtons: Boolean,
            isLoading: Boolean,
        },
        components: {
            CallLegs,
            VolieAudio,
        }
    });
</script>

<style lang="scss" scoped>
</style>
