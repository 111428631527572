import { get, post } from '.';

export function CreateEmail(options = {}) {
	return post<any>("/app/api/emails", options);
}

export function FindEmail(emailKey, options = {}) {
	return get<any>("/app/api/emails/" + emailKey, options);
}

export function GetEmails(options = {}) {
	return get<any>("/app/api/emails", options);
}


