import { get, post, put, destroy } from '.';

export function CreateAtoPBrand(options = {}) {
	return post<any>(`/app/api/atop_brands`, options);
}

export function DestroyAtoPBrand(vid:string, options = {}) {
	return destroy<any>(`/app/api/atop_brands/${vid}`, options);
}

export function FindAtoPBrand(vid:string, options = {}) {
	return get<any>(`/app/api/atop_brands/${vid}`, options);
}

export function GetAtoPBrands(options = {}) {
	return get<any>("/app/api/atop_brands", options);
}

export function UpdateAtoPBrand(vid:string, options = {}) {
	return put<any>(`/app/api/atop_brands/${vid}`, options);
}
