import { destroy, get, post, put } from '.';

export function GetContacts(options = {}) {
	return get<any>("/app/api/contacts", options);
}

export function CreateContact(options = {}) {
	return post<any>("/app/api/contacts", options);
}

export function UpdateContact(id, options = {}) {
    return put<any>("/app/api/contacts/" + (id || 0).toString(), options);
}

export function DestroyContact(id, options = {}) {
	return destroy<any>("/app/api/contacts/" + (id || 0).toString(), options);
}