import { get, post, put, destroy } from '.';

export function CreateAlertType(options = {}) {
    return post<any>("/app/api/alert_types", options);
}

export function DestroyAlertType(id, options = {}) {
    return destroy<any>("/app/api/alert_types/" + (id || 0).toString(), options);
}

export function FindAlertType(vid) {
    return get<any>("/app/api/alert_types/" + vid);
}

export function GetAlertTypes(options = {}) {
    return get<any>("/app/api/alert_types", options);
}

export function UpdateAlertType(id, options = {}) {
    return put<any>("/app/api/alert_types/" + (id || 0).toString(), options);
}