<template lang="pug">
    v-timeline-item(
        v-if="renderEvent"
        :color='itemColor'
        :icon='cardIcon'
        fill-dot
    )
        ExpandableCardListItem(
            :color="itemColor"
            :content-right-top="event.friendly_event_timestamp_date"
            :content-right-bottom="event.friendly_event_timestamp_time"
            dense
            :expandable="canRenderCard"
            :is-expanded="event.show_card === true"
            :on-expand="onExpand"
            :title="cardTitle"
        )

            template(v-slot:body)
                v-card-text.white.text--primary(
                    v-if="renderCardBody"
                )
                    v-list.py-0(
                        v-if="event.eventable_type === 'Appointment'"
                        two-line
                    )
                        v-list-item
                            v-list-item-content
                                v-list-item-title.list-item-title-overflow
                                    CopyableText(:text="event.appointment_datetime")
                                v-list-item-subtitle Datetime

                        v-list-item
                            v-list-item-content
                                v-list-item-title
                                    CopyableText(
                                        v-if="event.appointment_number && event.appointment_number.length > 0"
                                        :text="event.appointment_number"
                                    )
                                    template(v-else) N/a
                                v-list-item-subtitle Number

                        v-list-item
                            v-list-item-content
                                v-list-item-title
                                    CopyableText(
                                        v-if="vehicleYearMakeModel && vehicleYearMakeModel.length > 0"
                                        :text="vehicleYearMakeModel"
                                    )
                                    template(v-else) N/a
                                v-list-item-subtitle Vehicle

                        v-list-item
                            v-list-item-content
                                v-list-item-title(:class="event.appointment_status | statusClassMatcher") {{ event.appointment_status  | titleize }}
                                v-list-item-subtitle Status

                    v-list.py-0(
                        v-if="event.eventable_type === 'Call'"
                        two-line
                    )
                        template(v-if="event.call_missed_call_purpose && event.call_missed_call_purpose.length > 0")
                            v-list-item
                                v-list-item-content
                                    v-list-item-title
                                        strong.text-uppercase.error--text {{ event.call_missed_call_purpose | titleize }}
                                    v-list-item-subtitle Missed Call

                        template(v-else)
                            v-list-item
                                v-list-item-content
                                    v-list-item-title
                                        template(v-if="event.call_user_full_name && event.call_user_full_name.length > 0") {{ event.call_user_full_name }}
                                        template(v-else) N/a
                                    v-list-item-subtitle Agent


                            v-list-item
                                v-list-item-content
                                    v-list-item-title
                                        template(v-if="event.call_disposition_description && event.call_disposition_description.length > 0") {{ event.call_disposition_description }}
                                        template(v-else) N/a
                                    v-list-item-subtitle Disposition

                        v-list-item
                            v-list-item-content
                                v-list-item-title
                                    template(v-if="event.call_duration > 0") {{ event.call_duration | friendlyDurationFormat }}
                                    template(v-else) N/a
                                v-list-item-subtitle Duration

                        v-list-item(v-if="canListenToRecording")
                            v-list-item-content
                                VolieAudio(
                                    v-if="validRecording"
                                    :file="event.call_recording_url"
                                    color="success"
                                    :downloadable="canDownloadRecording"
                                )
                                v-list-item-title(v-else) No recording available

                    v-list.py-0(
                        v-if="event.eventable_type === 'Email'"
                        two-line
                    )
                        v-list-item
                            v-list-item-content
                                v-list-item-title {{ event.email_subject }}
                                v-list-item-subtitle Subject

                        v-list-item
                            v-list-item-content
                                v-list-item-title {{ displayFrom(event.email_from_address, event.email_from_name) }}
                                v-list-item-subtitle From

                        v-list-item
                            v-list-item-content
                                v-list-item-title {{ event.email_to_recipients }}
                                v-list-item-subtitle To

                        v-list-item
                            v-list-item-content
                                v-list-item-title
                                    template(v-if="event.email_cc_recipients && event.email_cc_recipients.length > 0") {{ event.email_cc_recipients }}
                                    template(v-else) N/a
                                v-list-item-subtitle CC

                        v-list-item
                            v-list-item-content
                                v-list-item-title(:class="event.email_last_event | statusClassMatcher")
                                    template(v-if="event.email_last_event && event.email_last_event.length > 0") {{ event.email_last_event  | titleize }}
                                    template(v-else) N/a
                                v-list-item-subtitle Last Event

                        v-list-item(v-if="event.email_user_full_name && event.email_user_full_name.length > 0")
                            v-list-item-content
                                v-list-item-title {{ event.email_user_full_name }}
                                v-list-item-subtitle Sent By

                    v-container(v-else-if="event.eventable_type === 'Message'")
                        | {{ event.message_body }}
                        template(v-if="event.message_user_full_name && event.message_user_full_name.length > 0")
                            br
                            br
                            | Sent by {{ event.message_user_full_name }}

                    template(v-if="event.eventable_type === 'Prospect'")
                        v-list.py-0(
                            two-line
                        )
                            v-list-item
                                v-list-item-content
                                    v-list-item-title
                                        template(v-if="event.prospect_lead_key && event.prospect_lead_key.length > 0") {{ event.prospect_lead_key }}
                                        template(v-else) N/a
                                    v-list-item-subtitle Lead Key

                            v-list-item
                                v-list-item-content
                                    v-list-item-title
                                        template(v-if="event.prospect_lead_source && event.prospect_lead_source.length > 0") {{ event.prospect_lead_source }}
                                        template(v-else) N/a
                                    v-list-item-subtitle Lead Source

                            v-list-item
                                v-list-item-content
                                    v-list-item-title {{ event.prospect_friendly_request_date }}
                                    v-list-item-subtitle Request Date

                            v-list-item
                                v-list-item-content
                                    v-list-item-title
                                        strong.text-uppercase(v-if="event.prospect_current_lead_status && event.prospect_current_lead_status.length > 0" :class="event.prospect_current_lead_status | statusClassMatcher") {{ event.prospect_current_lead_status }}
                                        template(v-else) N/a
                                    v-list-item-subtitle Current Status

                            v-list-item
                                v-list-item-content
                                    v-list-item-title
                                        template(v-if="event.prospect_vehicles_interest && event.prospect_vehicles_interest.length > 0") {{ event.prospect_vehicles_interest }}
                                        template(v-else) None
                                    v-list-item-subtitle Vehicle of Interest

                        //- p.mx-4(v-if="event.prospect_comments && event.prospect_comments.length > 0") {{ event.prospect_comments }}

                    v-list.py-0(
                        v-if="event.eventable_type === 'Sale'"
                        two-line
                    )
                        v-list-item
                            v-list-item-content
                                v-list-item-title
                                    template(v-if="event.sale_friendly_deal_book_date && event.sale_friendly_deal_book_date.length > 0") {{ event.sale_friendly_deal_book_date }}
                                    template(v-else) N/a
                                v-list-item-subtitle Deal Book Date

                        v-list-item
                            v-list-item-content
                                v-list-item-title
                                    template(v-if="event.sale_deal_number && event.sale_deal_number.length > 0") {{ event.sale_deal_number }}
                                    template(v-else) N/a
                                v-list-item-subtitle Deal #

                        v-list-item
                            v-list-item-content
                                v-list-item-title
                                    template(v-if="event.sale_deal_type && event.sale_deal_type.length > 0") {{ event.sale_deal_type | titilize }}
                                    template(v-else) N/a
                                v-list-item-subtitle Deal Type

                        v-list-item(v-if="event.sale_term > 1")
                            v-list-item-content
                                v-list-item-title {{ event.sale_term | number }}
                                v-list-item-subtitle Term

                        v-list-item
                            v-list-item-content
                                v-list-item-title
                                    template(v-if="event.sale_salesman_one_name && event.sale_salesman_one_name.length > 0") {{ event.sale_salesman_one_name }}
                                    template(v-else) N/a
                                v-list-item-subtitle Salesman

                        v-list-item
                            v-list-item-content
                                v-list-item-title
                                    CopyableText(
                                        v-if="vehicleYearMakeModel && vehicleYearMakeModel.length > 0"
                                        :text="vehicleYearMakeModel"
                                    )
                                    template(v-else) N/a
                                v-list-item-subtitle Vehicle

                        v-list-item
                            v-list-item-content
                                v-list-item-title
                                    CopyableText(
                                        v-if="vehicleVin && vehicleVin.length > 0"
                                        :text="vehicleVin"
                                    )
                                    template(v-else) N/a
                                v-list-item-subtitle VIN

                    v-list.py-0(
                        v-if="event.eventable_type === 'Service'"
                        two-line
                    )
                        v-list-item(v-if="event.service_friendly_open_date && event.service_friendly_open_date.length > 0")
                            v-list-item-content
                                v-list-item-title {{ event.service_friendly_open_date }}
                                v-list-item-subtitle Open Date

                        v-list-item
                            v-list-item-content
                                v-list-item-title
                                    template(v-if="event.service_friendly_close_date && event.service_friendly_close_date.length > 0") {{ event.service_friendly_close_date }}
                                    template(v-else) N/a
                                v-list-item-subtitle Close Date

                        v-list-item
                            v-list-item-content
                                v-list-item-title
                                    template(v-if="event.service_ro_number && event.service_ro_number.length > 0") {{ event.service_ro_number }}
                                    template(v-else) N/a
                                v-list-item-subtitle RO #

                        v-list-item
                            v-list-item-content
                                v-list-item-title
                                    template(v-if="event.service_advisor_name && event.service_advisor_name.length > 0") {{ event.service_advisor_name }}
                                    template(v-else) N/a
                                v-list-item-subtitle Service Advisor

                        v-list-item
                            v-list-item-content
                                v-list-item-title
                                    CopyableText(
                                        v-if="vehicleYearMakeModel && vehicleYearMakeModel.length > 0"
                                        :text="vehicleYearMakeModel"
                                    )
                                    template(v-else) N/a
                                v-list-item-subtitle Vehicle

                        v-list-item
                            v-list-item-content
                                v-list-item-title
                                    CopyableText(
                                        v-if="vehicleVin && vehicleVin.length > 0"
                                        :text="vehicleVin"
                                    )
                                    template(v-else) N/a
                                v-list-item-subtitle VIN

                        v-list-item
                            v-list-item-content
                                v-list-item-title
                                    template(v-if="odometerOut && odometerOut > 0") {{ odometerOut | number }}
                                    template(v-else) N/a
                                v-list-item-subtitle Odometer

</template>

<script lang="ts">
    import Vue from 'vue';
    import VolieState  from "@/store";

    // Components
    import CopyableText from '@/components/shared/copyable_text.vue';
    import ExpandableCardListItem from '@/components/shared/expandable_card_list_item.vue';
    import VolieAudio from '@/components/shared/audio_player.vue';

    // Helpers
    import { concatVehicleYearMakeModel } from "@/helpers/general";
    import { resourceColorClass, resourceIcon, } from "@/helpers/css_classes";

    // Types
    import { VehicleYearMakeModelObj } from "@/helpers/general";

    export default Vue.extend({
        data() {
            return {
            }
        },
        methods: {
            displayFrom(fromEmail, fromName) {
                return fromName?.length > 0 ? fromName + " <" + fromEmail + ">" : fromEmail;
            },
            onExpand() {
                if (!this.validEvent) {
                    return;
                }

                this.showClick(this.event);
            },
        },
        computed: {
            currentUser () {
                return VolieState.currentUser();
            },
            canRenderCard() {
                return this.renderEvent && this.event?.eventable_type !== "Customer";
            },
            accountListenRecordingEnabled() {
                return this.currentUser?.account?.communication_center_recordings_listen_enabled === true;
            },
            accountDownloadRecordingEnabled() {
                return this.currentUser?.account?.communication_center_recordings_download_enabled === true;
            },
            canListenToRecording() {
                return this.source !== "communication_center" || (this.source === "communication_center" && this.accountListenRecordingEnabled === true);
            },
            canDownloadRecording() {
                return this.accountDownloadRecordingEnabled && this.canListenToRecording;
            },
            cardIcon() {
                return resourceIcon(this.event?.eventable_type);
            },
            cardTitle() {
                let title = null;
                switch(this.event?.eventable_type) {
                    // case "AdfEmail":
                    //     break;
                    case "Appointment":
                        title = "New Appointment";
                        break;
                    // case "AppointmentImport":
                    //     break;
                    case "Call":
                        switch(this.event?.call_direction) {
                            case "inbound":
                                title = "Inbound Call";
                                break;
                            case "outbound":
                                title = "Outbound Call";
                                break;
                            default:
                                title = "New Call";
                                break;
                        }
                        break;
                    // case "CallBack":
                    //     break;
                    case "CampaignCustomer":
                        break;
                    // case "CampaignProof":
                    //     break;
                    case "Customer":
                        title = "New Customer"
                        break;
                    // case "CustomerImport":
                    //     break;
                    // case "CustomerUpdateImport":
                    //     break;
                    // case "CustomField":
                    //     break;
                    case "Email":
                        switch(this.event?.email_direction) {
                            case "incoming":
                                title = "Incoming Email";
                                break;
                            case "outgoing":
                                title = "Outgoing Email";
                                break;
                            default:
                                title = "New Email";
                                break;
                        }

                        break;
                    case "Message":
                        switch(this.event?.message_direction) {
                            case "incoming":
                                // title = "Customer texted in";
                                title = "Incoming Message";
                                break;
                            case "outgoing":
                                // title = (this.event?.message_user_full_name || 'Agent') + " texted customer";
                                title = "Outgoing Message";
                                break;
                            default:
                                title = "New Message";
                                break;
                        }

                        break;
                    case "Prospect":
                        title = "New Lead";
                        break;
                    case "Sale":
                        title = "New Sale";
                        break;
                    // case "SaleImport":
                    //     break;
                    // case "ScheduledCommunication":
                    //     break;
                    case "Service":
                        title = "New Service";
                        break;
                    // case "ServiceImport":
                    //     break;
                    // case "TelematicAlert":
                    //     break;
                    // case "Webhook":
                    //     break;
                    // case "VinMatchImport":
                    //     break;
                }
                return title;
            },
            itemColor() {
                return resourceColorClass(this.event?.eventable_type);
            },
            odometerOut() {
                if (!(this.event?.service_odometer_out?.length > 0)) {
                    return null;
                }

                return parseInt(this.event?.service_odometer_out);
            },
            renderCardBody() {
                return this.canRenderCard && this.event?.show_card === true;
            },
            renderEvent() {
                return this.validEvent && (this.cardTitle || '') !== ''; // item title switches over the eventables we want to render
            },
            validEvent() {
                return this.event?.event_id > 0;
            },
            validRecording() {
                return this.event?.call_recording_url?.length > 0;
            },
            vehicleYearMakeModel() {
                if (!this.validEvent) {
                    return null;
                }

                const vehObj = {
                    vehicleModelYear: this.event.vehicle_model_year,
                    vehicleMake:      this.event.vehicle_make,
                    vehicleModel:     this.event.vehicle_model,
                }

                return concatVehicleYearMakeModel(vehObj as VehicleYearMakeModelObj);
            },
            vehicleVin() {
                return this.event?.vehicle_vin;
            }
        },
        components: {
            CopyableText,
            ExpandableCardListItem,
            VolieAudio,
        },
        props: {
            event: Object,
            showClick: Function,
            source: String,
        },
        created() {
        },
    });
</script>

<style lang="scss" scoped>
</style>
