<template lang="pug">
    v-card.mx-lg-auto.mt-md-4.mb-md-4(v-if="validProspectVehicle" outlined :width="cardWidth" style="margin: 10px 0px !important;")
        v-toolbar(flat)
            v-btn(
                color="primary"
                icon
                ripple
                @click="showDetails = !showDetails;"
            )
                v-icon(v-if="showDetails === true")
                    | mdi-chevron-down
                v-icon(v-else)
                    | mdi-chevron-right

            v-toolbar-title(style ="font-size: 14px;")
                | {{ vehicleYearMakeModel }}

        v-divider
        v-card-text(v-if="showDetails === true")
            v-simple-table
                template(v-slot:default)
                    tbody
                        tr
                            td
                                .text-capitalize.text-overline.text--secondary.text--darken-3 Vehicle Status
                                .cell-value-spacing(v-if="prospectVehicle.vehicle_status") {{ prospectVehicle.vehicle_status | titleize }}
                                .cell-value-spacing(v-else) N/a
                        tr
                            td
                                .text-capitalize.text-overline.text--secondary.text--darken-3 Vehicle Interest
                                .cell-value-spacing(v-if="prospectVehicle.vehicle_interest") {{ prospectVehicle.vehicle_interest | titleize }}
                                .cell-value-spacing(v-else) N/a
                        tr
                            td
                                .text-capitalize.text-overline.text--secondary.text--darken-3 Vehicle Model Year
                                .cell-value-spacing(v-if="prospectVehicle.vehicle_model_year && prospectVehicle.vehicle_model_year.length > 0") {{ prospectVehicle.vehicle_model_year }}
                                .cell-value-spacing(v-else) N/a
                        tr
                            td
                                .text-capitalize.text-overline.text--secondary.text--darken-3 Vehicle Make
                                .cell-value-spacing(v-if="prospectVehicle.vehicle_make && prospectVehicle.vehicle_make.length > 0") {{ prospectVehicle.vehicle_make }}
                                .cell-value-spacing(v-else) N/a
                        tr
                            td
                                .text-capitalize.text-overline.text--secondary.text--darken-3 Vehicle Model
                                .cell-value-spacing(v-if="prospectVehicle.vehicle_model && prospectVehicle.vehicle_model.length > 0") {{ prospectVehicle.vehicle_model }}
                                .cell-value-spacing(v-else) N/a
                        tr
                            td
                                .text-capitalize.text-overline.text--secondary.text--darken-3 Vehicle Odometer
                                .cell-value-spacing(v-if="prospectVehicle.vehicle_odometer && prospectVehicle.vehicle_odometer.length > 0") {{ prospectVehicle.vehicle_odometer }}
                                .cell-value-spacing(v-else) N/a
                        tr
                            td
                                .text-capitalize.text-overline.text--secondary.text--darken-3 Vehicle Trim
                                .cell-value-spacing(v-if="prospectVehicle.vehicle_trim && prospectVehicle.vehicle_trim.length > 0") {{ prospectVehicle.vehicle_trim }}
                                .cell-value-spacing(v-else) N/a
                        tr
                            td
                                .text-capitalize.text-overline.text--secondary.text--darken-3 Vehicle Body Style
                                .cell-value-spacing(v-if="prospectVehicle.vehicle_body_style && prospectVehicle.vehicle_body_style.length > 0") {{ prospectVehicle.vehicle_body_style }}
                                .cell-value-spacing(v-else) N/a
                        tr
                            td
                                .text-capitalize.text-overline.text--secondary.text--darken-3 Vehicle Vin
                                .cell-value-spacing(v-if="prospectVehicle.vehicle_vin && prospectVehicle.vehicle_vin.length > 0") {{ prospectVehicle.vehicle_vin }}
                                .cell-value-spacing(v-else) N/a
                        tr
                            td
                                .text-capitalize.text-overline.text--secondary.text--darken-3 Vehicle Exterior Color
                                .cell-value-spacing(v-if="prospectVehicle.vehicle_exterior_color && prospectVehicle.vehicle_exterior_color.length > 0") {{ prospectVehicle.vehicle_exterior_color }}
                                .cell-value-spacing(v-else) N/a
                        tr
                            td
                                .text-capitalize.text-overline.text--secondary.text--darken-3 Vehicle Stock Number
                                .cell-value-spacing(v-if="prospectVehicle.vehicle_stock_number && prospectVehicle.vehicle_stock_number.length > 0") {{ prospectVehicle.vehicle_stock_number }}
                                .cell-value-spacing(v-else) N/a
                        tr
                            td
                                .text-capitalize.text-overline.text--secondary.text--darken-3 Vehicle Vehicle Comments
                                .cell-value-spacing(v-if="prospectVehicle.vehicle_comments && prospectVehicle.vehicle_comments.length > 0") {{ prospectVehicle.vehicle_comments }}
                                .cell-value-spacing(v-else) N/a

</template>

<script lang="ts">
    import Vue from 'vue';

    // Helpers
    import { concatVehicleYearMakeModel } from "@/helpers/general";

    // Types
    import { VehicleYearMakeModelObj } from "@/helpers/general";

    export default Vue.extend({
        data() {
            return {
                cardWidth: 300,
				showDetails: false,
            }
        },
        methods: {
            handleDetailsResize() {
                const detailsWidth = document?.getElementById('prospect-details-card')?.getBoundingClientRect()?.width
                if (detailsWidth >= 375) {
                    this.cardWidth = detailsWidth - 75
                } else {
                    this.cardWidth = 300
                }
            },
        },
        computed: {
            validProspectVehicle() {
                return this.prospectVehicle?.prospect_vehicle_id > 0;
            },
            vehicleYearMakeModel() {
                if (!this.validProspectVehicle) {
                    return null;
                }

                const vehObj = {
                    vehicleModelYear: this.prospectVehicle.vehicle_model_year,
                    vehicleMake:      this.prospectVehicle.vehicle_make,
                    vehicleModel:     this.prospectVehicle.vehicle_model,
                }

                return concatVehicleYearMakeModel(vehObj as VehicleYearMakeModelObj);
            },
        },
        props: {
            prospectVehicle: Object,
        },
        watch: {
            prospectVehicle: {
                handler() {
                    Vue.nextTick(() => { this.handleDetailsResize(); });
                },
                deep: true,
            },
        },
        created() {
			window.addEventListener('resize', this.handleDetailsResize);
        },
        destroyed() {
            window.removeEventListener('resize', this.handleDetailsResize);
        },
        mounted() {
            this.handleDetailsResize();
        },
    });
</script>

<style lang="scss" scoped>
    #app {
        .cell-value-spacing {
            padding: 0 0 5px 0;
        }
    }
</style>
