<template lang="pug">
    v-card.mx-lg-auto.mt-md-4.mb-md-4(v-if="validProspectEmailAddress" outlined :width="cardWidth" style="margin: 10px 0px !important;")
        v-toolbar(flat)
            v-btn(
                color="primary"
                icon
                ripple
                @click="showDetails = !showDetails;"
            )
                v-icon(v-if="showDetails === true")
                    | mdi-chevron-down
                v-icon(v-else)
                    | mdi-chevron-right

            v-toolbar-title(style ="font-size: 14px;")
                | {{ prospectEmailAddress.email }}
        v-divider
        v-card-text(v-if="showDetails === true")
            v-simple-table
                template(v-slot:default)
                    tbody
                        tr
                            td
                                .text-capitalize.text-overline.text--secondary.text--darken-3 Email Address
                                .cell-value-spacing(v-if="prospectEmailAddress.email && prospectEmailAddress.email.length > 0") {{ prospectEmailAddress.email }}
                                .cell-value-spacing(v-else) N/a
                        tr
                            td
                                .text-capitalize.text-overline.text--secondary.text--darken-3 Email Address Type
                                .cell-value-spacing(v-if="prospectEmailAddress.email_address_type && prospectEmailAddress.email_address_type.length > 0") {{ prospectEmailAddress.email_address_type | titleize }}
                                .cell-value-spacing(v-else) N/a
                        tr
                            td
                                .text-capitalize.text-overline.text--secondary.text--darken-3 Do Not Email
                                .cell-value-spacing(v-if="!prospectEmailAddress.do_not_email")
                                    v-icon(color="error") mdi-email-off
                                .cell-value-spacing(v-else) N/a

</template>

<script lang="ts">
    import Vue from 'vue';

    export default Vue.extend({
        data() {
            return {
                cardWidth: 300,
				showDetails: false,
            }
        },
        methods: {
            handleDetailsResize() {
                const detailsWidth = document?.getElementById('prospect-details-card')?.getBoundingClientRect()?.width
                if (detailsWidth >= 375) {
                    this.cardWidth = detailsWidth - 75
                } else {
                    this.cardWidth = 300
                }
            },
        },
        computed: {
            validProspectEmailAddress() {
                return this.prospectEmailAddress?.prospect_email_address_id > 0;
            },
        },
        props: {
            prospectEmailAddress: Object,
        },
        watch: {
            prospectEmailAddress: {
                handler() {
                    Vue.nextTick(() => { this.handleDetailsResize(); });
                },
                deep: true,
            },
        },
        created() {
			window.addEventListener('resize', this.handleDetailsResize);
        },
        destroyed() {
            window.removeEventListener('resize', this.handleDetailsResize);
        },
        mounted() {
            this.handleDetailsResize();
        },
    });
</script>

<style lang="scss" scoped>
    #app {
        .cell-value-spacing {
            padding: 0 0 5px 0;
        }
    }
</style>
