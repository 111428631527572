import { render, staticRenderFns } from "./floating_close_button.vue?vue&type=template&id=2d1a2c86&scoped=true&lang=pug"
import script from "./floating_close_button.vue?vue&type=script&lang=ts"
export * from "./floating_close_button.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2d1a2c86",
  null
  
)

export default component.exports