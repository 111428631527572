<template lang="pug">
    div
        v-container.mx-auto
            v-card.mx-lg-auto(width="100%")

                v-simple-table.table-x-scroll
                    template(v-slot:default)
                        thead.volie-background-color-blue(style="padding-top: 15px;")
                            tr
                                th.white--text Datetime
                                th.white--text Call Status
                                th.white--text Call Duration
                                th.white--text Agent
                        tbody(v-if="validCallSteps")
                            tr(v-for='callStep in conference.call_steps' :key='callStep.call_step_id')
                                td.no-wrap.sm-width {{ callStep.friendly_created_at_time }}
                                td.no-wrap.sm-width {{ callStep.call_status | titleize }}
                                td.no-wrap.sm-width {{ renderDuration(callStep.call_duration) }}
                                td.no-wrap.sm-width
                                    template(v-if="callStep.user_display_name")
                                        StrLimitTooltipText(:text="callStep.user_display_name" :char-limit="25")
                                    template(v-else) ---

                        tbody(v-else-if="isLoading")
                            tr
                                td(colspan="4")
                                    .text-center
                                        v-progress-circular(
                                            :size='100'
                                            :width='10'
                                            color='#0f5b9a'
                                            indeterminate
                                            style="margin-top: 75px; margin-bottom: 75px;"
                                        )

            v-alert(v-if="!validCallSteps && !isLoading" outlined type='info' dense border='left' style="margin-top: 16px;")
                | There are no call steps for this conference

                        

</template>

<script lang="ts">
    import Vue from 'vue';

    // Components
    import StrLimitTooltipText from "@/components/shared/str_limit_tooltip_text.vue";    


    // Helpers
    import { friendlyDurationFormat } from "@/helpers/general";

    export default Vue.extend({
        computed: {
            validCallSteps() {
                return this.conference?.call_steps?.length > 0;
            },
        },
        methods: {
            renderDuration(duration) {
                return friendlyDurationFormat(duration);
            },
        },
        props: {
            conference: Object,
            isLoading: Boolean,
        },
        components: {
            StrLimitTooltipText,
        },
    });
</script>

<style lang="scss" scoped>
</style>