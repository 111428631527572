<template lang="pug">
    div.mx-lg-auto(width="100%")

        v-card-text
            v-simple-table
                template(v-slot:default)
                    tbody(v-if='validConference')
                        tr
                            td(colspan="2")
                                .text-capitalize.text-overline.text--secondary.text--darken-3 Recording
                                VuetifyAudio(
                                    v-if="recordingUrl"
                                    :file="recordingUrl"
                                    color="success"
                                    :downloadable="!hideDownloadButtons"
                                    style="margin-bottom: 15px;"
                                )
                                template(v-else) ---

                        tr
                            td(style="max-width: 50%; width: 50%;")
                                .text-capitalize.text-overline.text--secondary.text--darken-3 Conference Key
                                template(v-if="linkToConference === true && !hideDownloadButtons")
                                    router-link(:to="{ name: 'conference_show', params: { conference_key: conference.conference_key } }") {{ conference.conference_key }}
                                template(v-else) {{ conference.conference_key }}
                            td(style="max-width: 50%; width: 50%;")
                                .text-capitalize.text-overline.text--secondary.text--darken-3 Call Key
                                | {{ conference.call_key ? conference.call_key : '---' }}

                        tr
                            td(style="max-width: 50%; width: 50%;")
                                .text-capitalize.text-overline.text--secondary.text--darken-3 Campaign Customer Key
                                | {{ conference.campaign_customer_key ? conference.campaign_customer_key : '---' }}
                            td(style="max-width: 50%; width: 50%;")
                                .text-capitalize.text-overline.text--secondary.text--darken-3 Survey Attempt Key
                                | {{ conference.survey_attempt_key ? conference.survey_attempt_key : '---' }}

                        tr
                            td(style="max-width: 50%; width: 50%;")
                                .text-capitalize.text-overline.text--secondary.text--darken-3 Start Time
                                | {{ conference.friendly_start_time }}
                            td(style="max-width: 50%; width: 50%;")
                                .text-capitalize.text-overline.text--secondary.text--darken-3 Duration
                                | {{ renderDuration(conference.duration) }}

                        tr
                            td(style="max-width: 50%; width: 50%;")
                                .text-capitalize.text-overline.text--secondary.text--darken-3 Organization
                                | {{ conference.organization_name ? conference.organization_name : '---' }}
                            td(style="max-width: 50%; width: 50%;")
                                .text-capitalize.text-overline.text--secondary.text--darken-3 Campaign
                                | {{ conference.campaign_name ? conference.campaign_name : '---' }}

                        tr
                            td(style="max-width: 50%; width: 50%;")
                                .text-capitalize.text-overline.text--secondary.text--darken-3 Customer
                                | {{ conference.customer_full_name ? conference.customer_full_name : '---' }}
                            td(style="max-width: 50%; width: 50%;")
                                .text-capitalize.text-overline.text--secondary.text--darken-3 Agent
                                | {{ conference.user_full_name ? conference.user_full_name : '---' }}

                        tr
                            td(style="max-width: 50%; width: 50%;")
                                .text-capitalize.text-overline.text--secondary.text--darken-3 Disposition
                                | {{ conference.disposition_description ? conference.disposition_description : '---' }}
                            td(style="max-width: 50%; width: 50%;")
                                .text-capitalize.text-overline.text--secondary.text--darken-3 Direction
                                | {{ conference.friendly_direction ? conference.friendly_direction : '---' | titleize }}

                        tr
                            td(style="max-width: 50%; width: 50%;")
                                .text-capitalize.text-overline.text--secondary.text--darken-3 To Number
                                template(v-if="conference.friendly_to_number") {{ conference.friendly_to_number | tel }}
                                template(v-else) ---
                            td(style="max-width: 50%; width: 50%;")
                                .text-capitalize.text-overline.text--secondary.text--darken-3 From Number
                                template(v-if="conference.friendly_from_number") {{ conference.friendly_from_number | tel }}
                                template(v-else) ---

                        tr
                            td(style="max-width: 50%; width: 50%;")
                                .text-capitalize.text-overline.text--secondary.text--darken-3 Notes
                                | {{ conference.survey_attempt_notes ? conference.survey_attempt_notes : '---' }}
                            td(style="max-width: 50%; width: 50%;")
                                .text-capitalize.text-overline.text--secondary.text--darken-3 SID
                                | {{ conference.conference_sid ? conference.conference_sid : '---' }}

                        tr
                            td(style="max-width: 50%; width: 50%;")
                                .text-capitalize.text-overline.text--secondary.text--darken-3 Duration
                                template(v-if="conference.duration") {{ conference.duration | friendlyDurationFormat }}
                                template(v-else) ---
                            td(style="max-width: 50%; width: 50%;")
                                .text-capitalize.text-overline.text--secondary.text--darken-3 Hold Duration
                                template(v-if="conference.hold_duration") {{ conference.hold_duration | friendlyDurationFormat }}
                                template(v-else) ---

                        tr
                            td(style="max-width: 50%; width: 50%;")
                                .text-capitalize.text-overline.text--secondary.text--darken-3 Agent Talk Duration
                                template(v-if="conference.agent_talk_duration") {{ conference.agent_talk_duration | friendlyDurationFormat }}
                                template(v-else) ---
                            td(style="max-width: 50%; width: 50%;")
                                .text-capitalize.text-overline.text--secondary.text--darken-3 Wrap Up Duration
                                template(v-if="conference.wrap_up_duration") {{ conference.wrap_up_duration | friendlyDurationFormat }}
                                template(v-else) ---

                        tr
                            td(style="max-width: 50%; width: 50%;")
                                .text-capitalize.text-overline.text--secondary.text--darken-3 Processed?
                                v-icon.green--text(v-if="conference.is_complete") mdi-check
                                v-icon.error--text(v-else) mdi-close
                            td(style="max-width: 50%; width: 50%;")
                                .text-capitalize.text-overline.text--secondary.text--darken-3 Process Attempts
                                | {{ conference.process_attempts }}

                        tr
                            td(style="max-width: 50%; width: 50%;")
                                .text-capitalize.text-overline.text--secondary.text--darken-3 Agent Dropped?
                                v-icon.green--text(v-if="conference.is_dropped") mdi-check
                                v-icon.error--text(v-else) mdi-close
                            td(style="max-width: 50%; width: 50%;")
                                .text-capitalize.text-overline.text--secondary.text--darken-3 Appointment?
                                v-icon.green--text(v-if="conference.is_appointment") mdi-check
                                v-icon.error--text(v-else) mdi-close

                        tr
                            td(style="max-width: 50%; width: 50%;")
                                .text-capitalize.text-overline.text--secondary.text--darken-3 Bad Number?
                                v-icon.green--text(v-if="conference.is_bad_number") mdi-check
                                v-icon.error--text(v-else) mdi-close
                            td(style="max-width: 50%; width: 50%;")
                                .text-capitalize.text-overline.text--secondary.text--darken-3 Call Disconnect?
                                v-icon.green--text(v-if="conference.is_call_disconnect") mdi-check
                                v-icon.error--text(v-else) mdi-close

                        tr
                            td(colspan="2")
                                .text-capitalize.text-overline.text--secondary.text--darken-3 Survey
                                .cell-value-spacing(v-if="validSurveyId")
                                    v-btn(v-if="!showSurvey" text color='primary' plain small @click="getSurveyAnswers()")
                                        | Show Survey
                                    v-progress-circular(
                                        v-else-if="isLoadingSurvey"
                                        :size='25'
                                        :width='3'
                                        color='#0f5b9a'
                                        indeterminate
                                    )
                                    SurveyAnswer(
                                        v-else-if="validSurveyAnswers"
                                        v-for='(surveyAnswer, i) in surveyAnswers'
                                        :key='i'
                                        :survey-answer="surveyAnswer"
                                    )
                                .cell-value-spacing(v-else) N/a

                        tr
                            td(style="max-width: 50%; width: 50%;")
                                .text-capitalize.text-overline.text--secondary.text--darken-3 Customer Completed?
                                v-icon.green--text(v-if="conference.campaign_customer_completed") mdi-check
                                v-icon.error--text(v-else) mdi-close
                            td(style="max-width: 50%; width: 50%;")
                                .text-capitalize.text-overline.text--secondary.text--darken-3 Customer Completion Code
                                template(v-if="conference.campaign_customer_completion_code === 1") Final Disposition
                                template(v-else-if="conference.campaign_customer_completion_code === 2") Max Attempts
                                template(v-else-if="conference.campaign_customer_completion_code === 3") Voicemail One and Done
                                template(v-else) ---

                        tr
                            td(style="max-width: 50%; width: 50%;")
                                .text-capitalize.text-overline.text--secondary.text--darken-3 Customer Contact?
                                v-icon.green--text(v-if="conference.is_contact") mdi-check
                                v-icon.error--text(v-else) mdi-close
                            td(style="max-width: 50%; width: 50%;")
                                .text-capitalize.text-overline.text--secondary.text--darken-3 Customer Dropped?
                                v-icon.green--text(v-if="conference.customer_dropped") mdi-check
                                v-icon.error--text(v-else) mdi-close

                        tr
                            td(style="max-width: 50%; width: 50%;")
                                .text-capitalize.text-overline.text--secondary.text--darken-3 Customer Unreachable?
                                v-icon.green--text(v-if="conference.is_unreachable") mdi-check
                                v-icon.error--text(v-else) mdi-close
                            td(style="max-width: 50%; width: 50%;")
                                .text-capitalize.text-overline.text--secondary.text--darken-3 Hot Alert?
                                v-icon.green--text(v-if="conference.is_hot_alert") mdi-check
                                v-icon.error--text(v-else) mdi-close

                        tr
                            td(style="max-width: 50%; width: 50%;")
                                .text-capitalize.text-overline.text--secondary.text--darken-3 Manual Call?
                                v-icon.green--text(v-if="conference.is_manual_call") mdi-check
                                v-icon.error--text(v-else) mdi-close
                            td(style="max-width: 50%; width: 50%;")
                                .text-capitalize.text-overline.text--secondary.text--darken-3 Survey?
                                v-icon.green--text(v-if="conference.is_survey") mdi-check
                                v-icon.error--text(v-else) mdi-close

                        tr
                            td(style="max-width: 50%; width: 50%;")
                                .text-capitalize.text-overline.text--secondary.text--darken-3 Test Call?
                                v-icon.green--text(v-if="conference.is_test_call") mdi-check
                                v-icon.error--text(v-else) mdi-close
                            td(style="max-width: 50%; width: 50%;")
                                .text-capitalize.text-overline.text--secondary.text--darken-3 Voicemail?
                                v-icon.green--text(v-if="conference.is_voice_mail_recording") mdi-check
                                v-icon.error--text(v-else) mdi-close

                        tr
                            td(colspan="2")
                                .text-capitalize.text-overline.text--secondary.text--darken-3 Max Hold Duration Redirect?
                                v-icon.green--text(v-if="conference.is_max_hold_duration_redirect") mdi-check
                                v-icon.error--text(v-else) mdi-close

                    tbody(v-else-if='isLoading')
                        tr
                            .text-center
                                v-progress-circular(
                                    :size='100'
                                    :width='10'
                                    color='#0f5b9a'
                                    indeterminate
                                    style="margin-top: 75px; margin-bottom: 75px;"
                                )

                    v-alert(v-else outlined type='info' dense border='left' style="margin-top: 16px;")
                        | Unable to find any conference details

</template>

<script lang="ts">
    import Vue from 'vue';
    import VolieAudio from '@/components/shared/audio_player.vue';

    // Components
    import SurveyAnswer from '@/components/survey_answers/survey_answer.vue';

    // Data
    import { GetSurveyAnswers } from "@/data";

    // Helpers
    import { friendlyDurationFormat } from "@/helpers/general";

    export default Vue.extend({
        data() {
            return {
                isLoadingSurvey: false,
                showSurvey: false,
                surveyAnswers: [],
            }
        },
        methods: {
            getSurveyAnswers() {
                if (!this.validSurveyId) {
                    return;
                }

                this.showSurvey = true;
                this.isLoadingSurvey = true;
                GetSurveyAnswers(this.conference.survey_id).then((response) => {
                    if (response?.Data?.survey_answers?.length > 0) {
                        this.surveyAnswers = response.Data.survey_answers;
                    }

                    this.isLoadingSurvey = false;
                });
            },
            renderDuration(duration) {
                return friendlyDurationFormat(duration);
            },
        },
        computed: {
            recordingUrl() {
                return this.conference?.recording_url;
            },
            validConference() {
                return this.conference?.conference_id > 0;
            },
            validSurveyAnswers() {
                return this.surveyAnswers?.length > 0;
            },
            validSurveyId() {
                return this.conference?.survey_id > 0;
            },
        },
        components: {
            SurveyAnswer,
            VolieAudio,
        },
        props: {
            conference: Object,
            hideDownloadButtons: Boolean,
            isLoading: Boolean,
            linkToConference: Boolean,
        },
    });
</script>

<style lang="scss" scoped>

</style>
