import { get, post, destroy } from '.';

export function GetCampaignTypeTeams(options = {}) {
    return get<any>("/app/api/campaign_type_teams", options);
}

export function GetAvailableCampaignTypeTeams(options = {}) {
    return get<any>("/app/api/campaign_type_teams_available", options);
}

export function CreateCampaignTypeTeams(id, options = {}) {
    return post<any>("/app/api/campaign_types/" + (id || 0).toString() + "/teams", options);
}

export function DestroyCampaignTypeTeam(id) {
    return destroy<any>("/app/api/campaign_type_teams/" + (id || 0).toString());
}

export function DestroyAllCampaignTypeTeams(id) {
    return destroy<any>("/app/api/campaign_type_teams_all/" + (id || 0).toString());
}