<template lang="pug">
    div.width-100-percent.height-100-percent
        v-layout.width-100-percent
            v-row(no-gutters)
                v-col.col-right-divider(cols='4' :style="'max-width: ' + colLeftWidth + 'px;'")
                    v-tabs(grow)
                        v-tab(disabled style="opacity: 1;") Web Rtc Session
                    v-simple-table
                        template(v-slot:default)
                            tbody(v-if="validWebRtcSession")
                                tr
                                    td
                                        .text-capitalize.text-overline.text--secondary.text--darken-3 Vid
                                        CopyableText(:text="webRtcSession.vid")
                                tr
                                    td
                                        .text-capitalize.text-overline.text--secondary.text--darken-3 Sid
                                        template(v-if="webRtcSession.sid")
                                            CopyableText(:text="webRtcSession.sid")
                                        template(v-else) ---
                                tr
                                    td
                                        .text-capitalize.text-overline.text--secondary.text--darken-3 Status
                                        | {{ webRtcSession.status | titleize }}

                    .text-center(v-if="isLoading")
                        v-progress-circular(
                            :size='100'
                            :width='10'
                            color='#0f5b9a'
                            indeterminate
                            style="margin-top: 75px; margin-bottom: 75px;"
                        )

                    v-alert(v-if="!validWebRtcSession && !isLoading" outlined type='info' dense border='left' style="margin-top: 16px;")
                        | There is no web rtc session for this conference

                v-col.col-left-divider(cols='8' :style="'max-width: ' + colRightWidth + 'px;'")
                    v-tabs(grow)
                        v-tab(disabled style="opacity: 1;") Web Rtc Participants
                    v-simple-table.table-x-scroll(v-if="validWebRtcParticipants && !isLoading")
                        template(v-slot:default)
                            thead.volie-background-color-blue
                                tr
                                    th.white--text Participant Type
                                    th.white--text Participant
                                    th.white--text Status
                                    th.white--text Vid
                                    th.white--text Sid
                                    th.white--text Call Vid
                            tbody
                                tr(v-for='webRtcParticipant in webRtcParticipants' :key='webRtcParticipant.vid')
                                    td.no-wrap.sm-width
                                        template(v-if="webRtcParticipant.participant_type")
                                            | {{ webRtcParticipant.participant_type | titleize }}
                                        template(v-else) ---
                                    td.no-wrap.xs-width
                                        template(v-if="webRtcParticipant.participant")
                                            StrLimitTooltipText(:text="webRtcParticipant.participant" :char-limit="25")
                                        template(v-else) ---
                                    td.no-wrap.xs-width
                                        | {{ webRtcParticipant.status | titleize }}
                                    td.no-wrap.xs-width
                                        CopyableText(:text="webRtcParticipant.vid")
                                    td.no-wrap.xs-width
                                        template(v-if="webRtcParticipant.sid")
                                            CopyableText(:text="webRtcParticipant.sid")
                                        template(v-else) ---
                                    td.no-wrap.xs-width
                                        template(v-if="webRtcParticipant.call_vid")
                                            CopyableText(:text="webRtcParticipant.call_vid")
                                        template(v-else) ---
                                
                    .text-center(v-if="isLoading")
                        v-progress-circular(
                            :size='100'
                            :width='10'
                            color='#0f5b9a'
                            indeterminate
                            style="margin-top: 75px; margin-bottom: 75px;"
                        )

                    v-alert(v-if="!validWebRtcParticipants && !isLoading" outlined type='info' dense border='left' style="margin-top: 16px;")
                        | There are no web rtc participants for this conference

</template>

<script lang="ts">
    import Vue from 'vue';

    // Components
    import CopyableText from '@/components/shared/copyable_text.vue';
    import StrLimitTooltipText from "@/components/shared/str_limit_tooltip_text.vue";    

    export default Vue.extend({
        data() {
            return {
                colLeftWidth: 250,
                colRightWidth: 500,
            }
        },
        methods: {
            handleWindowResize() {
                const container = document?.getElementById('web-rtc-container')?.getBoundingClientRect()?.width;

                this.colLeftWidth = .33 * container;
                this.colRightWidth = .66 * container;
            },
        },
        computed: {
            validWebRtcSession() {
                return this.webRtcSession?.vid?.length > 0;
            },
            validWebRtcParticipants() {
                return this.webRtcSession?.web_rtc_participants?.length > 0;
            },
            webRtcParticipants() {
                if (!this.validWebRtcParticipants) {
                    return [];
                }

                return this.webRtcSession.web_rtc_participants;
            },
        },
        props: {
            isLoading: Boolean,
            webRtcSession: Object,
        },
        components: {
            CopyableText,
            StrLimitTooltipText,
        },
        watch: {
            webRtcSession() {
                this.handleWindowResize();
            },
        },
        created() {
            window.addEventListener('resize', this.handleWindowResize);
        },
        mounted() {
            this.handleWindowResize();
        },
        destroyed() {
            window.removeEventListener('resize', this.handleWindowResize);
        },
    });
</script>

<style lang="scss" scoped>

    #app {
        .col-right-divider {
            border-right: 1px solid #F5F5F5;
            margin-top: 1%;
            margin-bottom: 2%;
            padding: 10px;
        }

        .col-left-divider {
            border-left: 1px solid #F5F5F5;
            margin-top: 1%;
            margin-bottom: 2%;
            padding: 10px;
        }
    }

</style>
