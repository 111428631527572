<template lang="pug">
    v-simple-table.table-x-scroll(v-if="validScheduledCommunications" style="width: 100%;")
        template(v-slot:default)
            thead
                tr
                    th(v-if="!hideCreateUpdateDestroyButtons")
                    th(v-if="!isOrganizationView")
                    th(v-if="isOrganizationView") Organization
                    th Campaign
                    th(v-if="isOrganizationView") Customer
                    th Scheduled Date / Time
                    th Communication
                    th Assigned To
                    th Notes
                    th(v-if="isOrganizationView")
                    th(v-if="isOrganizationView")
            tbody
                tr(
                    v-for='(scheduledCommunication, index) in scheduledCommunications'
                    :key='scheduledCommunication.scheduled_communication_id'
                )
                    td(v-if="isOrganizationView").no-wrap.xs-xs-width.text-center
                        | {{ index + 1 }}
                    td(v-else-if="!hideCreateUpdateDestroyButtons").no-wrap.sm-width.text-center
                        v-btn(
                            color="error"
                            :disabled="scheduledCommunication.status !== 'scheduled'"
                            ripple
                            small
                            @click="openDialogArchive(scheduledCommunication);"
                        )
                            v-icon mdi-delete
                            | Cancel
                    td(v-if="!isOrganizationView").no-wrap.sm-width.text-center
                        template(v-if="scheduledCommunication.status === 'scheduled'")
                            v-icon.info--text mdi-clock-outline
                            strong.info--text Scheduled
                        template(v-else-if="scheduledCommunication.status === 'in_progress'")
                            v-icon.warning--text mdi-microphone
                            strong.warning--text In Progress
                        template(v-else-if="scheduledCommunication.status === 'completed'")
                            v-icon.green--text mdi-check
                            strong.green--text Completed
                        template(v-else-if="scheduledCommunication.status === 'canceled'")
                            v-icon.error--text mdi-close
                            strong.error--text Canceled
                        template(v-else-if="scheduledCommunication.status === 'error'")
                            v-icon.green--text mdi-alert-circle-outline
                            strong.error--text Error
                    td(v-if="isOrganizationView").no-wrap.sm-width
                        v-tooltip(top)
                            template(v-slot:activator='{ on, attrs }')
                                span(v-bind="attrs" v-on="on" role="text")
                                    | {{ scheduledCommunication.organization_name | str_limit(35) }}
                            span
                                | {{ scheduledCommunication.organization_name }}

                    td.no-wrap.sm-width
                        v-tooltip(top)
                            template(v-slot:activator='{ on, attrs }')
                                span(v-bind="attrs" v-on="on" role="text")
                                    | {{ scheduledCommunication.campaign_name | str_limit(35) }}
                            span
                                | {{ scheduledCommunication.campaign_name }}

                    td(v-if="isOrganizationView").no-wrap.sm-width
                        template(v-if="scheduledCommunication.customer_full_name && scheduledCommunication.customer_full_name.length > 0") {{ scheduledCommunication.customer_full_name }}
                        template(v-else) ---

                    td.no-wrap.sm-width {{ scheduledCommunication.friendly_scheduled_datetime }}
                    td.no-wrap.sm-width {{ scheduledCommunication.communication_type | titleize }}
                    td.no-wrap.sm-width
                        template(v-if="scheduledCommunication.assignment_type === 'team'") (Team) {{ scheduledCommunication.assignment_name }}
                        template(v-else-if="scheduledCommunication.assignment_type === 'user'") {{ scheduledCommunication.assignment_name }}
                        template(v-else) ---

                    td.no-wrap.sm-width
                        v-tooltip(top v-if="scheduledCommunication.notes && scheduledCommunication.notes.length > 0")
                            template(v-slot:activator='{ on, attrs }')
                                span(v-bind="attrs" v-on="on" role="text")
                                    | {{ scheduledCommunication.notes | str_limit(35) }}
                            span
                                | {{ scheduledCommunication.notes }}
                        template(v-else) ---

                    td(v-if="isOrganizationView").no-wrap.sm-width.text-center
                        template(v-if="scheduledCommunication.status === 'scheduled'")
                            v-icon.info--text mdi-clock-outline
                            strong.info--text Scheduled
                        template(v-else-if="scheduledCommunication.status === 'in_progress'")
                            v-icon.warning--text mdi-microphone
                            strong.warning--text In Progress
                        template(v-else-if="scheduledCommunication.status === 'completed'")
                            v-icon.green--text mdi-check
                            strong.green--text Completed
                        template(v-else-if="scheduledCommunication.status === 'canceled'")
                            v-icon.error--text mdi-close
                            strong.error--text Canceled
                        template(v-else-if="scheduledCommunication.status === 'error'")
                            v-icon.green--text mdi-alert-circle-outline
                            strong.error--text Error

                    td(v-if="isOrganizationView && !hideCreateUpdateDestroyButtons").no-wrap.sm-width.text-center
                        v-btn(
                            color="error"
                            :disabled="scheduledCommunication.status !== 'scheduled'"
                            ripple
                            small
                            @click="openDialogArchive(scheduledCommunication);"
                        )
                            v-icon mdi-delete
                            | Cancel

                ShowMoreRow(
                    :colspan='columnAmount'
                    :data-length='scheduledCommunications.length'
                    :handle-show-more="handleShowMore"
                    :limit='limit'
                    :show-moreable="showMoreable"
                    :splices="scheduledCommunicationsSplices"
                )
</template>

<script lang="ts">
import Vue from 'vue';

// Components
import ShowMoreRow from "@/components/shared/show_more_row.vue";

export default Vue.extend({
    data() {
        return {

        }
    },
    methods: {

    },
    computed: {
        columnAmount(){
            if(this.isOrganizationView && !this.hideCreateUpdateDestroyButtons) {
                return 10
            }
            if(!this.isOrganizationView && !this.hideCreateUpdateDestroyButtons) {
                return 7
            }
            if(!this.isOrganizationView && this.hideCreateUpdateDestroyButtons) {
                return 6
            }

            return 10
        }
    },
    components: {
        ShowMoreRow
    },
    props: {
        closeDialogArchive: Function,
        handleShowMore: Function,
        hideCreateUpdateDestroyButtons: Boolean,
        isOrganizationView: Boolean,
        limit: Number,
        scheduledCommunications: Array,
        scheduledCommunicationsSplices: Number,
        showMoreable: Boolean,
        openDialogArchive: Function,
        validScheduledCommunications: Boolean,
    },
    watch: {

    },
    created() {
    },
    destroyed() {
    },
    mounted() {
    },
})
</script>

<style lang="scss" scoped>

</style>