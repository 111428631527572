<template lang="pug">
    v-app
        VolieMainTopNav

        //- Render component from shared link if valid
        #shared-link-app-body(v-if="validSharedLink" style="margin-top: 64px;")
            PasswordForm(
                v-if="requiresPassword && !validatedPassword"
                :shared-link="sharedLink"
                :validate-password-success="validatePasswordSuccess"
            )
            template(v-else)
                CustomerProfile(
                    v-if="renderCustomerProfile"
                    :customer-id="sharedLink.shareable_id"
                    :hide-create-update-destroy-buttons="true"
                    source="shared_link"
                )
                ConferenceShowFull(
                    v-if="renderConferenceShowFull"
                    :conference-vid="sharedLink.shareable_vid"
                    :hide-create-update-destroy-buttons="true"
                    :hide-download-buttons="hideDownloadButtons"
                )
                EmailShow(
                    v-if="renderEmailShow"
                    :email-key="sharedLink.shareable_vid"
                    :hide-download-buttons="hideDownloadButtons"
                )
                TicketShow(
                    v-if="renderTicketShow"
                    :ticket-key="sharedLink.shareable_vid"
                    :hide-download-buttons="hideDownloadButtons"
                    source="shared_link"
                )

</template>

<script lang="ts">
    import Vue from 'vue';
    import VolieState from '@/store';

    // Components
    import ConferenceShowFull from '@/components/conferences/show.vue';
    import CustomerProfile from '@/components/customers/base_customer_profile.vue';
    import EmailShow from "@/components/emails/show.vue";
    import PasswordForm from '@/components/shared_links/password_form.vue';
    import TicketShow from "@/components/tickets/show.vue";
    import VolieMainTopNav from '@/components/shared/volie_main_top_nav.vue';

    export default Vue.extend({
        name: 'App',
        data() {
            return {
                validatedPassword: false,
            }
        },
        methods: {
            validatePasswordSuccess() {
                this.validatedPassword = true;
            },
        },
        computed: {
            renderConferenceShowFull() {
                return this.sharedLink?.component === "conference_show_full" && this.sharedLink?.shareable_vid?.length > 0 && this.sharedLink?.shareable_type === 'conference';
            },
            renderCustomerProfile() {
                return this.sharedLink?.component === "customer_profile" && this.sharedLink?.shareable_id > 0 && this.sharedLink?.shareable_type === 'customer';
            },
            renderEmailShow() {
                return this.sharedLink?.component === "email_show" && this.sharedLink?.shareable_id > 0 && this.sharedLink?.shareable_type === 'email';
            },
            renderTicketShow() {
                return this.sharedLink?.component === "ticket_show" && this.sharedLink?.shareable_id > 0 && this.sharedLink?.shareable_type === 'ticket';
            },
            requiresPassword() {
                return this.sharedLink?.access === "password";
            },
            sharedLink() {
                return VolieState.sharedLink();
            },
            hideDownloadButtons() {
                return this.sharedLink?.downloads !== "enabled" || true;
            },
            validSharedLink() {
                return this.sharedLink?.id > 0;
            },
        },
        components: {
            ConferenceShowFull,
            CustomerProfile,
            EmailShow,
            PasswordForm,
            TicketShow,
            VolieMainTopNav,
        },
        destroyed() {
        },
        created() {
        },
        mounted() {
        },
        watch: {
        },
    });
</script>

<style lang="scss">
    @import '../styles/styles.scss';

    #shared-link-app-body {
        margin-top: 64px;
    }
</style>
