<template lang="pug">
    ExpandableCardListItem.mt-0.mb-4(
        v-if="validEmail"
        id="email-details-card"
        :color="itemColor"
        :content-right-top="email.friendly_direction"
        :content-right-bottom="email.friendly_created_at_date"
        expandable
        :is-expanded="showDetails === true"
        :on-expand="() => showDetails = !showDetails"
        :right-action-icon="renderViewLink ? 'mdi-eye' : null"
        :title="email.campaign_id > 0 ? email.campaign_name : 'Ad Hoc'"
        :subtitle="email.subject && email.subject.length > 0 ? email.subject : 'No Subject'"
    )
        template(v-slot:rightTop)
            strong.text-uppercase {{ email.friendly_direction }}

        template(v-slot:rightActionIcon)
            v-btn.no-text-underline(
                v-if="renderViewLink"
                icon 
                dark 
                :to="{ name: 'email_show', params: { email_key: email.email_key } }"
                target="_blank"
            )
                v-icon.text-white mdi-eye

        template(v-slot:body)
            v-card-text.white.text--primary(v-if="showDetails === true")
                v-list.py-0(
                    two-line
                )
                    ListItem(
                        default-title="N/a"
                        :icon="email.direction === 'incoming' ? 'mdi-email-plus' : 'mdi-email-fast'"
                        indent
                        subtitle="Datetime"
                        :title="email.friendly_created_at"
                    )
                    ListItem(
                        default-title="N/a"
                        indent
                        subtitle="From"
                        :title="displayFrom"
                    )
                    ListItem(
                        default-title="N/a"
                        indent
                        subtitle="To"
                        :title="to"
                    )
                    ListItem(
                        default-title="---"
                        indent
                        subtitle="cc"
                        :title="to"
                    )
                    ListItem(
                        default-title="---"
                        indent
                        subtitle="Reply To"
                        :title="displayReplyTo"
                    )
                    ListItem(
                        default-title="N/a"
                        indent
                        subtitle="Last Event"
                        :title="email.email_last_event"
                    )
                        template(v-slot:title)
                            template(v-if="email.email_last_event && email.email_last_event.length > 0")
                                StatusText(:text="email.email_last_event")
                            template(v-else) N/a

                    ListItem(
                        default-title="N/a"
                        indent
                        subtitle="Sent By"
                        :title="email.user_full_name"
                    )
                
                template
                    v-divider(inset)
                    v-simple-table(v-if="validBody && showBody")
                        template(v-slot:default)
                            tbody
                                tr
                                    td
                                        v-container(
                                            v-if="showBody"
                                            v-html="email.email_body.text_html_body"
                                        )
                    ListItem(
                        :default-title="!validBody ? 'Blank' : null"
                        icon="mdi-email-newsletter"
                        indent
                        :subtitle="(showBody || !validBody) ? 'Body' : null"
                    )
                        template(v-slot:title)
                            v-btn(v-if="validBody && !showBody" text color='primary' plain small @click="showBody = true;")
                                | Show Body

                template
                    v-divider(inset)
                    v-simple-table(v-if="validEmailAttachments && showAttachments")
                        template(v-slot:default)
                            tbody
                                tr
                                    td
                                        EmailAttachmentDetails(
                                            v-if="showAttachments"
                                            v-for='emailAttachment in email.email_attachments'
                                            :key='emailAttachment.email_attachment_key'
                                            :email-attachment="emailAttachment"
                                        )
                    ListItem(
                        :default-title="!validEmailAttachments ? 'None' : null"
                        icon="mdi-paperclip"
                        indent
                        :subtitle="(showAttachments || !validEmailAttachments) ? 'Attachments' : null"
                    )
                        template(v-slot:title)
                            v-btn(v-if="validEmailAttachments && !showAttachments" text color='primary' plain small @click="showAttachments = true;")
                                | Show Attachments

                template(v-if="validEmailEvents")
                    v-divider(inset)
                    v-simple-table(v-if="validEmailEvents && showEvents")
                        template(v-slot:default)
                            tbody
                                tr
                                    td
                                        EmailEventDetails(
                                            v-if="showEvents"
                                            v-for='emailEvent in email.email_events'
                                            :key='emailEvent.email_event_key'
                                            :email-event="emailEvent"
                                        )

                    ListItem(
                        :default-title="!validEmailEvents ? 'None' : null"
                        icon="mdi-calendar-multiple"
                        indent
                        :subtitle="(showEvents || !validEmailEvents) ? 'Events' : null"
                    )
                        template(v-slot:title)
                            v-btn(v-if="validEmailEvents && !showEvents" text color='primary' plain small @click="showEvents = true;")
                                | Show Events

</template>

<script lang="ts">
    import Vue from 'vue';

    // Components
    import EmailAttachmentDetails from "@/components/emails/email_attachment_details.vue";
    import EmailEvent from "@/components/emails/email_event.vue";
    import EmailEventDetails from "@/components/emails/email_event_details.vue";
    import EmailRecipientDetails from "@/components/emails/email_recipient_details.vue";
    import ExpandableCardListItem from '@/components/shared/expandable_card_list_item.vue';
    import ListItem from '@/components/shared/list_item.vue';
    import StatusText from '@/components/shared/status_text.vue';

    // Helpers
    import { resourceColorClass, } from "@/helpers/css_classes";

    export default Vue.extend({
        data() {
            return {
                showAttachments: false,
                showBody: false,
                showDetails: false,
                showEvents: false,
            }
        },
        methods: {
            recipients(recipientType) {
                const recipients = [];
                if (!(recipientType?.length > 0) || !(this.email?.email_recipients?.length > 0)) {
                    return "";
                }

                for (let i = 0; i < this.email.email_recipients.length; i++) {
                    const recipient = this.email.email_recipients[i];
                    if (recipient.recipient_type !== recipientType) {
                        continue;
                    }

                    recipients.push(recipient.friendly_email_recipient_name);
                }

                return recipients.join(", ");
            },
        },
        computed: {
            cc() {
                return this.recipients("cc");
            },
            displayFrom() {
                return this.email?.from_name?.length > 0 ? this.email?.from_name + " <" + this.email?.from_address + ">" : this.email?.from_address;
            },
            displayReplyTo() {
                return this.email?.reply_to_name?.length > 0 ? this.email?.reply_to_name + " <" + this.email?.reply_to_address + ">" : this.email?.reply_to_address;
            },
            itemColor() {
                return resourceColorClass("Email");
            },
            renderViewLink() {
                return this.source === "admin";
            },
            to() {
                return this.recipients("to");
            },
            validBody() {
                return this.email?.email_body?.text_html_body?.length > 0;
            },
            validEmail() {
                return this.email?.email_id > 0;
            },
            validEmailAttachments() {
                return this.email?.email_attachments?.length > 0;
            },
            validEmailEvents() {
                return this.email?.email_events?.length > 0;
            },
            validEmailRecipients() {
                return this.email?.email_recipients?.length > 0;
            },
        },
        components: {
            EmailAttachmentDetails,
            EmailEvent,
            EmailEventDetails,
            EmailRecipientDetails,
            ExpandableCardListItem,
            ListItem,
            StatusText,
        },
        props: {
            email: Object,
            source: String,
        },
    });
</script>

<style lang="scss" scoped>
    #app {
        .no-top-bottom-padding {
            padding-top: 0;
            padding-bottom: 0;
        }

        .cell-value-spacing {
            padding: 0 0 5px 0;
        }

        .m-l-5 {
            margin-left: 5px;
        }
    }
</style>
