import { get, post, put } from '.';

export function CreateMortgageProspect(options = {}) {
	return post<any>("/app/api/mortgage_prospects", options);
}

export function FindMortgageProspect(id, options = {}) {
	return get<any>("/app/api/mortgage_prospects/" + (id || 0).toString(), options);
}

export function UpdateMortgageProspect(id, options = {}) {
	return put<any>("/app/api/mortgage_prospects/" + (id || 0).toString(), options);
}
