import { get, post, destroy } from '.';

export function GetConnectKeyOrganizations(options = {}) {
	return get<any>("/app/api/connect_key_organizations", options);
}

export function CreateConnectKeyOrganization(options = {}) {
	return post<any>("/app/api/connect_key_organizations", options);
}

export function DestroyConnectKeyOrganization(id) {
	return destroy<any>("/app/api/connect_key_organizations/" + (id || 0).toString());
}

export function GetAvailableConnectKeyOrganizations(options = {}) {
	return get<any>("/app/api/connect_key_organizations_available", options);
}

export function CreateAllConnectKeyOrganizations(options = {}) {
	return post<any>("/app/api/connect_key_organizations_all", options);
}

export function DestroyAllConnectKeyOrganizations(id, options = {}) {
	return destroy<any>("/app/api/connect_key_organizations_all/" + (id || 0).toString(), options);
}
