<template lang="pug">
    ExpandableCardListItem.mt-0.mb-5.mx-1(
        v-if="validAppointment"
        :color="itemColor"
        expandable
        :flex-grow-left="8"
        :is-expanded="showDetails === true"
        :on-expand="() => showDetails = !showDetails"
        :subtitle="appointment.vehicle_year_make_model"
        :title="appointment.friendly_appointment_datetime"
    )
        template(v-slot:rightTop)
            v-icon(v-if="icon" dark)
                | {{ icon }}


        template(v-slot:body)
            v-card-text.white.text--primary(v-if="showDetails === true")
                v-list.py-0(
                    two-line
                )
                    template
                        ListItem(
                            default-title="N/a"
                            icon="mdi-office-building"
                            indent
                            subtitle="Organization"
                            :title="appointment.organization_name"
                        )
                        v-divider(inset)
                        ListItem(
                            default-title="N/a"
                            icon="mdi-bullhorn"
                            indent
                            subtitle="Campaign"
                            :title="appointment.campaign_name"
                        )
                        v-divider(inset)
                        ListItem(
                            default-title="N/a"
                            icon="mdi-calendar"
                            indent
                            subtitle="Appointment Datetime"
                            :title="appointment.friendly_appointment_datetime"
                        )
                        ListItem(
                            default-title="N/a"
                            indent
                            subtitle="Scheduled On"
                            :title="appointment.friendly_created_at_date"
                        )
                        ListItem(
                            default-title="N/a"
                            indent
                            subtitle="Canceled On"
                            :title="appointment.friendly_canceled_at_date"
                        )
                        v-divider(inset)
                        ListItem(
                            default-title="N/a"
                            icon="mdi-car"
                            indent
                            subtitle="Vehicle"
                            :title="appointment.vehicle_year_make_model"
                        )
                        ListItem(
                            default-title="N/a"
                            indent
                            subtitle="Vin"
                            :title="appointment.vin"
                        )
</template>

<script lang="ts">
    import Vue from 'vue';

    // Components
    import ExpandableCardListItem from '@/components/shared/expandable_card_list_item.vue';
    import ListItem from '@/components/shared/list_item.vue';

    // Helpers
    import { resourceColorClass, } from "@/helpers/css_classes";

    export default Vue.extend({
        data() {
            return {
                showDetails: false,
            }
        },
        methods: {
        },
        computed: {
            itemColor() {
                return resourceColorClass("Appointment");
            },
            validAppointment() {
                return this.appointment?.appointment_id > 0;
            },
        },
        components: {
            ExpandableCardListItem,
            ListItem,
        },
        props: {
            appointment: Object,
            icon: String,
        },
    });
</script>

<style lang="scss" scoped>
</style>
