<template lang="pug">
    .base-min-height
        v-container
            v-simple-table(v-if="validEmailAttachments")
                template(v-slot:default)
                    thead
                        tr
                            th Email Attachment Key
                            th Attachment Url
                    tbody
                        tr(v-for='emailAttachment in emailAttachments' :key='emailAttachment.email_attachment_id')
                            td.no-wrap.sm-width {{ emailAttachment.email_attachment_key }}
                            td.sm-width {{ emailAttachment.attachment_url && emailAttachment.attachment_url.length > 0 ? emailAttachment.attachment_url : '---' }}

            v-alert(outlined type='warning' dense border='left' style="margin-top: 16px;" v-else)
                | There are not any attachments for this email

</template>

<script lang="ts">
    import Vue from 'vue';


    export default Vue.extend({
        data() {
            return {
            }
        },
        methods: {
        },
        computed: {
            validEmailAttachments() {
                return this.emailAttachments?.length > 0;
            },
        },
        components: {
        },
        props: {
            emailAttachments: Array,
        },
        created() {
        },
    });
</script>

<style lang="scss" scoped>
    #app {
        .base-min-height {
            min-height: 300px;
        }
    }
</style>