<template lang="pug">
    v-btn(
        link
        text
        ripple
        small
        min-width="15px"
        @click="onSort(sortColumnName, columnType)"
        v-bind:style="{ padding: 0, color: renderColor, fontWeight: 'bold', textTransform: 'none', letterSpacing: 0 }"
    )
        | {{ title }}
        v-icon(v-if="selectedSortColumnName === sortColumnName" small) {{ this.renderIcon() }}

</template>
<!-- // style="padding: 0; color: #666666; font-weight: bold; text-transform: none; letter-spacing: 0;" -->

<script lang="ts">
	import Vue from 'vue';

	export default Vue.extend({
        data() {
            return {
                fontWeight: "bold",
                padding: 0,
            }
        },
        methods: {
            renderIcon() {
                return (this.sortDirection === "asc" ? "mdi-chevron-up" : "mdi-chevron-down");
            },
        },
        computed: {
            renderColor() {
                return this.color?.length > 0 ? this.color : "#666666";
            },
        },
        components: {
        },
        props: {
            color: String,
            columnType: String,
            onSort: Function,
            selectedSortColumnName: String,
            sortColumnName: String,
            sortDirection: String,
            title: String,
        },
	});
</script>

<style lang="scss" scoped>
</style>
