import { destroy, get, post, put } from '.';

export function GetRecurringTimes(options = {}) {
	return get<any>("/app/api/recurring_times", options);
}

export function CreateRecurringTime(options = {}) {
	return post<any>("/app/api/recurring_times", options);
}

export function UpdateRecurringTime(id:string, options = {}) {
	return put<any>(`/app/api/recurring_times/${id}`, options);
}

export function DestroyRecurringTime(id: string) {
	return destroy<any>(`/app/api/recurring_times/${id}`);
}
