<template lang="pug">
    v-tooltip(v-if="isOnePhoneNumberFormat" top)
        template(v-slot:activator='{ on, attrs }')
            span(
                v-bind="attrs"
                v-on="on"
                v-clipboard:copy="displayPhoneNumber"
                v-clipboard:success="onCopySuccess"
                v-clipboard:error="onCopyError"
                role="text"
                style="cursor: pointer;"
                @mouseleave="onMouseLeave()"
            )
                | {{ (formattedPhoneNumber || '').trim() }}

        .toolbar-container-column
            .toolbar-row
                strong.text-center
                    | {{ message }}
            .toolbar-row
                span
                    | {{ displayPhoneNumber }}

    v-menu(v-else v-model="menu" :close-on-content-click="false")
        template(v-slot:activator='{ on: menu, attrs }')
            v-tooltip(top)
                template(v-slot:activator='{ on: tooltip, attrs }')
                    span(
                        v-bind="attrs" 
                        v-on="{ ...tooltip, ...menu }"
                        role="text"
                        style="cursor: pointer;"
                        @mouseleave="onMouseLeave()"
                    )
                        | {{ formattedPhoneNumber }}

                .toolbar-container-column
                    .toolbar-row
                        strong.text-center 
                            | {{ message }}
                    .toolbar-row
                        span 
                            | {{ displayPhoneNumber }}
        v-list 
            .toolbar-container-column
                .toolbar-row(style="margin-bottom: 10px; display: flex; flex-direction: row; justify-content: flex-start; align-items: center;")
                    v-icon(small style="padding-left: 10px; margin-right: 10px;") mdi-content-copy
                    strong.text-center 
                        | {{ message }}

            v-list-item-group(:key="menuKey")
                v-list-item(v-if='validFormattedPhoneNumber' :key='1')
                    v-list-item-title
                        span(
                            v-clipboard:copy="formattedPhoneNumber"
                            v-clipboard:success="onCopySuccess"
                            v-clipboard:error="onCopyError"
                            @click="onClickMenuItem()"
                        )
                            | {{ formattedPhoneNumber }}
                v-list-item(v-if='validPhoneNumberPhoneNumber' :key='2')
                    v-list-item-title
                        span(
                            v-clipboard:copy="phoneNumberPhoneNumber"
                            v-clipboard:success="onCopySuccess"
                            v-clipboard:error="onCopyError"
                            @click="onClickMenuItem()"
                        )
                            | {{ phoneNumberPhoneNumber }}
                v-list-item(v-if='validPhoneNumberWithoutCountryCode' :key='3')
                    v-list-item-title
                        span(
                            v-clipboard:copy="phoneNumberWithoutCountryCode"
                            v-clipboard:success="onCopySuccess"
                            v-clipboard:error="onCopyError"
                            @click="onClickMenuItem()"
                        )
                            | {{ phoneNumberWithoutCountryCode }}

</template>

<script lang="ts">
    import Vue from 'vue';
    import VolieState from '@/store';
    import VueClipboard from 'vue-clipboard2';
    VueClipboard.config.autoSetContainer = true;
    Vue.use(VueClipboard);

    // Helpers
    import { formatTel } from "@/helpers/general";

   export default Vue.extend({
       data() {
            return {
                menu: false,
                menuKey: null,
                message: 'Click to Copy',
            }
       },
       methods: {
            onClickMenuItem() {
                setTimeout(() => { 
                    this.menu = false 
                }, 500);
            },
            onCopyError() {
                this.message = 'Error copying!';
            },
            onCopySuccess() {
                this.message = 'Text Copied!';
            },
            onMouseLeave() {
                setTimeout(() => {
                    this.message = 'Click to Copy';
                }, 250);

                this.menuKey = Date.now();
            }
       },
       computed: {
            accountSettingPhoneNumberFormatting() {
                return this.currentUser?.account?.phone_number_formatting || [];
            },
            currentUser() {
                return VolieState.currentUser();
            },
            displayPhoneNumber() {

                if (this.enabledFormattedPhoneNumber && this.formattedPhoneNumber) {
                    return this.formattedPhoneNumber;
                }
                if (this.enabledPhoneNumberPhoneNumber && this.phoneNumberPhoneNumber) {
                    return this.phoneNumberPhoneNumber;
                }
                if (this.enabledPhoneNumberWithoutCountryCode && this.phoneNumberWithoutCountryCode) {
                    return this.phoneNumberWithoutCountryCode;
                }

                // Edge case - account setting only enabled phone number without country code but phone number object doesn't have a country code
                if (this.enabledPhoneNumberWithoutCountryCode && this.isOnePhoneNumberFormat && !this.phoneNumberWithoutCountryCode) {
                    return this.phoneNumberPhoneNumber;
                }

                return this.formattedPhoneNumber;
            },
            enabledFormattedPhoneNumber() {
                return this.accountSettingPhoneNumberFormatting.includes('+1 (234) 567-8910');
            },
            enabledPhoneNumberPhoneNumber() {
                return this.accountSettingPhoneNumberFormatting.includes('+12345678910');
            },
            enabledPhoneNumberWithoutCountryCode() {
                return this.accountSettingPhoneNumberFormatting.includes('2345678910');
            },
            isOnePhoneNumberFormat() {

                // If zero or one phone number format is enabled, return true
                if (!(this.accountSettingPhoneNumberFormatting.length > 1)) {
                    return true;
                }

                let validPhoneNumberFormatsCount = 0;
                if (this.validFormattedPhoneNumber) {
                    validPhoneNumberFormatsCount++;
                }
                if (this.validPhoneNumberPhoneNumber) {
                    validPhoneNumberFormatsCount++;
                }
                if (this.validPhoneNumberWithoutCountryCode) {
                    validPhoneNumberFormatsCount++;
                }

                if (!(validPhoneNumberFormatsCount > 1)) {
                    return true;
                }

                return false;
            },
            formattedPhoneNumber() {
                // +1 (234) 567-8910

                if (this.phoneNumberPhoneNumber?.length > 0) {
                    return formatTel(this.phoneNumberPhoneNumber);
                }
                return null;
            },
            phoneNumberPhoneNumber() {
                // +12345678910

                if (this.phoneNumber?.phone_number_phone_number?.length > 0) {
                    return this.phoneNumber.phone_number_phone_number;
                }
                return null;
            },
            phoneNumberWithoutCountryCode() {
                // 2345678910

                if (this.phoneNumber?.phone_number_phone_number?.length > 0 && this.phoneNumber?.phone_number_country_code?.length > 0) {
                    return this.phoneNumber.phone_number_phone_number.replace(this.phoneNumber.phone_number_country_code, '');
                }
                return null;
            },
            validFormattedPhoneNumber() {
                return this.enabledFormattedPhoneNumber && this.formattedPhoneNumber;
            },
            validPhoneNumberPhoneNumber() {
                return this.enabledPhoneNumberPhoneNumber && this.phoneNumberPhoneNumber;
            },
            validPhoneNumberWithoutCountryCode() {
                return this.enabledPhoneNumberWithoutCountryCode && this.phoneNumberWithoutCountryCode;
            },
       },
        props: {
            phoneNumber: Object,
        },
    });
</script>

<style lang="scss" scoped>

    #app {
        .toolbar-container-column {
            display: flex; 
            flex-direction: column; 
            justify-content: center;
        }

        .toolbar-row {
            display: flex; 
            flex-direction: row; 
            justify-content: center;
        }
    }
</style>
