import {destroy, get, post} from '.'

export function CreateCallTranscriptionRuleRecord(options= {}) {
    return post<any>("/app/api/call_transcription_rule_records", options)
}

export function DestroyCallTranscriptionRuleRecord(id) {
    return destroy<any>("/app/api/call_transcription_rule_records/" + (id || 0).toString())
}

export function GetCallTranscriptionRuleAvailableCampaignCallTranscriptionRuleRecords(id, options = {}) {
    return get<any>("/app/api/call_transcription_rules/" + (id || 0).toString() + "/call_transcription_rule_records/available_campaigns", options)
}

export function GetCallTranscriptionRuleAvailableDispositionCallTranscriptionRuleRecords(id, options = {}) {
    return get<any>("/app/api/call_transcription_rules/" + (id || 0).toString() + "/call_transcription_rule_records/available_dispositions", options)
}

export function GetCallTranscriptionRuleAvailableOrganizationCallTranscriptionRuleRecords(id, options = {}) {
    return get<any>("/app/api/call_transcription_rules/" + (id || 0).toString() + "/call_transcription_rule_records/available_organizations", options)
}

export function GetCallTranscriptionRuleAvailableUserCallTranscriptionRuleRecords(id, options = {}) {
    return get<any>("/app/api/call_transcription_rules/" + (id || 0).toString() + "/call_transcription_rule_records/available_users", options)
}