<template lang="pug">
    .base-min-height
        v-container
            v-simple-table(v-if="validEmailEvents")
                template(v-slot:default)
                    thead
                        tr
                            th SID
                            th Event
                            th Timestamp
                            th Recipient
                    tbody
                        tr(v-for='emailEvent in emailEvents' :key='emailEvent.email_event_id')
                            td.no-wrap.sm-width {{ emailEvent.sid }}
                            td.no-wrap.sm-width
                                EmailEvent(:name="emailEvent.friendly_event")
                            td.no-wrap.sm-width {{ emailEvent.friendly_timestamp }}
                            td.no-wrap.sm-width {{ emailEvent.friendly_email_recipient_name && emailEvent.friendly_email_recipient_name.length > 0 ? emailEvent.friendly_email_recipient_name : '---' }}

            v-alert(outlined type='warning' dense border='left' style="margin-top: 16px;" v-else)
                | There are not any events for this email

</template>

<script lang="ts">
    import Vue from 'vue';

    // Components
    import EmailEvent from "@/components/emails/email_event.vue";

    export default Vue.extend({
        data() {
            return {
            }
        },
        methods: {
        },
        computed: {
            validEmailEvents() {
                return this.emailEvents?.length > 0;
            },
        },
        components: {
            EmailEvent,
        },
        props: {
            emailEvents: Array,
        },
        created() {
        },
    });
</script>

<style lang="scss" scoped>
    #app {
        .base-min-height {
            min-height: 300px;
        }
    }
</style>