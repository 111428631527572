import {get, put } from '.';

export function GetPriorityGroups(options = {}) {
	return get<any>("/app/api/priority_groups", options);
}

export function UpdatePriorityGroup(vid: string, options = {}) {
	return put<any>(`/app/api/priority_groups/${vid}`, options);
}

export function FindPriorityGroup(vid: string, options = {}) {
	return get<any>(`/app/api/priority_groups/${vid}`, options);
}

export function GetCampaignPriorityGroups(vid: string, options = {}) {
	return get<any>(`/app/api/priority_groups/${vid}/campaign_priority_groups`, options);
}

export function UpdateCampaignPriorityGroupSubPriorities(vid: string, options = {}) {
	return put<any>(`/app/api/priority_groups/${vid}/campaign_priority_group_sub_priorities`, options);
}

export function UpdateCampaignPriorityGroup(vid: string, options = {}) {
	return put<any>(`/app/api/priority_groups/${vid}/campaign_priority_groups`, options);
}

export function ChangeCampaignPriorityGroup(vid: string, options = {}) {
	return put<any>(`/app/api/priority_groups/${vid}/change_campaign_priority_group`, options);
}