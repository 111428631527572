<template lang="pug">
    div.mx-lg-auto(width="100%")
        v-row(v-if="compatibleTranscription")
            v-col(cols=6)
                v-card(v-if="validSentiment")
                    v-card-title Sentiments
                    v-card-subtitle (by Quarter)
                    v-card-text
                        line-chart(
                            :data="sentiments"
                            :min="-10"
                            :max="10"
                            xtitle="Quarter"
                            ytitle="Score"
                            :colors="['#ffce27', '#0f5b9a']"
                        )

            v-col(cols=6)
                v-card(v-if="validSpeakerTime")
                    v-card-title Speaker Time
                    v-card-subtitle (in percentage)
                    v-card-text
                        pie-chart(
                            :data="speakerTime"
                            :colors="['#ffce27', '#0f5b9a']"
                            plugins=[chartjsPluginDatalabels]
                        )

        v-card(v-if="validIssues && compatibleTranscription").mb-8.mt-4
            v-card-title Probable Reason(s) for Call
            v-card-text
                template(v-for="issue in issues")
                    p.font-weight-bold
                        span.issue {{issue}}

        v-card(v-if="compatibleTranscription" )
            v-card-title
                | Transcript
                .audio-wrapper.ml-auto
                    audio#audio-playback(
                        controls
                        :src="conference.recording_url"
                    )
            v-card-text
                v-btn.ma-2(color="warning" ripple @click='toggleDisplayType')
                    v-icon mdi-swap-horizontal
                    | Swap Agent/Customer

                .transcription-segment(v-for="segment in transcription.Transcript" :key="segment.BeginOffsetMillis" :class="isActiveRecordingSegment(segment)")
                    .sentiment
                        SentimentIcon(:sentiment="segment.Sentiment")
                    .transcript-content
                        .participant {{setParticipant(segment.ParticipantRole) | titleize}} -
                            span(class="time-seek" @click="setTimeOfPlayback(segment.BeginOffsetMillis)") {{ setTimeInSeconds(segment.BeginOffsetMillis) }}

                        template(v-if="hasIssues(segment)")
                            .content(v-html="stringWithIssueHighlight(segment)")
                        template(v-else)
                            .content {{segment.Content}}

        v-card(v-if="validAgentDecibels").my-8
            v-card-title Agent Call Decibels
            v-card-text
                column-chart(
                    :data="displayStyle === 'normal' ? agentDecibels : customerDecibels"
                    :colors="['#0f5b9a']"
                    xtitle="Seconds"
                    ytitle="Decibels"
                )


        v-card(v-if="validCustomerDecibels").my-8
            v-card-title Customer Call Decibels
            v-card-text
                column-chart(
                    :data="displayStyle === 'normal' ? customerDecibels : agentDecibels"
                    :colors="['#ffce27']"
                    xtitle="Seconds"
                    ytitle="Decibels"
                )

        div(v-if="!compatibleTranscription")
            v-alert(outlined type='info' dense border='left' style="margin-top: 16px;")
                | There is no call transcription for this conference

</template>

<script lang="ts">
import Vue from 'vue';

import Chartkick from 'vue-chartkick';
import Chart from 'chart.js';

Vue.use(Chartkick.use(Chart));

// Components
import SentimentIcon from '@/components/call_transcriptions/sentiment_icon.vue';

export default Vue.extend({
    data() {
        return {
            audio: null,
            agentDecibels: [],
            compatibleTranscription: true,
            customerDecibels: [],
            currentSeconds: 0,
            displayStyle: 'normal',
            issues: [],
            playing: false,
            sentiments: [],
            speakerTime: [],
            transcription: [],
        }
    },
    methods: {
        isActiveRecordingSegment(segment) {
            const beginningSeconds = (segment.BeginOffsetMillis / 1000).toFixed(0)
            const endingSeconds = (segment.EndOffsetMillis / 1000).toFixed(0)
            if (this.currentSeconds >= beginningSeconds && this.currentSeconds <= endingSeconds) {
                return "active-segment"
            } else {
                return "inactive-segment"
            }
        },
        hasIssues(segment) {
            return segment?.IssuesDetected?.length > 0
        },
        parseTranscription() {

            // Verify
            if (!this.callTranscription) {
                this.compatibleTranscription = false;
                return;
            }

            this.transcription = JSON.parse(this.callTranscription?.transcription_json)

            // if (this.transcription.ConversationCharacteristics)
            // this.transcription.ConversationCharacteristics.Sentiment.SentimentByPeriod.QUARTER.forEach()
            if(this.transcription.ConversationCharacteristics?.Sentiment) {
                this.setSentiment(this.transcription.ConversationCharacteristics.Sentiment)
                this.setSpeakerTime(this.transcription.ConversationCharacteristics.TalkTime)
                this.setIssuesAndDecibels(this.transcription.Transcript)
            } else {
                this.compatibleTranscription = false;
            }
        },
        setIssuesAndDecibels(transcript) {
            let lastAgentEndTime = 0;
            let lastAgentIndexValue = 0;
            let lastCustomerEndTime = 0;
            let lastCustomerIndexValue = 0;
            transcript.forEach((segment) => {
                if(this.hasIssues(segment)) {
                    const begin = segment.IssuesDetected[0]?.CharacterOffsets?.Begin;
                    const end = segment.IssuesDetected[0]?.CharacterOffsets?.End;
                    this.issues.push(segment.Content.substr(begin, end));
                }
                if(segment.ParticipantRole === "AGENT") {
                    if(lastAgentEndTime != segment.BeginOffsetMillis) {
                        const paddingToAdd = ((segment.BeginOffsetMillis - lastAgentEndTime ) / 1000).toFixed(0)
                        for (let i = 0; i < parseInt(paddingToAdd); i++) {
                            this.agentDecibels.push([lastAgentIndexValue, 0])
                            lastAgentIndexValue = lastAgentIndexValue+= 1
                        }
                    }

                    segment.LoudnessScores.forEach((value) => {
                        this.agentDecibels.push([lastAgentIndexValue, value])
                        lastAgentIndexValue = lastAgentIndexValue+=1
                    })

                    lastAgentEndTime = segment.EndOffsetMillis
                }
                if(segment.ParticipantRole === "CUSTOMER") {
                    if(lastCustomerEndTime != segment.BeginOffsetMillis) {
                        const paddingToAdd = ((segment.BeginOffsetMillis - lastCustomerEndTime) / 1000).toFixed(0)
                        for (let i = 0; i < parseInt(paddingToAdd); i++) {
                            this.customerDecibels.push([lastCustomerIndexValue, 0])
                            lastCustomerIndexValue = lastCustomerIndexValue+=1
                        }
                    }

                    segment.LoudnessScores.forEach((value) => {
                        this.customerDecibels.push([lastCustomerIndexValue, value])
                        lastCustomerIndexValue = lastCustomerIndexValue+=1
                    })
                    lastCustomerEndTime = segment.EndOffsetMillis
                }
            })
        },
        setParticipant(name) {

            let cleanedName = name

            if(this.displayStyle === 'flipped') {
                if (name === 'CUSTOMER') {
                    cleanedName = 'AGENT'
                }
                if (name === 'AGENT') {
                    cleanedName = 'CUSTOMER'
                }
            }

            return cleanedName.toLowerCase()
        },
        setSentiment(sentiment){
            this.sentiments.push(
                {
                    name: this.setParticipant("AGENT").charAt(0).toUpperCase() + this.setParticipant("AGENT").slice(1),
                    data: {
                        1: sentiment.SentimentByPeriod.QUARTER.AGENT[0].Score,
                        2: sentiment.SentimentByPeriod.QUARTER.AGENT[1].Score,
                        3: sentiment.SentimentByPeriod.QUARTER.AGENT[2].Score,
                        4: sentiment.SentimentByPeriod.QUARTER.AGENT[3].Score,
                    }
                },
                {
                    name: this.setParticipant("CUSTOMER").charAt(0).toUpperCase() + this.setParticipant("CUSTOMER").slice(1),
                    data: {
                        1: sentiment.SentimentByPeriod.QUARTER.CUSTOMER[0].Score,
                        2: sentiment.SentimentByPeriod.QUARTER.CUSTOMER[1].Score,
                        3: sentiment.SentimentByPeriod.QUARTER.CUSTOMER[2].Score,
                        4: sentiment.SentimentByPeriod.QUARTER.CUSTOMER[3].Score,
                    }
                },
            )

        },
        setSpeakerTime(talkTime){
            this.speakerTime.push(
                [
                    this.setParticipant("AGENT").charAt(0).toUpperCase() + this.setParticipant("AGENT").slice(1),
                    ((talkTime.DetailsByParticipant.AGENT.TotalTimeMillis /talkTime.TotalTimeMillis) * 100).toFixed(2)
                ],
                [
                    this.setParticipant("CUSTOMER").charAt(0).toUpperCase() + this.setParticipant("CUSTOMER").slice(1),
                    ((talkTime.DetailsByParticipant.CUSTOMER.TotalTimeMillis /talkTime.TotalTimeMillis) * 100).toFixed(2)
                ],

            )
        },
        setTimeInSeconds(millis) {
            const minutes = Math.floor(millis / 60000);
            const seconds = ((millis % 60000) / 1000).toFixed(0);
            return minutes + ":" + (parseInt(seconds) < 10 ? '0' : '') + seconds;
        },
        setTimeOfPlayback(millis) {
            this.audio.currentTime = (millis / 1000).toFixed(0);
            this.audio.play();
        },
        stringWithIssueHighlight(segment){
            const begin = segment.IssuesDetected[0]?.CharacterOffsets?.Begin;
            const end = segment.IssuesDetected[0]?.CharacterOffsets?.End;
            const beginningString = segment.Content.substr(0, begin);
            const issueString = segment.Content.substr(begin, end);
            let endString = "";
            if(end !== segment.Content.length - 1) {
                endString = segment.Content.substr(end, segment.Content.length);
            }


            return `${beginningString} <span class="issue">${issueString}</span>${endString}`
        },
        toggleDisplayType() {
          if(this.displayStyle === 'normal') {
              this.displayStyle = 'flipped';
              this.updateCharts();
              return
          }
          if(this.displayStyle === 'flipped') {
              this.displayStyle = 'normal';
              this.updateCharts();
              return
          }
        },
        updateCharts() {
            this.sentiments = [];
            this.speakerTime = [];
            this.setSentiment(this.transcription.ConversationCharacteristics.Sentiment);
            this.setSpeakerTime(this.transcription.ConversationCharacteristics.TalkTime);
        },
        update() {
            this.currentSeconds = parseInt(this.audio.currentTime);
        },
    },
    computed: {
        validAgentDecibels() {
            return this.agentDecibels.length > 0;
        },
        validCustomerDecibels(){
            return this.customerDecibels.length > 0;
        },
        validIssues() {
            return this.issues.length > 0;
        },
        validSentiment() {
            return this.sentiments.length > 0;
        },
        validSpeakerTime() {
            return true
        }
    },
    components: {
        SentimentIcon
    },
    props: {
        callTranscription: Object,
        conference: Object,
        isLoading: Boolean,
    },
    watch: {
        playing(value) {
            if (value) { return this.audio.play(); }
            this.audio.pause();
        },
    },
    created() {
        this.parseTranscription();
    },
    destroyed() {
    },
    mounted() {
        this.audio = document.getElementById('audio-playback');
        if(this.audio) {
            this.audio.addEventListener('timeupdate', this.update);
            this.audio.addEventListener('loadeddata', this.load);
            this.audio.addEventListener('pause', () => { this.playing = false; });
            this.audio.addEventListener('play', () => { this.playing = true; });
        }
    },
})
</script>

<style lang="scss" scoped>
    #app {


    }
</style>