<template lang="pug">
    v-form(
        :disabled="!validCustomer"
    )
        v-alert(dense outlined type='error' v-if="!validCustomer") In order to schedule a future communication, a valid customer must be present.
        
        v-radio-group(
            v-model='scheduledCommunication.radio_select'
            :mandatory='true'
            v-if="showRadioSelects === true"
            v-on:change="onChange()"
        )
            v-radio(label='1 hour later' value='hour1')
            v-radio(label='4 hours later' value='hour4')
            v-radio(label='24 hours later' value='hour24')
            v-radio(label='Custom' value='custom')

        div(v-if="scheduledCommunication.radio_select === 'custom'")
            v-select(
                v-model='scheduledCommunication.communication_type'
                :items='communicationTypes'
                label='Communication Type'
                item-text="key"
                item-value="value"
                v-on:change="onChange()"
                :disabled="communicationTypeDisabled"
                v-if="hideCommunicationTypeSelect !== true"
            )

            v-menu(
                v-model='datePickerMenu'
                :close-on-content-click='false'
                transition='scale-transition'
                offset-y
                max-width='290px'
                min-width='290px'
            )
                template(v-slot:activator='{ on, attrs }')
                    v-text-field(
                        v-model='formatScheduledTimeDate'
                        label='Date'
                        prepend-icon='mdi-calendar'
                        readonly
                        v-bind='attrs'
                        v-on='on'
                    )
                v-date-picker(
                    v-model='scheduledCommunication.scheduled_time_date'
                    no-title
                    @input='datePickerMenu = false'
                    :min="minDate"
                    v-on:change="onChange()"
                )

            Timepicker(
                :on-change="onChangeTimepicker"
            )

            v-select(
                v-model='scheduledCommunication.time_zone_id'
                :items='currentUser.time_zones'
                label='Time Zone'
                item-text="name"
                item-value="id"
                v-on:change="onChange()"
            )

        v-autocomplete(
            v-if="renderAvailableAssignments"
            :items='availableAssignments'
            item-text='name'
            :return-object="true"
            label='Assign to an agent or team'
            v-model='selectedAssignment'
            clearable
            dense
            outlined
        )                      
            template(v-slot:item='data')
                v-list-item-content
                    v-list-item-title {{ data.item.assignment_type | titleize }}
                    v-list-item-subtitle {{ data.item.name }}

        template(v-if="renderPreferenceSelect")
            v-autocomplete(
                v-if="isCommunicationTypeEmail"
                :items='availableEmailAddresses'
                item-text='email_address_email'
                :return-object="true"
                label='Email Address Preference'
                v-model='selectedEmailAddressPreference'
                clearable
                dense
                outlined
            )                      
                template(v-slot:item='data')
                    v-list-item-content
                        v-list-item-title {{ data.item.email_type | titleize }}
                        v-list-item-subtitle {{ data.item.email_address_email }}

            v-autocomplete(
                v-else-if="isCommunicationTypeMessage || isCommunicationTypePhone"
                :items='availablePhoneNumbers'
                item-text='phone_number_phone_number'
                :return-object="true"
                label='Phone Number Preference'
                v-model='selectedPhoneNumberPreference'
                clearable
                dense
                outlined
            )                      
                template(v-slot:item='data')
                    v-list-item-content
                        v-list-item-title {{ data.item.phone_number_type | titleize }}
                        v-list-item-subtitle {{ data.item.phone_number_phone_number }}

        v-textarea(
            v-model="scheduledCommunication.notes"
            label='Notes'
            rows="1"
            auto-grow
            clearable
            counter
            outlined
            dense
            prepend-inner-icon="mdi-text"
            v-on:input="onChange()"
        )

</template>

<script lang="ts">
    import Vue from 'vue';
    import VolieState  from "@/store";

    // Components
    import Timepicker from "@/components/shared/timepicker.vue";

    // Data
    import { GetCampaignAvailableAssignments } from "@/data";

    export default Vue.extend({
        data() {
            return {
                availableAssignments: [],
                communicationTypes: [
                    { key: "Email", value: "email" },
                    { key: "Message", value: "message" },
                    { key: "Phone", value: "phone" },
                ],
                communicationTypeDisabled: true,
                datePickerMenu: false,
                minDate: new Date().toISOString(),
                scheduledCommunication: {
                    assignment_id: null,
                    assignment_type: null,
                    communication_type: null,
                    notes: null,
                    preference_id: null,
                    preference_type: null,
                    radio_select: "custom",
                    scheduled_time_date: null,
                    scheduled_time_time: null,
                    time_zone_id: null,
                },
                selectedAssignment: null,
                selectedEmailAddressPreference: null,
                selectedPhoneNumberPreference: null,
            }
        },
        methods: {
            formatDate (date) {
                if (!date) {
                    return null;
                }

                const [year, month, day] = date.split('-');
                return `${month}/${day}/${year}`;
            },
            getAvailableAssignments() {

                // If hiding, skip loading since we do not need this data
                if (this.hideAssignmentSelect) {
                    return;
                }

                // Ensure valid campaign ID
                if (!this.validCampaignID) {
                    return;
                }

                GetCampaignAvailableAssignments(this.campaignId, {}).then((response) => {
                    if (response?.Data?.available_assignments?.length > 0) {
                        this.availableAssignments = response.Data.available_assignments;
                    }
                });
            },
            onChange() {

                // If we need to use, call function
                if (this.useOnChange === true) {
                    this.onScheduledCommunicationChange(this.scheduledCommunication);
                }
            },
            onChangeTimepicker(time) {
                this.scheduledCommunication.scheduled_time_time = time;
                this.onChange();
            },
            setDefaultCommunicationType() {
                if (this.defaultCommunicationType?.length > 0) {

                    // Find idx
                    const idx = this.communicationTypes.findIndex(i => i.key === this.defaultCommunicationType);
                    if (idx >= 0) {
                        this.scheduledCommunication.communication_type = this.communicationTypes[idx]?.value;
                    } else if (this.defaultCommunicationType === "SMS Message") {
                        this.scheduledCommunication.communication_type = "message";
                    }
                }
            },
            setDefaultScheduledTimeTimeZone() {

                // Set default time zone ID
                if (this.timeZoneId > 0 && this.currentUser?.time_zones?.length > 0) {
                    const idx = this.currentUser.time_zones.findIndex(i => i.id === this.timeZoneId);
                    if (idx > 0) {
                        this.scheduledCommunication.time_zone_id = this.timeZoneId;
                    }
                }

                // If still invalid, default to eastern
                if (!(this.scheduledCommunication.time_zone_id > 0)) {
                    this.scheduledCommunication.time_zone_id = 43;
                }

                // Manually call change function
                if (this.scheduledCommunication.time_zone_id > 0) {
                    this.onChange();
                }
            },
            setRadioSelect() {
                this.scheduledCommunication.radio_select = this.radioSelectOverride?.length > 0 ? this.radioSelectOverride : "custom";
            },
        },
        computed: {
            availableEmailAddresses() {

                // Init
                const emails = [];

                // Verify
                if (!this.communicationManager || !(this.communicationManager?.customer_email_addresses?.length > 0)) {
                    return emails;
                }

                // Loop through email addresses and build array
                for (let i = 0; i < this.communicationManager.customer_email_addresses.length; i++) {

                    // Init
                    const customerEmail = this.communicationManager.customer_email_addresses[i];
                    if (!customerEmail || !(customerEmail.customer_email_address_id > 0)) {
                        continue;
                    }

                    // Need to ensure that the email address can be emailed
                    if (customerEmail.do_not_email === true || customerEmail.email_address_do_not_email === true) {
                        continue;
                    }

                    // Push onto array
                    emails.push(customerEmail);
                }

                return emails;
            },
            availablePhoneNumbers() {

                // Init
                const pns = [];

                // Verify
                if (!this.communicationManager || !(this.communicationManager?.customer_phone_numbers?.length > 0)) {
                    return pns;
                }

                // Loop through phone numbers and build array
                for (let i = 0; i < this.communicationManager.customer_phone_numbers.length; i++) {

                    // Init
                    const customerPN = this.communicationManager.customer_phone_numbers[i];
                    if (!customerPN || !(customerPN.customer_phone_number_id > 0)) {
                        continue;
                    }

                    // Need to ensure that the phone number can be messaged
                    if (this.isCommunicationTypeMessage && (customerPN.do_not_message === true || customerPN.phone_number_do_not_message === true)) {
                        continue;
                    }

                    // Need to ensure that the phone number can be called
                    if (this.isCommunicationTypePhone && (customerPN.do_not_call === true || customerPN.phone_number_do_not_call === true)) {
                        continue;
                    }

                    // Push onto array
                    pns.push(customerPN);
                }

                return pns;
            },
            currentUser() {
                return VolieState.currentUser();
            },
            formatScheduledTimeDate() {
                return this.formatDate(this.scheduledCommunication?.scheduled_time_date);
            },
            isCommunicationTypeEmail() {
                return this.scheduledCommunication?.communication_type === "email";
            },
            isCommunicationTypeMessage() {
                return this.scheduledCommunication?.communication_type === "message";
            },
            isCommunicationTypePhone() {
                return this.scheduledCommunication?.communication_type === "phone";
            },
            renderAvailableAssignments() {
                return !this.hideAssignmentSelect && this.validCampaignID;
            },
            renderPreferenceSelect() {
                return !this.hidePreferenceSelect && this.communicationManager?.customer_id > 0;
            },
            validCampaignID() {
                return this.campaignId > 0;
            },
        },
        components: {
            Timepicker,
        },
        props: {
            campaignId: Number,
            communicationManager: Object,
            defaultCommunicationType: String,
            hideAssignmentSelect: Boolean,
            hideCommunicationTypeSelect: Boolean,
            hidePreferenceSelect: Boolean,
            onScheduledCommunicationChange: Function,
            radioSelectOverride: String,
            showRadioSelects: Boolean,
            timeZoneId: Number,
            useOnChange: Boolean,
            validCustomer: Boolean,
        },
        created() {
            this.setRadioSelect();
            this.setDefaultCommunicationType();
            this.setDefaultScheduledTimeTimeZone();

            // Load assignment options if not hidden
            if (this.renderAvailableAssignments) {
                this.getAvailableAssignments();
            }
        },
        watch: {
            selectedAssignment: {
                deep: true,
                immediate: true,
                handler() {
                    if (!!this.scheduledCommunication) {
                        if (this.selectedAssignment?.assignment_id > 0) {
                            this.scheduledCommunication.assignment_id = this.selectedAssignment.assignment_id;
                            this.scheduledCommunication.assignment_type = this.selectedAssignment.assignment_type;
                        } else {
                            this.scheduledCommunication.assignment_id = null;
                            this.scheduledCommunication.assignment_type = null;
                        }

                        this.onChange();
                    }
                },
            },
            selectedEmailAddressPreference: {
                deep: true,
                immediate: true,
                handler() {
                    if (!!this.scheduledCommunication) {
                        if (this.selectedEmailAddressPreference?.customer_email_address_id > 0) {
                            this.scheduledCommunication.preference_id = this.selectedEmailAddressPreference.customer_email_address_id;
                            this.scheduledCommunication.preference_type = "customer_email_address";
                        } else {
                            this.scheduledCommunication.preference_id = null;
                            this.scheduledCommunication.preference_type = null;
                        }

                        this.onChange();
                    }
                },
            },
            selectedPhoneNumberPreference: {
                deep: true,
                immediate: true,
                handler() {
                    if (!!this.scheduledCommunication) {
                        if (this.selectedPhoneNumberPreference?.customer_phone_number_id > 0) {
                            this.scheduledCommunication.preference_id = this.selectedPhoneNumberPreference.customer_phone_number_id;
                            this.scheduledCommunication.preference_type = "customer_phone_number";
                        } else {
                            this.scheduledCommunication.preference_id = null;
                            this.scheduledCommunication.preference_type = null;
                        }

                        this.onChange();
                    }
                },
            },
        },
    });
</script>

<style lang="scss" scoped>
    #app {
        .no-top-bottom-padding {
            padding-top: 0;
            padding-bottom: 0;
        }
    }
</style>