import { get, post, put } from '.';

export function GetEleadCustomers(eleadSubscriptionVid: string, organizationVid: string, options = {}) {
	return get<any>("/app/api/elead/subscriptions/" + (eleadSubscriptionVid || "").toString() + "/organizations/" + (organizationVid || "").toString() + "/customers", options);
}

export function ImportEleadCustomers(options = {}) {
	return post<any>("/app/api/elead/customers/import", options);
}

export function FindEleadCustomer(eleadSubscriptionVid, organizationVid, vid, options = {}) {
	return get<any>("/app/api/elead/subscriptions/" + (eleadSubscriptionVid || "").toString() + "/organizations/" + (organizationVid || "").toString() + "/customers/" + (vid || "").toString() , options);
}

export function CreateEleadCustomer(eleadSubscriptionVid: string, organizationVid: string,  options = {}) {
    return post<any>("/app/api/elead/subscriptions/" + (eleadSubscriptionVid || "").toString() + "/organizations/" + (organizationVid || "").toString() + "/customers", options);
}

export function UpdateEleadCustomer(eleadSubscriptionVid, organizationVid, vid, options = {}) {
    return put<any>("/app/api/elead/subscriptions/" + (eleadSubscriptionVid || "").toString() + "/organizations/" + (organizationVid || "").toString() + "/customers/" + (vid || "").toString(), options);
}

export function GetEleadCustomerVehicles(eleadSubscriptionVid, organizationVid, eleadCustomerVid) {
	return get<any>("/app/api/elead/subscriptions/" + (eleadSubscriptionVid || "").toString() + "/organizations/" + (organizationVid || "").toString() + "/customers/" + (eleadCustomerVid || "").toString() + "/customer_vehicles");
}

export function ImportEleadCustomerVehicles(eleadSubscriptionVid, eleadCustomerVid, options = {}) {
    return post<any>("/app/api/elead/subscriptions/" + (eleadSubscriptionVid || "").toString() + "/customers/" + (eleadCustomerVid || "").toString() + "/customer_vehicles", options);
}
