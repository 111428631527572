
import { get, post } from '.';

export function GetEleadSalesSteps(eleadSubscriptionVid: string, options = {}) {
	return get<any>("/app/api/elead/subscriptions/" + eleadSubscriptionVid + "/sales_steps", options);
}

export function ImportEleadSalesSteps(options = {}) {
	return post<any>("/app/api/elead/sales_steps/import", options);
}
