import { get, post, put, destroy } from ".";

export function CreateAutoAssistantPhoneNumber(options = {}) {
    return post<any>("/app/api/auto_assistant_phone_numbers", options);
}

export function DestroyAutoAssistantPhoneNumber(vid: string) {
    return destroy<any>(`/app/api/auto_assistant_phone_numbers/${vid}`);
}

export function FindAutoAssistantPhoneNumber(id: number, options = {}) {
    return get<any>(`/app/api/auto_assistant_phone_numbers/${id}`, options);
}

export function GetAutoAssistantPhoneNumbers(options = {}) {
    return get<any>("/app/api/auto_assistant_phone_numbers", options);
}

export function UpdateAutoAssistantPhoneNumber(vid: string, options = {}) {
    return put<any>(`/app/api/auto_assistant_phone_numbers/${vid}`, options);
}
