<template lang="pug">
    v-card.rounded-lg(
        outlined
        tile
        style="padding: 0 !important;"
        :disabled="!validEmailClient"
    )
        ComposeEmail(
            v-if="renderComposeEmail"
            :account-id="accountId"
            :audio-email-outcoming="audioEmailOutcoming"
            :cc="composingOptions.cc"
            :create-agent-for-sent-email="createAgentForSentEmail"
            :current-campaign-customer="currentCampaignCustomer"
            :customer-id="customerId"
            :exit="exitComposeEmail"
            :from="composingOptions.from"
            :main-client-height="mainClientHeight"
            :other-campaign-customer-id="otherCampaignCustomerId"
            :parent-email-id="composingOptions.parent_email_id"
            :purpose="composingOptions.purpose"
            :show-snackbar="showSnackbar"
            :subject="composingOptions.subject"
            :to="composingOptions.to"
        )

        template(v-else)
            v-toolbar(height="48px")
                v-btn.mr-sm-2(color="#FFFFFF" light small v-if="validExit" @click="exit();")
                    | Exit
                    v-icon
                        | mdi-close

                v-btn.mx-2(icon dark small color='warning' ripple v-if="renderExitThreadButton" @click="exitViewingEmailThread();")
                    v-icon(dark) mdi-arrow-left-circle
                v-spacer
                v-btn.mx-2(icon dark small color='primary' ripple v-if="renderComposeNewEmailButton" @click="initNewEmailComposingOptions();")
                    v-icon(dark) mdi-square-edit-outline

            v-card-text(
                style="overflow-y: auto; padding: 0;"
                v-bind:style="{ height: mainClientHeight + 'px' }"
            )

                template(v-if="!isSoftReloading")
                    ViewingEmailThread(
                        v-if="validSelectedEmailThread"
                        :create-agent="createAgent"
                        :current-campaign-customer="currentCampaignCustomer"
                        :email-thread="selectedEmailThread"
                        :start-reply-to-email="startReplyToEmail"
                    )

                    EmailThreads(
                        v-else-if="validEmailThreads"
                        :email-threads="emailClient.email_threads"
                        :set-selected-email-thread="setSelectedEmailThread"
                    )

                    NoEmailThreads(
                        v-else
                        :start-compose-new-email="startComposeNewEmail"
                    )

</template>

<script lang="ts">
    import Vue from 'vue';
    import VolieState  from "@/store";

    // Components
    import ComposeEmail from '@/components/communication_center/email/compose_email.vue';
    import EmailThreads from '@/components/communication_center/email/email_threads.vue';
    import NoEmailThreads from '@/components/communication_center/email/no_email_threads.vue';
    import ViewingEmailThread from '@/components/communication_center/email/viewing_email_thread.vue';

    // Data
    import {
        GetCommunicationCenterEmailClient,
    } from "@/data";

    export default Vue.extend({
        data() {
            return {
                bus: VolieState.websocketClient().eventBus(),
                composingOptions: null,
                emailClient: null,
                isLoading: false,
                selectedEmailThread: null,
                isSoftReloading: false,
            }
        },
        methods: {
            createAgentForOpenedInbox() {
                if (!(this.currentCampaignCustomer?.campaign_customer_active_id > 0)) {
                    return;
                }

                const options = {
                    campaign_customer_id: this.currentCampaignCustomer.campaign_customer_id,
                    campaign_id: this.currentCampaignCustomer.campaign_id,
                    noticeable_id: this.currentCampaignCustomer.campaign_customer_id,
                    noticeable_type: "campaign_customer",
                    organization_id: this.currentCampaignCustomer.organization_id,
                    status_name: "Email - Opened Inbox",
                }

                return this.validCreateAgent ? this.createAgent(options) : null;
            },
            createAgentForSentEmail(email) {
                if (!(email?.id > 0)) {
                    return;
                }

                const options = {
                    campaign_customer_id: email.campaign_customer_id,
                    campaign_id: email.campaign_id,
                    noticeable_id: email.id,
                    noticeable_type: "email",
                    organization_id: email.organization_id,
                    status_name: "Email - Sent Email",
                }

                return this.validCreateAgent ? this.createAgent(options) : null;
            },
            exitComposeEmail(reload) {
                this.composingOptions = null;

                if (reload) {
                    this.getCommunicationCenterEmailClient(false);
                }
            },
            exitViewingEmailThread() {
                this.selectedEmailThread = null;
            },
            getCommunicationCenterEmailClient(isResync) {
                const options = {
                    campaign_customer_active_id: (this.currentCampaignCustomer?.campaign_customer_active_id || 0),
                    account_id: (this.accountId || this.currentCampaignCustomer?.account_id || 0),
                    campaign_id: (this.campaignId || 0), // this field is only used for ad hoc softphone, so no cc active will be present
                    customer_id: (this.customerId || this.currentCampaignCustomer?.customer_id || 0),
                    other_campaign_customer_id: (this.otherCampaignCustomerId || 0),
                }

                if (!isResync) {
                    this.isLoading = true;
                }
                GetCommunicationCenterEmailClient(options).then((response) => {
                    if (!!response.Data?.email_client) {

                        // // In case of re-sync, check if we already have a conversation group and if so, if the last message is different
                        // const lastMessageTime = this.conversationGroup?.last_message_time;

                        // Set
                        this.emailClient = response.Data.email_client;

                        // // If the re-sync got us some missed messages, play incoming audio sound so the user knows new messages are here
                        // if (isResync === true && !!this.conversationGroup?.last_message_time && lastMessageTime !== this.conversationGroup?.last_message_time) {
                        //     this.playIncomingMessageAudio();
                        // }
                    }
                    this.isLoading = false;
                });
            },
            handleMessageBodyInputKeydown(e) {
                
                // If the user presses the enter button, submit message
                if (e.key?.toLowerCase() === "enter") {
                    // this.createEmail();
                    e.preventDefault();
                }
            },
            initNewEmailComposingOptions() {
                this.composingOptions = {
                    cc: [],
                    from: null,
                    parent_email_id: 0,
                    purpose: "new",
                    subject: null,
                    to: [],
                }
            },
            onEmailClientEmailThreadUpdate(response) {
                
                // Set thread and verify presence
                const thread = response?.Data;
                if (!thread || !(thread?.last_email_id > 0) || !(thread?.account_id > 0)) {
                    return;
                }

                // Validate email client for threads
                // If not present, append to email client and exit
                if (!this.validEmailThreads) {
                    this.emailClient.email_threads.push({ ...thread });

                    // Play audio if direction is incoming
                    if (thread.last_email_direction === "incoming") {
                        this.playAudio("audioEmailIncoming");
                    }
                    return;
                }

                // Init
                let ok = false;

                // Attempt to find thread on page
                if (thread.email_thread_id > 0) {

                    // Check by thread ID
                    const threadIdx = this.emailClient.email_threads.findIndex(i => (i.email_thread_id === thread.email_thread_id || (i.email_thread_id === null && i.last_email_id === thread.parent_email_id)));
                    if (threadIdx >= 0) {

                        // Init
                        const pageThread = this.emailClient.email_threads[threadIdx];
                        ok = true;

                        // Set changes
                        pageThread.email_thread_id = thread.email_thread_id;
                        pageThread.parent_email_id = thread.parent_email_id;
                        pageThread.has_email_attachment = thread.has_email_attachment;
                        pageThread.last_campaign_customer_active_id = thread.last_campaign_customer_active_id;
                        pageThread.last_campaign_customer_id = thread.last_campaign_customer_id;
                        pageThread.last_email_communication_provider_id = thread.last_email_communication_provider_id;
                        pageThread.last_email_created_at = thread.last_email_created_at;
                        pageThread.last_email_direction = thread.last_email_direction;
                        pageThread.last_email_from_address = thread.last_email_from_address;
                        pageThread.last_email_from_name = thread.last_email_from_name;
                        pageThread.last_email_id = thread.last_email_id;
                        pageThread.last_email_key = thread.last_email_key;
                        pageThread.last_email_noticeable_id = thread.last_email_noticeable_id;
                        pageThread.last_email_noticeable_type = thread.last_email_noticeable_type;
                        pageThread.last_email_reply_to_address = thread.last_email_reply_to_address;
                        pageThread.last_email_reply_to_name = thread.last_email_reply_to_name;
                        pageThread.last_email_sid = thread.last_email_sid;
                        pageThread.last_email_subject = thread.last_email_subject;
                        pageThread.last_email_text_html_body = thread.last_email_text_html_body;
                        pageThread.last_email_text_plain_body = thread.last_email_text_plain_body;
                        pageThread.reply_from = thread.reply_from;
                        pageThread.subject = thread.subject;
                        pageThread.unresponded_communication_response_id = thread.unresponded_communication_response_id;
                        pageThread.unresponded_to_incoming_email_id = thread.unresponded_to_incoming_email_id;
                    }
                }

                // Append to email threads if we didn't update existing
                if (!ok) {
                    this.emailClient.email_threads.push({ ...thread });                
                }

                // Play audio if direction is incoming
                if (thread.last_email_direction === "incoming") {
                    this.playAudio("audioEmailIncoming");

                    // Push alert
                    this.showSnackbar("green", "New incoming email received");
                }

                this.sortEmailThreads();
                this.softReload();
            },
            playAudio(key) {
                if (this.userDomInteraction === true && key?.length > 0 && !!this[key]) {

                    // Rewind current audio back to the start since this audio object is only loaded once
                    this[key].currentTime = 0;

                    // Set volume to 50%
                    this[key].volume = 0.5;

                    // Play audio
                    this[key].play();
                }
            },
            softReload() { // for causing the non-softphone components to reload
                this.isSoftReloading = true;

                // Turn off on next tick
                Vue.nextTick(() => {
                    this.isSoftReloading = false;
                });
            },
            sortEmailThreads() {
                if (this.validEmailThreads) {
                    this.emailClient.email_threads = this.emailClient.email_threads.sort(function(x, y) {
                        return y.last_email_id - x.last_email_id;
                    });
                }
            },
            startReplyToEmail(parentEmailID, purpose, subject, from, to, cc) {

                // Verify
                if (!parentEmailID) {
                    return;
                }

                // Init
                this.composingOptions = {
                    cc: [],
                    from: null,
                    parent_email_id: parentEmailID,
                    purpose: purpose,
                    subject: null,
                    to: [],
                }

                // Set details for replies
                if (purpose === "reply" || purpose === "replyall") {
                    this.composingOptions.cc = cc;
                    this.composingOptions.from = from;
                    this.composingOptions.subject = "Re: " + subject;
                    this.composingOptions.to = to;
                } else if (purpose === "forward") {
                    this.composingOptions.from = from;
                    this.composingOptions.subject = "Fwd: " + subject;
                }
            },
            resyncClient() {
                this.getCommunicationCenterEmailClient(true);
            },
            setSelectedEmailThread(thread) {
                this.selectedEmailThread = thread;
            },
            startComposeNewEmail() {
                this.initNewEmailComposingOptions();
            },
        },
        computed: {
            mainClientHeight() {
                return this.clientHeight - 48;
            },
            renderComposeEmail() {
                return this.composingOptions?.purpose?.length > 0;
            },
            renderComposeNewEmailButton() {
                return !this.validSelectedEmailThread;
            },
            renderExitThreadButton() {
                return this.validSelectedEmailThread;
            },
            userDomInteraction() {
                return VolieState.userDomInteraction();
            },
            validCampaignCustomerActiveID() {
                return this.currentCampaignCustomer?.campaign_customer_active_id > 0;
            },
            validEmailClient() {
                return this.emailClient?.account_id > 0 && this.emailClient?.customer_id > 0;
            },
            validCreateAgent() {
                return this.createAgent !== undefined && this.createAgent !== null;
            },
            validEmailThreads() {
                return this.validEmailClient && this.emailClient?.email_threads?.length > 0;
            },
            validExit() {
                return this.exit !== undefined && this.exit !== null;
            },
            validSelectedEmailThread() {
                return this.selectedEmailThread?.last_email_id > 0;
            },
        },
        components: {
            ComposeEmail,
            EmailThreads,
            NoEmailThreads,
            ViewingEmailThread,
        },
        props: {
            accountId: Number,
            audioEmailIncoming: HTMLAudioElement,
            audioEmailOutcoming: HTMLAudioElement,
            clientHeight: Number,
            createAgent: Function,
            currentCampaignCustomer: Object,
            customerId: Number,
            exit: Function,
            isRacOnline: Boolean,
            otherCampaignCustomerId: Number,
            showSnackbar: Function,
        },
        destroyed() {
            this.bus.unsubscribe({ type: "broadcast" , channel: { ID: null, Type: "emailClientEmailThreadUpdate" }}, this.onEmailClientEmailThreadUpdate);
        },
        created() {
            this.getCommunicationCenterEmailClient(false);
            this.createAgentForOpenedInbox();
            this.bus.subscribe({ type: "broadcast" , channel: { ID: null, Type: "emailClientEmailThreadUpdate" }}, this.onEmailClientEmailThreadUpdate);
        },
        mounted() {
        },
        watch: {
            isRacOnline() {

                // If it changed and now is true again, need to re-sync their softphone
                if (this.isRacOnline === true) {
                    this.resyncClient();
                }
            },
            selectedEmailThread() {
                if (this.selectedEmailThread === null) {
                    this.createAgentForOpenedInbox();
                }
            },
        },
    });
</script>

<style lang="scss" scoped>
</style>
