import VueRouter, { RouteConfig } from 'vue-router';
import volieStore from '../store';
import * as Role from '../model/roles';

let leavingSite = '';
leavingSite = '';

// Routes
const routes: Array<RouteConfig> = [

    // Access Groups
    {
        path: "/access_groups",
        name: "access_group_index",
        component: () => import( /* webpackChunkName: "group-app" */ '../views/access_groups/index.vue'),
        meta: {
            allowedRoles: [Role.SUPER_ADMIN],
        },
    },
    {
        path: "/access_groups/new",
        name: "access_group_new",
        component: () => import( /* webpackChunkName: "group-app" */ '../views/access_groups/new.vue'),
        meta: {
            allowedRoles: [Role.SUPER_ADMIN],
        },
    },
    {
        path: "/access_groups/:vid/edit",
        name: "access_group_edit",
        component: () => import( /* webpackChunkName: "group-app" */ '../views/access_groups/edit.vue'),
        meta: {
            allowedRoles: [Role.SUPER_ADMIN],
        },
    },

    // Access Groups - Account Accesses
    {
        path: "/access_groups/:access_group_vid/account_accesses",
        name: "account_access_index",
        component: () => import( /* webpackChunkName: "group-app" */ '../views/account_accesses/index.vue'),
        meta: {
            allowedRoles: [Role.SUPER_ADMIN],
        },
    },
    {
        path: "/access_groups/:access_group_vid/account_accesses/new",
        name: "account_access_new",
        component: () => import( /* webpackChunkName: "group-app" */ '../views/account_accesses/new.vue'),
        meta: {
            allowedRoles: [Role.SUPER_ADMIN],
        },
    },
    {
        path: "/access_groups/:access_group_vid/account_accesses/:vid/edit",
        name: "account_access_edit",
        component: () => import( /* webpackChunkName: "group-app" */ '../views/account_accesses/edit.vue'),
        meta: {
            allowedRoles: [Role.SUPER_ADMIN],
        },
    },

    // Access Groups - Organization Accesses
    {
        path: "/access_groups/:access_group_vid/account_accesses/:account_access_vid/organization_accesses/new",
        name: "organization_access_new",
        component: () => import( /* webpackChunkName: "group-app" */ '../views/organization_accesses/new.vue'),
        meta: {
            allowedRoles: [Role.SUPER_ADMIN],
        },
    },
    {
        path: "/access_groups/:access_group_vid/account_accesses/:account_access_vid/organization_accesses/:vid/edit",
        name: "organization_access_edit",
        component: () => import( /* webpackChunkName: "group-app" */ '../views/organization_accesses/edit.vue'),
        meta: {
            allowedRoles: [Role.SUPER_ADMIN],
        },
    },

    // Access Groups - Campaign Accesses
    {
        path: "/access_groups/:access_group_vid/account_accesses/:account_access_vid/organization_accesses/:organization_access_vid/campaign_accesses/new",
        name: "campaign_access_new",
        component: () => import( /* webpackChunkName: "group-app" */ '../views/campaign_accesses/new.vue'),
        meta: {
            allowedRoles: [Role.SUPER_ADMIN],
        },
    },
    {
        path: "/access_groups/:access_group_vid/account_accesses/:account_access_vid/organization_accesses/:organization_access_vid/campaign_accesses/:vid/edit",
        name: "campaign_access_edit",
        component: () => import( /* webpackChunkName: "group-app" */ '../views/campaign_accesses/edit.vue'),
        meta: {
            allowedRoles: [Role.SUPER_ADMIN],
        },
    },

    // Account Plugins
    {
        path: "/account_plugins",
        name: "account_plugin_index",
        component: () => import( /* webpackChunkName: "group-app" */ '../views/account_plugins/index.vue'),
        meta: {
            allowedRoles: [Role.SUPER_ADMIN, Role.MANAGER, Role.OWNER],
        },
    },
    {
        path: "/account_plugins/:account_plugin_id/show",
        name: "account_plugin_show",
        component: () => import( /* webpackChunkName: "group-app" */ '../views/account_plugins/show.vue'),
        meta: {
            allowedRoles: [Role.SUPER_ADMIN],
        },
    },
    {
        path: "/account_plugins/new",
        name: "account_plugin_new",
        component: () => import( /* webpackChunkName: "group-app" */ '../views/account_plugins/new.vue'),
        meta: {
            allowedRoles: [Role.SUPER_ADMIN],
        },
    },
    {
        path: "/account_plugins/:account_plugin_id/edit",
        name: "account_plugin_edit",
        component: () => import( /* webpackChunkName: "group-app" */ '../views/account_plugins/edit.vue'),
        meta: {
            allowedRoles: [Role.SUPER_ADMIN],
        },
    },

    // Accounts
    {
        path: "/super_admin/accounts",
        name: "accounts_index",
        component: () => import( /* webpackChunkName: "group-app" */ '../views/accounts/super_admin_index.vue'),
        meta: {
            allowedRoles: [Role.SUPER_ADMIN],
        },
    },

    // Adf Email
    {
        path: "/adf_emails",
        name: "adf_email_index",
        component: () => import( /* webpackChunkName: "group-app" */ '../views/adf_emails/index.vue'),
        meta: {
            allowedRoles: [Role.SUPER_ADMIN, Role.MANAGER, Role.OWNER, Role.ORGANIZATION_MANAGER],
        },
    },
    {
        path: "/adf_emails/:adf_email_key",
        name: "adf_email_show",
        component: () => import( /* webpackChunkName: "group-app" */ '../views/adf_emails/show.vue'),
        meta: {
            allowedRoles: [Role.SUPER_ADMIN, Role.MANAGER, Role.OWNER, Role.ORGANIZATION_MANAGER],
        },
    },

    // Adf Email Addresses
    {
        path: "/adf_email_addresses",
        name: "adf_email_addresses_index",
        component: () => import( /* webpackChunkName: "group-app" */ '../views/adf_email_addresses/index.vue'),
        meta: {
            allowedRoles: [Role.SUPER_ADMIN, Role.MANAGER, Role.OWNER, Role.ORGANIZATION_MANAGER],
        },
    },
    {
        path: '/adf_email_addresses/:vid/edit',
        name: "adf_email_addresses_edit",
        component: () => import( /* webpackChunkName: "group-app" */ '../views/adf_email_addresses/edit.vue'),
        meta: {
            allowedRoles: [Role.SUPER_ADMIN, Role.MANAGER, Role.OWNER, Role.ORGANIZATION_MANAGER],
        },
    },
    {
        path: '/adf_email_addresses/new',
        name: "adf_email_addresses_new",
        component: () => import( /* webpackChunkName: "group-app" */ '../views/adf_email_addresses/new.vue'),
        meta: {
            allowedRoles: [Role.SUPER_ADMIN, Role.MANAGER, Role.OWNER, Role.ORGANIZATION_MANAGER],
        },
    },

    // Agent Statuses
    {
        path: "/agent_statuses",
        name: "agent_statuses_index",
        component: () => import( /* webpackChunkName: "group-app" */ '../views/agent_statuses/index.vue'),
        meta: {
            allowedRoles: [Role.SUPER_ADMIN, Role.MANAGER, Role.OWNER],
        },
    },
    {
        path: "/agent_statuses/new",
        name: "agent_statuses_new",
        component: () => import( /* webpackChunkName: "group-app" */ '../views/agent_statuses/new.vue'),
        meta: {
            allowedRoles: [Role.SUPER_ADMIN, Role.MANAGER, Role.OWNER],
        },
    },
    {
        path: "/agent_statuses/:agent_status_key/edit",
        name: "agent_status_edit",
        component: () => import( /* webpackChunkName: "group-app" */ '../views/agent_statuses/edit.vue'),
        meta: {
            allowedRoles: [Role.SUPER_ADMIN, Role.MANAGER, Role.OWNER],
        },
    },

    // Alert Types
    {
        path: "/alert_types",
        name: "alert_types_index",
        component: () => import( /* webpackChunkName: "group-app" */ '../views/alert_types/index.vue'),
        meta: {
            allowedRoles: [Role.SUPER_ADMIN, Role.MANAGER, Role.OWNER],
        },
    },
    {
        path: "/alert_types/new",
        name: "alert_types_new",
        component: () => import( /* webpackChunkName: "group-app" */ '../views/alert_types/new.vue'),
        meta: {
            allowedRoles: [Role.SUPER_ADMIN, Role.MANAGER, Role.OWNER],
        },
    },
    {
        path: "/alert_types/:vid/edit",
        name: "alert_types_edit",
        component: () => import( /* webpackChunkName: "group-app" */ '../views/alert_types/edit.vue'),
        meta: {
            allowedRoles: [Role.SUPER_ADMIN, Role.MANAGER, Role.OWNER],
        },
    },

    // Appointment Scheduling Hours
    {
        path: "/appointment_scheduling_hours",
        name: "appointment_scheduling_hours_index",
        component: () => import( /* webpackChunkName: "group-app" */ '../views/appointment_scheduling_hours/index.vue'),
        meta: {
            allowedRoles: [Role.SUPER_ADMIN, Role.MANAGER, Role.OWNER],
        },
    },
    {
        path: "/appointment_scheduling_hours/new",
        name: "appointment_scheduling_hours_new",
        component: () => import( /* webpackChunkName: "group-app" */ '../views/appointment_scheduling_hours/new.vue'),
        meta: {
            allowedRoles: [Role.SUPER_ADMIN, Role.MANAGER, Role.OWNER],
        },
    },

    // Appointment types
    {
        path: "/appointment_types",
        name: "appointment_type_index",
        component: () => import( /* webpackChunkName: "group-app" */ '../views/appointment_types/index.vue'),
        meta: {
            allowedRoles: [Role.SUPER_ADMIN, Role.MANAGER, Role.OWNER],
        },
    },
    {
        path: "/appointment_types/new",
        name: "appointment_type_new",
        component: () => import( /* webpackChunkName: "group-app" */ '../views/appointment_types/new.vue'),
        meta: {
            allowedRoles: [Role.SUPER_ADMIN, Role.MANAGER, Role.OWNER],
        },
    },
    {
        path: "/appointment_types/:appointment_type_key/edit",
        name: "appointment_type_edit",
        component: () => import( /* webpackChunkName: "group-app" */ '../views/appointment_types/edit.vue'),
        meta: {
            allowedRoles: [Role.SUPER_ADMIN, Role.MANAGER, Role.OWNER],
        },
    },
    // Automate Deals
    {
        path: "/automate_deals",
        name: "automate_deal_index",
        component: () => import( /* webpackChunkName: "group-app" */ '../views/automate_deals/index.vue'),
        meta: {
            allowedRoles: [Role.SUPER_ADMIN],
        },
    },
    {
        path: "/automate_deals/:automate_deal_id",
        name: "automate_deal_show",
        component: () => import( /* webpackChunkName: "group-app" */ '../views/automate_deals/show.vue'),
        meta: {
            allowedRoles: [Role.SUPER_ADMIN],
        },
    },

    // Automate Files
    {
        path: "/automate_files",
        name: "automate_file_index",
        component: () => import( /* webpackChunkName: "group-app" */ '../views/automate_files/index.vue'),
        meta: {
            allowedRoles: [Role.SUPER_ADMIN],
        },
    },
    {
        path: "/automate_files/:file_id",
        name: "automate_file_show",
        component: () => import( /* webpackChunkName: "group-app" */ '../views/automate_files/show.vue'),
        meta: {
            allowedRoles: [Role.SUPER_ADMIN],
        },
    },

    // Automate Repair Order Headers
    {
        path: "/automate_repair_order_headers",
        name: "automate_repair_order_header_index",
        component: () => import( /* webpackChunkName: "group-app" */ '../views/automate_repair_order_headers/index.vue'),
        meta: {
            allowedRoles: [Role.SUPER_ADMIN],
        },
    },
    {
        path: "/automate_repair_order_headers/:repaire_order_header_id",
        name: "automate_repair_order_header_show",
        component: () => import( /* webpackChunkName: "group-app" */ '../views/automate_repair_order_headers/show.vue'),
        meta: {
            allowedRoles: [Role.SUPER_ADMIN],
        },
    },

    // Automate Repair Order Lines
    {
        path: "/automate_repair_order_lines",
        name: "automate_repair_order_line_index",
        component: () => import( /* webpackChunkName: "group-app" */ '../views/automate_repair_order_lines/index.vue'),
        meta: {
            allowedRoles: [Role.SUPER_ADMIN],
        },
    },
    {
        path: "/automate_repair_order_lines/:repaire_order_line_id",
        name: "automate_repair_order_line_show",
        component: () => import( /* webpackChunkName: "group-app" */ '../views/automate_repair_order_lines/show.vue'),
        meta: {
            allowedRoles: [Role.SUPER_ADMIN],
        },
    },

    // Automate Sale persons
    {
        path: "/automate_sales_persons",
        name: "automate_sales_person_index",
        component: () => import( /* webpackChunkName: "group-app" */ '../views/automate_sales_persons/index.vue'),
        meta: {
            allowedRoles: [Role.SUPER_ADMIN],
        },
    },
    {
        path: "/automate_sales_persons/:sales_person_id",
        name: "automate_sales_person_show",
        component: () => import( /* webpackChunkName: "group-app" */ '../views/automate_sales_persons/show.vue'),
        meta: {
            allowedRoles: [Role.SUPER_ADMIN],
        },
    },

    // Calls
    {
        path: "/calls",
        name: "call_index",
        component: () => import( /* webpackChunkName: "group-app" */ '../views/calls/index.vue'),
        meta: {
            allowedRoles: [Role.SUPER_ADMIN, Role.MANAGER, Role.OWNER, Role.SUPERVISOR, Role.ORGANIZATION_MANAGER],
        },
    },

    // Call Transcription Rules

    {
        path: "/call_transcription_rules",
        name: "call_transcription_rules_index",
        component: () => import( /* webpackChunkName: "group-app" */ '../views/call_transcription_rules/index.vue'),
        meta: {
            allowedRoles: [Role.SUPER_ADMIN, Role.MANAGER, Role.OWNER, Role.SUPERVISOR, Role.ORGANIZATION_MANAGER],
        }

    },
    {
        path: "/call_transcription_rules/new",
        name: "call_transcription_rules_new",
        component: () => import( /* webpackChunkName: "group-app" */ '../views/call_transcription_rules/new.vue'),
        meta: {
            allowedRoles: [Role.SUPER_ADMIN, Role.MANAGER, Role.OWNER, Role.SUPERVISOR, Role.ORGANIZATION_MANAGER],
        }

    },
    {
        path: "/call_transcription_rules/:vid/edit",
        name: "call_transcription_rules_edit",
        component: () => import( /* webpackChunkName: "group-app" */ '../views/call_transcription_rules/edit.vue'),
        meta: {
            allowedRoles: [Role.SUPER_ADMIN, Role.MANAGER, Role.OWNER, Role.SUPERVISOR, Role.ORGANIZATION_MANAGER],
        },
    },

    // Call Transcription Rules
    {
        path: "/call_transcriptions",
        name: "call_transcriptions_index",
        component: () => import( /* webpackChunkName: "group-app" */ '../views/call_transcriptions/index.vue'),
        meta: {
            allowedRoles: [Role.SUPER_ADMIN, Role.MANAGER, Role.OWNER, Role.SUPERVISOR, Role.ORGANIZATION_MANAGER],
        }

    },
    {
        path: "/call_transcriptions/post_call/:call_key",
        name: "call_transcriptions_post_call_detail",
        component: () => import( /* webpackChunkName: "group-app" */ '../views/call_transcriptions/post_call_detail.vue'),
        meta: {
            allowedRoles: [Role.SUPER_ADMIN, Role.MANAGER, Role.OWNER],
        },
    },

    // Campaign
    {
        path: "/campaigns",
        name: "campaigns_index",
        component: () => import( /* webpackChunkName: "group-app" */ '../views/campaigns/index.vue'),
        meta: {
            allowedRoles: [Role.SUPER_ADMIN, Role.MANAGER, Role.OWNER, Role.SUPERVISOR, Role.ORGANIZATION_MANAGER],
            hideMainMenu: true,
        },
    },
    {
        path: "/campaigns/:vid/edit",
        name: "campaigns_edit",
        component: () => import( /* webpackChunkName: "group-app" */ '../views/campaigns/edit.vue'),
        meta: {
            allowedRoles: [Role.SUPER_ADMIN, Role.MANAGER, Role.OWNER, Role.SUPERVISOR, Role.ORGANIZATION_MANAGER],
            hideMainMenu: true,
        },
    },
    {
        path: "/campaigns/new",
        name: "campaigns_new",
        component: () => import( /* webpackChunkName: "group-app" */ '../views/campaigns/new.vue'),
        meta: {
            allowedRoles: [Role.SUPER_ADMIN, Role.MANAGER, Role.OWNER, Role.SUPERVISOR, Role.ORGANIZATION_MANAGER],
        },
    },
    {
        path: "/campaigns/catalog",
        name: "campaigns_catalog",
        component: () => import( /* webpackChunkName: "group-app" */ '../views/campaigns/catalog.vue'),
        meta: {
            allowedRoles: [Role.SUPER_ADMIN, Role.MANAGER, Role.OWNER, Role.SUPERVISOR, Role.ORGANIZATION_MANAGER],
            hideMainMenu: true,
        },
    },

    // // Campaign Attempt Management
    // {
    //     path: "/campaign_attempt_management",
    //     name: "campaign_attempt_management",
    //     component: () => import( /* webpackChunkName: "group-app" */ '../views/campaigns/campaign_attempt_management.vue'),
    //     meta: {
    //         allowedRoles: [ Role.SUPER_ADMIN, Role.MANAGER, Role.OWNER, Role.SUPERVISOR, Role.ORGANIZATION_MANAGER ],
    //     },
    // },

    // Campaign Attempt Management
    {
        path: "/campaign_attempt_management",
        name: "campaign_attempt_management",
        component: () => import( /* webpackChunkName: "group-app" */ '../views/campaigns/campaign_attempt_management.vue'),
        meta: {
            allowedRoles: [ Role.SUPER_ADMIN, Role.MANAGER, Role.OWNER, Role.SUPERVISOR, Role.ORGANIZATION_MANAGER ],
            hideMainMenu: true,
        },
    },

    // Campaign Communication Transaction
    {
        path: "/campaign_communication_transactions",
        name: "campaign_communication_transaction_index",
        component: () => import( /* webpackChunkName: "group-app" */ '../views/campaign_communication_transactions/index.vue'),
        meta: {
            allowedRoles: [Role.SUPER_ADMIN, Role.MANAGER, Role.OWNER, Role.SUPERVISOR, Role.ORGANIZATION_MANAGER],
        },
    },

    // Campaign Deployment
    {
        path: "/campaign_deployments",
        name: "campaign_deployments",
        component: () => import( /* webpackChunkName: "group-app" */ '../views/campaign_deployments/main.vue'),
        meta: {
            allowedRoles: [Role.SUPER_ADMIN, Role.MANAGER, Role.OWNER, Role.SUPERVISOR, Role.ORGANIZATION_MANAGER],
        },
    },

    // Campaign edit
    {
        path: "/campaigns/:campaign_key/edit",
        name: "edit_campaign",
        component: () => import( /* webpackChunkName: "group-app" */ '../views/campaigns/edit.vue'),
        meta: {
            allowedRoles: [Role.SUPER_ADMIN, Role.MANAGER, Role.OWNER],
        },
    },

    // Campaign mappings
    {
        path: "/campaign_mappings",
        name: "campaign_mappings_index",
        component: () => import( /* webpackChunkName: "group-app" */ '../views/campaign_mappings/index.vue'),
        meta: {
            allowedRoles: [Role.SUPER_ADMIN],
        },
    },
    {
        path: "/campaign_mappings/new",
        name: "campaign_mappings_new",
        component: () => import( /* webpackChunkName: "group-app" */ '../views/campaign_mappings/new.vue'),
        meta: {
            allowedRoles: [Role.SUPER_ADMIN],
        },
    },
    {
        path: "/campaign_mappings/:mapping_id/edit",
        name: "campaign_mappings_edit",
        component: () => import( /* webpackChunkName: "group-app" */ '../views/campaign_mappings/edit.vue'),
        meta: {
            allowedRoles: [Role.SUPER_ADMIN],
        },
    },

    // Campaign Types
    {
        path: "/campaign_types",
        name: "campaign_types_index",
        component: () => import( /* webpackChunkName: "group-app" */ '../views/campaign_types/index.vue'),
        meta: {
            allowedRoles: [Role.SUPER_ADMIN, Role.MANAGER, Role.OWNER],
        },
    },
    {
        path: "/campaign_types/new",
        name: "campaign_types_new",
        component: () => import( /* webpackChunkName: "group-app" */ '../views/campaign_types/new.vue'),
        meta: {
            allowedRoles: [Role.SUPER_ADMIN, Role.MANAGER, Role.OWNER],
        },
    },
    {
        path: "/campaign_types/:vid",
        name: "campaign_types_show",
        component: () => import( /* webpackChunkName: "group-app" */ '../views/campaign_types/show.vue'),
        meta: {
            allowedRoles: [Role.SUPER_ADMIN, Role.MANAGER, Role.OWNER],
        },
    },

    // Cdk Customer Imports
    {
        path: "/cdk_customer_imports",
        name: "cdk_customer_import_index",
        component: () => import( /* webpackChunkName: "group-app" */ '../views/cdk_customer_imports/index.vue'),
        meta: {
            allowedRoles: [Role.SUPER_ADMIN],
        },
    },
    {
        path: "/cdk_customer_imports/:import_id",
        name: "cdk_customer_import_show",
        component: () => import( /* webpackChunkName: "group-app" */ '../views/cdk_customer_imports/show.vue'),
        meta: {
            allowedRoles: [Role.SUPER_ADMIN],
        },
    },

    // CDK Plugins
    {
        path: "/cdk_plugins",
        name: "cdk_plugins_index",
        component: () => import( /* webpackChunkName: "group-app" */ '../views/cdk_plugins/index.vue'),
        meta: {
            allowedRoles: [Role.SUPER_ADMIN, Role.MANAGER, Role.OWNER, Role.ORGANIZATION_MANAGER],
        },
    },
    {
        path: "/cdk_plugins/new",
        name: "cdk_plugins_new",
        component: () => import( /* webpackChunkName: "group-app" */ '../views/cdk_plugins/new.vue'),
        meta: {
            allowedRoles: [Role.SUPER_ADMIN, Role.MANAGER, Role.OWNER, Role.ORGANIZATION_MANAGER],
        },
    },
    {
        path: "/cdk_plugins/:cdk_plugin_key/edit",
        name: "cdk_plugins_edit",
        component: () => import( /* webpackChunkName: "group-app" */ '../views/cdk_plugins/edit.vue'),
        meta: {
            allowedRoles: [Role.SUPER_ADMIN, Role.MANAGER, Role.OWNER, Role.ORGANIZATION_MANAGER],
        },
    },

    // CDK Plugin Summaries
    {
        path: "/cdk_3pa_summaries",
        name: "cdk_3pa_summary_index",
        component: () => import( /* webpackChunkName: "group-app" */ '../views/cdk_3pa_summaries/index.vue'),
        meta: {
            allowedRoles: [Role.SUPER_ADMIN],
        },
    },

    // CDK Post Logs
    {
        path: "/cdk_post_logs",
        name: "cdk_post_log_index",
        component: () => import( /* webpackChunkName: "group-app" */ '../views/cdk_post_logs/index.vue'),
        meta: {
            allowedRoles: [Role.SUPER_ADMIN],
        },
    },
    {
        path: "/cdk_post_logs/:log_id",
        name: "cdk_post_log_show",
        component: () => import( /* webpackChunkName: "group-app" */ '../views/cdk_post_logs/show.vue'),
        meta: {
            allowedRoles: [Role.SUPER_ADMIN],
        },
    },

    // Cdk Sale Imports
    {
        path: "/cdk_sale_imports",
        name: "cdk_sale_import_index",
        component: () => import( /* webpackChunkName: "group-app" */ '../views/cdk_sale_imports/index.vue'),
        meta: {
            allowedRoles: [Role.SUPER_ADMIN],
        },
    },
    {
        path: "/cdk_sale_imports/:import_id",
        name: "cdk_sale_import_show",
        component: () => import( /* webpackChunkName: "group-app" */ '../views/cdk_sale_imports/show.vue'),
        meta: {
            allowedRoles: [Role.SUPER_ADMIN],
        },
    },

    // Cdk Service Header Imports
    {
        path: "/cdk_service_header_imports",
        name: "cdk_service_header_import_index",
        component: () => import( /* webpackChunkName: "group-app" */ '../views/cdk_service_header_imports/index.vue'),
        meta: {
            allowedRoles: [Role.SUPER_ADMIN],
        },
    },
    {
        path: "/cdk_service_header_imports/:import_id",
        name: "cdk_service_header_import_show",
        component: () => import( /* webpackChunkName: "group-app" */ '../views/cdk_service_header_imports/show.vue'),
        meta: {
            allowedRoles: [Role.SUPER_ADMIN],
        },
    },

    // Cdk Service Line Imports
    {
        path: "/cdk_service_line_imports",
        name: "cdk_service_line_import_index",
        component: () => import( /* webpackChunkName: "group-app" */ '../views/cdk_service_line_imports/index.vue'),
        meta: {
            allowedRoles: [Role.SUPER_ADMIN],
        },
    },
    {
        path: "/cdk_service_line_imports/:import_id",
        name: "cdk_service_line_import_show",
        component: () => import( /* webpackChunkName: "group-app" */ '../views/cdk_service_line_imports/show.vue'),
        meta: {
            allowedRoles: [Role.SUPER_ADMIN],
        },
    },

    // Client User Drops
    {
        path: "/user_client_drops",
        name: "client_user_drops_index",
        component: () => import( /* webpackChunkName: "group-app" */ '../views/user_client_drops/index.vue'),
        meta: {
            allowedRoles: [Role.SUPER_ADMIN, Role.MANAGER, Role.OWNER, Role.SUPERVISOR, Role.ORGANIZATION_AGENT, Role.ORGANIZATION_MANAGER],
        },
    },

    // Communication Center
    {
        path: "/communication_center",
        name: "communication_center",
        component: () => import( /* webpackChunkName: "group-communication-center" */ '../views/communication_center/main.vue'),
        meta: {
            hideMainMenu: true,
            allowedRoles: [Role.SUPER_ADMIN, Role.MANAGER, Role.OWNER, Role.AGENT, Role.SUPERVISOR, Role.ORGANIZATION_AGENT, Role.ORGANIZATION_MANAGER],
        },
    },

    // Communication Queue Groups
    {
        path: "/communication_queue_groups",
        name: "communication_queue_groups_index",
        component: () => import( /* webpackChunkName: "group-app" */ '../views/communication_queue_groups/index.vue'),
        meta: {
            allowedRoles: [Role.SUPER_ADMIN, Role.MANAGER, Role.OWNER],
        },
    },
    {
        path: "/communication_queue_groups/new",
        name: "communication_queue_groups_new",
        component: () => import( /* webpackChunkName: "group-app" */ '../views/communication_queue_groups/new.vue'),
        meta: {
            allowedRoles: [Role.SUPER_ADMIN, Role.MANAGER, Role.OWNER],
        },
    },
    {
        path: "/communication_queue_groups/:vid/edit",
        name: "communication_queue_groups_edit",
        component: () => import( /* webpackChunkName: "group-app" */ '../views/communication_queue_groups/edit.vue'),
        meta: {
            allowedRoles: [Role.SUPER_ADMIN, Role.MANAGER, Role.OWNER],
        },
    },

    // Conferences
    {
        path: "/conferences/:conference_key",
        name: "conference_show",
        component: () => import( /* webpackChunkName: "group-app" */ '../views/conferences/show.vue'),
        meta: {
            allowedRoles: [Role.SUPER_ADMIN, Role.MANAGER, Role.OWNER, Role.SUPERVISOR, Role.ORGANIZATION_MANAGER],
        },
    },

    // Connect Keys
    {
        path: "/connect_keys",
        name: "connect_keys_index",
        component: () => import( /* webpackChunkName: "group-app" */ '../views/connect_keys/index.vue'),
        meta: {
            allowedRoles: [Role.SUPER_ADMIN, Role.MANAGER, Role.OWNER, Role.ORGANIZATION_MANAGER],
        },
    },
    {
        path: "/connect_keys/new",
        name: "connect_keys_new",
        component: () => import( /* webpackChunkName: "group-app" */ '../views/connect_keys/new.vue'),
        meta: {
            allowedRoles: [Role.SUPER_ADMIN, Role.MANAGER, Role.OWNER, Role.ORGANIZATION_MANAGER],
        },
    },
    {
        path: "/connect_keys/:connect_key_key/edit",
        name: "connect_keys_edit",
        component: () => import( /* webpackChunkName: "group-app" */ '../views/connect_keys/edit.vue'),
        meta: {
            allowedRoles: [Role.SUPER_ADMIN, Role.MANAGER, Role.OWNER, Role.ORGANIZATION_MANAGER],
        },
    },

    // Conversica campaign mappings
    {
        path: "/conversica_campaign_mappings",
        name: "conversica_campaign_mappings_index",
        component: () => import( /* webpackChunkName: "group-app" */ '../views/conversica_campaign_mapping/index.vue'),
        meta: {
            allowedRoles: [Role.SUPER_ADMIN, Role.MANAGER, Role.OWNER, Role.ORGANIZATION_MANAGER],
        },
    },
    {
        path: "/conversica_campaign_mappings/new",
        name: "conversica_campaign_mappings_new",
        component: () => import( /* webpackChunkName: "group-app" */ '../views/conversica_campaign_mapping/new.vue'),
        meta: {
            allowedRoles: [Role.SUPER_ADMIN, Role.MANAGER, Role.OWNER, Role.ORGANIZATION_MANAGER],
        },
    },
    {
        path: "/conversica_campaign_mappings/:mapping_key/edit",
        name: "conversica_campaign_mappings_edit",
        component: () => import( /* webpackChunkName: "group-app" */ '../views/conversica_campaign_mapping/edit.vue'),
        meta: {
            allowedRoles: [Role.SUPER_ADMIN, Role.MANAGER, Role.OWNER, Role.ORGANIZATION_MANAGER],
        },
    },

    // Customers
    {
        path: "/customers/:customer_key",
        name: "customers_show",
        component: () => import( /* webpackChunkName: "group-app" */ '../views/customers/show.vue'),
        meta: {
            hideMainMenu: true,
            allowedRoles: [Role.SUPER_ADMIN, Role.MANAGER, Role.OWNER, Role.SUPERVISOR, Role.ORGANIZATION_MANAGER],
        },
    },

    // Dashboards
    {
        path: "/dashboards/affinity-dashboard",
        name: "dashboards_affinity_dashboard",
        component: () => import( /* webpackChunkName: "group-app" */ '../views/dashboards/affinity_dashboard.vue'),
        meta: {
            hideMainMenu: true,
            allowedRoles: [Role.SUPER_ADMIN, Role.MANAGER, Role.OWNER, Role.SUPERVISOR, Role.ORGANIZATION_MANAGER],
        },
    },
    {
        path: "/dashboards/appointment-dashboard",
        name: "dashboards_appointment_dashboard",
        component: () => import( /* webpackChunkName: "group-app" */ '../views/dashboards/appointment_dashboard.vue'),
        meta: {
            hideMainMenu: true,
            allowedRoles: [Role.SUPER_ADMIN, Role.MANAGER, Role.OWNER, Role.SUPERVISOR, Role.ORGANIZATION_MANAGER],
        },
    },
    {
        path: "/dashboards/lead-dashboard",
        name: "dashboards_lead_dashboard",
        component: () => import( /* webpackChunkName: "group-app" */ '../views/dashboards/lead_dashboard.vue'),
        meta: {
            hideMainMenu: true,
            allowedRoles: [Role.SUPER_ADMIN, Role.MANAGER, Role.OWNER, Role.SUPERVISOR, Role.ORGANIZATION_MANAGER],
        },
    },
    {
        path: "/dashboards/lead-dashboard/subaru",
        name: "dashboards_lead_dashboard_subaru",
        component: () => import( /* webpackChunkName: "group-app" */ '../views/dashboards/lead_dashboard_subaru.vue'),
        meta: {
            hideMainMenu: true,
            allowedRoles: [Role.SUPER_ADMIN, Role.MANAGER, Role.OWNER, Role.SUPERVISOR, Role.ORGANIZATION_MANAGER],
        },
    },
    {
        path: "/dashboards/live-dashboard",
        name: "dashboards_live_dashboard",
        component: () => import( /* webpackChunkName: "group-app" */ '../views/dashboards/live_dashboard.vue'),
        meta: {
            hideMainMenu: true,
            allowedRoles: [Role.SUPER_ADMIN, Role.MANAGER, Role.OWNER, Role.SUPERVISOR, Role.ORGANIZATION_MANAGER],
        },
    },

    // Data Export Templates
    {
        path: "/data_export_templates",
        name: "data_export_templates_index",
        component: () => import( /* webpackChunkName: "group-app" */ '../views/data_export_templates/index.vue'),
        meta: {
            allowedRoles: [Role.SUPER_ADMIN, Role.MANAGER, Role.OWNER],
        },
    },
    // {
    //     path: "/data_export_templates/:data_export_template_key/edit",
    //     name: "data_export_templates_edit",
    //     component: () => import( /* webpackChunkName: "group-app" */ '../views/data_export_templates/edit.vue'),
    //     meta: {
    //         allowedRoles: [ Role.SUPER_ADMIN, Role.MANAGER, Role.OWNER ],
    //     },
    // },

    // Data Import Templates
    {
        path: "/data_import_templates",
        name: "data_import_templates_index",
        component: () => import( /* webpackChunkName: "group-app" */ '../views/data_import_templates/index.vue'),
        meta: {
            allowedRoles: [Role.SUPER_ADMIN, Role.MANAGER, Role.OWNER],
        },
    },

    //Deal Types
    {
        path: "/deal_types",
        name: "deal_types_index",
        component: () => import( /* webpackChunkName: "group-app" */ '../views/deal_types/index.vue'),
        meta: {
            allowedRoles: [Role.SUPER_ADMIN, Role.MANAGER, Role.OWNER],
        },
    },
    {
        path: "/deal_types/new",
        name: "deal_type_new",
        component: () => import( /* webpackChunkName: "group-app" */ '../views/deal_types/new.vue'),
        meta: {
            allowedRoles: [Role.SUPER_ADMIN, Role.MANAGER, Role.OWNER],
        },
    },
    {
        path: "/deal_types/:vid/edit",
        name: "deal_type_edit",
        component: () => import( /* webpackChunkName: "group-app" */ '../views/deal_types/edit.vue'),
        meta: {
            allowedRoles: [Role.SUPER_ADMIN, Role.MANAGER, Role.OWNER],
        },
    },

    // Dispositions
    {
        path: "/dispositions",
        name: "dispositions_index",
        component: () => import( /* webpackChunkName: "group-app" */ '../views/dispositions/index.vue'),
        meta: {
            allowedRoles: [Role.SUPER_ADMIN, Role.MANAGER, Role.OWNER],
        },
    },
    {
        path: "/dispositions/new",
        name: "dispositions_new",
        component: () => import( /* webpackChunkName: "group-app" */ '../views/dispositions/new.vue'),
        meta: {
            allowedRoles: [Role.SUPER_ADMIN, Role.MANAGER, Role.OWNER],
        },
    },
    {
        path: "/dispositions/:disposition_key/edit",
        name: "dispositions_edit",
        component: () => import( /* webpackChunkName: "group-app" */ '../views/dispositions/edit.vue'),
        meta: {
            allowedRoles: [Role.SUPER_ADMIN, Role.MANAGER, Role.OWNER],
        },
    },

    // Disposition Groups
    {
        path: "/disposition_groups",
        name: "disposition_groups_index",
        component: () => import( /* webpackChunkName: "group-app" */ '../views/disposition_groups/index.vue'),
        meta: {
            allowedRoles: [Role.SUPER_ADMIN, Role.MANAGER, Role.OWNER],
        },
    },
    {
        path: "/disposition_groups/new",
        name: "disposition_groups_new",
        component: () => import( /* webpackChunkName: "group-app" */ '../views/disposition_groups/new.vue'),
        meta: {
            allowedRoles: [Role.SUPER_ADMIN, Role.MANAGER, Role.OWNER],
        },
    },
    {
        path: "/disposition_groups/:vid/edit",
        name: "disposition_groups_edit",
        component: () => import( /* webpackChunkName: "group-app" */ '../views/disposition_groups/edit.vue'),
        meta: {
            allowedRoles: [Role.SUPER_ADMIN, Role.MANAGER, Role.OWNER],
        },
    },

    // Elead activities
    {
        path: "/elead/subscriptions/:elead_subscription_vid/activities",
        name: "elead_activities_index",
        component: () => import( /* webpackChunkName: "group-app" */ '../views/elead_activities/index.vue'),
        meta: {
            allowedRoles: [Role.SUPER_ADMIN],
        },
    },
    {
        path: "/elead/subscriptions/:elead_subscription_vid/opportunities/:elead_opportunity_vid/activities/new",
        name: "elead_activities_new",
        component: () => import( /* webpackChunkName: "group-app" */ '../views/elead_activities/new.vue'),
        meta: {
            allowedRoles: [Role.SUPER_ADMIN],
        },
    },
    {
        path: "/elead/subscriptions/:elead_subscription_vid/opportunities/:elead_opportunity_vid/activities/:vid/complete",
        name: "elead_activities_complete",
        component: () => import( /* webpackChunkName: "group-app" */ '../views/elead_activities/complete.vue'),
        meta: {
            allowedRoles: [Role.SUPER_ADMIN],
        },
    },
    {
        path: "/elead/subscriptions/:elead_subscription_vid/opportunities/:elead_opportunity_vid/activities/:vid",
        name: "elead_activity_show",
        component: () => import( /* webpackChunkName: "group-app" */ '../views/elead_activities/show.vue'),
        meta: {
            allowedRoles: [Role.SUPER_ADMIN],
        },
    },

    // Elead activity outcomes
    {
        path: "/elead/subscriptions/:elead_subscription_vid/activity_outcomes",
        name: "elead_outcomes_index",
        component: () => import( /* webpackChunkName: "group-app" */ '../views/elead_activity_outcomes/index.vue'),
        meta: {
            allowedRoles: [Role.SUPER_ADMIN],
        },
    },

    // Elead activity types
    {
        path: "/elead/subscriptions/:elead_subscription_vid/activity_types",
        name: "elead_activity_types_index",
        component: () => import( /* webpackChunkName: "group-app" */ '../views/elead_activity_types/index.vue'),
        meta: {
            allowedRoles: [Role.SUPER_ADMIN],
        },
    },

    // Elead crm disposition mappings
    {
        path: "/elead/subscriptions/:elead_subscription_vid/organization_plugins/:organization_plugin_vid/crm_disposition_mapping",
        name: "elead_crm_disposition_mappings_index",
        component: () => import( /* webpackChunkName: "group-app" */ '../views/organization_plugins/crm_disposition_mapping/index.vue'),
        meta: {
            allowedRoles: [Role.SUPER_ADMIN],
        },
    },
    {
        path: "/elead/subscriptions/:elead_subscription_vid/organization_plugins/:organization_plugin_vid/crm_disposition_mapping/new",
        name: "elead_crm_disposition_mappings_new",
        component: () => import( /* webpackChunkName: "group-app" */ '../views/organization_plugins/crm_disposition_mapping/new.vue'),
        meta: {
            allowedRoles: [Role.SUPER_ADMIN],
        },
    },
    {
        path: "/elead/subscriptions/:elead_subscription_vid/organization_plugins/:organization_plugin_vid/crm_disposition_mapping/:vid/edit",
        name: "elead_crm_disposition_mappings_edit",
        component: () => import( /* webpackChunkName: "group-app" */ '../views/organization_plugins/crm_disposition_mapping/edit.vue'),
        meta: {
            allowedRoles: [Role.SUPER_ADMIN],
        },
    },

    // Elead customers
    {
        path: "/elead/subscriptions/:elead_subscription_vid/organizations/:organization_vid/customers",
        name: "elead_customers_index",
        component: () => import( /* webpackChunkName: "group-app" */ '../views/elead_customers/index.vue'),
        meta: {
            allowedRoles: [Role.SUPER_ADMIN],
        },
    },

    {
        path: "/elead/subscriptions/:elead_subscription_vid/organizations/:organization_vid/customers/new",
        name: "elead_customers_new",
        component: () => import( /* webpackChunkName: "group-app" */ '../views/elead_customers/new.vue'),
        meta: {
            allowedRoles: [Role.SUPER_ADMIN],
        },
    },

    {
        path: "/elead/subscriptions/:elead_subscription_vid/organizations/:organization_vid/customers/:vid/edit",
        name: "elead_customers_edit",
        component: () => import( /* webpackChunkName: "group-app" */ '../views/elead_customers/edit.vue'),
        meta: {
            allowedRoles: [Role.SUPER_ADMIN],
        },
    },

    {
        path: "/elead/subscriptions/:elead_subscription_vid/organizations/:organization_vid/customers/:vid",
        name: "elead_customers_show",
        component: () => import( /* webpackChunkName: "group-app" */ '../views/elead_customers/show.vue'),
        meta: {
            allowedRoles: [Role.SUPER_ADMIN],
        },
    },

    // Elead emails
    {
        path: "/elead/subscriptions/:elead_subscription_vid/emails",
        name: "elead_emails_index",
        component: () => import( /* webpackChunkName: "group-app" */ '../views/elead_emails/index.vue'),
        meta: {
            allowedRoles: [Role.SUPER_ADMIN],
        },
    },

    // Elead employees
    {
        path: "/elead/subscriptions/:elead_subscription_vid/employees",
        name: "elead_employees_index",
        component: () => import( /* webpackChunkName: "group-app" */ '../views/elead_employees/index.vue'),
        meta: {
            allowedRoles: [Role.SUPER_ADMIN],
        },
    },

    // Elead employee mapping

    {

        path: "/elead/subscriptions/:elead_subscription_vid/organizations/:organization_vid/employee_mapping",
        name: "elead_employee_user_mapping_index",
        component: () => import( /* webpackChunkName: "group-app" */ '../views/elead_employee_user_mapping/index.vue'),
        meta: {
            allowedRoles: [Role.SUPER_ADMIN],
        },
    },

    {
        path: "/elead/subscriptions/:elead_subscription_vid/organizations/:organization_vid/employee_mapping/new",
        name: "elead_employee_user_mappings_new",
        component: () => import( /* webpackChunkName: "group-app" */ '../views/elead_employee_user_mapping/new.vue'),
        meta: {
            allowedRoles: [Role.SUPER_ADMIN],
        },
    },

    {
        path: "/elead/subscriptions/:elead_subscription_vid/organizations/:organization_vid/employee_mapping/:vid/edit",
        name: "elead_employee_user_mappings_edit",
        component: () => import( /* webpackChunkName: "group-app" */ '../views/elead_employee_user_mapping/edit.vue'),
        meta: {
            allowedRoles: [Role.SUPER_ADMIN],
        },
    },

    // Elead opportunities
    {
        path: "/elead/subscriptions/:elead_subscription_vid/opportunities",
        name: "elead_opportunities_index",
        component: () => import( /* webpackChunkName: "group-app" */ '../views/elead_opportunities/index.vue'),
        meta: {
            allowedRoles: [Role.SUPER_ADMIN],
        },
    },
    {
        path: "/elead/subscriptions/:elead_subscription_vid/opportunities/new",
        name: "elead_opportunities_new",
        component: () => import( /* webpackChunkName: "group-app" */ '../views/elead_opportunities/new.vue'),
        meta: {
            allowedRoles: [Role.SUPER_ADMIN],
        },
    },
    {
        path: "/elead/subscriptions/:elead_subscription_vid/opportunities/:vid",
        name: "elead_opportunities_show",
        component: () => import( /* webpackChunkName: "group-app" */ '../views/elead_opportunities/show.vue'),
        meta: {
            allowedRoles: [Role.SUPER_ADMIN],
        },
    },

    // Elead organizations
    {
        path: "/elead/subscriptions/:elead_subscription_vid/organizations",
        name: "elead_organizations_index",
        component: () => import( /* webpackChunkName: "group-app" */ '../views/elead_organizations/index.vue'),
        meta: {
            allowedRoles: [Role.SUPER_ADMIN],
        },
    },

    // Elead positions
    {
        path: "/elead/subscriptions/:elead_subscription_vid/positions",
        name: "elead_positions_index",
        component: () => import( /* webpackChunkName: "group-app" */ '../views/elead_positions/index.vue'),
        meta: {
            allowedRoles: [Role.SUPER_ADMIN],
        },
    },

    // Elead runs
    {
        path: "/elead/runs",
        name: "elead_runs_index",
        component: () => import( /* webpackChunkName: "group-app" */ '../views/elead_runs/index.vue'),
        meta: {
            allowedRoles: [Role.SUPER_ADMIN],
        },
    },

    // Elead sales steps
    {
        path: "/elead/subscriptions/:elead_subscription_vid/sales_steps",
        name: "elead_sales_steps_index",
        component: () => import( /* webpackChunkName: "group-app" */ '../views/elead_sales_steps/index.vue'),
        meta: {
            allowedRoles: [Role.SUPER_ADMIN],
        },
    },

    // Elead sources
    {
        path: "/elead/subscriptions/:elead_subscription_vid/sources",
        name: "elead_sources_index",
        component: () => import( /* webpackChunkName: "group-app" */ '../views/elead_sources/index.vue'),
        meta: {
            allowedRoles: [Role.SUPER_ADMIN],
        },
    },

    // Elead statuses
    {
        path: "/elead/subscriptions/:elead_subscription_vid/statuses",
        name: "elead_statuses_index",
        component: () => import( /* webpackChunkName: "group-app" */ '../views/elead_statuses/index.vue'),
        meta: {
            allowedRoles: [Role.SUPER_ADMIN],
        },
    },

    // Elead sub sources
    {
        path: "/elead/subscriptions/:elead_subscription_vid/sub_sources",
        name: "elead_sub_sources_index",
        component: () => import( /* webpackChunkName: "group-app" */ '../views/elead_sub_sources/index.vue'),
        meta: {
            allowedRoles: [Role.SUPER_ADMIN],
        },
    },

    // Elead subscriptions
    {
        path: "/elead/subscriptions/new",
        name: "elead_subscription_new",
        component: () => import( /* webpackChunkName: "group-app" */ '../views/elead_subscriptions/new.vue'),
        meta: {
            allowedRoles: [Role.SUPER_ADMIN],
        },
    },
    {
        path: "/elead/subscriptions",
        name: 'elead_subscription_index',
        component: () => import( /* webpackChunkName: "group-app" */ '../views/elead_subscriptions/index.vue'),
        meta: {
            allowedRoles: [Role.SUPER_ADMIN],
        },
    },
    {
        path: "/elead/subscriptions/:vid",
        name: 'elead_subscription_show',
        component: () => import( /* webpackChunkName: "group-app" */ '../views/elead_subscriptions/show.vue'),
        meta: {
            allowedRoles: [Role.SUPER_ADMIN],
        },
    },
    {
        path: "/elead/subscriptions/:vid/edit",
        name: "elead_subscription_edit",
        component: () => import( /* webpackChunkName: "group-app" */ '../views/elead_subscriptions/edit.vue'),
        meta: {
            allowedRoles: [Role.SUPER_ADMIN],
        },
    },

    // Elead vehicle classes
    {
        path: "/elead/subscriptions/:elead_subscription_vid/vehicle_classes",
        name: "elead_vehicle_classes_index",
        component: () => import( /* webpackChunkName: "group-app" */ '../views/elead_vehicle_classes/index.vue'),
        meta: {
            allowedRoles: [Role.SUPER_ADMIN],
        },
    },

    // Elead vehicle makes
    {
        path: "/elead/subscriptions/:elead_subscription_vid/vehicle_makes",
        name: "elead_vehicle_makes_index",
        component: () => import( /* webpackChunkName: "group-app" */ '../views/elead_vehicle_makes/index.vue'),
        meta: {
            allowedRoles: [Role.SUPER_ADMIN],
        },
    },

    // Elead vehicle models
    {
        path: "/elead/subscriptions/:elead_subscription_vid/vehicle_models",
        name: "elead_vehicle_models_index",
        component: () => import( /* webpackChunkName: "group-app" */ '../views/elead_vehicle_models/index.vue'),
        meta: {
            allowedRoles: [Role.SUPER_ADMIN],
        },
    },

    // Elead vehicle years
    {
        path: "/elead/subscriptions/:elead_subscription_vid/vehicle_years",
        name: "elead_vehicle_years_index",
        component: () => import( /* webpackChunkName: "group-app" */ '../views/elead_vehicle_years/index.vue'),
        meta: {
            allowedRoles: [Role.SUPER_ADMIN],
        },
    },


    // Emails
    {
        path: "/emails",
        name: "email_index",
        component: () => import( /* webpackChunkName: "group-app" */ '../views/emails/index.vue'),
        meta: {
            allowedRoles: [Role.SUPER_ADMIN, Role.MANAGER, Role.OWNER],
        },
    },
    {
        path: "/emails/:email_key",
        name: "email_show",
        component: () => import( /* webpackChunkName: "group-app" */ '../views/emails/show.vue'),
        meta: {
            allowedRoles: [Role.SUPER_ADMIN, Role.MANAGER, Role.OWNER],
        },
    },

    // Enroller Runs
    {
        path: "/enroller_runs",
        name: "enroller_runs_index",
        component: () => import( /* webpackChunkName: "group-app" */ '../views/enroller_runs/index.vue'),
        meta: {
            hideMainMenu: true,
            allowedRoles: [Role.SUPER_ADMIN, Role.MANAGER, Role.OWNER],
        },
    },
    {
        path: "/enroller_runs/:vid",
        name: "enroller_runs_show",
        component: () => import( /* webpackChunkName: "group-app" */ '../views/enroller_runs/show.vue'),
        meta: {
            allowedRoles: [Role.SUPER_ADMIN, Role.MANAGER, Role.OWNER],
        },
    },

    // Goal Templates
    {
        path: "/goal_templates",
        name: "goal_templates_index",
        component: () => import( /* webpackChunkName: "group-app" */ '../views/goal_templates/index.vue'),
        meta: {
            hideMainMenu: true,
            allowedRoles: [Role.SUPER_ADMIN, Role.MANAGER, Role.OWNER],
        },
    },
    {
        path: "/goal_templates/new",
        name: "goal_templates_new",
        component: () => import( /* webpackChunkName: "group-app" */ '../views/goal_templates/new.vue'),
        meta: {
            allowedRoles: [Role.SUPER_ADMIN, Role.MANAGER, Role.OWNER],
        },
    },
    {
        path: "/goal_templates/:vid/edit",
        name: "goal_templates_edit",
        component: () => import( /* webpackChunkName: "group-app" */ '../views/goal_templates/edit.vue'),
        meta: {
            allowedRoles: [Role.SUPER_ADMIN, Role.MANAGER, Role.OWNER],
        },
    },

    // Incoming Phone Numbers
    {
        path: "/incoming_phone_numbers",
        name: "incoming_phone_numbers_index",
        component: () => import( /* webpackChunkName: "group-app" */ '../views/incoming_phone_numbers/index.vue'),
        meta: {
            allowedRoles: [Role.SUPER_ADMIN, Role.MANAGER, Role.OWNER],
        },
    },
    {
        path: "/incoming_phone_numbers/new",
        name: "incoming_phone_numbers_new",
        component: () => import( /* webpackChunkName: "group-app" */ '../views/incoming_phone_numbers/new.vue'),
        meta: {
            allowedRoles: [Role.SUPER_ADMIN, Role.MANAGER, Role.OWNER],
        },
    },
    {
        path: "/incoming_phone_numbers/:incoming_phone_number_key/edit",
        name: "incoming_phone_numbers_edit",
        component: () => import( /* webpackChunkName: "group-app" */ '../views/incoming_phone_numbers/edit.vue'),
        meta: {
            allowedRoles: [Role.SUPER_ADMIN, Role.MANAGER, Role.OWNER],
        },
    },

    // Incoming Phone Number Orders
    {
        path: "/incoming_phone_number_orders/:incoming_phone_number_order_key",
        name: "incoming_phone_number_orders_show",
        component: () => import( /* webpackChunkName: "group-app" */ '../views/incoming_phone_number_orders/show.vue'),
        meta: {
            allowedRoles: [Role.SUPER_ADMIN, Role.MANAGER, Role.OWNER],
        },
    },

    // IVR
    {
        path: "/ivr_trees",
        name: "ivr_trees_index",
        component: () => import( /* webpackChunkName: "group-app" */ '../views/ivr_trees/index.vue'),
        meta: {
            allowedRoles: [Role.SUPER_ADMIN, Role.MANAGER, Role.OWNER],
        },
    },
    {
        path: "/ivr_trees/:id/edit",
        name: "ivr_trees_edit",
        component: () => import( /* webpackChunkName: "group-app" */ '../views/ivr_trees/edit.vue'),
        meta: {
            allowedRoles: [Role.SUPER_ADMIN, Role.MANAGER, Role.OWNER],
            hideMainMenu: true,
        },
    },
    {
        path: "/ivr_trees/new",
        name: "ivr_trees_new",
        component: () => import( /* webpackChunkName: "group-app" */ '../views/ivr_trees/new.vue'),
        meta: {
            allowedRoles: [Role.SUPER_ADMIN, Role.MANAGER, Role.OWNER],
        },
    },

    // Job Titles
    {
        path: "/job_titles",
        name: "job_titles_index",
        component: () => import( /* webpackChunkName: "group-app" */ '../views/job_titles/index.vue'),
        meta: {
            allowedRoles: [Role.SUPER_ADMIN, Role.MANAGER, Role.OWNER],
        },
    },
    {
        path: "/job_titles/new",
        name: "job_titles_new",
        component: () => import( /* webpackChunkName: "group-app" */ '../views/job_titles/new.vue'),
        meta: {
            allowedRoles: [Role.SUPER_ADMIN, Role.MANAGER, Role.OWNER],
        },
    },
    {
        path: "/job_titles/:vid/edit",
        name: "job_titles_edit",
        component: () => import( /* webpackChunkName: "group-app" */ '../views/job_titles/edit.vue'),
        meta: {
            allowedRoles: [Role.SUPER_ADMIN, Role.MANAGER, Role.OWNER],
        },
    },

    // Messages
    {
        path: "/messages",
        name: "messages_index",
        component: () => import( /* webpackChunkName: "group-app" */ '../views/messages/index.vue'),
        meta: {
            allowedRoles: [Role.SUPER_ADMIN, Role.MANAGER, Role.OWNER],
        },
    },

    // Missed Calls
    {
        path: "/missed_calls",
        name: "missed_call_index",
        component: () => import( /* webpackChunkName: "group-app" */ '../views/voice_mail_recordings/index.vue'),
        meta: {
            allowedRoles: [Role.SUPER_ADMIN, Role.MANAGER, Role.OWNER, Role.SUPERVISOR, Role.ORGANIZATION_MANAGER],
        },
    },

    // Organizations CRUD
    {
        path: "/organizations",
        name: "organizations_index",
        component: () => import( /* webpackChunkName: "group-app" */ '../views/organizations/index.vue'),
        meta: {
            allowedRoles: [Role.SUPER_ADMIN, Role.MANAGER, Role.OWNER, Role.SUPERVISOR, Role.ORGANIZATION_MANAGER],
        }
    },
    {
        path: "/organizations/new",
        name: "organizations_new",
        component: () => import( /* webpackChunkName: "group-app" */ '../views/organizations/new.vue'),
        meta: {
            allowedRoles: [Role.SUPER_ADMIN, Role.MANAGER, Role.OWNER, Role.SUPERVISOR, Role.ORGANIZATION_MANAGER],
        }
    },
    {
        path: "/organizations/:vid/edit",
        name: "organizations_edit",
        component: () => import( /* webpackChunkName: "group-app" */ '../views/organizations/edit.vue'),
        meta: {
            allowedRoles: [Role.SUPER_ADMIN, Role.MANAGER, Role.OWNER, Role.SUPERVISOR, Role.ORGANIZATION_MANAGER],
        }
    },
    {
        path: "/organizations/:vid",
        name: "organizations_show",
        component: () => import( /* webpackChunkName: "group-app" */ '../views/organizations/show.vue'),
        meta: {
            allowedRoles: [Role.SUPER_ADMIN, Role.MANAGER, Role.OWNER, Role.SUPERVISOR, Role.ORGANIZATION_MANAGER],
        }
    },

    // Organization Departments
    {
        path: "/organizations/:organization_vid/departments/:vid",
        name: "organization_departments_show",
        component: () => import( /* webpackChunkName: "group-app" */ '../views/organizations/departments/show.vue'),
        meta: {
            allowedRoles: [Role.SUPER_ADMIN, Role.MANAGER, Role.OWNER, Role.SUPERVISOR, Role.ORGANIZATION_MANAGER],
        }
    },

    // Organizations vin solution
    {
        path: "/organizations_account_plugin",
        name: "organizations_account_plugin_index",
        component: () => import( /* webpackChunkName: "group-app" */ '../views/organizations_vin_solution/index.vue'),
        meta: {
            allowedRoles: [Role.SUPER_ADMIN],
        },
    },

    // Organizations mappings
    {
        path: "/organization_mappings",
        name: "organization_mappings_index",
        component: () => import( /* webpackChunkName: "group-app" */ '../views/organization_mappings/index.vue'),
        meta: {
            allowedRoles: [Role.SUPER_ADMIN],
        },
    },
    {
        path: "/organization_mappings/new",
        name: "organization_mappings_new",
        component: () => import( /* webpackChunkName: "group-app" */ '../views/organization_mappings/new.vue'),
        meta: {
            allowedRoles: [Role.SUPER_ADMIN],
        },
    },
    {
        path: "/organization_mappings/:mapping_id/edit",
        name: "organization_mappings_edit",
        component: () => import( /* webpackChunkName: "group-app" */ '../views/organization_mappings/edit.vue'),
        meta: {
            allowedRoles: [Role.SUPER_ADMIN],
        },
    },

    // Organization Plugins
    {
        path: "/organization_plugins",
        name: "organization_plugin_index",
        component: () => import( /* webpackChunkName: "group-app" */ '../views/organization_plugins/index.vue'),
        meta: {
            allowedRoles: [Role.SUPER_ADMIN, Role.MANAGER, Role.OWNER],
        },
    },
    {
        path: "/organization_plugins/new",
        name: "organization_plugin_new",
        component: () => import( /* webpackChunkName: "group-app" */ '../views/organization_plugins/new.vue'),
        meta: {
            allowedRoles: [Role.SUPER_ADMIN, Role.MANAGER, Role.OWNER],
        },
    },
    {
        path: "/organization_plugins/:organization_plugin_vid/edit",
        name: "organization_plugin_edit",
        component: () => import( /* webpackChunkName: "group-app" */ '../views/organization_plugins/edit.vue'),
        meta: {
            allowedRoles: [Role.SUPER_ADMIN, Role.MANAGER, Role.OWNER],
        },
    },

    // Organization Plugin Mappings
    // Promax Mappings
    {
        path: "/organization_plugins/:organization_plugin_vid/promax_mappings",
        name: "organization_plugin_promax_mappings_index",
        component: () => import( /* webpackChunkName: "group-app" */ '../views/organization_plugins/crm_disposition_mapping/index.vue'),
        meta: {
            allowedRoles: [Role.SUPER_ADMIN],
        },
    },

    {
        path: "/organization_plugins/:organization_plugin_vid/promax_mapping/new",
        name: "organization_plugin_promax_mappings_new",
        component: () => import( /* webpackChunkName: "group-app" */ '../views/organization_plugins/crm_disposition_mapping/new.vue'),
        meta: {
            allowedRoles: [Role.SUPER_ADMIN],
        },
    },

    {
        path: "/organization_plugins/:organization_plugin_vid/promax_mapping/:vid/edit",
        name: "organization_plugin_promax_mappings_edit",
        component: () => import( /* webpackChunkName: "group-app" */ '../views/organization_plugins/crm_disposition_mapping/edit.vue'),
        meta: {
            allowedRoles: [Role.SUPER_ADMIN],
        },
    },
    // Subaru Mappings
    {
        path: "/organization_plugins/:organization_plugin_vid/subaru_mappings",
        name: "organization_plugin_subaru_mappings_index",
        component: () => import( /* webpackChunkName: "group-app" */ '../views/organization_plugins/crm_disposition_mapping/index.vue'),
        meta: {
            allowedRoles: [Role.SUPER_ADMIN],
        },
    },

    {
        path: "/organization_plugins/:organization_plugin_vid/subaru_mapping/new",
        name: "organization_plugin_subaru_mappings_new",
        component: () => import( /* webpackChunkName: "group-app" */ '../views/organization_plugins/crm_disposition_mapping/new.vue'),
        meta: {
            allowedRoles: [Role.SUPER_ADMIN],
        },
    },

    {
        path: "/organization_plugins/:organization_plugin_vid/subaru_mapping/:vid/edit",
        name: "organization_plugin_subaru_mappings_edit",
        component: () => import( /* webpackChunkName: "group-app" */ '../views/organization_plugins/crm_disposition_mapping/edit.vue'),
        meta: {
            allowedRoles: [Role.SUPER_ADMIN],
        },
    },
    // Vin Solutions Mappings
    {
        path: "/organization_plugins/:organization_plugin_vid/vin_solution_mappings",
        name: "organization_plugin_vin_solution_mappings_index",
        component: () => import( /* webpackChunkName: "group-app" */ '../views/organization_plugins/crm_disposition_mapping/index.vue'),
        meta: {
            allowedRoles: [Role.SUPER_ADMIN],
        },
    },

    {
        path: "/organization_plugins/:organization_plugin_vid/vin_solution_mappings/new",
        name: "organization_plugin_vin_solution_mappings_new",
        component: () => import( /* webpackChunkName: "group-app" */ '../views/organization_plugins/crm_disposition_mapping/new.vue'),
        meta: {
            allowedRoles: [Role.SUPER_ADMIN],
        },
    },

    {
        path: "/organization_plugins/:organization_plugin_vid/vin_solution_mappings/:vid/edit",
        name: "organization_plugin_vin_solution_mappings_edit",
        component: () => import( /* webpackChunkName: "group-app" */ '../views/organization_plugins/crm_disposition_mapping/edit.vue'),
        meta: {
            allowedRoles: [Role.SUPER_ADMIN],
        },
    },

    // Organizations postal codes
    {
        path: "/organization_postal_codes",
        name: "organization_postal_codes_index",
        component: () => import( /* webpackChunkName: "group-app" */ '../views/organization_postal_codes/index.vue'),
        meta: {
            allowedRoles: [Role.SUPER_ADMIN],
        },
    },
    {
        path: "/organization_postal_codes/new",
        name: "organization_postal_codes_new",
        component: () => import( /* webpackChunkName: "group-app" */ '../views/organization_postal_codes/new.vue'),
        meta: {
            allowedRoles: [Role.SUPER_ADMIN],
        },
    },
    {
        path: "/organization_postal_codes/:vid/edit",
        name: "organization_postal_codes_edit",
        component: () => import( /* webpackChunkName: "group-app" */ '../views/organization_postal_codes/edit.vue'),
        meta: {
            allowedRoles: [Role.SUPER_ADMIN],
        },
    },

    // Priority Management

    {
        path: "/priority_groups",
        name: "priority_groups_index",
        component: () => import( /* webpackChunkName: "group-app" */ '../views/priority_groups/index.vue'),
        meta: {
            allowedRoles: [ Role.SUPER_ADMIN, Role.MANAGER, Role.OWNER ]
        },
    },

    {
        path: "/priority_groups/:vid",
        name: "priority_groups_show",
        component: () => import( /* webpackChunkName: "group-app" */ '../views/priority_groups/show.vue'),
        meta: {
            allowedRoles: [ Role.SUPER_ADMIN, Role.MANAGER, Role.OWNER ]
        },
    },


    // Reports
    {
        path: "/agent_efficiency_report",
        name: "agent_efficiency_report",
        component: () => import( /* webpackChunkName: "group-app" */ '../views/reports/agent_efficiency_report.vue'),
        meta: {
            allowedRoles: [Role.SUPER_ADMIN, Role.MANAGER, Role.OWNER, Role.SUPERVISOR, Role.ORGANIZATION_MANAGER],
            hideMainMenu: true,
        },
    },
    {
        path: "/appointment_compensation_report",
        name: "appointment_compensation_report",
        component: () => import( /* webpackChunkName: "group-app" */ '../views/reports/appointment_compensation_report.vue'),
        meta: {
            allowedRoles: [Role.SUPER_ADMIN, Role.MANAGER, Role.OWNER, Role.SUPERVISOR, Role.ORGANIZATION_MANAGER],
            hideMainMenu: true,
        },
    },
    {
        path: "/campaign_appointment_compensation_report",
        name: "campaign_appointment_compensation_report",
        component: () => import( /* webpackChunkName: "group-app" */ '../views/reports/appointment_compensation_report.vue'),
        meta: {
            allowedRoles: [Role.SUPER_ADMIN, Role.MANAGER, Role.OWNER, Role.SUPERVISOR, Role.ORGANIZATION_MANAGER],
            hideMainMenu: true,
        },
    },
    {
        path: "/campaign_time_report",
        name: "campaign_time_report",
        component: () => import( /* webpackChunkName: "group-app" */ '../views/reports/campaign_time_report.vue'),
        meta: {
            allowedRoles: [Role.SUPER_ADMIN, Role.MANAGER, Role.OWNER, Role.SUPERVISOR, Role.ORGANIZATION_MANAGER],
            hideMainMenu: true,
        },
    },
    {
        path: "/communication_disposition_report",
        name: "communication_disposition_report",
        component: () => import( /* webpackChunkName: "group-app" */ '../views/reports/communication_disposition_report.vue'),
        meta: {
            allowedRoles: [Role.SUPER_ADMIN, Role.MANAGER, Role.OWNER, Role.SUPERVISOR, Role.ORGANIZATION_MANAGER],
            hideMainMenu: true,
        },
    },
    {
        path: "/email_summary_report",
        name: "email_summary_report",
        component: () => import( /* webpackChunkName: "group-app" */ '../views/reports/email_summary_report.vue'),
        meta: {
            allowedRoles: [Role.SUPER_ADMIN, Role.MANAGER, Role.OWNER, Role.SUPERVISOR, Role.ORGANIZATION_MANAGER],
            hideMainMenu: true,
        },
    },
    {
        path: "/heat_report",
        name: "heat_report",
        component: () => import( /* webpackChunkName: "group-app" */ '../views/reports/heat_report.vue'),
        meta: {
            allowedRoles: [Role.SUPER_ADMIN, Role.MANAGER, Role.OWNER, Role.SUPERVISOR, Role.ORGANIZATION_MANAGER],
            hideMainMenu: true,
        },
    },
    {
        path: "/performance_report",
        name: "performance_report",
        component: () => import( /* webpackChunkName: "group-app" */ '../views/reports/performance_report.vue'),
        meta: {
            allowedRoles: [Role.SUPER_ADMIN, Role.MANAGER, Role.OWNER, Role.SUPERVISOR, Role.ORGANIZATION_MANAGER],
            hideMainMenu: true,
        },
    },
    {
        path: "/roi_report",
        name: "roi_report",
        component: () => import( /* webpackChunkName: "group-app" */ '../views/reports/roi_report.vue'),
        meta: {
            allowedRoles: [ Role.SUPER_ADMIN, Role.MANAGER, Role.OWNER, Role.SUPERVISOR, Role.ORGANIZATION_MANAGER ],
            hideMainMenu: true,
        },
    },

    // Reyonlds RCI log, DMS Data
    {
        path: "/reynolds_rci_logs",
        name: "reynolds_rci_log_index",
        component: () => import( /* webpackChunkName: "group-app" */ '../views/reynolds_rci_logs/index.vue'),
        meta: {
            allowedRoles: [Role.SUPER_ADMIN],
        },
    },
    {
        path: "/reynolds_rci_logs/:rci_log_id",
        name: "reynolds_rci_log_show",
        component: () => import( /* webpackChunkName: "group-app" */ '../views/reynolds_rci_logs/show.vue'),
        meta: {
            allowedRoles: [Role.SUPER_ADMIN],
        },
    },

    // Scorecards
    {
        path: "/scorecards",
        name: "scorecards_index",
        component: () => import( /* webpackChunkName: "group-app" */ '../views/scorecards/index.vue'),
        meta: {
            allowedRoles: [Role.SUPER_ADMIN, Role.MANAGER, Role.OWNER, Role.SUPERVISOR, Role.ORGANIZATION_MANAGER],
            hideMainMenu: true,
        },
    },
    {
        path: "/scorecards/reports",
        name: "scorecard_reports_index",
        component: () => import( /* webpackChunkName: "group-app" */ '../views/scorecards/reports/index.vue'),
        meta: {
            allowedRoles: [Role.SUPER_ADMIN, Role.MANAGER, Role.OWNER, Role.SUPERVISOR, Role.ORGANIZATION_MANAGER],
            hideMainMenu: true,
        },
    },
    {
        path: "/scorecard_templates",
        name: "scorecard_templates_index",
        component: () => import( /* webpackChunkName: "group-app" */ '../views/scorecard_templates/index.vue'),
        meta: {
            allowedRoles: [Role.SUPER_ADMIN, Role.MANAGER, Role.OWNER, Role.SUPERVISOR, Role.ORGANIZATION_MANAGER],
            hideMainMenu: true,
        },
    },

    {
        path: "/scorecard_templates/:vid/edit",
        name: "scorecard_templates_edit",
        component: () => import( /* webpackChunkName: "group-app" */ '../views/scorecard_templates/edit.vue'),
        meta: {
            allowedRoles: [Role.SUPER_ADMIN],
            hideMainMenu: true,
        },
    },

    // Subscriptions
    {
        path: "/subscriptions",
        name: 'subscriptions_index',
        component: () => import( /* webpackChunkName: "group-app" */ '../views/subscriptions/index.vue'),
        meta: {
            allowedRoles: [Role.SUPER_ADMIN],
        },
    },
    {
        path: "/subscriptions/new",
        name: 'subscriptions_new',
        component: () => import( /* webpackChunkName: "group-app" */ '../views/subscriptions/new.vue'),
        meta: {
            allowedRoles: [Role.SUPER_ADMIN],
        },
    },
    {
        path: "/subscriptions/:vid/edit",
        name: 'subscriptions_edit',
        component: () => import( /* webpackChunkName: "group-app" */ '../views/subscriptions/edit.vue'),
        meta: {
            allowedRoles: [Role.SUPER_ADMIN],
        },
    },

    // Survey Attempts
    {
        path: "/survey_attempts",
        name: 'survey_attempts_index',
        component: () => import( /* webpackChunkName: "group-app" */ '../views/survey_attempts/index.vue'),
        meta: {
            allowedRoles: [Role.SUPER_ADMIN, Role.MANAGER, Role.OWNER],
        },
    },

    // Tags
    {
        path: "/tags",
        name: "tag_index",
        component: () => import( /* webpackChunkName: "group-app" */ '../views/tags/index.vue'),
        meta: {
            allowedRoles: [Role.SUPER_ADMIN, Role.MANAGER, Role.OWNER],
        },
    },
    {
        path: "/tags/new",
        name: "tag_new",
        component: () => import( /* webpackChunkName: "group-app" */ '../views/tags/new.vue'),
        meta: {
            allowedRoles: [Role.SUPER_ADMIN, Role.MANAGER, Role.OWNER],
        },
    },
    {
        path: "/tags/:tag_key/edit",
        name: "tag_edit",
        component: () => import( /* webpackChunkName: "group-app" */ '../views/tags/edit.vue'),
        meta: {
            allowedRoles: [Role.SUPER_ADMIN, Role.MANAGER, Role.OWNER],
        },
    },

    // Teams
    {
        path: "/teams",
        name: "teams_index",
        component: () => import( /* webpackChunkName: "group-app" */ '../views/teams/index.vue'),
        meta: {
            allowedRoles: [Role.SUPER_ADMIN, Role.MANAGER, Role.OWNER],
        },
    },
    {
        path: "/teams/new",
        name: "teams_new",
        component: () => import( /* webpackChunkName: "group-app" */ '../views/teams/new.vue'),
        meta: {
            allowedRoles: [Role.SUPER_ADMIN, Role.MANAGER, Role.OWNER],
        },
    },
    {
        path: "/teams/:vid/edit",
        name: "teams_edit",
        component: () => import( /* webpackChunkName: "group-app" */ '../views/teams/edit.vue'),
        meta: {
            allowedRoles: [Role.SUPER_ADMIN, Role.MANAGER, Role.OWNER],
        },
    },

    // Tekion Appointments
    {
        path: "/tekion_appointments",
        name: "tekion_appointments_index",
        component: () => import( /* webpackChunkName: "group-app" */ '../views/tekion_appointments/index.vue'),
        meta: {
            allowedRoles: [Role.SUPER_ADMIN, Role.MANAGER, Role.OWNER],
        },
    },
    {
        path: "/tekion_appointments/:tekion_appointment_key",
        name: "tekion_appointments_detail",
        component: () => import( /* webpackChunkName: "group-app" */ '../views/tekion_appointments/detail.vue'),
        meta: {
            allowedRoles: [Role.SUPER_ADMIN, Role.MANAGER, Role.OWNER],
        }
    },

    // Tekion Customers
    {
        path: "/tekion_customers",
        name: "tekion_customers_index",
        component: () => import( /* webpackChunkName: "group-app" */ '../views/tekion_customers/index.vue'),
        meta: {
            allowedRoles: [Role.SUPER_ADMIN, Role.MANAGER, Role.OWNER],
        },
    },

    // Tekion Deals
    {
        path: "/tekion_deals",
        name: "tekion_deals_index",
        component: () => import( /* webpackChunkName: "group-app" */ '../views/tekion_deals/index.vue'),
        meta: {
            allowedRoles: [Role.SUPER_ADMIN, Role.MANAGER, Role.OWNER],
        },
    },
    {
        path: "/tekion_deals/:tekion_deal_key",
        name: "tekion_deals_detail",
        component: () => import( /* webpackChunkName: "group-app" */ '../views/tekion_deals/detail.vue'),
        meta: {
            allowedRoles: [Role.SUPER_ADMIN, Role.MANAGER, Role.OWNER],
        }
    },

    // Tekion Employees
    {
        path: "/tekion_employees",
        name: "tekion_employees_index",
        component: () => import( /* webpackChunkName: "group-app" */ '../views/tekion_employees/index.vue'),
        meta: {
            allowedRoles: [Role.SUPER_ADMIN, Role.MANAGER, Role.OWNER],
        },
    },

    // Tekion Opcodes
    {
        path: "/tekion_opcodes",
        name: "tekion_opcodes_index",
        component: () => import( /* webpackChunkName: "group-app" */ '../views/tekion_opcodes/index.vue'),
        meta: {
            allowedRoles: [Role.SUPER_ADMIN, Role.MANAGER, Role.OWNER],
        },
    },
    {
        path: "/tekion_opcodes/:tekion_opcode_key",
        name: "tekion_opcodes_detail",
        component: () => import( /* webpackChunkName: "group-app" */ '../views/tekion_opcodes/detail.vue'),
        meta: {
            allowedRoles: [Role.SUPER_ADMIN, Role.MANAGER, Role.OWNER],
        }
    },

    // Tekion Vehicle Inventories
    {
        path: "/tekion_vehicle_inventories",
        name: "tekion_vehicle_inventories_index",
        component: () => import( /* webpackChunkName: "group-app" */ '../views/tekion_vehicle_inventories/index.vue'),
        meta: {
            allowedRoles: [Role.SUPER_ADMIN, Role.MANAGER, Role.OWNER],
        },
    },
    {
        path: "/tekion_vehicle_inventories/:tekion_vehicle_inventory_key",
        name: "tekion_vehicle_inventories_detail",
        component: () => import( /* webpackChunkName: "group-app" */ '../views/tekion_vehicle_inventories/detail.vue'),
        meta: {
            allowedRoles: [Role.SUPER_ADMIN, Role.MANAGER, Role.OWNER],
        }
    },

    // Tekion Vehicles
    {
        path: "/tekion_vehicles",
        name: "tekion_vehicles_index",
        component: () => import( /* webpackChunkName: "group-app" */ '../views/tekion_vehicles/index.vue'),
        meta: {
            allowedRoles: [Role.SUPER_ADMIN, Role.MANAGER, Role.OWNER],
        },
    },
    {
        path: "/tekion_vehicles/:tekion_vehicle_key",
        name: "tekion_vehicles_detail",
        component: () => import( /* webpackChunkName: "group-app" */ '../views/tekion_vehicles/detail.vue'),
        meta: {
            allowedRoles: [Role.SUPER_ADMIN, Role.MANAGER, Role.OWNER],
        }
    },

    // Tekion Transportation
    {
        path: "/tekion_transportation",
        name: "tekion_transportation_index",
        component: () => import( /* webpackChunkName: "group-app" */ '../views/tekion_transportation/index.vue'),
        meta: {
            allowedRoles: [Role.SUPER_ADMIN, Role.MANAGER, Role.OWNER],
        },
    },

    // Telematic Alerts
    {
        path: "/telematic_alerts",
        name: "telematic_alerts_index",
        component: () => import( /* webpackChunkName: "group-app" */ '../views/telematic_alerts/index.vue'),
        meta: {
            allowedRoles: [Role.SUPER_ADMIN, Role.MANAGER, Role.OWNER, Role.ORGANIZATION_MANAGER],
            hideMainMenu: true,
        },
    },

    // Tickets
    {
        path: "/tickets",
        name: "ticket_index",
        component: () => import( /* webpackChunkName: "group-app" */ '../views/tickets/index.vue'),
        meta: {
            allowedRoles: [Role.SUPER_ADMIN, Role.MANAGER, Role.OWNER, Role.ORGANIZATION_MANAGER],
            forceNavbarSize: { isMini: true }
        },
    },
    {
        path: "/tickets/new",
        name: "ticket_new",
        component: () => import( /* webpackChunkName: "group-app" */ '../views/tickets/new.vue'),
        meta: {
            allowedRoles: [Role.SUPER_ADMIN, Role.MANAGER, Role.OWNER, Role.ORGANIZATION_MANAGER],
        },
    },
    {
        path: "/tickets/:ticket_key",
        name: "ticket_show",
        component: () => import( /* webpackChunkName: "group-app" */ '../views/tickets/show.vue'),
        meta: {
            allowedRoles: [Role.SUPER_ADMIN, Role.MANAGER, Role.OWNER, Role.ORGANIZATION_MANAGER],
        },
    },
    {
        path: "/ticket_assigners",
        name: "ticket_assigner_index",
        component: () => import( /* webpackChunkName: "group-app" */ '../views/ticket_assigners/index.vue'),
        meta: {
            allowedRoles: [Role.SUPER_ADMIN, Role.MANAGER, Role.OWNER, Role.ORGANIZATION_MANAGER],
        },
    },

    // Internal ticket groups
    {
        path: "/ticket_groups",
        name: "ticket_group_index",
        component: () => import( /* webpackChunkName: "group-app" */ '../views/ticket_groups/index.vue'),
        meta: {
            allowedRoles: [Role.SUPER_ADMIN],
        },
    },
    {
        path: "/ticket_groups/new",
        name: "ticket_group_new",
        component: () => import( /* webpackChunkName: "group-app" */ '../views/ticket_groups/new.vue'),
        meta: {
            allowedRoles: [Role.SUPER_ADMIN],
        },
    },
    {
        path: "/ticket_groups/:internal_ticket_group_key/edit",
        name: "ticket_group_edit",
        component: () => import( /* webpackChunkName: "group-app" */ '../views/ticket_groups/edit.vue'),
        meta: {
            allowedRoles: [Role.SUPER_ADMIN],
        },
    },
    {
        path: "/ticket_groups/:internal_ticket_group_key",
        name: "ticket_group_view",
        component: () => import( /* webpackChunkName: "group-app" */ '../views/ticket_groups/view.vue'),
        meta: {
            allowedRoles: [Role.SUPER_ADMIN],
        },
    },

    // Us Federal DNC Files
    {
        path: "/us_federal_dnc_files",
        name: "us_federal_dnc_file_index",
        component: () => import( /* webpackChunkName: "group-app" */ '../views/us_federal_dnc_files/index.vue'),
        meta: {
            allowedRoles: [Role.SUPER_ADMIN],
        },
    },
    {
        path: "/us_federal_dnc_files/new",
        name: "us_federal_dnc_file_new",
        component: () => import( /* webpackChunkName: "group-app" */ '../views/us_federal_dnc_files/new.vue'),
        meta: {
            allowedRoles: [Role.SUPER_ADMIN],
        }
    },

    // Vehicle transaction statuses
    {
        path: "/vehicle_transaction_statuses",
        name: "vehicle_transaction_statuses_index",
        component: () => import( /* webpackChunkName: "group-app" */ '../views/vehicle_transaction_statuses/index.vue'),
        meta: {
            allowedRoles: [Role.SUPER_ADMIN, Role.MANAGER, Role.OWNER, Role.ORGANIZATION_MANAGER],
        },
    },

    // Vin solutions contacts
    {
        path: "/vin_solution_contacts/:organization_key",
        name: "vin_solution_contacts_index",
        component: () => import( /* webpackChunkName: "group-app" */ '../views/vin_solution_contacts/index.vue'),
        meta: {
            allowedRoles: [Role.SUPER_ADMIN, Role.MANAGER, Role.OWNER],
        },
    },
    {
        path: "/vin_solution_contacts/:organization_key/new",
        name: "vin_solution_contacts_new",
        component: () => import( /* webpackChunkName: "group-app" */ '../views/vin_solution_contacts/new.vue'),
        meta: {
            allowedRoles: [Role.SUPER_ADMIN, Role.MANAGER, Role.OWNER],
        },
    },

    // Vin solutions dealers
    {
        path: "/vin_solution_dealers",
        name: "vin_solution_dealer_index",
        component: () => import( /* webpackChunkName: "group-app" */ '../views/vin_solution_dealers/index.vue'),
        meta: {
            allowedRoles: [Role.SUPER_ADMIN],
        },
    },
    {
        path: "/vin_solution_dealers/:organization_key/view",
        name: "vin_solution_dealer_view",
        component: () => import( /* webpackChunkName: "group-app" */ '../views/vin_solution_dealers/view.vue'),
        meta: {
            allowedRoles: [Role.SUPER_ADMIN, Role.MANAGER, Role.OWNER],
        },
    },

    // Vin solutions inventories
    {
        path: "/vin_solution_inventories/:organization_key",
        name: "vin_solution_inventories_index",
        component: () => import( /* webpackChunkName: "group-app" */ '../views/vin_solution_inventories/index.vue'),
        meta: {
            allowedRoles: [Role.SUPER_ADMIN, Role.MANAGER, Role.OWNER],
        },
    },

    // Vin solutions lead group categories
    {
        path: "/vin_solution_lead_group_categories/:organization_key",
        name: "vin_solution_lead_group_categories_index",
        component: () => import( /* webpackChunkName: "group-app" */ '../views/vin_solution_lead_group_categories/index.vue'),
        meta: {
            allowedRoles: [Role.SUPER_ADMIN, Role.MANAGER, Role.OWNER],
        },
    },

    // Vin solutions lead sources
    {
        path: "/vin_solution_lead_sources/:organization_key",
        name: "vin_solution_lead_sources_index",
        component: () => import( /* webpackChunkName: "group-app" */ '../views/vin_solution_lead_sources/index.vue'),
        meta: {
            allowedRoles: [Role.SUPER_ADMIN, Role.MANAGER, Role.OWNER],
        },
    },

    // Vin solutions lead status types
    {
        path: "/vin_solution_lead_status_types/:organization_key",
        name: "vin_solution_lead_status_types_index",
        component: () => import( /* webpackChunkName: "group-app" */ '../views/vin_solution_lead_status_types/index.vue'),
        meta: {
            allowedRoles: [Role.SUPER_ADMIN, Role.MANAGER, Role.OWNER],
        },
    },

    // Vin solutions lead statuses
    {
        path: "/vin_solution_lead_statuses/:organization_key",
        name: "vin_solution_lead_statuses_index",
        component: () => import( /* webpackChunkName: "group-app" */ '../views/vin_solution_lead_statuses/index.vue'),
        meta: {
            allowedRoles: [Role.SUPER_ADMIN, Role.MANAGER, Role.OWNER],
        },
    },

    // Vin solutions lead types
    {
        path: "/vin_solution_lead_types/:organization_key",
        name: "vin_solution_lead_types_index",
        component: () => import( /* webpackChunkName: "group-app" */ '../views/vin_solution_lead_types/index.vue'),
        meta: {
            allowedRoles: [Role.SUPER_ADMIN, Role.MANAGER, Role.OWNER],
        },
    },

    // Vin solutions leads
    {
        path: "/vin_solution_leads/:organization_key",
        name: "vin_solution_leads_index",
        component: () => import( /* webpackChunkName: "group-app" */ '../views/vin_solution_leads/index.vue'),
        meta: {
            allowedRoles: [Role.SUPER_ADMIN, Role.MANAGER, Role.OWNER],
        },
    },
    {
        path: "/vin_solution_leads/:organization_key/new",
        name: "vin_solution_lead_new",
        component: () => import( /* webpackChunkName: "group-app" */ '../views/vin_solution_leads/new.vue'),
        meta: {
            allowedRoles: [Role.SUPER_ADMIN, Role.MANAGER, Role.OWNER],
        },
    },
    {
        path: "/vin_solution_leads/:organization_key/:lead_key/edit",
        name: "vin_solution_lead_edit",
        component: () => import( /* webpackChunkName: "group-app" */ '../views/vin_solution_leads/edit.vue'),
        meta: {
            allowedRoles: [Role.SUPER_ADMIN, Role.MANAGER, Role.OWNER],
        },
    },

    // Vin solutions logs
    {
        path: "/vin_solution_logs/:organization_key",
        name: "vin_solution_logs_index",
        component: () => import( /* webpackChunkName: "group-app" */ '../views/vin_solution_logs/index.vue'),
        meta: {
            allowedRoles: [Role.SUPER_ADMIN, Role.MANAGER, Role.OWNER],
        },
    },
    {
        path: "/vin_solution_logs/:organization_key/:log_id",
        name: "vin_solution_logs_detail",
        component: () => import( /* webpackChunkName: "group-app" */ '../views/vin_solution_logs/detail.vue'),
        meta: {
            allowedRoles: [Role.SUPER_ADMIN, Role.MANAGER, Role.OWNER],
        },
    },

    // Vin solutions users
    {
        path: "/vin_solution_users/:organization_key",
        name: "vin_solution_users_index",
        component: () => import( /* webpackChunkName: "group-app" */ '../views/vin_solution_users/index.vue'),
        meta: {
            allowedRoles: [Role.SUPER_ADMIN, Role.MANAGER, Role.OWNER],
        },
    },

    // Vin solutions vehicle makes
    {
        path: "/vin_solution_vehicle_makes/:organization_key",
        name: "vin_solution_vehicle_makes_index",
        component: () => import( /* webpackChunkName: "group-app" */ '../views/vin_solution_vehicle_makes/index.vue'),
        meta: {
            allowedRoles: [Role.SUPER_ADMIN, Role.MANAGER, Role.OWNER],
        },
    },

    // Vin solutions vehicle models
    {
        path: "/vin_solution_vehicle_models/:organization_key",
        name: "vin_solution_vehicle_models_index",
        component: () => import( /* webpackChunkName: "group-app" */ '../views/vin_solution_vehicle_models/index.vue'),
        meta: {
            allowedRoles: [Role.SUPER_ADMIN, Role.MANAGER, Role.OWNER],
        },
    },

    // Vin solutions vehicle trims
    {
        path: "/vin_solution_vehicle_trims/:organization_key",
        name: "vin_solution_vehicle_trims_index",
        component: () => import( /* webpackChunkName: "group-app" */ '../views/vin_solution_vehicle_trims/index.vue'),
        meta: {
            allowedRoles: [Role.SUPER_ADMIN, Role.MANAGER, Role.OWNER],
        },
    },

    // Vin solutions vehicle model years
    {
        path: "/vin_solution_vehicle_model_years/:organization_key",
        name: "vin_solution_vehicle_model_years_index",
        component: () => import( /* webpackChunkName: "group-app" */ '../views/vin_solution_vehicle_model_years/index.vue'),
        meta: {
            allowedRoles: [Role.SUPER_ADMIN, Role.MANAGER, Role.OWNER],
        },
    },

    // Webhooks
    {
        path: "/webhooks",
        name: "webhooks_index",
        component: () => import( /* webpackChunkName: "group-app" */ '../views/webhooks/index.vue'),
        meta: {
            allowedRoles: [Role.SUPER_ADMIN, Role.MANAGER, Role.OWNER],
        },
    },
    {
        path: "/webhooks/new",
        name: "webhooks_new",
        component: () => import( /* webpackChunkName: "group-app" */ '../views/webhooks/new.vue'),
        meta: {
            allowedRoles: [Role.SUPER_ADMIN, Role.MANAGER, Role.OWNER],
        },
        // {
        //     path: "/webhooks/:vid/edit",
        //     name: "webhook_edit",
        //     component: () => import( /* webpackChunkName: "group-app" */ '../views/webhooks/edit.vue'),
        //     meta: {
        //         allowedRoles: [ Role.SUPER_ADMIN, Role.MANAGER, Role.OWNER ],
        //     },
    },

    // White labels
    {
        path: "/white_labels",
        name: "white_label_index",
        component: () => import( /* webpackChunkName: "group-app" */ '../views/white_labels/index.vue'),
        meta: {
            allowedRoles: [Role.SUPER_ADMIN],
        },
    },
    {
        path: "/white_labels/new",
        name: "white_label_new",
        component: () => import( /* webpackChunkName: "group-app" */ '../views/white_labels/new.vue'),
        meta: {
            allowedRoles: [Role.SUPER_ADMIN],
        },
    },
    {
        path: "/white_labels/:vid/edit",
        name: "white_label_edit",
        component: () => import( /* webpackChunkName: "group-app" */ '../views/white_labels/edit.vue'),
        meta: {
            allowedRoles: [Role.SUPER_ADMIN],
        },
    },

    // Utilities
    {
        path: '/utilities',
        name: 'utilities',
        component: () => import( /* webpackChunkName: "group-utilities" */ '../views/utilities/index.vue'),
        meta: {
            allowedRoles: [Role.SUPER_ADMIN],
        },
    },

    {
        path: '/utilities/bad_addresses',
        name: 'utilities_bad_address_index',
        component: () => import( /* webpackChunkName: "group-utilities" */ '../views/utilities/bad_addresses/index.vue'),
        meta: {
            allowedRoles: [Role.SUPER_ADMIN]
        }
    },

    {
        path: '/utilities/auto_assistant_phone_numbers',
        name: 'utilities_auto_assistant_index',
        component: () => import( /* webpackChunkName: "group-utilities" */ '../views/utilities/auto_assistant_phone_numbers/index.vue'),
        meta: {
            allowedRoles: [Role.SUPER_ADMIN]
        }
    },
    {
        path: '/utilities/bad_email_addresses',
        name: 'utilities_bad_email_address_index',
        component: () => import( /* webpackChunkName: "group-utilities" */ '../views/utilities/bad_email_addresses/index.vue'),
        meta: {
            allowedRoles: [Role.SUPER_ADMIN]
        }
    },
    {
        path: '/utilities/missed_calls',
        name: 'missedCalls',
        component: () => import( /* webpackChunkName: "group-utilities" */ '../views/utilities/communications/missed_calls.vue'),
        meta: {
            allowedRoles: [Role.SUPER_ADMIN],
        },
    },
    {
        path: '/utilities/communication_responses',
        name: 'communication_responses_index',
        component: () => import( /* webpackChunkName: "group-utilities" */ '../views/utilities/communications/communication_responses/index.vue'),
        meta: {
            allowedRoles: [ Role.SUPER_ADMIN ],
        },
    },
    {
        path: '/utilities/scheduled_communications',
        name: 'scheduledCommunications',
        component: () => import( /* webpackChunkName: "group-utilities" */ '../views/utilities/communications/scheduled_communications.vue'),
        meta: {
            allowedRoles: [Role.SUPER_ADMIN],
        },
    },

    // Utilities - Bad Records
    {
        path: "/utilities/bad_phone_numbers",
        name: "badPhoneNumbers",
        component: () => import( /* webpackChunkName: "group-app" */ '../views/utilities/bad_records/bad_phone_numbers.vue'),
        meta: {
            allowedRoles: [Role.SUPER_ADMIN],
        },
    },


    // Utilities - Telnyx

    {
        path: '/utilities/telnyx',
        name: 'telnyx',
        component: () => import( /* webpackChunkName: "group-utilities" */ '../views/utilities/telnyx/index.vue'),
        meta: {
            allowedRoles: [Role.SUPER_ADMIN],
        },
    },

    // Utilities - Telnyx - Billing Group Show
    {
        path: '/utilities/telnyx/accounts/:vid',
        name: 'telnyx_billing_groups_show',
        component: () => import( /* webpackChunkName: "group-utilities" */ '../views/utilities/telnyx/show.vue'),
        meta: {
            allowedRoles: [Role.SUPER_ADMIN],
        },
    },

    // Utilities - Telnyx - Billing Groups - Brands - Show
    {
        path: '/accounts/:account_vid/atop_brands/:vid',
        name: 'telnyx_billing_groups_brands_show',
        component: () => import( /* webpackChunkName: "group-utilities" */ '../views/utilities/telnyx/brands/show.vue'),
        meta: {
            allowedRoles: [Role.SUPER_ADMIN],
        }
    },

    // Utilities - Telnyx - Billing Groups - Brands - New
    {
        path: '/accounts/:account_vid/brands/new',
        name: 'telnyx_billing_groups_brands_new',
        component: () => import( /* webpackChunkName: "group-utilities" */ '../views/utilities/telnyx/brands/new.vue'),
        meta: {
            allowedRoles: [Role.SUPER_ADMIN],
        }
    },

    // Utilities - Telnyx - Billing Groups - Brands - Edit
    {
        path: '/accounts/:account_vid/brands/:vid/edit',
        name: 'telnyx_billing_groups_brands_edit',
        component: () => import( /* webpackChunkName: "group-utilities" */ '../views/utilities/telnyx/brands/edit.vue'),
        meta: {
            allowedRoles: [Role.SUPER_ADMIN],
        }
    },

    // Utilities - Telnyx - Billing Groups - Brands - Campaigns - New
    {
        path: '/accounts/:account_vid/brands/:brand_vid/campaigns/new',
        name: 'telnyx_billing_groups_brands_campaigns_new',
        component: () => import( /* webpackChunkName: "group-utilities" */ '../views/utilities/telnyx/brands/campaigns/new.vue'),
        meta: {
            allowedRoles: [Role.SUPER_ADMIN],
        }
    },

    // Utilities - Telnyx - Billing Groups - Brands - Campaigns - Show
    {
        path: '/accounts/:account_vid/brands/:brand_vid/campaigns/:vid',
        name: 'telnyx_billing_groups_brands_campaigns_show',
        component: () => import( /* webpackChunkName: "group-utilities" */ '../views/utilities/telnyx/brands/campaigns/show.vue'),
        meta: {
            allowedRoles: [Role.SUPER_ADMIN],
        }
    },

    // Utilities - Telnyx - Billing Groups - Brands - Campaigns - Edit
    {
        path: '/accounts/:account_vid/brands/:brand_vid/campaigns/:vid/edit',
        name: 'telnyx_billing_groups_brands_campaigns_edit',
        component: () => import( /* webpackChunkName: "group-utilities" */ '../views/utilities/telnyx/brands/campaigns/edit.vue'),
        meta: {
            allowedRoles: [Role.SUPER_ADMIN],
        }
    },

    // Utitities - Users
    {
      path: "/utilities/user_logging",
      name: "utilities_user_logging_index",
      component: () =>
        import(
          /* webpackChunkName: "group-utilities" */ "../views/utilities/user_logging/index.vue"
        ),
      meta: {
        allowedRoles: [Role.SUPER_ADMIN],
      },
    },

    // Home redirect logic
    {
        path: "/",
        name: "root",
        meta: {
            allowedRoles: Role.All,
        },
        redirect: () => {
            const currentUser = volieStore.store.state.CurrentUser;
            if (currentUser) {
                if (currentUser.activated_role_id == Role.AGENT || currentUser.activated_role_id == Role.ORGANIZATION_AGENT) {
                    return "/communication_center";
                } else if (currentUser.activated_role_id == Role.PURCHASED_LIST_ONLY) {
                    leavingSite = process.env.VUE_APP_RAILS_VOLIE_BASE_URL + '/purchased_lists';
                } else if (currentUser.activated_role_id == Role.WHITE_GLOVE_DEALER) {
                    leavingSite = process.env.VUE_APP_RAILS_VOLIE_BASE_URL + '/white_glove_lease_portfolio';
                } else {
                    leavingSite = process.env.VUE_APP_RAILS_VOLIE_BASE_URL;
                }
            }
            return "";
        }
    },

    // System
    {
        path: "*",
        name: "Catch all",
        redirect: () => {
            return "/";
        },
        meta: { allowedRoles: Role.All, redirect: true },
    },
];

const router = new VueRouter({
    mode: 'history',
    base: "/",
    routes
})

router.beforeEach((to, from, next) => {

    if (leavingSite) {
        window.location.assign(leavingSite);
        return;
    }
    if (to.query.redirect_to) { // Handle redirect_to links
        window.location.assign(to.query.redirect_to as string);
        return;
    } else if (!volieStore.store.state.CurrentUser) { // Handle no current user
        if (to.matched.some((record) => record.meta.noauth)) { // Allow noauth routes to be visited
            return;
        }
        window.location.replace('/auth/ab/login');
        return
    } else if (to.matched.length === 0) { // Handle no routes matched
        next();
        return
    }

    // Handle role not allowed
    const user = volieStore.store.state.CurrentUser;
    if (!to.matched.some((m) => {
        return m.meta.allowedRoles?.some((r) => {
            return r === user.activated_role_id;
        });
    })) {
        // window.location.assign(`/`);
        console.error("role not authorized")
        return
    }

    if (from) {
        sessionStorage.setItem("route_from", from.path);
    }
    if (to) {
        sessionStorage.setItem("route_to", to.path);
    }
    next();
});

export default router;
