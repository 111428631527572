import { get, post } from '.';

export function GetRasaniLoanOfficerRoster(options = {}) {
	return get<any>("/app/api/loan_officer_roster_rasani", options);
}

export function GetZillowValueRasani(options = {}) {
	return get<any>("/app/api/zillow_value_rasani", options);
}

export function PingPostRasani(options = {}) {
	return post<any>("/app/api/ping_post_rasani", options);
}
