<template lang="pug">
    .ma-sm-2
        v-container
            v-form
                v-row(align="center")
                    v-col(cols='6')
                        v-text-field(label='Department' type='text' v-model="searchObj.department_name")
                    v-col(cols='6')
                        v-text-field(label='Employee' type='text' v-model="searchObj.user_email_or_full_name")
                v-row(align="center")
                    v-col(cols='12')
                        .text-right
                            v-btn.mr-4(
                                @click='resetSearch()'
                            )
                                v-icon mdi-refresh
                                | Reset
                            v-btn.mr-4(
                                color='info'
                                @click='searchContacts()'
                                :disabled="disableSearchButton"
                            )
                                v-icon mdi-magnify
                                | Search
        
        .text-center(v-if="isLoading")
            v-progress-circular(
                :size='100'
                :width='10'
                color='#0f5b9a'
                indeterminate
                style="margin-top: 100px;"
            )


        v-alert(
            outlined
            type='info'
            dense
            border='left'
            style="margin-top: 16px;"
            v-else-if="departments.length === 0"
        )
            | Unable to find any contacts. Please adjust your search criteria.

        v-card.mx-auto.mt-md-4.mb-md-4(
            v-else
            v-for='department in departments'
            :key='department.department_id'
            outlined
        )

            v-toolbar.volie-v-toolbar-color(dark dense)
                v-icon(style="margin-right: 5px;") mdi-office-building
                v-toolbar-title Department: {{ department.department_name }}

            div(v-if="department.contacts && department.contacts.length > 0")
                v-simple-table
                    template(v-slot:default)
                        thead
                            tr
                                th.table-cell-padding
                                    .text-subtitle-1.font-weight-medium Contacts
                                    .text-caption
                                        | Successful call transfers today:
                                        strong {{ (department.daily_completed_call_transfers || 0) | number }}
                        tbody
                            tr(v-for='contact in department.contacts' :key='contact.contact_id')
                                td.table-cell-padding
                                    .text-capitalize.text-overline.text--secondary.text--darken-3 {{ contact.friendly_contact_method }}:
                                    | {{ contact.friendly_value }}

                                    div(v-if="contact.notes && contact.notes.length > 0")
                                        .text-capitalize.caption.text--secondary.text--darken-3 Notes:
                                        .communication-screen-contact-notes-margin(v-html="contact.notes")
                                    
                                    .text-center(v-if="isTransferContact(contact.contact_method) && validCall")
                                        v-btn.ma-2.mt-4(outlined x-small color="primary" light @click="openTransferDialog('department', 'external', 'blind', department.department_id, contact.value);")
                                            v-icon mdi-eye-off
                                            | Blind Transfer
                                        v-btn.ma-2.mt-4(outlined x-small color="error" light @click="openTransferDialog('department', 'external', 'warm', department.department_id, contact.value);")
                                            v-icon mdi-fire
                                            | Warm Transfer

            v-divider.divider-spacing
            .text-subtitle-2.employee-subtitle.text-center
                | Employees

            div(v-if="department.employees && department.employees.length > 0")
                v-card.employee-card(
                    outlined
                    v-for='employee in department.employees'
                    :key='employee.employee_id'
                )
                    v-simple-table
                        template(v-slot:default)
                            thead
                                tr
                                    th.table-cell-padding
                                        .text-subtitle-2 {{ employee.full_name }} <{{ employee.email }}>
                                        .caption
                                            | Job Title:
                                            strong(v-if="employee.job_title_id > 0") {{ employee.job_title_name }}
                                            template(v-else) N/a
                                        .caption
                                            | Successful call transfers today:
                                            strong {{ (employee.daily_completed_call_transfers || 0) | number }}
                            tbody
                                tr(v-for='contact in employee.contacts' :key='contact.contact_id')
                                    td.table-cell-padding
                                        .text-capitalize.text-overline.text--secondary.text--darken-3 {{ contact.friendly_contact_method }}:
                                        | {{ contact.friendly_value }}
                                        div(v-if="contact.notes && contact.notes.length > 0")
                                            .text-capitalize.caption.text--secondary.text--darken-3 Notes:
                                            .communication-screen-contact-notes-margin(v-html="contact.notes")

                                        .text-center(v-if="isTransferContact(contact.contact_method) && validCall")
                                            v-btn.ma-2.mt-4(outlined x-small color="primary" light @click="openTransferDialog('employee', 'external', 'blind', employee.employee_id, contact.value);")
                                                v-icon mdi-eye-off
                                                | Blind Transfer
                                            v-btn.ma-2.mt-4(outlined x-small color="error" light @click="openTransferDialog('employee', 'external', 'warm', employee.employee_id, contact.value);")
                                                v-icon mdi-fire
                                                | Warm Transfer

            v-simple-table(v-else)
                template(v-slot:default)
                    tbody
                        tr
                            td.text-center
                                .caption
                                    | No employees setup for this department

</template>

<script lang="ts">
    import Vue from 'vue';

    // Data
    import { GetCommunicationCenterOrganizationContacts } from "@/data";

    export default Vue.extend({
        data() {
            return {
                departments: [],
                isLoading: false,
                searchObj: null,
            }
        },
        methods: {
            getCommunicationCenterOrganizationContacts() {

                // Verify
                if (!this.organizationId) {
                    return;
                }

                const options = {
                    organization_id: this.organizationId,
                    department_name: this.searchObj.department_name,
                    user_email_or_full_name: this.searchObj.user_email_or_full_name,
                }

                this.isLoading = true;
                GetCommunicationCenterOrganizationContacts(options).then((response) => {
                    if (response?.Data?.departments?.length > 0) {
                        this.departments = response.Data.departments;
                    }
                    this.isLoading = false;
                });
            },
            isTransferContact(contactMethod) {
                return !!contactMethod && [1,2,3,7,8].indexOf(contactMethod) >= 0 ? true : false;
            },
            resetSearch() {
                this.searchObj = {
                    department_name: null,
                    user_email_or_full_name: null,
                };
                this.searchContacts();
            },
            searchContacts() {
                this.departments = [];
                this.getCommunicationCenterOrganizationContacts();
            },
        },
        computed: {
            disableSearchButton() {
                return !(this.searchObj?.department_name?.length > 0 || this.searchObj?.user_email_or_full_name?.length > 0);
            },
            validCall() {
                return this.callId > 0 && this.onCall === true;
            },
        },
        components: {
        },
        props: {
            callId: Number,
            onCall: Boolean,
            openTransferDialog: Function,
            organizationId: Number,
        },
        created() {
            this.resetSearch();
        },
    });
</script>

<style lang="scss" scoped>
    #app {
        .table-cell-padding {
            padding-top: 10px;
            padding-bottom: 10px;
        }

        .employee-subtitle {
            margin-bottom: 20px;
        }

        .employee-card {
            margin: 10px 10px 10px 10px;
        }

        .communication-screen-contact-notes-margin {
            margin-top: 5px;
            margin-left: 20px;
            margin-right: 20px;
        }
    }
</style>
