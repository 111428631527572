<template lang="pug">
    v-dialog(
        v-if="validDialog"
        v-model='dialog'
        max-width="600"
        persistent
        transition='dialog-bottom-transition'
    )
        template(v-slot:activator='{ on, attrs }')
            v-btn(color='primary' v-bind='attrs' v-on='on' small v-bind:class="[shareButtonClasses]")
                v-icon(small) mdi-export-variant
                | Share
        template(v-slot:default='dialog')
            v-card
                v-toolbar(dark color='primary')
                    v-toolbar-title Share {{ component | titleize }}
                    v-spacer
                    v-btn(icon dark @click='dialog.value = false; currentTab = null;')
                        v-icon mdi-close

                v-card-text
                    v-container
                        template(v-if="autoCreate")
                            Form(
                                :account-id="accountId"
                                :auto-create="autoCreate"
                                :component="component"
                                :shareable-id="shareableId"
                                :shareable-type="shareableType"
                            )

                        template(v-else)
                            v-tabs(fixed-tabs v-model="currentTab")
                                v-tab(v-for='(item, i) in tabItems' :key='item.key' :href="item.href")
                                    | {{ item.name }}

                            v-tabs-items
                                Form(
                                    v-if="currentTab === 'create-shared-link-tab'"
                                    :account-id="accountId"
                                    :component="component"
                                    :shareable-id="shareableId"
                                    :shareable-type="shareableType"
                                )

                                SharedLinks(
                                    v-else-if="currentTab === 'links-tab'"
                                    :component="component"
                                    :mobile-view="true"
                                    :shareable-id="shareableId"
                                    :shareable-type="shareableType"
                                )

</template>

<script lang="ts">
    import Vue from 'vue';

    // Components
    import Form from '@/components/shared_links/form.vue';
    import SharedLinks from '@/components/shared_links/index.vue';

    export default Vue.extend({
        data() {
            return {
                currentTab: null,
                dialog: false,
                tabItems: [
                    { name: "New Link", key: "new", href: "#create-shared-link-tab" },
                    { name: "Links", key: "links", href: "#links-tab" },
                ],
            }
        },
        methods: {
        },
        computed: {
            validComponent() {
                return ["conference_show_full", "customer_profile", "email_show", "message_show"].indexOf(this.component) >= 0;
            },
            validDialog() {
                return this.validComponent && this.validShareable;
            },
            validShareable() {
                return this.accountId > 0 && this.shareableId > 0 && ["conference", "customer", "email", "message"].indexOf(this.shareableType) >= 0;
            },
        },
        components: {
            Form,
            SharedLinks,
        },
        props: {
            accountId: Number,
            autoCreate: Boolean,
            component: String,
            shareableId: Number,
            shareableType: String,
            shareButtonClasses: String,
        },
    });
</script>

<style lang="scss" scoped>
</style>
