<template lang="pug">
    div
        div
            v-btn(
                v-if="downloadable"
                icon
                outlined
                :color="color"
                :href="fileToPlay"
                style="text-decoration: none; top: -10px;"
                class="float-right"
            )
                v-icon mdi-download
            
        
        div
            .audio-wrapper.ml-auto
                audio#audio-playback(
                    controls
                    controlsList="nodownload nofullscreen"
                    :src="fileToPlay"
                )
</template>

<script lang="ts">
import Vue from 'vue';

// Components

export default Vue.extend({
    data() {
        return {

        }
    },
    methods: {

    },
    computed: {
        fileToPlay() {
            if (this.file) {
                return this.file;
            }
            if (this.recordingUrl)  {
                return this.recordingUrl;
            }
            return "";
        }
    },
    components: {

    },
    props: {
        color: String,
        recordingUrl: String,
        file: String,
        downloadable: Boolean,
    },
    watch: {

    },
    created() {
    },
    destroyed() {
    },
    mounted() {
    },
})
</script>

<style lang="scss" scoped>

</style>