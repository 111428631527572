interface Tab {
    name: string;
    icon?: string;
    key:  string;
    href: string;
}

interface RightTabOptions {
    autobuyCrmEnabled?: boolean;
    autoAssistantName?: string;
    eleadEnabled: boolean;
    forteCrmEnabled: boolean;
    gmBacEnabled: boolean;
    inCommunicationCenter: boolean;
    inlineCrmEnabled: boolean;
    rasaniEnabled:  boolean;
    tekionEnabled?: boolean;
    toyoguardEnabled?: boolean;
    hasAutoAssist?: boolean;
    stellaEnabled?: boolean;
}

export function getCustomerProfileRightTabs(options: RightTabOptions): Tab[] {
    const rightTabs: Tab[] = [
        { name: "Appointment History",         key: "appointment_history",            href: "appointment-history-tab" },
        { name: "Campaign Enrollment History", key: "campaign_enrollment_history",    href: "campaign-enrollment-history-tab" },
        { name: "Communication History",       key: "communication_history",          href: "communication-history-tab" },
        { name: "Contacts",                    key: "contacts",                       href: "contacts-tab" },
        { name: "Custom Fields",               key: "custom_fields",                  href: "custom-fields-tab" },
        { name: "Disposition History",         key: "communication_dispositions_tab", href: "communication-dispositions-tab" },
        { name: "External Links",              key: "external_links",                 href: "external-links-tab" },
        { name: "Organization",                key: "organization",                   href: "organization-tab" },
        { name: "Promotions",                  key: "promotions",                     href: "promotions-tab" },
        { name: "Sales",                       key: "sales",                          href: "sales-tab" },
        { name: "Scheduled Communications",    key: "scheduled_communications",       href: "scheduled-communications-tab" },
        { name: "Services",                    key: "services",                       href: "services-tab" },
        { name: "Telematic Alerts",            key: "telematic_alerts",               href: "telematic-alerts-tab" },
        { name: "Vehicle Inventory",           key: "vehicle_inventories",            href: "vehicle-inventories-tab" },
        { name: "Vehicles",                    key: "customer_vehicles",              href: "customer-vehicles-tab" },
    ];

    if (options?.eleadEnabled === true) {
        rightTabs.push({ name: "Elead", key: "elead", href: "elead-tab" })
    }

    if (options?.toyoguardEnabled === true) {
        rightTabs.push({ name: "Toyoguard", key: "toyoguard", href: "toyoguard-tab"})
    }

    if (options?.tekionEnabled === true) {
        rightTabs.push({ name: "Tekion", key: "tekion", href: "tekion-tab"})
    }

    if (options?.hasAutoAssist === true || options?.stellaEnabled === true) {
        rightTabs.push({ name: setAutoAssistantTabName(options?.autoAssistantName), key: "auto-assistant", href: "auto-assistant-tab"})
    }

    if (options?.inCommunicationCenter === true) {


        if (options?.forteCrmEnabled === true) {
            rightTabs.push({ name: "Forte", key: "forte", href: "forte-tab" })
        }

        if (options?.inlineCrmEnabled === true) {
            rightTabs.push({ name: "Inline", key: "inline", href: "inline-tab" })
        }

        if (options?.gmBacEnabled === true) {
            rightTabs.push({name: "GM Repair Order History", key: "gm-bac", href: "gm-bac-tab"})
        }

        if (options?.rasaniEnabled === true) {
            rightTabs.push({ name: "Rasani Ping Post", key: "rasani-ping-post", href: "rasani-ping-post-tab" })
        }

    }

    const sortedRightTabs: Tab[] = rightTabs.sort((a, b) => {
        if (a.name < b.name) {
            return -1;
        }
        if (a.name > b.name) {
            return 1;
        }
        return 0;
    });

    return sortedRightTabs;
}

function setAutoAssistantTabName(name: string): string {
    switch (name) {
        case 'stella':
            return 'Stella Ai';
        default:
            return 'Auto Assistant';
    }
}

export function getInCommunicationCenterCustomerProfileLeftTabs(): Tab[] {
    const leftTabs: Tab[] = [
        { name: "Contact Info",       key: "customer",    href: "customer-tab", icon: 'mdi-contacts', },
        { name: "Prospects",          key: "prospects",   href: "prospects-tab", icon: 'mdi-folder-account', },
        { name: "Timeline",           key: "timeline",    href: "timeline-tab", icon: 'mdi-timeline', },
        { name: "Submit Disposition", key: "disposition", href: "disposition-tab", icon: 'mdi-pencil', }, // should be last
    ]

    return leftTabs;
}

export function getNonCommunicationCenterCustomerProfileLeftTabs(): Tab[] {
    const leftTabs: Tab[] = [
        { name: "Contact Info",       key: "customer",    href: "customer-tab", icon: 'mdi-contacts', },
        { name: "Prospects",          key: "prospects",   href: "prospects-tab", icon: 'mdi-folder-account', },
        { name: "Timeline",           key: "timeline",    href: "timeline-tab", icon: 'mdi-timeline', },
    ]

    return leftTabs;
}
