import { render, staticRenderFns } from "./forte_crm.vue?vue&type=template&id=d5b0231c&scoped=true&lang=pug"
import script from "./forte_crm.vue?vue&type=script&lang=ts"
export * from "./forte_crm.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d5b0231c",
  null
  
)

export default component.exports