import { get, post, destroy } from '.';

export function GetCampaignTypeMessageTemplates(options = {}) {
    return get<any>("/app/api/campaign_type_message_templates", options);
}

export function GetAvailableCampaignTypeMessageTemplates(options = {}) {
    return get<any>("/app/api/campaign_type_message_templates_available", options);
}

export function CreateCampaignTypeMessageTemplates(id, options = {}) {
    return post<any>("/app/api/campaign_types/" + (id || 0).toString() + "/message_templates", options);
}

export function DestroyCampaignTypeMessageTemplate(id) {
    return destroy<any>("/app/api/campaign_type_message_templates/" + (id || 0).toString());
}

export function DestroyAllCampaignTypeMessageTemplates(id) {
    return destroy<any>("/app/api/campaign_type_message_templates_all/" + (id || 0).toString());
}