<template lang="pug">
    form(v-if="validXtimeLink" target="_blank" :action="xtimeLink.base_url" method="post" enctype="application/x-www-form-urlencoded")
        input(type="text" name="w" id="w" :value="xtimeLink.dealer_key" hidden="true")
        input(type="text" name="Dc" id="Dc" :value="xtimeLink.dc_param" hidden="true")
        input(type="text" name="Di" id="Di" :value="xtimeLink.di_param" hidden="true")
        input(type="text" name="pn" id="pn" :value="xtimeLink.phone_number" hidden="true")
        input(type="text" name="fn" id="fn" :value="xtimeLink.first_name" hidden="true")
        input(type="text" name="ln" id="ln" :value="xtimeLink.last_name" hidden="true")
        input(type="text" name="vin" id="vin" :value="xtimeLink.vin" hidden="true")
    
        v-btn(type="submit" color="#337ab7" small text)
            v-icon(dark small) mdi-open-in-new
            | xtime

</template>

<script lang="ts">
    import Vue from 'vue';

    export default Vue.extend({
        computed: {
            validXtimeLink() {
                return this.xtimeLink?.dealer_key?.length > 0 && this.xtimeLink?.dc_param?.length > 0 && this.xtimeLink?.di_param?.length > 0 && this.xtimeLink?.base_url?.length > 0 && this.xtimeLink?.phone_number?.length > 0;
            },
        },
        props: {
            xtimeLink: Object,
        },
    });
</script>

<style lang="scss" scoped>
</style>