<template lang="pug">
    v-dialog(v-model='dialog' persistent max-width='600px')
        template(v-slot:activator='{ on, attrs }')
            v-btn.white--text.mr-4(
                color="primary"
                v-bind='attrs'
                v-on='on'
                dark
                small
            )
                v-icon mdi-update
                | Save For Later

        v-card
            v-card-title
                span.headline Save For Later
            v-card-text(v-if="isLoading === false")
                v-container
                    v-alert(type="info")
                        | Please determine when you want to follow up with this customer in the future. If the customer texts back in before this scheduled time, the customer will just come up out of the queue automatically.

                    ScheduledCommunicationForm(
                        :campaign-id="campaignID"
                        :default-communication-type="communicationType"
                        :hide-assignment-select="true"
                        :hide-communication-type-select="true"
                        :hide-preference-select="true"
                        :on-scheduled-communication-change="onScheduledCommunicationChange"
                        :radio-select-override="radioSelectOverride"
                        :show-radio-selects="true"
                        :time-zone-id="customerTimeZoneId()"
                        :use-on-change="true"
                        :valid-customer="validCampaignCustomer"
                    )

            v-card-actions(v-if="isLoading === false")
                v-spacer
                v-btn.ma-2(outlined ripple text @click='dialog = false')
                    v-icon mdi-window-close
                    | Close

                v-btn.ma-2.white--text(
                    outlined
                    ripple
                    color="green"
                    text
                    @click='createScheduledCommunication()'
                    :disabled="!okayToCreateScheduledCommunication"
                )
                    v-icon mdi-check
                    | Save

            v-card-text(v-if="isLoading === true")
                .text-center
                    v-progress-linear(
                        indeterminate
                        color="success"
                        height="15"
                        rounded
                    )


</template>

<script lang="ts">
    import Vue from 'vue';

    // Components
    import ScheduledCommunicationForm from '@/components/scheduled_communications/form.vue';

    // Data
    import { CreateScheduledCommunication } from "@/data";

    export default Vue.extend({
        data() {
            return {
                dialog: false,
                isLoading: false,
                radioSelectOverride: "hour4",
                scheduledCommunication: {
                    communication_type: null,
                    notes: null,
                    radio_select: "hour4",
                    scheduled_time_date: null,
                    scheduled_time_time: null,
                    time_zone_id: null,
                },
            }
        },
        methods: {
            createScheduledCommunication() {
                if (!this.okayToCreateScheduledCommunication) {
                    return;
                }

                const options = {
                    account_id: this.currentCampaignCustomer?.account_id,
                    assignment_id: (this.scheduledCommunication?.assignment_id || 0),
                    assignment_type: this.scheduledCommunication?.assignment_type,
                    campaign_customer_active_id: this.currentCampaignCustomer?.campaign_customer_active_id,
                    communication_type: this.scheduledCommunication.communication_type,
                    notes: this.scheduledCommunication.notes,
                    preference_id: (this.scheduledCommunication?.preference_id || 0),
                    preference_type: this.scheduledCommunication?.preference_type,
                    radio_select: this.scheduledCommunication.radio_select,
                    scheduled_time_date: this.scheduledCommunication.scheduled_time_date,
                    scheduled_time_time: this.scheduledCommunication.scheduled_time_time,
                    time_zone_id: this.scheduledCommunication.time_zone_id,
                }

                this.isLoading = true;
                CreateScheduledCommunication(options).then((response) => {
                    this.isLoading = false;
                    if (response?.Data?.scheduled_communication?.id > 0) {
                        this.dialog = false;
                        this.unassignCampaignCustomerActive();
                    }
                });
            },
            customerTimeZoneId() {
                // Try and use the customer's time zone
                if (this.currentCampaignCustomer?.customer?.time_zone_id > 0) {
                    return this.currentCampaignCustomer.customer.time_zone_id;
                }

                // Now try to use the organization's time zone
                if (this.currentCampaignCustomer?.organization_time_zone_id > 0) {
                    return this.currentCampaignCustomer?.organization_time_zone_id;
                }
            },
            onScheduledCommunicationChange(scheduledCommunication) {

                // Verify
                if (!scheduledCommunication) {
                    return;
                }

                // Set details
                this.scheduledCommunication.assignment_id = scheduledCommunication.assignment_id;
                this.scheduledCommunication.assignment_type = scheduledCommunication.assignment_type;
                this.scheduledCommunication.communication_type = scheduledCommunication.communication_type;
                this.scheduledCommunication.notes = scheduledCommunication.notes;
                this.scheduledCommunication.preference_id = scheduledCommunication.preference_id;
                this.scheduledCommunication.preference_type = scheduledCommunication.preference_type;
                this.scheduledCommunication.radio_select = scheduledCommunication.radio_select;
                this.scheduledCommunication.time_zone_id = scheduledCommunication.time_zone_id;
                
                // Only set these fields if radio select is custom
                if (scheduledCommunication.radio_select === "custom") {
                    this.scheduledCommunication.scheduled_time_date = scheduledCommunication.scheduled_time_date;
                    this.scheduledCommunication.scheduled_time_time = scheduledCommunication.scheduled_time_time;
                }
            },
        },
        computed: {
            campaignID() {
                return this.currentCampaignCustomer?.campaign_id;
            },
            okayToCreateScheduledCommunication() {
                if (this.scheduledCommunication.time_zone_id === null) {
                    return false;
                }

                // Custom
                if (this.scheduledCommunication.radio_select === "custom") {

                    // If any required values are null, disable button
                    if (this.scheduledCommunication.scheduled_time_date === null || this.scheduledCommunication.scheduled_time_time === null) {
                        return false;
                    }
                }

                // Verify campaign customer active
                if (!this.validCampaignCustomer) {
                    return false;
                }

                return true;
            },
            validCampaignCustomer() {
                return (this.currentCampaignCustomer?.campaign_customer_active_id > 0 && this.currentCampaignCustomer?.account_id > 0);
            },
        },
        components: {
            ScheduledCommunicationForm,
        },
        props: {
            communicationType: String,
            currentCampaignCustomer: Object,
            unassignCampaignCustomerActive: Function,
        },
        destroyed() {
        },
        created() {
        },
        mounted() {
        },
        watch: {
        },
    });
</script>

<style lang="scss" scoped>
</style>
