import { get, post, put, destroy } from ".";

export function GetCampaignSpecialInstructions(campaignId: string, options = {}) {
    return get<any>(`/app/api/campaigns/${campaignId}/special_instructions`, options);
}

export function GetAvailableSpecialInstructionsForCampaign(campaignId: string, options = {}) {
    return get<any>(`/app/api/campaigns/${campaignId}/special_instructions/available`, options);
}

export function AddSpecialInstructionToCampaign(campaignId: string, options = {}) {
    return post<any>(`/app/api/campaigns/${campaignId}/special_instructions`, options);
}

export function DeleteSpecialInstructionFromCampaign(campaignId: string, instructionId: string) {
    return destroy<any>(`/app/api/campaigns/${campaignId}/special_instructions/${instructionId}`);
}

export function UpdateSpecialInstructionOrdersInCampaign(campaignId: string, options = {}) {
    return put<any>(`/app/api/campaigns/${campaignId}/special_instructions_orders`, options);
}

