import 'core-js/stable';
import 'regenerator-runtime/runtime';

import Vue from 'vue';
import App from './App.vue';
import { crashDetector } from "./crash_detector";
import volieStore from './store';
import router from './router';
import SharedLinkApp from './shared_link/App.vue';
import SharedLinkRouter from './shared_link/router';
import vuetify from './plugins/vuetify';
import filters from "./filters";
import VueTimeago from 'vue-timeago';
import * as Sentry from "@sentry/vue";
import { Integrations } from "@sentry/tracing";
import { TiptapVuetifyPlugin } from 'tiptap-vuetify';
import 'tiptap-vuetify/dist/main.css'
import { NotAuthorized, cacheGet, cacheSet, validToken } from './data';
import VueRouter from 'vue-router';

const devMode = process.env.NODE_ENV !== 'production';
const prodMode = process.env.NODE_ENV === 'production';
Vue.config.productionTip = devMode;
Vue.config.devtools = devMode;

Vue.config.errorHandler = function(err, vm, info) {

    sessionStorage.setItem('error_event', JSON.stringify(err, Object.getOwnPropertyNames(err)));

}

const sharedLinkMode = window.location.pathname.startsWith("/s/");
const load = true;

// (function checkAuth(){
//     if (sharedLinkMode) { return }
//     const urlParams = new URLSearchParams(window.location.search);
//     const token = urlParams.get('token');
//     const redirectTo = urlParams.get('redirect_to');
//     if (token) {
//         cacheSet("token", token)
//         if (redirectTo) {
//             window.location.assign(redirectTo);
//         } else {
//             window.location.assign("/");
//         }
//     } else {
//         const tokenCheck = cacheGet<string>("token");
//         if (!validToken(tokenCheck)) {
//             cacheSet("token", null)
//             load = false;
//             window.location.replace("/auth/ab/login?redir=" + window.location.href);
//         }
//     }
// })();

if (load) {

    window.onerror = (a, b, c, d, e) => {
        sessionStorage.setItem('error_event', JSON.stringify(e, Object.getOwnPropertyNames(e)));
    };

    sessionStorage.setItem('log_xhr', 'false');

volieStore.load(sharedLinkMode).then(() => {

    const mixin = {
        methods: {}
    }
    Vue.mixin(mixin);
    filters();

    const store = volieStore.store;

    const dsn = process.env.VUE_APP_SENTRY_URL;
    if (prodMode && dsn) {
        Sentry.init({
          Vue: Vue,
          dsn: process.env.VUE_APP_SENTRY_URL,
          integrations: [new Integrations.BrowserTracing()],
          logErrors: true,

          // We recommend adjusting this value in production, or using tracesSampler
          // for finer control
          tracesSampleRate: 1.0,

          // ignoreErrors:[
          //   "Non-Error exception captured",
          //   "Non-Error promise rejection captured"
          // ],
        });

        const user = volieStore.currentUser();

        if (user) {
            Sentry.setUser({
                id: user.user_id,
                email: user.email,
            })
            if (user.log_crashes) {
                crashDetector.log = true;
            }
        }
    }

    if (sharedLinkMode) {
      new Vue({
          router: SharedLinkRouter,
          store,
          vuetify,
          render: h => h(SharedLinkApp)
      }).$mount('#app')
    } else {

      crashDetector.load();
      new Vue({
          router,
          store,
          vuetify,
          render: h => h(App)
      }).$mount('#app')
    }

}).catch((result) => {
        if (result == NotAuthorized) {
            window.location.replace("/auth/ab/login?redir=" + window.location.href);
        }
});


Vue.use(VueTimeago, {
    name: 'Timeago', // Component name, `Timeago` by default
    locale: undefined, // Default locale
    locales: {
        'zh-CN': require('date-fns/locale/zh_cn'),
        'ja': require('date-fns/locale/ja'),
    }
});

Vue.use(TiptapVuetifyPlugin, {
    // the next line is important! You need to provide the Vuetify Object to this place.
    vuetify, // same as "vuetify: vuetify"
    // optional, default to 'md' (default vuetify icons before v2.0.0)
    iconsGroup: 'mdi',
})

}

Vue.use(VueRouter)

