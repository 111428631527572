<template lang="pug">
    v-dialog(v-model='dialog' scrollable persistent max-width='600px')
        template(v-slot:activator='{ on }')
            v-btn(v-if="validCustomField" icon light v-on='on' @click="init()")
                v-icon mdi-circle-edit-outline
            v-btn(v-if='!validCustomField' outlined color="green" small light v-on='on' @click="init()")
                v-icon mdi-plus
                | Add Custom Field
        v-card
            v-card-title
                span.text-h5(v-if="!validCustomField") New Custom Field
                span.text-h5(v-if="validCustomField") Edit Custom Field
            v-card-text
                v-container
                    v-form(ref="form")
                        v-row
                            v-col(cols='12')
                                v-text-field(
                                    label='Key'
                                    v-model="key"
                                    clearable
                                    :rules="[rules.required]"
                                    required
                                    validate-on-blur
                                    @keydown.enter="handleEnterEvent()"
                                )

                            v-col(cols='12')
                                v-text-field(
                                    label='Value'
                                    v-model="value"
                                    clearable
                                    :rules="[rules.required]"
                                    required
                                    validate-on-blur
                                    @keydown.enter="handleEnterEvent()"
                                )

                            v-col(cols='12')
                                v-autocomplete(
                                    label='Data Type'
                                    v-model="dataType"
                                    :items="dataTypes"
                                    item-text="key"
                                    item-value="value"
                                    :rules="[rules.required]"
                                    required
                                    validate-on-blur
                                )

                            v-col(cols='12')
                                v-autocomplete(
                                    label='Modelable Type'
                                    v-model="modelableType"
                                    :items="modelableTypes"
                                    item-text="key"
                                    item-value="value"
                                    :rules="[rules.required]"
                                    required
                                    validate-on-blur
                                )

            v-card-actions.text-right
                v-spacer
                    v-btn.ma-2(color="#0f5b9a" outlined ripple @click='dialog = false; resetFormValues();')
                        v-icon mdi-window-close
                        | Close
                    v-btn.ma-2(v-if="!validCustomField" color="green" outlined ripple :disabled="!eligibleToSave" @click='handleCreateCustomField(); dialog = false; resetFormValues();')
                        v-icon mdi-content-save
                        | Save
                    v-btn.ma-2(v-if="validCustomField" color="green" outlined ripple :disabled="!eligibleToSave" @click='handleUpdateCustomField(); dialog = false; resetFormValues();')
                        v-icon mdi-content-save
                        | Save

</template>

<script lang="ts">
    import Vue from 'vue';

    export default Vue.extend({
        data() {
            return {
                dataType: null,
                dataTypes: [
                    {key: 'Boolean',    value: 'boolean'},
                    {key: 'Date',       value: 'date'},
                    {key: 'Datetime',   value: 'datetime'},
                    {key: 'Float',      value: 'float'},
                    {key: 'Integer',    value: 'integer'},
                    {key: 'JSON',       value: 'json'},
                    {key: 'Object',     value: 'object'},
                    {key: 'String',     value: 'string'},
                    {key: 'Text',       value: 'text'},
                    {key: 'Text Array', value: 'text array'},
                    {key: 'UUID',       value: 'uuid'},
                ],
                dialog: false,
                key: null,
                modelableId: null,
                modelableType: null,
                modelableTypes: [],
                rules: {
                    required: v => !!v || 'Required.',
                },
                value: null,
            }
        },
        methods: {
            handleEnterEvent(){
                if(!this.eligibleToSave) {
                    return
                }

                this.customField?.custom_field_id > 0 ? this.handleUpdateCustomField() : this.handleCreateCustomField();

            },
           handleCreateCustomField() {

                // Return if not valid options
                if (!this.eligibleToSave) {
                    return;
                }

                if (this.modelableType === "Customer") {
                    this.modelableId = this.customerId;
                } else if (this.modelableType === "CampaignCustomer") {
                    this.modelableId = this.campaignCustomerId;
                }

                const options = {
                    data_type:      this.dataType,
                    key:            this.key,
                    modelable_id:   this.modelableId,
                    modelable_type: this.modelableType,
                    value:          this.value,
                }

                this.createCustomField(options);
            },
           handleUpdateCustomField() {

                // Return if not valid options
                if (!(this.customField?.custom_field_id > 0 || !this.eligibleToSave)) {
                    return;
                }

                if (this.modelableType === "Customer") {
                    this.modelableId = this.customerId;
                } else if (this.modelableType === "CampaignCustomer") {
                    this.modelableId = this.campaignCustomerId;
                }

                const options = {
                    custom_field_id: this.customField.custom_field_id,
                    data_type:       this.dataType,
                    key:             this.key,
                    modelable_id:    this.modelableId,
                    modelable_type:  this.modelableType,
                    value:           this.value,
                }

                this.updateCustomField(options);
            },
            resetFormValues() {
                this.$refs.form.reset();
                this.$refs.form.resetValidation();
            },
            init() {
                // set modelableTypes
                this.modelableTypes = [
                    {
                        key:   "Customer",
                        value: "Customer",
                    },
                ]

                if (this.campaignCustomerId > 0) {
                    this.modelableTypes.push({
                        key:   "Campaign Customer",
                        value: "CampaignCustomer",
                    })
                }

                if (!this.validCustomField) {
                    this.modelableType = "Customer";

                    return;
                }

                this.dataType      = this.customField.data_type;
                this.key           = this.customField.key;
                this.modelableId   = this.customField.modelable_id;
                this.modelableType = this.customField.modelable_type;
                this.value         = this.customField.value;
                
                return;
            },
        },
        computed: {
            validCustomField() {
                return this.customField?.custom_field_id > 0
            },
            eligibleToSave() {
                if (!(this.dataType?.length > 0)) {
                    return false;
                } 

                if (!(this.key?.length > 0)) {
                    return false;
                }

                if (!(this.modelableType?.length > 0)) {
                    return false;
                }

                if (!(this.value?.length > 0)) {
                    return false;
                }

                return true;
            },
        },
        components: {},
        props: {
            campaignCustomerId: Number,
            customerId:         Number,
            customField:        Object,
            createCustomField:  Function,
            updateCustomField:  Function,
        },
    });
</script>

<style lang="scss" scoped>
</style>