<template lang="pug">
    .ma-sm-2()

        v-snackbar(
                v-model='snackbar.show'
                :color='snackbar.color'
                :timeout="snackbar.timeout"
                top
            )
                | {{ snackbar.text }}
                v-btn(dark text @click='snackbar.show = false')
                    | Close

        .text-center(v-if="isLoading")
            v-progress-circular(
                :size='100'
                :width='10'
                color='#0f5b9a'
                indeterminate
                style="margin-top: 100px;"
            )

        v-container.mx-auto(v-if="!isLoading")
            .text-subtitle-2.subtitle-2-padding.text-center
                | GM Repair Order History

            v-row(align="center" v-if="validGmRoHistoryLead && !gmRoHistoryLoaded" )
                v-col(cols='12')
                    v-form()

                        v-text-field(
                            v-model="gmRoHistoryLead.last_name"
                            label='Last Name'
                            rows="1"
                            clearable
                            outlined
                            dense
                            prepend-inner-icon="mdi-account"
                        )

                        v-text-field(
                            v-model="gmRoHistoryLead.email"
                            label='Email Address'
                            rows="1"
                            clearable
                            outlined
                            dense
                            prepend-inner-icon="mdi-email"
                        )

                        v-text-field(
                            v-model="gmRoHistoryLead.vin"
                            label='Vehicle VIN'
                            rows="1"
                            clearable
                            outlined
                            dense
                            prepend-inner-icon="mdi-car"
                        )

                        v-text-field(
                            v-model="gmRoHistoryLead.vehicle_odometer"
                            label='Vehicle Mileage'
                            rows="1"
                            clearable
                            outlined
                            dense
                            type="number"
                            prepend-inner-icon="mdi-car"
                        )

                        v-select(
                            prepend-inner-icon="mdi-ruler"
                            v-model='this.gmRoHistoryLead.mileageUnit'
                            :items='mileageUnits'
                            label='Mileage Unit'
                            item-text="name"
                            item-value="value"
                            outlined
                            dense
                        )

                        .text-right
                            v-btn.ma-2(
                                color='success'
                                outlined
                                ripple
                                :disabled="!validGmRoHistoryLeadForm"
                                @click='postToRetrieveGMROHistory()'
                            )
                                v-icon(style="margin-right: 5px;") mdi-arrow-right-circle-outline
                                | Get Repair Order History

            v-row(align="center" v-if="validGmRoHistory && gmRoHistoryLoaded" )
                v-col

                    v-btn.ml-auto(outlined color='info' @click="resetForm()") Reset Form

                    v-divider

                    div.pt-2
                        ExpandableCardListItem.mt-0.mb-4(
                            v-if="validGmRoHistory"
                            id="service-details-card"
                            :color="itemColor"
                            expandable
                            :is-expanded="showDetails === true"
                            :on-expand="() => showDetails = !showDetails"
                            title="RO History"
                        )
                            template(v-slot:body)
                                template(v-if="showDetails === true")
                                    v-card
                                        v-card-text(v-for="history in gmRoHistory.gm_ro_history_json.vehicleServiceHistory.repairOrderHistory").white.text--primary
                                            v-list.py-0(
                                                two-line
                                            )
                                                ListItem(
                                                    default-title="N/a"
                                                    icon="mdi-car"
                                                    indent
                                                    subtitle="Repair Date"
                                                    :title="history.repairOrderCompletedDate | dateFromDateTime"
                                                )
                                                ListItem(
                                                    default-title="N/a"
                                                    indent
                                                    subtitle="RO Number"
                                                    :title="history.roNumber"
                                                )
                                                ListItem(
                                                    default-title="N/a"
                                                    indent
                                                    subtitle="Recorded Odometer"
                                                    :title="displayMileageUnit(history.inDistanceMeasure, history.inDistanceUnitMeasure)"
                                                )

                                                v-list-item(style="min-height: 60px;")
                                                    v-list-item-icon
                                                    v-list-item-content.py-1
                                                        v-list-item-title
                                                            template(v-if="history.servicedAtThisDealer")
                                                                v-icon(color="success") mdi-check
                                                            template(v-else)
                                                                v-icon(color="error") mdi-close
                                                        v-list-item-subtitle
                                                            | Serviced at Dealer

                                                v-list-item(style="min-height: 60px;")
                                                    v-list-item-icon
                                                    v-list-item-content.py-1
                                                        v-card.mx-lg-auto.mt-md-4.mb-md-4.p-2(flat outlined :min-width="gmRoHistoryCardWidth" style="margin: 10px 0px !important;" color="purple lighten-5")
                                                            v-card-text
                                                                .h6 Repair Details
                                                                ul
                                                                    li(v-for="job in history.jobHistory" :key="`${history.repairOrderCompletedDate}-${job.operationId}`")
                                                                        | {{ job.operationName }}
                                                v-divider()

            v-row(align="center" v-else-if="!validGmRoHistory && gmRoHistoryLoaded" )
                v-col(cols="12")
                    v-alert(dense outlined type='warning' style="margin-top:16px;") The request failed to return results.
                v-col(cols="12")
                    v-btn(outlined color='info' @click="resetForm()") Try again





</template>

<script lang="ts">
    import Vue from 'vue';
    import VolieState from '@/store';


    import ExpandableCardListItem from '@/components/shared/expandable_card_list_item.vue';
    import ListItem from '@/components/shared/list_item.vue';

    // Data
    import { FindGmRoHistoryLead, GetGmRoHistory } from "@/data";
    import { resourceColorClass, } from "@/helpers/css_classes";

    export default Vue.extend({
        data() {
            return {
                bus: VolieState.websocketClient().eventBus(),
                gmRoHistoryLead: {},
                gmRoHistory: null,
                gmRoHistoryLoaded: false,
                gmRoHistoryCardWidth: 300,
                isLoading: false,
                mileageUnits: [
                    { name: "Kilometer",    value: 'kilometer' },
                    { name: "Mile",         value: "mile" },
                ],
                showDetails: true,
                snackbar: {
                    show: false,
                    timeout: 2500,
                },
            }
        },
        methods: {
            findGmRoHistoryLead() {
                if (!this.validGmRoHistoryLead) {
                    return;
                }
                const options = {
                    campaign_customer_id: this.currentCampaignCustomer?.campaign_customer_id,
                    organization_id:      this.currentCampaignCustomer?.organization_id,
                }

                this.isLoading = true;
                FindGmRoHistoryLead(options.campaign_customer_id, options).then((response) => {
                    if (response?.Data?.gm_ro_history?.campaign_customer_id > 0) {
                        this.gmRoHistoryLead = response.Data.gm_ro_history;
                        this.gmRoHistoryLead.mileageUnit = "mile";
                    } else {
                        this.showSnackbar("red", "Failed to find gm ro history")
                    }
                    this.isLoading = false;
                });
            },
            showSnackbar(color, text) {
                if (color?.length > 0 && text?.length > 0) {
                    this.snackbar.color = color;
                    this.snackbar.text = text;
                    this.snackbar.show = true;
                }
            },
            postToRetrieveGMROHistory() {

                const options = {
                    campaign_customer_id:   this.currentCampaignCustomer?.campaign_customer_id,
                    organization_id:        this.currentCampaignCustomer?.organization_id,
                    lastName:               this.gmRoHistoryLead.last_name,
                    email:                  this.gmRoHistoryLead.email,
                    mileage:                this.gmRoHistoryLead.vehicle_odometer,
                    mileageUnit:            this.gmRoHistoryLead.mileageUnit,
                    vin:                    this.gmRoHistoryLead.vin
                }

                this.isLoading = true;
                GetGmRoHistory(options).then((response) => {
                    if (!(response?.Error?.length > 0)) {
                        this.gmRoHistory = response.Data.gm_ro_history_data
                    } else {
                        this.showSnackbar("red", "Failed to retrieve Report Order History")
                    }

                    if(this.gmRoHistory?.gm_ro_history_json?.vehicleHistoryHeader?.vehicle?.vin?.length > 0) {
                        this.gmRoHistoryLoaded = true
                        this.isLoading = false
                    }
                });
            },
            onReceiveGmRoHistoryLongRequest(response) {

                // Verify we have the correct campaign customer
                const returnGmRoHistory = response?.Data
                if (!(returnGmRoHistory?.id > 0) && !(returnGmRoHistory?.campaign_customer_id === this.currentCampaignCustomer.campaign_customer_id)) {
                    this.gmRoHistoryLoaded = true
                    this.isLoading = false
                    return
                }

                // update the model to get the correct data
                this.gmRoHistory = returnGmRoHistory
                this.gmRoHistoryLoaded = true
                this.isLoading = false

            },
            resetForm() {
                this.gmRoHistory = null;
                this.gmRoHistoryLead = {};
                this.gmRoHistoryLoaded = false;
                this.findGmRoHistoryLead();
            },
            displayMileageUnit(mileage, label) {
                return  mileage + " " + label + "s";
            },
        },
        computed: {
            currentUser() {
                return VolieState.currentUser();
            },
            itemColor() {
                return resourceColorClass("Service");
            },
            isGmRoHistoryEnabled() {
                return this.currentUser?.account?.gm_ro_history_enabled === true;
            },
            validGmRoHistoryLeadForm() {
                return this.gmRoHistoryLead?.last_name?.length > 0 && this.gmRoHistoryLead?.email?.length > 0 && this.gmRoHistoryLead?.vehicle_odometer?.length > 0 && this.gmRoHistoryLead?.vin?.length > 0
            },
            validCampaignCustomer() {
                return this.currentCampaignCustomer?.campaign_customer_active_id > 0 && this.currentCampaignCustomer?.account_id > 0 && this.currentCampaignCustomer?.customer_id > 0 && this.currentCampaignCustomer?.organization_id > 0;
            },
            validGmRoHistory() {
                return this.gmRoHistory?.gm_ro_history_json?.vehicleServiceHistory?.repairOrderHistory?.length > 0;
            },
            validGmRoHistoryLead() {
                return this.validCampaignCustomer;
            },
        },
        components: {
            ExpandableCardListItem,
            ListItem,
        },
        props: {
            currentCampaignCustomer: Object,
        },
        created() {
            this.bus.subscribe({ type: "broadcast" , channel: { ID: null, Type: "updateGmRoHistory" }}, this.onReceiveGmRoHistoryLongRequest);
            this.findGmRoHistoryLead();
        },
        destroyed() {
            this.bus.unsubscribe({ type: "broadcast" , channel: { ID: null, Type: "updateGmRoHistory" }}, this.onReceiveGmRoHistoryLongRequest);
        }
    });
</script>

<style lang="scss" scoped>
</style>
