<template lang="pug">
    v-card.mx-lg-auto.mt-md-4.mb-md-4(outlined :width="emailRecipientCardWidth" style="margin: 10px 0px !important;")
        v-toolbar(flat)
            v-btn(
                color="primary"
                icon
                ripple
                @click="showDetails = !showDetails;"
            )
                v-icon(v-if="showDetails === true")
                    | mdi-chevron-down
                v-icon(v-else)
                    | mdi-chevron-right

            v-toolbar-title(style ="font-size: 14px;")
                | {{ emailRecipient.friendly_email_recipient_name }}
        v-divider
        v-card-text(v-if="showDetails === true")
            v-simple-table
                template(v-slot:default)
                    tbody
                        tr
                            td
                                .text-capitalize.text-overline.text--secondary.text--darken-3 Address
                                .cell-value-spacing {{ emailRecipient.to_address }}
                        tr
                            td
                                .text-capitalize.text-overline.text--secondary.text--darken-3 Name
                                .cell-value-spacing(v-if="emailRecipient.to_name && emailRecipient.to_name.length > 0") {{ emailRecipient.to_name }}
                                .cell-value-spacing(v-else) N/a
                        tr
                            td
                                .text-capitalize.text-overline.text--secondary.text--darken-3 Type
                                .cell-value-spacing.text-uppercase(v-if="emailRecipient.recipient_type && emailRecipient.recipient_type.length > 0") {{ emailRecipient.recipient_type }}
                                .cell-value-spacing(v-else) N/a
                        tr
                            td
                                .text-capitalize.text-overline.text--secondary.text--darken-3 Last Event
                                .cell-value-spacing(v-if="emailRecipient.last_email_event_friendly_event && emailRecipient.last_email_event_friendly_event.length > 0")
                                    EmailEvent(:name="emailRecipient.last_email_event_friendly_event")
                                .cell-value-spacing(v-else) N/a
                        tr
                            td
                                .text-capitalize.text-overline.text--secondary.text--darken-3 Last Event Timestamp
                                .cell-value-spacing(v-if="emailRecipient.last_email_event_friendly_timestamp && emailRecipient.last_email_event_friendly_timestamp.length > 0") {{ emailRecipient.last_email_event_friendly_timestamp }}
                                .cell-value-spacing(v-else) N/a

</template>

<script lang="ts">
    import Vue from 'vue';

    // Components
    import EmailEvent from "@/components/emails/email_event.vue";

    export default Vue.extend({
        data() {
            return {
                emailRecipientCardWidth: 300,
				showDetails: false,
            }
        },
        methods: {
            handleEmailDetailsResize() {
                const emailDetailsWidth = document?.getElementById('email-details-card')?.getBoundingClientRect()?.width
                if (emailDetailsWidth >= 375) {
                    this.emailRecipientCardWidth = emailDetailsWidth - 75
                } else {
                    this.emailRecipientCardWidth = 300
                }
            },
        },
        computed: {
            validEmailRecipient() {
                return this.emailRecipient?.email_recipient_key > 0;
            },
        },
        components: {
            EmailEvent,
        },
        props: {
            emailRecipient: Object,
        },
        watch: {
            emailRecipient: {
                handler() {
                    Vue.nextTick(() => { this.handleEmailDetailsResize(); });
                },
                deep: true,
            },
        },
        created() {
			window.addEventListener('resize', this.handleEmailDetailsResize);
        },
        destroyed() {
            window.removeEventListener('resize', this.handleEmailDetailsResize);
        },
        mounted() {
            this.handleEmailDetailsResize();
        },
    });
</script>

<style lang="scss" scoped>
    #app {
        .cell-value-spacing {
            padding: 0 0 5px 0;
        }
    }
</style>
