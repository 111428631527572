import { get, post, destroy } from '.';

export function GetTicketFollowers(ticketVid, options = {}) {
	return get<any>("/app/api/tickets/" + ticketVid + "/followers", options);
}

export function DestroyTicketFollower(options = {}) {
	return destroy<any>("/app/api/ticket_followers", options);
}

export function CreateTicketFollower(options = {}) {
	return post<any>("/app/api/ticket_followers", options);
}
