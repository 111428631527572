<template lang="pug">
    v-menu(
        bottom
        origin='center center'
        transition='scale-transition'
        open-on-hover
        v-if="messageTemplates && messageTemplates.length > 0"
    )
        template(v-slot:activator='{ on }')
            v-btn(color="#FFFFFF" light v-on='on' small)
                | Shortcuts
                v-icon
                    | mdi-chevron-down
        v-list
            v-list-item(
                v-for='(mt, i) in messageTemplates'
                :key='i'
                @click='applyMessageTemplate(mt);'
                ripple
            )
                v-list-item-content
                    v-list-item-title {{ mt.message_template_name }}
                    v-list-item-subtitle {{ mt.language_name }}
</template>

<script lang="ts">
    import Vue from 'vue';

    export default Vue.extend({
        data() {
            return {
            }
        },
        methods: {
        },
        computed: {
        },
        components: {
        },
        props: {
            applyMessageTemplate: Function,
            messageTemplates: Array,
        },
    });
</script>

<style lang="scss" scoped>
</style>
