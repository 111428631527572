<template lang="pug">
    .ma-sm-2(v-if="validInline")

        v-snackbar(
                v-model='snackbar.show'
                :color='snackbar.color'
                :timeout="snackbar.timeout"
                top
            )
                | {{ snackbar.text }}
                v-btn(dark text @click='snackbar.show = false')
                    | Close

        .text-center(v-if="isLoading")
            v-progress-circular(
                :size='100'
                :width='10'
                color='#0f5b9a'
                indeterminate
                style="margin-top: 100px;"
            )

        v-container.mx-auto(v-if="!isLoading")
            .text-subtitle-2.subtitle-2-padding.text-center
                | Inline

            v-alert(outlined type='warning' dense border='left' style="margin-top: 16px;" v-if="!validInlinePostLead")
                | Unable to find inline post lead

            v-row(align="center")
                v-col(cols='12')
                    v-form(v-if="validInlinePostLead")

                        v-text-field(
                            v-model="inlinePostLead.first_name"
                            label='First Name'
                            rows="1"
                            clearable
                            outlined
                            dense
                            prepend-inner-icon="mdi-account"
                        )

                        v-text-field(
                            v-model="inlinePostLead.last_name"
                            label='Last Name'
                            rows="1"
                            clearable
                            outlined
                            dense
                            prepend-inner-icon="mdi-account"
                        )

                        v-text-field(
                            v-model="inlinePostLead.phone_one"
                            label='Primary Phone Number'
                            rows="1"
                            clearable
                            outlined
                            dense
                            prepend-inner-icon="mdi-phone"
                        )

                        v-text-field(
                            v-model="inlinePostLead.email"
                            label='Email Address'
                            rows="1"
                            clearable
                            outlined
                            dense
                            prepend-inner-icon="mdi-email"
                        )

                        v-text-field(
                            v-model="inlinePostLead.address"
                            label='Street'
                            rows="1"
                            clearable
                            outlined
                            dense
                            prepend-inner-icon="mdi-map-marker"
                        )

                        v-text-field(
                            v-model="inlinePostLead.city"
                            label='City'
                            rows="1"
                            clearable
                            outlined
                            dense
                            prepend-inner-icon="mdi-map-marker"
                        )

                        v-text-field(
                            v-model="inlinePostLead.state_province"
                            label='Region'
                            rows="1"
                            clearable
                            outlined
                            dense
                            prepend-inner-icon="mdi-map-marker"
                        )

                        v-text-field(
                            v-model="inlinePostLead.postal_code"
                            label='Postal Code'
                            rows="1"
                            clearable
                            outlined
                            dense
                            prepend-inner-icon="mdi-map-marker"
                        )

                        v-text-field(
                            v-model="inlinePostLead.year"
                            label='Vehicle Model Year'
                            rows="1"
                            clearable
                            outlined
                            dense
                            prepend-inner-icon="mdi-car"
                        )

                        v-text-field(
                            v-model="inlinePostLead.make"
                            label='Vehicle Make'
                            rows="1"
                            clearable
                            outlined
                            dense
                            prepend-inner-icon="mdi-car"
                        )

                        v-text-field(
                            v-model="inlinePostLead.model"
                            label='Vehicle Model'
                            rows="1"
                            clearable
                            outlined
                            dense
                            prepend-inner-icon="mdi-car"
                        )

                        v-text-field(
                            v-model="inlinePostLead.vin"
                            label='Vehicle VIN'
                            rows="1"
                            clearable
                            outlined
                            dense
                            prepend-inner-icon="mdi-car"
                        )

                        v-text-field(
                            v-model="inlinePostLead.odometer"
                            label='Vehicle Mileage'
                            rows="1"
                            clearable
                            outlined
                            dense
                            prepend-inner-icon="mdi-car"
                        )

                        v-text-field(
                            v-model="inlinePostLead.responsecode"
                            label='Responsecode'
                            rows="1"
                            clearable
                            outlined
                            dense
                            prepend-inner-icon="mdi-key"
                        )

                        .text-right
                            v-btn.ma-2(
                                color='success'
                                outlined
                                ripple
                                @click='postToInlineCrm()'
                            )
                                v-icon(style="margin-right: 5px;") mdi-arrow-right-circle-outline
                                | Post to Inline

</template>

<script lang="ts">
    import Vue from 'vue';
    import VolieState from '@/store';

    // Data
    import { FindInlinePostLead, PostToInlineCrm } from "@/data";

    export default Vue.extend({
        data() {
            return {
                inlinePostLead: {},
                isLoading: false,
                snackbar: {
                    show: false,
                    timeout: 2500,
                },
            }
        },
        methods: {
            findInlinePostLead() {
                if (!this.validInline) {
                    return;
                }
                const options = {
                    account_id:           this.currentCampaignCustomer?.account_id,
                    campaign_customer_id: this.currentCampaignCustomer?.campaign_customer_id,
                    organization_id:      this.currentCampaignCustomer?.organization_id,
                }

                this.isLoading = true;
                FindInlinePostLead(options.campaign_customer_id, options).then((response) => {
                    if (response?.Data?.inline_post_lead?.campaign_customer_id > 0) {
                        this.inlinePostLead = response.Data.inline_post_lead;
                    } else {
                        this.showSnackbar("red", "Failed to find inline post lead")
                    }
                    this.isLoading = false;
                });
            },
            showSnackbar(color, text) {
                if (color?.length > 0 && text?.length > 0) {
                    this.snackbar.color = color;
                    this.snackbar.text = text;
                    this.snackbar.show = true;
                }
            },
            postToInlineCrm() {
                if (!this.validInlinePostLead) {
                    return;
                }

                const options = this.inlinePostLead;
                options.account_id           = this.currentCampaignCustomer.account_id;
                options.organization_id      = this.currentCampaignCustomer?.organization_id;
                options.campaign_customer_id = this.currentCampaignCustomer?.campaign_customer_id;
                options.campaign_id          = this.currentCampaignCustomer?.campaign_id;
                options.customer_id          = this.currentCampaignCustomer?.customer_id;

                this.isLoading = true;
                PostToInlineCrm(this.inlinePostLead).then((response) => {
                    if (!(response?.Error?.length > 0)) {
                        this.showSnackbar("green", "Successfully posted to Inline")
                    } else {
                        this.showSnackbar("red", "Failed to post to Inline")
                    }
                    this.isLoading = false;
                });
            },
        },
        computed: {
            currentUser() {
                return VolieState.currentUser();
            },
            isInlineCrmEnabled() {
                return this.currentUser?.account?.inline_crm_enabled === true;
            },
            validCampaignCustomer() {
                return this.currentCampaignCustomer?.campaign_customer_active_id > 0 && this.currentCampaignCustomer?.account_id > 0 && this.currentCampaignCustomer?.customer_id > 0 && this.currentCampaignCustomer?.organization_id > 0;
            },
            validInlinePostLead() {
                return this.inlinePostLead?.campaign_customer_id > 0 && this.inlinePostLead?.inline_crm_campaign_code?.length > 0 && this.inlinePostLead?.inline_crm_key?.length > 0 && this.inlinePostLead?.inline_crm_web_lead_url?.length > 0;
            },
            validInline() {
                return this.validCampaignCustomer && this.isInlineCrmEnabled;
            },
        },
        components: {
        },
        props: {
            currentCampaignCustomer: Object,
        },
        created() {
            this.findInlinePostLead();
        },
    });
</script>

<style lang="scss" scoped>
</style>
