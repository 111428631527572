<template lang="pug">
    .ma-sm-2(v-if="validOrganizationId")
        .text-center(v-if="isLoading")
            v-progress-circular(
                :size='100'
                :width='10'
                color='#0f5b9a'
                indeterminate
                style="margin-top: 100px; margin-bottom: 100px;"
            )

        v-container.mx-auto(v-if="!isLoading")
            .text-subtitle-2.subtitle-2-padding.text-center
                | External Links

            v-simple-table(v-if="validExternalLinks")
                template(v-slot:default)
                    thead
                        tr
                            th.text-capitalize.text-overline.text--secondary.text--darken-3(style="width: 80%;") Name
                            th.text-center(style="width: 20%;")
                    tbody
                        tr(v-if="validExternalLinks && !isLoading" v-for='externalLink in externalLinks' :key='externalLink.external_link_id')
                            td.text-left {{ externalLink.name }}
                            td.text-right
                                .cell-value-spacing(v-if="externalLink.link_url && externalLink.link_url.length > 0" style="word-wrap: break-word;")
                                    v-btn(
                                        color="primary"
                                        icon
                                        ripple
                                        exact
                                        :href="formatLinkUrl(externalLink.link_url)"
                                        target="_blank"
                                    )
                                        v-icon(small color="primary") mdi-open-in-new
                                .cell-value-spacing(v-else) N/a

            v-alert(outlined type='warning' dense border='left' style="margin-top: 16px;" v-if="!validExternalLinks")
                | No external links found

</template>

<script lang="ts">
    import Vue from 'vue';

    // Data
    import { GetExternalLinks } from "@/data";

    export default Vue.extend({
        data() {
            return {
                externalLinks: [],
                isLoading: false,
            }
        },
        methods: {
            formatLinkUrl(linkUrl) { 
                // if url doesn't include a scheme '//' such as www.google.com, vue href prop will prepend the current domain to the url
                if (linkUrl?.includes("//")) {
                    return linkUrl
                }

                return "//" + linkUrl
            },
            getExternalLinks() {
                if (!this.validOrganizationId) {
                    return;
                }

                const options = {
                    organization_id: this.organizationId,
                    campaign_id:     this.campaignId > 0 ? this.campaignId : null,
                    customer_id:     this.customerId > 0 ? this.customerId : null,
                }

                this.isLoading = true;
                GetExternalLinks(options).then((response) => {
                    if (response?.Data?.external_links?.length > 0) {
                        this.externalLinks = response.Data.external_links;
                    }

                    this.isLoading = false;
                });
            },
        },
        computed: {
            validExternalLinks() {
                return this.externalLinks?.length > 0;
            },
            validOrganizationId() {
                return this.organizationId > 0;
            },
        },
        components: {
        },
        props: {
            campaignId:     Number,
            customerId:     Number,
            organizationId: Number,
        },
        created() {
            this.getExternalLinks();
        },
    });
</script>

<style lang="scss" scoped>
</style>
