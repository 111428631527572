<template lang="pug">
    v-dialog(v-model='dialog' scrollable persistent max-width='600px')
        template(v-slot:activator='{ on }')
            v-btn(v-if='isEditing' icon :light="!dark" :dark="dark" v-on='on' @click='initCustomerVehicle();')
                v-icon() mdi-circle-edit-outline	
            v-btn(v-if='isNew' outlined color="green" small :light="!dark" :dark="dark" v-on='on' @click='initCustomerVehicle();')
                v-icon mdi-plus
                | Add Vehicle
        v-card
            v-card-title
                span(v-if='isEditing').text-h5 Edit Vehicle
                span(v-if='isNew').text-h5 Add New Vehicle
            v-card-text
                v-container
                    v-row
                        v-col(cols='6')
                            v-autocomplete(
                                label='Model Year'
                                v-model="vehicleModelYearDefinitionId"
                                :items="vehicleModelYearDefinitions"
                                :disabled="!(vehicleModelYearDefinitions && vehicleModelYearDefinitions.length > 0)"
                                item-text="name"
                                item-value="vehicle_model_year_definition_id"
                                @change="handleVehicleModelYearChange(false)"
                                clearable
                                :rules="[rules.required]"
                                required
                                validate-on-blur
                            )

                        v-col(cols='6')
                            v-autocomplete(
                                label='Make'
                                v-model="vehicleMakeDefinitionId"
                                :items="vehicleMakeDefinitions"
                                :disabled="!(vehicleMakeDefinitions && vehicleMakeDefinitions.length > 0)"
                                item-text="name"
                                item-value="vehicle_make_definition_id"
                                @change="handleVehicleMakeChange(false)"
                                clearable
                                :rules="[rules.required]"
                                required
                                validate-on-blur
                            )

                        v-col(cols='6')
                            v-autocomplete(
                                label='Model'
                                v-model="vehicleModelDefinitionId"
                                :items="vehicleModelDefinitions"
                                :disabled="!(vehicleModelDefinitions && vehicleModelDefinitions.length > 0)"
                                item-text="name"
                                item-value="vehicle_model_definition_id"
                                @change="handleVehicleModelChange(false)"
                                clearable
                                :rules="[rules.required]"
                                required
                                validate-on-blur
                            )

                        v-col(cols='6')
                            v-autocomplete(
                                label='Trim'
                                v-model="vehicleTrimDefinitionId"
                                :items="vehicleTrimDefinitions"
                                :disabled="!(vehicleTrimDefinitions && vehicleTrimDefinitions.length > 0)"
                                item-text="name"
                                item-value="vehicle_trim_definition_id"
                                @change="handleVehicleTrimChange(false)"
                                clearable
                            )

                        v-col(cols='12')
                            v-text-field(
                                @keydown.enter="handleEnterEvent()"
                                label='Vin'
                                v-model="vin"
                                clearable
                                :rules="[rules.vinRule]"
                                validate-on-blur
                            )

                        v-col(v-if='isEditing' cols='12')
                            v-btn(
                                color="primary"
                                icon
                                ripple
                                @click="noLongerOwns = !noLongerOwns;"
                            )
                                v-icon(v-if="noLongerOwns === false")
                                    | mdi-checkbox-marked
                                v-icon(v-else)
                                    | mdi-checkbox-blank-outline
                            span Currently Owns

            v-card-actions.text-right
                v-spacer
                    v-btn.ma-2(color="#0f5b9a" outlined ripple @click='dialog = false; reinit();') 
                        v-icon mdi-window-close
                        | Cancel
                    v-btn.ma-2(v-if='isEditing' color="green" outlined ripple :disabled="!eligibleToSave" @click='handleUpdateVehicle(); dialog = false; reinit();')
                        v-icon mdi-content-save
                        | Save
                    v-btn.ma-2(v-if='isNew' color="green" outlined ripple :disabled="!eligibleToSave" @click='handleCreateCustomerVehicle(); dialog = false; reinit();')
                        v-icon mdi-content-save
                        | Create

</template>

<script lang="ts">
import Vue from "vue";

// Data
import { GetVehicleMakeDefinitions } from "@/data";
import { GetVehicleModelDefinitions } from "@/data";
import { GetVehicleModelYearDefinitions } from "@/data";
import { GetVehicleTrimDefinitions } from "@/data";

export default Vue.extend({
  data() {
    return {
      customerVehicleId: null,
      dialog: false,
      noLongerOwns: false,
      rules: {
        required: (v) => !!v || v > 0 || "Required.",
        vinRule: (v) =>
          !v || v?.length === 0 || v.length === 17 || "Invalid vin",
      },
      vehicleId: null,
      vehicleMake: null,
      vehicleMakeDefinitionId: null,
      vehicleMakeDefinitions: [],
      vehicleModel: null,
      vehicleModelDefinitionId: null,
      vehicleModelDefinitions: [],
      vehicleModelYear: null,
      vehicleModelYearDefinitionId: null,
      vehicleModelYearDefinitions: [],
      vehicleTrim: null,
      vehicleTrimDefinitionId: null,
      vehicleTrimDefinitions: [],
      vin: null,
    };
  },
  methods: {
    handleCreateCustomerVehicle() {
      if (this.isEditing) {
        return;
      }

      // Set options
      const options = {
        vehicle_make: this.vehicleMake,
        vehicle_model: this.vehicleModel,
        vehicle_model_year: this.vehicleModelYear,
        vehicle_trim: this.vehicleTrim,
        vin: this.vin,
      };

      this.createCustomerVehicle(options);
    },
    handleEnterEvent() {
        if(this.isEditing) {
            this.handleUpdateVehicle()
        } else {
            this.handleCreateCustomerVehicle()
        }
    },
    handleUpdateVehicle() {
      if (this.isNew) {
        return;
      }

      // Set options
      const options = {
        customer_vehicle_id: this.customerVehicleId,
        no_longer_owns: this.noLongerOwns,
        vehicle_id: this.vehicleId,
        vehicle_make: this.vehicleMake,
        vehicle_model: this.vehicleModel,
        vehicle_model_year: this.vehicleModelYear,
        vehicle_trim: this.vehicleTrim,
        vin: this.vin,
      };

      this.updateVehicle(options);
    },
    getVehicleMakeDefinitions() {
      if (!(this.vehicleModelYearDefinitionId > 0)) {
        return;
      }

      // Set options
      const options = {
        vehicle_model_year_definition_id: this.vehicleModelYearDefinitionId,
      };

      GetVehicleMakeDefinitions(options).then((response) => {
        if (response?.Data?.vehicle_make_definitions?.length > 0) {
          this.vehicleMakeDefinitions = response.Data.vehicle_make_definitions;

          // Run the handle function in case the user already had selected a model and/or trim
          this.handleVehicleMakeChange(true);
        }
      });
    },
    getVehicleModelDefinitions() {
      if (!(this.vehicleMakeDefinitionId > 0)) {
        return;
      }

      // Set options
      const options = {
        vehicle_make_definition_id: this.vehicleMakeDefinitionId,
      };

      GetVehicleModelDefinitions(options).then((response) => {
        if (response?.Data?.vehicle_model_definitions?.length > 0) {
          this.vehicleModelDefinitions =
            response.Data.vehicle_model_definitions;

          // Run the handle function in case the user already had selected a trim
          this.handleVehicleModelChange(true);
        }
      });
    },
    getVehicleModelYearDefinitions() {
      GetVehicleModelYearDefinitions().then((response) => {
        if (response?.Data?.vehicle_model_year_definitions?.length > 0) {
          this.vehicleModelYearDefinitions =
            response.Data.vehicle_model_year_definitions;

          // Run the change function in case the user already had selected a make, model and/or trim
          this.handleVehicleModelYearChange(true);
        }
      });
    },
    getVehicleTrimDefinitions() {
      if (!(this.vehicleModelDefinitionId > 0)) {
        return;
      }

      // Set options
      const options = {
        vehicle_model_definition_id: this.vehicleModelDefinitionId,
      };

      GetVehicleTrimDefinitions(options).then((response) => {
        if (response?.Data?.vehicle_trim_definitions?.length > 0) {
          this.vehicleTrimDefinitions = response.Data.vehicle_trim_definitions;

          this.handleVehicleTrimChange(true);
        }
      });
    },
    handleVehicleMakeChange(attemptTranslate) {
      // Reset definitions that rely on selected make definition
      this.vehicleModelDefinitionId = null;
      this.vehicleModelDefinitions = [];
      this.vehicleTrimDefinitionId = null;
      this.vehicleTrimDefinitions = [];

      // Init
      let ok = true;

      // If true, attempt to use existing make if applicable
      if (attemptTranslate && this.translateMake()) {
        // Set ok to false so we don't set vehicle make since we translated successfully
        ok = false;
      }

      // Attempt to set vehicle make definition ID
      if (ok) {
        this.setVehicleMake();
      }

      // If we have a valid vehicle make definition ID, load vehicle model definitions
      if (this.vehicleMakeDefinitionId > 0) {
        // Reload vehicle model definitions
        this.getVehicleModelDefinitions();
      }

      return;
    },
    handleVehicleModelChange(attemptTranslate) {
      // Reset definitions that rely on selected model definition
      this.vehicleTrimDefinitionId = null;
      this.vehicleTrimDefinitions = [];

      // Init
      let ok = true;

      // If true, attempt to use existing model if applicable
      if (attemptTranslate && this.translateModel()) {
        // Set ok to false so we don't set vehicle model since we translated successfully
        ok = false;
      }

      // Attempt to set vehicle model definition ID
      if (ok) {
        this.setVehicleModel();
      }

      // If we have a valid vehicle model definition ID, load vehicle trim definitions
      if (this.vehicleModelDefinitionId > 0) {
        // Reload vehicle trim definitions
        this.getVehicleTrimDefinitions();
      }

      return;
    },
    handleVehicleModelYearChange(attemptTranslate) {
      // Reset definitions that rely on selected model year definition
      this.vehicleMakeDefinitionId = null;
      this.vehicleMakeDefinitions = [];
      this.vehicleModelDefinitionId = null;
      this.vehicleModelDefinitions = [];
      this.vehicleTrimDefinitionId = null;
      this.vehicleTrimDefinitions = [];

      // Init
      let ok = true;

      // If true, attempt to use existing model if applicable
      if (attemptTranslate && this.translateModelYear()) {
        // Set ok to false so we don't set vehicle model year since we translated successfully
        ok = false;
      }

      // Attempt to set vehicle model year definition ID
      if (ok) {
        this.setVehicleModelYear();
      }

      // If we have a valid vehicle model year definition ID, load vehicle make definitions
      if (this.vehicleModelYearDefinitionId > 0) {
        // Reload vehicle make definitions
        this.getVehicleMakeDefinitions();
      }

      return;
    },
    handleVehicleTrimChange(attemptTranslate) {
      let ok = true;
      if (attemptTranslate && this.translateTrim()) {
        ok = false;
      }

      if (ok) {
        this.setVehicleTrim();
      }

      return;
    },
    initCustomerVehicle() {
      if (this.customerVehicle?.customer_vehicle_id > 0) {
        this.customerVehicleId = this.customerVehicle.customer_vehicle_id;
        this.noLongerOwns = this.customerVehicle.no_longer_owns;
        this.vehicleId = this.customerVehicle.vehicle_id;
        this.vehicleMake = this.customerVehicle.vehicle_make;
        this.vehicleModel = this.customerVehicle.vehicle_model;
        this.vehicleModelYear = this.customerVehicle.vehicle_model_year;
        this.vehicleTrim = this.customerVehicle.vehicle_trim;
        this.vin = this.customerVehicle.vin;
      }

      // Setup form
      this.reinitAndLoadModelYearDefinitions();
    },
    reinit() {
      this.customerVehicleId = null;
      this.noLongerOwns = false;
      this.vehicleId = null;
      this.vehicleMake = null;
      this.vehicleModel = null;
      this.vehicleModelYear = null;
      this.vehicleTrim = null;
      this.vin = null;

      this.reinitDefinitions();
    },
    reinitDefinitions() {
      this.vehicleMakeDefinitionId = null;
      this.vehicleMakeDefinitions = [];
      this.vehicleModelDefinitionId = null;
      this.vehicleModelDefinitions = [];
      this.vehicleModelYearDefinitionId = null;
      this.vehicleModelYearDefinitions = [];
      this.vehicleTrimDefinitionId = null;
      this.vehicleTrimDefinitions = [];

      return;
    },
    reinitAndLoadModelYearDefinitions() {
      this.reinitDefinitions();
      this.getVehicleModelYearDefinitions();
      return;
    },
    setVehicleMake() {
      if (
        this.vehicleMakeDefinitions?.length > 0 &&
        this.vehicleMakeDefinitionId > 0
      ) {
        const idx = this.vehicleMakeDefinitions.findIndex(
          (obj) =>
            obj?.vehicle_make_definition_id === this.vehicleMakeDefinitionId
        );

        if (idx >= 0) {
          this.vehicleMake = this.vehicleMakeDefinitions[idx].name;
          return;
        }
      }

      this.vehicleMakeDefinitionId = null;
      this.vehicleMake = null;
      return;
    },
    setVehicleModel() {
      if (
        this.vehicleModelDefinitions?.length > 0 &&
        this.vehicleModelDefinitionId > 0
      ) {
        const idx = this.vehicleModelDefinitions.findIndex(
          (obj) =>
            obj?.vehicle_model_definition_id === this.vehicleModelDefinitionId
        );

        if (idx >= 0) {
          this.vehicleModel = this.vehicleModelDefinitions[idx].name;
          return;
        }
      }

      this.vehicleModelDefinitionId = null;
      this.vehicleModel = null;
      return;
    },
    setVehicleModelYear() {
      if (
        this.vehicleModelYearDefinitions?.length > 0 &&
        this.vehicleModelYearDefinitionId > 0
      ) {
        const idx = this.vehicleModelYearDefinitions.findIndex(
          (obj) =>
            obj?.vehicle_model_year_definition_id ===
            this.vehicleModelYearDefinitionId
        );

        if (idx >= 0) {
          this.vehicleModelYear = this.vehicleModelYearDefinitions[idx].name;
          return;
        }
      }

      this.vehicleModelYearDefinitionId = null;
      this.vehicleModelYear = null;
      return;
    },
    setVehicleTrim() {
      if (
        this.vehicleTrimDefinitions?.length > 0 &&
        this.vehicleTrimDefinitionId > 0
      ) {
        const idx = this.vehicleTrimDefinitions.findIndex(
          (obj) =>
            obj?.vehicle_trim_definition_id === this.vehicleTrimDefinitionId
        );

        if (idx >= 0) {
          this.vehicleTrim = this.vehicleTrimDefinitions[idx].name;
          return;
        }
      }

      this.vehicleTrimDefinitionId = null;
      this.vehicleTrim = null;
      return;
    },
    translateMake() {
      if (
        this.vehicleMakeDefinitions?.length > 0 &&
        this.vehicleMake?.length > 0
      ) {
        const idx = this.vehicleMakeDefinitions.findIndex(
          (obj) => obj?.name === this.vehicleMake
        );

        if (idx >= 0) {
          this.vehicleMakeDefinitionId =
            this.vehicleMakeDefinitions[idx].vehicle_make_definition_id;
          this.vehicleMake = this.vehicleMakeDefinitions[idx].name;
          return;
        }
      }

      this.vehicleMakeDefinitionId = null;
      this.vehicleMake = null;
      return;
    },
    translateModel() {
      if (
        this.vehicleModelDefinitions?.length > 0 &&
        this.vehicleModel?.length > 0
      ) {
        const idx = this.vehicleModelDefinitions.findIndex(
          (obj) => obj?.name === this.vehicleModel
        );

        if (idx >= 0) {
          this.vehicleModelDefinitionId =
            this.vehicleModelDefinitions[idx].vehicle_model_definition_id;
          this.vehicleModel = this.vehicleModelDefinitions[idx].name;
          return;
        }
      }

      this.vehicleModelDefinitionId = null;
      this.vehicleModel = null;
      return;
    },
    translateModelYear() {
      if (
        this.vehicleModelYearDefinitions?.length > 0 &&
        this.vehicleModelYear?.length > 0
      ) {
        const idx = this.vehicleModelYearDefinitions.findIndex(
          (obj) => obj?.name === this.vehicleModelYear
        );

        if (idx >= 0) {
          this.vehicleModelYearDefinitionId =
            this.vehicleModelYearDefinitions[
              idx
            ].vehicle_model_year_definition_id;
          this.vehicleModelYear = this.vehicleModelYearDefinitions[idx].name;
          return;
        }
      }

      this.vehicleModelYearDefinitionId = null;
      this.vehicleModelYear = null;
      return;
    },
    translateTrim() {
      if (
        this.vehicleTrimDefinitions?.length > 0 &&
        this.vehicleTrim?.length > 0
      ) {
        const idx = this.vehicleTrimDefinitions.findIndex(
          (obj) => obj?.name === this.vehicleTrim
        );

        if (idx >= 0) {
          this.vehicleTrimDefinitionId =
            this.vehicleTrimDefinitions[idx].vehicle_trim_definition_id;
          this.vehicleTrim = this.vehicleTrimDefinitions[idx].name;
          return true;
        }
      }

      this.vehicleTrimDefinitionId = null;
      this.vehicleTrim = null;
      return;
    },
  },
  computed: {
    eligibleToSave() {
      return (
        this.vehicleMake?.length > 0 &&
        this.vehicleMakeDefinitionId > 0 &&
        this.vehicleModel?.length > 0 &&
        this.vehicleModelDefinitionId > 0 &&
        this.vehicleModelYear?.length > 0 &&
        this.vehicleModelYearDefinitionId > 0 &&
        (!this.vin || this.vin.length === 0 || this.vin.length === 17)
      );
    },
    isEditing() {
      return this.customerVehicle?.customer_vehicle_id > 0;
    },
    isNew() {
      return !(this.customerVehicle?.customer_vehicle_id > 0);
    },
  },
  components: {},
  props: {
    customerVehicle: Object,
    createCustomerVehicle: Function,
    dark: Boolean,
    updateVehicle: Function,
  },
  created() {},
});
</script>

<style lang="scss" scoped>
</style>
