import { get, post, put, destroy } from '.';

export function CreateBadAddress(options = {}) {
    return post<any>("/app/api/bad_addresses", options);
}

export function DeleteBadAddress(id: string, options = {}) {
    return destroy<any>(`/app/api/bad_addresses/${id}`, options);
}

export function GetBadAddresses(options = {}) {
    return get<any>(`/app/api/bad_addresses`, options);
}

export function UpdateBadAddress(id: string, options = {}) {
    return put<any>(`/app/api/bad_addresses/${id}`, options);
}