<template lang="pug">
    div
        v-simple-table.table-x-scroll(:id="'table-fixed-header'+key")
            template(v-slot:default)
                slot(name="thead")
                    thead(:id="'table-fixed-header-head'+key")
                        slot(name="thead-tr")
                            tr(:class="tableHeaderRowClass")
                                slot(name="th-default")
                                    //- EXAMPLE BELOW
                                    //- th.text-left.no-wrap Column Name 1

                slot(name="tbody" :tableKey="key")
                    TBodyDraggable(:items="items" :id="'table-fixed-header-body'+key")
                        template(v-slot:tbody-draggable)
                            slot(name="tbody-tr")
                                tr(v-for='(item, idx) in items' v-if='!isLoading && validItems' :key='idx')
                                    slot(name="tr-default" :item="item" :idx="idx")
                                        //- Example Below
                                        //- td.no-wrap.xs-width
                                        //-     template(v-if="slotProps.item.field_1")
                                        //-         | {{ slotProps.item.field_1 }}
                                        //-     template(v-else) ---


                                tr(v-if="isLoading")
                                    td.text-center(colspan="100%")
                                        v-progress-circular(
                                            :size='100'
                                            :width='10'
                                            color='#0f5b9a'
                                            indeterminate
                                            style="margin-top: 75px; margin-bottom: 75px;"
                                        )

</template>

<script lang="ts">
    import Vue from 'vue';

    // Components
    import TBodyDraggable from "@/components/shared/tbody_draggable.vue";

    // Imports
    import { v4 as uuidv4 } from 'uuid';

    export default Vue.extend({
        data() {
            return {
                key: null,
            }
        },
        computed: {
            tableHeaderRowClass() {
                if (this.dark) {
                    return "volie-background-color-blue";
                }

                return "bg-white";
            },
            tableHeaderThClass() {
                if (this.dark) {
                    return "white--text";
                }

                return "";
            },
            validItems() {
                return this.items?.length > 0;
            },
        },
        props: {
            dark: Boolean, // dark = Blue table header row background, White table header text, light/default = White table header row background, Dark grey header text
            isLoading: Boolean,
            items: Array,
        },
        components: {
            TBodyDraggable,
        },
        created() {
            this.key = '-'+uuidv4();
        },
    });
</script>

<style lang="scss" scoped>
    #app {
        tbody {
            tr.total-row-footer{
                background-color: #7A7A7A !important;
                color: #FFFFFF !important;
                font-weight: 500 !important;
            }

            tr#total-row.total-row-footer:hover {
                background-color: #616161 !important;
            }
        }
    }
</style>
