import { get, post } from '.';

export function GetTekionVehicleInventories(options = {}) {
	return get<any>("/app/api/tekion/vehicle_inventories", options);
}

export function ImportTekionVehicleInventories(options = {}) {
	return post<any>("/app/api/tekion/vehicle_inventories/import", options);
}

export function GetTekionVehicleInventory(key) {
	return get<any>("/app/api/tekion/vehicle_inventories/" + key);
}
