<template lang="pug">
    v-container(v-if="validEmailThreads" style="padding: 0;")
        v-list(three-line elevation="2" style="padding: 0;")
            v-list-item-group(v-model="selectedIndex")
                template(v-for="(item, index) in emailThreads")
                    v-list-item(:key="item.last_email_id" v-model="item.selected")
                        v-list-item-icon(style="margin-top: 18px; margin-right: 0;")
                            v-icon(
                                v-if="item.unresponded_communication_response_id > 0"
                                color='primary'
                                x-small
                            ) mdi-circle
                        v-list-item-content
                            v-list-item-title.font-weight-bold {{ displayFrom(item.last_email_from_address, item.last_email_from_name) }}
                            v-list-item-title.font-weight-bold {{ item.subject }}
                            v-list-item-subtitle {{ item.last_email_text_plain_body }}
                        v-list-item-action
                            v-list-item-action-text(v-text='displayEmailTime(item.last_email_created_at)')
                            v-icon(small v-if="item.has_email_attachment") mdi-paperclip

                    v-divider(
                        v-if="index < emailThreads.length - 1"
                        :key="index"
                        style="margin: 0;"
                    )

</template>

<script lang="ts">
    import Vue from 'vue';
    import VolieState  from "@/store";

    // Components

    // Data
    import {
    } from "@/data";

    export default Vue.extend({
        data() {
            return {
                bus: VolieState.websocketClient().eventBus(),
                selectedIndex: null,
            }
        },
        methods: {
            displayFrom(fromEmail, fromName) {
                return fromName?.length > 0 ? fromName + " <" + fromEmail + ">" : fromEmail;
            },
            displayEmailTime(createdAt) {

                // Load date
                const d = new Date(createdAt);
                if (!d) {
                    return null;
                }

                // Build friendly date
                const friendlyDate = d.toLocaleDateString('en-US', {day: '2-digit', month:'2-digit', year:'numeric'});

                // If today's date, render time. Otherwise render local date
                return friendlyDate === this.todaysDate ? d.toLocaleTimeString('en-US', {hour: '2-digit', minute:'2-digit'}) : friendlyDate;
            },
            resyncThread() {
                // this.getCommunicationCenterEmailClient(true);
            },
        },
        computed: {
            todaysDate() {
                const d = new Date();
                return d?.toLocaleDateString('en-US', {day: '2-digit', month:'2-digit', year:'numeric'});
            },
            validEmailThreads() {
                return this.emailThreads?.length > 0;
            },
        },
        components: {
        },
        props: {
            emailThreads: Array,
            setSelectedEmailThread: Function,
        },
        destroyed() {
        },
        created() {
        },
        mounted() {
        },
        watch: {
            selectedIndex() {
                // Set selected
                if (this.validEmailThreads && this.selectedIndex >= 0) {
                    this.setSelectedEmailThread({ ...this.emailThreads[this.selectedIndex] });
                } else {
                    this.setSelectedEmailThread(null);
                }
            },
        },
    });
</script>

<style lang="scss" scoped>
</style>
