import { get, } from '.';

export function GetAgentEfficiencyReport(options = {}) {
	return get<any>("/app/api/agent_efficiency_report", options);
}

// export function GetAgentEfficiencyReportUserLoggedInTime(options = {}) {
// 	return get<any>("/app/api/agent_efficiency_report/user_logged_in_time", options);
// }

export function GetAppointmentCompensationReport(options = {}) {
	return get<any>("/app/api/appointment_compensation_report", options);
}

export function GetCampaignAppointmentCompensationReport(options = {}) {
	return get<any>("/app/api/campaign_appointment_compensation_report", options);
}

export function GetCampaignTimeReport(options = {}) {
	return get<any>("/app/api/campaign_time_report", options);
}

export function GetCampaignTimeAgentReport(options = {}) {
	return get<any>("/app/api/campaign_time_report/agents", options);
}

export function GetCampaignTimeReportCallQueueLogIns(options = {}) {
	return get<any>("/app/api/campaign_time_report/call_queue_log_ins", options);
}

export function GetCampaignUserHeatReport(options = {}) {
	return get<any>("/app/api/heat_report/campaign_users", options);
}

export function GetOverviewHeatReport(options = {}) {
	return get<any>("/app/api/heat_report/overview", options);
}

export function GetCommunicationDispositionSummaryReport(options ={}) {
	return get<any>("/app/api/communication_disposition_report/summary", options)
}

export function GetCommunicationDispositionSummaryTotal(options ={}) {
	return get<any>("/app/api/communication_disposition_report/summary_total", options)
}

export function GetCommunicationDispositionSummaryByDispositionTotal(options ={}) {
	return get<any>("/app/api/communication_disposition_report/summary_by_disposition_total", options)
}

export function GetCommunicationDispositionLogReport(options ={}) {
	return get<any>("/app/api/communication_disposition_report/log", options)
}

export function GetEmailDetailSummaryReport(options = {}) {
	return get<any>("/app/api/email_summary_detail_report", options);
}

export function GetEmailSummaryReport(options = {}) {
	return get<any>("/app/api/email_summary_report", options);
}

export function GetHeatReportDetails(options = {}) {
	return get<any>("/app/api/heat_report/details", options)
}

export function GetOverviewByOrganizationHeatReport(options = {}) {
	return get<any>("/app/api/heat_report/overview_by_organization", options);
}

export function GetOverviewCampaignSummaryByOrganizationHeatReport(options = {}) {
	return get<any>("/app/api/heat_report/campaign_summary_overview_by_organization", options);
}

export function GetPerformanceReport(options = {}) {
	return get<any>("/app/api/performance_report/summary", options);
}

export function GetPerformanceReportAppointmentSummary(options = {}) {
	return get<any>("/app/api/performance_report/appointment_summary", options);
}

export function GetPerformanceReportEnrollmentSummary(options = {}) {
	return get<any>("/app/api/performance_report/enrollment_summary", options);
}

export function GetPerformanceReportProspectSummary(options = {}) {
	return get<any>("/app/api/performance_report/prospect_summary", options);
}

export function GetPerformanceReportEnrolledCampaignCustomers(organizationId, options = {}) {
	return get<any>("/app/api/performance_report/organizations/" + (organizationId || 0).toString() + "/enrolled_campaign_customers", options);
}
