import { get, post } from '.';

export function GetTekionOpcodes(options = {}) {
	return get<any>("/app/api/tekion/opcodes", options);
}

export function ImportTekionOpcodes(options = {}) {
	return post<any>("/app/api/tekion/opcodes/import", options);
}

export function GetTekionOpcode(key) {
	return get<any>("/app/api/tekion/opcodes/" + key);
}
