<template lang="pug">
    v-navigation-drawer.navigation-drawer(
        v-if="!!currentUser && !!currentUser.account" 
        v-model='drawer' 
        app 
        clipped 
        dark
        permanent
        mini-variant-width='75'
        :mini-variant="mini"
        :expand-on-hover="mini"
        :key="mini"
    )
        v-list(dense)

            v-subheader.mt-4.white--text(v-if='!mini' style="margin-top: 0 !important;") {{ currentUser.account.name }}

            v-list-item(ripple link :href="railsVolieBaseUrl")
                v-list-item-action
                    v-icon mdi-home
                v-list-item-content
                    v-list-item-title Home

            //- v-list-item(ripple link :href="railsVolieBaseUrl + '/call_screen'")
            //-     v-list-item-action
            //-         v-icon mdi-phone
            //-     v-list-item-content
            //-         v-list-item-title Call Screen

            v-list-item(ripple link to="/communication_center")
                v-list-item-action
                    v-icon mdi-headphones
                v-list-item-content
                    v-list-item-title Communication Center

            v-list-item(ripple link :href="railsVolieBaseUrl + '/organizations'")
                v-list-item-action
                    v-icon mdi-office-building
                v-list-item-content
                    v-list-item-title Organizations

        v-divider

        v-list(dense)
            v-list-item(ripple link :href="railsVolieBaseUrl + '/users/' + currentUser.user_key + '/edit'")
                v-list-item-action
                    v-icon mdi-account
                v-list-item-content
                    v-list-item-title Profile

            v-list-item(ripple link :href="railsVolieBaseUrl + '/docs/api'")
                v-list-item-action
                    v-icon mdi-notebook
                v-list-item-content
                    v-list-item-title Api Docs

            v-list-item(ripple link :href="railsVolieBaseUrl + '/docs/how_tos'")
                v-list-item-action
                    v-icon mdi-help-circle
                v-list-item-content
                    v-list-item-title Help

            //- v-list-item(ripple link to="/privacy_policy")
            //-     v-list-item-action
            //-         v-icon mdi-security
            //-     v-list-item-content
            //-         v-list-item-title Privacy Policy

            //- v-list-item(ripple link to="/terms_and_conditions")
            //-     v-list-item-action
            //-         v-icon mdi-clipboard-text
            //-     v-list-item-content
            //-         v-list-item-title Terms and Conditions

            v-list-item(ripple link :href="railsVolieBaseUrl + '/log_out'")
                v-list-item-action
                    v-icon mdi-logout
                v-list-item-content
                    v-list-item-title Logout

</template>

<script lang="ts">
    import Vue from 'vue';
    import VolieState from '@/store';

    export default Vue.extend({
        data() {
            return {
                drawer: null,
                mini: false,
            }
        },
        methods: {
            handleWindowResize() {
                const windowWidth = window.innerWidth;
                if (windowWidth > 1200) {
                    this.mini = false;
                } else {
                    this.mini = true;
                }
            },
        },
        computed: {
            currentUser() {
                return VolieState.currentUser();
            },
            railsVolieBaseUrl() {
                return VolieState.baseRailsUrl();
            },
        },
        components: {
        },
        props: {
        },
        created() {
            window.addEventListener('resize', this.handleWindowResize);
        },
        mounted() {
            this.handleWindowResize();
        },
        destroyed() {
            window.removeEventListener('resize', this.handleWindowResize);
        },
    });
</script>

<style lang="scss" scoped>
    #app {
        .navigation-drawer {
            background-color: #0f5b9a;

            .v-list-item, .v-icon {
                color: #FFFFFF !important;
            }
            
            .active-item {
                background-color: #0277BD;
            }
        }
    }
</style>
