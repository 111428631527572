import { get, destroy, post } from '.';

export function GetCampaignInstantVoicemailMp3s(id: number, options = {}) {
	return get<any>(`/app/api/campaigns/${id}/campaign_instant_voicemail_mp3s`, options);
}

export function GetAvailableInstantVoicemailMp3s(id: number, options = {}) {
	return get<any>(`/app/api/campaigns/${id}/instant_voicemail_mp3s`, options);
}

export function DeleteCampaignInstantVoicemailMp3(id: number) {
	return destroy<any>(`/app/api/campaign_instant_voicemail_mp3s/${id}`); 
}

export function CreateCampaignInstantVoicemailMp3(id: number, options = {}) {
	return post<any>(`/app/api/campaigns/${id}/campaign_instant_voicemail_mp3s`, options);
}