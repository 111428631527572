import { get, post, put } from '.';

export function GetInternalTicketGroups(options = {}) {
	return get<any>("/app/api/internal_ticket_groups", options);
}

export function CreateInternalTicketGroup(options = {}) {
	return post<any>("/app/api/internal_ticket_groups", options);
}

export function UpdateInternalTicketGroup(id, options = {}) {
	return put<any>("/app/api/internal_ticket_groups/" + (id || 0).toString(), options);
}

export function FindInternalTicketGroup(id) {
	return get<any>("/app/api/internal_ticket_groups/" + (id || 0).toString());
}
