<template lang="pug">
    v-card-text
        template(v-if="validScheduledCommunication")
            v-list(two-line)
                template
                    v-list-item
                        v-list-item-content
                            v-list-item-title {{ scheduledCommunication.organization_name }}
                            v-list-item-subtitle.text--primary(v-text='scheduledCommunication.campaign_name')

                template(v-if="showCustomerName")
                    v-list-item
                        v-list-item-icon
                            v-icon(color='primary')
                                | mdi-account
                        v-list-item-content
                            v-list-item-title Customer
                            v-list-item-subtitle.text--primary {{ scheduledCommunication.customer_full_name }}

                template
                    v-list-item
                        v-list-item-icon
                            v-icon(color='primary')
                                | mdi-clock-outline
                        v-list-item-content
                            v-list-item-title Date / Time
                            v-list-item-subtitle.text--primary {{ scheduledCommunication.friendly_scheduled_datetime }}

                template
                    v-list-item
                        v-list-item-icon
                            v-icon(color='primary')
                                | mdi-account
                        v-list-item-content
                            v-list-item-title Scheduled By
                            v-list-item-subtitle.text--primary {{ scheduledCommunication.scheduled_by }} on {{ scheduledCommunication.friendly_created_date }}

                template
                    v-list-item
                        v-list-item-icon
                            v-icon(color='primary')
                                | mdi-account
                        v-list-item-content
                            v-list-item-title(v-if="scheduledCommunication.assignment_type === 'team'") Team Assignment
                            v-list-item-title(v-else-if="scheduledCommunication.assignment_type === 'user'") User Assignment
                            v-list-item-title(v-else) No Assignment

                            v-list-item-subtitle.text--primary(v-if="scheduledCommunication.assignment_name && scheduledCommunication.assignment_name.length > 0") {{ scheduledCommunication.assignment_name }}
                            v-list-item-subtitle.text--primary(v-else) ---

                template
                    v-list-item
                        v-list-item-icon
                            v-icon(color='primary')
                                | mdi-bullhorn
                        v-list-item-content
                            v-list-item-title Communication
                            v-list-item-subtitle.text--primary {{ scheduledCommunication.communication_type | titleize }}

                template
                    v-list-item
                        v-list-item-icon
                            v-icon(color='primary')
                                | mdi-account
                        v-list-item-content
                            v-list-item-title Contact Preference
                            v-list-item-subtitle.text--primary(v-if="scheduledCommunication.preference_type && scheduledCommunication.preference_type.length > 0") {{ scheduledCommunication.preference_value }}
                            v-list-item-subtitle.text--primary(v-else) ---

                template
                    v-divider(inset)
                    v-list-item
                        v-list-item-icon
                            v-icon(color='primary')
                                | mdi-pencil
                        v-list-item-content
                            v-list-item-title Notes
                            v-list-item-subtitle
                                template(v-if="scheduledCommunication.notes && scheduledCommunication.notes.length > 0") {{ scheduledCommunication.notes }}
                                template(v-else) ---

</template>

<script lang="ts">
    import Vue from 'vue';

    export default Vue.extend({
        data() {
            return {
            }
        },
        methods: {
        },
        computed: {
            validScheduledCommunication() {
                return this.scheduledCommunication?.scheduled_communication_id > 0;
            },
        },
        components: {
        },
        props: {
            scheduledCommunication: Object,
            showCustomerName: Boolean,
        },
    });
</script>

<style lang="scss" scoped>
</style>
