<template lang="pug">
    v-dialog(v-model='dialog' persistent max-width='600px')
        template(v-slot:activator='{ on }')
            v-btn(v-if="validCustomerPhoneNumber" icon light v-on='on' @click="init();")
                v-icon mdi-circle-edit-outline
            v-btn(v-else outlined color="green" small light v-on='on')
                v-icon mdi-plus
                | Add Phone Number
        v-card
            v-card-title
                span.text-h5(v-if="validCustomerPhoneNumber") Edit Phone Number
                span.text-h5(v-else) New Phone Number
            v-card-text
                v-container
                    v-row
                        v-col(cols='12')
                            v-select(
                                :items='countries'
                                :item-text="countryDisplay"
                                item-value="iso_two"
                                label='Country'
                                v-model="countryIsoTwo"
                            )
                    v-row
                        v-col(cols='12')
                            v-text-field(
                                label='Phone Number'
                                type='text'
                                v-model="phoneNumber"
                                :error-messages="errorMessage"
                                v-on:keypress="phoneNumberChange"
                                :hint="evaluatedNumberType"
                            )

                    v-row
                        v-col(cols='12')
                            v-select(
                                :items='availableCustomerPhoneNumberTypes'
                                item-text="name"
                                item-value="value"
                                label='Number Type'
                                v-model="numberType"
                            )

            v-card-actions.text-right
                v-spacer
                    v-btn.ma-2(color="#0f5b9a" outlined ripple @click='dialog = false; reinit();')
                        v-icon mdi-window-close
                        | Close
                    v-btn.ma-2(v-if="validCustomerPhoneNumber" color="green" outlined ripple :disabled="!eligibleToSave" @click='handleUpdateCustomerPhoneNumber(); dialog = false; reinit();')
                        v-icon mdi-content-save
                        | Save
                    v-btn.ma-2(v-else color="green" outlined ripple :disabled="!eligibleToSave" @click='handleCreateCustomerPhoneNumber(); dialog = false; reinit();')
                        v-icon mdi-content-save
                        | Save

</template>

<script lang="ts">
    import Vue from 'vue';
    import VolieState  from "@/store";

    // Helpers
    import { cleanupPhoneNumber } from "@/helpers/phone_number";

    export default Vue.extend({
        data() {
            return {
                availableCustomerPhoneNumberTypes: [
                    {
                        name: "Cell",
                        value: "cell",
                    },
                    {
                        name: "Home",
                        value: "home",
                    },
                    {
                        name: "Work",
                        value: "work",
                    },
                ],
                countryIsoTwo: null,
                dialog: false,
                errorMessage: null,
                evaluatedNumberType: null,
                numberType: null,
                phoneNumber: null,
                validPhoneNumber: false,
            }
        },
        methods: {
            countryDisplay(item) {
                return item?.name + ' (' + item?.dialing_code + ")";
            },
            handleCreateCustomerPhoneNumber() {

                // Return if not valid options
                if (!this.validPhoneNumber) {
                    return;
                }

                const options = {
                    country_iso_two: this.countryIsoTwo,
                    phone_number: this.phoneNumber,
                    phone_number_type: this.numberType,
                }

                this.createCustomerPhoneNumber(options);
            },
            handleUpdateCustomerPhoneNumber() {

                // Return if not valid options
                if (!this.validPhoneNumber || !this.validCustomerPhoneNumber) {
                    return;
                }

                const options = {
                    customer_phone_number_id: this.customerPhoneNumber?.customer_phone_number_id,
                    country_iso_two: this.countryIsoTwo,
                    phone_number: this.phoneNumber,
                    phone_number_type: this.numberType,
                }

                this.updateCustomerPhoneNumber(options);
            },
            init() {
                if (this.validCustomerPhoneNumber) {
                    this.countryIsoTwo = this.customerPhoneNumber.phone_number_country_iso;
                    this.numberType = this.customerPhoneNumber.phone_number_type;
                    this.phoneNumber = ((this.customerPhoneNumber.phone_number_area_code || "") + (this.customerPhoneNumber.phone_number_prefix_code || "") + (this.customerPhoneNumber.phone_number_line_number || "")).trim();
                }
            },
            phoneNumberChange(e) {
                
                // Ensure only numbers are entered
                if (isNaN(e.key)) {
                    e.preventDefault();
                }
            },
            reinit() {
                this.countryIsoTwo = null;
                this.numberType = null;
                this.phoneNumber = null;
                this.errorMessage = null;
                this.evaluatedNumberType = null;
            },
            setCountryIsoTwoUsingDefault() {

                // Verify
                if (this.countries?.length > 0 && this.defaultCountryIsoTwo?.length > 0) {

                    // Locate country
                    const idx = this.countries.findIndex(i => i.iso_two?.toLowerCase() === this.defaultCountryIsoTwo?.toLowerCase());

                    // Check if we found valid index and set on customer phone number
                    if (idx >= 0) {
                        this.countryIsoTwo = this.countries[idx].iso_two;
                    }
                }

                // If still null, pull from current campaign customer's organization's address country
                if (this.countryIsoTwo === null) {
                    this.countryIsoTwo = "US"; // todo - nick to do after normalizing organization's address
                }
            },
            validatePhoneNumber() {
                if (this.phoneNumber?.length > 0) {
                    const cleanPN = cleanupPhoneNumber(this.phoneNumber, this.countryIsoTwo);
                    this.errorMessage = cleanPN.valid === true ? null : "Please enter a valid phone number";
                    this.evaluatedNumberType = cleanPN.valid === true ? cleanPN.numberType[0].toUpperCase() + cleanPN.numberType.slice(1) : null;
                    this.validPhoneNumber = cleanPN.valid === true
                } else {
                    this.errorMessage = null;
                    this.evaluatedNumberType = null;
                }

                return;
            },
        },
        computed: {
            countries() {
                return VolieState.countries();
            },
            eligibleToSave() {
                if (!this.validPhoneNumber) {
                    return false;
                }
                if (!(this.countryIsoTwo?.length > 0)) {
                    return false;
                }
                if (!(this.numberType?.length > 0)) {
                    return false;
                }
                if (!(this.phoneNumber?.length > 0)) {
                    return false;
                }

                return true;
            },
            validCustomerPhoneNumber() {
                return this.customerPhoneNumber?.customer_phone_number_id > 0;
            },
        },
        components: {
        },
        props: {
            customerPhoneNumber:  Object,
            defaultCountryIsoTwo: String,
            createCustomerPhoneNumber: Function,
            updateCustomerPhoneNumber: Function,
        },
        created() {
            this.setCountryIsoTwoUsingDefault();
            return;
        },
        watch: {
            "countryIsoTwo"() {
                this.validatePhoneNumber();
            },
            "phoneNumber"() {
                this.validatePhoneNumber();
            },
        },
    });
</script>

<style lang="scss" scoped>
</style>