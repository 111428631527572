import { get, put } from '.';

export function FindConference(conferenceKey, options = {}) {
	return get<any>("/app/api/conferences/" + conferenceKey, options);
}

export function MuteConferenceLeg(conferenceKey:string, options = {}) {
	return put<any>(`/app/api/conferences/${conferenceKey}/mute`, options);
}

export function UnMuteConferenceLeg(conferenceKey:string, options = {}) {
	return put<any>(`/app/api/conferences/${conferenceKey}/unmute`, options);
}