import { get, post, put } from '.';

export function GetInternalTicketTasks(options = {}) {
	return get<any>("/app/api/internal_ticket_tasks", options);
}

export function FindInternalTicketTask(id) {
	return get<any>("/app/api/internal_ticket_tasks/" + (id || 0).toString());
}

export function CreateInternalTicketTask(options = {}) {
	return post<any>("/app/api/internal_ticket_tasks", options);
}

export function UpdateInternalTicketTask(id, options = {}) {
	return put<any>("/app/api/internal_ticket_tasks/" + (id || 0).toString(), options);
}

export function GetInternalTicketTaskNotes(options = {}) {
	return get<any>("/app/api/internal_ticket_task_notes", options);
}

export function CreateInternalTicketTaskNote(options = {}) {
	return post<any>("/app/api/internal_ticket_task_notes", options);
}

export function UpdateInternalTicketTaskNote(id, options = {}) {
	return put<any>("/app/api/internal_ticket_task_notes/" + (id || 0).toString(), options);
}
