import { get, put, post } from '.';

export function GetTickets(options = {}) {
	return get<any>("/app/api/tickets", options);
}

export function FindTicket(key, options = {}) {
	return get<any>("/app/api/tickets/" + key, options);
}

export function CreateTicket(options = {}) {
	return post<any>("/app/api/tickets", options);
}

export function UpdateTicket(key, options = {}) {
	return put<any>("/app/api/tickets/" + key, options);
}

export function ReopenTicket(key, options = {}) {
	return post<any>("/app/api/tickets/" + key + "/reopen", options);
}

export function GetAssignables(options = {}) {
	return get<any>("/app/api/tickets/assignables", options);
}

export function UpdateTicketComment(options = {}) {
	return put<any>("/app/api/tickets/comments", options);
}

export function GetAssigners(options = {}) {
	return get<any>("/app/api/tickets/assigners", options);
}
