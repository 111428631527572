import { get, post, put, destroy } from '.';

export function CreateAtoPCampaign(options = {}) {
	return post<any>(`/app/api/atop_campaigns`, options);
}

export function DestroyAtoPCampaign(vid:string, options = {}) {
	return destroy<any>(`/app/api/atop_campaigns/${vid}`, options);
}

export function FindAtoPCampaign(vid:string, options = {}) {
	return get<any>(`/app/api/atop_campaigns/${vid}`, options);
}

export function GetAtoPCampaigns(options = {}) {
	return get<any>("/app/api/atop_campaigns", options);
}

export function GetAtoPCampaignUseCases(options = {}) {
	return get<any>("/app/api/atop_campaigns/use_cases", options);
}

export function UpdateAtoPCampaign(vid:string, options = {}) {
	return put<any>(`/app/api/atop_campaigns/${vid}`, options);
}
