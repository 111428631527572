import { get, post } from '.';

export function GetEleadOpportunities(eleadSubscriptionVid: string, options = {}) {
	return get<any>("/app/api/elead/subscriptions/" + (eleadSubscriptionVid || 0).toString() + "/opportunities", options);
}

export function FindEleadOpportunity(eleadSubscriptionVid, vid, options = {}) {
	return get<any>("/app/api/elead/subscriptions/" + (eleadSubscriptionVid || 0).toString() + "/opportunities/" + (vid || 0).toString(), options);
}

export function ImportEleadOpportunities(options = {}) {
	return post<any>("/app/api/elead/opportunities/import", options);
}

export function GetEleadOpportunityVehicles(options = {}) {
	return get<any>("/app/api/elead/opportunity_vehicles", options);
}

export function GetEleadOpportunityTradeIns(options = {}) {
	return get<any>("/app/api/elead/opportunity_trade_ins", options);
}

export function GetEleadOpportunitySalesPersons(options = {}) {
	return get<any>("/app/api/elead/opportunity_sales_persons", options);
}

export function GetEleadOpportunitySalesSteps(options = {}) {
	return get<any>("/app/api/elead/opportunity_sales_steps", options);
}

export function ImportEleadOpportunitySalesSteps(options = {}) {
	return post<any>("/app/api/elead/opportunity_sales_steps/import", options);
}

export function CreateEleadOpportunitySalesStep(options = {}) {
	return post<any>("/app/api/elead/opportunity_sales_steps", options);
}

export function CreateEleadOpportunity(options = {}) {
	return post<any>("/app/api/elead/opportunities", options);
}
