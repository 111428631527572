import { get, post } from '.';

export function GetTekionVehicles(options = {}) {
	return get<any>("/app/api/tekion/vehicles", options);
}

export function ImportTekionVehicles(options = {}) {
	return post<any>("/app/api/tekion/vehicles/import", options);
}

export function GetTekionVehicle(key) {
	return get<any>("/app/api/tekion/vehicles/" + key);
}
