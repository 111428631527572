import { get, post, put, destroy } from '.';

export function CreateBadEmailAddress(options = {}) {
    return post<any>("/app/api/bad_email_addresses", options);
}

export function DeleteBadEmailAddress(id: string, options = {}) {
    return destroy<any>(`/app/api/bad_email_addresses/${id}`, options);
}

export function GetBadEmailAddresses(options = {}) {
    return get<any>(`/app/api/bad_email_addresses`, options);
}


export function UpdateBadEmailAddress(id: string, options = {}) {
    return put<any>(`/app/api/bad_email_addresses/${id}`, options);
}

