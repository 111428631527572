import { get, post, put, } from '.';

export function GetSurveyAttempts(options = {}) {
	return get<any>("/app/api/survey_attempts", options);
}

export function CreateSurveyAttempt(options = {}) {
	return post<any>("/app/api/survey_attempts", options);
}

export function UpdateSurveyAttempt(id, options = {}) {
	return put<any>("/app/api/survey_attempts/" + (id || 0).toString(), options);
}
