<template lang="pug">
    div
        v-simple-table
            template(v-slot:default)
                thead
                    tr
                        th
                            v-btn(
                                small
                                color='#0f5b9a'
                                outlined
                                @click="openAttachmentInput()"
                            )
                                v-icon(small) mdi-plus
                                | {{ assets.length > 0 ? "Add more attachments" : "Add attachments" }}
                            v-file-input(
                                id="asset-attachment-input"
                                accept="audio/*,video/*,image/*,.csv,.doc,.docx,.pdf,.txt,.rtf,.html,.xls,.xlsx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,.zip,.mkv,.ppt,.pptx"
                                multiple
                                style="display: none;"
                                v-model="newAttachmentFiles"
                                @change="onAttachmentInputChange();"
                            )
                tbody
                    tr(v-for="(asset, idx) in assets" :key="idx")
                        td
                            v-card-text.row(v-if="asset.file_type === 'image'")
                                v-img(
                                    contain
                                    max-height='250'
                                    max-width='250'
                                    :src='asset.asset_url'
                                )
                                v-btn(small color='error' dark style='margin: 0 12px;' @click='onDeleteAssets(idx);')
                                    v-icon mdi-delete
                            a(v-else :href="asset.asset_url") {{ asset.original_file_name }}

</template>

<script lang="ts">
    import Vue from 'vue';
    import $ from 'jquery';

    import { CreateAssets } from "@/data";

    export default Vue.extend({
        data() {
            return {
                newAttachmentFiles: null,
                assets: [],
            }
        },
        methods: {
            openAttachmentInput() {
                $("#asset-attachment-input")?.click();
            },
            onAttachmentInputChange() {

                // Verify valid files
                if (!(this.newAttachmentFiles?.length > 0)) {
                    return;
                }

                // Init formData
                const formData = new FormData();

                // Set common fields
                formData.set("account_id", (this.accountId || 0));
                formData.set("asset_id", (this.ticketId || 0));
                formData.set("asset_type", "ticket");

                // Loop over files and append to formData
                for (let i = 0; i < this.newAttachmentFiles.length; i++) {
                    // Append
                    // Key is 'file-1', 'file-2', etc
                    formData.append("file-" + (i + 1), this.newAttachmentFiles[i]);
                }

                CreateAssets(formData).then((response) => {

                    // Empty new attachment files
                    this.newAttachmentFiles = [];

                    if (response.Data?.assets?.length > 0) {
                        
                        for(let i = 0; i < response.Data.assets.length; i++) {

                            // Init
                            const asset = response.Data.assets[i];
                            if (!(asset?.asset_url?.length > 0)) {
                                continue;
                            }

                            // Append
                            let find = false;
                            this.assets.forEach(function (src) {
                                if (src?.asset_url == asset.asset_url) {
                                    find = true;
                                }
                            });
                            if (!find) {
                                this.assets.push(asset);
                            }
                        }
                    } else {
                        this.showSnackbar("error", "Unable to create new asset");
                    }

                    if (this.assets?.length > 0 && this.updatedAssets) {
                        this.updatedAssets(this.assets);
                    }

                });

            },
            onDeleteAssets(idx) {
                this.assets.splice(idx, 1);
                if (this.updatedAssets) {
                    this.updatedAssets(this.assets);
                }
            },
        },
        computed: {},
        components: {
        },
        props: {
            accountId:      Number,
            ticketId:      Number,
            updatedAssets:  Function,
            showSnackbar:   Function,
        },
        created() {
            return;
        },
    });
</script>

<style lang="scss" scoped>
</style>