<template lang="pug">
    ExpandableCardListItem.mt-0.mb-5.mx-1.text-left(
        v-if="validAppointment"
        :color="itemColor"
        expandable
        :flex-grow-left="8"
        :is-expanded="showDetails === true"
        :on-expand="() => showDetails = !showDetails"
        :subtitle="vehicleYearMakeModel"
        :title="convertAppointmentDateTimeFromMilliseconds(parseInt(appointment.appointment_date_time))"
        title-alignment="center"
    )

        template(v-slot:body v-if="showDetails === true")
            v-card-text.white.text--primary
                v-list.py-0.text-left(
                    two-line
                )
                    template
                        ListItem(
                            default-title="N/a"
                            icon="mdi-store"
                            indent
                            subtitle="Service Shop"
                            :title="appointment.shop_name"
                        )
                        ListItem(
                            default-title="N/a"
                            icon="mdi-train-car"
                            indent
                            subtitle="Transportation Type"
                            :title="appointment.transportation_type"
                        )
                        ListItem(
                            default-title="N/a"
                            icon="mdi-message-reply-text"
                            indent
                            subtitle="Customer Comments"
                            :title="appointment.customer_comments"
                        )

                        v-row.justify-content-end(style="margin-top: 25px; margin-right: 15px; margin-bottom: 7px")
                            v-btn.mb-10.mr-4(color="warning" small ripple @click="handleReschedule()")
                                v-icon mdi-circle-edit-outline
                                | Reschedule
                            CancelDialog(
                                :convert-appointment-date-time-from-milliseconds="convertAppointmentDateTimeFromMilliseconds"
                                :current-campaign-customer="currentCampaignCustomer"
                                :display-details-view="displayDetailsView"
                                :get-tekion-appointments="getTekionAppointments"
                                :handle-cancel-tekion-appointment="handleCancelTekionAppointment"
                                :handle-close-details-on-cancel="handleCloseDetailsOnCancel"
                                :set-tekion-error="setTekionError"
                                :show-snackbar="showSnackbar"
                                :set-tekion-appointment-details="setTekionAppointmentDetails"
                                :tekion-appointment="appointment"


                            )

</template>

<script lang="ts">
    import Vue from 'vue';

    // Components
    import ExpandableCardListItem from '@/components/shared/expandable_card_list_item.vue';
    import ListItem from '@/components/shared/list_item.vue';
    import CancelDialog from '@/components/communication_center/shared/tabs/tekion/cancel.vue'

    // Helpers
    import { resourceColorClass, } from "@/helpers/css_classes";

    export default Vue.extend({
        data() {
            return {
                showDetails: false,
            }
        },
        methods: {
            handleReschedule(){
                this.setScopedAppointment(this.appointment);
                this.displayRescheduleView();
            },
            //Ensures that all details cards are closed when you cancel an appointment to ensure user doesn't think the appt they canceled didn't cancel because of an open card. 
            handleCloseDetailsOnCancel(){
                this.showDetails = false;
            }
        },
        computed: {
            itemColor() {
                return resourceColorClass("Appointment");
            },
            validAppointment() {
                return this.appointment?.tekion_appointment_id !== '';
            },
            vehicleYearMakeModel() {
                return (this.appointment.vehicle_year ? this.appointment.vehicle_year + ' ' : '') +
                    (this.appointment.vehicle_make ? this.appointment.vehicle_make + ' ' : '') +
                    (this.appointment.vehicle_model ? this.appointment.vehicle_model : '').trim();
            }
        },
        components: {
            CancelDialog,
            ExpandableCardListItem,
            ListItem,
        },
        props: {
            appointment: Object,
            convertAppointmentDateTimeFromMilliseconds: Function,
            currentCampaignCustomer: Object,
            displayRescheduleView: Function,
            displayDetailsView: Function,
            setScopedAppointment: Function,
            setTekionAppointmentDetails: Function, 
            showSnackbar: Function,
            getTekionAppointments: Function,
            handleCancelTekionAppointment: Function, 
            setTekionError: Function, 
            
        },
        created() {
        }
    });
</script>

<style lang="scss" scoped>
</style>
