import { get, post, put } from '.';

export function SuperviseCall(id, options = {}) {
	return put<any>("/app/api/calls/" +  (id || 0).toString()  + "/supervise", options);
}

export function CreateOutboundCall(options = {}) {
	return post<any>("/app/api/calls/outbound", options);
}

export function GetAvailableCallTransferTeams(id, options = {}) {
	return get<any>("/app/api/calls/" +  (id || 0).toString()  + "/available_call_transfer_teams", options);
}

export function GetAvailableCallTransferUsers(id, options = {}) {
	return get<any>("/app/api/calls/" +  (id || 0).toString()  + "/available_call_transfer_users", options);
}

export function GetCalls(options = {}) {
	return get<any>("/app/api/calls", options);
}

export function HandleAgentCallDisconnect(id, options = {}) {
	return put<any>("/app/api/calls/" +  (id || 0).toString()  + "/handle_agent_call_disconnect", options);
}

export function HoldCall(id) {
	return put<any>("/app/api/calls/" +  (id || 0).toString()  + "/hold");
}

export function LeaveInstantVoicemail(id, options = {}) {
	return put<any>("/app/api/calls/" +  (id || 0).toString()  + "/leave_instant_voicemail", options);
}

export function CallJoinWebRtcSession(id, options = {}) {
	return put<any>("/app/api/calls/" +  (id || 0).toString()  + "/join_web_rtc_session", options);
}

export function MuteCall(id) {
	return put<any>("/app/api/calls/" +  (id || 0).toString()  + "/mute");
}

export function SendDTMF(id: string, options = {}) {
	return post<any>(`/app/api/calls/${id}/send_dtmf`, options);
}

export function UnmuteCall(id) {
	return put<any>("/app/api/calls/" +  (id || 0).toString()  + "/unmute");
}

export function UnholdCall(id) {
	return put<any>("/app/api/calls/" +  (id || 0).toString()  + "/unhold");
}

export function VerifyCustomerCallLegStatus(id, options = {}) {
	return get<any>("/app/api/calls/" +  (id || 0).toString()  + "/verify_customer_call_leg_status", options);
}