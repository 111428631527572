<template lang="pug">
    v-card.mx-lg-auto.mt-md-4.mb-md-4(v-if="validProspectAddress" outlined :width="cardWidth" style="margin: 10px 0px !important;")
        v-toolbar(flat)
            v-btn(
                color="primary"
                icon
                ripple
                @click="showDetails = !showDetails;"
            )
                v-icon(v-if="showDetails === true")
                    | mdi-chevron-down
                v-icon(v-else)
                    | mdi-chevron-right

            v-toolbar-title(style ="font-size: 14px;")
                AddressPostalFormat(
                    :street-one="prospectAddress.address_street_one"
                    :street-two="prospectAddress.address_street_two"
                    :city="prospectAddress.address_city"
                    :region="prospectAddress.address_region"
                    :postal-code="prospectAddress.address_postal_code"
                )
        v-divider
        v-card-text(v-if="showDetails === true")
            v-simple-table
                template(v-slot:default)
                    tbody
                        tr
                            td
                                .text-capitalize.text-overline.text--secondary.text--darken-3 Address Type
                                .cell-value-spacing(v-if="prospectAddress.address_type && prospectAddress.address_type.length > 0") {{ prospectAddress.address_type | titleize }}
                                .cell-value-spacing(v-else) N/a
                        tr
                            td
                                .text-capitalize.text-overline.text--secondary.text--darken-3 Address Street One
                                .cell-value-spacing(v-if="prospectAddress.address_street_one && prospectAddress.address_street_one.length > 0") {{ prospectAddress.address_street_one }}
                                .cell-value-spacing(v-else) N/a
                        tr
                            td
                                .text-capitalize.text-overline.text--secondary.text--darken-3 Address Street Two
                                .cell-value-spacing(v-if="prospectAddress.address_street_two && prospectAddress.address_street_two.length > 0") {{ prospectAddress.address_street_two }}
                                .cell-value-spacing(v-else) N/a
                        tr
                            td
                                .text-capitalize.text-overline.text--secondary.text--darken-3 Address City
                                .cell-value-spacing(v-if="prospectAddress.address_city && prospectAddress.address_city.length > 0") {{ prospectAddress.address_city }}
                                .cell-value-spacing(v-else) N/a
                        tr
                            td
                                .text-capitalize.text-overline.text--secondary.text--darken-3 Address Region
                                .cell-value-spacing(v-if="prospectAddress.address_region && prospectAddress.address_region.length > 0") {{ prospectAddress.address_region }}
                                .cell-value-spacing(v-else) N/a
                        tr
                            td
                                .text-capitalize.text-overline.text--secondary.text--darken-3 Address Postal Code
                                .cell-value-spacing(v-if="prospectAddress.address_postal_code && prospectAddress.address_postal_code.length > 0") {{ prospectAddress.address_postal_code }}
                                .cell-value-spacing(v-else) N/a
                        tr
                            td
                                .text-capitalize.text-overline.text--secondary.text--darken-3 Do Not Mail
                                .cell-value-spacing(v-if="prospectAddress.do_not_mail")
                                    v-icon(color="error") mdi-email-off
                                .cell-value-spacing(v-else) N/a

</template>

<script lang="ts">
    import Vue from 'vue';

    // Components
    import AddressPostalFormat from "@/components/shared/addresses/address_postal_format.vue";

    export default Vue.extend({
        data() {
            return {
                cardWidth: 300,
				showDetails: false,
            }
        },
        methods: {
            handleDetailsResize() {
                const detailsWidth = document?.getElementById('prospect-details-card')?.getBoundingClientRect()?.width
                if (detailsWidth >= 375) {
                    this.cardWidth = detailsWidth - 75
                } else {
                    this.cardWidth = 300
                }
            },
        },
        computed: {
            validProspectAddress() {
                return this.prospectAddress?.prospect_address_id > 0;
            },
        },
        components: {
            AddressPostalFormat,
        },
        props: {
            prospectAddress: Object,
        },
        watch: {
            prospectAddress: {
                handler() {
                    Vue.nextTick(() => { this.handleDetailsResize(); });
                },
                deep: true,
            },
        },
        created() {
			window.addEventListener('resize', this.handleDetailsResize);
        },
        destroyed() {
            window.removeEventListener('resize', this.handleDetailsResize);
        },
        mounted() {
            this.handleDetailsResize();
        },
    });
</script>

<style lang="scss" scoped>
    #app {
        .cell-value-spacing {
            padding: 0 0 5px 0;
        }
    }
</style>
