<template lang="pug">
    div.pa-md-4(v-if="validTask")

        v-dialog(v-model='noteDialog' persistent='' max-width='600px')
            v-card(v-if="scopedNote")
                v-card-title
                    span.text-h5(v-if='scopedNote.internal_ticket_task_note_id > 0') Update Note
                    span.text-h5(v-else) Create Note
                v-card-text
                    v-container
                        v-row
                            v-col(cols='12')
                                tiptap-vuetify(
                                    v-model='scopedNote.notes'
                                    :extensions='extensions'
                                    :toolbar-attributes="{ color: '#0f5b9a', dark: true }"
                                )

                v-card-actions
                    v-spacer
                        v-btn.ma-2(color="#0f5b9a" outlined ripple @click='noteDialog = false; scopedNote = null')
                            v-icon mdi-window-close
                            | Close

                        v-btn.ma-2(v-if='scopedNote.internal_ticket_task_note_id > 0' color="success" outlined ripple @click='noteDialog = false; updateInternalTicketTaskNote();')
                            v-icon mdi-content-save
                            | Save

                        v-btn.ma-2(v-else color="success" outlined ripple @click='noteDialog = false; createInternalTicketTaskNote();')
                            v-icon mdi-content-save
                            | Save

        v-card.mx-lg-auto(outlined)
            v-toolbar(flat :src="toolbarBackground" height="100px")

                v-toolbar-title(style="font-size: 13px; width: 25%; min-width: 25%;")
                    CopyableText(v-if="task.title" :text="task.title")
                    br
                    v-list-item-subtitle(style="font-size: 13px; color: rgba(0, 0, 0, 0.6);")
                        template(v-if="task.status")
                            strong.text-uppercase.success--text(v-if="task.status === 'done'")
                                | {{ task.status | titleize }}
                            strong.text-uppercase.info--text(v-else-if="task.status === 'in_progress'")
                                | {{ task.status | titleize }}
                            strong.text-uppercase.info--text(v-else-if="task.status === 'in_review'")
                                | {{ task.status | titleize }}
                            strong.text-uppercase.warning--text(v-else-if="task.status === 'open'")
                                | {{ task.status | titleize }}
                            strong.text-uppercase(v-else)
                                | {{ task.status | titleize }}
                        template(v-else) ---

                v-spacer(style="width: 10px; min-width: 10px;")

                v-toolbar-title(style="flex-grow: 50; font-size: 13px;")
                    TooltipText(text="Created At" :is-bold="true" :is-uppercase="true")
                    v-list-item-subtitle(style="font-size: 13px; margin-bottom: 7.5px; color: rgba(0, 0, 0, 0.6);")
                        CopyableText(v-if="task.friendly_created_at_date" :text="task.friendly_created_at_date.toString()")
                        template(v-else) ---
                    
                    TooltipText(text="Updated At" :is-bold="true" :is-uppercase="true")
                    v-list-item-subtitle(style="font-size: 13px; color: rgba(0, 0, 0, 0.6);")
                        CopyableText(v-if="task.friendly_updated_at_date" :text="task.friendly_updated_at_date.toString()")
                        template(v-else) ---

                v-spacer(style="width: 10px; min-width: 10px;")

                v-toolbar-title(style="flex-grow: 50; font-size: 13px;")
                    TooltipText(text="Estimate Hours" :is-bold="true" :is-uppercase="true")
                    v-list-item-subtitle(style="font-size: 13px; margin-bottom: 7.5px; color: rgba(0, 0, 0, 0.6);")
                        CopyableText(v-if="task.estimate_hours" :text="task.estimate_hours.toString()")
                        template(v-else) ---
                    
                    TooltipText(text="Actual Hours" :is-bold="true" :is-uppercase="true")
                    v-list-item-subtitle(style="font-size: 13px; color: rgba(0, 0, 0, 0.6);")
                        CopyableText(v-if="task.actual_hours" :text="task.actual_hours.toString()")
                        template(v-else) ---

                v-spacer(style="width: 10px; min-width: 10px;")

                v-toolbar-title(style="flex-grow: 50; font-size: 13px; min-width: 25%;")
                    TooltipText(text="Reporter" :is-bold="true" :is-uppercase="true")
                    v-list-item-subtitle(style="font-size: 13px; margin-bottom: 7.5px; color: rgba(0, 0, 0, 0.6);")
                        CopyableText(v-if="task.created_user_name" :text="task.created_user_name.toString()")
                        template(v-else) ---
                    
                    TooltipText(text="Assignee" :is-bold="true" :is-uppercase="true")
                    v-list-item-subtitle(style="font-size: 13px; color: rgba(0, 0, 0, 0.6);")
                        CopyableText(v-if="task.assigned_user_name" :text="task.assigned_user_name.toString()")
                        template(v-else) ---

                v-spacer(style="width: 10px; min-width: 10px;")

                v-toolbar-title(style="width: 125px; min-width: 125px;")
                    TaskDialogForm(
                        :set-changes-on-ticket-task="setChangesOnTicketTask"
                        :show-snackbar="showSnackbar"
                        :ticket="ticket"
                        :ticket-task="task"
                    )

                    v-btn(small color="info" dark @click="onNewNote()" style="margin-right: 10px; margin-left: 10px;" )
                        v-icon mdi-comment-plus-outline

        v-hover(
            v-for="(note, idx) in task.notes"
            :key="idx"
            v-slot:default='{ hover }'
        )
            v-row.dashed-border(style="margin: 0px;")
                v-col.col-9
                    v-container(class="tiptap-vuetify-editor__content" v-html="note.notes")
                v-col.col-3(style="padding-right: 24px;")
                    v-row.no-margin
                        v-col.activity-cell
                            strong {{ note.user_name }}

                    v-row.no-margin
                        v-col.activity-cell
                            strong {{ note.friendly_created_at_date }}

                    v-row.no-margin(v-show='!!hover')
                        v-col.activity-cell
                            v-btn(small color="warning" dark @click="onUpdateNote(note)" )
                                v-icon mdi-comment-edit-outline

</template>

<script lang="ts">
    import Vue from 'vue';

    // Data
    import { 
        CreateInternalTicketTaskNote,
        // GetInternalTicketTaskNotes,
        UpdateInternalTicketTaskNote,
    } from "@/data";

    // Components
    import CopyableText from '@/components/shared/copyable_text.vue';
    import TaskDialogForm from '@/components/internal_ticket_tasks/dialog_form.vue';
    import TooltipText from '@/components/shared/tooltip_text.vue';

    import {
        TiptapVuetify,
        Heading,
        Bold,
        Italic,
        Strike,
        Underline,
        // Code,
        Paragraph,
        BulletList,
        OrderedList,
        ListItem,
        Link,
        Blockquote,
        HardBreak,
        // HorizontalRule,
        // History,
    } from 'tiptap-vuetify';

    export default Vue.extend({
        data() {
            return {
                extensions: [
                    Blockquote,
                    Link,
                    Underline,
                    Strike,
                    Italic,
                    ListItem,
                    BulletList,
                    OrderedList,
                    [Heading, {
                        options: {
                            levels: [1, 2, 3]
                        }
                    }],
                    Bold,
                    Paragraph,
                    HardBreak
                ],
                noteDialog: false,
                scopedNote: null,
            }
        },
        methods: {
            onNewNote() {
                this.scopedNote = { notes: null };
                this.noteDialog = true;
            },
            onUpdateNote(note) {
                this.scopedNote = { ...note };
                this.noteDialog = true;
            },
            createInternalTicketTaskNote() {
                const options = {
                    internal_ticket_task_id: this.task?.internal_ticket_task_id || 0,
                    notes:                   this.scopedNote?.notes || '',
                }

                CreateInternalTicketTaskNote(options).then((response) => {
                    if (response?.Error === null) {

                        // Bubble up updates
                        this.setChangesOnTicketTask();
                        
                        this.showSnackbar("success", "Successfully created task note");
                    } else {
                        let err = response?.Error;
                        if (!(err?.length > 0)) {
                            err = "Unable to create task note"
                        }
                        this.showSnackbar("error", err);
                    }

                    this.noteDialog = false;
                    this.scopedNote = null;
                });
            },
            updateInternalTicketTaskNote() {
                if (!(this.scopedNote?.internal_ticket_task_note_id > 0)) {
                    return;
                }

                const options = {
                    notes: this.scopedNote?.notes || '',
                }

                UpdateInternalTicketTaskNote(this.scopedNote.internal_ticket_task_note_id, options).then((response) => {
                    if (response?.Error === null) {
                        
                        // Bubble up updates
                        this.setChangesOnTicketTask();

                        this.showSnackbar("success", "Successfully updated task note");
                    } else {
                        let err = response?.Error;
                        if (!(err?.length > 0)) {
                            err = "Unable to update task note"
                        }
                        this.showSnackbar("error", err);
                    }

                    this.noteDialog = false;
                    this.scopedNote = null;
                });
            },
        },
        computed: {
            toolbarBackground() {
                return process.env.VUE_APP_VOLIE_DETAILS_COMPONENT_TOOLBAR_BACKGROUND_IMG;
            },
            validTask() {
                return this.task?.internal_ticket_task_id > 0;
            },
        },
        props: {
            setChangesOnTicketTask: Function,
            showSnackbar: Function,
            task: Object,
            ticket: Object,
        },
        created() {},
        components: {
            CopyableText,
            TaskDialogForm,
            TiptapVuetify,
            TooltipText,
        }
    });
</script>

<style lang="scss" scoped>
.activity-cell {
    text-align: right !important;
    padding: 6px 0;
    font-size: 12px;
}
.dashed-border {
    border-bottom: 1px dashed #cfdadd;
}
</style>
