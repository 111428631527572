import { get, post, destroy } from '.';

export function GetCampaignTypeDispositionGroups(options = {}) {
    return get<any>("/app/api/campaign_type_disposition_groups", options);
}

export function GetAvailableCampaignTypeDispositionGroups(options = {}) {
    return get<any>("/app/api/campaign_type_disposition_groups_available", options);
}

export function CreateCampaignTypeDispositionGroups(id, options = {}) {
    return post<any>("/app/api/campaign_types/" + (id || 0).toString() + "/disposition_groups", options);
}

export function DestroyCampaignTypeDispositionGroup(id) {
    return destroy<any>("/app/api/campaign_type_disposition_groups/" + (id || 0).toString());
}

export function DestroyAllCampaignTypeDispositionGroups(id) {
    return destroy<any>("/app/api/campaign_type_disposition_groups_all/" + (id || 0).toString());
}