import { destroy, get, post, put } from '.';

export function GetPromotions(options = {}) {
	return get<any>("/app/api/promotions", options);
}

export function CreatePromotion(options = {}) {
	return post<any>("/app/api/promotions", options);
}

export function UpdatePromotion(id: number, options = {}) {
	return put<any>(`/app/api/promotions/${id}`, options);
}

export function DestroyPromotion(id: number) {
	return destroy<any>(`/app/api/promotions/${id}`);
}
