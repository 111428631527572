import * as Sentry from "@sentry/vue";

class CrashDetector {

        beforeunload: ((e:any) => boolean);
        log = false;
        previousState = {};
        trackedKeys = [
            "error_event",
            "last_rac",
            "last_request",
            "navigation",
            "route_from",
            "route_to",
            "time_before_crash",
            "log_xhr",
        ]

        load() {
            window.addEventListener("beforeunload", (e) => this.onBeforeunload(e));

            if (!this.log) {
                return;
            }

            if(sessionStorage.getItem('good_exit') === 'pending') {

                Sentry.captureException(new Error("a crash occured"), (scope) => {
                    scope.setExtra('last_error', sessionStorage.getItem("error_event"))
                    scope.setExtra('last_navigation', sessionStorage.getItem('navigation'))
                    scope.setExtra('last_rac', sessionStorage.getItem('last_rac'))
                    scope.setExtra('last_request', sessionStorage.getItem('last_request'))
                    scope.setExtra('route_from', sessionStorage.getItem('route_from'))
                    scope.setExtra('route_to', sessionStorage.getItem('route_to'))
                    scope.setExtra('time_before_crash', sessionStorage.getItem('time_before_crash') )
                    return scope
                });
                console.warn("crash detected")
            }

            sessionStorage.setItem('good_exit', 'pending');
            sessionStorage.setItem('log_xhr', 'true');


            setInterval(function () {
                sessionStorage.setItem('time_before_crash', new Date().toString());
            }, 1000);
        }

        onBeforeunload(e:any) {
            let prevent = false;
            if (this.beforeunload) {
                prevent = this.beforeunload(e);
            }
            if (prevent) {
                if (this.log) {
                    this.copyStorage();
                    this.unloadStorage();

                    // try to detect a cancel by executing code in the future outside of the non-cancel behavior
                    setTimeout(() => { this.restoreStorage() }, 10000)
                }

                e.preventDefault();
                e.returnValue = "";
            } else {
                if (this.log) {
                    this.unloadStorage();
                }
            }
        }

        copyStorage() {
            if (this.log) {
                for (let i = 0; i < this.trackedKeys.length; i++) {
                    this.previousState[this.trackedKeys[i]] = sessionStorage.getItem(this.trackedKeys[i]);
                }
            }
        }

        restoreStorage() {
            if (this.log) {
                for (let i = 0; i < this.trackedKeys.length; i++) {
                    const value = sessionStorage.getItem(this.trackedKeys[i]);
                    if (!value) {
                        sessionStorage.setItem(this.trackedKeys[i], this.previousState[this.trackedKeys[i]]);
                    }
                }
                sessionStorage.setItem('good_exit', 'pending');
                this.previousState = {};
            }
        }

        unloadStorage() {
            if (this.log) {
                for (let i = 0; i < this.trackedKeys.length; i++) {
                    sessionStorage.setItem(this.trackedKeys[i], "");
                }
                sessionStorage.setItem('good_exit', 'true');
            }
        }
}

export const crashDetector = new CrashDetector();
