import { render, staticRenderFns } from "./email_event_details.vue?vue&type=template&id=aef18014&scoped=true&lang=pug"
import script from "./email_event_details.vue?vue&type=script&lang=ts"
export * from "./email_event_details.vue?vue&type=script&lang=ts"
import style0 from "./email_event_details.vue?vue&type=style&index=0&id=aef18014&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "aef18014",
  null
  
)

export default component.exports