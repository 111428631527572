import { get, post } from '.';


export function GetEleadActivityTypes(eleadSubscriptionVid, options = {}) {
	return get<any>("/app/api/elead/subscriptions/" + (eleadSubscriptionVid || 0).toString() + "/activity_types", options);
}

export function ImportEleadActivityTypes(options = {}) {
	return post<any>("/app/api/elead/activity_types/import", options);
}
