<template lang="pug">
    .ma-sm-2(v-if="validCustomer")
        v-snackbar(
            v-model='snackbar.show'
            :color='snackbar.color'
            :timeout="snackbar.timeout"
            top
        )
            | {{ snackbar.text }}
            v-btn(dark text @click='snackbar.show = false')
                | Close

        v-dialog(
            v-if='validScheduledCommunication && !hideCreateUpdateDestroyButtons'
            v-model='dialogArchive'
            max-width="600"
            transition='dialog-bottom-transition'
        )
            v-card
                v-toolbar
                    v-btn(icon @click='closeDialogArchive();')
                        v-icon mdi-close
                    v-toolbar-title Confirm Cancel

                template
                    ScheduledCommunicationDetailsCardText(
                        :scheduled-communication="scheduledCommunication"
                        :show-customer-name="true"
                    )

                    v-card-actions.text-right
                        v-spacer
                            v-btn.ma-2(color="#0f5b9a" outlined ripple @click='closeDialogArchive();')
                                v-icon mdi-window-close
                                | Close

                            v-btn.ma-2(
                                color="red"
                                outlined
                                ripple
                                @click='cancelScheduledCommunication();'
                                :disabled="isLoadingArchive"
                            )
                                v-icon mdi-delete
                                | Confirm Cancel

        .text-center(v-if="isLoading")
            v-progress-circular(
                :size='100'
                :width='10'
                color='#0f5b9a'
                indeterminate
                style="margin-top: 100px;"
            )

        v-container.mx-auto(v-if="!isLoading")
            .text-subtitle-2.subtitle-2-padding.text-center
                | Scheduled Communications

            ScheduledCommunicationsTable(
                v-if="validScheduledCommunications"
                :handle-show-more="handleShowMore"
                :hide-create-update-destroy-buttons="hideCreateUpdateDestroyButtons"
                is-organization-view=false
                :limit="limit"
                :open-dialog-archive="openDialogArchive"
                :scheduled-communications="scheduledCommunications"
                :scheduled-communications-splices="scheduledCommunicationsSplices"
                :show-moreable="showMoreable"
                :valid-scheduled-communications="validScheduledCommunications"
            )

            v-alert(outlined type='warning' dense border='left' style="margin-top: 16px;" v-if="!validScheduledCommunications && !isLoading")
                | Customer does not have any scheduled communications

</template>

<script lang="ts">
    import Vue from 'vue';

    // Components
    import ScheduledCommunicationDetailsCardText from "@/components/scheduled_communications/details_card_text.vue";
    import ScheduledCommunicationsTable from '@/components/scheduled_communications/table.vue';

    // Data
    import {CancelScheduledCommunication, GetScheduledCommunications} from "@/data";

    export default Vue.extend({
        data() {
            return {
                dialogArchive: false,
                isLoading: true,
                isLoadingArchive: false,
                limit: 100,
                scheduledCommunication: null,
                scheduledCommunications: [],
                scheduledCommunicationsSplices: 0,
                showMoreable: true,
                snackbar: {
                    show: false,
                    timeout: 2500,
                },
            }
        },
        methods: {
            cancelScheduledCommunication() {

                // Ensure
                if (!this.validScheduledCommunication) {
                    return;
                }

                this.isLoadingArchive = true;
                CancelScheduledCommunication(this.scheduledCommunication.scheduled_communication_id).then((response) => {

                    if (response?.Data?.ok === true) {

                        // Remove voice mail recording
                        const idxToRemove = this.scheduledCommunications.findIndex(x => x.scheduled_communication_id === this.scheduledCommunication?.scheduled_communication_id);
                        if (idxToRemove >= 0) {
                            this.scheduledCommunications.splice(idxToRemove, 1);

                            // update spliced count to keep show more functioning. Subtract 1 if removed 1
                            this.scheduledCommunicationsSplices = this.scheduledCommunicationsSplices - 1
                        }

                        // Close
                        this.closeDialogArchive();

                        this.showSnackbar("green", "Successfully canceled communication");
                    } else {
                        this.showSnackbar("error", "Failed to cancel communication");
                    }

                    this.isLoadingArchive = false;
                });
            },
            closeDialogArchive() {
                this.dialogArchive = false;
                this.scheduledCommunication = null;
            },
            getScheduledCommunications() {
                if (!this.validCustomer) {
                    return;
                }
                const options = {
                    customer_id: this.currentCampaignCustomer?.customer_id,
                    organization_id: this.currentCampaignCustomer?.organization_id,
                    limit: this.limit,
                }

                GetScheduledCommunications(options).then((response) => {
                    if (response?.Data?.scheduled_communications?.length > 0) {
                        this.showMoreable = true;
                        if (this.validScheduledCommunications) {
                            this.scheduledCommunications = this.scheduledCommunications.concat(response.Data.scheduled_communications);
                        } else {
                            this.scheduledCommunications = response.Data.scheduled_communications;
                        }

                        this.setLastScheduledCommunicationId();
                    } else {
                        this.showMoreable = false;
                    }

                    this.isLoading = false;
                });
            },
            handleShowMore() {
                this.getScheduledCommunications();
            },
            openDialogArchive(scheduledCommunication) {
                this.scheduledCommunication = scheduledCommunication;
                this.dialogArchive = true;
            },
            setLastScheduledCommunicationId() {

                // Verify
                if (!this.validScheduledCommunications) {
                    return;
                }

                // Get last
                const last = this.scheduledCommunications[this.scheduledCommunications.length - 1];
                if (!last || !(last.scheduled_communication_id > 0)) {
                    return;
                }

                // Set
                this.lastScheduledCommunicationId = last.scheduled_communication_id;
            },
            showSnackbar(color, text) {
                if (color?.length > 0 && text?.length > 0) {
                    this.snackbar.color = color;
                    this.snackbar.text = text;
                    this.snackbar.show = true;
                }
            },
        },
        computed: {
            validCustomer() {
                return this.currentCampaignCustomer?.account_id > 0 && this.currentCampaignCustomer?.customer_id > 0 && this.currentCampaignCustomer?.organization_id > 0;
            },
            validScheduledCommunication() {
                return this.scheduledCommunication?.scheduled_communication_id > 0;
            },
            validScheduledCommunications() {
                return this.scheduledCommunications?.length > 0;
            },
        },
        components: {
            ScheduledCommunicationsTable,
            ScheduledCommunicationDetailsCardText,
        },
        props: {
            currentCampaignCustomer: Object,
            hideCreateUpdateDestroyButtons: Boolean,
        },
        created() {
            this.getScheduledCommunications();
        },
    });
</script>

<style lang="scss" scoped>
</style>
