<template lang="pug">
    v-card.mx-lg-auto.mt-md-4.mb-md-4(outlined width="100%" v-if="validToyoguardProgram")
        v-toolbar(
            flat
            color="grey lighten-4"
        )
            v-btn(
                color="primary"
                icon
                ripple
                @click="showDetails = !showDetails;"
            )
                v-icon(v-if="showDetails === true")
                    | mdi-chevron-down
                v-icon(v-else)
                    | mdi-chevron-right


            v-toolbar-title(style="font-size: 16px; padding-left: 10px;")
                template(v-if="vehicleYearMakeModel && vehicleYearMakeModel.length > 0")
                    CopyableText(:text="vehicleYearMakeModel")
                template(v-else) N/a

                template(v-if="validVin")
                    br
                    span.text-body-2
                        CopyableText(:text="toyoguardProgram.vin")

            v-spacer
            .text-right
                | Status:
                span(:class="toyoguardProgram.service_program_status | statusClassMatcher" style="padding-left: 8px;").font-weight-bold.text-uppercase
                    | {{ toyoguardProgram.service_program_status }}


        template(v-if="showDetails === true")

            v-card-text

                v-list.py-0(
                    two-line
                )

                    v-list-item(dense)
                        v-list-item-content
                            v-list-item-title
                                | {{ toyoguardProgram.option_code_description }}
                            v-list-item-subtitle Program Name
                    v-list-item(dense)
                        v-list-item-content
                            v-list-item-title
                                | {{ toyoguardProgram.seller_dealer_name }}
                            v-list-item-subtitle Seller Name
                        v-list-item-content
                            v-list-item-title
                                | {{ formatDateForDisplay }}
                            v-list-item-subtitle Expiration Date

                v-divider
                v-list.py-0(
                    v-for="service in toyoguardProgram.services" :key="service.id"
                    style="border-bottom: 1px solid #efefef;"
                )
                    v-list-item(dense)
                        v-list-item-content
                            v-list-item-title
                                | {{ displayService(service.service_type, service.service_type_number) }}
                            v-list-item-subtitle Service Type
                        v-list-item-content
                            v-list-item-title
                                | {{ displayServiceTime(service) }}
                            v-list-item-subtitle {{ displayServiceTimeLabel(service) }}
                        v-list-item-content
                            v-list-item-title
                                span(:class="service.service_status | statusClassMatcher").font-weight-bold.text-uppercase
                                    | {{ service.service_status }}
                            v-list-item-subtitle Status



</template>

<script lang="ts">
    import Vue from 'vue';

    // Components
    import CopyableText from '@/components/shared/copyable_text.vue';

    // Helpers
    import { concatVehicleYearMakeModel } from "@/helpers/general";
    import { formatDateFromDateTime } from "@/helpers/general";

    // Types
    import { VehicleYearMakeModelObj } from "@/helpers/general";

    export default Vue.extend({
        data() {
            return {
                customerVehicle: {},
                showDetails: false,
            }
        },
        methods: {
            displayService(serviceType: string, serviceTypeNumber: number) {
                if (serviceType === "oil_change") {
                    return `Oil Change ${serviceTypeNumber}`;
                } else if (serviceType === "tire_rotation") {
                    return `Tire Rotation ${serviceTypeNumber}`;
                }
            },
            displayServiceTime(service: any) {
                if (service.date_serviced === null) {
                    return formatDateFromDateTime(service.service_expiration_date);
                } else if (service.date_serviced !== "") {
                    return formatDateFromDateTime(service.date_serviced);
                }
            },
            displayServiceTimeLabel(service: any) {
                if (service.date_serviced === null) {
                    return "Expiration Date";
                } else if (service.date_serviced !== "") {
                    return "Date Serviced";
                }
            }

        },
        computed: {
            formatDateForDisplay() {
                return formatDateFromDateTime(this.toyoguardProgram.service_program_expiration_date)
            },
            validToyoguardProgram() {
                return this.toyoguardProgram?.vin?.length > 0;
            },
            validVin() {
                return this.toyoguardProgram?.vin?.length > 0;
            },
            vehicleYearMakeModel() {
                if (!this.validToyoguardProgram) {
                    return null;
                }

                const vehObj = {
                    vehicleModelYear: this.toyoguardProgram.customer_vehicle.vehicle_model_year,
                    vehicleMake:      this.toyoguardProgram.customer_vehicle.vehicle_make,
                    vehicleModel:     this.toyoguardProgram.customer_vehicle.vehicle_model,
                }

                return concatVehicleYearMakeModel(vehObj as VehicleYearMakeModelObj);
            },
        },
        components: {
            CopyableText
        },
        props: {
            toyoguardProgram: Object,
        },
        watch: {
        },
        created() {
            this.customerVehicle = this.toyoguardProgram.customer_vehicle
        },
    });
</script>

<style lang="scss" scoped>
    #app {

        .cell-value-spacing {
            padding: 0 0 5px 0;
        }
    }
</style>
