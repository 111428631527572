<template lang="pug">
    v-menu(
        bottom
        origin='center center'
        transition='scale-transition'
        v-model="tagMenu"
        v-if="menuTags && menuTags.length > 0"
    )
        template(v-slot:activator='{ on }')
            v-btn(color="#FFFFFF" light v-on='on' small)
                | Shortcuts
                v-icon
                    | mdi-chevron-down
        v-list
            v-list-item(
                v-for='(tag, i) in menuTags'
                :key='i'
                style="padding-right: 0; padding-left: 0;"
            )
                v-menu(
                    origin='center center'
                    transition='scale-transition'
                    open-on-hover
                    offset-x
                    right
                )
                    template(v-slot:activator='{ on }')
                        v-btn.override-btn.text-left(color="#FFFFFF" light v-on='on' block depressed style="height: 100%;")
                            v-list-item-content
                                v-list-item-title {{ tag.name }}
                            v-list-item-action
                                v-icon mdi-menu-right
                    v-list
                        v-list-item(
                            v-for='(mt, j) in tag.messageTemplates'
                            :key='j'
                            @click='handleClick(mt);'
                            ripple
                        )
                            v-list-item-content
                                v-list-item-title {{ mt.message_template_name }}
                                v-list-item-subtitle {{ mt.language_name }}
</template>

<script lang="ts">
    import Vue from 'vue';

    export default Vue.extend({
        data() {
            return {
                tagMenu: false,
            }
        },
        methods: {
            handleClick(messageTemplate) {
                this.tagMenu = false;
                this.applyMessageTemplate(messageTemplate);
            },
        },
        computed: {
        },
        components: {
        },
        props: {
            applyMessageTemplate: Function,
            menuTags: Array,
        },
    });
</script>

<style lang="scss" scoped>
    #app {
        .override-btn {
            font-weight: 400;
            letter-spacing: normal;
            text-decoration: none;
            text-transform: none;
        }
    }
</style>
