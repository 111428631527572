import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import VolieState from '@/store';
import volieStore from '../store';

Vue.use(VueRouter)

// Routes
const routes: Array<RouteConfig> = [

	// Shared Links
	{
		path: "/s/*",
		name: "shared_links_main",
	},
];

const SharedLinkRouter = new VueRouter({
	routes
});

SharedLinkRouter.beforeEach((to, from, next) => {

	// Handle invalid shared link
	const sharedLink = volieStore.store.state.SharedLink;
	if (!(sharedLink?.id > 0)) {
		window.location.assign(VolieState.baseRailsUrl());
		return;
	}

	next();
});

export default SharedLinkRouter;