import { render, staticRenderFns } from "./video_player.vue?vue&type=template&id=a535b7b2&scoped=true&lang=pug"
import script from "./video_player.vue?vue&type=script&lang=ts"
export * from "./video_player.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a535b7b2",
  null
  
)

export default component.exports