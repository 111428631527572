<template lang="pug">
    v-main(v-if="validSharedLink && requiresPassword")
        v-container
            v-layout(align-center justify-center)
                v-flex(xs12 sm8 md4)
                    .text-center
                        v-img.shrink(
                            alt="Volie Logo"
                            class="shrink mr-2"
                            contain
                            :src="mainLogoUrl"
                            transition="scale-transition"
                            style="margin-right: 0 !important;"
                        )

                    v-card.elevation-12.mt-2(:class="{ 'apply-shake': shake }")
                        v-toolbar(dark color='primary')
                            v-toolbar-title Enter the password to view this link
                        v-card-text
                            v-form
                                v-text-field(prepend-icon='mdi-lock' name='password' label='Password' type='password' v-model="password" @keydown.enter.prevent="validatePassword()")
                        v-card-actions
                            v-spacer
                            v-btn(color='primary' @click="validatePassword();") Continue

</template>

<script lang="ts">
    import Vue from 'vue';

    // Data
    import { ValidateSharedLinkPassword } from "@/data";

    export default Vue.extend({
        data() {
            return {
                password: null,
                shake: false,
            }
        },
        methods: {
            validatePassword() {

                // Reset
                this.shake = false;

                // Verify
                if (!this.isPresentPassword) {
                    this.shake = true;
                    return;
                }

                // Init
                const options = {
                    password: this.password,
                }

                ValidateSharedLinkPassword(this.sharedLink?.id, options).then((response) => {
                    if (response?.Data?.ok === true) {
                        this.validatePasswordSuccess();
                    } else {
                        this.shake = true;
                    }
                });
            },
        },
        computed: {
            isPresentPassword() {
                return this.password?.length > 0;
            },
            mainLogoUrl() {
                return "https://s3.amazonaws.com/switch-telephony-misc/volie_logos_2/Volie_280x105px.png";
            },
            requiresPassword() {
                return this.sharedLink?.access === "password";
            },
            validSharedLink() {
                return this.sharedLink?.id > 0;
            },
        },
        components: {
        },
        props: {
            sharedLink: Object,
            validatePasswordSuccess: Function,
        },
        created() {
        },
        destroyed() {
        },
    });
</script>

<style lang="scss" scoped>
    @keyframes shake {
        10%,
        90% {
            transform: translate3d(-1px, 0, 0);
        }

        20%,
        80% {
            transform: translate3d(2px, 0, 0);
        }

        30%,
        50%,
        70% {
            transform: translate3d(-4px, 0, 0);
        }

        40%,
        60% {
            transform: translate3d(4px, 0, 0);
        }
    }

    .apply-shake {
        animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
    }
</style>
