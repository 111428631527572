<template lang="pug">
    v-list-item-content.pa-0
        v-list-item-subtitle.text-center {{ conversation.friendly_created_at }}

        v-list.max-width-100-percent(v-if="!!conversation.messages && conversation.messages.length > 0")
            v-list-item.px-0(
                v-for='(message, j) in conversation.messages'
                :key='j'
            ) 

                v-list-item-content(v-if="message.personable_type === 'customer_phone_number' && !message.is_message_undelivered")
                    Message(
                        :message="message"
                        :sender="message.personable_name"
                    )
                v-list-item-content.justify-content-flex-end(v-else-if="message.personable_type === 'twilio_number'")
                    Message(
                        :message="message"
                        :sender="message.user_full_name" 
                        right
                    )

</template>

<script lang="ts">
    import Vue from 'vue';

    // Components
    import Message from "@/components/communication_center/sms_message/message.vue"

    export default Vue.extend({
        components: {
            Message,
        },
        props: {
            conversation: Object,
        },
    });
</script>

<style lang="scss" scoped>
</style>
