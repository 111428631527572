<template lang="pug">
    .ma-sm-2(v-if="validCustomer")
        .text-center(v-if="isLoading")
            v-progress-circular(
                :size='100'
                :width='10'
                color='#0f5b9a'
                indeterminate
                style="margin-top: 100px;"
            )

        v-container.mx-auto(v-if="!isLoading")
            v-container
                v-form
                    v-row(align="center")
                        v-col(cols='6')
                            v-text-field(
                                label='Lead Key'
                                type='text'
                                v-model="searchObj.leadKey"
                                @keydown.enter="search()"
                            )
                        v-col(cols='6')
                            v-text-field(label='Lead Source' type='text' v-model="searchObj.leadSource" @keydown.enter="search()")

                    v-row(align="center")
                        v-col(cols='6')
                            v-text-field(label='Lead Status' type='text' v-model="searchObj.leadStatus" @keydown.enter="search()")
                        v-col(cols='6')
                            v-text-field(label='Vehicle VIN' type='text' v-model="searchObj.vehicleVin" @keydown.enter="search()")

                    v-row(align="center")
                        v-col(cols='12')
                            .text-right
                                v-btn.mr-4(
                                    @click='resetSearch();'
                                )
                                    v-icon mdi-refresh
                                    | Reset
                                v-btn.mr-4(
                                    color='info'
                                    @click='search();'
                                    :disabled="disableSearchButton"
                                )
                                    v-icon mdi-magnify
                                    | Search


            ProspectDetails(
                v-if="validProspects"
                v-for='prospect in prospects'
                :key='prospect.prospect_id'
                :prospect="prospect"
            )


            v-alert(outlined type='warning' dense border='left' style="margin-top: 16px;" v-if="!validProspects")
                | Unable to find prospects

            v-simple-table(v-if="validProspects")
                template(v-slot:default)
                    tbody
                        ShowMoreRow(
                            :colspan=1
                            :data-length='prospectsLength'
                            :handle-show-more="handleShowMore"
                            :is-show-more-loading="isShowMoreLoading"
                            :limit='limit'
                            :show-moreable="showMoreable"
                            :splices="0"
                        )

</template>

<script lang="ts">
    import Vue from 'vue';

    // Components
    import ProspectDetails from '@/components/prospects/prospect_details.vue';
    import ShowMoreRow from '@/components/shared/show_more_row.vue';

    // Data
    import { GetProspects } from "@/data";

    export default Vue.extend({
        data() {
            return {
                currentTab: 'prospects-tab',
                isLoading: false,
                isShowMoreLoading: false,
                limit: 25,
                offset: 0,
                prospects: [],
                searchObj: {},
                showMoreable: true,
                tabItems: [
                    { name: "Prospects", key: "prospects", href: "#prospects-tab" },
                    { name: "Search",   key: "search",   href: "#search-tab" },
                ]
            }
        },
        methods: {
            getProspects() {
                if (!this.validCustomer) {
                    return;
                }

                const options = {
                    account_id: this.currentCampaignCustomer?.account_id,
                    customer_id: this.currentCampaignCustomer?.customer_id,
                    organization_id: this.currentCampaignCustomer?.organization_id,
                    limit: this.limit,
                    offset: this.offset,
                    lead_key: this.searchObj.leadKey || '',
                    lead_status: this.searchObj.leadStatus || '',
                    lead_source: this.searchObj.leadSource || '',
                    vin: this.searchObj.vin || '',

                }

                if (this.prospects?.length > 0) {
                    this.isShowMoreLoading = true;
                } else {
                    this.isLoading = true;
                }

                GetProspects(options).then((response) => {
                    if (response?.Data?.prospects?.length > 0) {

                        // set show moreable to true because records are returned
                        this.showMoreable = true;

                        this.prospects = this.prospects.concat(response.Data.prospects);
                    } else {

                        // set show moreable to false because 0 records returned
                        this.showMoreable = false;
                    }

                    this.isLoading = false;
                    this.isShowMoreLoading = false;
                });
            },
            handleShowMore() {
                this.offset += this.limit;
                this.getProspects();
                return;
            },
            search() {
                this.prospects = [];
                this.getProspects();
                this.currentTab = 'prospects-tab';
            },
            resetSearch() {
                this.searchObj = {}
            }
        },
        computed: {
            disableSearchButton() {
                return JSON.stringify(this.searchObj) === '{}';
            },
            prospectsLength() {
                return this.prospects.length;
            },
            validCustomer() {
                return this.currentCampaignCustomer?.account_id > 0 && this.currentCampaignCustomer?.customer_id > 0 && this.currentCampaignCustomer?.organization_id > 0;
            },
            validProspects() {
                return this.prospects?.length > 0;
            },
        },
        components: {
            ProspectDetails,
            ShowMoreRow,
        },
        props: {
            currentCampaignCustomer: Object,
        },
        created() {
            this.getProspects();
        },
    });
</script>

<style lang="scss" scoped>
</style>
