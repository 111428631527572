<template lang="pug">
    .ma-sm-2(v-if="validRasani")

        TransferDialog(
            v-if="transferDialog"
            :call-id="callId"
            destination="unknown"
            :end-call="endCall"
            :exit-transfer-dialog="exitTransferDialog"
            location="external"
            :transfer-to-number="transferTo"
            type="warm"
        )

        v-snackbar(
                v-model='snackbar.show'
                :color='snackbar.color'
                :timeout="snackbar.timeout"
                top
            )
                | {{ snackbar.text }}
                v-btn(dark text @click='snackbar.show = false')
                    | Close

        .text-center(v-if="isLoading")
            v-progress-circular(
                :size='100'
                :width='10'
                color='#0f5b9a'
                indeterminate
                style="margin-top: 100px;"
            )

        v-container.mx-auto(v-if="!isLoading")
            .text-subtitle-2.subtitle-2-padding.text-center
                | Rasani Ping Post

            v-alert(outlined type='warning' dense border='left' style="margin-top: 16px;" v-if="!validMortgageProspect")
                | Unable to find mortgage prospect

            v-row(align="center")
                v-col(cols='12')
                    v-alert(
                        outlined
                        prominent
                        border="left"
                        :color="pingPostError ? 'error' : 'info'"
                        v-if="pingPostResponse && pingPostResponse.length > 0"
                        style="margin-bottom: 15px; font-size: 14px;"
                    )
                        .text-capitalize.text-overline.text--secondary.text--darken-3 Ping Post
                        div(v-if="pingPostError === true" style="color: rgba(0, 0, 0, 0.87);") {{ pingPostResponse }}
                        v-row(v-else align='center')
                            v-col.grow
                                div(style="color: rgba(0, 0, 0, 0.87); overflow-wrap: break-word; word-break: break-word;") {{ sourceCode }}
                                div(style="color: rgba(0, 0, 0, 0.87);") {{ transferTo | tel }}
                            v-col.shrink(style="padding: 0px;")
                                v-btn.mx-2(
                                    v-if="renderTransferButton"
                                    color='primary'
                                    dark
                                    fab
                                    ripple
                                    x-small
                                    @click.stop="openTransferDialog();"
                                )
                                    v-icon(dark)
                                        | mdi-phone-forward

                    v-container(
                        v-if="validMortgageProspect"
                        style="margin-bottom: 15px;"
                    )
                        div(style="display: flex; justify-content: space-between; align-items: center;")
                            .text-capitalize.text-overline.text--secondary.text--darken-3 Mortgage Address
                            CustomerAddressFormDialog(
                                :customer-address="mortgageProspect.customer_address"
                                :update-customer-address="updateCustomerAddress"
                            )
                        AddressPostalFormat(
                            :street-one="mortgageProspect.address_street_one"
                            :street-two="mortgageProspect.address_street_two"
                            :city="mortgageProspect.address_city"
                            :region="mortgageProspect.address_region_abbr ? mortgageProspect.address_region_abbr : mortgageProspect.address_region"
                            :postal-code="mortgageProspect.address_postal_code"
                            :country="mortgageProspect.address_country_name"
                        )

                    v-container(
                        v-else
                        style="margin-bottom: 15px;"
                    )
                        v-simple-table
                            template(v-slot:default)
                                thead
                                    tr
                                        th.text-center(style="width: 15%; border-width: 0px;")
                                        th.text-center.text-capitalize.text-overline.text--secondary.text--darken-3(style="width: 70%; border-width: 0px;") Mortgage Address
                                        th.text-center(style="width: 15%; border-width: 0px;")
                                            CustomerAddressFormDialog(
                                                :create-customer-address="createMortgageProspect"
                                            )

                    v-form(
                        v-if="validMortgageProspect"
                        ref="rasaniForm"
                        v-model="validRasaniForm"
                    )
                        v-select(
                            v-if="(!loanOfficer || loanOfficer.length === 0) || (!!loanOfficers && loanOfficers.length > 0)"
                            v-model="loanOfficer"
                            :items='loanOfficers'
                            item-text="full_loan_officer_info"
                            item-value="full_loan_officer_info"
                            label='Loan Officer'
                            clearable
                            outlined
                            dense
                            prepend-inner-icon="mdi-account-edit"
                            :error="loanOfficerError && loanOfficerError.length > 0"
                            :error-messages="loanOfficerError"
                            @change="loanOfficerError = null"
                        )
                        v-text-field(
                            v-else-if="!!loanOfficer && loanOfficer.length > 0"
                            v-model="loanOfficer"
                            label='Loan Officer'
                            rows="1"
                            readonly
                            outlined
                            dense
                            prepend-inner-icon="mdi-account-edit"
                        )

                        v-checkbox(
                            v-model="behindOnPayments"
                            color="primary"
                            dense
                            ripple
                            label="Behind on Payments"
                        )

                        v-checkbox(
                            v-model="mortgageProspect.bankruptcy"
                            color="primary"
                            dense
                            ripple
                            label="Bankruptcy"
                        )

                        v-text-field(
                            v-model="mortgageProspect.lead_key"
                            label='Universal Lead Id'
                            rows="1"
                            readonly
                            outlined
                            dense
                            prepend-inner-icon="mdi-account-edit"
                        )

                        v-checkbox(
                            v-model="mtgStatement"
                            color="primary"
                            dense
                            ripple
                            label="MTG Statement"
                        )

                        v-text-field(
                            v-model="propertyType"
                            label='Property Type'
                            rows="1"
                            clearable
                            outlined
                            dense
                            prepend-inner-icon="mdi-home"
                            :rules="[v => !!v || 'A property type is required']"
                            required
                        )

                        v-text-field(
                            v-model="loanBalance"
                            label='Loan Balance'
                            rows="1"
                            clearable
                            outlined
                            dense
                            type="number"
                            prepend-inner-icon="mdi-currency-usd"
                            @change="handleChangeLoanBalance"
                            :rules="[v => !!isNotEmpty(v) || 'A loan balance is required']"
                            required
                        )

                        v-text-field(
                            v-model="homeValue"
                            label='Home Value'
                            rows="1"
                            clearable
                            outlined
                            dense
                            type="number"
                            prepend-inner-icon="mdi-currency-usd"
                            :rules="[v => v > 0 || 'A home value is required']"
                            required
                            :hint="zillowValueHint"
                            persistent-hint
                        )
                            template(v-slot:append-outer)
                                v-btn.ma-2(
                                    color='primary'
                                    outlined
                                    ripple
                                    @click='getZillowValueRasani()'
                                    style="margin-top: -8px !important; min-height: 40px;"
                                    :loading="isLoadingZillowValue"
                                )
                                    v-icon mdi-home-currency-usd
                                    | Zillow

                        v-text-field(
                            v-model="ltv"
                            label='LTV'
                            rows="1"
                            readonly
                            outlined
                            dense
                            prepend-inner-icon="mdi-percent"
                        )

                        v-text-field(
                            v-model="equity"
                            label='Equity'
                            rows="1"
                            readonly
                            outlined
                            dense
                            type="number"
                            prepend-inner-icon="mdi-currency-usd"
                        )

                        v-menu(
                            v-model='mtgDatePickerMenu'
                            :close-on-content-click='false'
                            transition='scale-transition'
                            offset-y
                            max-width='290px'
                            min-width='290px'
                        )
                            template(v-slot:activator='{ on, attrs }')
                                v-text-field(
                                    :value='formatMTGDate'
                                    label='MTG Date'
                                    readonly
                                    v-bind='attrs'
                                    v-on='on'
                                    clearable
                                    outlined
                                    dense
                                    prepend-inner-icon='mdi-calendar'
                                    @click:clear='mtgDate = null;'
                                    :rules="[v => !!v || 'A mtg date is required']"
                                    required
                                )
                            v-date-picker(
                                v-model='mtgDate'
                                no-title
                                @input='mtgDatePickerMenu = false'
                            )

                        v-select(
                            v-model="loanType"
                            :items='loanTypes'
                            label='Loan Type'
                            :hint="loanTypeHint"
                            persistent-hint
                            clearable
                            outlined
                            dense
                            prepend-inner-icon="mdi-percent"
                            class="loan-type-select"
                            :rules="[v => !!v || 'A loan type is required']"
                            required
                        )

                        v-text-field(
                            v-model="lender"
                            label='Lender'
                            rows="1"
                            clearable
                            outlined
                            dense
                            prepend-inner-icon="mdi-office-building"
                            :rules="[v => !!v || 'A lender is required']"
                            required
                        )

                        v-text-field(
                            v-model="interestRate"
                            label='Interest Rate'
                            rows="1"
                            clearable
                            outlined
                            dense
                            type="number"
                            prepend-inner-icon="mdi-percent"
                            :rules="[v => v > 0 || 'An interest rate is required']"
                            required
                        )

                        v-select(
                            v-model="rateType"
                            :items='rateTypes'
                            label='Rate Type'
                            clearable
                            outlined
                            dense
                            prepend-inner-icon="mdi-percent"
                            :rules="[v => !!v || 'A rate type is required']"
                            required
                        )

                        v-autocomplete(
                            v-model='creditScore'
                            :items='creditScores'
                            label='Credit Score'
                            clearable
                            outlined
                            dense
                            prepend-inner-icon="mdi-speedometer"
                            :rules="[v => !!v || 'A credit score is required']"
                            required
                        )

                        v-text-field(
                            v-model="debt"
                            label='Debt'
                            rows="1"
                            clearable
                            outlined
                            dense
                            type="number"
                            prepend-inner-icon="mdi-currency-usd"
                            :rules="[v => !!isNotEmpty(v) || 'A debt value is required']"
                            required
                        )

                        v-text-field(
                            v-model="loanAmount"
                            label='Loan Amount'
                            rows="1"
                            clearable
                            outlined
                            dense
                            type="number"
                            prepend-inner-icon="mdi-currency-usd"
                            :rules="[v => v > 0 || 'A loan amount is required']"
                            required
                        )

                        v-text-field(
                            v-model="cashOut"
                            label='Cash Out'
                            rows="1"
                            clearable
                            outlined
                            dense
                            type="number"
                            prepend-inner-icon="mdi-currency-usd"
                            :rules="[v => !!isNotEmpty(v) || 'A cash out value is required']"
                            required
                        )

                        .text-right
                            v-btn.ma-2(
                                color='success'
                                outlined
                                ripple
                                :disabled="!requireSave"
                                @click='updateMortgageProspect()'
                            )
                                v-icon mdi-content-save
                                | Save
                            v-btn.ma-2(
                                color='primary'
                                outlined
                                ripple
                                :disabled="!!requireSave"
                                @click='pingPostRasani()'
                            )
                                v-icon mdi-phone-forward
                                | Ping Post

</template>

<script lang="ts">
    import Vue from 'vue';
    import VolieState from '@/store';

    // Components
    import AddressPostalFormat from "@/components/shared/addresses/address_postal_format.vue";
    import CustomerAddressFormDialog from "@/components/customer_addresses/form_dialog.vue";
    import TransferDialog from '@/components/communication_center/phone/transfer_dialog.vue';

    // Data
    import { 
        CreateMortgageProspect,
        FindMortgageProspect,
        GetRasaniLoanOfficerRoster, 
        GetZillowValueRasani,
        PingPostRasani,
        UpdateCustomerAddress,
        UpdateMortgageProspect,
    } from "@/data";

    // Helpers
    import { cleanupPhoneNumber } from "@/helpers/phone_number";

    export default Vue.extend({
        data() {
            return {
                bankruptcy: null,
                behindOnPayments: null,
                bus: VolieState.websocketClient().eventBus(),
                cashOut: null,
                creditScore: null,
                creditScores: [],
                debt: null,
                equity: null,
                homeValue: null,
                interestRate: null,
                invalidRawLoanType: null,
                isLoading: false,
                isLoadingZillowValue: false,
                lender: null,
                loanAmount: null,
                loanBalance: null,
                loanOfficer: null,
                loanOfficerError: null,
                loanOfficers: [],
                loanType: null,
                loanTypes: [
                    'Conventional',
                    'FHA',
                    'VA',
                    'VA Eligible',
                ],
                ltv: null,
                mortgageProspect: {},
                mtgDate: null,
                mtgDatePickerMenu: false,
                mtgStatement: null,
                pingPostError: false,
                pingPostResponse: null,
                propertyType: null,
                rateType: null,
                rateTypes: [
                    'Adjustable',
                    'Fixed',
                ],
                snackbar: {
                    show: false,
                    timeout: 3000,
                },
                sourceCode: null,
                transferDialog: false,
                transferTo: null,
                validRasaniForm: false,
                zillowValue: null,
            }
        },
        methods: {
            createMortgageProspect(options) {
                // Options coming thru are customer address params - agent can add mortgage prospect details on update

                // Verify valid current campaign customer
                if (!(this.currentCampaignCustomer?.customer_id > 0)) {
                    return;
                }

                //  Set on options
                options.account_id           = this.currentCampaignCustomer.account_id;
                options.organization_id      = this.currentCampaignCustomer.organization_id;
                options.campaign_customer_id = this.currentCampaignCustomer.campaign_customer_id;
                options.sourceable_type      = 'user';

                CreateMortgageProspect(options).then((response) => {
                    if (response?.Data?.mortgage_prospect?.mortgage_prospect_id > 0) {
                        this.setMortgageProspect(response.Data.mortgage_prospect);
                        this.showSnackbar("green", "Successfully created mortgage prospect");
                    } else {
                        this.showSnackbar("red", "Failed to create mortgage prospect");
                    }
                    this.isLoading = false;
                });
            },
            exitTransferDialog() {
                this.transferDialog = false;
            },
            findMortgageProspect() {
                if (!this.validRasani) {
                    return;
                }
                const options = {
                    account_id:           this.currentCampaignCustomer?.account_id,
                    campaign_customer_id: this.currentCampaignCustomer?.campaign_customer_id,
                    organization_id:      this.currentCampaignCustomer?.organization_id,
                }

                this.isLoading = true;
                FindMortgageProspect(options.campaign_customer_id, options).then((response) => {
                    if (response?.Data?.mortgage_prospect?.mortgage_prospect_id > 0) {
                        this.setMortgageProspect(response.Data.mortgage_prospect);
                    } else {
                        this.showSnackbar("red", "Failed to find mortgage prospect. Please add a customer address.");
                    }
                    this.isLoading = false;
                });
            },
            formatDate (date) {
                if (!date) {
                    return null;
                }
                const [year, month, day] = date.split('-');
                return `${month}/${day}/${year}`;
            },
            getZillowValueRasani() {
                if (!(this.currentCampaignCustomer?.account_id > 0)) {
                    return;
                }

                const options = {
                    account_id:  this.currentCampaignCustomer.account_id,
                    street_one:  this.mortgageProspect.address_street_one,
                    street_two:  this.mortgageProspect.address_street_two,
                    city:        this.mortgageProspect.address_city,
                    region:      this.mortgageProspect.address_region_abbr ? this.mortgageProspect.address_region_abbr : this.mortgageProspect.address_region,
                    postal_code: this.mortgageProspect.address_postal_code,
                }

                this.isLoadingZillowValue = true;
                GetZillowValueRasani(options).then((response) => {
                    if (response?.Data?.zillow_value > 0) {
                        this.zillowValue = response.Data.zillow_value;
                        this.homeValue   = response.Data.zillow_value;
                        this.handleChangeHomeValue();

                        this.showSnackbar("green", "Successfully loaded Zillow home value");
                    } else {
                        this.zillowValue = "Zillow api failure";

                        if (response?.Error?.includes("Please edit mortgage address.")) {
                            this.showSnackbar("red", response.Error);
                        } else {
                            this.showSnackbar("red", "Failed to get Zillow home value");
                        }
                    }
                    this.isLoadingZillowValue = false;
                });
            },
            handleChangeHomeValue() {
                // check valid home_value
                if (this.isNotEmpty(this.loanBalance) && this.homeValue > 0) {
                    this.ltv = ((this.loanBalance / this.homeValue) * 100).toFixed(2);
                    this.equity = this.homeValue - this.loanBalance;
                }
            },
            handleChangeLoanBalance() {
                // check valid loan_balance and home_value above 0
                if (this.isNotEmpty(this.loanBalance) && this.homeValue > 0) {
                    this.ltv = ((this.loanBalance / this.homeValue) * 100).toFixed(2);
                    this.equity = this.homeValue - this.loanBalance;
                }
            },
            isNotEmpty(value) {
                // 0 is ok but empty is not
                if (value?.toString()?.length > 0) {
                    return true;
                }

                return false;
            },
            onEditCustomerAddress(response) {

                // Verify customer address
                const newCustomerAddress = response?.Data?.new_customer_address;
                const oldCustomerAddress = response?.Data?.old_customer_address;
                if (!(newCustomerAddress?.customer_address_id > 0 && oldCustomerAddress?.customer_address_id > 0 && oldCustomerAddress?.customer_id > 0)) {
                    return;
                }

                // Verify it belongs to campaign customer
                if (this.currentCampaignCustomer?.customer_id !== oldCustomerAddress.customer_id) {
                    return;
                }

                // Verify old customer address matches mortgage prospect
                if (oldCustomerAddress?.customer_address_id === this.mortgageProspect?.customer_address?.customer_address_id) {
                    this.setMortgageProspectCustomerAddress(newCustomerAddress);
                }
            },
            openTransferDialog() {
                this.transferDialog = true;
            },
            pingPostRasani() {
                if (!this.validRasani || !this.validateRasaniForm() || this.requireSave) {
                    return;
                }

                const options = {
                    mortgage_prospect_id: this.mortgageProspect.mortgage_prospect_id,
                    account_id:           this.mortgageProspect.account_id,
                    organization_id:      this.mortgageProspect.organization_id,
                    credit_score:         parseInt(this.mortgageProspect.credit_score),
                    debt:                 parseFloat(this.mortgageProspect.debt),
                    interest_rate:        parseFloat(this.mortgageProspect.interest_rate),
                    loan_balance:         parseFloat(this.mortgageProspect.loan_balance),
                    loan_type:            this.mortgageProspect.loan_type,
                    ltv:                  parseFloat(this.mortgageProspect.ltv),
                    phone:                this.mortgageProspect.phone,
                    rate_type:            this.mortgageProspect.rate_type,
                    state_cd:             this.mortgageProspect.address_region_abbr ? this.mortgageProspect.address_region_abbr : this.mortgageProspect.address_region,
                }

                // reset ping post and loan officers
                this.pingPostError      = false;
                this.pingPostResponse   = null;
                this.sourceCode         = null;
                this.transferTo         = null;
                this.loanOfficerError   = null;
                this.loanOfficers       = [];

                this.isLoading = true;
                PingPostRasani(options).then((response) => {
                    if (response?.Data?.ping_post_response?.length > 0) {
                        this.pingPostResponse = response.Data.ping_post_response;
                        this.pingPostError    = response.Data.ping_post_error;

                        if (!this.pingPostError) {
                            this.showSnackbar("green", "Successful ping post to Rasani");

                            this.sourceCode = response.Data.source_code;

                            // Clean pn and set to transfer pn
                            const cleanPN = cleanupPhoneNumber(response.Data.transfer_to, "US");
                            if (cleanPN?.valid === true) {
                                this.transferTo = cleanPN.phoneNumber;
                            }

                            // get roster of loan officers
                            const rosterOptions = {
                                mortgage_prospect_id: this.mortgageProspect?.mortgage_prospect_id,
                                account_id:           this.mortgageProspect?.account_id,
                                organization_id:      this.mortgageProspect?.organization_id,
                            }

                            GetRasaniLoanOfficerRoster(rosterOptions).then((response) => {
                                if (response?.Data?.roster?.length > 0) {
                                    this.loanOfficers = response.Data.roster;
                                    if (this.loanOfficer?.length > 0 && this.loanOfficers?.length > 0 && !this.loanOfficers.includes(this.loanOfficers)) {
                                        this.loanOfficerError = 'Previously selected loan officer is not available';
                                        this.loanOfficer = null;
                                        this.validateRasaniForm();
                                        this.showSnackbar("red", this.loanOfficerError);
                                    }
                                } else if (this.loanOfficer?.length > 0) {
                                    this.loanOfficerError = 'Previously selected loan officer is not available';
                                    this.loanOfficer = null;
                                    this.validateRasaniForm();
                                    this.showSnackbar("red", this.loanOfficerError);
                                } else {
                                    this.showSnackbar("red", "Failed to load Rasani loan officer roster");
                                }
                            })
                        }
                    } else {
                        this.pingPostResponse = response?.Error;
                        this.pingPostError = true;

                        this.showSnackbar("red", "Failed to ping post Rasani");
                    }
                    this.isLoading = false;
                });
            },
            resetAndSearch() {
                this.resetMortgageProspect();
                this.findMortgageProspect();
            },
            resetMortgageProspect() {
                this.mortgageProspect = {};
            },
            setCreditScores() {
                let i = 580
                while (i <= 850) {
                    this.creditScores.push(i);
                    i = i + 10;
                }
            },
            setFormValues() {
                if (this.mortgageProspect?.mortgage_prospect_id > 0) {
                    this.bankruptcy       = this.mortgageProspect.bankruptcy;
                    this.behindOnPayments = this.mortgageProspect.behind_on_payments;
                    this.cashOut          = this.mortgageProspect.cash_out;
                    this.creditScore      = this.mortgageProspect.credit_score;
                    this.debt             = this.mortgageProspect.debt;
                    this.equity           = this.mortgageProspect.equity;
                    this.homeValue        = this.mortgageProspect.home_value;
                    this.interestRate     = this.mortgageProspect.interest_rate;
                    this.lender           = this.mortgageProspect.lender;
                    this.loanAmount       = this.mortgageProspect.loan_amount;
                    this.loanBalance      = this.mortgageProspect.loan_balance;
                    this.loanOfficer      = this.mortgageProspect.loan_officer;
                    this.loanType         = this.mortgageProspect.loan_type;
                    this.ltv              = this.mortgageProspect.ltv;
                    this.mtgDate          = this.mortgageProspect.friendly_mtg_date;
                    this.mtgStatement     = this.mortgageProspect.mtg_statement;
                    this.pingPostError    = this.mortgageProspect.ping_post_error;
                    this.pingPostResponse = this.mortgageProspect.ping_post_response;
                    this.propertyType     = this.mortgageProspect.property_type;
                    this.rateType         = this.mortgageProspect.rate_type;
                    this.sourceCode       = this.mortgageProspect.source_code;

                    // Clean pn and set to transfer pn
                    this.transferTo = null;
                    const cleanPN = cleanupPhoneNumber(this.mortgageProspect.transfer_to, "US");
                    if (cleanPN?.valid === true) {
                        this.transferTo = cleanPN.phoneNumber;
                    }
                }
            },
            setMortgageProspect(mortgageProspect) {
                this.mortgageProspect = mortgageProspect;
                this.setFormValues();
                this.handleChangeLoanBalance();
                if (this.mortgageProspect.loan_type?.length > 0 && !this.loanTypes.includes(this.mortgageProspect.loan_type)) {
                    this.invalidRawLoanType = this.mortgageProspect.loan_type;
                }
            },
            setMortgageProspectCustomerAddress(customerAddress) {
                this.mortgageProspect.customer_address = customerAddress;

                this.mortgageProspect.address_street_one = customerAddress.address_street_one;
                this.mortgageProspect.address_street_two = customerAddress.address_street_two;
                this.mortgageProspect.address_city = customerAddress.address_city;
                this.mortgageProspect.address_region = customerAddress.address_region;
                this.mortgageProspect.address_region_abbr = customerAddress.address_region_abbr;
                this.mortgageProspect.address_postal_code = customerAddress.address_postal_code;
                this.mortgageProspect.address_country = customerAddress.address_country;
            },
            showSnackbar(color, text) {
                if (color?.length > 0 && text?.length > 0) {
                    this.snackbar.color = color;
                    this.snackbar.text = text;
                    this.snackbar.show = true;
                }
            },
            updateCustomerAddress(options) {

                // Verify valid current campaign customer
                if (!(this.currentCampaignCustomer?.customer_id > 0)) {
                    return;
                }

                // Set on options
                options.customer_id     = this.currentCampaignCustomer.customer_id;
                options.account_id      = this.currentCampaignCustomer.account_id;
                options.organization_id = this.currentCampaignCustomer.organization_id;

                UpdateCustomerAddress(options.customer_address_id, options).then((response) => {
                    if (response?.Data?.customer_address?.customer_address_id > 0) {
                        this.setMortgageProspectCustomerAddress(response.Data.customer_address);
                        this.showSnackbar("green", "Successfully updated customer address");
                    } else {
                        this.showSnackbar("red", "Failed to update customer address");
                    }
                });
            },
            updateMortgageProspect() {
                if (!this.validRasani || !this.validateRasaniForm()) {
                    return;
                }

                const options = {
                    mortgage_prospect_id: this.mortgageProspect?.mortgage_prospect_id,
                    account_id:           this.mortgageProspect?.account_id,
                    organization_id:      this.mortgageProspect?.organization_id,
                    bankruptcy:           this.bankruptcy,
                    behind_on_payments:   this.behindOnPayments,
                    cash_out:             parseFloat(this.cashOut),
                    credit_score:         parseInt(this.creditScore),
                    debt:                 parseFloat(this.debt),
                    equity:               parseFloat(this.equity),
                    home_value:           parseFloat(this.homeValue),
                    interest_rate:        parseFloat(this.interestRate),
                    lender:               this.lender,
                    loan_amount:          parseFloat(this.loanAmount),
                    loan_balance:         parseFloat(this.loanBalance),
                    loan_officer:         this.loanOfficer,
                    loan_type:            this.loanType,
                    ltv:                  parseFloat(this.ltv),
                    mtg_date:             this.mtgDate,
                    mtg_statement:        this.mtgStatement,
                    property_type:        this.propertyType,
                    rate_type:            this.rateType,
                }

                // if loan officer error present due to loan officer being no longer available, reset even if not changed because not required - this request will remove the unavailable loan officer
                this.loanOfficerError = null;

                this.isLoading = true;
                UpdateMortgageProspect(options.mortgage_prospect_id, options).then((response) => {
                    if (response?.Data?.mortgage_prospect?.mortgage_prospect_id > 0 && this.mortgageProspect?.mortgage_prospect_id === response?.Data?.mortgage_prospect?.mortgage_prospect_id) {
                        this.setMortgageProspect(response.Data.mortgage_prospect);
                        this.showSnackbar("green", "Successfully updated mortgage prospect");
                    } else {
                        this.showSnackbar("red", "Failed to update mortgage prospect");
                    }
                    this.isLoading = false;
                });
            },
            validateRasaniForm() {
                const valid = this.$refs?.rasaniForm?.validate();
                if (!!valid) {
                    this.validRasaniForm = true;
                } else {
                    this.validRasaniForm = false;
                }

                return valid;
            },
        },
        computed: {
            currentUser() {
                return VolieState.currentUser();
            },
            formatMTGDate() {
                return this.formatDate(this.mtgDate);
            },
            isRasaniEnabled() {
                return this.currentUser?.account?.rasani_enabled === true;
            },
            loanTypeHint() {
                if (this.invalidRawLoanType?.length > 0) {
                    return 'Raw import value: ' + this.invalidRawLoanType;
                }

                return null;
            },
            renderTransferButton() {
                return this.callId > 0 && this.transferTo?.length > 0 && this.onCall === true;
            },
            requireSave() {
                // Check for form changes requiring a save
                if (this.loanOfficer !== this.mortgageProspect.loan_officer) return true;
                if (this.behindOnPayments !== this.mortgageProspect.behind_on_payments) return true;
                if (this.bankruptcy !== this.mortgageProspect.bankruptcy) return true;
                if (this.mtgStatement !== this.mortgageProspect.mtg_statement) return true;
                if (this.propertyType !== this.mortgageProspect.property_type) return true;
                if (parseFloat(this.loanBalance) !== parseFloat(this.mortgageProspect.loan_balance)) return true;
                if (parseFloat(this.homeValue) !== parseFloat(this.mortgageProspect.home_value)) return true;
                if (parseFloat(this.ltv) !== parseFloat(this.mortgageProspect.ltv)) return true;
                if (parseFloat(this.equity) !== parseFloat(this.mortgageProspect.equity)) return true;
                if (this.mtgDate !== this.mortgageProspect.friendly_mtg_date) return true;
                if (this.loanType !== this.mortgageProspect.loan_type) return true;
                if (this.lender !== this.mortgageProspect.lender) return true;
                if (parseFloat(this.interestRate) !== parseFloat(this.mortgageProspect.interest_rate)) return true;
                if (this.rateType !== this.mortgageProspect.rate_type) return true;
                if (parseInt(this.creditScore) !== parseInt(this.mortgageProspect.credit_score)) return true;
                if (parseFloat(this.debt) !== parseFloat(this.mortgageProspect.debt)) return true;
                if (parseFloat(this.loanAmount) !== parseFloat(this.mortgageProspect.loan_amount)) return true;
                if (parseFloat(this.cashOut) !== parseFloat(this.mortgageProspect.cash_out)) return true;

                return false;
            },
            validCampaignCustomer() {
                return this.currentCampaignCustomer?.campaign_customer_active_id > 0 && this.currentCampaignCustomer?.account_id > 0 && this.currentCampaignCustomer?.customer_id > 0 && this.currentCampaignCustomer?.organization_id > 0;
            },
            validMortgageProspect() {
                return this.mortgageProspect?.mortgage_prospect_id > 0
            },
            validRasani() {
                return this.validCampaignCustomer && this.isRasaniEnabled;
            },
            zillowValueHint() {
                if (this.zillowValue?.toString()?.length > 0) {
                    return 'Zillow value: ' + this.zillowValue;
                }

                return null;
            },
        },
        components: {
            AddressPostalFormat,
            CustomerAddressFormDialog,
            TransferDialog,
        },
        props: {
            callId: Number,
            currentCampaignCustomer: Object,
            endCall: Function,
            onCall: Boolean,
        },
        created() {
            this.resetAndSearch();
            this.setCreditScores();
            this.bus.subscribe({ type: "broadcast" , channel: { ID: null, Type: "editCustomerAddress" }}, this.onEditCustomerAddress);
        },
        destroyed() {
            this.bus.unsubscribe({ type: "broadcast" , channel: { ID: null, Type: "editCustomerAddress" }}, this.onEditCustomerAddress);
        },
    });
</script>

<style lang="scss" scoped>
    .v-input--checkbox {
        margin-top: 0px;
    }
</style>
