<template lang="pug">
    .ma-sm-2(v-if="validCustomer")
        .text-center(v-if="isLoading")
            v-progress-circular(
                :size='100'
                :width='10'
                color='#0f5b9a'
                indeterminate
                style="margin-top: 100px;"
            )

        v-container.mx-auto(v-if="!isLoading")
            .text-subtitle-2.subtitle-2-padding.text-center
                | Services

            ServiceDetails(
                v-if="validServices"
                v-for='service in services'
                :key='service.service_id'
                :service="service"
            )

            v-alert(outlined type='warning' dense border='left' style="margin-top: 16px;" v-if="!validServices")
                | Unable to find services

</template>

<script lang="ts">
	import Vue from 'vue';
	
    // Components
	import ServiceDetails from '@/components/services/service_details.vue';
	
    // Data
	import { GetServices } from "@/data";
	
    export default Vue.extend({
        data() {
            return {
                services: [],
                isLoading: false,
                lastServiceID: null,
                limit: 100,
            }
        },
        methods: {
            getServices() {
                if (!this.validCustomer) {
                    return;
                }
                const options = {
                    customer_id: this.currentCampaignCustomer?.customer_id,
                    last_service_id: this.lastServiceID || 0,
                    limit: this.limit,
                    organization_id: this.currentCampaignCustomer?.organization_id,
                }

                // todo - add show more to render above
                this.isLoading = true;
                GetServices(options).then((response) => {
                    if (response?.Data?.services?.length > 0) {

                        // Get last record and set Id
                        this.lastServiceID = response.Data.services[response.Data.services.length - 1]?.service_id;

                        if (this.services?.length > 0) {
                            this.services = this.services.concat(response.Data.services);
                        } else {
                            this.services = response.Data.services;
                        }
                    }
                    this.isLoading = false;
                });
            },
        },
        computed: {
            validServices() {
                return this.services?.length > 0;
            },
            validCustomer() {
                return this.currentCampaignCustomer?.account_id > 0 && this.currentCampaignCustomer?.customer_id > 0 && this.currentCampaignCustomer?.organization_id > 0;
            },
        },
        components: {
            ServiceDetails,
        },
        props: {
            currentCampaignCustomer: Object,
        },
        created() {
            this.getServices();
        },
    });
</script>

<style lang="scss" scoped>
</style>
