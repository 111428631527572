import {get, post, put, destroy } from '.'

export function CreateCampaignProof(options = {}) {
    return post<any>('/app/api/campaign_proofs', options);
}

export function DestroyCampaignProof(id) {
    return destroy<any>(`/app/api/campaign_proofs/${id}`);
}

export function GetCampaignProofs(options = {}) {
    return get<any>('/app/api/campaign_proofs', options);
}

export function GetCampaignEmailTemplateIds(options = {}) {
    return get<any>('/app/api/campaign_proofs/email_template_ids', options)
}

export function SendCampaignProof(id) {
    return put<any>(`/app/api/campaign_proofs/${id}/send_campaign_proof`)
}
