<template lang="pug">
    .ma-sm-2(v-if="validCustomer")
        .text-center(v-if="isLoading")
            v-progress-circular(
                :size='100'
                :width='10'
                color='#0f5b9a'
                indeterminate
                style="margin-top: 100px;"
            )

        v-container.mx-auto(v-if="!isLoading")
            .text-subtitle-2.subtitle-2-padding.text-center
                | Call History

            ConferenceHistoryDetails(
                v-if="validCalls"
                v-for='conference in communicationHistory.calls'
                :key='conference.conference_id'
                :conference="conference"
                :source="source"
            )

            v-alert(outlined type='warning' dense border='left' style="margin-top: 16px;" v-if="!validCalls")
                | Unable to find call history

        v-divider.divider-spacing(v-if="!isLoading")

        v-container.mx-auto(v-if="!isLoading")
            .text-subtitle-2.subtitle-2-padding.text-center
                | Email History

            EmailHistoryDetails(
                v-if="validEmails"
                v-for='email in communicationHistory.emails'
                :key='email.email_id'
                :email="email"
                :source="source"
            )

            v-alert(outlined type='warning' dense border='left' style="margin-top: 16px;" v-if="!validEmails")
                | Unable to find email history

        v-divider.divider-spacing(v-if="!isLoading")

        v-container.mx-auto(v-if="!isLoading")
            .text-subtitle-2.subtitle-2-padding.text-center
                | Message History

            ConversationHistoryDetails(
                v-if="validConversations"
                v-for='conversationGroup in communicationHistory.conversations'
                :key='conversationGroup.conversation_group_id'
                :conversation-group="conversationGroup"
            )

            v-alert(outlined type='warning' dense border='left' style="margin-top: 16px;" v-if="!validConversations")
                | Unable to find message history

</template>

<script lang="ts">
    import Vue from 'vue';

    // Components
    import ConferenceHistoryDetails from '@/components/conferences/conference_history_details.vue';
    import ConversationHistoryDetails from '@/components/conversations/conversation_history_details.vue';
    import EmailHistoryDetails from '@/components/emails/email_history_details.vue';

    // Data
    import { GetCustomerCommunicationHistory } from "@/data";

    export default Vue.extend({
        data() {
            return {
                communicationHistory: null,
                isLoading: false,
                limit: 100,
                lastConferenceID: null,
                lastEmailID: null,
            }
        },
        methods: {
            getCustomerCommunicationHistory() {
                if (!this.validCustomer) {
                    return;
                }

                const options = {
                    account_id: this.currentCampaignCustomer?.account_id,
                    customer_id: this.currentCampaignCustomer?.customer_id,
                    last_conference_id: this.lastConferenceID || 0,
                    last_email_id: this.lastEmailID || 0,
                    limit: this.limit,
                    organization_id: this.currentCampaignCustomer?.organization_id,
                }

                // todo - hook up show more to calls and emails components (currently limited to 100)
                this.isLoading = true;
                GetCustomerCommunicationHistory(options).then((response) => {
                    if (response?.Data?.communication_history.customer_id > 0 && this.currentCampaignCustomer?.customer_id === response?.Data?.communication_history.customer_id) {
                        this.communicationHistory = response.Data.communication_history;
                    }
                    this.isLoading = false;
                });
            },
            resetAndSearch() {
                this.resetCommunicationHistory();
                this.getCustomerCommunicationHistory();
            },
            resetCommunicationHistory() {
                this.communicationHistory = {}
            },
        },
        computed: {
            validCalls() {
                return this.communicationHistory?.calls?.length > 0;
            },
            validCustomer() {
                return this.currentCampaignCustomer?.account_id > 0 && this.currentCampaignCustomer?.customer_id > 0 && this.currentCampaignCustomer?.organization_id > 0;
            },
            validConversations() {
                return this.communicationHistory?.conversations?.length > 0;
            },
            validEmails() {
                return this.communicationHistory?.emails?.length > 0;
            },
        },
        components: {
            ConferenceHistoryDetails,
            ConversationHistoryDetails,
            EmailHistoryDetails,
        },
        props: {
            currentCampaignCustomer: Object,
            source: String,
        },
        created() {
            this.resetAndSearch();
        },
    });
</script>

<style lang="scss" scoped>
</style>
