import { get, put, post, destroy } from '.';

export function GetDispositionGroups(options = {}) {
	return get<any>("/app/api/disposition_groups", options);
}

export function FindDispositionGroup(vid) {
	return get<any>("/app/api/disposition_groups/" + vid);
}

export function CreateDispositionGroup(options = {}) {
	return post<any>("/app/api/disposition_groups", options);
}

export function UpdateDispositionGroup(id, options = {}) {
	return put<any>("/app/api/disposition_groups/" + (id || 0).toString(), options);
}

export function DestroyDispositionGroup(id, options = {}) {
	return destroy<any>("/app/api/disposition_groups/" + (id || 0).toString(), options);
}
