<template lang="pug">
    div
        v-container.mx-auto
            v-card.mx-lg-auto(width="100%")
                //- v-toolbar.volie-v-toolbar-color(dark)
                //-     v-toolbar-title Call Transfers

                v-simple-table.table-x-scroll
                    template(v-slot:default)
                        thead.volie-background-color-blue(style="padding-top: 15px;")
                            tr
                                th.white--text Datetime
                                th.white--text Type
                                th.white--text Location
                                th.white--text Destination
                                th.white--text Status
                                th.white--text To Number
                                th.white--text From Number
                        tbody(v-if="validCallTransfers")
                            tr(v-for='callTransfer in conference.call_transfers' :key='callTransfer.call_transfer_id')
                                td.no-wrap.sm-width {{ callTransfer.friendly_created_at_time }}
                                td.no-wrap.sm-width.text-capitalize {{ callTransfer.transfer_type }}
                                td.no-wrap.sm-width.text-capitalize {{ callTransfer.transfer_location }}
                                td.no-wrap.sm-width.text-capitalize
                                    template(v-if='callTransfer.transfer_destination')
                                        StrLimitTooltipText(:text="callTransfer.transfer_destination" :char-limit="25")
                                    template(v-else) N/a
                                td.no-wrap.sm-width.text-capitalize {{ callTransfer.transfer_status }}

                                td.no-wrap.sm-width 
                                    template(v-if="callTransfer.transfer_to_number") {{ callTransfer.transfer_to_number | tel }}
                                    template(v-else) ---
                                td.no-wrap.sm-width
                                    template(v-if="callTransfer.transfer_from_number") {{ callTransfer.transfer_from_number | tel }}
                                    template(v-else) ---

                        tbody(v-else-if="isLoading")
                            tr
                                td(colspan="7")
                                    .text-center
                                        v-progress-circular(
                                            :size='100'
                                            :width='10'
                                            color='#0f5b9a'
                                            indeterminate
                                            style="margin-top: 75px; margin-bottom: 75px;"
                                        )

            v-alert(v-if="!validCallTransfers && !isLoading" outlined type='info' dense border='left' style="margin-top: 16px;")
                | There are no call transfers for this conference

                        

</template>

<script lang="ts">
    import Vue from 'vue';

    import StrLimitTooltipText from "@/components/shared/str_limit_tooltip_text.vue";

    export default Vue.extend({
        computed: {
            validCallTransfers() {
                return this.conference?.call_transfers?.length > 0;
            },
        },
        props: {
            conference: Object,
            isLoading: Boolean,
        },
        components: {
            StrLimitTooltipText,
        },
    });
</script>

<style lang="scss" scoped>
</style>