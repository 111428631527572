<template lang="pug">
    div
        v-card.mx-lg-auto(width="100%" v-if='validAlertTypeSurvey' :style="idx !== 0 ? 'margin-top: 25px;' : ''")
            v-toolbar.volie-v-toolbar-color(dark)
                v-toolbar-title Hot Alert {{ idx+1 }}

            v-card-text
                v-simple-table
                    template(v-slot:default)
                        tbody
                            tr
                                td(style="max-width: 50%; width: 50%;")
                                    .text-capitalize.text-overline.text--secondary.text--darken-3 Alert Type
                                    template(v-if="alertTypeSurvey.alert_type_name") {{ alertTypeSurvey.alert_type_name }}
                                    template(v-else) ---
                                td(style="max-width: 50%; width: 50%;")
                                    .text-capitalize.text-overline.text--secondary.text--darken-3 Datetime
                                    template(v-if="alertTypeSurvey.friendly_created_at") {{ alertTypeSurvey.friendly_created_at }}
                                    template(v-else) ---
                            tr
                                td(style="max-width: 50%; width: 50%;")
                                    .text-capitalize.text-overline.text--secondary.text--darken-3 Email Template
                                    template(v-if="alertTypeSurvey.email_template_name") {{ alertTypeSurvey.email_template_name }}
                                    template(v-else) ---
                                td(style="max-width: 50%; width: 50%;")
                                    .text-capitalize.text-overline.text--secondary.text--darken-3 Vid
                                    template(v-if="alertTypeSurvey.alert_type_survey_key") {{ alertTypeSurvey.alert_type_survey_key }}
                                    template(v-else) ---
                            tr
                                td(style="max-width: 50%; width: 50%;")
                                    .text-capitalize.text-overline.text--secondary.text--darken-3 Pending
                                    v-icon.green--text(v-if="alertTypeSurvey.is_pending") mdi-check
                                    v-icon.error--text(v-else) mdi-close
                                td(style="max-width: 50%; width: 50%;")
                                    .text-capitalize.text-overline.text--secondary.text--darken-3 Sent Alerts
                                    v-icon.green--text(v-if="alertTypeSurvey.sent_alerts") mdi-check
                                    v-icon.error--text(v-else) mdi-close

                            //- used for bottom border
                            tr

            div.pa-4
                v-card.mx-lg-auto
                    v-simple-table.table-x-scroll
                        template(v-slot:default)
                            thead.volie-background-color-blue(style="padding-top: 15px;")
                                tr
                                    th.white--text Sent At
                                    th.white--text Sent
                                    th.white--text Pending
                                    th.white--text Alert Method
                                    th.white--text Department
                                    th.white--text Job Title
                                    th.white--text Employee
                                    th.white--text Vid
                            tbody(v-if="validAlerts")
                                tr(v-for='alert in alertTypeSurvey.alerts' :key='alert.alert_id')
                                    td.no-wrap.xs-width {{ alert.friendly_sent_at ? alert.friendly_sent_at : '---' }}
                                    td.no-wrap.xs-width 
                                        v-icon.green--text(v-if="alert.is_sent") mdi-check
                                        v-icon.error--text(v-else) mdi-close
                                    td.no-wrap.xs-width 
                                        v-icon.green--text(v-if="alert.is_pending") mdi-check
                                        v-icon.error--text(v-else) mdi-close
                                    td.no-wrap.sm-width 
                                        template(v-if='alert.alert_method === 0') Email 
                                        template(v-else-if='alert.alert_method === 1') Text 
                                        template(v-else-if='alert.alert_method === 2') Adf Xml 
                                        template(v-else) ---
                                    td.no-wrap.sm-width  {{ alert.department_name ? alert.department_name : '---' }}
                                    td.no-wrap.sm-width  {{ alert.job_title_name ? alert.job_title_name : '---' }}
                                    td.no-wrap.sm-width 
                                        template(v-if="alert.user_id > 0")
                                            StrLimitTooltipText(:text="displayUser(alert)" :char-limit="35")
                                        template(v-else) ---
                                    td.no-wrap.sm-width  {{ alert.alert_key }}

                            tbody
                                v-alert(colspan='8' v-if="!validAlerts && !isLoading" outlined type='info' dense border='left' style="margin-top: 16px;")
                                    | There are no alerts for this conference

</template>

<script lang="ts">
    import Vue from 'vue';

    // Components
    import StrLimitTooltipText from "@/components/shared/str_limit_tooltip_text.vue";

    export default Vue.extend({
        data() {
            return {}
        },
        methods: {
            displayUser(obj) {
                if (!(obj?.user_id > 0)) {
                    return null;
                }

                if (obj?.user_email?.length > 0) {
                    return ((obj.user_first_name + ' ' + obj.user_last_name).trim() + ' <' + obj.user_email + '>').trim();
                }

                return (obj.user_first_name + ' ' + obj.user_last_name).trim();
            },
        },
        computed: {
            validAlertTypeSurvey() {
                return this.alertTypeSurvey?.alert_type_survey_id > 0;
            },
            validAlerts() {
                return this.alertTypeSurvey?.alerts?.length > 0;
            },
        },
        props: {
            alertTypeSurvey: Object,
            idx: Number,
        },
        components: {
            StrLimitTooltipText,
        },
    });
</script>

<style lang="scss" scoped>
</style>
