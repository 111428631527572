import { get, post, put, destroy } from '.';

export function GetConversicaCampaignMappings(options = {}) {
	return get<any>("/app/api/conversica_campaign_mappings", options);
}

export function FindConversicaCampaignMapping(key, options = {}) {
	return get<any>("/app/api/conversica_campaign_mappings/" + key, options);
}

export function CreateConversicaCampaignMapping(options = {}) {
	return post<any>("/app/api/conversica_campaign_mappings", options);
}

export function DestroyConversicaCampaignMapping(id, options = {}) {
	return destroy<any>("/app/api/conversica_campaign_mappings/" + (id || 0).toString(), options);
}

export function UpdateConversicaCampaignMapping(id, options = {}) {
	return put<any>("/app/api/conversica_campaign_mappings/" + (id || 0).toString(), options);
}
