import {get, post, put, destroy} from "@/data/index"

export function GetCommunicationQueues(options = {}) {
    return get<any>('/app/api/communication_queues', options)
}

export function DestroyCommunicationQueue(id: string) {
    return destroy<any>(`/app/api/communication_queues/${id}`)
}

export function CreateCommunicationQueue(options = {}) {
    return post<any>('/app/api/communication_queues', options)
}

export function UpdateCommunicationQueue(id: string, options = {}) {
    return put<any>(`/app/api/communication_queues/${id}`, options)
}

export function UpdateCommunicationQueuesOrders(options = {}) {
    return put<any>('/app/api/communication_queues/reorder', options)
}