import {get, put, post, destroy} from "@/data/index";

export function CreateCommunicationQueueGroup(options = {}) {
    return post<any>("/app/api/communication_queue_groups", options);
}

export function DestroyCommunicationQueueGroup(vid, options = {}) {
	return destroy<any>("/app/api/communication_queue_groups/" + vid, options);
}

export function FindCommunicationQueueGroup(vid) {
    return get<any>("/app/api/communication_queue_groups/" + vid);
}

export function GetCommunicationQueueGroups(options = {}) {
    return get<any>("/app/api/communication_queue_groups", options)
}

export function UpdateCommunicationQueueGroup(vid, options = {}) {
	return put<any>("/app/api/communication_queue_groups/" + (vid || 0).toString(), options);
}