import { post, destroy, put, } from '.';

export function CreateCustomerPhoneNumber(options = {}) {
	return post<any>("/app/api/customer_phone_numbers", options);
}

export function UpdateCustomerPhoneNumber(id, options = {}) {
	return put<any>("/app/api/customer_phone_numbers/" + (id || 0).toString(), options);
}

export function DestroyCustomerPhoneNumber(id, options = {}) {
	return destroy<any>("/app/api/customer_phone_numbers/" + (id || 0).toString(), options);
}

export function UpdateCustomerPhonePrimary(id, options = {}) {
	return put<any>("/app/api/update_customer_phone_number_primary/" + (id || 0).toString(), options);
}