import { get } from '.';

export function GetSummaryRecordsAppointments(options = {}) {
    return get<any>("/app/api/summary_records/appointments", options)
}

export function GetSummaryRecordsEmails(options = {}) {
    return get<any>("/app/api/summary_records/emails", options)
}

export function GetSummaryRecordsFollowUps(options = {}) {
    return get<any>("/app/api/summary_records/follow_ups", options)
}

export function GetSummaryRecordsIgnoreCalls(options = {}) {
    return get<any>("/app/api/summary_records/ignores", options)
}

export function GetSummaryRecordsPhoneCalls(options = {}) {
    return get<any>("/app/api/summary_records/phone_calls", options)
}

export function GetSummaryRecordsSkips(options = {}) {
    return get<any>("/app/api/summary_records/skips", options)
}

export function GetSummaryRecordsSmsMessages(options = {}) {
    return get<any>("/app/api/summary_records/sms_messages", options)
}

export function GetCampaignUserSummarySummaryRecordsAppointments(options = {}) {
    return get<any>("/app/api/campaign_summary_summary_records/appointments", options)
}

export function GetCampaignUserSummarySummaryRecordsEmails(options = {}) {
    return get<any>("/app/api/campaign_summary_summary_records/emails", options)
}

export function GetCampaignUserSummarySummaryRecordsFollowUps(options = {}) {
    return get<any>("/app/api/campaign_summary_summary_records/follow_ups", options)
}

export function GetCampaignUserSummarySummaryRecordsPhoneCalls(options = {}) {
    return get<any>("/app/api/campaign_summary_summary_records/phone_calls", options)
}

export function GetCampaignUserSummarySummaryRecordsSkips(options = {}) {
    return get<any>("/app/api/campaign_summary_summary_records/skips", options)
}

export function GetCampaignUserSummarySummaryRecordsSmsMessages(options = {}) {
    return get<any>("/app/api/campaign_summary_summary_records/sms_messages", options)
}

