<template lang="pug">
    v-card.attachment-container-card(
        v-if="attachment"
        :class="attachmentContainerClass"
        @mouseover="hover = true"
        @mouseleave="hover = false"
    )
        FloatingCloseButton(
            v-if="removable"
            @click.native="handleRemove"
        )

        v-img.image-container-card(
            v-if="isImage"
            :src="sourceUrl"
            contain
        )
            template(v-slot:placeholder)
                v-skeleton-loader(
                    class="mx-auto"
                    type="image"
                    width="100%"
                    height="100%"
                    :loading="true"
                )

        v-container.attachment-icon-container(v-else style="padding: 5px;")
            .mr-2
                v-icon(v-if="isPdf" large) mdi-file-pdf-box
                v-icon(v-else-if="isAudio" large) mdi-waveform
                v-icon(v-else-if="isVideo" large) mdi-video
                v-icon(v-else-if="isCsv" large) mdi-file-table-outline
                v-icon(v-else large) mdi-file

            v-list(two-line)
                .text-left(style="font-size: 14px; font-weight: 400;")
                    StrLimitTooltipText(:text="attachment.original_file_name" :char-limit="30")
                    v-list-item-subtitle(v-text="attachmentMetadata" style="color: #666666; font-size: 12px;")

        v-fade-transition(v-if="isLink")
            v-overlay(v-if='hover' absolute color='#999999' :opacity=".6")
                v-btn.mr-2(fab light x-small @click="dialog = true" v-if="isExpandable")
                    v-icon(color="primary") mdi-magnify
                v-btn(fab light x-small @click="handleClick")
                    v-icon(color="primary") mdi-download

        v-dialog(v-model='dialog' max-width='750px')
            v-card.width-100-percent
                v-card-title
                    div(style="width: 90%;")
                        span.text-h5 {{ attachment.original_file_name }}
                    div(style="width: 10%; display: flex; flex-direction: row; justify-content: flex-end; align-items: center;")
                        v-btn(icon light x-large @click="dialog = false")
                            v-icon(large) mdi-close

                v-divider.ma-0

                v-img.width-100-percent(
                    v-if="isImage"
                    :src="sourceUrl"
                    contain
                    max-height='500px'
                    max-width='750px'
                )
                AudioPlayer.ma-5.pa-5(
                    v-else-if="isAudio"
                    :recording-url="sourceUrl"
                )
                VideoPlayer(
                    v-else-if="isVideo"
                    :video-type="attachmentContentType"
                    :video-url="sourceUrl"
                )

</template>

<script lang="ts">
    import Vue from 'vue';

    // Components
    import AudioPlayer from '@/components/shared/audio_player.vue';
    import FloatingCloseButton from '@/components/shared/buttons/floating_close_button.vue';
    import StrLimitTooltipText from "@/components/shared/str_limit_tooltip_text.vue";
    import VideoPlayer from '@/components/shared/video_player.vue';

    export default Vue.extend({
        data() {
            return {
                dialog: false,
                hover: false,
            }
        },
        methods: {
            formatFileSize(byteSize :number) {
                if (byteSize ===  0) {
                    return '0 bytes'
                }

                const i = byteSize == 0 ? 0 : Math.floor(Math.log(byteSize) / Math.log(1024));
                const numValue = (byteSize / Math.pow(1024, i)).toFixed(0);
                const numUnit = ['Bytes', 'kB', 'MB', 'GB', 'TB'][i];
                return numValue + ' ' + numUnit;
            },
            handleClick() {
                if (!(this.isLink && this.sourceUrl?.length > 0)) {
                    return;
                }

                window.open(this.sourceUrl, '_blank');
            },
        },
        computed: {
            attachmentContentType() {
                return this.attachment?.asset_content_type || null;
            },
            attachmentContainerClass() {
                const classes = [];

                if (this.alignCenter) {
                    classes.push('mx-auto');
                }
                if (this.isImage) {
                    classes.push('image-container-card');
                }
                if (this.isLink) {
                    classes.push('cursor-default');
                } else {
                    classes.push('cursor-text');
                }

                return classes.join(' ');
            },
            attachmentMetadata() {
                const text = [];
                if (this.attachment?.file_extension?.length > 0) {
                    text.push(this.attachment.file_extension.toUpperCase());
                }
                if (this.attachment?.asset_file_size > 0) {
                    text.push(this.formatFileSize(this.attachment.asset_file_size));
                }

                return text.join(' - ');
            },
            isAudio() {
                return this.attachmentContentType === 'audio/mp3' 
                    || this.attachmentContentType === 'audio/mpeg' 
                    || this.attachmentContentType === 'audio/wav';
            },
            isCsv() {
                return this.attachmentContentType === 'text/csv';
            },
            isExpandable() {
                return this.isAudio || this.isImage || this.isVideo;
            },
            isImage() {
                return this.attachment?.file_type === 'image' || this.attachmentContentType?.includes("image/");
            },
            isPdf() {
                return this.attachmentContentType === 'application/pdf';
            },
            isVideo() {
                return this.attachmentContentType === 'video/mp4';
            },
            sourceUrl() {
                return this.attachment?.asset_url || this.attachment?.media_url;
            },
        },
        props: {
            alignCenter: Boolean,
            attachment: Object,
            handleRemove: Function,
            isLink: Boolean,
            removable: Boolean,
        },
        components: {
            AudioPlayer,
            FloatingCloseButton,
            StrLimitTooltipText,
            VideoPlayer,
        },
    });
</script>

<style lang="scss" scoped>

    #app {
        .attachment-container-card {
            display: flex; 
            flex-direction: column; 
            justify-content: center; 
            align-items: center; 
            min-height: 100px; 
            max-height: 125px; 
            min-width: 150px; 
            max-width: 315px; 
            margin-bottom: 1px; 
            margin-top: 1px;
        }

        .attachment-icon-container {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
        }

        .image-container-card {
            min-height: 100px; 
            max-height: 125px; 
            min-width: 100px; 
            max-width: 125px; 
        }
    }

</style>
