import { get, post, put } from '.';

export function GetCampaignCustomerActives(options = {}) {
	return get<any>("/app/api/campaign_customer_actives", options);
}

export function GetCampaignCustomerOtherAvailableCampaigns(id, options = {}) {
	return get<any>("/app/api/campaign_customer_actives/" + (id || 0).toString() + "/other_available_campaigns", options);
}

export function GetUnrespondedToCommunications(options = {}) {
	return get<any>("/app/api/campaign_customer_actives/unresponded_to_communications", options);
}


export function ProcessCampaignCustomerActive(options = {}) {
	return post<any>("/app/api/process_campaign_customer_active", options);
}

export function ReassignCampaignCustomerCampaign(id, options = {}) {
	return put<any>("/app/api/campaign_customer_actives/" + (id || 0).toString() + "/reassign_campaign", options);
}

export function UpdateCampaignCustomerActive(options = {}) {
	return put<any>("/app/api/campaign_customer_active", options);
}

export function UpdateCampaignCustomerActiveCustomerVehicle(id, options = {}) {
	return put<any>("/app/api/campaign_customer_active_customer_vehicle/" + (id || 0).toString(), options);
}