<template lang="pug">
    div(v-if="currentAgent")
        v-tooltip(v-if="isConnectionUnstable" bottom)
            template(v-slot:activator='{ on, attrs }')
                span.red--text(v-bind="attrs" v-on="on" role="text")
                    v-icon(color="red") mdi-wifi-off
                    | {{ renderStatus }}
            span
                | Your connection is unstable

        template(v-else) {{ renderStatus }}
</template>

<script lang="ts">
	import Vue from 'vue';
    import VolieState from '@/store';
    import { secondsToFullWords } from '@/helpers/general';

	export default Vue.extend({
        data() {
            return {
                bus: VolieState.websocketClient().eventBus(),
                isRacOnline: true,
                isWindowOnline: true,
            }
        },
        methods: {
            handleWindowOnline() {
                this.isWindowOnline = true;
            },
            handleWindowOffline() {
                this.isWindowOnline = false;
            },
            onClientStateChange(isOpen) {
                this.isRacOnline = isOpen;
            },
        },
        computed: {
            currentAgent() {
                return VolieState.currentAgent();
            },
            currentAgentTimer() {
                return VolieState.currentAgentTimer();
            },
            isConnectionUnstable() {
                return !this.isWindowOnline || !this.isRacOnline;
            },
            renderStatus() {
                return this.currentAgentTimer >= 0 ? this.currentAgent?.agent_status_name + ": " + secondsToFullWords(this.currentAgentTimer) : this.currentAgent?.agent_status_name;
            },
        },
        components: {
        },
        destroyed() {
            this.bus.unsubscribe({ type: "clientState" }, this.onClientStateChange);
            window.removeEventListener('online', this.handleWindowOnline);
            window.removeEventListener('offline', this.handleWindowOffline);
        },
        created() {
            this.bus.subscribe({ type: "clientState" }, this.onClientStateChange);
        },
        mounted() {
            window.addEventListener('online', this.handleWindowOnline);
            window.addEventListener('offline', this.handleWindowOffline);
        },
	});
</script>

<style lang="scss" scoped>
</style>
