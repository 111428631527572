import { get, post } from '.';

export function GetTekionEmployees(options = {}) {
	return get<any>("/app/api/tekion/employees", options);
}

export function ImportTekionEmployees(options = {}) {
	return post<any>("/app/api/tekion/employees/import", options);
}

export function GetTekionEmployee(key) {
	return get<any>("/app/api/tekion/employees/" + key);
}
