export function formatDollarFloat(dollars: number): string {
    if (dollars === null || dollars === undefined || isNaN(dollars)) {
        return "$0.00"
    }

    return dollars.toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2, style: 'currency', currency: 'USD' });
}

export function formatCentsToDollarsFloat(cents: number): string {
    if (cents === null || cents === undefined || isNaN(cents)) {
        return "$0.00"
    }

    const dollars = cents / 100;

    return formatDollarFloat(dollars);
}
