import { get, post } from '.';

export function FindAdfEmail(adfEmailKey, options = {}) {
	return get<any>("/app/api/adf_emails/" + adfEmailKey, options);
}

export function GetAdfEmails(options = {}) {
	return get<any>("/app/api/adf_emails", options);
}

export function AssignAdfEmailCampaign(options = {}) {
	return post<any>("/app/api/adf_emails/assign_adf_email_campaign", options);
}
