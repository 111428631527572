import { get, post, put, destroy } from '.'

export function GetCallTranscriptionRules(options = {}) {
    return get<any>("/app/api/call_transcription_rules", options)
}

export function FindCallTranscriptionRule(key) {
    return get<any>("/app/api/call_transcription_rules/" + key)
}

export function CreateCallTranscriptionRule(options = {}) {
    return post<any>("/app/api/call_transcription_rules", options)
}

export function UpdateCallTranscriptionRule(id, options = {}) {
    return put<any>("/app/api/call_transcription_rules/" + (id || 0).toString(), options)
}

export function DestroyCallTranscriptionRule(id) {
    return destroy<any>("/app/api/call_transcription_rules/" + (id || 0).toString())
}