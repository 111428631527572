<template lang="pug">
    v-card.mx-auto.my-sm-4(
        elevation="4"
        outlined
    )
        v-toolbar(
            flat
            rounded
            :src="toolbarBackground"
        )
            v-btn(
                color="primary"
                icon
                ripple
                @click="showDetails = !showDetails;"
            )
                v-icon(v-if="showDetails === true")
                    | mdi-chevron-down
                v-icon(v-else)
                    | mdi-chevron-right

            v-toolbar-title
                | {{ vehicleYearMakeModel }}
                br
                span.text-body-2
                    | {{ vehicleInventory.organization_name }}
            v-spacer
            .text-caption.text-right.m-l-5
                strong.text-uppercase(:class="vehicleInventory.inventory_status | statusClassMatcher") {{ vehicleInventory.inventory_status | titleize }}
                br
                strong.text-uppercase {{ friendlyVehicleType | titleize }}

        v-card-text
            v-simple-table
                template(v-slot:default)
                    tbody
                        tr
                            td.text-center(style="width: 100.0%; max-width: 100.0%;" colspan="3")
                                h6
                                    strong(v-if="vehicleInventory.list_price > 0") List Price: {{ vehicleInventory.list_price | dollar }}
                                    template(v-else) List Price: N/a

                        template(v-if="showDetails")
                            tr
                                td.text-center(style="width: 33.33%; max-width: 33.33%;") VIN: {{ vehicleInventory.vin?.length > 0 ? vehicleInventory.vin : 'N/a' }}
                                td.text-center(style="width: 33.33%; max-width: 33.33%;") Stock #: {{ (vehicleInventory.stock_number?.length > 0 ? vehicleInventory.stock_number : 'N/a') }}
                                td.text-center(style="width: 33.33%; max-width: 33.33%;") Last Updated: {{ vehicleInventory.friendly_updated_at }}
                            tr
                                td.text-center(style="width: 33.33%; max-width: 33.33%;") Odometer: {{ vehicleInventory.odometer > 0 ? vehicleInventory.odometer : 'N/a' | number }}
                                td.text-center(style="width: 33.33%; max-width: 33.33%;") Ext: {{ (vehicleInventory.exterior_color?.length > 0 ? vehicleInventory.exterior_color : 'N/a') | titleize }}
                                td.text-center(style="width: 33.33%; max-width: 33.33%;") Int: {{ (vehicleInventory.interior_color?.length > 0 ? vehicleInventory.interior_color : 'N/a') | titleize }}
                            tr
                                td.text-center(style="width: 33.33%; max-width: 33.33%;") Transmission: {{ vehicleInventory.transmission?.length > 0 ? vehicleInventory.transmission : 'N/a' | number }}
                                td.text-center(style="width: 33.33%; max-width: 33.33%;") Engine: {{ (vehicleInventory.engine?.length > 0 ? vehicleInventory.engine : 'N/a') | titleize }}
                                td.text-center(style="width: 33.33%; max-width: 33.33%;") Cylinder: {{ (vehicleInventory.cylinder?.length > 0 ? vehicleInventory.cylinder : 'N/a') | titleize }}

</template>

<script lang="ts">
    import Vue from 'vue';

    // Helpers
    import { concatVehicleYearMakeModel } from "@/helpers/general";

    // Types
    import { VehicleYearMakeModelObj } from "@/helpers/general";

    export default Vue.extend({
        data() {
            return {
                showDetails: false,
            }
        },
        methods: {
        },
        computed: {
            friendlyVehicleType() {
                if (!this.validVehicleInventory) {
                    return;
                }

                // Set
                let output = this.vehicleInventory.vehicle_type;
                switch(output) {
                case "used":
                    output = "pre-owned";
                    break;
                }
                return output;
            },
            toolbarBackground() {
                return process.env.VUE_APP_VOLIE_DETAILS_COMPONENT_TOOLBAR_BACKGROUND_IMG;
            },
            validVehicleInventory() {
                return this.vehicleInventory?.vehicle_inventory_id > 0;
            },
            vehicleYearMakeModel() {
                if (!this.validVehicleInventory) {
                    return null;
                }

                const vehObj = {
                    vehicleModelYear: this.vehicleInventory?.model_year,
                    vehicleMake:      this.vehicleInventory?.make,
                    vehicleModel:     this.vehicleInventory?.model,
                    vehicleTrim:      this.vehicleInventory?.trim,
                }

                return concatVehicleYearMakeModel(vehObj as VehicleYearMakeModelObj);
            },
        },
        components: {
        },
        props: {
            vehicleInventory: Object,
        },
    });
</script>

<style lang="scss" scoped>
    #app {
        .table-cell-padding {
            padding-top: 10px;
            padding-bottom: 10px;
        }
    }
</style>
