import { get, destroy, post, put } from '.';

export function GetCampaignAttemptCallingTimes(options = {}) {
    return get<any>("/app/api/campaign_attempt_calling_times", options);
}
export function CreateCampaignAttemptCallingTime(options = {}) {
    return post<any>('/app/api/campaign_attempt_calling_times', options)
}
export function UpdateCampaignAttemptCallingTime(id: string, options = {}) {
    return put<any>(`/app/api/campaign_attempt_calling_times/${id}`, options)
}
export function DestroyCampaignAttemptCallingTime(id: string) {
    return destroy<any>(`/app/api/campaign_attempt_calling_times/${id}`);
}