import { get } from '.';

export function GetUsers(options = {}) {
	return get<any>("/app/api/users", options);
}

export function GetLoggedInCampaigns(id, options = {}) {
	return get<any>("/app/api/users/" + (id || 0).toString() + "/logged_in_campaigns", options);
}

export function VerifyCurrentAgent(id, options = {}) {
	return get<any>("/app/api/users/" + (id || 0).toString() + "/verify_current_agent", options);
}