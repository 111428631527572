<template lang="pug">
    .ma-sm-2(v-if="validCustomer")
        .text-center(v-if="isLoading")
            v-progress-circular(
                :size='100'
                :width='10'
                color='#0f5b9a'
                indeterminate
                style="margin-top: 100px;"
            )

        v-snackbar(
            v-model='snackbarOpen'
            :color='snackbar.color'
            :timeout="snackbar.timeout"
            top
        )
            | {{ snackbar.text }}

            template(v-slot:action="{ attrs }")
                v-btn(dark text @click='snackbarOpen = false' v-bind="attrs")
                    | Close

        v-container.mx-auto(v-if="!isLoading")
            v-row(no-gutters)
                v-tabs(grow show-arrows light v-model="currentTab")
                    v-tab(v-for='(item, i) in tabItems' :key='item.key' :href="item.href")
                        | {{ item.name }}

                v-tabs-items(style='width: 100%;')
                    keep-alive
                        div(v-if="currentTab === 'vehicles-tab'")
                            v-row.py-4
                                v-col(cols='12')
                                    .text-right(v-if="!hideCreateUpdateDestroyButtons")
                                        CustomerVehicleFormDialog(
                                            :create-customer-vehicle="createCustomerVehicle"
                                        )

                            CustomerVehicleDetails(
                                v-if="validCustomerVehicles"
                                v-for='customerVehicle in customerVehicles'
                                :key='customerVehicle.customer_vehicle_id'
                                :agents-can-delete-customer-vehicles="agentsCanDeleteCustomerVehicles"
                                :agents-can-edit-customer-vehicles="agentsCanEditCustomerVehicles"
                                :campaign-customer-id="campaignCustomerID"
                                :customer-vehicle="customerVehicle"
                                :primary-customer-vehicle-id="primaryCustomerVehicleId"
                                :destroy-customer-vehicle="destroyCustomerVehicle"
                                :hide-create-update-destroy-buttons="hideCreateUpdateDestroyButtons"
                                :in-communication-center="inCommunicationCenter"
                                :update-campaign-customer-active-customer-vehicle="updateCampaignCustomerActiveCustomerVehicle"
                                :update-vehicle="updateVehicle"
                                :change-tab="changeTab"

                            )

                            .text-center(v-if="showMore")
                                v-btn.volie-color-blue(link text ripple small @click="getCustomerVehicles(true)")
                                    | Show More

                            v-alert(outlined type='warning' dense border='left' style="margin-top: 16px;" v-if="!validCustomerVehicles")
                                | Customer does not have any vehicles

                        CustomerVehicleSearch.overflow-y-auto(
                                v-if="currentTab === 'search-tab'"
                                :organization-id="currentCampaignCustomer.organization_id"
                                :create-customer-vehicle="createCustomerVehicle"
                                :is-vehicle-search="true"
                            )

</template>

<script lang="ts">
    import Vue from 'vue';

    // Components
    import CustomerVehicleDetails from '@/components/customer_vehicles/customer_vehicle_details.vue';
    import CustomerVehicleFormDialog from '@/components/customer_vehicles/form_dialog.vue';
    import CustomerVehicleSearch from '@/components/customers/search.vue';

    // Data
    import { CreateCustomerVehicle } from "@/data";
    import { DestroyCustomerVehicle } from "@/data";
    import { GetCustomerVehicles } from "@/data";
    import { UpdateCampaignCustomerActiveCustomerVehicle } from "@/data";
    import { UpdateVehicle } from "@/data";

    export default Vue.extend({
        data() {
            return {
                agentsCanDeleteCustomerVehicles: false,
                agentsCanEditCustomerVehicles: false,
                currentTab: 'vehicles-tab',
                customerVehicles: [],
                isLoading: false,
                lastCustomerVehicleId: null,
                limit: 25,
                primaryCustomerVehicleId: null,
                snackbarOpen: false,
                snackbar: {
                    timeout: 2000,
                },
                tabItems: [],
            }
        },
        methods: {
            createCustomerVehicle(vehicleOptions) {
                if (!(this.validCustomer && vehicleOptions?.vehicle_make?.length > 0 && vehicleOptions?.vehicle_model?.length > 0 && vehicleOptions?.vehicle_model_year?.length > 0)) {
                    return;
                }
                
                // Set options
                const options = {
                    account_id:                  this.currentCampaignCustomer.account_id,
                    campaign_customer_active_id: (this.currentCampaignCustomer.campaign_customer_active_id || 0),
                    customer_id:                 this.currentCampaignCustomer.customer_id,
                    organization_id:             this.currentCampaignCustomer.organization_id,
                    vehicle_make:                vehicleOptions.vehicle_make,
                    vehicle_model:               vehicleOptions.vehicle_model,
                    vehicle_model_year:          vehicleOptions.vehicle_model_year,
                    vehicle_trim:                vehicleOptions.vehicle_trim,
                    vin:                         vehicleOptions.vin,
                }

                CreateCustomerVehicle(options).then((response) => {
                    if (response?.Data?.customer_vehicle?.customer_vehicle_id > 0) {

                        // // Add created customer vehicle to front of customerVehicles array
                        // this.customerVehicles.unshift(response.Data.customer_vehicle);
                        this.resetAndSearch(); // current logic matches/creates so getCustomerVehicles in case matched duplicate on backend instead of creating

                        // Check for optional param that indicates if primary customer vehicle / reason for communication customer vehicle has changed
                        if (response.Data.primary_customer_vehicle_id > 0) {
                            this.primaryCustomerVehicleId = response.Data.primary_customer_vehicle_id;
                        }

                        this.currentTab = 'vehicles-tab';
                        this.showSnackbar("green", "Successfully created customer vehicle");
                    } else {
                        if (response?.Error === "Invalid vin") {
                            this.showSnackbar("red", "Invalid vin");
                        } else {
                            this.showSnackbar("red", "Failed to create customer vehicle");
                        }
                    }
                })
            },
            destroyCustomerVehicle(customerVehicleId) {
                if (!(this.validCustomer && customerVehicleId > 0)) {
                    return;
                }

                // Set options
                const options = {
                    account_id:          this.currentCampaignCustomer.account_id,
                    customer_vehicle_id: customerVehicleId,
                }
                
                DestroyCustomerVehicle(options.customer_vehicle_id, options).then((response) => {
                    if (response?.Error === null) {
                        if (this.customerVehicles?.length > 0) {
                            const idxToRemove = this.customerVehicles.findIndex(obj => obj.customer_vehicle_id === customerVehicleId);
                            if (idxToRemove >= 0) {
                                this.customerVehicles.splice(idxToRemove, 1);
                            }
                        }

                        if (this.primaryCustomerVehicleId === customerVehicleId) {
                            this.primaryCustomerVehicleId = null;
                        }

                        this.showSnackbar("green", "Successfully deleted campaign customer vehicle");
                    } else {
                        this.showSnackbar("red", "Failed to delete campaign customer vehicle");
                    }
                })
            },
            getCustomerVehicles(skipLoader) {
                if (!this.validCustomer) {
                    return;
                }

                const options = {
                    account_id:                  this.currentCampaignCustomer.account_id,
                    customer_id:                 this.currentCampaignCustomer.customer_id,
                    last_customer_vehicle_id:    (this.lastCustomerVehicleId || 0),
                    limit:                       this.limit,
                    organization_id:             this.currentCampaignCustomer.organization_id,
                    primary_customer_vehicle_id: (this.primaryCustomerVehicleId || 0),
                }

                // Load if necessary
                if (!skipLoader) {
                    this.isLoading = true;
                }

                GetCustomerVehicles(options).then((response) => {
                    if (response?.Data?.customer_vehicles?.length > 0) {
                        this.agentsCanDeleteCustomerVehicles = response.Data.agents_can_delete_customer_vehicles;
                        this.agentsCanEditCustomerVehicles   = response.Data.agents_can_edit_customer_vehicles;

                        // Set on array
                        if (!!this.customerVehicles && this.customerVehicles.length > 0) {
                            this.customerVehicles = this.customerVehicles.concat(response.Data.customer_vehicles);
                        } else {
                            this.customerVehicles = response.Data.customer_vehicles;
                        }

                        // Set last
                        this.setLastCustomerVehicleId();
                    }
                    this.isLoading = false;

                });

            },
            resetAndSearch() {
                this.customerVehicles = [];
                this.lastCustomerVehicleId = null;
                this.getCustomerVehicles(false);
            },
            setLastCustomerVehicleId() {
                
                // Verify
                if (!this.validCustomerVehicles) {
                    return;
                }

                // Get last
                const last = this.customerVehicles[this.customerVehicles.length - 1];
                if (!last || !(last.customer_vehicle_id > 0)) {
                    return;
                }

                // Set
                this.lastCustomerVehicleId = last.customer_vehicle_id;
            },
            setTabs() {

                // Init
                this.tabItems = [
                    { name: "Vehicles", key: "vehicles", href: "#vehicles-tab" },
                ]

                // Only show search if user has write action permissions
                if (!this.hideCreateUpdateDestroyButtons) {
                    this.tabItems.push({ name: "Search",   key: "search",   href: "#search-tab" });
                }
            },
            showSnackbar(color, text) {
                if (color?.length > 0 && text?.length > 0) {
                    this.snackbar.color = color;
                    this.snackbar.text = text;
                    this.snackbarOpen = true;
                }
            },
            updateCampaignCustomerActiveCustomerVehicle(customerVehicleId) {
                if (!(this.validCustomer && customerVehicleId > 0 && this.validCampaignCustomer)) {
                    return;
                }

                // Set options
                const options = {
                    account_id:                  this.currentCampaignCustomer.account_id,
                    campaign_customer_active_id: this.currentCampaignCustomer.campaign_customer_active_id,
                    customer_vehicle_id:         customerVehicleId,
                }

                UpdateCampaignCustomerActiveCustomerVehicle(options.campaign_customer_active_id, options).then((response) => {
                    if (response?.Error === null) {

                        // updateCampaignCustomerActiveCustomerVehicle currently only called to set customer vehicle to reason for communication / primary customer vehicle (no unsetting)
                        this.primaryCustomerVehicleId = customerVehicleId;

                        this.showSnackbar("green", "Successfully updated campaign customer vehicle");
                    } else {
                        this.showSnackbar("red", "Failed to update campaign customer vehicle");
                    }
                })
            },
            updateVehicle(vehicleOptions) {
                if (!(this.validCustomer && vehicleOptions?.vehicle_id > 0 && vehicleOptions?.customer_vehicle_id > 0)) {
                    return;
                }

                // Set options
                const options = {
                    account_id:           this.currentCampaignCustomer.account_id,
                    customer_id:          this.currentCampaignCustomer.customer_id,
                    organization_id:      this.currentCampaignCustomer.organization_id,
                    customer_vehicle_id:  vehicleOptions.customer_vehicle_id,
                    no_longer_owns:       vehicleOptions.no_longer_owns,
                    vehicle_id:           vehicleOptions.vehicle_id,
                    make:                 vehicleOptions.vehicle_make,
                    model:                vehicleOptions.vehicle_model,
                    model_year:           vehicleOptions.vehicle_model_year,
                    trim:                 vehicleOptions.vehicle_trim,
                    vin:                  vehicleOptions.vin,
                }

                UpdateVehicle(options.vehicle_id, options).then((response) => {
                    if (response?.Data?.customer_vehicle?.customer_vehicle_key?.length > 0) { 
                        if (this.customerVehicles?.length > 0) {
                            const idx = this.customerVehicles.findIndex(obj => obj.customer_vehicle_id === options.customer_vehicle_id);
                            if (idx >= 0) {
                                this.customerVehicles[idx] = response.Data.customer_vehicle;
                            }
                        } else {
                            this.customerVehicles.push(response.Data.customer_vehicle);
                        }

                        this.showSnackbar("green", "Successfully updated vehicle");
                    } else {
                        if (response?.Error === "Invalid vin") {
                            this.showSnackbar("red", "Invalid vin");
                        } else {
                            this.showSnackbar("red", "Failed to update vehicle");
                        }
                    }
                })
            },
        },
        computed: {
            campaignCustomerID() {
                return (this.currentCampaignCustomer?.campaign_customer_id || 0);
            },
            showMore() {
                return (this.customerVehicles?.length > 0 && this.customerVehicles.length % this.limit === 0);
            },
            validCampaignCustomer() {
                return this.validCustomer && this.currentCampaignCustomer?.campaign_customer_active_id > 0 && this.currentCampaignCustomer?.campaign_customer_id > 0;
            },
            validCustomerVehicles() {
                return this.customerVehicles?.length > 0;
            },
            validCustomer() {
                return this.currentCampaignCustomer?.account_id > 0 && this.currentCampaignCustomer?.customer_id > 0 && this.currentCampaignCustomer?.organization_id > 0;
            },
        },
        components: {
            CustomerVehicleDetails,
            CustomerVehicleFormDialog,
            CustomerVehicleSearch,
        },
        props: {
            currentCampaignCustomer: Object,
            hideCreateUpdateDestroyButtons: Boolean,
            inCommunicationCenter: Boolean,
            changeTab: Function,
        },
        created() {
            this.primaryCustomerVehicleId = this.currentCampaignCustomer.customer_vehicle_id;
            this.setTabs();
            this.getCustomerVehicles(false);
        },
    });
</script>

<style lang="scss" scoped>
</style>
