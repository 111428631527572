<template lang="pug">
    v-btn(
        elevation="3"
        fab
        absolute
        color="#7A7A7A"
        style="top: -10px; right: -10px; height: 20px; width: 20px;"
    )
        v-icon(color="grey lighten-2" dense small) mdi-close-thick

</template>

<script lang="ts">
    import Vue from 'vue';

    export default Vue.extend({
        methods: {},
        props: {},
    });
</script>

<style lang="scss" scoped>
</style>
