import { get, destroy, post, postRaw } from '.';

export function GetUsFederalDncFiles(options = {}) {
	return get<any>("/app/api/us_federal_dnc_files", options);
}

export function DestroyUsFederalDncFile(options = {}) {
	return destroy<any>("/app/api/us_federal_dnc_file", options);
}

export function CreateUsFederalDncFile(options = {}) {
	return post<any>("/app/api/us_federal_dnc_file", options);
}

export function UploadUsFederalDncFile(options = {}) {
	return postRaw<any>("/app/api/us_federal_dnc_file/upload", options);
}
