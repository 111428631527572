import { get, post, put, destroy } from '.';

export function GetAlertTypeCampaignSurveyAlerts(options = {}) {
	return get<any>("/app/api/alert_type_campaign_survey_alerts", options);
}
export function CreateAlertTypeCampaignSurveyAlert(options = {}) {
	return post<any>("/app/api/alert_type_campaign_survey_alerts", options);
}
export function CreateAllAlertTypeCampaignSurveyAlerts(options = {}) {
	return post<any>(`/app/api/all_alert_type_campaign_survey_alerts`, options);
}
export function UpdateAlertTypeCampaignSurveyAlert(id: string, options = {}) {
    return put<any>(`/app/api/alert_type_campaign_survey_alerts/${id}`, options);
}
export function UpdateAllAlertTypeCampaignSurveyAlerts(options = {}) {
    return put<any>(`/app/api/all_alert_type_campaign_survey_alerts`, options);
}
export function DestroyAlertTypeCampaignSurveyAlert(id: string, options = {}) {
    return destroy<any>(`/app/api/alert_type_campaign_survey_alerts/${id}`, options);
}
export function DestroyAllAlertTypeCampaignSurveyAlerts(options = {}) {
    return destroy<any>(`/app/api/all_alert_type_campaign_survey_alerts`, options);
}
