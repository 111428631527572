import { get, put, post, destroy } from '.';

export function GetAccountAccesses(accessGroupVid, options = {}) {
	return get<any>("/app/api/access_groups/" + accessGroupVid + "/account_accesses", options);
}

export function FindAccountAccess(accessGroupVid, vid) {
	return get<any>("/app/api/access_groups/" + accessGroupVid + "/account_accesses/" + vid);
}

export function CreateAccountAccess(accessGroupVid, options = {}) {
	return post<any>("/app/api/access_groups/" + accessGroupVid + "/account_accesses", options);
}

export function UpdateAccountAccess(accessGroupVid, id, options = {}) {
	return put<any>("/app/api/access_groups/" + accessGroupVid + "/account_accesses/" + (id || 0).toString(), options);
}

export function DestroyAccountAccess(accessGroupVid, id, options = {}) {
	return destroy<any>("/app/api/access_groups/" + accessGroupVid + "/account_accesses/" + (id || 0).toString(), options);
}

export function GetAvailableAccountAccesses(accessGroupVid, options = {}) {
	return get<any>("/app/api/access_groups/" + accessGroupVid + "/account_accesses/available", options);
}
