<template lang="pug">
    v-dialog(v-if="validLastSourceId && validService && currentUserIsSuperAdmin" v-model='dialog' scrollable persistent max-width='960px')
            template(v-slot:activator='{ on }')
                v-btn(
                    color="primary"
                    v-on='on'
                    icon
                )
                    v-icon(outlined) mdi-eye-outline 
                
            v-card(height="700px")
                v-toolbar(flat color="white" dense)
                    v-row.no-gutters.align-center
                        v-col(cols="auto")
                            v-icon.black--text.mr-2 mdi-delta
                        v-col(cols="auto")
                            v-toolbar-title.black--text RO details
                        v-spacer
                        v-col(cols="auto")
                            v-btn(icon @click='closeDialog();')
                                v-icon mdi-close
                v-card-text 
                    v-card(
                        rounded
                        width="100%"
                        color="#212121"
                        dark
                        elevation="6"
                    )
                        vue-json-pretty( 
                            :data="formattedData"
                            showLineNumber
                        )

                v-card-actions.text-right
                    v-spacer
                        v-btn.ma-2(color="#ffffff" outlined ripple @click='closeDialog')
                            v-icon mdi-window-close
                            | Close
                     

</template>

<script lang="ts">
import Vue from 'vue';
import VolieState from '@/store';

import VueJsonPretty from 'vue-json-pretty';
import 'vue-json-pretty/lib/styles.css';

import {GetFormattedRoOrderDetailsFromXML} from '@/data'

// Components

export default Vue.extend({
    data() {
        return {
            dialog: false, 
            formattedData: {},

        }
    },
    methods: {
        closeDialog(){
            this.dialog = false;
        }, 
        
        getFormattedRepairOrderData() {
            GetFormattedRoOrderDetailsFromXML(this.service.last_source_id).then((response) => {
                console.log(response)
                if (response?.Data?.processing_record) {
                    this.formattedData = response.Data.processing_record
                }
            })
        },
        init(){
            this.getFormattedRepairOrderData();
        },

    },
    computed: {
        currentUser() {
            return VolieState.currentUser();
        },
        currentUserIsSuperAdmin() {
            return this.currentUser?.activated_role_id === 1;
        },
        validService() {
            return this.service?.service_id > 0; 
        },
        validLastSourceId(){
            return this.service?.last_source_id > 0;
        }

    },
    components: {
        VueJsonPretty,

    },
    props: {
        service: Object, 
    },
    watch: {

    },
    created() {
        this.init()
    },
    destroyed() {
    },
    mounted() {
    },
})
</script>

<style lang="scss" scoped>

</style>