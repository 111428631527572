
import volieStore from '../store';

// General
export * from "./auth";
export * from "../model";

// Resources
export * from "./access_group";
export * from "./account";
export * from "./account_access";
export * from "./account_plugin";
export * from "./account_user";
export * from "./adf_email";
export * from "./adf_email_address";
export * from "./agent";
export * from "./agent_status";
export * from "./agent_status_campaign_type";
export * from "./alert_type";
export * from "./alert_type_campaign_survey_alert";
export * from "./alert_type_email_template";
export * from "./alert_type_survey";
export * from "./alert_type_campaign_disposition";
export * from "./appointment";
export * from "./appointment_scheduling_hour";
export * from "./appointment_type";
export * from "./atop_brand";
export * from "./atop_campaign";
export * from "./atop_campaign_phone_number"
export * from "./asset";
export * from "./auto_assistant_phone_number";
export * from "./auto_assistant_request";
export * from "./autobuy";
export * from "./automate_deal";
export * from "./automate_file";
export * from "./automate_repair_order_header";
export * from "./automate_repair_order_line";
export * from "./automate_sales_person";
export * from "./bad_address";
export * from "./bad_phone_number";
export * from "./bad_email_address";
export * from "./base_dashboard_summary";
export * from "./base_organization_summary";
export * from "./base_user_summary";
export * from "./business_hour";
export * from "./call";
export * from "./call_call_queue";
export * from "./call_communication_queue";
export * from "./call_event";
export * from "./call_transcription";
export * from "./call_transcription_rules";
export * from "./call_transcription_rule_records";
export * from "./call_transfer";
export * from "./campaign";
export * from "./campaign_access";
export * from "./campaign_attempt";
export * from "./campaign_attempt_calling_time";
export * from "./campaign_attempt_follow_up";
export * from "./campaign_campaign_sync";
export * from "./campaign_communication_transaction";
export * from "./campaign_customer";
export * from "./campaign_customer_active";
export * from "./campaign_customer_assignment";
export * from "./campaign_customer_deployment";
export * from "./campaign_deployment";
export * from "./campaign_deployment_activity";
export * from "./campaign_disposition";
export * from "./campaign_email_template";
export * from "./campaign_incoming_phone_number";
export * from "./campaign_inheritance";
export * from "./campaign_inheritance_children";
export * from "./campaign_instant_voicemail_mp3";
export * from "./campaign_mapping";
export * from "./campaign_message_template";
export * from "./campaign_mp3";
export * from "./campaign_proof";
export * from "./campaign_survey_alert";
export * from "./campaign_survey_template";
export * from "./campaign_sync";
export * from "./campaign_type";
export * from "./campaign_campaign_type";
export * from "./campaign_type_disposition_group";
export * from "./campaign_type_message_template";
export * from "./campaign_special_instruction";
export * from "./campaign_type_survey_template";
export * from "./campaign_type_team";
export * from "./cdk_customer_import";
export * from "./cdk_plugin";
export * from "./cdk_plugin_summary";
export * from "./cdk_plugin_setting";
export * from "./cdk_post_log";
export * from "./cdk_sale_import";
export * from "./cdk_service_header_import";
export * from "./cdk_service_line_import";
export * from "./city";
export * from "./communication_center";
export * from "./communication_disposition";
export * from "./communication_queue"
export * from "./communication_queue_connect_key";
export * from "./communication_queue_group";
export * from "./communication_queue_group_user";
export * from "./communication_queue_user";
export * from "./communication_queue_team";
export * from "./communication_response";
export * from "./conference";
export * from "./conference_event";
export * from "./connect_key";
export * from "./connect_key_organization";
export * from "./connect_session";
export * from "./contact";
export * from "./conversation_group";
export * from "./conversica_campaign_mapping";
export * from "./country";
export * from "./criterion";
export * from "./criterion_type";
export * from "./crm_disposition_mappings";
export * from "./custom_field";
export * from "./customer";
export * from "./customer_address";
export * from "./customer_assignment";
export * from "./customer_email_address";
export * from "./customer_phone_number";
export * from "./customer_vehicle";
export * from "./dashboard_affinity";
export * from "./dashboard_appointment";
export * from "./dashboard_lead_subaru";
export * from "./dashboard_live_agents";
export * from "./data_export_template";
export * from "./data_import";
export * from "./data_import_template";
export * from "./deal_type";
export * from "./device_notification_event";
export * from "./disposition";
export * from "./disposition_group";
export * from "./disposition_disposition_group";
export * from "./dms_connect_repair_order"
export * from "./elead_activities";
export * from "./elead_activity_outcomes";
export * from "./elead_activity_types";
export * from "./elead_customer";
export * from "./elead_email";
export * from "./elead_employee";
export * from "./elead_employee_user_mapping";
export * from "./elead_opportunity";
export * from "./elead_position";
export * from "./elead_run";
export * from "./elead_sales_step";
export * from "./elead_source";
export * from "./elead_status";
export * from "./elead_subscription";
export * from "./elead_vehicle";
export * from "./elead_workflow";
export * from "./email";
export * from "./email_template";
export * from "./enroller_run";
export * from "./enroller_run_criterion";
export * from "./enroller_run_customer";
export * from "./enroller_run_event";
export * from "./external_link";
export * from "./event";
export * from "./follow_up";
export * from "./forte_crm";
export * from "./gm_ro_history";
export * from "./goal";
export * from "./goal_template";
export * from "./incoming_phone_number";
export * from "./incoming_phone_number_order";
export * from "./incoming_phone_number_order_phone_number";
export * from "./inline_crm";
export * from "./internal_ticket_group";
export * from "./internal_ticket_task";
export * from "./ivr_tree";
export * from "./ivr_tree_option";
export * from "./job_title";
export * from "./labor_type";
export * from "./lead_source";
export * from "./lead_status";
export * from "./merge_tag";
export * from "./message";
export * from "./mortgage_prospect";
export * from "./mp3";
export * from "./operation_code";
export * from "./opt_in_out";
export * from "./organization";
export * from "./organization_access";
export * from "./organization_department";
export * from "./organization_employee";
export * from "./organization_user";
export * from "./organization_organization_user";
export * from "./organization_mapping";
export * from "./organization_plugin";
export * from "./organization_postal_code";
export * from "./outoing_caller_id";
export * from "./plugin";
export * from "./postal_code";
export * from "./priority_group";
export * from "./promotion";
export * from "./prospect_enrollment_log";
export * from "./prospect_lead";
export * from "./prospect";
export * from "./rasani";
export * from "./recording";
export * from "./recurring_time";
export * from "./region";
export * from "./report";
export * from "./reynolds_rci_log";
export * from "./sale";
export * from "./scheduled_communication";
export * from "./scorecard";
export * from "./scorecard_criterion";
export * from "./scorecard_item";
export * from "./scorecard_template";
export * from "./scorecard_template_item";
export * from "./service";
export * from "./shared_link";
export * from "./skip";
export * from "./subaru";
export * from "./subscription";
export * from "./subscription_organization";
export * from "./summary_record";
export * from "./survey_answer";
export * from "./survey_attempt";
export * from "./tag";
export * from "./team";
export * from "./tekion_appointment";
export * from "./tekion_customer";
export * from "./tekion_deal";
export * from "./tekion_dealer";
export * from "./tekion_employee";
export * from "./tekion_opcode";
export * from "./tekion_service_menu";
export * from "./tekion_service_shop";
export * from "./tekion_transportation";
export * from "./tekion_transportation_type";
export * from "./tekion_vehicle";
export * from "./tekion_vehicle_inventory";
export * from "./telnyx_billing_group";
export * from "./telnyx_enum";
export * from "./telematic_alert";
export * from "./ticket";
export * from "./ticket_follower";
export * from "./toyoguard_program";
export * from "./us_federal_dnc_file";
export * from "./user";
export * from "./user_client_drop";
export * from "./vehicle";
export * from "./vehicle_inventory";
export * from "./vehicle_make";
export * from "./vehicle_make_definition";
export * from "./vehicle_model";
export * from "./vehicle_model_definition";
export * from "./vehicle_model_year";
export * from "./vehicle_model_year_definition";
export * from "./vehicle_transaction_statuses";
export * from "./vehicle_trim_definition";
export * from "./version";
export * from "./vin_solutions_contacts";
export * from "./vin_solutions_dealers";
export * from "./vin_solutions_inventories";
export * from "./vin_solutions_lead_group_categories";
export * from "./vin_solutions_lead_sources";
export * from "./vin_solutions_lead_status_types";
export * from "./vin_solutions_lead_statuses";
export * from "./vin_solutions_lead_types";
export * from "./vin_solutions_leads";
export * from "./vin_solutions_logs";
export * from "./vin_solutions_users";
export * from "./vin_solutions_vehicle_makes";
export * from "./vin_solutions_vehicle_model_years";
export * from "./vin_solutions_vehicle_models";
export * from "./vin_solutions_vehicle_trims";
export * from "./voice_mail_recording";
export * from "./web_rtc_participant";
export * from "./web_rtc_session";
export * from "./webhook";
export * from "./webhook_event";
export * from "./white_label";
export * from "./xtime_link";

export const NotAuthorized = {
    Data: null,
    Error: "Not Authorized"
};

export interface Response<T> {
    Data: T;
    Error: string;
}

export function cacheGet<T>(k: string): T {
    k = `volie:${k}`;
    return JSON.parse(window.localStorage.getItem(k));
}

export function cacheSet(k: string, v: any) {
    k = `volie:${k}`;
    return window.localStorage.setItem(k, JSON.stringify(v));
}

export function parseJwtToken(token: string) {
    try {
        return JSON.parse(atob(token.split('.')[1]));
    } catch (e) {
        return null;
    }
}

export function validToken(token: string): boolean {
    if (!token) {
        return false;
    }
    const parsed = parseJwtToken(token);
    if (parsed) {
        try {
            return parsed.exp < Date.now();
        } catch {
            return false;
        }
    }
}

function xhr<T>(method: string, url: string, payload?: any, headers = {}): Promise<Response<T>> {
    method = method.toUpperCase();

    const opts: any = {
        method,
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            ...headers,
        },
        credentials: 'include',
    };

    // Check if we need to do shared link auth or standard auth
    if (window.location.pathname.startsWith("/s/")) {
        opts.headers["Shared-Link"] = `slKey ${window?.location?.pathname?.split("/s/")[1]}`;

        // If we don't currently have a shared link in storage, then we should create a visit after authenticating
        if (!(volieStore?.store?.state?.SharedLink?.id > 0)) {
            opts.headers["Track-Shared-Link-Visit"] = "true";
        }
    } else {
        const token = cacheGet<string>('token');
        if (token) {
            opts.headers.Authorization = `Bearer ${token}`;
        }
    }

    // check this so the last values don't get wiped out on login
    if (sessionStorage.getItem("log_xhr") === "true") {
        sessionStorage.setItem("last_request", url);
    }

    if (payload) {
        if ('GET' === method) {
            url += `?${Object.keys(payload)
                    .filter((k) => !(payload[k] === null || payload === undefined))
                    .map((k) => encodeURIComponent(k) + '=' + encodeURIComponent(payload[k]))
                    .join('&')
                }`;
        } else {
            opts.body = JSON.stringify(payload);
        }
    }

    return new Promise<Response<T>>((resolve, reject) => {
        fetch(url, opts)
            .then((res: any) => {
                if (res.ok) {
                    res.json()
                        .then((json: any) => resolve(json as Response<T>))
                        .catch((e) => {
                            console.error(e);
                            reject(new Error("server error"))
                        })
                } else if (res.status == 401) {
                    window.location.replace("/auth/ab/login?redir=" + window.location.href);
                    // reject(NotAuthorized);
                    return;
                } else if (res.status == 504) {
                    resolve({
                        Data: null,
                        Error: "Request timed out."
                    });
                    return;
                } else {
                    res.json()
                        .then((j) => {
                            if (j && (j.Data || j.Error)) {
                                resolve(j);
                            } else {
                                console.log(j);
                                reject(new Error("server error"));
                            }
                        })
                        .catch((e) => {
                            console.error(e);
                            reject(new Error("server error"));
                        });
                }
            })
            .catch((err: any) => {
                console.error(err);
                if (err.Error) {
                    reject(new Error(err.Error));
                } else if (err) {
                    reject(new Error(err));
                } else {
                    reject(new Error("server error"));
                }
            });
    });
}

function xhrForMultipart<T>(method: string, url: string, payload?: any, headers = {}): Promise<Response<T>> {
    method = method.toUpperCase();

    const opts: any = {
        method,
        credentials: 'include',
        body: payload,
        headers: {
            ...headers,
        },
    };

    const token = cacheGet<string>('token');
    if (token) {
        opts.headers.Authorization = `Bearer ${token}`;
    }

    return new Promise<Response<T>>((resolve, reject) => {
        fetch(url, opts)
            .then((res: any) => {
                if (res.ok) {
                    res.json().then((json: any) => resolve(json as Response<T>));
                } else {
                    res.json()
                        .then((j) => { reject(j); })
                        .catch((e) => {
                            console.error(e);
                            reject();
                        });
                }
            })
            .catch((err: any) => {
                reject(new Error(err.Error));
            });
    });
}

export function put<T>(url: string, payload?: any, headers = {}): Promise<Response<T>> {
    return xhr<T>('PUT', url, payload, headers);
}

export function post<T>(url: string, payload?: any, headers = {}): Promise<Response<T>> {
    return xhr<T>('POST', url, payload, headers);
}

export function get<T>(url: string, payload?: any, headers = {}): Promise<Response<T>> {
    return xhr<T>('GET', url, payload, headers);
}

export function destroy<T>(url: string, payload?: any, headers = {}): Promise<Response<T>> {
    return xhr<T>('DELETE', url, payload, headers);
}

export function postRaw<T>(url: string, payload?: any, headers = {}): Promise<Response<T>> {
    return xhrForMultipart<T>('POST', url, payload, headers);
}
