// Helpers
import { formatName } from '@/helpers/name'; 

// Models
import { AssignedCustomer, CustomerCommunicationManager, } from '@/model/index';

export function UpdateAssignedCustomerFromCustomerCommunicationManager(customerCommunicationManager: CustomerCommunicationManager, currentAssignedCustomer: AssignedCustomer): AssignedCustomer {
    const customer: AssignedCustomer = {
        account_id:      currentAssignedCustomer.account_id,
        organization_id: currentAssignedCustomer.organization_id,
        customer_id:     currentAssignedCustomer.customer_id,
        elead_enabled:   currentAssignedCustomer.elead_enabled,

        cell_phone_id:  customerCommunicationManager.cell_phone_id,
        first_name_id:  customerCommunicationManager.first_name_id,
        home_phone_id:  customerCommunicationManager.home_phone_id,
        last_name_id:   customerCommunicationManager.last_name_id,
        middle_name_id: customerCommunicationManager.middle_name_id,
        work_phone_id:  customerCommunicationManager.work_phone_id,
        first_name:     customerCommunicationManager.first_name,
        full_name:      formatName([customerCommunicationManager.first_name, customerCommunicationManager.middle_name, customerCommunicationManager.last_name]),
        last_name:      customerCommunicationManager.last_name,
        middle_name:    customerCommunicationManager.middle_name,
        
        time_zone_id:   currentAssignedCustomer.time_zone_id,

        primary_address_id:           currentAssignedCustomer.primary_address_id,
        primary_address_city:         currentAssignedCustomer.primary_address_city,
        primary_address_postal_code:  currentAssignedCustomer.primary_address_postal_code,
        primary_address_region:       currentAssignedCustomer.primary_address_region,
        primary_address_street_one:   currentAssignedCustomer.primary_address_street_one,
        primary_address_street_two:   currentAssignedCustomer.primary_address_street_two,
        primary_address_street_three: currentAssignedCustomer.primary_address_street_three,

        primary_email_address_id: currentAssignedCustomer.primary_email_address_id,
        primary_email_address:    currentAssignedCustomer.primary_email_address,
    }
    
    // check if primary address exists
    if (customerCommunicationManager.primary_address_id > 0) {
                        
        // If primary address changed, then find primary address in customer addresses array and set primary address params
        if (customerCommunicationManager.primary_address_id !== customer.primary_address_id && customerCommunicationManager.customer_addresses?.length > 0) {
        
            for (let i = 0; i < customerCommunicationManager.customer_addresses.length; i++) {

                if (customerCommunicationManager.primary_address_id === customerCommunicationManager.customer_addresses[i]?.address_id) {
                    const customerAddress = customerCommunicationManager.customer_addresses[i];
                    
                    // set primary address params
                    customer.primary_address_id           = customerAddress.address_id;
                    customer.primary_address_city         = customerAddress.address_city;
                    customer.primary_address_postal_code  = customerAddress.address_postal_code;
                    customer.primary_address_region       = customerAddress.address_region;
                    customer.primary_address_street_one   = customerAddress.address_street_one;
                    customer.primary_address_street_two   = customerAddress.address_street_two;
                    customer.primary_address_street_three = customerAddress.address_street_three;
                    break;
                }
            }
        }
    } else {
        customer.primary_address_id           = 0;
        customer.primary_address_city         = null;
        customer.primary_address_postal_code  = null;
        customer.primary_address_region       = null;
        customer.primary_address_street_one   = null;
        customer.primary_address_street_two   = null;
        customer.primary_address_street_three = null;
    }

    // check if primary email address exists
    if (customerCommunicationManager.primary_email_address_id > 0) {

        // If primary address changed, then find primary address in customer addresses array and set primary address params
        if (customerCommunicationManager.primary_email_address_id !== customer.primary_email_address_id && customerCommunicationManager.customer_email_addresses?.length > 0) {
        
            for (let i = 0; i < customerCommunicationManager.customer_email_addresses.length; i++) {

                if (customerCommunicationManager.primary_email_address_id === customerCommunicationManager.customer_email_addresses[i]?.email_address_id) {
                    const customerEmailAddress = customerCommunicationManager.customer_email_addresses[i];
                    
                    // set primary email address params
                    customer.primary_email_address_id = customerEmailAddress.email_address_id;
                    customer.primary_email_address    = customerEmailAddress.email_address_email;
                    break;
                }
            }
        }
    } else {
        customer.primary_email_address_id = 0;
        customer.primary_email_address    = null;
    }

    return customer;
}
