import { get, post, put, destroy } from '.';

export function GetSubscriptionOrganizations(subscriptionID, options = {}) {
	return get<any>("/app/api/subscriptions/" + (subscriptionID || 0).toString() + "/subscription_organizations", options);
}

export function CreateSubscriptionOrganization(options = {}) {
	return post<any>("/app/api/subscription_organizations", options);
}

export function DestroySubscriptionOrganization(subscriptionOrganizationID) {
	return destroy<any>("/app/api/subscription_organizations/" + (subscriptionOrganizationID || 0).toString());
}

export function UpdateSubscriptionOrganization(subscriptionOrganizationID, options = {}) {
	return put<any>("/app/api/subscription_organizations/" + (subscriptionOrganizationID || 0).toString(), options);
}