import { get, post, put, destroy } from '.';

export function GetConnectKeys(options = {}) {
	return get<any>("/app/api/connect_keys", options);
}

export function FindConnectKey(key) {
	return get<any>("/app/api/connect_keys/" + key);
}

export function CreateConnectKey(options = {}) {
	return post<any>("/app/api/connect_keys", options);
}

export function UpdateConnectKey(id, options = {}) {
	return put<any>("/app/api/connect_keys/" + (id || 0).toString(), options);
}

export function DestroyConnectKey(id) {
	return destroy<any>("/app/api/connect_keys/" + (id || 0).toString());
}

export function RotateConnectKeyKeys(id) {
	return put<any>("/app/api/connect_keys/" + (id || 0).toString() + "/rotate_keys");
}
