<template lang="pug">
    v-container
        v-alert(prominent type='warning' outlined shaped)
            v-row(align='center')
                v-col.grow
                    | Customer has no email history
                v-col.shrink 
                    v-btn.white--text(
                        color="primary"
                        dark
                        small
                        @click="startComposeNewEmail();"
                    )
                        v-icon mdi-square-edit-outline
                        | Compose New Email

</template>

<script lang="ts">
    import Vue from 'vue';

    // Data
    import {
    } from "@/data";

    export default Vue.extend({
        data() {
            return {
            }
        },
        methods: {
            compose() {
            },
        },
        computed: {
        },
        components: {
        },
        props: {
            startComposeNewEmail: Function,
        },
        destroyed() {
        },
        created() {
        },
        mounted() {
        },
        watch: {
        },
    });
</script>

<style lang="scss" scoped>
</style>
