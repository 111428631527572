import { get, destroy, post, put } from '.';

export function GetCampaignAttemptFollowUps(options = {}) {
    return get<any>("/app/api/campaign_attempt_follow_ups", options);
}
export function GetAvailableFollowUpsForCampaignAttempt(options = {}) {
    return get<any>("/app/api/available_campaign_attempt_follow_ups", options);
}
export function CreateCampaignAttemptFollowUp(options = {}) {
    return post<any>('/app/api/campaign_attempt_follow_ups', options)
}
export function CreateCampaignFollowUpApplyAll(options = {}) {
    return post<any>('/app/api/campaign_attempt_follow_up_apply_all', options)
}
export function UpdateCampaignAttemptFollowUp(options = {}) {
    return put<any>('/app/api/campaign_attempt_follow_ups', options)
}
export function UpdateCampaignAttemptFollowUpPriority(options = {}) {
    return put<any>('/app/api/campaign_attempt_follow_up_priority', options)
}
export function DestroyCampaignAttemptFollowUp(id: string) {
    return destroy<any>(`/app/api/campaign_attempt_follow_ups/${id}`);
}