import { get, put, post, destroy } from '.';

export function GetCampaignAccesses(accessGroupVid, accountAccessVid, organizationAccessVid, options = {}) {
	return get<any>("/app/api/access_groups/" + accessGroupVid + "/account_accesses/" + accountAccessVid + "/organization_accesses/" + organizationAccessVid + "/campaign_accesses", options);
}

export function FindCampaignAccess(accessGroupVid, accountAccessVid, organizationAccessVid, vid) {
	return get<any>("/app/api/access_groups/" + accessGroupVid + "/account_accesses/" + accountAccessVid + "/organization_accesses/" + organizationAccessVid + "/campaign_accesses/" + vid);
}

export function CreateCampaignAccess(accessGroupVid, accountAccessVid, organizationAccessVid, options = {}) {
	return post<any>("/app/api/access_groups/" + accessGroupVid + "/account_accesses/" + accountAccessVid + "/organization_accesses/" + organizationAccessVid + "/campaign_accesses", options);
}

export function UpdateCampaignAccess(accessGroupVid, accountAccessVid, organizationAccessVid, id, options = {}) {
	return put<any>("/app/api/access_groups/" + accessGroupVid + "/account_accesses/" + accountAccessVid + "/organization_accesses/" + organizationAccessVid + "/campaign_accesses/" + (id || 0).toString(), options);
}

export function DestroyCampaignAccess(accessGroupVid, accountAccessVid, organizationAccessVid, id, options = {}) {
	return destroy<any>("/app/api/access_groups/" + accessGroupVid + "/account_accesses/" + accountAccessVid + "/organization_accesses/" + organizationAccessVid + "/campaign_accesses/" + (id || 0).toString(), options);
}

export function GetAvailableCampaignAccesses(accessGroupVid, accountAccessVid, organizationAccessVid, options = {}) {
	return get<any>("/app/api/access_groups/" + accessGroupVid + "/account_accesses/" + accountAccessVid + "/organization_accesses/" + organizationAccessVid + "/campaign_accesses/available", options);
}
