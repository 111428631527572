<template lang="pug">
    v-dialog(v-model='dialog' scrollable persistent max-width='600px')
        template(v-slot:activator='{ on }')
            v-btn.ma-2.white--text(v-if="validTicket && ticket.current_status === 'done'" ripple color="#0f5b9a" v-on='on')
                v-icon mdi-content-save
                | Reopen
        v-card
            v-card-title
                span.text-h5 Reopen Ticket
            v-card-text
                v-container
                    span.text-h6 Please confirm that you would like to reopen this ticket.

                v-container
                    v-row 
                        v-col(cols='12')
                            | Add Comment
                            tiptap-vuetify(
                                v-model='comments'
                                :extensions='extensions'
                                :toolbar-attributes="{ color: '#0f5b9a', dark: true }"
                            )

            v-card-actions.text-right
                v-spacer
                    v-btn.ma-2(color="#0f5b9a" outlined ripple @click='dialog = false;')
                        v-icon mdi-window-close
                        | Close
                    v-btn.ma-2(color="green" outlined :loading="isSaving" ripple @click='reopenTicket();')
                        v-icon mdi-content-save
                        | Reopen

</template>

<script lang="ts">
    import Vue from 'vue';

    // Components
    import {
        TiptapVuetify,
        Heading,
        Bold,
        Italic,
        Strike,
        Underline,
        // Code,
        Paragraph,
        BulletList,
        OrderedList,
        ListItem,
        Link,
        Blockquote,
        HardBreak,
        // HorizontalRule,
        // History,
    } from 'tiptap-vuetify';

    // Data
    import { ReopenTicket, } from "@/data";

    export default Vue.extend({
        data() {
            return {
                comments: null,
                dialog: false,
                extensions: [
                    Blockquote,
                    Link,
                    Underline,
                    Strike,
                    Italic,
                    ListItem,
                    BulletList,
                    OrderedList,
                    [Heading, {
                        options: {
                            levels: [1, 2, 3]
                        }
                    }],
                    Bold,
                    Paragraph,
                    HardBreak
                ],
                isSaving: false,
            }
        },
        methods: {
            reopenTicket() {

                const options = {
                    comments: this.comments || '',
                }

                this.isSaving = true;
                ReopenTicket(this.ticket.ticket_key, options).then((response) => {
                    if (response?.Error === null) {
                        this.showSnackbar("success", "Successfully reopend ticket");

                        this.reloadTicket();
                    } else {
                        let err = response?.Error;
                        if (!(err?.length > 0)) {
                            err = "Unable to reopen ticket"
                        }
                        this.showSnackbar("error", err);
                    }

                    this.dialog = false;
                    this.isSaving = false;
                });
            },
        },
        components: {
            TiptapVuetify,
        },
        computed: {
            validTicket() {
                return this.ticket?.ticket_key?.length > 0;
            },
        },
        props: {
            reloadTicket: Function,
            showSnackbar: Function,
            ticket: Object,
        },
    });
</script>

<style lang="scss" scoped>
</style>
