import { destroy, get, post, put } from '.';

export function GenerateCapabilityToken(id, options = {}) {
	return post<any>("/app/api/incoming_phone_numbers/" + (id || 0).toString() + "/generate_capability_token", options);
}

export function GetIncomingPhoneNumbers(options = {}) {
	return get<any>("/app/api/incoming_phone_numbers", options);
}

export function FindIncomingPhoneNumber(key) {
	return get<any>("/app/api/incoming_phone_numbers/" + key);
}

export function ReleaseIncomingPhoneNumber(id) {
	return destroy<any>("/app/api/incoming_phone_numbers/" + (id || 0).toString());
}

export function SearchAvailablePhoneNumbers(options = {}) {
	return get<any>("/app/api/incoming_phone_numbers/available", options);
}

export function UpdateIncomingPhoneNumber(id, options = {}) {
	return put<any>("/app/api/incoming_phone_numbers/" + (id || 0).toString(), options);
}