<template lang="pug">
    v-card.mx-auto(max-width='375px' v-if="validDialPad")
        v-toolbar(dark color='white')
            v-btn(icon light @click='exit();' v-if="validCloseDialPad")
                v-icon mdi-close
            v-toolbar-title.text--primary(v-if="validDigits") {{ digits | tel }}
            v-toolbar-title.text--primary(v-else) ---
        v-card-text
            v-simple-table
                template(v-slot:default)
                    tbody
                        tr
                            td.text-center(style="width: 33%; max-width: 33%;")
                                v-btn.mx-2.my-md-4(fab dark small color='primary' outlined @click="handleSendDigit('1');")
                                    v-icon(dark large)
                                        | mdi-numeric-1

                            td.text-center(style="width: 33%; max-width: 33%;")
                                v-btn.mx-2.my-md-4(fab dark small color='primary' outlined @click="handleSendDigit('2');")
                                    v-icon(dark large)
                                        | mdi-numeric-2

                            td.text-center(style="width: 33%; max-width: 33%;")
                                v-btn.mx-2.my-md-4(fab dark small color='primary' outlined @click="handleSendDigit('3');")
                                    v-icon(dark large)
                                        | mdi-numeric-3

                        tr
                            td.text-center(style="width: 33%; max-width: 33%;")
                                v-btn.mx-2.my-md-4(fab dark small color='primary' outlined @click="handleSendDigit('4');")
                                    v-icon(dark large)
                                        | mdi-numeric-4

                            td.text-center(style="width: 33%; max-width: 33%;")
                                v-btn.mx-2.my-md-4(fab dark small color='primary' outlined @click="handleSendDigit('5');")
                                    v-icon(dark large)
                                        | mdi-numeric-5

                            td.text-center(style="width: 33%; max-width: 33%;")
                                v-btn.mx-2.my-md-4(fab dark small color='primary' outlined @click="handleSendDigit('6');")
                                    v-icon(dark large)
                                        | mdi-numeric-6
                        tr
                            td.text-center(style="width: 33%; max-width: 33%;")
                                v-btn.mx-2.my-md-4(fab dark small color='primary' outlined @click="handleSendDigit('7');")
                                    v-icon(dark large)
                                        | mdi-numeric-7

                            td.text-center(style="width: 33%; max-width: 33%;")
                                v-btn.mx-2.my-md-4(fab dark small color='primary' outlined @click="handleSendDigit('8');")
                                    v-icon(dark large)
                                        | mdi-numeric-8

                            td.text-center(style="width: 33%; max-width: 33%;")
                                v-btn.mx-2.my-md-4(fab dark small color='primary' outlined @click="handleSendDigit('9');")
                                    v-icon(dark large)
                                        | mdi-numeric-9
                        tr
                            td.text-center(style="width: 33%; max-width: 33%;")
                                v-btn.mx-2.my-md-4(fab dark small color='primary' outlined @click="handleSendDigit('*');")
                                    v-icon(dark)
                                        | mdi-asterisk

                            td.text-center(style="width: 33%; max-width: 33%;")
                                v-btn.mx-2.my-md-4(fab dark small color='primary' outlined @click="handleSendDigit('0');")
                                    v-icon(dark large)
                                        | mdi-numeric-0

                            td.text-center(style="width: 33%; max-width: 33%;")
                                v-btn.mx-2.my-md-4(fab dark small color='primary' outlined @click="handleSendDigit('#');")
                                    v-icon(dark)
                                        | mdi-pound

</template>

<script lang="ts">
    import Vue from 'vue';

    export default Vue.extend({
        data() {
            return {
                digits: "",
            }
        },
        methods: {
            exit() {
                if (this.validCloseDialPad) {
                    this.closeDialPad();
                }
            },
            handleSendDigit(digit) {
                if (!(digit?.length > 0)) {
                    return;
                }

                this.digits += digit;
                this.sendDigit(digit, this.isTransfer);
            },
        },
        computed: {
            validCloseDialPad() {
                return this.closeDialPad !== null && this.closeDialPad !== undefined;
            },
            validDialPad() {
                return this.callId > 0;
            },
            validDigits() {
                return this.digits?.length > 0;
            },
        },
        components: {
        },
        props: {
            callId: Number,
            closeDialPad: Function,
            isTransfer: Boolean,
            sendDigit: Function,
        },
    });
</script>

<style lang="scss" scoped>
</style>
