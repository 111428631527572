<template lang="pug">
    ExpandableCardListItem.mt-0.mb-4(
        v-if="validTelematicAlert"
        :color="itemColor"
        :content-right-top="'VIN: ' + (telematicAlert.vehicle_vin || '---')"
        :content-right-bottom="(telematicAlert.friendly_created_date || '')"
        expandable
        :is-expanded="showDetails === true"
        :on-expand="() => showDetails = !showDetails"
        :title="telematicAlert.vehicle_vin_link || telematicAlert.alert_type"
        :subtitle="vehicleYearMakeModel"
    )
        template(v-slot:title)
            template(v-if="telematicAlert.vehicle_vin_link && telematicAlert.vehicle_vin_link.length > 0")
                a(:href="telematicAlert.vehicle_vin_link" target="_blank" style="color: white;") {{ telematicAlert.vehicle_vin_link }}
            template(v-else-if="telematicAlert.alert_type && telematicAlert.alert_type.length > 0")
                | {{ telematicAlert.alert_type }}
            template(v-else)
                | N/a

        template(v-slot:rightBottom)
            .text-uppercase
                | {{ telematicAlert.friendly_created_date }}
                v-icon.volie-color-blue(v-if="isCurrentCampaignCustomer" small) mdi-star

        template(v-slot:body)
            template(v-if="showDetails === true")
                v-card-text.white.text--primary
                    v-list.py-0(
                        two-line
                    )
                        ListItem(
                            icon="mdi-calendar"
                            indent
                            subtitle="Received At"
                            :title="telematicAlert.friendly_created_at"
                        )
                        v-divider(inset)
                        ListItem(
                            default-title="N/a"
                            icon="mdi-clock-alert"
                            indent
                            subtitle="Alert Timestamp"
                            :title="telematicAlert.alert_timestamp"
                        )
                        ListItem(
                            default-title="N/a"
                            indent
                            subtitle="Alert Type"
                            :title="telematicAlert.alert_type"
                        )
                        ListItem(
                            default-title="N/a"
                            indent
                            subtitle="VIN Link"
                            :title="telematicAlert.alert_timestamp"
                        )
                            template(v-slot:title)
                                template(v-if="telematicAlert.vehicle_vin_link && telematicAlert.vehicle_vin_link.length > 0")
                                    a(:href="telematicAlert.vehicle_vin_link" target="_blank") {{ telematicAlert.vehicle_vin_link }}
                                template(v-else) N/a

                        ListItem(
                            default-title="N/a"
                            indent
                            subtitle="Status"
                            :title="telematicAlert.alert_status"
                        )
                            template(v-slot:title)
                                template(v-if="telematicAlert.alert_status && telematicAlert.alert_status.length > 0")
                                    StatusText(:text="telematicAlert.alert_status")
                                template(v-else) N/a
                        v-divider(inset)
                        ListItem(
                            default-title="N/a"
                            icon="mdi-car"
                            indent
                            subtitle="Vehicle"
                            :title="vehicleYearMakeModel"
                        )
                        ListItem(
                            default-title="N/a"
                            indent
                            subtitle="VIN"
                            :title="telematicAlert.vehicle_vin"
                        )
                        v-divider(inset)
                        ListItem(
                            default-title="N/a"
                            icon="mdi-counter"
                            indent
                            subtitle="Odometer"
                            :title="telematicAlert.vehicle_odometer"
                        )
                        v-divider(inset)
                        ListItem(
                            default-title="N/a"
                            icon="mdi-note"
                            indent
                            multi-line-title
                            subtitle="Onboard Warnings"
                            :title="telematicAlert.vehicle_odometer"
                        )
                            template(v-slot:title)
                                .white-space-normal
                                    ul(v-if="telematicAlert.onboard_warning_titles && telematicAlert.onboard_warning_titles.length > 0")
                                        li(v-for="title in telematicAlert.onboard_warning_titles") {{ title }}
                                    template(v-else-if="telematicAlert.onboard_warnings && telematicAlert.onboard_warnings.length > 0") {{ telematicAlert.onboard_warnings }}
                                    template(v-else) N/a

                        ListItem(
                            default-title="N/a"
                            indent
                            multi-line-title
                            subtitle="Notes"
                            :title="telematicAlert.notes"
                        )
                        ListItem(
                            default-title="N/a"
                            indent
                            multi-line-title
                            subtitle="Enrolled"
                            :title="telematicAlert.notes"
                        )
                            template(v-slot:title)
                                .white-space-normal
                                    template(v-if="telematicAlert.campaign_customer_id && telematicAlert.campaign_customer_id > 0") {{ telematicAlert.enrolled_campaign_name }} ({{ telematicAlert.enrolled_communication_type_name }})
                                    .error--text(v-else-if="telematicAlert.enroll_reason && telematicAlert.enroll_reason.length > 0") ENROLL ERROR: {{ telematicAlert.enroll_reason }}
                                    template(v-else) ---

</template>

<script lang="ts">
    import Vue from 'vue';

    // Components
    import ExpandableCardListItem from '@/components/shared/expandable_card_list_item.vue';
    import ListItem from '@/components/shared/list_item.vue';
    import StatusText from '@/components/shared/status_text.vue';

    // Helpers
    import { resourceColorClass, resourceIcon, } from "@/helpers/css_classes";
    import { concatVehicleYearMakeModel } from "@/helpers/general";

    // Types
    import { VehicleYearMakeModelObj } from "@/helpers/general";

    export default Vue.extend({
        data() {
            return {
                showDetails: false,
            }
        },
        computed: {
            isCurrentCampaignCustomer() {
                return this.validTelematicAlert && this.validCurrentCampaignCustomer && this.telematicAlert.campaign_customer_id > 0 && this.telematicAlert.campaign_customer_id === this.currentCampaignCustomer.campaign_customer_id;
            },
            itemColor() {
                return resourceColorClass("TelematicAlert");
            },
            toolbarBackground() {
                return process.env.VUE_APP_VOLIE_DETAILS_COMPONENT_TOOLBAR_BACKGROUND_IMG;
            },
            validCurrentCampaignCustomer() {
                return this.currentCampaignCustomer?.campaign_customer_active_id > 0;
            },
            validTelematicAlert() {
                return this.telematicAlert?.telematic_alert_id > 0;
            },
            vehicleYearMakeModel() {
                if (!this.validTelematicAlert) {
                    return null;
                }

                const vehObj = {
                    vehicleModelYear: this.telematicAlert.vehicle_model_year,
                    vehicleMake:      this.telematicAlert.vehicle_make,
                    vehicleModel:     this.telematicAlert.vehicle_model,
                }

                return concatVehicleYearMakeModel(vehObj as VehicleYearMakeModelObj);
            },
        },
        components: {
            ExpandableCardListItem,
            ListItem,
            StatusText,
        },
        props: {
            currentCampaignCustomer: Object,
            telematicAlert: Object,
        },
    });
</script>

<style lang="scss" scoped>
    #app {
        .no-top-bottom-padding {
            padding-top: 0;
            padding-bottom: 0;
        }

        .cell-value-spacing {
            padding: 0 0 5px 0;
        }

        .m-l-5 {
            margin-left: 5px;
        }
    }
</style>
