export function statusClassMatcher(name: string): string {
    let className = "";
    switch(name) {
        case "active":
          className = "green--text";
          break;
        case "archived":
          className = "error--text";
          break;
        case "Available":
          className = "green--text";
          break;
        case "Available Queue Selection":
          className = "green--text";
          break;
        case "Both":
          className = "primary--text";
          break;
        case "Break":
          className = "info--text";
          break;
        case "Call Queue Selection":
          className = "primary--text";
          break;
        case "canceled":
          className = "error--text";
          break;
        case "canceling":
          className = "error--text";
          break;
        case "complete":
          className = "green--text";
          break;
        case "completed":
          className = "green--text";
          break;
        case "confirmed":
          className = "warning--text";
          break;
        case "create":
          className = "green--text";
          break;
        case "delete":
          className = "error--text";
          break;
        case "deleted":
          className = "error--text";
          break;
        case "delivered":
          className = "green--text";
          break;
        case "draft":
          className = "warning--text";
          break;
        case "duplicate":
            className = 'error--text';
            break;
        case "Email":
          className = "primary--text";
          break;
        case "Email - Available":
          className = "green--text";
          break;
        case "Email - Completed Customer":
          className = "green--text";
          break;
        case "Email - Composing New Email":
          className = "warning--text";
          break;
        case "Email - Forwarding Email":
          className = "warning--text";
          break;
        case "Email - Joined Thread":
          className = "primary--text";
          break;
        case "Email - Opened Inbox":
          className = "primary--text";
          break;
        case "Email - Replying To Email":
          className = "warning--text";
          break;
        case "Email - Saved Disposition":
          className = "green--text";
          break;
        case "Email - Saved For Later":
          className = "warning--text";
          break;
        case "Email - Sent Email":
          className = "green--text";
          break;
        case "error":
          className = "error--text";
          break;
        case "Finished CampaignCustomer":
          className = "green--text";
          break;
        case "inactive":
          className = "error--text";
          break;
        case "in_progress":
          className = "warning--text";
          break;
        case "Inbound":
          className = "primary--text";
          break;
        case "Inbound Wrap Up":
          className = "warning--text";
          break;
        case "Incoming Call":
          className = "warning--text";
          break;
        case "Logged In":
          className = "green--text";
          break;
        case "Logged In Queue Selection":
          className = "green--text";
          break;
        case "Logged Into All Call Queues":
          className = "green--text";
          break;
        case "Logged Into Call Queue":
          className = "green--text";
          break;
        case "Logged Into Call Queue Group":
          className = "green--text";
          break;
        case "Logged Out":
          className = "red--text";
          break;
        case "Logged Out of All Call Queues":
          className = "red--text";
          break;
        case "Logged Out of Call Queue":
          className = "red--text";
          break;
        case "Logged Out of Call Queue Group":
          className = "red--text";
          break;
        case "Lunch":
          className = "info--text";
          break;
        case "Meeting":
          className = "info--text";
          break;
        case "Missed Call Selection":
          className = "red--text";
          break;
        case "Neither":
          className = "warning--text";
          break;
        case "no_eligible_campaign_customers":
            className = "warning--text";
            break;
        case "no_show":
            className = "red--text";
            break;
        case "Non Production":
          className = "warning--text";
          break;
        case "Offering Call":
          className = "info--text";
          break;
        case "other":
          className = "info--text";
          break;
        case "Outbound":
          className = "primary--text";
          break;
        case "Outbound Wrap Up":
          className = "warning--text";
          break;
        case "Outgoing Call":
          className = "warning--text";
          break;
        case "Pause":
          className = "warning--text";
          break;
        case "paused":
          className = "warning--text";
          break;
        case "pausing":
          className = "warning--text";
          break;
        case "Phone - Accepted Incoming Call":
          className = "green--text";
          break;
        case "Phone - Accepted Incoming Call Transfer":
          className = "green--text";
          break;
        case "Phone - Declined Incoming Call":
          className = "red--text";
          break;
        case "Phone - Declined Incoming Call Transfer":
          className = "red--text";
          break;
        case "preparing":
          className = "info--text";
          break;
        case "rescheduled":
          className = "purple--text";
          break;
        case "Resume":
          className = "green--text";
          break;
        case "resumed":
          className = "green--text";
          break;
        case "resuming":
          className = "green--text";
          break;
        case "sale":
          className = "green--text";
          break;
        case "scheduled":
          className = "primary--text";
          break;
        case "Scheduled Communication Selection":
          className = "primary--text";
          break;
        case "Sending Hot Alert":
          className = "warning--text";
          break;
        case "service":
          className = "warning";
          break;
        case "Session":
          className = "info--text";
          break;
        case "SMS Message":
          className = "primary--text";
          break;
        case "SMS Message - Available":
          className = "green--text";
          break;
        case "SMS Message - Completed Customer":
          className = "green--text";
          break;
        case "SMS Message - Joined Conversation":
          className = "green--text";
          break;
        case "SMS Message - Processed Customer":
          className = "green--text";
          break;
        case "SMS Message - Saved Disposition":
          className = "green--text";
          break;
        case "SMS Message - Saved For Later":
          className = "warning--text";
          break;
        case "SMS Message - Sent Message":
          className = "green--text";
          break;
        case "sold":
            className = 'green--text';
            break;
        case "Start CampaignCustomer":
          className = 'green--text';
          break;
        case "Start Manual Call":
          className = 'green--text';
          break;
        case "Start Manual Email":
          className = 'green--text';
          break;
        case "Start Manual SMS Message":
          className = 'green--text';
          break;
        case "Start Return Missed Call":
          className = 'green--text';
          break;
        case "submitted":
          className = "warning--text";
          break;
        case "success":
          className = "green--text";
          break;
        case "system":
          className = "warning--text";
          break;
        case "System Logout":
          className = "red--text";
          break;
        case "System Template":
          className = "warning--text";
          break;
        case "vEmail":
          className = "primary--text";
          break;
        case "verified":
          className = "green--text";
          break;
        case "vMessage":
          className = "primary--text";
          break;
        case "unverified":
          className = "warning--text";
          break;
        case "expired":
          className = "warning--text";
          break;
        case "in_stock":
          className = "green--text";
          break;
        case "unavailable":
            className = "red--text";
            break;
        case "update":
          className = "warning--text";
          break;
        default:
          className = "primary--text";
          break;
      }
    return className;
}

export function calendarEventColorMatcher(name: string): string {
    let colorName = "";
    switch(name) {
        case "sale":
            colorName = "green";
            break;
        case "service":
            colorName = "orange";
            break;
        case "other":
            colorName = "amber";
            break;
        default:
            colorName = "blue";
            break;
    }
    return colorName;
}

export function resourceColorClass(name: string): string {
  let color = "primary";
  switch(name) {
      // case "AdfEmail":
      //     color = "red lighten-2";
      //     break;
      case "Activity":
          color = "white";
          break;
      case "AgentStatuses":
          color = "white";
          break;
      case "Appointment":
          color = "light-green lighten-2";
          break;
      case "Call":
          color = "indigo lighten-2";
          break;
      case "CampaignCustomer":
          break;
      case "Customer":
          color = "primary";
          break;
      case "Email":
          color = "pink";
          break;
      case "Message":
          color = "amber darken-2";
          break;
      case "Promotion":
          color = "light-green lighten-2";
          break;
      case "Prospect":
          color = "red lighten-2";
          break;
      case "Sale":
          color = "green";
          break;
      // case "ScheduledCommunication":
      //     break;
      case "Service":
          color = "purple lighten-2";
          break;
      case "TelematicAlert":
          color = "red lighten-2";
          break;
      case "Vehicle":
          color = "primary lighten-2";
          break;
      // default:
          // color = "primary"
    }

    return color;
}

// Uses resourceColorClass to get resource colors and appends --text to each color class and prepends to each lighten/dark class to use for text color
// https://v2.vuetifyjs.com/en/styles/colors/
export function resourceTextColorClass(name: string): string {
  const colorClassStr =  resourceColorClass(name);
  if (!(colorClassStr?.length > 0)) {
    return resourceColorClass(name);
  }

  const colorClasses = colorClassStr.split(' ');
  if (!(colorClasses?.length > 0)) {
    return resourceColorClass(name);
  }

  for(let i = 0; i < colorClasses.length; i++) {
    if (colorClasses[i].includes('lighten') || colorClasses[i].includes('darken')) {
      colorClasses[i] = 'text--' + colorClasses[i];
    } else {
      colorClasses[i] = colorClasses[i] + '--text';
    }
  }

  return colorClasses.join(' ');
}

export function resourceIcon(name: string): string {
  let icon = null;
  switch(name) {
      // case "AdfEmail":
      //     break;
      case "Appointment":
          icon = "mdi-calendar-month";
          break;
      case "Call":
          icon = "mdi-phone";
          break;
      case "CampaignCustomer":
          break;
      case "Customer":
          icon = "mdi-account";
          break;
      case "Email":
          icon = "mdi-email";
          break;
      case "Events":
          icon = "mdi-calendar-multiple";
          break;
      case "Export List":
          icon = "mdi-view-list-outline";
          break;
      case "Message":
          icon = "mdi-cellphone";
          break;
      case "Promotion":
          icon = "mdi-sale";
          break;
      case "Prospect":
          icon = "mdi-account-star";
          break;
      case "Sale":
          icon = "mdi-currency-usd";
          break;
      // case "ScheduledCommunication":
      //     break;
      case "scorecard_templates_index":
          icon = "mdi-file-outline";
          break;
      case "scorecards_index":
          icon = "mdi-inbox";
          break;
      case "scorecard_reports_index":
          icon = "mdi-chart-box-outline";
          break;
      case "Service":
          icon = "mdi-car-estate";
          break;
      case "sso":
          icon = "mdi-shield-lock";
          break;
      // case "TelematicAlert":
      //     break;
    }
    return icon;
}
