<template lang="pug">
v-tooltip(top)
    template(v-slot:activator='{ on, attrs }')
        span(
            v-bind="attrs"
            v-on="on"
            v-clipboard:copy="text"
            v-clipboard:success="onCopySuccess"
            v-clipboard:error="onCopyError"
            role="text"
            style="cursor: pointer;"
            @mouseleave="onMouseLeave()"
        )
            | {{ (text || '').trim() }}

    .toolbar-container-column
        .toolbar-row
            strong.text-center
                | {{ message }}
        .toolbar-row
            span
                | {{ (text || '').trim() }}
</template>

<script lang="ts">
import Vue from 'vue';
import VueClipboard from 'vue-clipboard2';
VueClipboard.config.autoSetContainer = true;
Vue.use(VueClipboard);

export default Vue.extend({
    data() {
        return {
            message: 'Click to Copy',
        }
    },
    methods: {
        onCopyError() {
            this.message = 'Error copying!';
        },
        onCopySuccess() {
            this.message = 'Text Copied!';
        },
        onMouseLeave() {
            setTimeout(() => {
                this.message = 'Click to Copy';
            }, 250);
        }
    },
    props: {
        text: String,
    },
});
</script>

<style lang="scss" scoped>
#app {
    .toolbar-container-column {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .toolbar-row {
        display: flex;
        flex-direction: row;
        justify-content: center;
    }
}
</style>
