import { get, post, put, destroy } from '.';

export function GetGoalTemplates(options = {}) {
	return get<any>("/app/api/goal_templates", options);
}

export function CreateGoalTemplate(options = {}) {
	return post<any>("/app/api/goal_templates", options);
}

export function FindGoalTemplate(vid:string, options = {}) {
	return get<any>(`/app/api/goal_templates/${vid}`, options);
}

export function UpdateGoalTemplate(vid:string, options = {}) {
	return put<any>(`/app/api/goal_templates/${vid}`, options);
}

export function DestroyGoalTemplate(id, options = {}) {
	return destroy<any>("/app/api/goal_templates/" + (id || 0).toString(), options);
}
