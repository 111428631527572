import {get, post, put, destroy } from "./"

export function CreateCampaignCampaignType(options = {}) {
    return post<any>('/app/api/campaign_campaign_types', options)
}

export function DestroyCampaignCampaignType(id) {
    return destroy<any>(`/app/api/campaign_campaign_types/${id}`)
}

export function SetPrimaryCampaignCampaignType(id) {
    return put<any>(`/app/api/campaign_campaign_types/${id}`)
}

export function GetAvailableCampaignCampaignTypes(options = {} ) {
    return get<any>('/app/api/campaign_campaign_types/available_types', options)
}

export function GetCampaignCampaignTypes(options = {}) {
    return get<any>('/app/api/campaign_campaign_types', options)
}
