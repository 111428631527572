import { get, post, put } from '.';

export function AcceptIncomingCall(id: string, options = {}) {
	return post<any>("/app/api/call_communication_queues/" + (id || 0).toString() + "/accept", options);
}

export function AssignNextInboundCall(options = {}) {
	return put<any>("/app/api/call_communication_queues/next", options);
}

export function DeclineIncomingCall(id: string, options = {}) {
	return post<any>("/app/api/call_communication_queues/" + (id || 0).toString() + "/decline", options);
}

export function GetCallsWaiting(options = {}) {
	return get<any>("/app/api/call_communication_queues/waiting", options);
}