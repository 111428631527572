<template lang="pug">
    v-hover(v-if="validEmailAttachment")
        template(v-slot:default='{ hover }')
            v-card.mx-auto(
                :elevation="hover ? 12 : 2"
            )
                v-card-text(v-if="isImage")
                    v-img(
                        contain
                        max-height='60'
                        max-width='90'
                        :src='emailAttachment.attachment_url'
                    )
                v-card-subtitle {{ displayAttachmentName | str_limit(50) }}
                v-fade-transition
                    v-overlay(v-if='hover' absolute color='#999999')
                        v-btn(
                            light
                            fab
                            small
                            ripple
                            :href="emailAttachment.attachment_url"
                            target="_blank"
                        )
                            v-icon mdi-download

</template>

<script lang="ts">
    import Vue from 'vue';

    export default Vue.extend({
        data() {
            return {
            }
        },
        methods: {
        },
        computed: {
            displayAttachmentName() {

                // Verify
                if (!this.validEmailAttachment) {
                    return;
                }

                // Split
                const pieces = this.emailAttachment.attachment_url.split("/");
                if (!(pieces?.length > 0)) {
                    return;
                }

                // Return last part of url
                return pieces[pieces.length - 1];
            },
            isImage() {
                return this.displayAttachmentName?.indexOf(".jpeg") >= 0 || this.displayAttachmentName?.indexOf(".jpg") || this.displayAttachmentName?.indexOf(".png");
            },
            validEmailAttachment() {
                return this.emailAttachment?.email_attachment_key?.length > 0 && this.emailAttachment?.attachment_url?.length > 0;
            },
        },
        components: {
        },
        props: {
            emailAttachment: Object,
        },
        destroyed() {
        },
        created() {
        },
        mounted() {
        },
        watch: {
        },
    });
</script>

<style lang="scss" scoped>
</style>
