<template lang="pug">
    ExpandableCardListItem.mt-0.mb-5.mx-1(
        :color="itemColor"
        :subtitle="campaignCustomerActivity.friendly_created_at"
    )
        template(v-slot:title)
            v-icon(medium :class="campaignCustomerActivity.status | statusClassMatcher") mdi-list-status
            span(:class="campaignCustomerActivity.status | statusClassMatcher")
                | {{ normalizedStatus }}

        template(v-slot:subtitle)
            span.black--text
                | {{ campaignCustomerActivity.friendly_created_at }}

        template(v-slot:rightTop)
            v-icon(v-if="campaignCustomerActivity.user_full_name" small) mdi-account
            span.black--text
                | {{ campaignCustomerActivity.user_full_name }}
</template>

<script lang="ts">
import Vue from 'vue';

// Components
import ExpandableCardListItem from '@/components/shared/expandable_card_list_item.vue';
import ListItem from '@/components/shared/list_item.vue';

// Helpers
import { resourceColorClass, } from "@/helpers/css_classes";
import {statusClassMatcher} from "@/helpers/css_classes";

export default Vue.extend({
    data() {
        return {
            showDetails: false,
        }
    },
    methods: {
    },
    computed: {
        itemColor() {
            return resourceColorClass("Activity");
        },
        normalizedStatus() {
          switch (this.campaignCustomerActivity.status) {
              case "assigned":
                  return "Assigned"
              case "completed":
                  return "Completed"
              case "error":
                  return "Error"
              case "in_progress":
                  return "In Progress"
              case "pending_deployment":
                  return "Pending Deployment"
              case "queued":
                  return "Queued"
              default:
                  return ""
          }
        },
    },
    components: {
        ExpandableCardListItem,
        ListItem,
    },
    props: {
        campaignCustomerActivity: Object,
    },
});
</script>

<style lang="scss" scoped>
#app {
    .cell-value-spacing {
        padding: 0 0 5px 0;
    }
}
</style>
