import { get, post, destroy, put } from '.';

export function GetTekionAppointments(options = {}) {
	return get<any>("/app/api/tekion/appointments", options);
}

export function ImportTekionAppointments(options = {}) {
	return post<any>("/app/api/tekion/appointments/import", options);
}

export function GetTekionAppointment(key) {
	return get<any>("/app/api/tekion/appointments/" + key);
}

export function CancelTekionAppointment(options = {}, id) {
	return destroy<any>(`/app/api/tekion/appointments/${id}`, options)
}

export function CreateTekionAppointment(options = {}) {
	return post<any>("/app/api/tekion/appointments", options);
}

export function UpdateTekionAppointment(id, options = {}) {
	return put<any>(`/app/api/tekion/appointments/${id}`, options)
}

export function GetTekionAppointmentSlots(options = {}) {
    return post<any>("/app/api/tekion/appointments/slots", options);
}