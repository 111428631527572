<template lang="pug">
    v-form(
        v-if="currentUser"
        :disabled="!validCustomer"
    )
        v-alert(dense outlined type='error' v-if="!validCustomer") In order to schedule a new appointment, a valid customer must be present.

        v-menu(
            v-model='datePickerMenu'
            :close-on-content-click='false'
            transition='scale-transition'
            offset-y
            max-width='290px'
            min-width='290px'
        )
            template(v-slot:activator='{ on, attrs }')
                v-text-field(
                    v-model='formatAppointmentDate'
                    label='Date *'
                    prepend-icon='mdi-calendar'
                    readonly
                    v-bind='attrs'
                    v-on='on'
                    :disabled="disableAppointmentDatetimeFields"
                )
            v-date-picker(
                v-model='appointment.appointment_datetime_date'
                no-title
                @input='datePickerMenu = false'
                :min="minDate"
            )

        Timepicker(
            label="Time *"
            :on-change="onChangeTimepicker"
            :value="appointment.appointment_datetime_time"
            :disable-input="disableAppointmentDatetimeFields"
            :appointment-scheduling-hour="appointmentSchedulingHour"
        )

        v-select(
            v-model='appointment.time_zone_id'
            :items='currentUser.time_zones'
            label='Time Zone *'
            item-text="name"
            item-value="id"
            :disabled="disableAppointmentDatetimeFields"
        )

        v-select(
            v-if="appointmentTypes.length > 0"
            v-model='appointment.appointment_type_id'
            :items='appointmentTypes'
            label='Type'
            item-text="name"
            item-value="appointment_type_id"
        )

        v-text-field(
            v-if="renderDeclineBonusesField"
            v-model="appointment.decline_bonuses"
            label="Decline Bonuses"
            type="number"
            min="0"
        )

        v-text-field(
            v-if="renderRecallFields"
            v-model="appointment.recall_bonuses"
            label='Recalls'
            type='number'
            min='0'
        )

        v-select(
            v-if="renderLeaseFields"
            v-model='appointment.vehicle_access'
            :items='vehicleAccesses'
            label='Vehicle Access'
            item-text="key"
            item-value="value"
        )

        v-select(
            v-if="renderLeaseFields"
            v-model='appointment.delivery_location'
            :items='deliveryLocations'
            label='Delivery Location'
            item-text="key"
            item-value="value"
        )

        v-text-field(
            v-if="renderLeaseFields"
            v-model="appointment.delivery_address"
            label='Delivery Address'
            type='text'
        )

        v-text-field(
            v-if="renderLeaseFields"
            v-model="appointment.customer_phone_number"
            label='Customer Phone Number'
            type='text'
        )

        v-text-field(
            v-if="renderLeaseFields"
            v-model="appointment.license_plate"
            label='License Plate'
            type='text'
        )

        v-text-field(
            v-if="renderLeaseFields"
            v-model="appointment.demo_vehicle"
            label='Demo Vehicle'
            type='text'
        )

        v-textarea(
            v-model="appointment.special_instructions"
            label='Special Instructions'
            rows="1"
            auto-grow
            clearable
            counter
            outlined
            dense
            :disabled="validTekionAppointmentDetails"
            prepend-inner-icon="mdi-text"
        )

</template>

<script lang="ts">
    import Vue from 'vue';
    import VolieState  from "@/store";

    // Components
    import Timepicker from "@/components/shared/timepicker.vue";

    // Helpers
    import { time12ToMilitary } from "@/helpers/general";

    export default Vue.extend({
        data() {
            return {
                appointment: null,
                datePickerMenu: false,
                deliveryLocations: [
                    { key: "Home", value: "Home" },
                    { key: "Office", value: "Office" },
                    { key: "Other", value: "Other" },
                ],
                minDate: null,
                vehicleAccesses: [
                    { key: "Pick Up", value: 1 },
                    { key: "Drop Off", value: 2 },
                ],
            }
        },
        methods: {
            formatDate (date) {
                if (!date) {
                    return null;
                }

                const [year, month, day] = date.split('-');
                return `${month}/${day}/${year}`;
            },
            initAppointment() {
                this.appointment = {
                    appointment_datetime_date: null,
                    appointment_datetime_time: null,
                    appointment_type_id: null,
                    attempt_key: this.currentAgent?.attempt_key,
                    customer_phone_number: null,
                    decline_bonuses: '0',
                    delivery_address: null,
                    delivery_location: null,
                    demo_vehicle: null,
                    license_plate: null,
                    recall_bonuses: '0',
                    special_instructions: null,
                    time_zone_id: null,
                    vehicle_access: null,
                }

                // Set autobuy fields if already present
                if (!!this.autobuyCommunicationDisposition) {
                    this.appointment.appointment_datetime_date = this.autobuyCommunicationDisposition.appointment_datetime_date;
                    this.appointment.appointment_datetime_time = this.autobuyCommunicationDispositionBuyerAvailability;
                }

                // set tekion fields if present
                if (!!this.tekionAppointmentDetails && this.tekionAppointmentDetails.appointment_id > 0) {
                    this.appointment.appointment_datetime_date = this.tekionAppointmentDetails.appointment_datetime_date;
                    this.appointment.appointment_datetime_time = this.tekionAppointmentDetails.appointment_datetime_time;
                    this.appointment.time_zone_id = this.tekionAppointmentDetails.time_zone_id;
                    this.appointment.special_instructions = this.tekionAppointmentDetails.special_instructions;
                }
            },
            onChange() {

                // If we need to use, call function
                if (this.useOnChange === true) {
                    this.onAppointmentChange(this.appointment);
                }
            },
            onChangeTimepicker(time) {
                this.appointment.appointment_datetime_time = time;
            },
            setDefaultTimeZone() {

                // Set default time zone ID
                if (this.timeZoneId > 0 && this.currentUser?.time_zones?.length > 0) {
                    const idx = this.currentUser.time_zones.findIndex(i => i.id === this.timeZoneId);
                    if (idx >= 0) {
                        this.appointment.time_zone_id = this.timeZoneId;
                    }
                }

                // If still invalid, default to eastern
                if (!(this.appointment.time_zone_id > 0)) {
                    this.appointment.time_zone_id = 43;
                }
            },
            setMinDate() {
                const tzoffset = (new Date()).getTimezoneOffset() * 60000; //offset in milliseconds
                this.minDate = (new Date(Date.now() - tzoffset)).toISOString().slice(0, -1);
            },
            setRescheduleAppointmentFields() {

                // Verify reschedule is present
                if (!(this.rescheduleAppointment?.appointment_id > 0)) {
                    return;
                }

                // Set defaults
                this.appointment.appointment_datetime_date = this.rescheduleAppointment.appointment_datetime_date;
                this.appointment.appointment_datetime_time = this.rescheduleAppointment.appointment_datetime_time;
                this.appointment.appointment_type_id = this.rescheduleAppointment.appointment_type_id;
                this.appointment.special_instructions = this.rescheduleAppointment.special_instructions;
                
                // Lease fields
                this.appointment.customer_phone_number = this.rescheduleAppointment.customer_phone_number;
                this.appointment.delivery_address = this.rescheduleAppointment.delivery_address;
                this.appointment.delivery_location = this.rescheduleAppointment.delivery_location;
                this.appointment.demo_vehicle = this.rescheduleAppointment.demo_vehicle;
                this.appointment.license_plate = this.rescheduleAppointment.license_plate;
                this.appointment.vehicle_access = this.rescheduleAppointment.vehicle_access;

                // set tekion fields if present
                if (!!this.tekionAppointmentDetails && this.tekionAppointmentDetails.appointment_id > 0) {
                    this.appointment.appointment_datetime_date = this.tekionAppointmentDetails.appointment_datetime_date;
                    this.appointment.appointment_datetime_time = this.tekionAppointmentDetails.appointment_datetime_time;
                    this.appointment.time_zone_id = this.tekionAppointmentDetails.time_zone_id;
                    this.appointment.special_instructions = this.tekionAppointmentDetails.special_instructions;
                }
            },
        },
        computed: {
            appointmentTypes() {
                return (this.currentUser?.account?.appointment_types || []);
            },
            autobuyCommunicationDispositionBuyerAvailability() {
                return this.autobuyCommunicationDisposition?.buyer_availability?.length > 0 ? time12ToMilitary(this.autobuyCommunicationDisposition?.buyer_availability) : null;
            },
            currentAgent() {
                return VolieState.currentAgent();
            },
            currentUser() {
                return VolieState.currentUser();
            },
            disableAppointmentDatetimeFields() {
                return this.validAutobuyCommunicationDisposition || this.validTekionAppointmentDetails;
            },
            formatAppointmentDate() {
                return this.formatDate(this.appointment?.appointment_datetime_date);
            },
            renderDeclineBonusesField() {
                return this.currentUser?.account?.account_id === 1020;
            },
            renderLeaseFields() {
                return this.currentUser?.account?.account_id === 7; // account 7 only
            },
            renderRecallFields() {
                return this.currentUser?.account?.account_id === 1020;
            },
            validAutobuyCommunicationDisposition() {
                return !!this.autobuyCommunicationDisposition;
            },
            validTekionAppointmentDetails() {
                return !!this.tekionAppointmentDetails && this.tekionAppointmentDetails.appointment_id > 0;
            }
        },
        components: {
            Timepicker,
        },
        props: {
            appointmentSchedulingHour: Object,
            autobuyCommunicationDisposition: Object,
            onAppointmentChange: Function,
            rescheduleAppointment: Object,
            timeZoneId: Number,
            useOnChange: Boolean,
            validCustomer: Boolean,
            tekionAppointmentDetails: Object,
        },
        watch: {
            appointment: {
                deep: true,
                handler() {
                    this.onChange();
                },
            },
            "autobuyCommunicationDisposition.appointment_datetime_date"() {
                this.appointment.appointment_datetime_date = this.autobuyCommunicationDisposition.appointment_datetime_date;
            },
            "autobuyCommunicationDisposition.buyer_availability"() {
                this.appointment.appointment_datetime_time = this.autobuyCommunicationDispositionBuyerAvailability;
            },
           'tekionAppointmentDetails': {
                handler(newValue) {
                    if (newValue.appointment_id > 0) {
                        this.initAppointment();
                    }
                },
                immediate: false,
                deep:true
            }
                    },
        created() {
            this.initAppointment();
            this.setDefaultTimeZone();
            this.setMinDate();

            // Set fields from reschedule is present
            this.setRescheduleAppointmentFields();
        },
    });
</script>

<style lang="scss" scoped>
</style> 
