<template lang="pug">      
    ExpandableCardListItem.mt-0.mb-4(
        v-if="validPromotion"
        :color="itemColor"
        :content-right-top="promotionStartDate + ' - ' + promotionEndDate"
        content-right-bottom="Active Date"
        expandable
        :is-expanded="showDetails === true"
        :on-expand="() => showDetails = !showDetails"
        :title="promotion.promotion_title"
    )

        template(v-slot:body)
            v-card-text.white.text--primary(v-if="showDetails === true")
                v-list.py-0(
                    two-line
                )
                    ListItem(
                        default-title="N/a"
                        :icon="resourceIcon('Promotion')"
                        indent
                        subtitle="Title"
                        :title="promotion.promotion_title"
                    )
                    ListItem(
                        default-title="N/a"
                        indent
                        multi-line-title
                        subtitle="Text"
                        :title="promotion.promotion_text"
                    )
                        template(v-slot:title)
                            v-container.pa-0(style="overflow: scroll;" v-html="promotion.promotion_text")

                    ListItem(
                        default-title="N/a"
                        indent
                        subtitle="Start Date"
                        :title="promotion.friendly_start_date"
                    )
                    ListItem(
                        default-title="N/a"
                        indent
                        subtitle="End Date"
                        :title="promotion.friendly_end_date"
                    )

</template>
<script lang="ts">
    import Vue from 'vue';

    // Components
    import ExpandableCardListItem from '@/components/shared/expandable_card_list_item.vue'; 
    import ListItem from '@/components/shared/list_item.vue';

    // Helpers
    import { resourceColorClass, resourceIcon, } from "@/helpers/css_classes";

    export default Vue.extend({
        data() {
            return {
                showDetails: false,
            }
        },
        methods: {
            resourceIcon(resource) {
                return resourceIcon(resource);
            },
        },
        computed: {
            itemColor() {
                return resourceColorClass("Promotion");
            },
            promotionEndDate() {
                return this.promotion?.friendly_end_date?.length > 0 ? this.promotion.friendly_end_date : '?'
            },
            promotionStartDate() {
                return this.promotion?.friendly_start_date?.length > 0 ? this.promotion.friendly_start_date : '?'
            },
            validPromotion() {
                return this.promotion?.promotion_id > 0;
            },
        },
        components: {
            ExpandableCardListItem,
            ListItem,
        },
        props: {
            promotion: Object,
            showClick: Function,
            source: String,
        },
    });
</script>

<style lang="scss" scoped>
    #app {
        .cell-value-spacing {
            padding: 0 0 5px 0;
        }
        .m-l-5 {
            margin-left: 5px;
        }
    }
</style>
