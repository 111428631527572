<template lang="pug">
    v-card.mx-lg-auto.mt-md-4.mb-md-4(outlined :width="emailEventCardWidth" style="margin: 10px 0px !important;")
        v-toolbar(flat)
            v-toolbar-title
                EmailEvent(:name="emailEvent.friendly_event")
                br
                span.text-body-2
                    | {{ emailEvent.friendly_email_recipient_name }}

            v-spacer
            .text-caption.text-right.m-l-5
                | {{ emailEvent.friendly_timestamp }}

</template>

<script lang="ts">
    import Vue from 'vue';

    // Components
    import EmailEvent from "@/components/emails/email_event.vue";

    export default Vue.extend({
        data() {
            return {
                emailEventCardWidth: 300,
				showDetails: false,
            }
        },
        methods: {
            handleEmailDetailsResize() {
                const emailDetailsWidth = document?.getElementById('email-details-card')?.getBoundingClientRect()?.width
                if (emailDetailsWidth >= 375) {
                    this.emailEventCardWidth = emailDetailsWidth - 75
                } else {
                    this.emailEventCardWidth = 300
                }
            },
        },
        computed: {
            validEmailEvent() {
                return this.emailEvent?.email_event_key > 0;
            },
        },
        components: {
            EmailEvent,
        },
        props: {
            emailEvent: Object,
        },
        watch: {
            emailEvent: {
                handler() {
                    Vue.nextTick(() => { this.handleEmailDetailsResize(); });
                },
                deep: true,
            },
        },
        created() {
			window.addEventListener('resize', this.handleEmailDetailsResize);
        },
        destroyed() {
            window.removeEventListener('resize', this.handleEmailDetailsResize);
        },
        mounted() {
            this.handleEmailDetailsResize();
        },
    });
</script>

<style lang="scss" scoped>
    #app {
        .cell-value-spacing {
            padding: 0 0 5px 0;
        }
    }
</style>
