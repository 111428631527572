import { get, post, put, destroy } from '.';

export function CreateCampaignType(options = {}) {
    return post<any>("/app/api/campaign_types", options);
}

export function DestroyCampaignType(id, options = {}) {
	return destroy<any>("/app/api/campaign_types/" + (id || 0).toString(), options);
}

export function FindCampaignType(vid) {
	return get<any>("/app/api/campaign_types/" + vid);
}

export function GetCampaignTypes(options = {}) {
	return get<any>("/app/api/campaign_types", options);
}

export function GetCampaignTypesForAccessGroups(options = {}) {
	return get<any>("/app/api/campaign_types/access_groups", options);
}

export function GetCampaignTypesForOrganization(options = {}) {
	return get<any>("/app/api/campaign_types_for_organization", options);
}

export function UpdateCampaignType(id, options = {}) {
	return put<any>("/app/api/campaign_types/" + (id || 0).toString(), options);
}