<template lang="pug">
    v-card.mx-lg-auto.mt-md-4.mb-md-4(v-if="validProspectContact" outlined :width="cardWidth" style="margin: 10px 0px !important;")
        v-toolbar(flat)
            v-btn(
                color="primary"
                icon
                ripple
                @click="showDetails = !showDetails;"
            )
                v-icon(v-if="showDetails === true")
                    | mdi-chevron-down
                v-icon(v-else)
                    | mdi-chevron-right

            v-toolbar-title(style ="font-size: 14px;")
                | {{ prospectContactName }}
        v-divider
        v-card-text(v-if="showDetails === true")
            v-simple-table
                template(v-slot:default)
                    tbody
                        tr
                            td
                                .text-capitalize.text-overline.text--secondary.text--darken-3 Contact First Name 
                                .cell-value-spacing(v-if="prospectContact.contact_first_name && prospectContact.contact_first_name.length > 0") {{ prospectContact.contact_first_name }}
                                .cell-value-spacing(v-else) N/a
                        tr
                            td
                                .text-capitalize.text-overline.text--secondary.text--darken-3 Contact Last Name 
                                .cell-value-spacing(v-if="prospectContact.contact_last_name && prospectContact.contact_last_name.length > 0") {{ prospectContact.contact_last_name }}
                                .cell-value-spacing(v-else) N/a
                        tr
                            td
                                .text-capitalize.text-overline.text--secondary.text--darken-3 Contact Email
                                .cell-value-spacing(v-if="prospectContact.contact_email && prospectContact.contact_email.length > 0") {{ prospectContact.contact_email }}
                                .cell-value-spacing(v-else) N/a
                        tr
                            td
                                .text-capitalize.text-overline.text--secondary.text--darken-3 Contact Address Street One
                                .cell-value-spacing(v-if="prospectContact.contact_address_street_one && prospectContact.contact_address_street_one.length > 0") {{ prospectContact.contact_address_street_one }}
                                .cell-value-spacing(v-else) N/a
                        tr
                            td
                                .text-capitalize.text-overline.text--secondary.text--darken-3 Contact Address Street Two
                                .cell-value-spacing(v-if="prospectContact.contact_address_street_two && prospectContact.contact_address_street_two.length > 0") {{ prospectContact.contact_address_street_two }}
                                .cell-value-spacing(v-else) N/a
                        tr
                            td
                                .text-capitalize.text-overline.text--secondary.text--darken-3 Contact Address City
                                .cell-value-spacing(v-if="prospectContact.contact_address_city && prospectContact.contact_address_city.length > 0") {{ prospectContact.contact_address_city }}
                                .cell-value-spacing(v-else) N/a
                        tr
                            td
                                .text-capitalize.text-overline.text--secondary.text--darken-3 Contact Address Region
                                .cell-value-spacing(v-if="prospectContact.contact_address_region && prospectContact.contact_address_region.length > 0") {{ prospectContact.contact_address_region }}
                                .cell-value-spacing(v-else) N/a
                        tr
                            td
                                .text-capitalize.text-overline.text--secondary.text--darken-3 Contact Address Postal Code
                                .cell-value-spacing(v-if="prospectContact.contact_address_postal_code && prospectContact.contact_address_postal_code.length > 0") {{ prospectContact.contact_address_postal_code }}
                                .cell-value-spacing(v-else) N/a
                        tr
                            td
                                .text-capitalize.text-overline.text--secondary.text--darken-3 Contact Home Phone
                                .cell-value-spacing(v-if="prospectContact.contact_home_phone && prospectContact.contact_home_phone.length > 0") {{ prospectContact.contact_home_phone | tel }}
                                .cell-value-spacing(v-else) N/a
                        tr
                            td
                                .text-capitalize.text-overline.text--secondary.text--darken-3 Contact Work Phone
                                .cell-value-spacing(v-if="prospectContact.contact_work_phone && prospectContact.contact_work_phone.length > 0") {{ prospectContact.contact_work_phone | tel }}
                                .cell-value-spacing(v-else) N/a
                        tr
                            td
                                .text-capitalize.text-overline.text--secondary.text--darken-3 Contact Cell Phone
                                .cell-value-spacing(v-if="prospectContact.contact_cell_phone && prospectContact.contact_cell_phone.length > 0") {{ prospectContact.contact_cell_phone | tel }}
                                .cell-value-spacing(v-else) N/a
                        tr
                            td
                                .text-capitalize.text-overline.text--secondary.text--darken-3 Contact Birth Date
                                .cell-value-spacing(v-if="prospectContact.contact_birth_date && prospectContact.contact_birth_date.length > 0") {{ prospectContact.contact_birth_date }}
                                .cell-value-spacing(v-else) N/a

</template>

<script lang="ts">
    import Vue from 'vue';

    // Helpers
    import { formatName } from "@/helpers/name";

    export default Vue.extend({
        data() {
            return {
                cardWidth: 300,
				showDetails: false,
            }
        },
        methods: {
            handleDetailsResize() {
                const detailsWidth = document?.getElementById('prospect-details-card')?.getBoundingClientRect()?.width
                if (detailsWidth >= 375) {
                    this.cardWidth = detailsWidth - 75
                } else {
                    this.cardWidth = 300
                }
            },
        },
        computed: {
            prospectContactName() {
                if (!this.validProspectContact) {
                    return null;
                }
                
                if (this.prospectContact?.contact_first_name?.length > 0 || this.prospectContact?.contact_last_name?.length > 0) {
                    return formatName([this.prospectContact.contact_first_name, this.prospectContact.contact_last_name])
                }

                if (this.prospectContact?.contact_email?.length > 0) {
                    return this.prospectContact.contact_email;
                }

                return '---';
            },
            validProspectContact() {
                return this.prospectContact?.prospect_contact_id > 0;
            },
        },
        props: {
            prospectContact: Object,
        },
        watch: {
            prospectContact: {
                handler() {
                    Vue.nextTick(() => { this.handleDetailsResize(); });
                },
                deep: true,
            },
        },
        created() {
			window.addEventListener('resize', this.handleDetailsResize);
        },
        destroyed() {
            window.removeEventListener('resize', this.handleDetailsResize);
        },
        mounted() {
            this.handleDetailsResize();
        },
    });
</script>

<style lang="scss" scoped>
    #app {
        .cell-value-spacing {
            padding: 0 0 5px 0;
        }
    }
</style>
