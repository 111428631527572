import { get, post, put, destroy } from '.';

export function CreateTeam(options = {}) {
	return post<any>("/app/api/teams", options);
}

export function DestroyTeam(teamVid:string, options = {}) {
	return destroy<any>(`/app/api/teams/${teamVid}`, options);
}

export function FindTeam(teamVid:string, options = {}) {
	return get<any>(`/app/api/teams/${teamVid}`, options);
}

export function GetTeams(options = {}) {
	return get<any>("/app/api/teams", options);
}

export function GetAvailableTeamsForCommunicationQueue(options = {}) {
	return get <any>("/app/api/teams/available_for_communication_queue", options)
}

export function UpdateTeam(teamVid:string, options = {}) {
	return put<any>(`/app/api/teams/${teamVid}`, options);
}