import { get, post, put, destroy } from '.';

export function GetBusinessHours(options = {}) {
    return get<any>("/app/api/business_hours", options);
}

export function CreateBusinessHour(ownableType: string, ownableId: number, options = {}) {
    return post<any>(`/app/api/${ownableType}/${ownableId}/business_hours`, options);
}

export function UpdateBusinessHour(ownableType: string, ownableId: number, businessHourId: number, options = {}) {
    return put<any>(`/app/api/${ownableType}/${ownableId}/business_hours/${businessHourId}`, options);
}

export function DestroyBusinessHour(ownableType, ownableId, id) {
    return destroy<any>(`/app/api/${ownableType}/${ownableId}/business_hours/${id}`);
  }
