<template lang="pug">
    .ma-sm-2(v-if="validOrganizationId")
        .text-center(v-if="isLoading")
            v-progress-circular(
                :size='100'
                :width='10'
                color='#0f5b9a'
                indeterminate
                style="margin-top: 100px; margin-bottom: 100px;"
            )

        v-container.mx-auto(v-if="!isLoading")
            .text-subtitle-2.subtitle-2-padding.text-center
                | Promotions

            PromotionDetails(
                v-if="validPromotions"
                v-for='promotion in promotions'
                :key='promotion.promotion_id'
                :promotion="promotion"
            )

            v-alert(outlined type='warning' dense border='left' style="margin-top: 16px;" v-if="!validPromotions")
                | No active promotions found

</template>

<script lang="ts">
    import Vue from 'vue';

    // Components
    import PromotionDetails from '@/components/promotions/promotion_details.vue';

    // Data
    import { GetPromotions } from "@/data";

    export default Vue.extend({
        data() {
            return {
                promotions: [],
                isLoading: false,
            }
        },
        methods: {
            getPromotions() {
                if (!this.validOrganizationId) {
                    return;
                }

                const options = {
                    organization_id: this.organizationId,
                    campaign_id:     this.campaignId > 0 ? this.campaignId : null,
                    customer_id:     this.customerId > 0 ? this.customerId : null,
                }

                this.isLoading = true;
                 GetPromotions(options).then((response) => {
                    if (response?.Data?.promotions?.length > 0) {
                        this.promotions = response.Data.promotions;
                    }

                    this.isLoading = false;
                });
            },
        },
        computed: {
            validPromotions() {
                return this.promotions?.length > 0;
            },
            validOrganizationId() {
                return this.organizationId > 0;
            },
        },
        components: {
            PromotionDetails,
        },
        props: {
            campaignId:     Number,
            customerId:     Number,
            organizationId: Number,
        },
        created() {
            this.getPromotions();
        },
    });
</script>

<style lang="scss" scoped>
</style>
