import { get, post, put, destroy } from '.';

export function GetAvailableCommunicationProviders(id, options = {}) {
	return get<any>("/app/api/organizations/" + (id || 0).toString() + "/communication_providers", options);
}

export function GetCommunicationCenterOrganization(options = {}) {
	return get<any>("/app/api/communication_center_organization", options);
}

export function GetOrganizationsForSuperAdmin(options = {}) {
	return get<any>("/app/api/organizations/super_admin", options);
}

export function GetOrganizationsForAccessGroups(options = {}) {
	return get<any>("/app/api/organizations/access_groups", options);
}

export function CreateOrganization(options = {}) {
	return post<any>("/app/api/organizations", options);
}

export function DestroyOrganization(options = {}) {
	return destroy<any>("/app/api/organizations", options);
}

export function FindOrganization(vid: string, options = {}) {
	return get<any>(`/app/api/organizations/${vid}`, options);
}

export function GetOrganizations(options = {}) {
	return get<any>("/app/api/organizations", options);
}

export function UpdateOrganization(vid: string, options = {}) {
	return put<any>(`/app/api/organizations/${vid}`, options);
}
