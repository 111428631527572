import Vue from 'vue';
import { formatDateFromDateTime, formatTel, secondsToFullWords, stringFormat, titleize, secondsToFriendlyElapsedTimeFormat } from '@/helpers/general';
import { formatCentsToDollarsFloat, formatDollarFloat } from '@/helpers/currency';
import {joinArrayOfStringsWithComma} from "@/helpers/array";
import {statusClassMatcher} from "@/helpers/css_classes";

export default function() {
    Vue.filter('statusClassMatcher', function (statusName) {
        return statusClassMatcher(statusName)
    });

    Vue.filter('friendlyDurationFormat', function (seconds) {
        if (seconds === undefined || seconds === null || seconds <= 0) {
            return "---";
        }

        const hours = (seconds / 3600);
        let hourPiece = "";
        if (hours >= 1) {
            if (Math.floor(hours).toFixed() === "1") {
                hourPiece = Math.floor(hours).toString() + " hr";
            } else {
                hourPiece = Math.floor(hours).toString() + " hrs";
            }
        }

        const minutes = ((seconds - (Math.floor(hours) * 3600)) / 60);
        let minutePiece = "";
        if (minutes >= 1) {
            if (Math.floor(minutes).toFixed() === "1") {
                minutePiece = Math.floor(minutes).toString() + " min";
            } else {
                minutePiece = Math.floor(minutes).toString() + " mins";
            }
        }

        const secs = (seconds % 60);
        let secPiece = "";
        if (secs >= 1) {
            if (Math.floor(secs).toFixed() === "1") {
                secPiece = Math.floor(secs).toString() + " sec";
            } else {
                secPiece = Math.floor(secs).toString() + " secs";
            }
        }

        return (hourPiece + " " + minutePiece + " " + secPiece).trim();
    });

    Vue.filter('arrayStringToCommaString', function(stringArray){
        if(!stringArray || stringArray?.length === 0) { return ''; }

        return joinArrayOfStringsWithComma(stringArray);
    })

    Vue.filter('secondsToFriendlyElapsedTimeFormat', function(str){
        if(!str || str?.length === 0) { return ''; }

        return secondsToFriendlyElapsedTimeFormat(str);
    })

    Vue.filter('titleize', function (str) {
        return titleize(str)
    });

    Vue.filter('tel', function (tel) {
        return formatTel(tel);
    });

    Vue.filter('secondsToFullWords', function (seconds) {
        return secondsToFullWords(seconds)
    });

    Vue.filter('makePositive', function (value) {
        if (!!value) {
            return Math.abs(value);
        }

        return "0";
    });

    Vue.filter('number', function (value) {
        if (!!value) {
            return value.toLocaleString();
        }

        return "0";
    });

    Vue.filter('dollars', function (value) {
        if (!!value) {
            return "$" + value.toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
            })
        }
    });

    Vue.filter('str_limit', function (value, size) {
        if (!value) return '';
        const valueStr = value.toString();

        if (valueStr.length <= size) {
            return valueStr;
        }
        return valueStr.substr(0, size) + '...';
    });

    Vue.filter('cents_to_dollar', function (value) {
        return formatCentsToDollarsFloat(value);
    });

    Vue.filter('dollar', function (value) {
        return formatDollarFloat(value);
    });

    Vue.filter('prettify_json', function (value) {
        return JSON.stringify(JSON.parse(value, (k, v) => {

            if (!!v) {
                const valueStr = v.toString();

                if (valueStr.length > 100) {
                    return valueStr.substr(0, 100) + '...';
                }
            }

            return v;

        }), null, 2);
    });


    Vue.filter('dateFromDateTime', function (dateTime) {
        return formatDateFromDateTime(dateTime);
    });

    Vue.filter('stringFormat', function (str) {
        return stringFormat(str)
    });
}