export const AGENT = parseInt(process.env.VUE_APP_ROLE_AGENT);
export const CLIENT_EMPLOYEE = parseInt(process.env.VUE_APP_ROLE_CLIENT_EMPLOYEE);
export const MANAGER = parseInt(process.env.VUE_APP_ROLE_MANAGER);
export const ORGANIZATION_AGENT = parseInt(process.env.VUE_APP_ROLE_ORGANIZATION_AGENT);
export const ORGANIZATION_MANAGER = parseInt(process.env.VUE_APP_ROLE_ORGANIZATION_MANAGER);
export const OWNER = parseInt(process.env.VUE_APP_ROLE_OWNER);
export const PURCHASED_LIST_ONLY = parseInt(process.env.VUE_APP_ROLE_PURCHASED_LIST_ONLY);
export const SUPERVISOR = parseInt(process.env.VUE_APP_ROLE_SUPERVISOR);
export const SUPER_ADMIN = parseInt(process.env.VUE_APP_ROLE_SUPER_ADMIN);
export const WHITE_GLOVE_DEALER = parseInt(process.env.VUE_APP_ROLE_WHITE_GLOVE_DEALER);

export const All = [
	AGENT,
	CLIENT_EMPLOYEE,
	MANAGER,
	ORGANIZATION_AGENT,
	ORGANIZATION_MANAGER,
	OWNER,
	PURCHASED_LIST_ONLY,
	SUPERVISOR,
	SUPER_ADMIN,
	WHITE_GLOVE_DEALER,
]
