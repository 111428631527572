<template lang="pug">
    v-card.mx-lg-auto.mt-md-4.mb-md-4(outlined width="100%" v-if="validCustomField")
        v-toolbar(flat)
            v-btn(
                color="primary"
                icon
                ripple
                @click="showDetails = !showDetails;"
            )
                v-icon(v-if="showDetails === true")
                    | mdi-chevron-down
                v-icon(v-else)
                    | mdi-chevron-right

            v-toolbar-title(style="padding-left: 16px; font-size: 16px;")
                | {{ customField.key }}
                br
                v-list-item(style='padding: 0px; min-height: 0px;')
                    v-list-item-content(style='padding: 0px;')
                        v-list-item-subtitle Key

            v-spacer(style='min-width: 96px;' v-if="!hideCreateUpdateDestroyButtons")
                .text-right
                    CustomFieldFormDialog(
                        :campaign-customer-id="campaignCustomerId"
                        :customer-id="customerId"
                        :custom-field="customField"
                        :update-custom-field="updateCustomField"
                    )
                    CustomFieldDestroyDialog(
                        :custom-field="customField"
                        :destroy-custom-field="destroyCustomField"
                    )

        v-card-text(style="padding-top: 0px;")
            v-list-item
                v-list-item-content(style="margin-left: 36px;")
                    v-list-item-title.list-item-title-overflow
                        template(v-if="customField.value && customField.value.length > 0") {{ customField.value }}
                        template(v-else) N/a
                    v-list-item-subtitle Value
            v-list-item
                v-list-item-content(style="margin-left: 36px;")
                    v-list-item-title.list-item-title-overflow
                        template(v-if="customField.modelable_type && customField.modelable_type.length > 0") {{ customField.modelable_type }}
                        template(v-else) N/a
                    v-list-item-subtitle Model

        template(v-if="showDetails === true")
            v-divider
            v-card-text
                v-simple-table
                    template(v-slot:default)
                        tbody
                            tr
                                td
                                    .text-capitalize.text-overline.text--secondary.text--darken-3 Key
                                    .cell-value-spacing {{ customField.key }}
                            tr
                                td
                                    .text-capitalize.text-overline.text--secondary.text--darken-3 Value
                                    .cell-value-spacing {{ customField.value }}
                            tr
                                td
                                    .text-capitalize.text-overline.text--secondary.text--darken-3 Model
                                    .cell-value-spacing(v-if="customField.modelable_type && customField.modelable_type.length > 0") {{ customField.modelable_type | titleize }}
                                    .cell-value-spacing(v-else) N/a
                            tr
                                td
                                    .text-capitalize.text-overline.text--secondary.text--darken-3 Data Type
                                    .cell-value-spacing(v-if="customField.data_type && customField.data_type.length > 0") {{ customField.data_type | titleize }}
                                    .cell-value-spacing(v-else) N/a
                            tr
                                td
                                    .text-capitalize.text-overline.text--secondary.text--darken-3 Source
                                    .cell-value-spacing(v-if="customField.source && customField.source.length > 0") {{ customField.source }}
                                    .cell-value-spacing(v-else) N/a
                            tr
                                td
                                    .text-capitalize.text-overline.text--secondary.text--darken-3 Created
                                    .cell-value-spacing {{ customField.friendly_created_at_date }}


</template>

<script lang="ts">
    import Vue from 'vue';

    // Components
    import CustomFieldDestroyDialog from "@/components/custom_fields/destroy_dialog.vue";
    import CustomFieldFormDialog from "@/components/custom_fields/form_dialog.vue";

    export default Vue.extend({
        data() {
            return {
                showDetails: false,
            }
        },
        methods: {
        },
        computed: {
            validCustomField() {
                return this.customField?.custom_field_id > 0;
            },
        },
        components: {
            CustomFieldDestroyDialog,
            CustomFieldFormDialog,
        },
        props: {
            campaignCustomerId:             Number,
            customerId:                     Number,
            customField:                    Object,
            destroyCustomField:             Function,
            hideCreateUpdateDestroyButtons: Boolean,
            updateCustomField:              Function,
        },
    });
</script>

<style lang="scss" scoped>
    #app {

        .cell-value-spacing {
            padding: 0 0 5px 0;
        }

        .list-item-title-overflow {
            overflow: auto !important;
            white-space: normal !important;
        }
    }
</style>
