<template lang="pug">
    ExpandableCardListItem.mt-0.mb-4(
        v-if="validConference"
        :color="itemColor"
        :content-right-top="conference.friendly_direction"
        :content-right-bottom="conference.friendly_start_date"
        expandable
        :is-expanded="showDetails === true"
        :on-expand="() => showDetails = !showDetails"
        :right-action-icon="renderViewLink ? 'mdi-eye' : null"
        :title="conference.campaign_name"
        :subtitle="conference.disposition_description"
    )
        template(v-slot:rightTop)
            strong.text-uppercase {{ conference.friendly_direction }}

        template(v-slot:rightActionIcon)
            v-btn.no-text-underline(
                v-if="renderViewLink"
                icon 
                dark 
                :to="{ name: 'conference_show', params: { conference_key: conference.conference_key } }"
                target="_blank"
            )
                v-icon.text-white mdi-eye

        template(v-slot:body)
            v-card-text.white.text--primary(v-if="showDetails === true")
                v-list.py-0(
                    two-line
                )
                    ListItem(
                        default-title="N/a"
                        :icon="conference.missed_call_purpose && conference.missed_call_purpose.length > 0 ? 'mdi-phone-remove' : 'mdi-phone-check'"
                        indent
                        subtitle="Campaign"
                        :title="conference.campaign_name"
                    )
                    ListItem(
                        default-title="N/a"
                        indent
                        subtitle="Customer Phone Number"
                        :title="conference.customer_phone_number | tel"
                    )
                        template(v-slot:title)
                            template(v-if="conference.customer_phone_number && conference.customer_phone_number.length > 0")
                                CopyablePhoneNumber(:phone-number="{phone_number_phone_number: conference.customer_phone_number}")
                            template(v-else) N/a

                    ListItem(
                        default-title="N/a"
                        indent
                        subtitle="Datetime"
                        :title="conference.friendly_start_time"
                    )
                    ListItem(
                        default-title="N/a"
                        indent
                        subtitle="Duration"
                        :title="conference.friendly_duration | friendlyDurationFormat"
                    )

                    template(v-if="conference.missed_call_purpose && conference.missed_call_purpose.length > 0")
                        ListItem(
                            default-title="N/a"
                            indent
                            subtitle="Missed Call"
                            :title="conference.friendly_duration | friendlyDurationFormat"
                        )
                            template(v-slot:title)
                                strong.text-uppercase.error--text {{ conference.missed_call_purpose | titleize }}


                    template(v-if="conference.missed_call_purpose && conference.missed_call_purpose.length > 0")
                        v-list-item
                            v-list-item-icon
                            v-list-item-content
                                v-list-item-title
                                    strong.text-uppercase.error--text {{ conference.missed_call_purpose | titleize }}
                                v-list-item-subtitle Missed Call

                    template(v-else)
                        ListItem(
                            default-title="N/a"
                            indent
                            subtitle="Agent"
                            :title="conference.user_full_name"
                        )
                        ListItem(
                            default-title="N/a"
                            indent
                            subtitle="Disposition"
                            :title="conference.disposition_description"
                        )

                        ListItem(
                            default-title="N/a"
                            indent
                            multi-line-title
                            subtitle="Notes"
                            :title="conference.survey_attempt_notes"
                        )

                        //- appointment
                        template(v-if="conference.appointment_id > 0")
                            v-divider(inset)
                            ListItem(
                                default-title="N/a"
                                icon="mdi-calendar"
                                indent
                                subtitle="Appointment Datetime"
                                :title="conference.friendly_appointment_datetime"
                            )
                            ListItem(
                                default-title="N/a"
                                indent
                                subtitle="Appointment Number"
                                :title="conference.appointment_number"
                            )
                            ListItem(
                                default-title="N/a"
                                indent
                                subtitle="Appointment Status"
                                :title="conference.appointment_status | titleize"
                            )
                                template(v-slot:title)
                                    template(v-if="conference.appointment_status && conference.appointment_status.length > 0")
                                        StatusText(:text="conference.appointment_status")
                                    template(v-else) N/a


                        template(v-else)
                            v-divider(inset)
                            ListItem(
                                default-title="N/a"
                                icon="mdi-calendar"
                                indent
                                title="No Appointment Scheduled"
                            )

                        //- prospect
                        template(v-if="conference.prospect_id > 0")
                            v-divider(inset)
                            ListItem(
                                default-title="N/a"
                                icon="mdi-folder-account"
                                indent
                                subtitle="Lead Key"
                                :title="conference.prospect_lead_key"
                            )
                            ListItem(
                                default-title="N/a"
                                indent
                                subtitle="Lead Date"
                                :title="conference.friendly_prospect_request_date"
                            )
                            ListItem(
                                default-title="N/a"
                                indent
                                subtitle="Lead Source"
                                :title="conference.prospect_lead_source_name"
                            )

                    //- recording
                    template(v-if="canListenToRecording")
                        v-divider(inset)
                        ListItem(
                            default-title="No recording available"
                            icon="mdi-record-circle-outline"
                            indent
                            subtitle="Recording"
                            :title="validRecording ? conference.recording_url : null"
                        )
                            template(v-slot:title)
                                VolieAudio(
                                    v-if="validRecording"
                                    :file="conference.recording_url"
                                    color="success"
                                    :downloadable="canDownloadRecording"
                                )

                //- survey
                template(v-if="validSurveyId")
                    v-divider(inset)
                    v-simple-table
                        template(v-slot:default)
                            tbody
                                tr
                                    td
                                        v-btn(v-if="!showSurvey" text color='primary' plain small @click="getSurveyAnswers()")
                                            | Show Survey
                                        v-progress-circular(
                                            v-else-if="isLoadingSurvey"
                                            :size='25'
                                            :width='3'
                                            color='#0f5b9a'
                                            indeterminate
                                        )
                                        SurveyAnswer(
                                            v-else-if="validSurveyAnswers"
                                            v-for='(surveyAnswer, i) in surveyAnswers'
                                            :key='i'
                                            :survey-answer="surveyAnswer"
                                        )

</template>

<script lang="ts">
    import Vue from 'vue';
    import VolieState  from "@/store";

    // Components
    import CopyablePhoneNumber from '@/components/shared/copyable_phone_number.vue';
    import ExpandableCardListItem from '@/components/shared/expandable_card_list_item.vue';
    import ListItem from '@/components/shared/list_item.vue';
    import StatusText from '@/components/shared/status_text.vue';
    import SurveyAnswer from '@/components/survey_answers/survey_answer.vue';
    import VolieAudio from '@/components/shared/audio_player.vue';

    // Data
    import { GetSurveyAnswers } from "@/data";

    // Helpers
    import { friendlyDurationFormat } from "@/helpers/general";
    import { resourceColorClass, } from "@/helpers/css_classes";

    export default Vue.extend({
        data() {
            return {
                isLoadingSurvey: false,
                showDetails: false,
                showSurvey: false,
                surveyAnswers: [],
            }
        },
        methods: {
            getSurveyAnswers() {
                if (!this.validSurveyId) {
                    return;
                }

                this.showSurvey = true;
                this.isLoadingSurvey = true;
                GetSurveyAnswers(this.conference.survey_id).then((response) => {
                    if (response?.Data?.survey_answers?.length > 0) {
                        this.surveyAnswers = response.Data.survey_answers;
                    }

                    this.isLoadingSurvey = false;
                });
            },
            renderDuration(duration) {
                return friendlyDurationFormat(duration);
            },
        },
        computed: {
            accountListenRecordingEnabled() {
                return this.currentUser?.account?.communication_center_recordings_listen_enabled === true;
            },
            accountDownloadRecordingEnabled() {
                return this.currentUser?.account?.communication_center_recordings_download_enabled === true;
            },
            canListenToRecording() {
                return this.source !== "communication_center" || (this.source === "communication_center" && this.accountListenRecordingEnabled === true);
            },
            canDownloadRecording() {
                return this.accountDownloadRecordingEnabled && this.canListenToRecording;
            },
            currentUser () {
                return VolieState.currentUser();
            },
            itemColor() {
                return resourceColorClass("Call");
            },
            renderViewLink() {
                return this.source === "admin";
            },
            validConference() {
                return this.conference?.conference_id > 0;
            },
            validNotes() {
                return this.conference?.survey_attempt_notes?.length > 0;
            },
            validRecording() {
                return this.conference?.recording_id > 0 && this.conference?.recording_url?.length > 0;
            },
            validSurveyAnswers() {
                return this.surveyAnswers?.length > 0;
            },
            validSurveyId() {
                return this.conference?.survey_id > 0;
            },
        },
        components: {
            CopyablePhoneNumber,
            ExpandableCardListItem,
            ListItem,
            StatusText,
            SurveyAnswer,
            VolieAudio,
        },
        props: {
            conference: Object,
            source: String,
        },
    });
</script>

<style lang="scss" scoped>
    #app {
        .no-top-bottom-padding {
            padding-top: 0;
            padding-bottom: 0;
        }

        .cell-value-spacing {
            padding: 0 0 5px 0;
        }

        .m-l-5 {
            margin-left: 5px;
        }
    }
</style>
