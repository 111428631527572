export interface CleanPhoneNumber {
	areaCode: string;
	countryIso: string;
	dialingCode: string;
	internationalCallPrefix: string;
	lineNumber: string;
	numberType: string;
	phoneNumber: string;
	prefixCode: string;
	valid: boolean;
}

// Local: 10 Digits, starts with non-"4". Ex: (02) xxxx-xxxx
// Mobile: 9 Digits, starts with "4". Ex: 4 xxxxxxxx
// Toll Free: Prefix "1800", "1300", or "13" followed by 4-10 digits
export function evaluateAustraliaPhoneNumber(pn, cleanStr: string): CleanPhoneNumber {

	// Verify presence
	if (cleanStr === null || cleanStr === "" || cleanStr?.length <= 2) {
		return pn;
	}

	// If clean str still has +61 prefix, remove
	if (cleanStr.startsWith("+61")) {
		cleanStr = cleanStr.substr(1); // Remove +
		cleanStr = cleanStr.substr(1); // Remove 6
		cleanStr = cleanStr.substr(1); // Remove 1
	} else if (cleanStr.startsWith("61") && (cleanStr.length === 11 || cleanStr.length === 12)) {
		cleanStr = cleanStr.substr(1); // Remove 6
		cleanStr = cleanStr.substr(1); // Remove 1
	}

	// If the length is 10 and starts with a leading 0, remove the 0
	if (cleanStr.length === 10 && cleanStr.startsWith("0")) {
		cleanStr = cleanStr.substr(1); // Remove 0
	}

	// Init
	const validAustraliaTollFreePrefixes = ["1800", "1300", "13"];

	const prefix = cleanStr[0]; // First character
	switch(prefix) {
		case "4":
			pn.prefixCode = "4";
			pn.numberType = "mobile";
			break

		case "1":

			// Verify prefix is valid toll free pattern
			for (let i = 0; i < validAustraliaTollFreePrefixes.length; i++) {

				const val = validAustraliaTollFreePrefixes[i];
				if (cleanStr.startsWith(val)) {
					pn.prefixCode = val
				}
			}

			// If we found a valid prefix pattern, set type to toll free
			if (pn.prefixCode != "") {
				pn.numberType = "tollfree";
			}
			break

		default:
			pn.prefixCode = prefix;
			pn.numberType = "local";
			break
	}

	// If we are missing the prefix or type at this point, abort - invalid Australia Phone Number
	if (pn.prefixCode === "" || pn.prefixCode === undefined || pn.prefixCode === null || pn.numberType === "") {
		return pn;
	}

	// Local and mobile numbers must be 9 digits
	if ((pn.numberType === "local" || pn.numberType === "mobile") && cleanStr.length !== 9) {
		return pn;
	}

	// Init new phone number
	pn.dialingCode = "+61";
	pn.countryIso = "AU";
	pn.internationalCallPrefix = "0011";
	pn.lineNumber = cleanStr.substring(pn.prefixCode.length); // Remaining digits after prefix
	pn.phoneNumber = pn.dialingCode + cleanStr;
	pn.valid = true;

	return pn;
}

// Local: 9 OR 10 digits (10 if include leading 0), allows start with one of "01", "02", "03", "04", "05"
// Mobile: 9 OR 10 digits (10 if include leading 0), allows start with one of "6", "700", "73", "74", "75", "76", "77", "78"
// Toll Free: allows start with "08" or "09"
export function evaluateFrancePhoneNumber(pn, cleanStr: string): CleanPhoneNumber {

	// Verify presence
	if (cleanStr === null || cleanStr === "" || cleanStr?.length <= 2) {
		return pn;
	}

	// If clean str still has +33 prefix, remove
	if (cleanStr.startsWith("+33")) {
		cleanStr = cleanStr.substr(1); // Remove +
		cleanStr = cleanStr.substr(1); // Remove 3
		cleanStr = cleanStr.substr(1); // Remove 3
	} else if (cleanStr.startsWith("33") && (cleanStr.length === 11 || cleanStr.length === 12)) {
		cleanStr = cleanStr.substr(1); // Remove 3
		cleanStr = cleanStr.substr(1); // Remove 3
	}

	// If the length is 10 and starts with a leading 0, remove the 0
	if (cleanStr.length === 10 && cleanStr.startsWith("0")) {
		cleanStr = cleanStr.substr(1); // Remove 0
	}

	// At this point, if the phone number length isn't 9, abort - invalid France phone number
	if (cleanStr.length !== 9) {
		return pn;
	}

	const validFranceMobilePrefixes = ["6", "700", "73", "74", "75", "76", "77", "78"];

	const prefix = cleanStr[0] // First character
	switch(prefix) {
	case "1": case "2": case "3": case "4": case "5":
		pn.prefixCode = prefix;
		pn.numberType = "local";
		break;
	case "6": case "7":
		
		// Verify prefix is valid mobile pattern
		for (let i = 0; i < validFranceMobilePrefixes.length; i++) {
			const val = validFranceMobilePrefixes[i];
			if (cleanStr.startsWith(val)) {
				pn.prefixCode = val;
			}
		}

		// If we found a valid prefix pattern, set type to mobile
		if (pn.prefixCode != "") {
			pn.numberType = "mobile";
		}

		break;

	case "8": case "9":
		pn.prefixCode = prefix;
		pn.numberType = "tollfree";
		break;

	}

	// If we are missing the prefix or type at this point, abort - invalid France Phone Number
	if (pn.prefixCode === "" || pn.prefixCode === undefined || pn.prefixCode === null || pn.numberType === "") {
		return pn;
	}

	// // Init new phone number
	// phoneNumber := FranceCountryCode + cleanStr

	pn.dialingCode = "+33"
	pn.countryIso = "FR"
	pn.internationalCallPrefix = "00"
	pn.lineNumber = cleanStr.substring(pn.prefixCode.length); // Remaining digits after prefix
	pn.phoneNumber = pn.dialingCode + cleanStr;
	pn.valid = true;

	return pn;
}

// It is a closed numbering plan, which means that all telephone numbers, including the area code, have a fixed number of digits.
// Swiss area codes are officially termed national destination codes (NDC). A complete telephone number consists of ten digits: 0xx xxx xx xx.
// Two formats are distinguished (a few exceptions exist):
	// three digits for the NDC and seven digits for the subscriber number
	// four digits for the NDC and six digits for the subscriber number
// The associated dial plan requires that all numbers, even for local calls, must be dialed with the assigned NDC, in contrast to previous plans.
// When dialing from within the country, a prefix 0 must be dialed.
export function evaluateSwitzerlandPhoneNumber(pn, cleanStr: string): CleanPhoneNumber {

	// Verify presence
	if (cleanStr === null || cleanStr === "" || cleanStr?.length <= 2) {
		return pn;
	}

	// If clean str still has +41 prefix, remove
	if (cleanStr.startsWith("+41")) {
		cleanStr = cleanStr.substr(1); // Remove +
		cleanStr = cleanStr.substr(1); // Remove 4
		cleanStr = cleanStr.substr(1); // Remove 1
	} else if (cleanStr.startsWith("41") && (cleanStr.length === 11 || cleanStr.length === 12)) {
		cleanStr = cleanStr.substr(1); // Remove 4
		cleanStr = cleanStr.substr(1); // Remove 1
	}

	// If the length is 10 and starts with a leading 0, remove the 0
	if (cleanStr.length === 10 && cleanStr.startsWith("0")) {
		cleanStr = cleanStr.substr(1); // Remove 0
	}

	// At this point, if the phone number length isn't 9 or 10, abort - invalid Switzerland phone number
	if (cleanStr.length !== 9 && cleanStr.length !== 10) {
		return pn;
	}

	// Default: Grab first 2 digits when length is 9 digits
	let prefix = cleanStr.substring(0, 2);

	// When phone number is 10 digits, take first 3 digits
	if (cleanStr.length === 10) {
		prefix = cleanStr.substring(0, 3);
	}

	// Default
	pn.numberType = "local";

	// On 10 digit phone numbers where the NPC is only 2 digits long, we can drop the 0
	// +41 0xx xxx xx xx => +41 xx xxx xx xx
	switch(prefix) {

	// 21 – ZN Lausanne
	case "21": case "021":
		pn.prefixCode = "21";
		break;

	// 22 – ZN Geneva
	case "22": case "022":
		pn.prefixCode = "22";
		break;

	// ZN Yverdon, Aigle
	case "24": case "024":
		pn.prefixCode = "24";
		break;

	// 26 – ZN Fribourg
	case "26": case "026":
		pn.prefixCode = "26";
		break;

	// 27 – ZN Valais/Wallis
	case "27": case "027":
		pn.prefixCode = "27";
		break;

	// 31 – ZN Bern and surrounding areas.
	case "31": case "031":
		pn.prefixCode = "31";
		break;

	// 32 – ZN Biel/Bienne, Neuchâtel, Solothurn, Jura
	case "32": case "032":
		pn.prefixCode = "32";
		break;

	// 33 – ZN Berner Oberland
	case "33": case "033":
		pn.prefixCode = "33";
		break;

	// 34 – ZN Region Bern-Emme
	case "34": case "034":
		pn.prefixCode = "34";
		break;

	// 41 – ZN Central Switzerland (Luzern, Zug etc.)
	case "41": case "041":
		pn.prefixCode = "41";
		break;

	// 43 – ZN Zurich
	case "43": case "043":
		pn.prefixCode = "43";
		break;

	// 44 – ZN Zurich (formerly 1)
	case "44": case "044":
		pn.prefixCode = "44";
		break;

	// 51 – business telecommunication networks (SBB, CFF, FFS)
	case "51": case "051":
		pn.prefixCode = "51";
		break;

	// 52 – ZN Winterthur, Schaffhausen
	case "52": case "052":
		pn.prefixCode = "52";
		break;

	// 55 – ZN Rapperswil
	case "55": case "055":
		pn.prefixCode = "55";
		break;

	// 56 – ZN Baden, Zurzach
	case "56": case "056":
		pn.prefixCode = "56";
		break;

	// 58 – business telecommunication networks
	case "58": case "058":
		pn.prefixCode = "58";
		break;

	// 61 – ZN Region Basel
	case "61": case "061":
		pn.prefixCode = "61";
		break;

	// 62 – ZN Region Olten-Langenthal(Oberaargau)-Aargau-West
	case "62": case "062":
		pn.prefixCode = "62";
		break;

	// 71 – ZN Region Eastern Switzerland (St. Gallen etc.)
	case "71": case "071":
		pn.prefixCode = "71";
		break;

	// 74 – mobile services: paging services
	case "74": case "074":
		pn.prefixCode = "74";
		pn.numberType = "mobile";
		break;

	// 75 – mobile services: GSM / UMTS - Swisscom
	case "75": case "075":
		pn.prefixCode = "75";
		pn.numberType = "mobile";
		break;

	// 76 – mobile services: GSM / UMTS - Sunrise (with Yallo, TalkTalk, Lebara, MTV Mobile, Aldi)
	case "76": case "076":
		pn.prefixCode = "76";
		pn.numberType = "mobile";
		break;

	// 77 – mobile services: GSM / UMTS - various: M-Budget (774), Wingo (775), Mucho, Lycamobile (779), ok.-mobile, Tele2
	case "77": case "077":
		pn.prefixCode = "77";
		pn.numberType = "mobile";
		break;

	// 78 – mobile services: GSM / UMTS - Salt (with CoopMobile till 2018-12-31, UPC till 2018-12-31)
	case "78": case "078":
		pn.prefixCode = "78";
		pn.numberType = "mobile";
		break;

	// 79 – mobile services: GSM / UMTS - Swisscom
	case "79": case "079":
		pn.prefixCode = "79";
		pn.numberType = "mobile";
		break;

	// 800 – freephone numbers
	// 81 – ZN Chur
	// 840 – shared-cost numbers
	// 842 – shared-cost numbers
	// 844 – shared-cost numbers
	// 848 – shared-cost numbers
	// 860 – voice mail access (+ 9 digits phone without the initial 0, i.e. +41 860 66 555 44 33 is the voice mail of +41 66 555 44 33)
	// 868 – test numbers - Not accessible from abroad
	// 869 – VPN access code ( + 3 - 10 digits)
	// 878 – personal numbers (UPT)
	// 900 – Premium rate service for business, marketing
	// 901 – Premium rate service for entertainment,
	// 906 – Premium rate service for adult entertainment
	
	// 91 – ZN Ticino, Moesa
	case "91": case "091":
		pn.prefixCode = "91";
		break;

	// 98 – Inter-network routing numbers - Not accessible from abroad - Non diallable
	// 99 – Internal network numbers - Not accessible from abroad - Non diallable
	}

	// If we are missing the prefix or type at this point, abort - invalid Switzerland Phone Number
	if (pn.prefixCode === "" || pn.prefixCode === undefined || pn.prefixCode === null || pn.numberType === "") {
		return pn;
	}

	pn.dialingCode = "+41";
	pn.countryIso = "CH";
	pn.internationalCallPrefix = "00";
	pn.lineNumber = cleanStr.substring(pn.prefixCode.length); // Remaining digits after prefix
	pn.phoneNumber = pn.dialingCode + cleanStr;
	pn.valid = true;

	return pn;
}

export function evaluateNewZealandPhoneNumber(pn, cleanStr: string): CleanPhoneNumber {
	
	// Verify presence
	if (cleanStr === null || cleanStr === "" || cleanStr?.length <= 2) {
		return pn;
	}

	// // If clean str still has +64 prefix, remove
	if (cleanStr.startsWith("+64")) {
		cleanStr = cleanStr.substr(1); // Remove +
		cleanStr = cleanStr.substr(1); // Remove 6
		cleanStr = cleanStr.substr(1); // Remove 4
	} else if (cleanStr.startsWith("64") && cleanStr.length === 12) {
		cleanStr = cleanStr.substr(1); // Remove 6
		cleanStr = cleanStr.substr(1); // Remove 4
	}

	// If starts with 0, remove since 0 is the trunk prefix
	if (cleanStr.startsWith("0")) {
		cleanStr = cleanStr.substr(1); // Remove 0
	}

	// Init
	const numberLength = cleanStr.length;
	const firstNum = cleanStr.substring(0, 1);

	// Switch over first number
	switch(firstNum) {
	case "2":

		// Exception: 2 followed by 409 (Ross Dependency)
		if (cleanStr.startsWith("2409")) {
			pn.prefixCode = "2409";
			pn.numberType = "local";
		} else {

			// 2 number prefix
			const prefixTwo = cleanStr.substring(0, 2);

			// Switch over 2
			switch(prefixTwo) {
			case "21": // Vodafone
				pn.prefixCode = prefixTwo;

				// Ensure 8 to 10 digits
				if (numberLength !== 8 && numberLength !== 10) {
					return pn;
				}

				break;

			case "22": // 2degrees
				pn.prefixCode = prefixTwo;
				break;

			case "23": // Unused
				pn.prefixCode = prefixTwo;

				// Ensure 8 to 9 digits
				if (numberLength !== 8 && numberLength !== 9) {
					return pn;
				}

				break;

			case "24":
				pn.prefixCode = prefixTwo;

				// Ensure 8 to 9 digits
				if (numberLength !== 8 && numberLength !== 9) {
					return pn;
				}

				break;

			case "25": // Unused
				pn.prefixCode = prefixTwo;

				// Ensure 8 to 9 digits
				if (numberLength !== 8 && numberLength !== 9) {
					return pn;
				}

				break;

			case "26": // Formerly Team Talk (0) OR Spark New Zealand (remaining)
				pn.prefixCode = prefixTwo;

				// Ensure 8 to 9 digits
				if (numberLength !== 8 && numberLength !== 9) {
					return pn;
				}

				break;

			case "27": // Spark New Zealand
				pn.prefixCode = prefixTwo;

				// Ensure 9 digits
				if (numberLength !== 9) {
					return pn;
				}

				break;

			case "28": // Compass Communications, CallPlus, Black + White, Vodafone, 2Talk, Two Degrees Mobile, NOW
				pn.prefixCode = prefixTwo;

				break;

			case "29": // TelstraClear
				pn.prefixCode = prefixTwo;

				// Ensure 8 to 9 digits
				if (numberLength !== 8 && numberLength !== 9) {
					return pn;
				}

				break;
			}

			// If unmatched, check for 3 digit prefixes
			if (pn.prefixCode === "" || pn.prefixCode === undefined || pn.prefixCode === null) {

				// 3 number prefix
				const prefixThree = cleanStr.substring(0, 3);

				// Switch over 3
				switch(prefixThree) {
				case "201": // Vocus
					pn.prefixCode = prefixThree;
					break;

				case "202": // Vocus
					pn.prefixCode = prefixThree;
					break;

				case "203": // Voyager
					pn.prefixCode = prefixThree;
					break;

				case "204": // Skinny
					pn.prefixCode = prefixThree;
					break;

				case "205": // Vodafone
					pn.prefixCode = prefixThree;
					break;

				case "206": // Voyager Internet
					pn.prefixCode = prefixThree;
					break;

				case "207": // TeamTalk
					pn.prefixCode = prefixThree;
					break;

				case "208": // TeamTalk
					pn.prefixCode = prefixThree;
					break;

				case "209": // TeamTalk
					pn.prefixCode = prefixThree;
					break;

				}

				// All of these prefixes are 9 digits -- ensure
				if (numberLength !== 9) {
					return pn;
				}
			}

			// If still unmatched, return error since non-valid mobile number
			if (pn.prefixCode === "" || pn.prefixCode === undefined || pn.prefixCode === null) {
				return pn;
			}
		
			// Set to mobile since we validated the prefix
			pn.numberType = "mobile"
		}

		break;

	// local use case (besides 1 toll free case)
	case "3":
	case "4":
	case "6":
	case "7":
	case "9":

		// Ensure not 900 for premium rate services
		if (cleanStr.substring(0, 3) === "900") {
			pn.prefixCode = "900";
			pn.numberType = "tollfree";

			// Ensure 9 digits
			if (numberLength !== 9) {
				return pn;
			}

		} else {
			pn.prefixCode = firstNum;
			pn.numberType = "local";

			// Ensure 8 digits
			if (numberLength !== 8) {
				return pn;
			}
		}

		break; // this breaks all "3", "4", "6", "7", "9"

	// Toll free (except 900 - validation in landline since 9 is also a landline case)
	case "5":
	case "8":

		// Init first 3 digits and check if matches any of the valid toll free prefixes
		if (["508","800","900"].indexOf(cleanStr.substring(0, 3))) {
			pn.prefixCode = cleanStr.substring(0, 3);
			pn.numberType = "tollfree";
			
			// Ensure 9 digits
			if (numberLength !== 9) {
				return pn;
			}
		}

		break;
	}

	// Abort if we do not have a valid number type or prefix
	if (pn.numberType === "" || pn.numberType === undefined || pn.numberType === null || pn.prefixCode === "" || pn.prefixCode === undefined || pn.prefixCode === null) {
		return pn;
	}

	// Set constants
	pn.dialingCode = "+64";
	pn.countryIso = "NZ";
	pn.internationalCallPrefix = "00";
	pn.lineNumber = cleanStr.substring(pn.prefixCode.length, numberLength); // Remaining digits after prefix
	pn.phoneNumber = pn.dialingCode + cleanStr;
	pn.valid = true;
	return pn;
}

// All phone numbers in Mexico are 10 digits long
// The first digit denotes a geographical area of the country
// 2 East
// 3 West
// 4 North
// 5 Center (Mexico City metropolitan area)
// 6 Northwest
// 7 South
// 8 Northeast
// 9 Southeast
// 800 (toll free) / 900 (premium toll free numbers)
export function evaluateMexicoPhoneNumber(pn, cleanStr: string): CleanPhoneNumber {
	
	// Verify presence
	if (cleanStr === null || cleanStr === "" || cleanStr?.length <= 2) {
		return pn;
	}

	// Cleanup phone number even more
	if (cleanStr.startsWith("+52")) {
		cleanStr = cleanStr.substr(1); // Remove +
		cleanStr = cleanStr.substr(1); // Remove 5
		cleanStr = cleanStr.substr(1); // Remove 2
	} else if (cleanStr.startsWith("52") && cleanStr.length === 12) {
		cleanStr = cleanStr.substr(1); // Remove 5
		cleanStr = cleanStr.substr(1); // Remove 2
	}

	// Double check that the cleaned str is 10 digits
	if (cleanStr.length !== 10) {
		return pn;
	}

	// // Init 2 and 3 char codes
	const areaCodeTwo = cleanStr.substring(0,2);
	const areaCodeThree = cleanStr.substring(0,3);

	// Check if toll free
	const validMexicoTollFreePrefixes = ["800","900"];
	if (validMexicoTollFreePrefixes.indexOf(areaCodeThree) >= 0) {
		pn.numberType = "tollfree"

		// Set phone number values
		pn.areaCode = areaCodeThree // First 3 digits
		pn.prefixCode = cleanStr.substring(3,6); // Middle 3 digits
		pn.lineNumber = cleanStr.substring(6,10); // Last 4 digits
	} else {

		// Check for local area codes
		// --------------------------

		// Switch over 2 character area codes
		switch(areaCodeTwo) {
		case "55": // Mexico City
			pn.areaCode = areaCodeTwo
			break;
		case "56": // Mexico City
			pn.areaCode = areaCodeTwo
			break;
		case "81": // Monterrey, Nuevo León
			pn.areaCode = areaCodeTwo
			break;
		case "33": // Guadalajara, Jalisco
			pn.areaCode = areaCodeTwo
			break;
		default: // If we make it here, we need to check 3 character codes

			// Switch over 3 character area codes
			switch(areaCodeThree) {
			case "656": // Ciudad Juárez, Chihuahua
				pn.areaCode = areaCodeThree
				break;
			case "614": // Chihuahua, Chihuahua
				pn.areaCode = areaCodeThree
				break;
			case "618": // Durango, Durango
				pn.areaCode = areaCodeThree
				break;
			case "999": // Mérida, Yucatán
				pn.areaCode = areaCodeThree
				break;
			case "990": // Mérida, Yucatán
				pn.areaCode = areaCodeThree
				break;
			case "221": // Puebla, Puebla
				pn.areaCode = areaCodeThree
				break;
			case "222": // Puebla, Puebla
				pn.areaCode = areaCodeThree
				break;
			case "442": // Querétaro, Querétaro
				pn.areaCode = areaCodeThree
				break;
			case "446": // Querétaro, Querétaro
				pn.areaCode = areaCodeThree
				break;
			case "449": // Aguascalientes, Aguascalientes
				pn.areaCode = areaCodeThree
				break;
			case "663": // Tijuana, Baja California
				pn.areaCode = areaCodeThree
				break;
			case "664": // Tijuana, Baja California
				pn.areaCode = areaCodeThree
				break;
			case "612": // La Paz, Baja California Sur
				pn.areaCode = areaCodeThree
				break;
			case "624": // Los Cabos, Baja California Sur
				pn.areaCode = areaCodeThree
				break;
			case "844": // Saltillo, Coahuila
				pn.areaCode = areaCodeThree
				break;
			case "686": // Mexicali, Baja California
				pn.areaCode = areaCodeThree
				break;
			case "667": // Culiacán, Sinaloa
				pn.areaCode = areaCodeThree
				break;
			case "722": // Toluca, Mexico
				pn.areaCode = areaCodeThree
				break;
			case "729": // Toluca, Mexico
				pn.areaCode = areaCodeThree
				break;
			case "998": // Cancún, Quintana Roo
				pn.areaCode = areaCodeThree
				break;
			case "871": // Torreón, Coahuila
				pn.areaCode = areaCodeThree
				break;
			case "744": // Acapulco, Guerrero
				pn.areaCode = areaCodeThree
				break;
			case "444": // San Luis Potosí, San Luis Potosí
				pn.areaCode = areaCodeThree
				break;
			case "440": // San Luis Potosí, San Luis Potosí
				pn.areaCode = areaCodeThree
				break;
			case "833": // Tampico, Tamaulipas
				pn.areaCode = areaCodeThree
				break;
			case "477": // León, Guanajuato
				pn.areaCode = areaCodeThree
				break;
			case "479": // León, Guanajuato
				pn.areaCode = areaCodeThree
				break;
			case "961": // Tuxtla Gutiérrez, Chiapas
				pn.areaCode = areaCodeThree
				break;
			case "662": // Hermosillo, Sonora
				pn.areaCode = areaCodeThree
				break;
			case "633": // Agua Prieta, Sonora
				pn.areaCode = areaCodeThree
				break;
			case "645": // Cananea, Sonora
				pn.areaCode = areaCodeThree
				break;
			case "644": // Cd. Obregón, Sonora
				pn.areaCode = areaCodeThree
				break;
			case "642": // Navojoa, Sonora
				pn.areaCode = areaCodeThree
				break;
			case "631": // Nogales, Sonora
				pn.areaCode = areaCodeThree
				break;
			case "229": // Veracruz, Veracruz
				pn.areaCode = areaCodeThree
				break;
			case "443": // Morelia, Michoacán
				pn.areaCode = areaCodeThree
				break;
			case "921": // Coatzacoalcos, Veracruz
				pn.areaCode = areaCodeThree
				break;
			case "771": // Pachuca, Hidalgo
				pn.areaCode = areaCodeThree
				break;
			case "981": // Campeche, Campeche
				pn.areaCode = areaCodeThree
				break;
			case "899": // Reynosa, Tamaulipas
				pn.areaCode = areaCodeThree
				break;
			case "868": // Matamoros, Tamaulipas
				pn.areaCode = areaCodeThree
				break;
			default:
				pn.areaCode = areaCodeThree // Non-major metro area
				break;
			}
		}

		// If we don't have a valid area code at this point, return false
		if (pn.areaCode === "" || pn.areaCode === undefined || pn.areaCode === null) {
			return pn;
		}

		// Set remaining fields for local number
		pn.numberType = "local"
		pn.prefixCode = cleanStr.substring(pn.areaCode.length,6); // Middle 3-4 digits
		pn.lineNumber = cleanStr.substring(6,10); // Last 4 digits
	}

	// Abort if we do not have a valid number type
	if (pn.numberType === "" || pn.numberType === undefined || pn.numberType === null) {
		return pn;
	}

	// Set constants
	pn.dialingCode = "+52";
	pn.countryIso = "MX";
	pn.internationalCallPrefix = "00";
	pn.phoneNumber = pn.dialingCode + cleanStr;
	pn.valid = true;
	return pn;
}

// Local/mobile: 10 digits, cannot start with 1 OR 0
// Toll-free: 10 digits, starts with any of these prefixes - "800","888","877","866","855","844","833","822","880","887","889"
export function evaluateUnitedStatesAndCanadaPhoneNumber(pn, cleanStr: string): CleanPhoneNumber {
	
	// Verify presence
	if (cleanStr === null || cleanStr === "" || cleanStr?.length <= 2) {
		return pn;
	}

	// Cleanup phone number even more
	if (cleanStr.startsWith("+1")) {
		cleanStr = cleanStr.substr(1); // Remove +
		cleanStr = cleanStr.substr(1); // Remove 1
	} else if (cleanStr.startsWith("1") && cleanStr.length === 11) {
		cleanStr = cleanStr.substr(1); // Remove 1
	}

	// Double check that the cleaned str is 10 digits & doesn't start with a 1 OR 0
	if (cleanStr.length !== 10 || cleanStr.startsWith("1") || cleanStr.startsWith("0")) {
		return pn;
	}

	// Set details
	pn.areaCode = cleanStr.substring(0,3); // First 3 digits
	pn.dialingCode = "+1";
	pn.countryIso = "US";
	pn.internationalCallPrefix = "011";
	pn.lineNumber = cleanStr.substring(6,10); // Last 4 digits
	pn.numberType = "local";
	pn.phoneNumber = pn.dialingCode + cleanStr;
	pn.prefixCode = cleanStr.substring(3,6); // Middle 3 digits
	pn.valid = true;

	const validUSCanadaTollFreePrefixes = ["800","888","877","866","855","844","833","822","880","887","889"];
	for (let i = 0; i < validUSCanadaTollFreePrefixes.length; i++) {
		if (validUSCanadaTollFreePrefixes[i] === pn.areaCode) {
			pn.numberType = "tollfree";
		}
	}

	return pn;
}

export function cleanupPhoneNumber(phoneNumber: string, countryIsoTwo: string): CleanPhoneNumber {

	let cleanPhoneNumber = {
		valid: false,
	} as CleanPhoneNumber;

	// Verify presence
	if (!(phoneNumber?.length > 0)) {
		return cleanPhoneNumber;
	}

	// Remove all non-numbers except +
	const cleanStr = phoneNumber.replace(/[^0-9+]/g, '');

	// Double check again that phone number is still present after cleaning
	if (!(cleanStr?.length > 0)) {
		return cleanPhoneNumber;
	}

	// If the phone number starts with a plus, evaluate the number based on the assumption of the country
	// If we have the plus, we don't need to worry about the input defaultCountryISO since we can derive the country from the country code
	// Need to go biggest to smallest country code-wise
	if (cleanStr[0] === "+") {
		
		// New Zealand: +64
		if (cleanStr.startsWith("+64")) {
			return evaluateNewZealandPhoneNumber(cleanPhoneNumber, cleanStr);
		}

		// Australia: +61
		if (cleanStr.startsWith("+61")) {
			return evaluateAustraliaPhoneNumber(cleanPhoneNumber, cleanStr);
		}

		// Mexico: +52
		if (cleanStr.startsWith("+52")) {
			return evaluateMexicoPhoneNumber(cleanPhoneNumber, cleanStr);
		}

		// Switzerland: +41
		if (cleanStr.startsWith("+41")) {
			return evaluateSwitzerlandPhoneNumber(cleanPhoneNumber, cleanStr);
		}

		// France: +33
		if (cleanStr.startsWith("+33")) {
			return evaluateFrancePhoneNumber(cleanPhoneNumber, cleanStr);
		}

		// US/CA: +1
		if (cleanStr.startsWith("+1")) {
			return evaluateUnitedStatesAndCanadaPhoneNumber(cleanPhoneNumber, cleanStr);
		}

		return cleanPhoneNumber;
	}

	// Switch over country iso
	switch(countryIsoTwo) {

		// Australia
		case "AU":
			cleanPhoneNumber = evaluateAustraliaPhoneNumber(cleanPhoneNumber, cleanStr);
			break;
		
		// France
		case "FR":
			cleanPhoneNumber = evaluateFrancePhoneNumber(cleanPhoneNumber, cleanStr);
			break;

		// Mexico
		case "MX":
			cleanPhoneNumber = evaluateMexicoPhoneNumber(cleanPhoneNumber, cleanStr);
			break;

		// New Zealand
		case "NZ":
			cleanPhoneNumber = evaluateNewZealandPhoneNumber(cleanPhoneNumber, cleanStr);
			break;

		// Switzerland
		case "CH":
			cleanPhoneNumber = evaluateSwitzerlandPhoneNumber(cleanPhoneNumber, cleanStr);
			break;

		// US / Canada
		default:
			cleanPhoneNumber = evaluateUnitedStatesAndCanadaPhoneNumber(cleanPhoneNumber, cleanStr);
			break;
	}

	return cleanPhoneNumber;
}

export function formatPhoneNumber(tel: string): string {

	if (!tel) { return ''; }

	const value = tel.toString().trim().replace(/^0-9+/, '');
	if (!!value && value.length > 0) {

		// Clean pn
		const cleanPN = cleanupPhoneNumber(value, "");
		if (cleanPN.valid !== true) {
			return value;
		}

		// US/CA
		if (cleanPN.dialingCode === "+1") {
			return cleanPN.dialingCode + " (" + cleanPN.areaCode + ") " + cleanPN.prefixCode + "-" + cleanPN.lineNumber;
		}

		// Mexico
		if (cleanPN.dialingCode === "+52") {
			return cleanPN.dialingCode + " " + cleanPN.areaCode + " " + cleanPN.prefixCode + " " + cleanPN.lineNumber;
		}

		// New Zealand
		if (cleanPN.dialingCode === "+64") {

			// Init line number
			let lineNumber = cleanPN.lineNumber;

			// Split line number 3 and remaining (3/4)
			if (lineNumber?.length >= 6) {
				lineNumber = lineNumber.substring(0,3) + " " + lineNumber.substring(3,lineNumber.length);
			}

			return cleanPN.dialingCode + " " + cleanPN.prefixCode + " " + lineNumber;
		}

		return cleanPN.dialingCode + " " + ((cleanPN.areaCode || "") + (cleanPN.prefixCode || "") + (cleanPN.lineNumber || "")).trim();
	}

	return "";
}
