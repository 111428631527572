import { get, post } from '.';

// Subaru Dispositions to map to from Volie Dispositions
export function GetSubaruDispositionActivityCodes(options = {}) {
	return get<any>("/app/api/subaru_disposition_activity_codes", options);
}

// Merge subaru leads
export function CreateSubaruCustomerLeadMerges(subaruCustomerId, options = {}) {
	return post<any>("/app/api/subaru_customers/" + (subaruCustomerId || 0).toString() + "/lead_merges", options);
}
