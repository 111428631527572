<template lang="pug">
    .div
       | {{ renderClock }}
</template>

<script lang="ts">
	import Vue from 'vue';
    import VolieState from '@/store';

	export default Vue.extend({
        data() {
            return {
            }
        },
        methods: {
            friendlyDay(day) {

                // Switch over day
                switch(day) {
                case 0:
                    return "Sun";
                case 1:
                    return "Mon";
                case 2:
                    return "Tue";
                case 3:
                    return "Wed";
                case 4:
                    return "Thu";
                case 5:
                    return "Fri";
                case 6:
                    return "Sat";
                }

                return "";
            },
        },
        computed: {
            currentTime() {
                return VolieState.currentTime();
            },
            currentUserClock() {
                return VolieState.currentUserClock();
            },
            renderClock() {
                const clock = this.currentUserClock;
                if (!!clock && clock.timeZoneId > 0 && this.showTimezoneAbbreviation === true) {
                    return this.friendlyDay(clock.currentTime.getDay()) +  ", " + clock.currentTime.toLocaleTimeString() + " " + clock.timeZoneAbbreviation;
                }

                return this.friendlyDay(this.currentTime.getDay()) +  ", " + this.currentTime.toLocaleTimeString();
            },
        },
        components: {
        },
        props: {
            showTimezoneAbbreviation: Boolean, 
        }
	});
</script>

<style lang="scss" scoped>
</style>
